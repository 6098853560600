import React from "react";
import { Button } from "primereact/button";



export default function AddButton({ customWidth, customHeight, btnLabel, onClick }) {
 
  return (
    <div>
      <Button
        icon="pi pi-plus  font-bold"
        onClick = {onClick}
        label={btnLabel?btnLabel:"Add"}
        iconPos="right"
        // onClick={handleClick ? buttonClick : null}
        className="bg-main border-round-md text-white text-center text-base  karla "
      />
      {/* style={{width: customWidth?customWidth:"27px",height: customHeight?customHeight:"27px"}} */}
    </div>
  );
}
