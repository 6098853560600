import { Password } from "primereact/password";
import React from "react";

export default function PasswordField({
  label,
  name,
  errorMessage,
  extraClassName,
  required,
  inputClass,
  onChange,
  toggle = "true",
  ...props
}) {
  return (
    <div className="grid w-full">
      <div className="col-12 md:col-6 w-full">
        <label htmlFor={name}>
          {label} {required && <span className="error">*</span>}
        </label>
        <Password
          id={name}
          name={name}
          inputClassName="w-full"
          className={`w-full mt-2 ${inputClass} ${
            errorMessage ? "p-invalid" : null
          }`}
          {...props}
          feedback={false}
          onChange={onChange}
          toggleMask={toggle}
        />
        {errorMessage ? <small className="error">{errorMessage}</small> : null}
      </div>
    </div>
  );
}
