import React,{useState} from "react";
import ContentArea from "../../../shared/ContentArea";
import { Card } from "primereact/card";
import InputField from "../../../components/input/input";
import DropdownField from "../../../components/input/dropdown";
import { TabView, TabPanel } from 'primereact/tabview';
import { Calendar } from "primereact/calendar";
import InputTextareaField from "../../../components/input/inputTextArea";
import CancelButton from "../../../components/Buttons/CancelButton";
import SaveButton from "../../../components/Buttons/SaveButton";
import Table from "../../../components/TableList/Table";
import { Checkbox } from "primereact/checkbox";
const AddNote = () => {
    const [checked, setChecked] = useState(false);

    const TableData = [
        {
            srno: "1",
            date: "02-22-2023",
            time: "02:32 pm",
            note: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",

        },
        {
            srno: "2",
            date: "02-22-2023",
            time: "02:32 pm",
            note: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        },
        {
            srno: "3",
            date: "02-22-2023",
            time: "02:32 pm",
            note: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        },
        {
            srno: "4",
            date: "02-22-2023",
            time: "02:32 pm",
            note: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        },
        {
            srno: "5",
            date: "02-22-2023",
            time: "02:32 pm",
            note: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        }


    ]
    const TableHeading = [
        { field: "srno", header: "Sr. No.", },
        { field: "date", header: "Date", },
        { field: "time", header: "Time", },
        { field: "note", header: "Notes", },
        { field: "Actions", header: "Actions",body: "AddNoteBodyTemplate"},
    ]
    const TableDatas = [
        {
            date: "22/02/23",
            Vocalization: "2",
            Tantrums: "3",
            Toilet: "4",
            Inappropriate:"1",
            PICA:"2",
            Tolerating :"4",
            Communication:"1",
            Initials:"Ashley"
        },
        {
            date: "22/02/23",
            Vocalization: "2",
            Tantrums: "3",
            Toilet: "4",
            Inappropriate:"1",
            PICA:"2",
            Tolerating :"4",
            Communication:"1",
            Initials:"Ashley"
        },
        {
            date: "22/02/23",
            Vocalization: "2",
            Tantrums: "3",
            Toilet: "4",
            Inappropriate:"1",
            PICA:"2",
            Tolerating :"4",
            Communication:"1",
            Initials:"Ashley"
        }
    ]
    const TableHeadings = [
        { field: "date", header: "Date", },
        { field: "Vocalization", header: "Excessive Vocalization", },
        { field: "Tantrums", header: "Tantrums", },
        { field: "Toilet", header: "Toilet Issues", },
        { field: "Inappropriate", header: "Inappropriate Sexual Touch", },
        { field: "PICA", header: "PICA", },
        { field: "Tolerating", header: "Tolerating & Delay", },
        { field: "Communication", header: "Functional Communication Training", },
        { field: "Initials", header: "Initials", },
        { field: "Check-Off", header: "Check-Off",body:"AddCheckboxTemple" },
    ]
    return (
        <>
            <ContentArea>
                <div className="manage_employees_container p-3 ">

                    <div className="grid mb-5 mr-0 ml-0 align-items-center bg-main-light border-round-lg shadow-1 flex justify-content-around">
                        <div className="md:col-11 ">
                            <h1 className="text-main" style={{ fontSize: "24px" }}>
                                Add Note
                            </h1>
                        </div>
                    </div>
                    <div className="grid mt-2 mb-3 ml-2">
                        <div className="col-12 md:col-4 ">
                            <DropdownField label="Select Client" />
                        </div>
                        <div className="col-12 md:col-4">
                            <InputField label="Client Name" />
                        </div>
                    </div>
                    <div>
                        <TabView>
                            <TabPanel header="Client Notes">
                                <Card className="shedule shadow-2 p-3 mb-5">
                                    <div className="p-fluid grid mb-5">
                                        <div className="md:col-12 ">
                                            <h1 className="text-main" style={{ fontSize: "20px" }}>
                                                Client Notes
                                            </h1>
                                        </div>
                                        <div className="col-12 md:col-6">
                                            <label className="font block mb-2">Select Date<code className="text-red">*</code></label>
                                            <Calendar className="calender_picker" inputClassName="border-round-md mb-2" />
                                        </div>
                                        <div className="col-12 md:col-6">
                                            <DropdownField label="Select Time" />
                                        </div>
                                        <div className="col-12 md:col-12 ">
                                            <InputTextareaField label="Add Notes" inputClass="p-5" />
                                        </div>
                                    </div>
                                    <div className="submitButton flex justify-content-end">
                                        <SaveButton label="Add" ></SaveButton>
                                        <CancelButton label="Cancel" ></CancelButton>
                                    </div>

                                </Card>
                                <Card className="shedule shadow-2 p-3">
                                    <Table tableData={TableData} tableHeading={TableHeading} />
                                </Card>
                                <div className="submitButton flex mt-5 justify-content-end" >
                                    <SaveButton label="Save" ></SaveButton>
                                    <CancelButton label="Cancel" ></CancelButton>
                                </div>
                            </TabPanel>
                            <TabPanel header="Corrective Notes">
                            <Card className="shedule shadow-2 p-3 mb-5">
                                    <div className="p-fluid grid mb-5">
                                        <div className="md:col-12 ">
                                            <h1 className="text-main" style={{ fontSize: "20px" }}>
                                            Corrective Notes
                                            </h1>
                                        </div>
                                        <div className="col-12 md:col-6">
                                            <label className="font block mb-2">Select Date<code className="text-red">*</code></label>
                                            <Calendar className="calender_picker" inputClassName="border-round-md mb-2" />
                                        </div>
                                        <div className="col-12 md:col-6">
                                            <DropdownField label="Select Time" />
                                        </div>
                                        <div className="col-12 md:col-12 ">
                                            <InputTextareaField label="Add Notes" inputClass="p-5" />
                                        </div>
                                    </div>
                                    <div className="submitButton flex justify-content-end" >
                                        <SaveButton label="Add" ></SaveButton>
                                        <CancelButton label="Cancel" ></CancelButton>
                                    </div>

                                </Card>
                                <Card className="shedule shadow-2 p-3">
                                    <Table tableData={TableData} tableHeading={TableHeading} />
                                </Card>
                                <div className="submitButton flex mt-5 justify-content-end" >
                                    <SaveButton label="Save"></SaveButton>
                                    <CancelButton label="Cancel"></CancelButton>
                                </div>
                            </TabPanel>
                            <TabPanel header="Behavior Notes">
                            <Card className="shedule shadow-2 p-3 mb-5">
                                    <div className="p-fluid grid mb-5">
                                        <div className="md:col-12 ">
                                            <h1 className="text-main" style={{ fontSize: "20px" }}>
                                            Behavior Notes
                                            </h1>
                                        </div>
                                        <div className="col-12 md:col-4">
                                            <label className="font block mb-2">Date<code className="text-red">*</code></label>
                                            <Calendar className="calender_picker" inputClassName="border-round-md mb-2" />
                                        </div>
                                        <div className="col-12 md:col-4">
                                            <InputField label = "Excessive Vocalization"/>
                                        </div>
                                        <div className="col-12 md:col-4">
                                            <InputField label = "Tantrums"/>
                                        </div>
                                        <div className="col-12 md:col-4">
                                            <InputField label = "Toilet Issues"/>
                                        </div>
                                        <div className="col-12 md:col-4">
                                            <InputField label = "Inappropriate Sexual Touch"/>
                                        </div>
                                        <div className="col-12 md:col-4">
                                            <InputField label = "PICA"/>
                                        </div>
                                        <div className="col-12 md:col-4">
                                            <InputField label = "Tolerating & Delay"/>
                                        </div>
                                        <div className="col-12 md:col-4">
                                            <InputField label = "Functional Communication Training"/>
                                        </div>
                                        <div className="col-12 md:col-4">
                                            <InputField label = "Initials"/>
                                        </div>
                                        <div className="col-12 md:col-4">
                                        <Checkbox onChange={e => setChecked(e.checked)} checked={checked}></Checkbox>
                                        <label htmlFor="ingredient1" className="ml-2 check-off text-base font-normal">Check-Off</label>
                                        </div>
                                    </div>
                                    <div className="submitButton flex justify-content-end" >
                                        <SaveButton label="Add" ></SaveButton>
                                        <CancelButton label="Cancel" ></CancelButton>
                                    </div>

                                </Card>
                                <Card className="shedule shadow-2 p-3">
                                    <div className ="add-notesTable">
                                    <Table tableData={TableDatas} tableHeading={TableHeadings} showGridlines  />
                                    </div>
                                </Card>
                                <div className="submitButton flex mt-5 justify-content-end" >
                                    <SaveButton label="Save"></SaveButton>
                                    <CancelButton label="Cancel"></CancelButton>
                                </div>
                            </TabPanel>
                        </TabView>
                    </div>
                </div>
            </ContentArea></>
    )
}

export default AddNote