
import React, { useState, useEffect } from "react";
import InputField from "../../../../components/input/input";
import { Card } from "primereact/card";
import ContentArea from "../../../../shared/ContentArea";
import SaveButton from "../../../../components/Buttons/SaveButton";
import CancelButton from "../../../../components/Buttons/CancelButton";
import { viewRevenuCategoriesAction, editRevenueCategoriesAction } from "../../../../Redux/HospitalAction/expenseAction";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import Loader from "../../../../pages/Loader";
const EditRevenueCategories = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { id } = useParams()
    const [data, setData] = useState({
        revenue_category_name: ""
    })
    const [error, setError] = useState("")
    useEffect(() => {
        dispatch(viewRevenuCategoriesAction(id))
    }, [])
    const getViewData = useSelector((state) => state.viewRevenueCategories.viewRevenueCategoriesProfile)
    useEffect(() => {
        if (getViewData) {
            let obj = {
                revenue_category_name: getViewData?.category,
                id: getViewData?.id,
                user_id: getViewData?.user_id
            }
            setData(obj)
        }
    }, [getViewData])
    const handleChange = (e) => {
        const { name, value } = e.target
        setError({
            ...error,
            [name]: null
        })
        setData({
            ...data,
            [name]: value
        })
    }
    const handleSubmit = () => {
        if (data?.revenue_category_name === "") {
            setError({
                ...error,
                revenue_category_name: data.revenue_category_name === "" ? "Please Enter Category Name" : error.revenue_category_name
            })
            return false
        }
        const formData = new FormData()
        formData.append("revenue_category_name", data.revenue_category_name)
        dispatch(editRevenueCategoriesAction(formData, id, () => navigate("/account-owner/manage-revenue-categories")))
    }
    const isLoading = useSelector((state) => state.loader.isLoading)
    return (
        <>
            {isLoading === true ? <Loader /> :
                <ContentArea>
                    <div className="add_client_tier_container">
                        <div className="p-3">
                            <div className="grid card ml-0 mr-0 px-5 mb-5 align-items-center bg-main-light border-round-lg">
                                <h2 className="text-main">
                                    Edit Revenue Category
                                </h2>
                            </div>
                            <Card className="shadow-2 p-3 border-round-lg">
                                <div className="p-fluid grid mb-3">
                                    <div className="field col-12 md:col-3">
                                        <InputField label="Category Name" required value={data?.revenue_category_name} name="revenue_category_name" onChange={handleChange} />
                                        {error?.revenue_category_name && <span className="error">{error.revenue_category_name}</span>}
                                    </div>

                                </div>
                                <div className="submitButton flex">
                                    <SaveButton label="Save" onClick={handleSubmit}></SaveButton>
                                    <CancelButton label="Cancel" onClick={() => navigate("/account-owner/manage-revenue-categories")}></CancelButton>
                                </div>
                            </Card>
                        </div>
                    </div>
                </ContentArea>
            }
        </>
    )
}

export default EditRevenueCategories;
