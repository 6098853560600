
import React, { useState } from "react";
import ContentArea from "../../../shared/ContentArea";
import Table from '../../../components/TableList/Table';
import { useNavigate } from "react-router-dom";
import { Button } from 'primereact/button';
import SearchIcon from "../../../assets/icons/search.png"
import FilterIcon from "../../../assets/icons/filter.png"

const ProfitLossReport = () => {
  const navigate = useNavigate()

  const TableData = [
    {
      Date: "31/10/2022",
      Expenses: "$200",
      Amount: "$500",
      ProfitLoss: "$300"

    },
    {
      Date: "31/10/2022",
      Expenses: "$200",
      Amount: "$500",
      ProfitLoss: "$300"
    },
    {
      Date: "31/10/2022",
      Expenses: "$200",
      Amount: "$500",
      ProfitLoss: "$300"
    },
    {
      Date: "31/10/2022",
      Expenses: "$200",
      Amount: "$500",
      ProfitLoss: "$300"
    },
    {
      Date: "31/10/2022",
      Expenses: "$200",
      Amount: "$500",
      ProfitLoss: "$300"
    },
    {
      Date: "31/10/2022",
      Expenses: "$200",
      Amount: "$500",
      ProfitLoss: "$300"
    },
    {
      Date: "31/10/2022",
      Expenses: "$200",
      Amount: "$500",
      ProfitLoss: "$300"
    },
    {
      Date: "31/10/2022",
      Expenses: "$200",
      Amount: "$500",
      ProfitLoss: "$300"
    },
    {
      Date: "31/10/2022",
      Expenses: "$200",
      Amount: "$500",
      ProfitLoss: "$300"
    },
    {
      Date: "31/10/2022",
      Expenses: "$200",
      Amount: "$500",
      ProfitLoss: "$300"
    },

  ]

  const TableHeading = [
    { selectionMode: "multiple" },
    { field: "Date", header: "Date", },
    { field: "Amount", header: "Total Expenses Amount", },
    { field: "Expenses", header: "Total Revenue Amount", },
    { field: "Amount", header: "Profit/Loss", },
    { field: "icons", header: "Action", body: "expensesreportsiconBodyTemplate" },

  ];
  const handleGrandTotal = () => {
    return (
      <div className="grid bg-blue-100 border-round-lg p-0 m-0 mt-4">
        <div className="col-12 md:col-2">
          <p className=" mx-2"> Total Amount </p>
        </div>
        <div className="col-12 md:col-1">
          <p className="text-center ml-4"> $ 3000 </p>
        </div>
        <div className="col-12 md:col-5">
          <p className="text-center ml-8"> $ 3000 </p>
        </div>
        <div className="col-12 md:col-2">
          <p className="text-center ml-4"> $ 3000 </p>
        </div>
      </div>
    )
  }
  return (
    <>

      <ContentArea>
        <div className="manage_employees_container p-3 ">

          <div className="grid mb-5 mr-0 ml-0 align-items-center bg-main-light border-round-lg shadow-1 flex justify-content-around">
            <div className="md:col-5 ">
              <h1 className="text-main  " style={{ fontSize: "24px" }}>
                Profit & Loss Report
              </h1>
            </div>
            <div className="md:col-3">
              <div className="search-box w-full">
                <img
                  src={SearchIcon}
                  alt="Search Icon"
                  width="15px"
                  height="15px"
                />
                <input type="search" placeholder="Search" />
              </div>
            </div>
            <div className="md:col-1 relative bg-main px-3 border-round-md w-max ">

              <div className="border-round-md text-white text-center text-base relative karla">
                Filter &nbsp;
                <img src={FilterIcon} alt="" width={15} /></div>


            </div>
            <div className="md:col-2 w-max ">
              <Button label="Export" className="bg-main karla px-4 border-round-md" />
            </div>
          </div>

          <div className="grid mt-2 mb-5">
            <div className="md:col-12">

              <Table tableHeading={TableHeading}
                tableData={TableData}
                title="expensesReport"
                handleGrandTotal={handleGrandTotal()}
              />
            </div>

          </div>

        </div>
      </ContentArea>
    </>
  )
}

export default ProfitLossReport;
