import { combineReducers } from "redux";
import {
  editMangeServiceProviderReducer,
  editServiceProviderReducer,
  getServiceProviderReducer,
  getServiceReducer,
  getfilterServiceProviderReducer,
  viewManageServiceProviderReducer,
  viewServiceProviderReducer,
} from "./serviceProviderReducer";
import loaderReducer from "./loaderReducer";
import { carrierReducer } from "./carrierReducer";
import toastReducer from "./toastReducer";
import {
  addPaCodeReducer,
  deletePaCodesReducer,
  editPaCodeReducer,
  getPaCodeReducer,
  getfilterPaCodeReducer,
  viewPaCodeReducer,
} from "./paCodeReducer";
import {
  SignupReducer,
  checkDiscountReducer,
  paymentReducer,
  subscriptionPlanReducer,
} from "./signupReducer";
import { loginReducer } from "./loginReducer";
import {
  forgetPasswordReducer,
  resetPasswordReducer,
  verifyOtpReducer,
} from "./forgetPasswordReducer";
import {
  addClientPaCodeReducer,
  addClientReducer,
  addClientTierReducer,
  deleteClientPaCodeReducer,
  deleteClientReducer,
  deleteClientTierReducer,
  editClientPaCodeReducer,
  editClientReducer,
  editClientTierReducer,
  getClientPaCodeReducer,
  getClientReducer,
  getClientTierReducer,
  viewClientPaCodeReducer,
  viewClientReducer,
  viewClientTierReducer,
} from "./clientReducer";
import {
  addEmployeeReducer,
  addEmployeeSchedulingReducer,
  addHourlyRateRducer,
  addSpecialEmployeeScheduling,
  deleteEmployeeReducer,
  deleteEmployeeSchedulingReducer,
  deleteHourlyRateReducer,
  editEmployeeReducer,
  editEmployeeSchedulingReducer,
  editHourlyEmployeeReducer,
  getEmployeeReducer,
  getEmployeeSchedulingReducer,
  getHourlyRateReducer,
  getLeaveCategoryReducer,
  getLeaveTypeReducer,
  getPermissionReducer,
  getSchedulingListingTypeReducer,
  getSelectedEmployeeReducer,
  postSchedulingLeaveTypeReducer,
  viewEmployeeReducer,
  viewEmployeeSchedulingReducer,
  viewHourlyEmployeeReducer,
} from "./employeeReducer";
import {
  addClientCertificationReducer,
  addEmployeeCertificationListReducer,
  addEmployeeCertificationReducer,
  certificationClientDeleteReducer,
  certificationClientEditReducer,
  certificationClientGetReducer,
  certificationClientViewReducer,
  deleteClientCertificationReducer,
  deleteEmployeeCertificationListReducer,
  deleteEmployeeCertificationReducer,
  editClientCertificationReducer,
  editEmployeeCertificationListReducer,
  editEmployeeCertificationReducer,
  getClientCertificationReducer,
  getEmployeeCerticationListReducer,
  getEmployeeCertificationReducer,
  viewClientCerticationReducer,
  viewEmployeeCerticationReducer,
  viewEmployeeCertificationListReducer,
} from "./certificationReducer";
import {
  addExpenseCategoriesReducer,
  addExpenseReducer,
  addRevenueCategoriesReducer,
  addRevenueReducer,
  deleteExpenseCategoriesReducer,
  deleteExpenseReducer,
  deleteRevenueCategoriesReducer,
  deleteRevenueReducer,
  editExpenseCategoriesReducer,
  editExpenseReducer,
  editRevenueCategoriesReducer,
  editRevenueReducer,
  getExpenseCategoriesReducer,
  getExpenseReducer,
  getRevenueCategoriesReducer,
  getRevenueReducer,
  viewExpenseCategoriesReducer,
  viewExpenseReducer,
  viewRevenueCategoriesReducer,
  viewRevenueReducer,
} from "./expenseReducer";
import { timeClockReducer } from "./timeReducer";
import {
  CalendarSchedulingReducer,
  addAppointmentBookingReducer,
  appointmentAddServiceReducer,
  appointmentBookingReducer,
  appointmentCalendarReducer,
  appointmentDeleteServiceReducer,
  appointmentGetServiceReducer,
  appointmentViewServiceReducer,
  appointmenttEditServiceReducer,
  editAppointmentDataReducer,
  getAppointmentDataReducer,
  getDateEvenetReducer,
  getappointmentReducer,
  selectedAppointmentReducer,
  viewAppointmentDataReducer,
  viewappointmentReducer,
} from "./appoinmentReducer";
import {
  CompanyLeaveReducer,
  CompanyLocationReducer,
  CompanydetailsReducer,
  DeleteCompanyActionReducer,
  LeavePostReducer,
  LocationPostReducer,
  getLeaveReducer,
} from "../SettingReducer/SettingReducer";
import { CompanyPostData } from "../HospitalAction/appoinmentAction";
import {
  ClientPasswordResend,
  ClientProfileReducer,
} from "./clientProfileReducer";

export default combineReducers({
  getServiceProvider: getServiceProviderReducer,
  getService: getServiceReducer,
  getFilterService: getfilterServiceProviderReducer,
  loader: loaderReducer,
  carrier: carrierReducer,
  toast: toastReducer,
  viewServiceProvider: viewServiceProviderReducer,
  editServiceProvider: editServiceProviderReducer,
  getPaCode: getPaCodeReducer,
  deletePaCode: deletePaCodesReducer,
  filterPaCode: getfilterPaCodeReducer,
  addPaCode: addPaCodeReducer,
  viewPaCode: viewPaCodeReducer,
  editPaCode: editPaCodeReducer,
  signupData: SignupReducer,
  discountCode: checkDiscountReducer,
  payment: paymentReducer,
  subscriptionPlan: subscriptionPlanReducer,
  login: loginReducer,
  forgetPassword: forgetPasswordReducer,
  verifyOtp: verifyOtpReducer,
  resetPassword: resetPasswordReducer,
  getEmployee: getEmployeeReducer,
  addEmployee: addEmployeeReducer,
  editEmployee: editEmployeeReducer,
  deleteEmployee: deleteEmployeeReducer,
  viewEmployee: viewEmployeeReducer,
  Permissions: getPermissionReducer,
  getHourlyRate: getHourlyRateReducer,
  deleteHourlyRate: deleteHourlyRateReducer,
  addHourlyRate: addHourlyRateRducer,
  getSelectedEmployee: getSelectedEmployeeReducer,
  viewHourlyEmployee: viewHourlyEmployeeReducer,
  editEmployeeHourly: editHourlyEmployeeReducer,
  addEmployeeScheduling: addEmployeeSchedulingReducer,
  getEmployeeScheduling: getEmployeeSchedulingReducer,
  deleteEmployeeScheduling: deleteEmployeeSchedulingReducer,
  viewEmployeeScheduling: viewEmployeeSchedulingReducer,
  editEmployeeScheduling: editEmployeeSchedulingReducer,
  getClientTier: getClientTierReducer,
  deleteClientTier: deleteClientTierReducer,
  addClientTier: addClientTierReducer,
  editClientTier: editClientTierReducer,
  getClient: getClientReducer,
  viewClientTier: viewClientTierReducer,
  addClient: addClientReducer,
  deleteClient: deleteClientReducer,
  viewClient: viewClientReducer,
  editClient: editClientReducer,
  getClientPaCode: getClientPaCodeReducer,
  addClientPaCode: addClientPaCodeReducer,
  deleteClientPaCode: deleteClientPaCodeReducer,
  viewClientPaCode: viewClientPaCodeReducer,
  editClientPaCode: editClientPaCodeReducer,
  getEmployeeCertication: getEmployeeCertificationReducer,
  addEmployeeCertification: addEmployeeCertificationReducer,
  viewEmployeeCertication: viewEmployeeCerticationReducer,
  editEmployeeCertication: editEmployeeCertificationReducer,
  deleteEmployeeCertification: deleteEmployeeCertificationReducer,
  getClientCertification: getClientCertificationReducer,
  addClientCertification: addClientCertificationReducer,
  deleteClientCertification: deleteClientCertificationReducer,
  viewClientCertification: viewClientCerticationReducer,
  editClientCertification: editClientCertificationReducer,
  getExpenseCategories: getExpenseCategoriesReducer,
  addExpenseCategories: addExpenseCategoriesReducer,
  deleteExpenseCategories: deleteExpenseCategoriesReducer,
  editExpenseCategories: editExpenseCategoriesReducer,
  viewExpenseCategories: viewExpenseCategoriesReducer,
  getExpense: getExpenseReducer,
  addExpense: addExpenseReducer,
  deleteExpense: deleteExpenseReducer,
  viewExpense: viewExpenseReducer,
  editExpense: editExpenseReducer,
  getRevenueCategories: getRevenueCategoriesReducer,
  addRevenueCategories: addRevenueCategoriesReducer,
  deleteRevenueCategories: deleteRevenueCategoriesReducer,
  viewRevenueCategories: viewRevenueCategoriesReducer,
  editRevenueCategories: editRevenueCategoriesReducer,
  getRevenue: getRevenueReducer,
  addRevenue: addRevenueReducer,
  deleteRevenue: deleteRevenueReducer,
  viewRevenue: viewRevenueReducer,
  editRevenue: editRevenueReducer,
  getEmployeeCertificationList: getEmployeeCerticationListReducer,
  addEmployeeCertificationList: addEmployeeCertificationListReducer,
  viewEmployeeCertificationList: viewEmployeeCertificationListReducer,
  deleteEmployeeCertificationList: deleteEmployeeCertificationListReducer,
  editEmployeeCertificationList: editEmployeeCertificationListReducer,
  certificationClientgetList: certificationClientGetReducer,
  certificationClientDeleteList: certificationClientDeleteReducer,
  certificationClientViewList: certificationClientViewReducer,
  certicationClientEditList: certificationClientEditReducer,
  timeClock: timeClockReducer,
  addappoinment: addClientPaCodeReducer,
  getappointment: getappointmentReducer,
  appointmentAddService: appointmentAddServiceReducer,
  appointmentGetService: appointmentGetServiceReducer,
  appointmentDeleteService: appointmentDeleteServiceReducer,
  appointmentViewService: appointmentViewServiceReducer,
  appointmentEditService: appointmenttEditServiceReducer,
  addAppointmentBooking: addAppointmentBookingReducer,
  selectedAppointment: selectedAppointmentReducer,
  appointmentBooking: appointmentBookingReducer,
  getAppointmentData: getAppointmentDataReducer,
  viewAppointmentData: viewAppointmentDataReducer,
  editAppointmentData: editAppointmentDataReducer,
  calendar: appointmentCalendarReducer,
  getEvent: getDateEvenetReducer,
  calendarScheduling: CalendarSchedulingReducer,
  companyDetails: CompanydetailsReducer,
  companyPostData: CompanyPostData,
  companyLocation: CompanyLocationReducer,
  companyLeave: CompanyLeaveReducer,
  LocationPost: LocationPostReducer,
  LeavePost: LeavePostReducer,
  ClientProfile: ClientProfileReducer,
  ClientResetPassword: ClientPasswordResend,
  DELETECOMPANYACTION: DeleteCompanyActionReducer,
  addSpecialEmployeeScheduling: addSpecialEmployeeScheduling,
  getLeaveType: getLeaveTypeReducer,
  getLeaveCategory: getLeaveCategoryReducer,
  postSchedulingLeaveType: postSchedulingLeaveTypeReducer,
  getSchedulingListing: getSchedulingListingTypeReducer,
  getLeaveList: getLeaveReducer,
  viewappointmentList: viewappointmentReducer,
  viewManageService: viewManageServiceProviderReducer,
  editMangeServiceProvider: editMangeServiceProviderReducer,
});
