import React from "react";
import ContentArea from "../../shared/ContentArea";
import Totalclient from "../../assets/images/totalclient.png";
import Providerclient from "../../assets/images/providers.png";
import Employeework from "../../assets/images/employeeworking.png";
import Totalrevenge from "../../assets/images/totalrevenge.png";
import BarChart from "../../components/Charts/BarChart.jsx";
import LineChart from "../../components/LineChart/LineChart";
import RevenueStatus from "../../components/RevenueStatus/RevenueStatus";
import Layout from "../../shared/Layout";

export default function MainDashboard() {
  return (
    <>
      <ContentArea>
        <div className="account_owner_main_dashboard">
          <div className="p-3">
            <div className="grid mb-5">
              <div className="col-3">
                <div className="border-round-2xl border-none shadow-none bg-seagreen p-5 h-full ">
                  <div className="relative">
                    <h3 className=" karla  text-xl font-bold p-0 m-0">
                      Total Clients
                    </h3>
                    <h1 className="text-4xl font-bold mb-0">75</h1>
                    <img
                      src={Totalclient}
                      className=" dashboard_revenu "
                      alt=""
                      width={60}
                      height={60}
                    />
                  </div>
                </div>
              </div>

              <div className="col-3">
                <div className="border-round-2xl border-none shadow-none bg-lightred p-5 h-full ">
                  <div className="relative">
                    <h3 className=" karla  text-xl font-bold p-0 m-0">
                      Total Service Providers
                    </h3>
                    <h1 className="text-4xl font-bold mb-0">25</h1>
                    <img
                      src={Providerclient}
                      className=" dashboard_revenu "
                      alt=""
                      width={60}
                      height={60}
                    />
                  </div>
                </div>
              </div>

              <div className="col-3">
                <div className="border-round-2xl border-none shadow-none bg-lightpirple p-5 h-full ">
                  <div className="relative">
                    <h3 className=" karla  text-xl font-bold p-0 m-0">
                      Employees Working Today
                    </h3>
                    <h1 className="text-4xl font-bold mb-0">$250</h1>
                    <img
                      src={Employeework}
                      className=" dashboard_revenu "
                      alt=""
                      width={60}
                      height={60}
                    />
                  </div>
                </div>
              </div>

              <div className="col-3">
                <div className="border-round-2xl border-none shadow-none bg-lightorange p-5 h-full ">
                  <div className="relative">
                    <h3 className=" karla  text-xl font-bold p-0 m-0">
                      Total Revenue
                    </h3>
                    <h1 className="text-4xl font-bold mb-0">$1,250</h1>
                    <img
                      src={Totalrevenge}
                      className=" dashboard_revenu "
                      alt=""
                      width={60}
                      height={60}
                    />
                  </div>
                </div>
              </div>

            </div>
            <div className="grid">
              <div className="col-6 mb-3">
                <BarChart title="Client Status" />
              </div>
              <div className="col-6 mb-3">
                <LineChart title="Service Provider Status" />
              </div>
              <div className="col-12">
                <RevenueStatus title="Revenue Status" />
              </div>
            </div>
          </div>
        </div>
      </ContentArea>
    </>
  );
}
