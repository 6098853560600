import React, { useState } from "react";
import { InputNumber } from "primereact/inputnumber";

export default function InputNumberField({
  label,
  name,
  errorMessage,
  extraClassName,
  required,
  inputClass,
  allowEmpty,
  onValueChange,
  useGrouping,
  maxLength,
  ...props
}) {
  return (
    <>
      <label htmlFor={name}>
        {label} {required && <span className="error">*</span>}
      </label>
      <InputNumber
        id={name}
        name={name}
        className="w-full mt-2 border-round-md"
        inputClassName={`w-full mt-2 border-round-md ${inputClass} ${
          errorMessage ? "p-invalid" : null
        }`}
        {...props}
        useGrouping={useGrouping}
        maxLength={maxLength}
        onValueChange={onValueChange}
        allowEmpty={allowEmpty}
      />
      {errorMessage ? <small className="error">{errorMessage}</small> : null}
    </>
  );
}
