import React from "react";

export default function BlogCard({extraClass,src,date,authorname,heading}) {
  return (
    <div className={extraClass}>
      <div className="p-3 blog-card bg-white border-round-lg">
        <img src={src} alt="" className="blog-img w-full" />
        <div className="blog-content">
          <span className="flex gap-5">
            <p className="text-sub-heading text-md">{date}</p>
            <ul className="">
              <li className="text-sub-heading text-md list-disc p-0">
                By {authorname}
              </li>
            </ul>
          </span>
          <h3 className="m-0">
            {heading}
          </h3>
        </div>
      </div>
    </div>
  );
}
