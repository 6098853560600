import { types } from "../HospitalType/types";

const intitalState = {
  CompanyDetails: {},
};

export const CompanydetailsReducer = (state = intitalState, action) => {
  switch (action.type) {
    case types.COMPANY_DETAIL:
      return {
        ...state,
        CompanyDetails: action.payload,
      };
    default: {
      return { ...state };
    }
  }
};
export const CompanyLocationReducer = (state = intitalState, action) => {
  switch (action.type) {
    case types.COMPANY_LOCATION:
      return {
        ...state,
        companyLocation: action.payload,
      };
    default: {
      return { ...state };
    }
  }
};
export const CompanyLeaveReducer = (state = intitalState, action) => {
  switch (action.type) {
    case types.COMPANY_LEAVE:
      return {
        ...state,
        companyLeave: action.payload,
      };
    default: {
      return { ...state };
    }
  }
};
export const CompanyPostReducer = (state = intitalState, action) => {
  switch (action.type) {
    case types.COMPANY_POST_DATA:
      return {
        ...state,
        CompanyPostData: action.payload,
      };
    default: {
      return { ...state };
    }
  }
};
export const LocationPostReducer = (state = intitalState, action) => {
  switch (action.type) {
    case types.Location_POST_DATA:
      return {
        ...state,
        LocationPostData: action.payload,
      };
    default: {
      return { ...state };
    }
  }
};

export const LeavePostReducer = (state = intitalState, action) => {
  switch (action.type) {
    case types.LEAVE_POST_DATA:
      return {
        ...state,
        LeavePostData: action.payload,
      };
    default: {
      return { ...state };
    }
  }
};

export const getLeaveReducer = (state = intitalState, action) => {
  switch (action.type) {
    case types.GET_LEAVE_LIST:
      return {
        ...state,
        getLeaveList: action.payload,
      };
    default: {
      return { ...state };
    }
  }
};

export const DeleteCompanyActionReducer = (state = intitalState, action) => {
  switch (action.type) {
    case types.DELETE_COMPANY_ACTION_DATA:
      return {
        ...state,
        DeleteCompanyActionData: action.payload,
      };
    default: {
      return { ...state };
    }
  }
};
