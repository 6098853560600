import React, { useEffect, useState } from "react";
import ContentArea from "../../shared/ContentArea";
import { Card } from "primereact/card";
import DropdownField from "../../../../components/input/dropdown";
import SaveButton from "../../../../components/Buttons/SaveButton";
import CancelButton from "../../../../components/Buttons/CancelButton";
import { useNavigate } from "react-router-dom";
import { Calendar } from "primereact/calendar";
import { useDispatch, useSelector } from "react-redux";
import {
  addSchedulingLeave,
  getEmployeesAction,
  getLeaveCategory,
  getLeaveList,
  getLeaveType,
} from "../../../../Redux/HospitalAction/employessAction";

const AddLeave = () => {
  const [data, setData] = useState({
    category: "",
    leave_type: "",
    employee_id: "",
    date: "",
    start_time: "",
    end_time: "",
  });
  const [date, setDate] = useState();
  const [categoryId, setcategory] = useState();
  const [endTimeSolts, setEndTimeSolts] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isError, setIsError] = useState({});

  const Validate = () => {
    let errors = {};
    if (!data.employee_id) {
      errors.employee_id = "Select Employee ";
    }

    if (data.leave_type === 3) {
      if (!data.start_time) {
        errors.start_time = "Enter Start Time ";
      }
      if (!data.end_time) {
        errors.end_time = "Enter End Time ";
      }
    }
    if (!data.date) {
      errors.date = "Enter Date ";
    }
    if (!data.leave_type) {
      errors.leave_type = "Enter Leave Type";
    }
    if (!data.category) {
      errors.category = "Enter Category";
    }
    setIsError(errors);
    if (Object.keys(errors).length === 0) {
      return true;
    } else {
      return false;
    }
  };

  const DataSubmit = async (data) => {
    let errors = await Validate();
    if (errors) {
      const newData = { ...data, date: data.date.toISOString().split("T")[0] };
      dispatch(addSchedulingLeave(newData, navigate));
    }
  };

  const handleChange = (name) => (e) => {
    if (name === "employee_id") {
      setData({ ...data, [name]: e.value.emp_id });
    }
    if (name === "leave_type") {
      setData({ ...data, [name]: e.value });
      setcategory(e.value);
    }
    setIsError({ ...isError, [name]: "" });
    setData({ ...data, [name]: e.target.value });
  };

  const getallEmployee = useSelector(
    (state) => state.getEmployee.getEmployeeProfile
  );

  const fetchLeaveCategory = useSelector(
    (state) => state.getLeaveCategory.getLeaveCategory
  );
  const fetchLeaveList = useSelector(
    (state) => state.getLeaveList.getLeaveList
  );

  useEffect(() => {
    dispatch(getEmployeesAction());
    dispatch(getLeaveList());
  }, []);
  useEffect(() => {
    if (categoryId) {
      dispatch(getLeaveCategory(categoryId));
    }
  }, [categoryId]);

  const generateTimeSlots = async (hh, mm, ss) => {
    const startTime = new Date();
    startTime.setHours(0, 0, 0);
    const endTime = new Date();
    endTime.setHours(23, 30, 0);
    const genTimeSlots = [];
    while (startTime <= endTime) {
      const hours = startTime.getHours().toString().padStart(2, "0");
      const minutes = startTime.getMinutes().toString().padStart(2, "0");
      let timeSlot = `${hours}:${minutes}`;
      genTimeSlots.push(timeSlot);
      startTime.setMinutes(startTime.getMinutes() + 60);
    }
    setEndTimeSolts(() => {
      return [...genTimeSlots];
    });

    setDate(genTimeSlots);
  };

  useEffect(() => {
    generateTimeSlots();
  }, []);

  return (
    <ContentArea>
      <div className="p-3">
        <div className="grid card ml-0 mr-0 px-5 mb-5 align-items-center bg-main-light border-round-lg">
          <h2 className="text-main ">Add Leave</h2>
        </div>
        <Card className="shedule shadow-2 p-3">
          <div className="p-fluid grid mb-3">
            <div className="col-12 md:col-6 ">
              <DropdownField
                label="Employee"
                inputId="dropdown"
                value={data?.employee_id}
                options={getallEmployee}
                onChange={handleChange("employee_id")}
                itemTemplate={(e) => {
                  return (
                    <>
                      {e.emp_id} {e.first_name} {e.last_name}
                    </>
                  );
                }}
                optionLabel="emp_id"
                placeholder="Select"
                required
              />
              {isError?.employee_id && (
                <p className="text-red-600 text-xs mt-1">
                  {isError.employee_id}
                </p>
              )}
            </div>
            <div className="col-12 md:col-6 ">
              <DropdownField
                label="Leave Type"
                inputId="dropdown"
                value={data?.leave_type}
                options={fetchLeaveList?.map((item) => {
                  return { name: item?.name, value: item?.id };
                })}
                onChange={handleChange("leave_type")}
                optionLabel="name"
                placeholder="Select"
                required
              />
              {isError?.leave_type && (
                <p className="text-red-600 text-xs mt-1">
                  {isError.leave_type}
                </p>
              )}
            </div>
            <div className="col-12 md:col-6 gap-2">
              <label className="font block mb-2">
                Date <cod className="text-red">*</cod>
              </label>
              <Calendar
                placeholder="Date"
                className="calender_picker border-round-md"
                inputClassName="border-round-md"
                value={"startDates"}
                onChange={handleChange("date")}
              />
              {isError?.date && (
                <p className="text-red-600 text-xs mt-1">{isError.date}</p>
              )}
            </div>
            {data?.leave_type === 3 ? (
              <>
                <div className="col-12 md:col-3 ">
                  <DropdownField
                    label="Start Time"
                    name="Start Time"
                    value={data?.start_time}
                    onChange={handleChange("start_time")}
                    options={date}
                  />
                  {isError?.start_time && (
                    <p className="text-red-600 text-xs mt-1">
                      {isError.start_time}
                    </p>
                  )}
                </div>
                <div className="col-12 md:col-3  ">
                  <DropdownField
                    label="End Time"
                    name="End Time"
                    value={data?.end_time}
                    onChange={handleChange("end_time")}
                    options={endTimeSolts}
                  />
                  {isError?.end_time && (
                    <p className="text-red-600 text-xs mt-1">
                      {isError.end_time}
                    </p>
                  )}
                </div>
              </>
            ) : (
              ""
            )}
            <div className="col-12 md:col-6 ">
              <DropdownField
                label="Category"
                inputId="dropdown"
                value={data?.category}
                options={fetchLeaveCategory?.map((item) => {
                  return { name: item.category, value: item.id };
                })}
                onChange={handleChange("category")}
                optionLabel="name"
                placeholder="Select"
                required
              />
              {isError?.category && (
                <p className="text-red-600 text-xs mt-1">{isError.category}</p>
              )}
            </div>
          </div>
          <div className="submitButton flex">
            <SaveButton
              label="Save"
              onClick={() => DataSubmit(data)}
            ></SaveButton>
            <CancelButton
              label="Cancel"
              onClick={() => navigate("/account-owner/employees-scheduling")}
            ></CancelButton>
          </div>
        </Card>
      </div>
    </ContentArea>
  );
};

export default AddLeave;
