
import React, { useEffect, useState } from "react";
import AddButton from '../../../../components/Buttons/AddButton';
import ContentArea from "../../../../shared/ContentArea";
import SearchIcon from "../../../../assets/icons/search.png";
import FilterIcon from "../../../../assets/icons/filter.png";
import Table from '../../../../components/TableList/Table';
import AddClients from './AddClients';
import { useNavigate } from "react-router-dom";
// import Index from '../../../account-owner/Index';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import Deleteimg from "../../../../assets/images/deleted.png";
import { getClientAction, deleteAction } from "../../../../utils/action";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FilterMatchMode } from 'primereact/api';
import { Calendar } from "primereact/calendar";
import { ProgressSpinner } from 'primereact/progressspinner';
import Index from "../../../../portal/account-owner/Index";
toast.configure();

export default function ManageClients() {
    const [deleteButton, setDeleteButton] = useState(false)
    const [value, setValue] = useState("")
    const [tableData, setTableData] = useState("")
    const [isLoading, setIsLoading] = useState(null)
    const { isNextPage, setIsNextPage } = Index();
    const [data, setData] = useState("")
    const [isShowFilter, setIsShowFilter] = useState(false);
    const [isFiltered, setIsFiltered] = useState(false);
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [filterData, setFilterData] = useState({
        start_date: "",
        end_date: ""
    })
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });
    const navigate = useNavigate()
    const AddClients = () => {
        setIsNextPage(!isNextPage)
        navigate("/sales-person/add-clients")
    }
    const id = data;
    const handleDelete = async () => {
        try {
            setIsLoading(true)
            let response = await deleteAction(id)
            if (response.status === true) {
                setIsLoading(false)
                setDeleteButton(false)
                handleSubmit()
                toast.success(response.data?.message || "Delete Client Successfully")
            }
        } catch (err) {
            setIsLoading(false)
            setDeleteButton(false)
            toast.error(err?.response?.data?.message);
        }
    }
    const handleSubmit = async () => {
        setIsLoading(true)
        try {
            let response = await getClientAction({ payload: value })
            response.data?.length > 0 && response?.data?.map((item) => {
                let date = new Date(item.dob)
                let year = date.getFullYear();
                let month = date.getMonth() + 1;
                let dt = date.getDate();
                if (dt < 10) {
                    dt = '0' + dt;
                }
                if (month < 10) {
                    month = '0' + month;
                }
                item.dob = dt + '-' + month + '-' + year;
            })

            setTableData(response.data)
            setIsLoading(false)
        } catch (err) {
            setIsLoading(false)
            toast.error(err?.response?.data?.message);
            return false;
        }
    }
    const applyfilter = async () => {
        setIsLoading(true)
        setIsShowFilter(!isShowFilter)
        let response = await getClientAction({ payload: filterData })
        setIsFiltered(true)
        response?.data?.map((item, index) => {
            let date = new Date(item.dob)
            let year = date.getFullYear();
            let month = date.getMonth() + 1;
            let dt = date.getDate();
            if (dt < 10) {
                dt = '0' + dt;
            }
            if (month < 10) {
                month = '0' + month;
            }
            item.dob = dt + '-' + month + '-' + year;
        })
        setTableData(response.data)
        setIsLoading(false)
    }
    const Clearfilter = async () => {
        setIsLoading(true)
        setIsFiltered(false)
        setIsShowFilter(false)
        let res = await getClientAction({ payload: value })
        res?.data?.map((item, index) => {
            let date = new Date(item.dob)
            let year = date.getFullYear();
            let month = date.getMonth() + 1;
            let dt = date.getDate();
            if (dt < 10) {
                dt = '0' + dt;
            }
            if (month < 10) {
                month = '0' + month;
            }
            item.dob = dt + '-' + month + '-' + year;
        })
        setIsLoading(false)
        setTableData(res.data.reverse())
    }
    useEffect(() => {
        handleSubmit()
    }, [])
    const handleChange = (e) => {
        let date = new Date(e.target.value)
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let dt = date.getDate();
        if (dt < 10) {
            dt = '0' + dt;
        }
        if (month < 10) {
            month = '0' + month;
        }
        let dob = year + '-' + month + '-' + dt;
        setFilterData({
            ...filterData,
            [e?.target?.name]: dob,
        });
    };

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        _filters['global'].value = value;
        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const TableHeading = [
        { field: "SrNo", header: "Sr. No.", body: "srnoTemplates" },
        { field: "profile_id", header: "Client Id" },
        { field: "dob", header: "Date",sortable: true },
        { field: "name", header: "Client Name",sortable: true},
        { field: "company_name", header: "Company Name",sortable: true },
        { field: "subscription_details", header: "Program Category", body: "ProgramDetails" },
        { field: "lead_status", header: "Status", body: "chageClientColor",sortable: true },
        // { field: "subscription_details", header: "Program Category",body:"ProgramDetails" },
        // { field: "lead_status", header: "Status",sortable: true },
        { field: "icons", header: "Action", body: "imageBodyTemplate" },
    ];
    return (
        <>
            {isLoading === true ? <ProgressSpinner style={{ width: '50px', height: '50px' }} className='text-center h-screen flex justify-content-center' strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" /> :
                <ContentArea>
                    <div className="manage_client_container p-3 ">
                        <div className="grid mb-5 mr-0 ml-0 align-items-center bg-main-light border-round-lg shadow-1 flex justify-content-evenly">
                            <div className="md:col-5 ">
                                <h1 className="text-main  " style={{ fontSize: "24px" }}>
                                    Manage Clients
                                </h1>
                            </div>
                            <div className="md:col-3">
                                <div className="search-box w-full">
                                    <img
                                        src={SearchIcon}
                                        alt="Search Icon"
                                        width="15px"
                                        height="15px"
                                    />
                                    <input type="search" onChange={onGlobalFilterChange} placeholder="Search" onClick={() => setIsShowFilter(false)} />
                                </div>
                            </div>
                            <div className="md:col-2 relative bg-main px-3 border-round-md w-max ">
                                {
                                    isFiltered ?
                                        <div onClick={() => Clearfilter()} className="bg-main border-round-md text-white text-center text-base relative karla">
                                            Clear &nbsp;
                                            <i className="pi pi-filter-slash"></i></div>
                                        :
                                        <div onClick={() => setIsShowFilter(prev => !prev)} className="border-round-md text-white text-center text-base relative karla">
                                            Filter &nbsp;
                                            <img src={FilterIcon} alt="" width={15} /></div>
                                }
                                {
                                    isShowFilter ?
                                        <div className="filter-popup">
                                            <label className="font block mb-2">
                                                Start Date<code className="text-red"></code>
                                            </label>
                                            <Calendar
                                                className="border-round-md calender_picker "
                                                inputClassName="calendar p-2 border-round-md "
                                                placeholder="Select Date"
                                                name="start_date"
                                                onChange={(e) => handleChange(e)}
                                            />
                                            <label className="font block mb-2 mt-2">
                                                End Date<code className="text-red"></code>
                                            </label>
                                            <Calendar
                                                className="border-round-md calender_picker "
                                                inputClassName="calendar p-2 border-round-md "
                                                placeholder="Select Date"
                                                name="end_date"
                                                onChange={(e) => handleChange(e)}
                                            />
                                            <div className="mt-3 flex justify-content-between">
                                                <Button onClick={Clearfilter}>Clear</Button>
                                                <Button onClick={(e) => applyfilter(e)}>Apply</Button></div>
                                        </div>
                                        : null
                                }
                            </div>
                            <div className="md:col-2 w-max ">
                                <AddButton onClick={() => AddClients()} />
                            </div>
                        </div>
                        <div className="grid mt-2">
                            <div className="md:col-12">

                                <Table tableHeading={TableHeading} tableData={tableData} setDeleteButton={setDeleteButton} deleteButton={deleteButton} setData={setData} filters={filters} setFilters={setFilters} />
                            </div>

                        </div>

                        {
                            deleteButton ?
                                <Dialog
                                    className="table_delete_modal flex justify-content-center "
                                    visible={deleteButton}
                                    onHide={() => setDeleteButton(false)}
                                    style={{ width: '30vw' }}>
                                    <p className="m-0">
                                        <div className=" flex justify-content-center">
                                            <img src={Deleteimg} alt="" width={150} height={127}></img>
                                        </div>
                                        <div className="text-center mb-5">
                                            <h4 className=" p-0 m-0 ">Are you sure you want to delete this user?</h4>
                                            <p className="text-xs text-400">All associated data will also be deleted! There is no undo!</p>
                                        </div>
                                        <div className=" btn flex justify-content-center">
                                            <div className="text-center">
                                                <Button
                                                    label="Cancel"
                                                    className="w-full surface-500 p-3 px-5    border-0 border-round-md "
                                                    onClick={() => setDeleteButton(false)}
                                                />
                                            </div>
                                            <div className="text-center">
                                                <Button
                                                    label="Delete"
                                                    className=" w-full px-5 border-0 p-3 ml-3 border-round-md bg-red-500"
                                                    onClick={handleDelete}
                                                />
                                            </div>
                                        </div>
                                    </p>
                                </Dialog> : null
                        }
                    </div>
                </ContentArea>
            }
        </>
    );
}
