import { Component, useEffect, useState } from "react";
import { Chart } from "primereact/chart";

const LineChartData = () => {

   
    const [lineStylesData] = useState({
        labels: ['Jan23', 'Feb23', 'Mar23', 'Apr23', 'May23', 'Jun23', 'Jul23', "Aug23", "Sep23", "Oct23", "Nov23", "Dec23"],
        datasets: [
            {
                label: '',
                data: [12, 15, 50, 50, 20, 62, 45, 60, 40, 30, 70, 48],
                fill: true,
                borderColor: '#006ED0',
                tension: .0,
                background: 'linear-gradient(89.98deg, rgba(0, 110, 208, 0.1) 2.33%, #B9DEFF 99.97%);'
            }
            
        ]
        
    });


    const getLightTheme = () => {
        let basicOptions = {
            maintainAspectRatio: false,
            aspectRatio: .6,
            plugins: {
                legend: {
                    labels: {
                        color: '#495057'
                    },
                    display: false
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef',
                        display:false
                    }
                    
                },
                y: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef'
                    },
                    display:false
                }
            }
        };

        return {
            basicOptions,
          };
       
    } 
    const { basicOptions } = getLightTheme();

        return {
            basicOptions,
            lineStylesData
        }
    
}

export default LineChartData;