import React from 'react'
import ContentArea from "../../../../portal/account-owner/shared/ContentArea";
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import ViewLeads from './ViewLeads';
import { viewLeads, editLeads, getCarrier } from '../../../../utils/action';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import { useEffect } from 'react';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { validEmail } from '../../../../components/Validation';
import { Dropdown } from "primereact/dropdown";
import { Card } from 'primereact/card';
import { Calendar } from 'primereact/calendar';
import { InputTextarea } from 'primereact/inputtextarea';
import Table from '../../../../components/TableList/Table';
toast.configure();
export default function EditLeads() {
    const { id } = useParams()
    const navigate = useNavigate()
    const [carrier, setCarrier] = useState("")
    const [selectedCarrier, setSelectedCarrier] = useState(null)
    const [selectedCompanyCarrier, setSelectedCompanyCarrier] = useState(null)
    const [addNotes, setAddNotes] = useState({
        date: "",
        note: "",
        name: ""
    })
    const [selectStatus, setSelectStatus] = useState(null)
    const [update, setUpdate] = useState(false)
    const [value, setValue] = useState({
        first_name: "",
        middle_name: "",
        last_name: "",
        email: "",
        phone_number: "",
        carrier: "",
        address_1: "",
        address_2: "",
        city: "",
        state: "",
        zipcode: "",
        company_name: "",
        company_email: "",
        company_mobile: "",
        company_alt_mobile: "",
        company_carrier: "",
        company_address_1: "",
        company_address_2: "",
        comapny_carrier: "",
        company_city: "",
        company_state: "",
        company_zipcode: "",
        social_security_no: "",
        lead_id: "",
        lead_status: "",
        notes_data: [],
        add_notes: ""
    })
    const [storeIndex, setStoreIndex] = useState("")
    const [getIndex, setGetIndex] = useState("")
    const [tableData, setTableData] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const [editButton, setEditButton] = useState(false)
    const [error, setError] = useState("")
    const handleView = async () => {
        setIsLoading(true)
        let response = await viewLeads(id)
        setValue(response.data)
        setTableData(response?.data?.lead_note)
        setIsLoading(false)
    }
    useEffect(() => {
        handleView()
    }, [])
    const handleValueChange = (e) => {
        setValue({
            ...value,
            [e?.target?.name]: e?.target?.value,
        });
        setError({
            ...error,
            [e?.target?.name]: "",
        });
    };
    const TableHeading = [
        { field: "SrNo", header: "Sr. No.", body: "srnoTemplates" },
        { field: "date", header: "Date", sortable: true, body: "addLeadsDateTemplate" },
        { field: "note", header: "Note", sortable: true },
        { field: "icons", header: "Action", body: "AddLeadsBodyTemplate" },
    ];
    const handleSubmit = async () => {
        setIsLoading(true)
        try {
            if (value.first_name === "" || value.last_name === "" || value.email === "" || value.phone_number === null || value.carrier === ""
                || value.address_1 === "" || value.city === "" || value.state === "" || value.zipcode === null || value.company_name === ""
                || value.company_email === "" || value.company_mobile === null || value.company_alt_mobile === null || value.company_carrier === "" || value.company_address_1 === ""
                || value.company_city === "" || value.company_state === "" || value.company_zipcode === null || value.social_security_no === "" || !validEmail(value.email) || !validEmail(value.company_email) || value.lead_note?.length === 0) {
                setError({
                    ...error,
                    // lead_id: value.lead_id === "" ? "Please Enter Lead Id" : error.lead_id,
                    first_name: value.first_name === "" ? "Please Enter First Name" : error.first_name,
                    last_name: value.last_name === "" ? "Please Enter Last Name" : error.last_name,
                    email: value.email === "" ? "Please Enter Email" : !validEmail(value.email) ? "Please Enter Valid Email" : error.email,
                    carrier: value.carrier === "" ? "Please Enter Carrier" : error.carrier,
                    phone_number: value.phone_number === null ? "Please Enter Phone Number" : error.phone_number,
                    address_1: value.address_1 === "" ? "Please Enter Address" : error.address_1,
                    city: value.city === "" ? "Please Enter City" : error.city,
                    state: value.state === "" ? "Please Enter State" : error.state,
                    zipcode: value.zipcode === null ? "Please Enter Zip Code" : error.zipcode,
                    company_name: value.company_name === "" ? "Please Enter Company Name" : error.company_name,
                    company_email: value.company_email === "" ? "Please Enter Company Email" : !validEmail(value.company_email) ? "Please Enter Valid Email" : error.company_email,
                    company_mobile: value.company_mobile === null ? "Please Enter Company Mobile" : error.company_mobile,
                    company_carrier: value.company_carrier === "" ? "Please Enter Company Carrier" : error.company_carrier,
                    company_alt_mobile: value.company_alt_mobile === null ? "Please Enter  Mobile Number" : error.company_alt_mobile,
                    company_address_1: value.company_address_1 === "" ? "Please Enter Company Address" : error.company_address_1,
                    company_city: value.company_city === "" ? "Please Enter Company City" : error.company_city,
                    company_state: value.company_state === "" ? "Please Enter Company State" : error.company_state,
                    company_zipcode: value.company_zipcode === null ? "Please Enter Company Zip Code" : error.company_zipcode,
                    lead_note: value.lead_note?.length === 0 ? "Please Add Notes" : error?.lead_note,
                })
                setIsLoading(false)
                return false
            }
            const formData = new FormData()
            formData.append("first_name", value.first_name)
            formData.append("middle_name", value.middle_name)
            formData.append("last_name", value.last_name)
            formData.append("email", value.email)
            formData.append("phone_number", value.phone_number)
            formData.append("carrier", value.carrier)
            formData.append("address_1", value.address_1)
            formData.append("address_2", value.address_2)
            formData.append("city", value.city)
            formData.append("state", value.state)
            formData.append("zipcode", value.zipcode)
            formData.append("company_name", value.company_name)
            formData.append("company_email", value.company_email)
            formData.append("company_mobile", value.company_mobile)
            formData.append("company_alt_mobile", value.company_alt_mobile)
            formData.append("company_carrier", value.company_carrier)
            formData.append("company_address_1", value.company_address_1)
            formData.append("company_address_2", value.company_address_2)
            formData.append("company_city", value.company_city)
            formData.append("company_state", value.company_state)
            formData.append("company_zipcode", value.company_zipcode)
            formData.append("social_security_no", value.social_security_no)
            formData.append("status", value.lead_status)
            formData.append("add_notes", value.add_notes)
            formData.append("notes_data", JSON.stringify(value?.lead_note))
            let response = await editLeads({ payload: formData, id: id })
            if (response.status === false) {
                setIsLoading(false)
                if (response?.message === "The email has already been taken.") {
                    setError({
                        ...error,
                        email: response?.message
                    })
                    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                    return false;
                }
                else if (response?.message === "The company email has already been taken.") {
                    setError({
                        ...error,
                        company_email: response?.message
                    })
                    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                    return false;
                } else {
                    toast.error(response.message || "Something Wrong")
                }
            }
            else if (response.status === true) {
                setIsLoading(false)
                toast.success(response.message || "Add Lead")
                navigate("/sales-person/manage-leads")
                return true;
            }
        } catch (err) {
            setIsLoading(false)
            toast.error(err?.response?.data?.message);
            return false;
        }
    }
    const getCarrierData = async () => {
        async function fetchMyAPI() {
            const response = await getCarrier();
            setCarrier(response.data)
        }
        fetchMyAPI();
    }
    const handleCarrier = (e) => {
        setSelectedCarrier(e.value)
        setValue({
            ...value,
            carrier: e.value.id,
        })
    }
    const handleCompanyCarrier = (e) => {
        setSelectedCompanyCarrier(e.value)
        setValue({
            ...value,
            company_carrier: e.value.id
        })
    }
    useEffect(() => {
        getCarrierData()
    }, [])
    useEffect(() => {
        carrier.length > 0 && carrier.map((item) => {
            if (item.id == value?.carrier) {
                setSelectedCarrier(item)
            }
            if (item.id == value.company_carrier) {
                setSelectedCompanyCarrier(item)
            }

        })
    }, [carrier]);
    const handleAddNotes = () => {
        let array = []
        array.push(addNotes)
        setTableData([...tableData, ...array])
        setAddNotes({
            date: "",
            note: ""
        })
    }
    useEffect(() => {
        let payload = []
        addNotes?.length > 0 && addNotes?.map((item) => {
            let object = {
                date: item?.date,
                note: item?.note
            }
            payload.push(object)
        })
        setValue({
            ...value,
            notes_data: payload
        })
    }, [addNotes])
    const status = [
        { name: 'intial_contact', code: 'NY' },
        { name: 'follow_up', code: 'RM' },
        { name: 'not_interested', code: 'LDN' },
        { name: 'sold', code: 'IST' },
    ];
    const handleStatus = (e) => {
        setSelectStatus(e.value)
        setValue({
            ...value,
            lead_status: e.value.name,
        })
        setError({
            ...error,
            lead_status: ""
        })
    }
    useEffect(() => {
        status?.map((item) => {
            if (item?.name == value.lead_status) {
                setSelectStatus(item)
            }
        })
    }, [value])

    useEffect(() => {
        setValue({
            ...value,
            lead_note: tableData
        })
    }, [tableData])
    useEffect(() => {
        if (value?.lead_note?.length === 0) {
            setError({
                ...error,
                lead_note: ""
            })
        }
    }, [value])
    const handleCancel = () => {
        setEditButton(false)
        setAddNotes({
            date: "",
            note: ""
        })
    }
    const statusOptionTemplates = (option) => {
        return (
            <div style={option.name === "follow_up" ? { color: "#F9AE2B" } : option.name === "intial_contact" ? { color: "#006ED0" } : option.name === "not_interested" ? { color: "#FF0000" } : option.name === "sold" ? { color: "#0C8D00" } : { color: "black" }}>
                {option.name}
            </div>
        )
    }
    const handleUpdate = () => {
        let newArray = []
        newArray = tableData.map((item, i) => {
            if (getIndex === i) {
                return { ...addNotes };
            } else {
                return item;
            }
        });
        setTableData(newArray)
        setEditButton(false)
        setAddNotes({
            date: "",
            note: ""
        })
    }
    return (
        <>
            <>
                {isLoading === true ? <ProgressSpinner style={{ width: '50px', height: '50px' }} className='text-center h-screen flex justify-content-center' strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" /> :
                    <ContentArea>
                        <div className="p-3">

                            <div className="grid card ml-0 mr-0 px-5 mb-5 align-items-center bg-main-light border-round-lg">
                                <h2 className="text-main">
                                    Edit Leads
                                </h2>
                            </div>
                            <div className="shadow-2 p-5 border-round-lg">
                                <h2 className="text-main mb-5 p-0 m-0 Karla font-bold " style={{fontSize:"22px"}}>Leads Details</h2>
                                <div className="p-fluid grid mb-3">
                                    <div className="col-12 md:col-4 mb-3 ">
                                        <label className="font block mb-2">
                                            Lead Id<code className="text-red">*</code>
                                        </label>
                                        <InputText
                                            id=""
                                            className="border-round-md "
                                            name="lead_id"
                                            value={value?.lead_id}
                                            disabled
                                        />

                                    </div>
                                    <div className=" col-12 md:col-4 mb-3">
                                        <label className="font block mb-2">
                                            First Name<code className="text-red">*</code>
                                        </label>
                                        <InputText
                                            id=""
                                            className="border-round-md "
                                            name="first_name"
                                            placeholder=''
                                            value={value?.first_name}
                                            onChange={(e) => handleValueChange(e)}
                                        />
                                        {error?.first_name && <span className='error'>{error?.first_name}</span>}
                                    </div>
                                    <div className="col-12 md:col-4 mb-3 ">
                                        <label className="font block mb-2">Middle Name</label>
                                        <InputText
                                            id=""
                                            className="border-round-md "
                                            name="middle_name"
                                            placeholder=''
                                            value={value?.middle_name}
                                            onChange={(e) => handleValueChange(e)}
                                        />
                                    </div>

                                    <div className="col-12 md:col-4 mb-3">
                                        <label className="font block mb-2">
                                            Last Name<code className="text-red">*</code>
                                        </label>
                                        <InputText
                                            id=""
                                            className="border-round-md "
                                            name="last_name"
                                            placeholder=''
                                            onChange={(e) => handleValueChange(e)}
                                            value={value?.last_name}
                                        />
                                        {error?.last_name && <span className='error'>{error?.last_name}</span>}
                                    </div>
                                    <div className="col-12 md:col-4 mb-3">
                                        <label className="font block mb-2">
                                            Email<code className="text-red">*</code>
                                        </label>
                                        <InputText
                                            id=""
                                            className="border-round-md "
                                            name="email"
                                            placeholder=''
                                            onChange={(e) => handleValueChange(e)}
                                            value={value?.email}
                                        />
                                        {error?.email && <span className='error'>{error?.email}</span>}
                                    </div>
                                    <div className="col-12 md:col-4 mb-3 ">
                                        <label className="font block mb-2">
                                            Carrier<code className="text-red">*</code>
                                        </label>
                                        <Dropdown className="border-round-md " name="carrier" options={carrier} optionLabel="carrier_name" value={selectedCarrier} onChange={(e) => handleCarrier(e)} />
                                        {error?.carrier && <span className='error'>{error?.carrier}</span>}
                                    </div>

                                    <div className="col-12 md:col-4 mb-3">
                                        <label className="font block mb-2">
                                            Phone No.<code className="text-red">*</code>
                                        </label>
                                        <InputNumber
                                            id=""
                                            inputClassName='border-round-md '
                                            className="border-round-md "
                                            name="phone_number"
                                            placeholder=''
                                            useGrouping={false}
                                            maxLength={10}
                                            value={value?.phone_number}
                                            onValueChange={(e) => {
                                                setError({
                                                    ...error,
                                                    phone_number: null,
                                                });
                                                setValue({
                                                    ...value,
                                                    phone_number: e.value,
                                                });
                                            }}
                                        />
                                        {error?.phone_number && <span className='error'>{error?.phone_number}</span>}
                                    </div>
                                    <div className="col-12 md:col-4 mb-3">
                                        <label className="font block mb-2">
                                            Social Security No.
                                        </label>
                                        <InputNumber
                                            id=""
                                            inputClassName='border-round-md '
                                            className="border-round-md "
                                            name="social_security_no."
                                            placeholder=''
                                            useGrouping={false}
                                            value={value?.social_security_no === "null" ? "" : value?.social_security_no}
                                            onValueChange={(e) => {
                                                setError({
                                                    ...error,
                                                    social_security_no: null
                                                })
                                                setValue({
                                                    ...value,
                                                    social_security_no: e.target.value,
                                                });
                                            }}
                                        />
                                    </div>
                                    <div className="col-12 md:col-4 mb-3">
                                        <label className="font block mb-2">
                                            Status
                                        </label>
                                        <Dropdown className='border-round-md' placeholder="Select" options={status} optionLabel='name' value={selectStatus} onChange={(e) => handleStatus(e)} itemTemplate={statusOptionTemplates} />
                                    </div>
                                    <div className="col-12 md:col-12 mb-3">
                                        <label className="font block mb-2">
                                            Add Note
                                        </label>
                                        <InputTextarea className='border-round-md' rows={5} cols={5} value={value?.add_notes} name="add_notes" onChange={(e) => handleValueChange(e)} />
                                    </div>
                                    <div className='col-12 md:col-12'>
                                        <h2 className="text-main mb-3 p-0 m-0 Karla " style={{fontSize:"22px"}}>Address Details</h2>
                                    </div>
                                    <div className="col-12 md:col-6 mb-3 ">
                                        <label className="font block mb-2">
                                            Address 1<code className="text-red">*</code>
                                        </label>
                                        <InputText
                                            id=""
                                            className="border-round-md "
                                            name="address_1"
                                            placeholder=''
                                            value={value?.address_1}
                                            onChange={(e) => handleValueChange(e)}
                                        />
                                        {error?.address_1 && <span className='error'>{error?.address_1}</span>}
                                    </div>

                                    <div className="col-12 md:col-6 mb-3 ">
                                        <label className="font block mb-2">
                                            Address 2
                                        </label>
                                        <InputText
                                            id=""
                                            className="border-round-md "
                                            name="address_2"
                                            placeholder=''
                                            value={value?.address_2 === "null" ? "" : value?.address_2}
                                            onChange={(e) => handleValueChange(e)}
                                        />
                                    </div>

                                    <div className="col-12 md:col-4 mb-3 ">
                                        <label className="font block mb-2">
                                            City<code className="text-red">*</code>
                                        </label>
                                        <InputText
                                            id=""
                                            className="border-round-md "
                                            name="city"
                                            placeholder=''
                                            value={value?.city}
                                            onChange={(e) => handleValueChange(e)}
                                        />
                                        {error?.city && <span className='error'>{error?.city}</span>}
                                    </div>

                                    <div className="col-12 md:col-4 mb-3 ">
                                        <label className="font block mb-2">
                                            State<code className="text-red">*</code>
                                        </label>
                                        <InputText
                                            id=""
                                            className="border-round-md "
                                            name="state"
                                            placeholder=''
                                            value={value?.state}
                                            onChange={(e) => handleValueChange(e)}
                                        />
                                        {error?.state && <span className='error'>{error?.state}</span>}
                                    </div>

                                    <div className="col-12 md:col-4 mb-3">
                                        <label className="font block mb-2">
                                            Zip Code<code className="text-red">*</code>
                                        </label>
                                        <InputNumber
                                            id=""
                                            inputClassName='border-round-md '
                                            className="border-round-md "
                                            name="zipcode"
                                            maxLength={5}
                                            placeholder=''
                                            value={value?.zipcode}
                                            useGrouping={false}
                                            onValueChange={(e) => {
                                                setError({
                                                    ...error,
                                                    zipcode: null
                                                })
                                                setValue({
                                                    ...value,
                                                    zipcode: e.value,
                                                });
                                            }}
                                        />
                                        {error?.zipcode && <span className='error'>{error.zipcode}</span>}
                                    </div>


                                    <div className='col-12 md:col-12'>
                                        <h2 className="text-main mb-3 p-0 m-0 Karla font-bold" style={{fontSize:"22px"}}>Company Details</h2>
                                    </div>

                                    <div className="col-12 md:col-4 mb-3 ">
                                        <label className="font block mb-2">
                                            Company Name<code className="text-red">*</code>
                                        </label>
                                        <InputText
                                            id=""
                                            className="border-round-md "
                                            name="company_name"
                                            placeholder=''
                                            value={value?.company_name}
                                            onChange={(e) => handleValueChange(e)}
                                        />
                                        {error?.company_name && <span className='error'>{error?.company_name}</span>}
                                    </div>

                                    <div className="col-12 md:col-4 mb-3 ">
                                        <label className="font block mb-2">
                                            Company Email<code className="text-red">*</code>
                                        </label>
                                        <InputText
                                            id=""
                                            className="border-round-md "
                                            name="company_email"
                                            placeholder=''
                                            value={value?.company_email}
                                            onChange={(e) => handleValueChange(e)}
                                        />
                                        {error?.company_email && <span className='error'>{error?.company_email}</span>}
                                    </div>

                                    <div className="col-12 md:col-4 mb-3">
                                        <label className="font block mb-2">
                                            Company Contact No.<code className="text-red">*</code>
                                        </label>
                                        <InputNumber
                                            id=""
                                            inputClassName='border-round-md '
                                            className="border-round-md "
                                            name="company_mobile"
                                            placeholder=''
                                            useGrouping={false}
                                            maxLength={10}
                                            value={value?.company_mobile}
                                            onValueChange={(e) => {
                                                setError({
                                                    ...error,
                                                    company_mobile: null
                                                })
                                                setValue({
                                                    ...value,
                                                    company_mobile: e.value,
                                                });
                                            }}
                                        />
                                        {error?.company_mobile && <span className='error'>{error?.company_mobile}</span>}
                                    </div>

                                    <div className="col-12 md:col-6 mb-3 ">
                                        <label className="font block mb-2">
                                            Carrier<code className="text-red">*</code>
                                        </label>
                                        <Dropdown className="border-round-md " name="carrier" options={carrier} optionLabel="carrier_name" value={selectedCompanyCarrier} onChange={(e) => handleCompanyCarrier(e)} />
                                        {error?.company_carrier && <span className='error'>{error?.company_carrier}</span>}
                                    </div>

                                    <div className="col-12 md:col-6 mb-3">
                                        <label className="font block mb-2">
                                            Phone No.<code className="text-red">*</code>
                                        </label>
                                        <InputNumber
                                            id=""
                                            inputClassName='border-round-md '
                                            className="border-round-md "
                                            name="company_alt_mobile"
                                            maxLength={10}
                                            placeholder=''
                                            useGrouping={false}
                                            value={value?.company_alt_mobile}
                                            onValueChange={(e) => {
                                                setError({
                                                    ...error,
                                                    company_alt_mobile: null
                                                })
                                                setValue({
                                                    ...value,
                                                    company_alt_mobile: e.value,
                                                });
                                            }}
                                        />
                                        {error?.company_alt_mobile && <span className='error'>{error?.company_alt_mobile}</span>}
                                    </div>

                                    <div className="col-12 md:col-6 mb-3 ">
                                        <label className="font block mb-2">
                                            Address 1<code className="text-red">*</code>
                                        </label>
                                        <InputText
                                            id=""
                                            className="border-round-md "
                                            name="company_address_1"
                                            onChange={(e) => handleValueChange(e)}
                                            placeholder=''
                                            value={value?.company_address_1}
                                        />
                                        {error?.company_address_1 && <span className='error'>{error?.company_address_1}</span>}
                                    </div>

                                    <div className="col-12 md:col-6 mb-3 ">
                                        <label className="font block mb-2">
                                            Address 2
                                        </label>
                                        <InputText
                                            id=""
                                            className="border-round-md "
                                            name="company_address_2"
                                            onChange={(e) => handleValueChange(e)}
                                            placeholder=''
                                            value={value?.company_address_2 === "null" ? "" : value?.company_address_2}
                                        />
                                    </div>

                                    <div className="col-12 md:col-4 mb-3 ">
                                        <label className="font block mb-2">
                                            City<code className="text-red">*</code>
                                        </label>
                                        <InputText
                                            id=""
                                            className="border-round-md "
                                            name="company_city"
                                            onChange={(e) => handleValueChange(e)}
                                            placeholder=''
                                            value={value?.company_city}
                                        />
                                        {error?.company_city && <span className='error'>{error?.company_city}</span>}
                                    </div>

                                    <div className="col-12 md:col-4 mb-3 ">
                                        <label className="font block mb-2">
                                            State<code className="text-red">*</code>
                                        </label>
                                        <InputText
                                            id=""
                                            className="border-round-md "
                                            name="company_state"
                                            onChange={(e) => handleValueChange(e)}
                                            placeholder=''
                                            value={value?.company_state}
                                        />
                                        {error?.company_state && <span className='error'>{error?.company_state}</span>}
                                    </div>

                                    <div className="col-12 md:col-4 mb-3">
                                        <label className="font block mb-2">
                                            Zip Code<code className="text-red">*</code>
                                        </label>
                                        <InputNumber
                                            id=""
                                            inputClassName='border-round-md '
                                            className="border-round-md "
                                            name="company_zipcode."
                                            useGrouping={false}
                                            maxLength={5}
                                            placeholder=''
                                            value={value?.company_zipcode}
                                            onValueChange={(e) => {
                                                setError({
                                                    ...error,
                                                    company_zipcode: null,
                                                });
                                                setValue({
                                                    ...value,
                                                    company_zipcode: e.value,
                                                });
                                            }}
                                        />
                                        {error?.company_zipcode && <span className='error'>{error?.company_zipcode}</span>}
                                    </div>
                                    <div className="  w-full  p-2">
                                        <Card className='shadow-1 border-round-md'>

                                            <p className='text-xl text-main font-bold' style={{fontSize:"22px"}}>Follow Up Notes</p>

                                            <div className='calender_picker mb-5 w-5'>
                                                <label className='mb-2'>Date</label>
                                                <Calendar inputClassName=' border-round-md mt-2' className='notes_calender_picker ' name="date" value={addNotes.date} onChange={(e) => {
                                                    setAddNotes({
                                                        ...addNotes,
                                                        date: e.value
                                                    })
                                                }} />
                                            </div>
                                            <div className="mb-5">
                                                <label>Note</label>
                                                <InputTextarea className='mt-2 border-round-md' rows={5} cols={5} name="note" value={addNotes.note} onChange={(e) => {
                                                    setAddNotes({
                                                        ...addNotes,
                                                        note: e.target.value
                                                    })
                                                }} />
                                            </div>
                                            {error?.lead_note && <span className='error'>{error?.lead_note}</span>}
                                            <div className='btn'>
                                                {editButton === true ?
                                                    <Button
                                                        className="save_button text-center  border-0 text-lg font-medium karla p-3 border-round-lg bg-main"
                                                        label="Update"
                                                        onClick={() => handleUpdate()}
                                                    /> :
                                                    <Button
                                                        className=" save_button text-center  border-0 text-lg font-medium karla p-3 border-round-lg bg-main"
                                                        label="Add"
                                                        onClick={() => handleAddNotes()}
                                                    />
                                                }
                                                <Button
                                                    className="cancel_button  text-center border-0 p-3 ml-3 karla border-round-lg"
                                                    label="Cancel"
                                                    onClick={() => handleCancel()}
                                                />
                                            </div>

                                        </Card>
                                    </div>
                                    <div className='p-2' style={{ width: "100%" }}>
                                        <p className='text-main font-bold' style={{fontSize:"22px"}}>Follow Up Notes</p>
                                        <Table
                                            tableHeading={TableHeading}
                                            tableData={tableData}
                                            setGetIndex={setGetIndex}
                                            setTableData={setTableData}
                                            setEditButton={setEditButton}
                                            setAddNotes={setAddNotes} />
                                    </div>

                                </div>
                                <div className="grid program_table">

                                    <div className='col-12 md:col-12'>
                                        <Button
                                            label="Save"
                                            className=" save_button text-center  border-0 text-lg font-medium karla p-3 border-round-lg bg-main"
                                            onClick={() => handleSubmit()}
                                        />
                                        <Button
                                            label="Cancel"
                                            className="cancel_button  text-center border-0 p-3 ml-3 karla border-round-lg"
                                            onClick={() => navigate("/sales-person/manage-leads")}
                                        />
                                    </div>

                                </div>
                            </div>
                        </div>
                    </ContentArea>}
            </>
        </>
    );
};
