import React from "react";
import ContentArea from "../../../shared/ContentArea";
import Table from "../../../components/TableList/Table";
import SearchIcon from "../../../assets/icons/search.png"
import FilterIcon from "../../../assets/icons/filter.png"


const ClientCertificationData = () => {
    const TableData = [
        {
            Srno: "1",
            Name: "Tom Hanks",
            Certificate: "Physical",
            Certification: "20/10/2022",
            Status: "Inactive"

        },
        {
            Srno: "2",
            Name: "Tom Hanks",
            Certificate: "Physical",
            Certification: "20/10/2022",
            Status: "Inactive"
        },
        {
            Srno: "3",
            Name: "Tom Hanks",
            Certificate: "Physical",
            Certification: "20/10/2022",
            Status: "Inactive"
        },
        {
            Srno: "4",
            Name: "Tom Hanks",
            Certificate: "Physical",
            Certification: "20/10/2022",
            Status: "Inactive"
        },
        {
            Srno: "5",
            Name: "Tom Hanks",
            Certificate: "Physical",
            Certification: "20/10/2022",
            Status: "Inactive"
        },
        {
            Srno: "6",
            Name: "Tom Hanks",
            Certificate: "Physical",
            Certification: "20/10/2022",
            Status: "Inactive"
        },
        {
            Srno: "7",
            Name: "Tom Hanks",
            Certificate: "Physical",
            Certification: "20/10/2022",
            Status: "Inactive"
        },
        {
            Srno: "8",
            Name: "Tom Hanks",
            Certificate: "Physical",
            Certification: "20/10/2022",
            Status: "Inactive"
        },
        {
            Srno: "9",
            Name: "Tom Hanks",
            Certificate: "Physical",
            Certification: "20/10/2022",
            Status: "Inactive"
        },
        {
            Srno: "10",
            Name: "Tom Hanks",
            Certificate: "Physical",
            Certification: "20/10/2022",
            Status: "Inactive"
        },

    ]

    const TableHeading = [
        { field: "Srno", header: "Sr. No.", },
        { field: "Name", header: "Name", },
        { field: "Certificate", header: "Certificate name", },
        { field: "Certification", header: "Certification last date", },
        { field: "Status", header: "Status" },

    ];
    return (
        <div>
            <ContentArea>
                <div className="manage_employees_container p-3 ">

                    <div className="grid mb-5 mr-0 ml-0 align-items-center bg-main-light border-round-lg shadow-1 flex justify-content-around">
                        <div className="md:col-5 ">
                            <h1 className="text-main  " style={{ fontSize: "24px" }}>
                                Manage Client Certification
                            </h1>
                        </div>
                        <div className="md:col-3">
                            <div className="search-box w-full">
                                <img
                                    src={SearchIcon}
                                    alt="Search Icon"
                                    width="15px"
                                    height="15px"
                                />
                                <input type="search" placeholder="Search" />
                            </div>
                        </div>
                        <div className="md:col-1 relative bg-main px-3 border-round-md w-max ">

                            <div className="border-round-md text-white text-center text-base relative karla">
                                Filter &nbsp;
                                <img src={FilterIcon} alt="" width={15} /></div>


                        </div>
                    </div>

                    <div className="grid mt-2 mb-5">
                        <div className="md:col-12">

                            <Table tableHeading={TableHeading}
                                tableData={TableData}
                            />
                        </div>

                    </div>
                </div>
            </ContentArea>
        </div>
    )
}

export default ClientCertificationData;