import React, { useState, useEffect } from "react";
import ContentArea from "../shared/ContentArea";
import InputField from "../../../components/input/input";
import { Checkbox } from "primereact/checkbox";
import SaveButton from "../../../components/Buttons/SaveButton";
import CancelButton from "../../../components/Buttons/CancelButton";
import { Card } from "primereact/card";
import { useParams } from "react-router-dom";
import { Button } from "primereact/button";
import { validEmail } from "../../../components/Validation";
import { useNavigate } from "react-router-dom";
import InputNumberField from "../../../components/input/inputNumber";
import Loader from "../../../pages/Loader";
import DropdownField from "../../../components/input/dropdown";
import {
  viewServiceProviderAction,
  getServiceAction,
  editServiceProviderAction,
  viewManageService,
  editManageServiceProviderAction,
} from "../../../Redux/HospitalAction/serviceProviderAction";
import { carrierAction } from "../../../Redux/HospitalAction/carrierAction";
import { useDispatch, useSelector } from "react-redux";
import { viewServiceProvider } from "../../../utils/HospitalAction";
const EditProviders = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [checked, setChecked] = useState(false);
  const [Service, Setservice] = useState(null);
  const [carrier, SetCarrier] = useState(null);
  const [error, setError] = useState("");
  const [selectedCarrier, setSelectedCarrier] = useState(null);
  const [selectedService, setSelectedService] = useState(null);
  const [selectService, setSelectService] = useState("");
  const [selectCarrier, setSelectCarrier] = useState("");
  const [addService, setAddService] = useState([
    {
      service_name: "",
    },
  ]);
  const [data, setData] = useState({
    service_provider_name: "",
    email: "",
    phone_number: null,
    service: [],
    address: "",
    state: "",
    zip_code: null,
    service_type_id: "",
    carrier_id: "",
    is_sms_notification: 0,
  });
  const { id } = useParams();
  useEffect(() => {
    // dispatch(viewServiceProviderAction(id));
    dispatch(viewManageService(id));
    dispatch(getServiceAction());
    dispatch(carrierAction());
  }, []);
  const viewServiceData = useSelector(
    (state) => state.viewManageService.viewManageServiceProfile
  );
  const serviceDatalist = useSelector(
    (state) => state.getService?.getServiceProfile
  );
  const carrierData = useSelector((state) => state.carrier.carrierProfile);
  useEffect(() => {
    setData(viewServiceData);
    if (viewServiceData?.is_sms_notification === "1") {
      setChecked(true);
    }
    Setservice(serviceDatalist);
    SetCarrier(carrierData);
  }, [viewServiceData, serviceDatalist, carrierData]);
  useEffect(() => {
    Service?.map((item) => {
      if (viewServiceData?.service_type_id == item?.id) {
        setSelectedService(item);
      }
    });
  }, [Service, serviceDatalist, viewServiceData]);
  useEffect(() => {
    carrier?.map((item) => {
      if (viewServiceData?.carrier_id == item.id) {
        setSelectedCarrier(item);
      }
    });
  }, [carrier, viewServiceData, carrierData]);
  useEffect(() => {
    let array = [];
    if (viewServiceData?.service?.length !== 0) {
      viewServiceData?.service?.map((item) => {
        let obj = {
          service_name: item?.service_name,
        };
        array.push(obj);
      });
    }
    setAddService(array);
  }, [viewServiceData]);
  const handleChange = (e) => {
    setData({
      ...data,
      [e?.target?.name]: e?.target?.value,
    });
    setError({
      ...error,
      [e?.target?.name]: "",
    });
  };
  const handleNumberChange = (e) => {
    setData({
      ...data,
      [e.target?.name]: e?.value,
    });
    setError({
      ...error,
      [e.target?.name]: "",
    });
  };
  useEffect(() => {
    setData({
      ...data,
      carrier_id: selectCarrier?.id,
    });
    setError({
      ...error,
      carrier_id: null,
    });
  }, [selectCarrier]);
  useEffect(() => {
    setData({
      ...data,
      service_type_id: selectService?.id,
    });
    setError({
      ...error,
      service_type_id: null,
    });
  }, [selectService]);
  const handleService = (e) => {
    setSelectedService(e.value);
    setData({
      ...data,
      service_type_id: e.value.id,
    });
  };
  const handleCarrier = (e) => {
    setSelectedCarrier(e.value);
    setData({
      ...data,
      carrier_id: e.value?.id,
    });
  };
  useEffect(() => {
    if (checked === true) {
      setData({
        ...data,
        is_sms_notification: 1,
      });
    } else {
      setData({
        ...data,
        is_sms_notification: 0,
      });
    }
  }, [checked]);
  const handleAddNotes = () => {
    setAddService([...addService, { service_name: "" }]);
  };
  const handleremoveNotes = (index) => {
    if (addService.length > 1) {
      let data = addService.filter((item, i) => {
        return index !== i;
      });
      setAddService(data);
    }
  };
  const handleValueChange = (i, e) => {
    const { value } = e.target;
    let newData = addService?.map((item, index) => {
      if (index === i) {
        return {
          ...item,
          service_name: value,
        };
      } else {
        return item;
      }
    });
    setAddService(newData);
  };
  const handleSubmit = () => {
    if (
      data.service_provider_name === "" ||
      data.service_type_id === "" ||
      data.email === "" ||
      data.phone_number === null ||
      data.phone_number === undefined ||
      !validEmail(data.email)
    ) {
      setError({
        ...error,
        service_provider_name:
          data.service_provider_name === ""
            ? "Please Enter Service Provider Name"
            : error.service_provider_name,
        service_type_id:
          data.service_type_id === ""
            ? "Please Choose Service type"
            : error.service_type_id,
        email:
          data.email === ""
            ? "Please Enter Email"
            : !validEmail(data.email)
            ? "Please Enter Valid Email"
            : error.email,
        phone_number:
          data.phone_number === null || data.phone_number === undefined
            ? "Please Enter Phone Number"
            : error.phone_number,
      });
      return false;
    }
    const obj = data;
    let array = Object.entries(obj);

    const formData = new FormData();
    for (let i = 0; i < array.length; i++) {
      if (array[i][0] === "service") {
        formData.append(array[i][0], JSON.stringify(addService));
      } else {
        formData.append(array[i][0], array[i][1]);
      }
    }
    dispatch(
      editManageServiceProviderAction(id, formData, () =>
        navigate("/account-owner/manage-providers")
      )
    );
  };
  const isLoading = useSelector((state) => state.loader.isLoading);
  return (
    <>
      {isLoading === true ? (
        <Loader />
      ) : (
        <ContentArea>
          <div className="edit_provider_container">
            <div className="p-3">
              <div className="grid card ml-0 mr-0 px-5 mb-5 align-items-center bg-main-light border-round-lg">
                <h2 className="text-main">Edit Service Providers Details</h2>
              </div>
              <Card className="shadow-2 p-3 border-round-lg">
                <div className="p-fluid grid">
                  <div className="field col-12 md:col-6">
                    <InputField
                      label="Service Provider Name"
                      name="service_provider_name"
                      required
                      className="border-round-md mt-2"
                      value={data?.service_provider_name}
                      onChange={(e) => handleChange(e)}
                      errorMessage={error?.service_provider_name}
                    />
                  </div>

                  <div className="field col-12 md:col-6">
                    <DropdownField
                      label="Service Type"
                      className="border-round-md mt-2"
                      inputId="dropdown"
                      optionLabel="program_name"
                      placeholder="Select"
                      value={selectedService}
                      options={Service}
                      required
                      onChange={(e) => handleService(e)}
                      errorMessage={error?.service_type_id}
                    />
                  </div>
                  {addService.length > 0 &&
                    addService?.map((item, index) => {
                      return (
                        <div className="field col-12 md:col-4">
                          <label className="font block mb-2">
                            Service {index + 1}
                          </label>
                          <span className="p-input-icon-right">
                            <i
                              className="pi pi-times-circle"
                              onClick={() => handleremoveNotes(index)}
                            />
                            <InputField
                              name="service_1"
                              value={item?.service_name}
                              onChange={(e) => handleValueChange(index, e)}
                            />
                          </span>
                        </div>
                      );
                    })}

                  <div className="field col-12 md:col-12 text-main">
                    <Button
                      label="Add"
                      icon="pi pi-plus"
                      iconPos="right"
                      onClick={() => handleAddNotes()}
                      className=" save_button text-center w-max border-0 text-lg font-medium karla border-round-lg bg-main "
                    />
                  </div>

                  <div className="field col-12 md:col-12 text-main ">
                    <h2 className="p-0 m-0">Service Provider Address*</h2>
                  </div>

                  <div className="field col-12 md:col-4">
                    <InputField
                      name="address"
                      label=" Street Address"
                      value={data?.address === "null" ? "" : data?.address}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>

                  <div className="field col-12 md:col-4">
                    <InputField
                      name="state"
                      label="State"
                      value={data?.state === "null" ? "" : data?.state}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>

                  <div className="field col-12 md:col-4">
                    <InputNumberField
                      label="Zip Code"
                      name="zip_code"
                      useGrouping={false}
                      maxLength={5}
                      value={data?.zip_code === "null" ? "" : data?.zip_code}
                      onValueChange={(e) => handleNumberChange(e)}
                    />
                  </div>

                  <div className="field col-12 md:col-12 ">
                    <h2 className="text-main p-0 m-0">Contact No*</h2>
                  </div>

                  <div className="field col-12 md:col-4">
                    <InputField
                      name="email"
                      label="Email Address"
                      value={data?.email}
                      onChange={(e) => handleChange(e)}
                      required
                      errorMessage={error?.email}
                    />
                  </div>

                  <div className="field col-12 md:col-4">
                    <DropdownField
                      label="Select Carrier"
                      className="border-round-md mt-2"
                      placeholder="Select"
                      optionLabel="carrier_name"
                      value={selectedCarrier}
                      options={carrier}
                      onChange={(e) => handleCarrier(e)}
                    />
                  </div>

                  <div className="field col-12 md:col-4">
                    <InputNumberField
                      label="Phone Number"
                      name="phone_number"
                      useGrouping={false}
                      maxLength={10}
                      value={data?.phone_number}
                      required
                      onValueChange={(e) => handleNumberChange(e)}
                      errorMessage={error?.phone_number}
                    />
                  </div>
                  <div className="col-12 md:col-12 mb-3">
                    <Checkbox
                      checked={checked}
                      onChange={(e) => setChecked(e.checked)}
                    ></Checkbox>{" "}
                    &nbsp; Check to receive the SMS reminder notifications.
                  </div>
                </div>
                <div className="col-12 md:col-4 mt-1">
                  <div className="submitButton flex mt-3">
                    <SaveButton
                      label="Save"
                      onClick={handleSubmit}
                    ></SaveButton>
                    <CancelButton
                      label="Cancel"
                      onClick={() =>
                        navigate("/account-owner/manage-providers")
                      }
                    ></CancelButton>
                  </div>
                </div>
              </Card>
            </div>
          </div>
        </ContentArea>
      )}
    </>
  );
};

export default EditProviders;
