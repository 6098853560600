import React, { useEffect, useState } from "react";
import ProfileImage from "./../../../assets/images/Profilepic.png";
import EditImg from "./../../../assets/images/edit.png";
import EditProfile from "./EditProfile";
import ResetPassword from "./ResetPassword";
import { useDispatch, useSelector } from "react-redux";
import { ClientProfileData } from "../../../Redux/HospitalAction/appoinmentAction";
import HospitalConstants from "../../../Services/HospitalConstants";

const MyProfile = () => {
  const [data, setData] = useState({
    first_name: "",
    middle_name: "",
    last_name: "",
    mobile: "",
    carrier: "",
    address_1: "",
    address_2: "",
    city: "",
    social_security: "",
    email: "",
    dob: "",
    state: "",
    zipcode: "",
    image: "",
  });

  const dispatch = useDispatch();
  const [showEdit, setShowEdit] = useState(false);
  const [ShowResetP, setShowResetP] = useState(false);
  const ProfileDatalist = useSelector(
    (state) => state?.ClientProfile?.ClientProfileData
  );
  useEffect(() => {
    dispatch(ClientProfileData());
  }, []);

  return (
    <>
      {showEdit ? (
        <EditProfile
          setShowEdit={setShowEdit}
          ProfileDatalist={ProfileDatalist}
          data={data}
          setData={setData}
        />
      ) : ShowResetP ? (
        <ResetPassword setShowResetP={setShowResetP} />
      ) : (
        <div className=" p-5">
          <div className="heading">My Profile</div>
          <div className="col-12 flex flex-wrap profile-content p-5 mt-5 ">
            <div className="col-3 p-0">
              <img
                src={
                  // ProfileDatalist?.image
                  //   ? HospitalConstants.BASE_URL + ProfileDatalist?.image
                  // :
                  ProfileImage
                }
                alt=""
                style={{
                  width: "200px",
                  height: "200px",
                  borderRadius: "10px",
                }}
              />
            </div>
            <div className="col-8 mx-auto ">
              <div className="flex  justify-content-between align-items-center">
                <h2 className="text-main text-28">John Bride</h2>
                <div
                  className="cursor-pointer"
                  onClick={() => {
                    setShowEdit(true);
                    setData({
                      social_security_no:
                        ProfileDatalist?.social_security_no !== "null"
                          ? ProfileDatalist?.social_security_no
                          : "",
                      email:
                        ProfileDatalist?.email !== "null"
                          ? ProfileDatalist?.email
                          : "",
                      dob:
                        ProfileDatalist?.dob !== "null"
                          ? ProfileDatalist?.dob
                          : "",
                      address_2:
                        ProfileDatalist?.address_2 !== "null"
                          ? ProfileDatalist?.address_2
                          : "",
                      city:
                        ProfileDatalist?.city !== "null"
                          ? ProfileDatalist?.city
                          : "",
                      first_name:
                        ProfileDatalist?.first_name !== "null"
                          ? ProfileDatalist?.first_name
                          : "",
                      middle_name:
                        ProfileDatalist?.middle_name !== "null"
                          ? ProfileDatalist?.middle_name
                          : "",
                      last_name:
                        ProfileDatalist?.last_name !== "null"
                          ? ProfileDatalist?.last_name
                          : "",
                      mobile:
                        ProfileDatalist?.mobile !== "null"
                          ? ProfileDatalist?.mobile
                          : "",
                      carrier: ProfileDatalist?.carrier,
                      address_1: ProfileDatalist?.address_1,
                      state: ProfileDatalist?.state,
                      zipcode: ProfileDatalist?.zipcode,
                    });
                  }}
                >
                  <img src={EditImg} height={20} alt="" />
                </div>
              </div>
              <div className="flex align-items-center ">
                <h2 className="text-lg my-2 font-mediumn col ">Email</h2>{" "}
                <small className="text-lg font-mediumn  text-lightgreen col ">
                  {ProfileDatalist?.email}
                </small>
              </div>
              <div className="flex align-items-center ">
                <h2 className="text-lg my-2 font-mediumn col ">First Name</h2>{" "}
                <small className="text-lg font-mediumn  text-lightgreen col ">
                  {ProfileDatalist?.first_name}
                </small>
              </div>
              <div className="flex align-items-center ">
                <h2 className="text-lg my-2 font-mediumn col ">Middle Name</h2>{" "}
                <small className="text-lg font-mediumn  text-lightgreen col ">
                  {ProfileDatalist?.middle_name}
                </small>
              </div>
              <div className="flex align-items-center ">
                <h2 className="text-lg my-2 font-mediumn col ">Last Name</h2>{" "}
                <small className="text-lg font-mediumn  text-lightgreen col ">
                  {ProfileDatalist?.last_name}
                </small>
              </div>
              <div className="flex align-items-center ">
                <h2 className="text-lg my-2 font-mediumn col ">DOB</h2>{" "}
                <small className="text-lg font-mediumn  text-lightgreen col ">
                  {ProfileDatalist?.dob.slice(3, 16)}
                </small>
              </div>
              <div className="flex align-items-center ">
                <h2 className="text-lg my-2 font-mediumn col">Phone Number</h2>{" "}
                <small className="text-lg font-mediumn text-lightgreen col ">
                  {ProfileDatalist?.mobile}
                </small>
              </div>
              <div className="flex align-items-center ">
                <h2 className="text-lg my-2 font-mediumn col">Carrier</h2>{" "}
                <small className="text-lg font-mediumn text-lightgreen col">
                  {ProfileDatalist?.carrier}
                </small>
              </div>
              <div className="flex align-items-center ">
                <h2 className="text-lg my-2 font-mediumn col">City</h2>{" "}
                <small className="text-lg font-mediumn text-lightgreen col">
                  {ProfileDatalist?.city}
                </small>
              </div>
              <div className="flex align-items-center ">
                <h2 className="text-lg my-2 font-mediumn col">Address 1</h2>{" "}
                <small className="text-lg font-mediumn text-lightgreen col">
                  {ProfileDatalist?.address_1}
                </small>
              </div>
              <div className="flex align-items-center ">
                <h2 className="text-lg my-2 font-mediumn col">Address 2</h2>{" "}
                <small className="text-lg font-mediumn text-lightgreen col">
                  {ProfileDatalist?.address_2 !== "null"
                    ? ProfileDatalist?.address_2
                    : ""}
                </small>
              </div>
              <div className="flex align-items-center ">
                <h2 className="text-lg my-2 font-mediumn col">State</h2>{" "}
                <small className="text-lg font-mediumn text-lightgreen col">
                  {ProfileDatalist?.state}
                </small>
              </div>
              <div className="flex align-items-center ">
                <h2 className="text-lg my-2 font-mediumn col">Pin Code</h2>{" "}
                <small className="text-lg font-mediumn text-lightgreen col">
                  {ProfileDatalist?.zipcode}
                </small>
              </div>
              <div className="flex align-items-center ">
                <h2 className="text-lg my-2 font-mediumn col">
                  Social Security No
                </h2>{" "}
                <small className="text-lg font-mediumn text-lightgreen col">
                  {ProfileDatalist?.social_security_no}
                </small>
              </div>
              <div className="flex align-items-center ">
                <h2 className="text-lg my-2 font-mediumn col">
                  Change Password
                </h2>{" "}
                <small
                  className="text-lg font-mediumn text-red-800 col cursor-pointer"
                  onClick={() => setShowResetP(true)}
                >
                  Reset Password
                </small>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MyProfile;
