
import React, { useEffect, useState } from "react";
import AddButton from "../../../../components/Buttons/AddButton";
import ContentArea from "../../../../shared/ContentArea";
import SearchIcon from "../../../../assets/icons/search.png";
import Table from '../../../../components/TableList/Table';
import { useNavigate } from "react-router-dom";
import Index from '../../Index';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import Deleteimg from "../../../../assets/images/deleted.png";
import { certificationClientGetAction,certicationClientDelete} from "../../../../Redux/HospitalAction/certificationAction";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../../pages/Loader";
import { FilterMatchMode } from 'primereact/api';
import FilterIcon from "../../../../assets/icons/filter.png";
import { Calendar } from "primereact/calendar";
const ClientCertification= () => {
  const dispatch = useDispatch()
  const [deleteButton, setDeleteButton] = useState(false)
  const [tableData, setTableData] = useState("")
  const { isNextPage, setIsNextPage } = Index();
  const [isShowFilter, setIsShowFilter] = useState(false);
  const[startDate,setStartDate] =  useState("")
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const [isFiltered, setIsFiltered] = useState(false);
  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const[data,setData] =  useState("")
  const [filterData, setFilterData] = useState({
    start_date: "",
    end_date: ""
  })
  const navigate = useNavigate()

  const AddIndividualCertification = () => {
    setIsNextPage(!isNextPage)
    navigate("/account-owner/add-certification")
  }
  useEffect(() => {
    dispatch(certificationClientGetAction())
  }, [])
  const getData = useSelector((state) => state.certificationClientgetList.certificationClientGetProfile)
  useEffect(() => {
    if(getData?.length !== 0){
      setTableData(getData)
    }else{
      setTableData("")
    }
  }, [getData])
  const TableHeading = [
    { field: "SrNo", header: "Sr. No.",body: "srnoTemplates" },
    { field: "client_name", header: "Name", },
    { field: "certificate_name", header: "Certificate Name",body:"certicationNameTemplate" },
    { field: "last_date", header: "Certification last date",body:"certicationLastDateTemplate" },
    { field: "status", header: "Status",body:"statusColorTemplate" },
    { field: "icons", header: "Action", body: "clientCertificaticonTemplate" },
  ];
  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters['global'].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };
  const clearFilter = () => {
    setIsFiltered(false)
    setIsShowFilter(false)
    dispatch(certificationClientGetAction())
  }
  const handleChange = (e) => {
    if (e.target.name === "start_date") {
      setStartDate(e.target.value)
    }
    let date = new Date(e.target.value)
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let dt = date.getDate();
    if (dt < 10) {
      dt = '0' + dt;
    }
    if (month < 10) {
      month = '0' + month;
    }
    let dob = dt + '-' + month + '-' + year;
    setFilterData({
      ...filterData,
      [e?.target?.name]: dob,
    });
  };
  const applyFilter = () => {
    setIsFiltered(true)
    setIsShowFilter(!isShowFilter)
    dispatch(certificationClientGetAction(filterData))
  }
  const id= data
  const isLoading = useSelector((state) => state.loader.isLoading)
  const handleDelete =  ()=>{
    dispatch(certicationClientDelete(id,()=>setDeleteButton(false))).then(()=>{
      dispatch(certificationClientGetAction())
    })
  }
  return (
    <>
  {isLoading === true ? <Loader/>:
      <ContentArea>
        <div className="manage_individual_cetification_container p-3 ">

           <div className="grid mb-5 mr-0 ml-0 align-items-center bg-main-light border-round-lg shadow-1 flex justify-content-around">
              <div className="md:col-5 ">
                <h1 className="text-main  " style={{ fontSize: "24px" }}>
                Manage Client Certification
                </h1>
              </div>
              <div className="md:col-3">
                <div className="search-box w-full">
                  <img
                    src={SearchIcon}
                    alt="Search Icon"
                    width="15px"
                    height="15px"
                  />
                  <input type="search" placeholder="Search" onChange={onGlobalFilterChange} onClick={() => setIsShowFilter(false)} />
                </div>
              </div>
              <div className="md:col-2 relative bg-main px-3 border-round-md w-max ">
                {
                  isFiltered ?
                    <div onClick={() => clearFilter()} className="bg-main border-round-md text-white text-center text-base relative karla">
                      Clear &nbsp;
                      <i className="pi pi-filter-slash"></i></div>
                    :
                    <div onClick={() => setIsShowFilter(prev => !prev)} className="border-round-md text-white text-center text-base relative karla">
                      Filter &nbsp;
                      <img src={FilterIcon} alt="" width={15} /></div>
                }
                {
                  isShowFilter ?
                    <div className="filter-popup">
                      <label className="font block mb-2">
                        Start Date<code className="text-red"></code>
                      </label>
                      <Calendar
                        className="border-round-md calender_picker "
                        inputClassName="calendar p-2 border-round-md "
                        placeholder="Select Date"
                        name="start_date"
                        onChange={(e) => handleChange(e)}
                      />
                      <label className="font block mb-2 mt-2">
                        End Date<code className="text-red"></code>
                      </label>
                      <Calendar
                        className="border-round-md calender_picker "
                        inputClassName="calendar p-2 border-round-md "
                        placeholder="Select Date"
                        name="end_date"
                        onChange={(e) => handleChange(e)}
                        minDate={startDate}
                      />
                      <div className="mt-3 flex justify-content-between">
                        <Button onClick={clearFilter}>Clear</Button>
                        <Button onClick={(e) => applyFilter(e)}>Apply</Button></div>
                    </div>
                    : null
                }
              </div>
              <div className="md:col-1 w-max ">
                <AddButton onClick={() => AddIndividualCertification()} />
              </div>
            </div>

          <div className="grid mt-2">
            <div className="md:col-12">

              <Table tableHeading={TableHeading}
                tableData={tableData}
                filters={filters}
                setFilters = {setFilters}
                setDeleteButton={setDeleteButton}
                setData={setData}
              />
            </div>

          </div>

          {
            deleteButton ?
              <Dialog
                className="table_delete_modal flex justify-content-center "
                visible={deleteButton}
                onHide={() => setDeleteButton(false)}
                style={{ width: '30vw' }}>
                <p className="m-0">
                  <div className=" flex justify-content-center">
                    <img src={Deleteimg} alt="" width={150} height={127}></img>
                  </div>
                  <div className="text-center mb-5">
                    <h4 className=" p-0 m-0 ">Are you sure you want to delete this user?</h4>
                    <p className="text-xs text-400">All associated data will also be deleted! There is no undo!</p>
                  </div>
                  <div className=" btn flex justify-content-center">
                    <div className="text-center">
                      <Button
                        label="Cancel"
                        className="w-full surface-500 p-3 px-5    border-0 border-round-md "
                        onClick={() => setDeleteButton(false)}
                      />
                    </div>
                    <div className="text-center">
                      <Button
                        label="Delete"
                        className=" w-full px-5 border-0 p-3 ml-3 border-round-md bg-red-500"
                        onClick={handleDelete}
                      />
                    </div>
                  </div>
                </p>
              </Dialog> : null
          }
        </div>
      </ContentArea>}
    </>
  )
}

export default ClientCertification;
