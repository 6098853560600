import { types } from "../HospitalType/types";

const intitalState = {
  clientProfileReducer: {},
};

export const ClientProfileReducer = (state = intitalState, action) => {
  switch (action.type) {
    case types.CLIENT_PROFILE_DATA:
      return {
        ...state,
        ClientProfileData: action.payload,
      };
    default: {
      return { ...state };
    }
  }
};
export const ClientEditProfileReducer = (state = intitalState, action) => {
  switch (action.type) {
    case types.CLIENT_PROFILE_EDIT_DATA:
      return {
        ...state,
        ClientEditProfileData: action.payload,
      };
    default: {
      return { ...state };
    }
  }
};
export const ClientPasswordResend = (state = intitalState, action) => {
  switch (action.type) {
    case types.CLIENT_PASSWORD_RESET:
      return {
        ...state,
        ClientResetPassword: action.payload,
      };
    default: {
      return { ...state };
    }
  }
};
