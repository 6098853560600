import React, { useRef } from "react";
import { Button } from "primereact/button";
import { Menu } from "primereact/menu";

export default function MenuDrop({ items, label, icon }) {
  const menuRight = useRef(null);
  return (
    <div className="card flex justify-content-center">
      <Menu model={items} popup ref={menuRight} id="popup_menu_left" />
      <Button
        label={label}
        icon={icon}
        iconPos="right"
        className="mr-2 border-round-md"
        onClick={(event) => menuRight.current.toggle(event)}
        aria-controls="popup_menu_right"
        aria-haspopup
      />
    </div>
  );
}
