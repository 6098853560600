

import React, { useState } from "react";
import ContentArea from "../../../../shared/ContentArea";
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import { Calendar } from "primereact/calendar";
import Table from '../../../../components/TableList/Table';
import { Card } from 'primereact/card';
import { Dropdown } from 'primereact/dropdown';
import TableAddButton from "./TableCountButton";
import { InputSwitch } from "primereact/inputswitch";
import { viewClientAction } from "../../../../utils/action";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { ProgressSpinner } from "primereact/progressspinner";

toast.configure();
export default function EditClients() {
    const [checked, setChecked] = useState(false);
    const [TableData, setTableData] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const [SubscriptionPlanTableData, setSubscriptionPlanTableData] = useState("")
    const [finalDiscount, setFinalDiscount] = useState()
    const [countPrice, setCountPrice] = useState(0)
    const [client, setClient] = useState(0)
    const [selectedDiscount, setSelectdDiscount] = useState(null);
    const [total, setTotal] = useState(0)
    const [selectedDiscountPrice, setSelectedDiscountPrice] = useState(0)
    useEffect(() => {
        let client = 0;
        let total = 0;
        let discount = 0;
        SubscriptionPlanTableData.length > 0 && SubscriptionPlanTableData?.map((item) => {
            client += item.number_of_client
            total += item.total
            discount = item.manage_discounts_id
        })
        setClient(client)
        setTotal(total)
        setSelectedDiscountPrice(discount)
        let final = total * selectedDiscountPrice / 100
        let totalCount = total - final
        setCountPrice(totalCount)
        setFinalDiscount(final)
    }, [SubscriptionPlanTableData, selectedDiscountPrice])

    // const Discount = [
    //     { name: 'Name' },
    //     { name: 'DD05', },
    //     { name: 'DD06', },
    //     { name: 'DD07', },
    //     { name: 'DD08', }
    // ];


    const [value, setValue] = useState("")
    const { id } = useParams()
    const handleSubmit = async () => {
        setIsLoading(true)
        try {
            let response = await viewClientAction(id)
            if (response.data.is_active) {
                setChecked(true);
            }
            response.data.dob = new Date(response.data.dob.slice(0, 10));
            setValue(response.data)
            setSubscriptionPlanTableData(response.data.subscription_details)
            toast.success(response?.data?.message)
            setIsLoading(false)
        } catch (err) {
            setIsLoading(false)
            toast.error(err?.response?.data?.message)
        }
    }
    useEffect(() => {
        handleSubmit()
    }, [])
    // const TableData = [
    //     {
    //         ProgramName: "Adult Day Program",
    //         PricePerClient: "$25",
    //         NoOfClient: CountButton,
    //         Total: "$475",

    //     },
    //     {
    //         ProgramName: "Home Health",
    //         PricePerClient: "$25",
    //         NoOfClient: CountButton,
    //         Total: "$475",
    //     },
    //     {
    //         ProgramName: "seniour Facility",
    //         PricePerClient: "$25",
    //         NoOfClient: CountButton,
    //         Total: "$0",

    //     },
    //     {
    //         ProgramName: "Education Program",
    //         PricePerClient: "$25",
    //         NoOfClient: CountButton,
    //         Total: "$0",

    //     },
    //     {
    //         ProgramName: "Grand Total",
    //         PricePerClient: "",
    //         NoOfClient: "40",
    //         Total: "$950",
    //     },
    //     {
    //         ProgramName: "Discount",
    //         PricePerClient: "",
    //         NoOfClient: "",
    //         Total: "$70",
    //     },
    //     {
    //         ProgramName: "Final Total",
    //         PricePerClient: "",
    //         NoOfClient: "",
    //         Total: "$880",

    //     },

    // ]

    const TableHeading = [
        { field: "subscription_plan.program_name", header: "Program Name" },
        { field: "subscription_plan.price_per_person", header: "Price Per Client" },
        { field: "number_of_client", header: "No. of Clients" },
        { field: "total", header: "Revenue" },
    ];

    function handleGrandTotal() {
        return (
            <>
                <div className="grid mt-2">
                    <div className="md:col-7 ">
                        <small className="karla ml-3">Grand Total</small>
                    </div>

                    <div className="md:col-2 ">
                        <small className="karla">{client}</small>
                    </div>
                 
                    <div className="md:col-2  text-center ">
                        <small>${total}</small>
                    </div>
                </div>
                <div className="grid  mt-2 bg-main-light">
                    <div className="md:col-9">
                        <small className="karla ml-3">Discount</small>
                    </div>
                  
                    
                    <div className="md:col-2 text-center">
                        <small>${finalDiscount ? finalDiscount : 0}</small>
                    </div>
                </div>
                <div className="grid  mt-2">
                    <div className="md:col-9">
                        <small className="karla ml-3">Final Total</small>
                    </div>
                   
                    <div className="md:col-2 text-center ">
                        <small>${countPrice}</small>
                    </div>
                </div>
            </>
        )
    }
    return (
        <>
            {isLoading === true ? <ProgressSpinner style={{ width: '50px', height: '50px' }} className='text-center h-screen flex justify-content-center' strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" /> :
                <ContentArea>
                    <div className="p-3">

                        <div className="grid card ml-0 mr-0 px-5 mb-5 align-items-center bg-main-light border-round-lg">
                            <h2 className="text-main">
                                View Client
                            </h2>
                        </div>
                        <div className="shadow-2 p-5 border-round-lg">
                            <div className="client_details flex align-items-center justify-content-between ">
                                <h2 className="text-main mb-5 p-0 m-0 Karla font-bold "style={{fontSize:"22px"}}>Client Details   </h2>
                                <p className="flex align-items-center text-lg font-">
                                    In Active
                                    <span className="ml-2">
                                        <InputSwitch checked={checked} onChange={(e) => setChecked(e.value)} />
                                    </span>
                                </p>
                            </div>
                            <div className="p-fluid grid mb-3">

                                <div className=" col-12 md:col-4 mb-3">
                                    <label className="font block mb-2">
                                        First Name<code className="text-red">*</code>
                                    </label>
                                    <InputText
                                        id=""
                                        className="border-round-md "
                                        name="first_name"
                                        placeholder=""
                                        value={value?.first_name}
                                        disabled
                                    />
                                </div>
                                <div className="col-12 md:col-4 mb-3 ">
                                    <label className="font block mb-2">Middle Name</label>
                                    <InputText
                                        id=""
                                        className="border-round-md "
                                        name="middle_name"
                                        placeholder=""
                                        value={value?.middle_name}
                                        disabled
                                    />
                                </div>
                                <div className="col-12 md:col-4 mb-3">
                                    <label className="font block mb-2">
                                        Last Name<code className="text-red">*</code>
                                    </label>
                                    <InputText
                                        id=""
                                        className="border-round-md "
                                        name="last_name"
                                        placeholder=""
                                        value={value?.last_name}
                                        disabled
                                    />
                                </div>

                                <div className="col-12 md:col-4 mb-3">
                                    <label className="font block mb-2">
                                        Email<code className="text-red">*</code>
                                    </label>
                                    <InputText
                                        id=""
                                        className="border-round-md "
                                        name="email"
                                        placeholder=""
                                        value={value?.email}
                                        disabled
                                    />
                                </div>
                                <div className="col-12 md:col-4 mb-3 ">
                                    <label className="font block mb-2">
                                        Phone No.<code className="text-red">*</code>
                                    </label>
                                    <InputText
                                        id=""
                                        className="border-round-md "
                                        name="phone_no"
                                        placeholder=""
                                        value={value.mobile}
                                        disabled
                                    />
                                </div>
                                <div className="col-12 md:col-4 mb-3 ">
                                    <label className="font block mb-2">
                                        DOB<code className="text-red">*</code>
                                    </label>
                                    <Calendar
                                        className="border-round-md calender_picker "
                                        inputClassName="calendar p-2 border-round-md "
                                        name="dob"
                                        placeholder=""
                                        value={value.dob}
                                        disabled
                                    />

                                </div>

                                <div className="col-12 md:col-4 mb-3">
                                    <label className="font block mb-2">
                                        Social Security No.
                                    </label>
                                    <InputText
                                        id=""
                                        inputClassName='border-round-md '
                                        className="border-round-md "
                                        name="social_security_no."
                                        placeholder=""
                                        value={value?.social_security_no}
                                        disabled
                                    />
                                </div>
                                <div className="col-12 md:col-4 mb-3 ">
                                    <label className="font block mb-2">
                                        Address 1<code className="text-red">*</code>
                                    </label>
                                    <InputText
                                        id=""
                                        className="border-round-md "
                                        name="address1"
                                        placeholder=""
                                        value={value.address_1}
                                        disabled
                                    />
                                </div>
                                <div className="col-12 md:col-4 mb-3 ">
                                    <label className="font block mb-2">
                                        Address 2
                                    </label>
                                    <InputText
                                        id=""
                                        className="border-round-md "
                                        name="addres2"
                                        placeholder=""
                                        value={value.address_2}
                                        disabled
                                    />
                                </div>

                                <div className="col-12 md:col-4 mb-3 ">
                                    <label className="font block mb-2">
                                        City<code className="text-red">*</code>
                                    </label>
                                    <InputText
                                        id=""
                                        className="border-round-md "
                                        name="city"
                                        placeholder=""
                                        value={value.city}
                                        disabled
                                    />
                                </div>
                                <div className="col-12 md:col-4 mb-3 ">
                                    <label className="font block mb-2">
                                        State<code className="text-red">*</code>
                                    </label>
                                    <InputText
                                        id=""
                                        className="border-round-md "
                                        name="state"
                                        placeholder=""
                                        value={value.state}
                                        disabled
                                    />
                                </div>
                                <div className="col-12 md:col-4 mb-3">
                                    <label className="font block mb-2">
                                        Zip Code<code className="text-red">*</code>
                                    </label>
                                    <InputNumber
                                        id=""
                                        inputClassName='border-round-md '
                                        className="border-round-md "
                                        name="zip_code."
                                        placeholder=""
                                        useGrouping={false}
                                        value={value.zipcode}
                                        disabled
                                    />
                                </div>


                                <div className='col-12 md:col-12'>
                                    <h2 className="text-main mb-3 p-0 m-0 Karla font-bold" style={{fontSize:"22px"}}>Company Details</h2>
                                </div>
                                <div className="col-12 md:col-6 mb-3 ">
                                    <label className="font block mb-2">
                                        Company Name<code className="text-red">*</code>
                                    </label>
                                    <InputText
                                        id=""
                                        className="border-round-md "
                                        name="city"
                                        placeholder=""
                                        value={value?.company_name}
                                        disabled
                                    />
                                </div>
                                <div className="col-12 md:col-6 mb-3 ">
                                    <label className="font block mb-2">
                                        Company TIN/EIN<code className="text-red">*</code>
                                    </label>
                                    <InputText
                                        id=""
                                        className="border-round-md "
                                        name="city"
                                        placeholder=""
                                        value={value?.company_tin_eim}
                                        disabled
                                    />
                                </div>


                                <div className="col-12 md:col-4 mb-3 ">
                                    <label className="font block mb-2">
                                        Company Email<code className="text-red">*</code>
                                    </label>
                                    <InputText
                                        id=""
                                        className="border-round-md "
                                        name="company_email"
                                        placeholder=""
                                        value={value.company_email}
                                        disabled
                                    />
                                </div>
                                <div className="col-12 md:col-4 mb-3">
                                    <label className="font block mb-2">
                                        Company Contact No.<code className="text-red">*</code>
                                    </label>
                                    <InputNumber
                                        id=""
                                        inputClassName='border-round-md '
                                        className="border-round-md "
                                        name="company_contact_no."
                                        placeholder=""
                                        value={value?.company_mobile}
                                        useGrouping={false}
                                        disabled
                                    />
                                </div>
                                <div className="col-12 md:col-4 mb-3">
                                    <label className="font block mb-2">
                                        Phone No.<code className="text-red">*</code>
                                    </label>
                                    <InputNumber
                                        id=""
                                        inputClassName='border-round-md '
                                        className="border-round-md "
                                        name="phone_no."
                                        placeholder=""
                                        value={value?.company_alt_mobile}
                                        useGrouping={false}
                                        disabled
                                    />
                                </div>


                                <div className="col-12 md:col-4 mb-3 ">
                                    <label className="font block mb-2">
                                        Address 1<code className="text-red">*</code>
                                    </label>
                                    <InputText
                                        id=""
                                        className="border-round-md "
                                        name="address1"
                                        placeholder=""
                                        value={value?.company_address_1}
                                        disabled
                                    />
                                </div>
                                <div className="col-12 md:col-4 mb-3 ">
                                    <label className="font block mb-2">
                                        Address 2
                                    </label>
                                    <InputText
                                        id=""
                                        className="border-round-md "
                                        name="addres2"
                                        placeholder=""
                                        value={value?.company_address_2}
                                        disabled
                                    />
                                </div>
                                <div className="col-12 md:col-4 mb-3 ">
                                    <label className="font block mb-2">
                                        City<code className="text-red">*</code>
                                    </label>
                                    <InputText
                                        id=""
                                        className="border-round-md "
                                        name="city"
                                        placeholder=""
                                        value={value?.company_city}
                                        disabled
                                    />
                                </div>


                                <div className="col-12 md:col-6 mb-3 ">
                                    <label className="font block mb-2">
                                        State<code className="text-red">*</code>
                                    </label>
                                    <InputText
                                        id=""
                                        className="border-round-md "
                                        name="state"
                                        placeholder=""
                                        value={value?.company_state}
                                        disabled
                                    />
                                </div>

                                <div className="col-12 md:col-6 mb-3">
                                    <label className="font block mb-2">
                                        Zip Code<code className="text-red">*</code>
                                    </label>
                                    <InputNumber
                                        id=""
                                        inputClassName='border-round-md '
                                        className="border-round-md "
                                        name="zip_code."
                                        placeholder=""
                                        useGrouping={false}
                                        value={value?.company_zipcode}
                                        disabled
                                    />
                                </div>


                            </div>
                            <Card className="shadow-2 text-main border-round-lg mb-5">
                                <div className="grid justify-content-between align-items-center mb-3">
                                    <h2 className="text-main mb-3 p-0 m-0 Karla font-bold ml-2" style={{fontSize:"22px"}}>
                                        Program Details
                                    </h2>
                                    {/* <Dropdown value={selectedDiscount} onChange={(e) => setSelectdDiscount(e.value)} options={Discount} optionLabel="name"
                                    editable placeholder="Apply Discount" className="mr-2 border-round-md" /> */}
                                </div>

                                <div className="grid program_table">
                                    <div className="md:col-12">
                                        <Table tableHeading={TableHeading} tableData={SubscriptionPlanTableData} handleGrandTotal={handleGrandTotal()} title="viewClient" />
                                    </div>
                                </div>

                            </Card>


                        </div>

                    </div>
                </ContentArea>
            }
        </>
    )
}