import { types } from "../HospitalType/types";

const intitalState = {
  addAppoinmentProfile: {},
  getAppointmentProfile: [],
  appointmentAddServiceProfile: [],
  appointmentGetServiceProfile: [],
  appointmentDeleteServiceProfile: [],
  appointementViewServiceProfile: [],
  appointmentEditServiceProfile: {},
  addAppointmentBookingProfile: [],
  selectedAppointmentProfile: [],
  appointmentBookingProfile: [],
  getAppointmentScheduleProfile: [],
  getAppointmentDataProfile: {},
  viewAppointmentDataProfile: [],
  editAppointmentDataProfile: {},
  appointmentCalendarProfile: {},
  getDateEventProfile: {},
  calendarSchedulingProfile: [],
};

export const addappoinmentReducer = (state = intitalState, action) => {
  switch (action.type) {
    case types.ADD_APPOINTMENT:
      return {
        ...state,
        addAppoinmentProfile: action.payload,
      };
    default: {
      return { ...state };
    }
  }
};

export const getappointmentReducer = (state = intitalState, action) => {
  switch (action.type) {
    case types.GET_APPOINTMENT:
      return {
        ...state,
        getAppointmentProfile: action.payload,
      };
    default: {
      return { ...state };
    }
  }
};
export const viewappointmentReducer = (state = intitalState, action) => {
  switch (action.type) {
    case types.VIEWAPPOINTMENT:
      return {
        ...state,
        viewAppointmentList: action.payload,
      };
    default: {
      return { ...state };
    }
  }
};

export const appointmentAddServiceReducer = (state = intitalState, action) => {
  switch (action.type) {
    case types.APPOINTMENT_ADD_SERVICE:
      return {
        ...state,
        appointmentAddServiceProfile: action.payload,
      };
    default: {
      return { ...state };
    }
  }
};

export const appointmentGetServiceReducer = (state = intitalState, action) => {
  switch (action.type) {
    case types.APPOINTMENT_GET_SERVICE:
      return {
        ...state,
        appointmentGetServiceAction: action.payload,
      };
    default: {
      return { ...state };
    }
  }
};

export const appointmentDeleteServiceReducer = (
  state = intitalState,
  action
) => {
  switch (action.type) {
    case types.APPOINTMENT_DELETE_SERVICE:
      return {
        ...state,
        appointmentDeleteServiceProfile: action.payload,
      };
    default: {
      return { ...state };
    }
  }
};

export const appointmentViewServiceReducer = (state = intitalState, action) => {
  switch (action.type) {
    case types.APPOINTMENT_VIEW_SERVICE:
      return {
        ...state,
        appointementViewServiceProfile: action.payload,
      };
    default: {
      return { ...state };
    }
  }
};

export const appointmenttEditServiceReducer = (
  state = intitalState,
  action
) => {
  switch (action.type) {
    case types.APPOINTMENT_EDIT_SERVICE:
      return {
        ...state,
        appointmentEditServiceProfile: action.payload,
      };
    default: {
      return { ...state };
    }
  }
};

export const addAppointmentBookingReducer = (state = intitalState, action) => {
  switch (action.type) {
    case types.ADD_APPOINTMENT_BOOKING:
      return {
        ...state,
        addAppointmentBookingProfile: action.payload,
      };
    default: {
      return { ...state };
    }
  }
};

export const selectedAppointmentReducer = (state = intitalState, action) => {
  switch (action.type) {
    case types.SELECTED_APPOINTMENT:
      return {
        ...state,
        selectedAppointmentProfile: action.payload,
      };
    default: {
      return { ...state };
    }
  }
};

export const appointmentBookingReducer = (state = intitalState, action) => {
  switch (action.type) {
    case types.APPOINTMENT_BOOKING:
      return {
        ...state,
        appointmentBookingProfile: action.payload,
      };
    default: {
      return { ...state };
    }
  }
};

export const getAppointmentScheduleReducer = (state = intitalState, action) => {
  switch (action.type) {
    case types.GET_APPOINTMENT_SCHEDULE:
      return {
        ...state,
        getAppointmentScheduleProfile: action.payload,
      };
    default: {
      return { ...state };
    }
  }
};

export const getAppointmentDataReducer = (state = intitalState, action) => {
  switch (action.type) {
    case types.GET_APPOINTMENT_DATA:
      return {
        ...state,
        getAppointmentDataProfile: action.payload,
      };
    default: {
      return { ...state };
    }
  }
};

export const viewAppointmentDataReducer = (state = intitalState, action) => {
  switch (action.type) {
    case types.VIEW_APPOINTMENT_DATA:
      return {
        ...state,
        viewAppointmentDataProfile: action.payload,
      };
    default: {
      return { ...state };
    }
  }
};

export const editAppointmentDataReducer = (state = intitalState, action) => {
  switch (action.type) {
    case types.EDIT_APPOINTMENT_DATA:
      return {
        ...state,
        editAppointmentDataProfile: action.payload,
      };
    default: {
      return { ...state };
    }
  }
};

export const appointmentCalendarReducer = (state = intitalState, action) => {
  switch (action.type) {
    case types.APPOINTMENT_CALENDER:
      return {
        ...state,
        appointmentCalendarProfile: action.payload,
      };
    default: {
      return { ...state };
    }
  }
};

export const getDateEvenetReducer = (state = intitalState, action) => {
  switch (action.type) {
    case types.GET_DATE_EVENT:
      return {
        ...state,
        getDateEventProfile: action.payload,
      };
    default: {
      return { ...state };
    }
  }
};

export const CalendarSchedulingReducer = (state = intitalState, action) => {
  switch (action.type) {
    case types.SCHEDULING:
      return {
        ...state,
        calendarSchedulingProfile: action.payload,
      };
    default: {
      return { ...state };
    }
  }
};
