import React, { useState } from "react";
import Container from "../components/container/Container";
import LogoWhite from "../assets/images/logowhite.png";
import { Link } from "react-router-dom";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";

export default function Footer() {
    
  return (
    <>
      <div className="bg-main pt-5">
        <Container>
          <div className="w-full text-center">
            <img src={LogoWhite} alt="" className="md:w-1 lg:w-1 mb-2" />
            <p className="text-white w-9 m-auto line-height-3 mb-4">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s
            </p>
            <ul className="flex flex-column md:flex-row gap-3 justify-content-between mb-5">
              <li>
                <Link to="/" className="text-white no-underline">
                  Home
                </Link>
              </li>
              <li>
                <Link to="/about" className="text-white no-underline">
                  About Us
                </Link>
              </li>
              <li>
                <Link to="/Pricing" className="text-white no-underline">
                  Pricing
                </Link>
              </li>
             
              <li>
                <Link to="/contact-us" className="text-white no-underline">
                  Contact us
                </Link>
              </li>
              <li>
                <Link to="" className="text-white no-underline">
                  Sales Person Login
                </Link>
              </li>
            </ul>
          </div>
        </Container>
        <div className="border-top-1 border-100 py-1">
          <Container containerClass="align-items-center flex-wrap justify-content-between">
            <p className="text-white w-full md:w-auto text-center">
              © 2022 RESMEDX
            </p>
            <div className="flex justify-content-center gap-3 w-full md:w-auto">
              <div className="social-icon">
                <i className="pi pi-linkedin"></i>
              </div>
              <div className="social-icon">
                <i className="pi pi-facebook"></i>
              </div>
              <div className="social-icon">
                <i className="pi pi-instagram"></i>
              </div>
              <div className="social-icon">
                <i className="pi pi-twitter"></i>
              </div>
              <div className="social-icon">
                <i className="pi pi-envelope"></i>
              </div>
            </div>
          </Container>
        </div>
      </div>
     
    </>
  );
}
