import React from "react";
import { TabView, TabPanel } from "primereact/tabview";
import Company from "./Company/Company";
import Location from "./Location/Location";
import Leave from "./Leave/Leave";

const Setting = () => {
  return (
    <div className=" p-5">
      <div className="heading">Settings </div>
      <div className="profile-content my-5 ">
        <TabView className="py-5">
          <TabPanel header="Company">
            <Company></Company>
          </TabPanel>
          <TabPanel header="Payment Plans">
            <p className="m-0">
              Sed ut perspiciatis unde omnis iste natus error sit voluptatem
              accusantium doloremque laudantium, totam rem aperiam, eaque ipsa
              quae ab illo inventore veritatis et quasi architecto beatae vitae
              dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit
              aspernatur aut odit aut fugit, sed quia consequuntur magni dolores
              eos qui ratione voluptatem sequi nesciunt. Consectetur, adipisci
              velit, sed quia non numquam eius modi.
            </p>
          </TabPanel>
          <TabPanel header="Location">
            <Location></Location>
          </TabPanel>
          <TabPanel header="Leaves">
            <Leave></Leave>
          </TabPanel>
        </TabView>
      </div>
      {/* <div className="col-12 p-5 mt-5 ">
        <div className="flex p-0 ">
          <div className="col pl-0">
            <InputField label={"Old Password"}></InputField>
          </div>
          <div className="col">
            <InputField label={"New Password"}></InputField>
          </div>
          <div className="col">
            <InputField label={"Confirm Password"} required></InputField>
          </div>
        </div>
        <div className="flex py-5">
          <SaveButton
            label={"Change Password"}
            save_button={false}
          ></SaveButton>
          <CancelButton label={"Cancel"}></CancelButton>
        </div>
      </div> */}
    </div>
  );
};

export default Setting;
