
import React, { useState, useEffect } from "react";
import InputField from "../../../../components/input/input";
import { Card } from "primereact/card";
import { Calendar } from "primereact/calendar";
import { InputText } from "primereact/inputtext";
import ContentArea from "../../../../shared/ContentArea";
import SaveButton from "../../../../components/Buttons/SaveButton";
import CancelButton from "../../../../components/Buttons/CancelButton";
import { addRevenueAction, getRevenueCategoriesAction } from "../../../../Redux/HospitalAction/expenseAction";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DropdownField from "../../../../components/input/dropdown";
import moment from "moment/moment";
import Loader from "../../../../pages/Loader";
const AddRevenue = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [categories, setCategories] = useState(null);
    const [data, setData] = useState({
        select_category_id: "",
        revenue_name: "",
        revenue_date: "",
        revenue_description: "",
        assignment: "",
        revenue_amount:""
    })
    const [error, setError] = useState("")
    useEffect(() => {
        dispatch(getRevenueCategoriesAction())
    }, [])
    const getRevenueCategoriesData = useSelector((state) => state.getRevenueCategories.getRevenueCategoriesProfile)
    const [date, setDate] = useState(null);
    const handleChange = (e) => {
        const { name, value } = e.target
        setError({
            ...error,
            [name]: null
        })
        setData({
            ...data,
            [name]: value
        })
    }
    useEffect(() => {
        setError({
            ...error,
            select_category_id: null
        })
        setData({
            ...data,
            select_category_id: categories?.id
        })
    }, [categories])
    useEffect(() => {
        setError({
            ...error,
            revenue_date: null
        })
        setData({
            ...data,
            revenue_date: date
        })
    }, [date])
    const handleSubmit = ()=>{
        if(data.select_category_id === "" || data.revenue_name === "" || data.revenue_date === null || data.assignment=== "" || data.revenue_amount === ""){
            setError({
                ...error,
                select_category_id:data.select_category_id === "" ? "Please Select Category":error.select_category_id,
                revenue_name:data.revenue_name === "" ?"Please Enter Name":error.revenue_name,
                revenue_date:data.revenue_date === null?"Please Choose Date":error.revenue_date,
                revenue_amount:data.revenue_amount === ""?"Please Enter Amount":error.revenue_amount,
                assignment:data.assignment === ""?"Please Enter Assignment":error.assignment 
            })
            return false
        }
        const obj = data;
        let array = Object.entries(obj)
        const formData = new FormData()
        for (let i = 0; i < array.length; i++) {
            if(array[i][0] === "revenue_date"){
                formData.append(array[i][0],moment(data.revenue_date).format("DD-MM-YYYY"))
            }else{
                formData.append(array[i][0], array[i][1])
            } 
        }
        dispatch(addRevenueAction(formData,()=>navigate("/account-owner/manage-revenue")))
    }
    const isLoading = useSelector((state) => state.loader.isLoading)
    return (
        <>
        {isLoading === true ? <Loader/>:
            <ContentArea>
                <div className="add_expenses_container">
                    <div className="p-3">
                        <div className="grid card ml-0 mr-0 px-5 mb-5 align-items-center bg-main-light border-round-lg">
                            <h2 className="text-main">
                                Add Revenue
                            </h2>
                        </div>
                        <Card className="shadow-2 p-3 border-round-lg">
                            <div className="p-fluid grid mb-3">
                                <div className="field col-12 md:col-6">
                                    <DropdownField label="Select Category" value={categories} onChange={(e) => setCategories(e.value)} options={getRevenueCategoriesData} optionLabel="category"
                                        placeholder="Select" required />
                                        {error.select_category_id && <span className="error">{error.select_category_id}</span>}
                                </div>
                                <div className="field col-12 md:col-6">
                                    <InputField label="Revenue Name" required name="revenue_name" value={data?.revenue_name} onChange={handleChange} />
                                    {error.revenue_name && <span className="error">{error.revenue_name}</span>}
                                </div>
                                <div className="field col-12 md:col-6">
                                    <InputField label="Revenue Description" name="revenue_description" value={data?.revenue_description} onChange={handleChange} />
                                </div>
                                <div className="field col-12 md:col-6">
                                    <label className="font block mb-2">Select Revenue Date<code className="text-red">*</code></label>
                                    <Calendar value={date} onChange={(e) => setDate(e.value)} className="calender_picker" inputClassName="border-round-md mb-2" />
                                   {error.revenue_date && <span className="error">{error.revenue_date}</span>}
                                </div>
                                <div className="field col-12 md:col-6">
                                    <label className="font block mb-2">Revenue Amount<code className="text-red">*</code></label>
                                    <div className="p-inputgroup mb-2">
                                        <span className="p-inputgroup-addon bg-blue-100">$</span>
                                        <InputText placeholder="" className="border-round-right-md" name="revenue_amount" value={data?.revenue_amount} onChange={handleChange} />
                                    </div>
                                    {error.revenue_amount && <span className="error">{error.revenue_amount}</span>}
                                </div>
                                <div className="field col-12 md:col-6">
                                    <InputField label="Assignment" required name="assignment" value={data?.assignment} onChange={handleChange} />
                                    {error.assignment && <span className="error">{error.assignment}</span>}
                                </div>
                            </div>
                            <div className="submitButton flex">
                                <SaveButton label="Save" onClick={handleSubmit}></SaveButton>
                                <CancelButton label="Cancel" onClick={()=>navigate("/account-owner/manage-revenue")}></CancelButton>
                            </div>
                        </Card>
                    </div>
                </div>
            </ContentArea>
}
        </>
    )
}

export default AddRevenue;
