import React, { useState } from "react";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { InputSwitch } from "primereact/inputswitch";
import InputField from "../../../../../components/input/input";
import { useEffect } from "react";
import InputNumberField from "../../../../../components/input/inputNumber";

const Timesloat = ({ item, index, form, setForm, viewData }) => {
  const [times_ara, settimes_ara] = useState("");
  const [showTimeArea, setshowTimeArea] = useState("");
  function parseTime(s) {
    var c = s.split(":");
    return parseInt(c[0]) * 60 + parseInt(c[1]);
  }

  function convertHours(mins) {
    var hour = Math.floor(mins / 60);
    var mins = mins % 60;
    var converted = pad(hour, 2) + ":" + pad(mins, 2);
    return converted;
  }

  function pad(str, max) {
    str = str.toString();
    return str.length < max ? pad("0" + str, max) : str;
  }

  function calculate_time_slot(
    start_time,
    end_time,
    interval = item?.time_duration
  ) {
    var i, formatted_time;
    var time_slots = new Array();
    for (var i = start_time; i <= end_time; i = i + interval) {
      formatted_time = convertHours(i);
      time_slots.push(formatted_time);
    }
    return time_slots;
  }
  useEffect(() => {
    var startTime = item?.start_time;
    var endTime = item?.end_time;

    var start_time = parseTime(startTime),
      end_time = parseTime(endTime),
      interval =
        item?.time_duration === "30 min"
          ? parseInt(item?.time_duration)
          : parseInt(item?.time_duration) * 60;

    var times_ara = calculate_time_slot(start_time, end_time, interval);
    times_ara.shift();
    settimes_ara(times_ara);
  }, [item]);

  useEffect(() => {
    let showTimeArea = [...times_ara];
    showTimeArea.unshift(item.start_time);
    setshowTimeArea(showTimeArea);
  }, [times_ara]);

  const handleChange = (name, value, rowIndex) => {
    let newTimeSchedule = form.time_schedule?.map((schedule, i) => {
      if (i === index) {
        schedule.time_duration_data[rowIndex] = {
          ...schedule.time_duration_data[rowIndex],
          [name]: value,
        };
        return schedule;
      } else return schedule;
    });

    setForm({ ...form, isView: false, time_schedule: newTimeSchedule });
  };
  const createTimeSlots = (timeArr) => {
    let timeSlotArr = [];
    timeArr?.forEach((time, index) => {
      if (index < timeArr.length - 1) {
        let newObj = {
          time: time + " - " + timeArr[index + 1],
          select: "",
          is_block: 0,
        };
        timeSlotArr.push(newObj);
      }
    });

    let newTimeSchedule = form.time_schedule?.map((schedule, i) => {
      if (i === index) {
        schedule.time_duration_data = timeSlotArr;
        return schedule;
      } else return schedule;
    });

    setForm({ ...form, time_schedule: newTimeSchedule });
  };

  useEffect(() => {
    if (showTimeArea.length && !form.isView) createTimeSlots(showTimeArea);
  }, [showTimeArea, viewData]);
  return (
    <>
      {form.time_schedule[index]?.time_duration_data?.length > 0 &&
        form.time_schedule[index]?.time_duration_data?.map((item, i) => {
          return (
            <div className="p-fluid grid flex align-items-center p-0 m-0  justify-content-between">
              <div className=" col-12 md:col-3">
                <InputText
                  id="inputtext "
                  className="border-round-md"
                  value={item.time}
                  disabled
                ></InputText>
              </div>
              <div className="text-color_rgb col-12 md:col-3">
                Set Appointments Allowed
              </div>
              <div className=" col-12 md:col-4 ">
                <InputField
                  inputId="dropdown"
                  placeholder="Enter"
                  value={item.select}
                  className="border-round-md"
                  name="select"
                  onChange={(e) => handleChange("select", e.target.value, i)}
                />
              </div>

              <div className=" col-12 md:col-2">
                <p className="flex text-color_rgb align-items-center mt-3 justify-content-end ">
                  {/* {propse.name} */}
                  Block
                  <span className="ml-2">
                    <InputSwitch
                      checked={item.is_block === 1 ? true : false}
                      onChange={(e) =>
                        handleChange("is_block", e.value === true ? 1 : 0, i)
                      }
                      name="is_block"
                    />
                  </span>
                </p>
              </div>
            </div>
          );
        })}
    </>
  );
};

export default Timesloat;
