import React from "react";
import SearchIcon from "../assets/icons/search.png";
import BellIcon from "../assets/icons/bell.png";
import BellRedDotIcon from "../assets/icons/bell-with-red-dot.png";
import CalendarIcon from "../assets/icons/calendar.png";
import ArrowDownIcon from "../assets/icons/arrow-down-black.png";
import ProfileImage from "../assets/images/profile-image.png";
import { useNavigate } from "react-router-dom";
export default function TopBar() {
  const navigate = useNavigate();
  const name = localStorage.getItem("name");

  const handleLogout = () => {
    localStorage.clear();
    navigate("/");
  };
  return (
    <div className="topbar_container">
      <div className="flex topbar-inner justify-content-between">
        <div className="search-box">
          <img src={SearchIcon} alt="Search Icon" width="18px" height="18px" />
          <input type="search" placeholder="Search" />
        </div>
        <div className="right-side flex">
          <div className="icon-btn flex align-items-center">
            <div className="pr-3" style={{ borderRight: "1px solid #D1D1D1" }}>
              <img
                src={CalendarIcon}
                width="27px"
                height="24px"
                alt="Calendar icon"
              />
            </div>
            <div className="pl-2">
              <img
                src={BellRedDotIcon}
                width="40px"
                height="40px"
                alt="Bell Icon"
              />
            </div>
          </div>
          <div className="profile-box flex align-items-center ml-4">
            <div className="user-name pr-2 ">
              <p className="text-lg font-medium p-0 m-0">{name}</p>
              <p className="text-sm p-0 m-0 text-right">Sales Person</p>
            </div>
            <img
              src={ProfileImage}
              className="mr-2 border-circle"
              width="40px"
              height="40px"
              alt="Profile Image"
            />
            <label className="dropdown">
              <div className="dd-button"></div>
              <input type="checkbox" className="dd-input" id="test" />
              <ul className="dd-menu " onClick={() => handleLogout()}>
                <li>
                  <i className="pi  pi-sign-out"></i> &nbsp;&nbsp;Logout
                </li>
              </ul>
            </label>
          </div>
        </div>
      </div>
      <hr
        className="m-0 border-none"
        style={{ height: "1px", backgroundColor: "rgba(30, 30, 30, .1)" }}
      />
    </div>
  );
}
