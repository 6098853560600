import React, { useEffect, useState } from "react";
import { Checkbox } from "primereact/checkbox";
import { Dropdown } from "primereact/dropdown";
import EmployeeTiming from "./EmployeeTiming";
import ContentArea from "../../shared/ContentArea";
import InputField from "../../../../components/input/input";
import { Card } from "primereact/card";
import SaveButton from "../../../../components/Buttons/SaveButton";
import CancelButton from "../../../../components/Buttons/CancelButton";
import DropdownField from "../../../../components/input/dropdown";
import { getEmployeesAction } from "../../../../Redux/HospitalAction/employessAction";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  viewEmployeeSchedulingAction,
  editEmployeeSchedulingAction,
} from "../../../../Redux/HospitalAction/employessAction";
import Loader from "../../../../pages/Loader";
import { LocationData } from "../../../../Redux/HospitalAction/appoinmentAction";
const EditScheduling = () => {
  const [Employe, SetEmploye] = useState(null);
  const [checked, setChecked] = useState(false);
  const [employeeData, setEmployeeData] = useState(null);
  const [error, setError] = useState("");
  const [data, setData] = useState({
    employee_name: "",
    employee_id: "",
    is_repeat: "",
    time_schedule: [
      {
        day: 0,
        start_time: "",
        end_time: "",
        start_lunch_time: "",
        end_lunch_time: "",
        is_working: "",
      },
      {
        day: 1,
        start_time: "",
        end_time: "",
        start_lunch_time: "",
        end_lunch_time: "",
        is_working: "",
      },
      {
        day: 2,
        start_time: "",
        end_time: "",
        start_lunch_time: "",
        end_lunch_time: "",
        is_working: "",
      },
      {
        day: 3,
        start_time: "",
        end_time: "",
        start_lunch_time: "",
        end_lunch_time: "",
        is_working: "",
      },
      {
        day: 4,
        start_time: "",
        end_time: "",
        start_lunch_time: "",
        end_lunch_time: "",
        is_working: "",
      },
      {
        day: 5,
        start_time: "",
        end_time: "",
        start_lunch_time: "",
        end_lunch_time: "",
        is_working: "",
      },
      {
        day: 6,
        start_time: "",
        end_time: "",
        start_lunch_time: "",
        end_lunch_time: "",
        is_working: "",
      },
    ],
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  useEffect(() => {
    dispatch(getEmployeesAction());
  }, []);
  const getallEmployee = useSelector(
    (state) => state.getEmployee.getEmployeeProfile
  );
  useEffect(() => {
    setEmployeeData(getallEmployee);
  }, [getallEmployee]);
  useEffect(() => {
    dispatch(viewEmployeeSchedulingAction(id));
  }, []);
  const viewData = useSelector(
    (state) => state.viewEmployeeScheduling.viewEmployeeSchedulingProfile
  );
  useEffect(() => {
    let newViewData = viewData?.time_schedule?.map((item, index) => {
      if (item.is_working === 1) {
        return {
          ...item,
          start_time:
            new Date(item.start_time).getHours().toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            }) +
            ":" +
            new Date(item.start_time).getMinutes().toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            }),
          end_time:
            new Date(item.end_time).getHours().toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            }) +
            ":" +
            new Date(item.end_time).getMinutes().toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            }),
          start_lunch_time:
            new Date(item.start_lunch_time).getHours().toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            }) +
            ":" +
            new Date(item.start_lunch_time)
              .getMinutes()
              .toLocaleString("en-US", {
                minimumIntegerDigits: 2,
                useGrouping: false,
              }),
          end_lunch_time:
            new Date(item.end_lunch_time).getHours().toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            }) +
            ":" +
            new Date(item.end_lunch_time).getMinutes().toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            }),
          is_working: item.is_working === 1 ? true : false,
        };
      } else {
        return item;
      }
    });
    let obj = {
      ...viewData,
      time_schedule: newViewData,
    };
    setData(obj);
  }, [viewData]);
  useEffect(() => {
    employeeData?.map((item) => {
      if (item.emp_id == data.employee_id) {
        SetEmploye(item);
      }
    });
  }, [employeeData, data]);
  useEffect(() => {
    setData({
      ...data,
      employee_id: Employe?.emp_id,
      employee_name: Employe?.name,
    });
    setError({
      ...error,
      employee_id: null,
    });
  }, [Employe]);
  const handleSubmit = () => {
    if (data.employee_id === undefined) {
      setError({
        ...error,
        employee_id:
          data.employee_id === undefined
            ? "Please Select Employee"
            : error.employee_id,
      });
      return false;
    }
    const formData = new FormData();
    formData.append("emp_id", data.employee_id);
    formData.append("is_repeat", data.is_repeat);
    formData.append("location_id", data.location_id);
    data.time_schedule?.map((item, index) => {
      if (item.is_working === true) {
        formData.append(
          `time_schedule[${index}][location_id]`,
          item.location_id
        );
        formData.append(`time_schedule[${index}][day]`, item.day);
        formData.append(`time_schedule[${index}][is_working]`, 1);
        formData.append(`time_schedule[${index}][start_time]`, item.start_time);
        formData.append(`time_schedule[${index}][end_time]`, item.end_time);
        formData.append(
          `time_schedule[${index}][start_lunch_time]`,
          item.start_lunch_time
        );
        formData.append(
          `time_schedule[${index}][end_lunch_time]`,
          item.end_lunch_time
        );
      }
    });
    dispatch(
      editEmployeeSchedulingAction(id, formData, () =>
        navigate("/account-owner/employees-scheduling")
      )
    );
  };
  useEffect(() => {
    if (checked === true) {
      setData((prev) => {
        return {
          ...prev,
          is_repeat: 1,
        };
      });
    } else {
      setData((prev) => {
        return {
          ...prev,
          is_repeat: 0,
        };
      });
    }
  }, [checked]);
  useEffect(() => {
    if (viewData.is_repeat === 1) {
      setChecked(true);
      setData((prev) => {
        return {
          ...prev,
          is_repeat: 1,
        };
      });
    } else {
      setChecked(false);
      setData((prev) => {
        return {
          ...prev,
          is_repeat: 0,
        };
      });
    }
  }, [viewData]);
  const Location = useSelector(
    (state) => state.companyLocation.companyLocation
  );
  useEffect(() => {
    dispatch(LocationData());
  }, []);

  const isLoading = useSelector((state) => state.loader.isLoading);
  return (
    <>
      {isLoading === true ? (
        <Loader />
      ) : (
        <ContentArea>
          <div className="p-3">
            <div className="grid card ml-0 mr-0 px-5 mb-5 align-items-center bg-main-light border-round-lg">
              <h2 className="text-main ">Edit Scheduling</h2>
            </div>
            <Card className="shedule shadow-2 p-3">
              <div className="p-fluid grid">
                <div className="col-12 md:col-6 ">
                  <DropdownField
                    inputId="dropdown"
                    label="Select Employee"
                    value={Employe}
                    options={employeeData}
                    onChange={(e) => SetEmploye(e.value)}
                    optionLabel="emp_id"
                    required
                    disabled
                  />
                  {error?.employee_id && (
                    <span className="error">{error?.employee_id}</span>
                  )}
                </div>
                <div className="col-12 md:col-6">
                  <InputField
                    id="inputtext"
                    label="Full Name"
                    value={data.employee_name}
                    required
                    disabled
                  />
                </div>
              </div>
              <div className="p-fluid grid">
                <div className=" col-12 md:col-12 ">
                  <h2
                    className="text-main font-bold"
                    style={{ fontSize: "22px" }}
                  >
                    Add Timings for selected days
                  </h2>
                </div>
              </div>
              <div className="p-fluid grid">
                <div className="col-12 md:col-12 border-round-sm ">
                  {data?.time_schedule?.length > 0 &&
                    data?.time_schedule?.map((item, index) => {
                      return (
                        <EmployeeTiming
                          title={item.day}
                          index={index}
                          items={item}
                          setData={setData}
                          data={data}
                          Location={Location}
                        />
                      );
                    })}
                </div>
              </div>
              <div className="p-fluid grid mb-5">
                <div className="col-12 md:col-12 ">
                  <Checkbox
                    onChange={(e) => setChecked(e.checked)}
                    checked={checked}
                  ></Checkbox>{" "}
                  &nbsp; Repeat for every week.
                </div>
              </div>
              <div className="submitButton flex">
                <SaveButton label="Save" onClick={handleSubmit}></SaveButton>
                <CancelButton
                  label="Cancel"
                  onClick={() =>
                    navigate("/account-owner/employees-scheduling")
                  }
                ></CancelButton>
              </div>
            </Card>
          </div>
        </ContentArea>
      )}
    </>
  );
};

export default EditScheduling;
