import React, { useEffect, useState } from "react";
import InputField from "../../../../components/input/input";
import InputTextareaField from "../../../../components/input/inputTextArea";
import SaveButton from "../../../../components/Buttons/SaveButton";
import CancelButton from "../../../../components/Buttons/CancelButton";
import { useDispatch, useSelector } from "react-redux";
import {
  CompanyPostData,
  CompanyShowData,
} from "../../../../Redux/HospitalAction/appoinmentAction";

const EditCompany = ({ setShowEdit, companyDatalist }) => {
  const [data, setData] = useState();
  const [isError, setIsError] = useState({});

  const dispatch = useDispatch();

  const SaveEditData = (data) => {
    dispatch(CompanyPostData(data, setShowEdit));
  };

  const Validate = () => {
    let errors = {};

    if (!data.tin_ein) {
      errors.tin_ein = "Enter Company Tin_Ein ";
    }
    if (!data.name) {
      errors.name = "Enter Company Name ";
    }
    if (!data.address) {
      errors.address = "Enter Address ";
    }
    if (!data.state) {
      errors.state = "Enter Company state ";
    }
    if (!data.pin_code) {
      errors.pin_code = "Enter Zip Code ";
    }

    setIsError(errors);
    if (Object.keys(errors).length === 0) {
      return true;
    } else {
      return false;
    }
  };

  const DataSubmit = async (data) => {
    let errors = await Validate();
    if (errors) SaveEditData(data);
  };

  useEffect(() => {
    setData(companyDatalist);
  }, [companyDatalist]);

  const handleChange = (name) => (e) => {
    setData({ ...data, [name]: e.target.value });
  };

  return (
    <>
      <div className="px-4">
        <h2 className="">Edit Company Details</h2>
        <div className="flex p-0 ">
          <div className="col pl-0">
            <InputField
              label={"Company TIN/EIN"}
              required
              value={data?.tin_ein}
              onChange={handleChange("tin_ein")}
            ></InputField>
            {isError?.tin_ein && (
              <p className="text-red-600 text-xs mt-1">{isError.tin_ein}</p>
            )}
          </div>
          <div className="col">
            <InputField
              label={"Company Name"}
              value={data?.name}
              required
              onChange={handleChange("name")}
            ></InputField>
            {isError?.name && (
              <p className="text-red-600 text-xs mt-1">{isError.name}</p>
            )}
          </div>
        </div>
        <div>
          <InputTextareaField
            label={"Street Address"}
            value={data?.address}
            required
            onChange={handleChange("address")}
          ></InputTextareaField>
          {isError?.address && (
            <p className="text-red-600 text-xs mt-1">{isError.address}</p>
          )}
        </div>
        <div className="flex p-0 ">
          <div className="col pl-0">
            <InputField
              label={"State"}
              required
              value={data?.state}
              onChange={handleChange("state")}
            ></InputField>
            {isError?.state && (
              <p className="text-red-600 text-xs mt-1">{isError.state}</p>
            )}
          </div>
          <div className="col">
            <InputField
              label={"Zip code"}
              required
              value={data?.pin_code}
              onChange={handleChange("pin_code")}
            ></InputField>
            {isError?.pin_code && (
              <p className="text-red-600 text-xs mt-1">{isError.pin_code}</p>
            )}
          </div>
        </div>
        <div className="flex py-5">
          <SaveButton
            label={"Save"}
            onClick={() => DataSubmit(data)}
          ></SaveButton>
          <CancelButton
            label={"Cancel"}
            onClick={() => setShowEdit(false)}
          ></CancelButton>
        </div>
      </div>
    </>
  );
};

export default EditCompany;
