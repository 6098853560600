import React, { useEffect, useState } from "react";
import InputField from "../../../components/input/input";
import ContentArea from "../shared/ContentArea";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import { validEmail } from "../../../components/Validation";
import DropdownField from "../../../components/input/dropdown";
import InputNumberField from "../../../components/input/inputNumber";
import { useNavigate } from "react-router-dom";
import { Checkbox } from 'primereact/checkbox';
import { useDispatch, useSelector } from "react-redux";
import { addServiceProviderAction, getServiceAction } from "../../../Redux/HospitalAction/serviceProviderAction";
import { carrierAction } from "../../../Redux/HospitalAction/carrierAction"
import Loader from "../../../pages/Loader";
import SaveButton from "../../../components/Buttons/SaveButton";
import CancelButton from "../../../components/Buttons/CancelButton";
const AddProviders = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [service, setService] = useState(null)
    const [carrier, setCarrier] = useState(null)
    const [selectService, setSelectService] = useState("")
    const [selectCarrier, setSelectCarrier] = useState("")
    const [error, setError] = useState("")
    const [checked, setChecked] = useState(false);
    const [addService, setAddService] = useState([{
        service_name: ""
    }])
    const [data, setData] = useState({
        service_provider_name: "",
        email: "",
        phone_number: null,
        service: [],
        address: "",
        state: "",
        zip_code: null,
        service_type_id: "",
        carrier_id: "",
        is_sms_notification: 0
    })
    useEffect(() => {
        dispatch(getServiceAction())
        dispatch(carrierAction())
    }, [])
    const serviceDatalist = useSelector((state) => state.getService?.getServiceProfile)
    const carrierData = useSelector((state) => state.carrier.carrierProfile)
    useEffect(() => {
        setService(serviceDatalist)
        setCarrier(carrierData)
    }, [serviceDatalist, carrierData])
    const handleChange = (e) => {
        setData({
            ...data,
            [e?.target?.name]: e?.target?.value,
        });
        setError({
            ...error,
            [e?.target?.name]: "",
        });
    };
    const handleNumberChange = (e) => {
        setData({
            ...data,
            [e.target?.name]: e?.value
        })
        setError({
            ...error,
            [e.target?.name]: ""
        })
    }
    const handleAddNotes = () => {
        setAddService([...addService, { service_name: "" }])
    }
    const handleremoveNotes = (index) => {
        if (addService.length > 1) {
            let data = addService.filter((item, i) => { return index !== i })
            setAddService(data)
        }
    }
    const handleValueChange = (i, e) => {
        const { value } = e.target
        let newData = addService?.map((item, index) => {
            if (index === i) {
                return {
                    ...item,
                    service_name: value
                }
            }
            else {
                return item
            }
        })
        setAddService(newData)
    }
    useEffect(() => {
        setData({
            ...data,
            service: addService
        })
    }, [addService])
    useEffect(() => {
        setData({
            ...data,
            carrier_id: selectCarrier?.id
        })
        setError({
            ...error,
            carrier_id: null
        })
    }, [selectCarrier])
    useEffect(() => {
        setData({
            ...data,
            service_type_id: selectService?.id
        })
        setError({
            ...error,
            service_type_id: null
        })
    }, [selectService])
    useEffect(() => {
        if (checked === true) {
            setData({
                ...data,
                is_sms_notification: 1
            })
        } else {
            setData({
                ...data,
                is_sms_notification: 0
            })
        }
    }, [checked])
    const handleSubmit = () => {
        if (data.service_provider_name === "" || data.service_type_id === "" || data.email === "" || data.phone_number === null || data.phone_number === undefined || !validEmail(data.email)) {
            setError({
                ...error,
                service_provider_name: data.service_provider_name === "" ? "Please Enter Service Provider Name" : error.service_provider_name,
                service_type_id: data.service_type_id === "" ? "Please Choose Service type" : error.service_type_id,
                email: data.email === "" ? "Please Enter Email" : !validEmail(data.email) ? "Please Enter Valid Email" : error.email,
                phone_number: data.phone_number === null || data.phone_number === undefined ? "Please Enter Phone Number" : error.phone_number
            })
            return false;
        }
        const obj = data;
        let array = Object.entries(obj)

        const formData = new FormData()
        for (let i = 0; i < array.length; i++) {
            if (array[i][0] === "service") {
                formData.append(array[i][0], JSON.stringify(array[i][1]))
            } else {
                formData.append(array[i][0], array[i][1])
            }
        }
        dispatch(addServiceProviderAction(formData, () => navigate("/account-owner/manage-providers")))
    }
    const isLoading = useSelector((state) => state.loader.isLoading)
    return (
        <>

            <ContentArea>
            {isLoading === true ? <Loader/>:
                <div className="add_provider_container">
                    <div className="p-3">
                        <div className="grid card ml-0 mr-0 px-5 mb-5 align-items-center bg-main-light border-round-lg">
                            <h2 className="text-main">
                                Add Service Providers Details
                            </h2>
                        </div>
                        <Card className="shadow-2 p-3 border-round-lg">
                            <div className="p-fluid grid">
                                <div className="field col-12 md:col-6">
                                    <InputField label="Service Provider Name" name="service_provider_name" required className="border-round-md mt-2" onChange={(e) => handleChange(e)} errorMessage={error?.service_provider_name} value= {data?.service_provider_name} />
                                </div>

                                <div className="field col-12 md:col-6">
                                    <DropdownField label="Service Type" className="border-round-md mt-2" inputId="dropdown" options={service} optionLabel="program_name" placeholder="Select" onChange={(e) => setSelectService(e.value)} value={selectService} required errorMessage={error?.service_type_id} />
                                </div>
                                {addService?.length > 0 && addService?.map((item, index) => {
                                    return (
                                        <div className="field col-12 md:col-4">
                                            <label className="font block mb-2">Service {index + 1}</label>
                                            <span className="p-input-icon-right">
                                                <i className="pi pi-times-circle" onClick={() => handleremoveNotes(index)} />
                                                <InputField name="service_1" onChange={(e) => handleValueChange(index, e)} value={item.service} />
                                            </span>
                                        </div>
                                    )
                                })}

                                <div className="field col-12 md:col-12 text-main">
                                    <Button
                                        label="Add"
                                        icon="pi pi-plus" iconPos="right"
                                        className=" save_button text-center w-max border-0 text-lg font-medium karla border-round-lg bg-main "
                                        onClick={() => handleAddNotes()}
                                    />
                                </div>
                                <div className="field col-12 md:col-12 text-main ">
                                    <h2 className="p-0 m-0 font-bold" style={{ fontSize: "22px" }}>Service Provider Address*</h2>
                                </div>

                                <div className="field col-12 md:col-4">
                                    <InputField name="address" label=" Street Address" onChange={(e) => handleChange(e)} value ={data?.address}/>
                                </div>

                                <div className="field col-12 md:col-4">
                                    <InputField name="state" label="State" onChange={(e) => handleChange(e)} value = {data?.state} />
                                </div>


                                <div className="field col-12 md:col-4">
                                    <InputNumberField label="Zip Code" name="zip_code" onValueChange={(e) => handleNumberChange(e)} useGrouping={false} maxLength={5} value={data.zip_code} inputClassName="border-round-md"/>
                                </div>

                                <div className="field col-12 md:col-12 text-main ">
                                    <h2 className="p-0 m-0 font-bold" style={{ fontSize: "22px" }}>Contact No*</h2>
                                </div>

                                <div className="field col-12 md:col-4">
                                    <InputField name="email" label="Email Address" onChange={(e) => handleChange(e)} errorMessage={error?.email} value= {data?.email} required/>
                                </div>

                                <div className="field col-12 md:col-4">
                                    <DropdownField label="Select Carrier" className="border-round-md mt-2"
                                        placeholder="Select"
                                        optionLabel="carrier_name" options={carrier} onChange={(e) => setSelectCarrier(e.value)} value={selectCarrier} />
                                </div>

                                <div className="field col-12 md:col-4">
                                    <InputNumberField label="Phone Number" onValueChange={(e) => handleNumberChange(e)} name="phone_number" useGrouping={false} maxLength={10} errorMessage={error?.phone_number} value ={data?.phone_number} required inputClassName="border-round-md"/>
                                </div>
                                <div className="col-12 md:col-12 mb-3">
                                    <Checkbox onChange={e => setChecked(e.checked)} checked={checked}></Checkbox> &nbsp; Check to receive the SMS reminder notifications.
                                </div>
                            </div>
                            <div className="col-12 md:col-4 mt-1">
                                    <div className="submitButton flex mt-3">
                                        <SaveButton label="Save" onClick={handleSubmit}></SaveButton>
                                        <CancelButton label="Cancel" onClick={() => navigate("/account-owner/manage-providers")}></CancelButton>
                                    </div>
                                </div>
                        </Card>
                    </div>
                </div >}
            </ContentArea > 
        </>
    )
}

export default AddProviders;
