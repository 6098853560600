
import React, { useState, useEffect } from 'react'
import ContentArea from "../../../../shared/ContentArea";
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import { viewLeads, getCarrier } from '../../../../utils/action';
import { useParams } from 'react-router-dom';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Dropdown } from "primereact/dropdown";
import { Card } from 'primereact/card';
import { Calendar } from 'primereact/calendar';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import Table from '../../../../components/TableList/Table';
export default function ViewLeads() {
    const [value, setValue] = useState()
    const [isLoading, setIsLoading] = useState(false)
    const [carrier, setCarrier] = useState("")
    const [selectedCarrier, setSelectedCarrier] = useState(null)
    const [selectedCompanyCarrier, setSelectedCompanyCarrier] = useState(null)
    const [selectStatus, setSelectStatus] = useState(null)
    const [tableData,setTableData] =  useState("")
    const { id } = useParams()
    const handleView = async () => {
        setIsLoading(true)
        let response = await viewLeads(id)
        setValue(response.data)
        setTableData(response.data?.lead_note)
        setIsLoading(false)
    }
    useEffect(() => {
        handleView()
    }, [])
    useEffect(() => {
        async function fetchMyAPI() {
            let response = await getCarrier();
            setCarrier(response.data)
        }
        fetchMyAPI();
    }, [])
    useEffect(() => {
        carrier.length > 0 && carrier?.map((item) => {
            if (item.id == value?.carrier) {
                setSelectedCarrier(item)
            }
            if (item.id == value?.company_carrier) {
                setSelectedCompanyCarrier(item)
            }
        })
    }, [carrier])
    const handleCarrier = (e) => {
        setSelectedCarrier(e.value)
    }
    const status = [
        { name: 'intial_contact', code: 'NY' },
        { name: 'follow_up', code: 'RM' },
        { name: 'not_interested', code: 'LDN' },
        { name: 'sold', code: 'IST' },
    ];
    useEffect(()=>{
        status?.map((item)=>{
            if(item?.name === value?.lead_status){
                setSelectStatus(item)
            }
        })
    },[value])
     const TableHeading = [
        { field: "SrNo", header: "Sr. No.", body: "srnoTemplates" },
        { field: "date", header: "Date", sortable: true, body: "addLeadsDateTemplate" },
        { field: "note", header: "Note", sortable: true },
    ];
    return (
        <>
            {isLoading === true ? <ProgressSpinner style={{ width: '50px', height: '50px' }} className='text-center h-screen flex justify-content-center' strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" /> :
                <>
                    <ContentArea>
                        <div className="p-3">

                            <div className="grid card ml-0 mr-0 px-5 mb-5 align-items-center bg-main-light border-round-lg">
                                <h2 className="text-main">
                                    View Leads
                                </h2>
                            </div>
                            <div className="shadow-2 p-5 border-round-lg">
                                <h2 className="text-main mb-5 p-0 m-0 Karla font-bold" style={{fontSize:"22px"}}>Leads Details</h2>
                                <div className="p-fluid grid mb-3">
                                    <div className="col-12 md:col-4 mb-3 ">
                                        <label className="font block mb-2">
                                            Lead Id<code className="text-red">*</code>
                                        </label>
                                        <InputText
                                            id=""
                                            className="border-round-md "
                                            name="lead_id"
                                            value={value?.lead_id}
                                            disabled
                                        />

                                    </div>
                                    <div className=" col-12 md:col-4 mb-3">
                                        <label className="font block mb-2">
                                            First Name<code className="text-red">*</code>
                                        </label>
                                        <InputText
                                            id=""
                                            className="border-round-md "
                                            name="first_name"
                                            placeholder=''
                                            value={value?.first_name}
                                            disabled
                                        />
                                    </div>
                                    <div className="col-12 md:col-4 mb-3 ">
                                        <label className="font block mb-2">Middle Name</label>
                                        <InputText
                                            id=""
                                            className="border-round-md "
                                            name="middle_name"
                                            placeholder=''
                                            value={value?.middle_name}
                                            disabled
                                        />
                                    </div>

                                    <div className="col-12 md:col-4 mb-3">
                                        <label className="font block mb-2">
                                            Last Name<code className="text-red">*</code>
                                        </label>
                                        <InputText
                                            id=""
                                            className="border-round-md "
                                            name="last_name"
                                            placeholder=''
                                            value={value?.last_name}
                                            disabled
                                        />
                                    </div>
                                    <div className="col-12 md:col-4 mb-3">
                                        <label className="font block mb-2">
                                            Email<code className="text-red">*</code>
                                        </label>
                                        <InputText
                                            id=""
                                            className="border-round-md "
                                            name="email"
                                            placeholder=''
                                            value={value?.email}
                                            disabled
                                        />
                                    </div>
                                    <div className="col-12 md:col-4 mb-3 ">
                                        <label className="font block mb-2">
                                            Carrier<code className="text-red">*</code>
                                        </label>
                                        <Dropdown className="border-round-md" disabled name="carrier" options={carrier} optionLabel="carrier_name" value={selectedCarrier} onChange={(e) => handleCarrier(e)} />
                                    </div>

                                    <div className="col-12 md:col-4 mb-3">
                                        <label className="font block mb-2">
                                            Phone No.<code className="text-red">*</code>
                                        </label>
                                        <InputNumber
                                            id=""
                                            inputClassName='border-round-md '
                                            className="border-round-md "
                                            name="phone_number."
                                            placeholder=''
                                            value={value?.phone_number}
                                            useGrouping={false}
                                            disabled
                                        />
                                    </div>
                                    <div className="col-12 md:col-4 mb-3">
                                        <label className="font block mb-2">
                                            Social Security No.
                                        </label>
                                        <InputText
                                            id=""
                                            inputClassName='border-round-md '
                                            className="border-round-md "
                                            name="social_security_no."
                                            placeholder=''
                                            useGrouping={false}
                                            value={value?.social_security_no === "null" ? "" : value?.social_security_no}
                                            disabled
                                        />
                                    </div>
                                    <div className="col-12 md:col-4 mb-3">
                                        <label className="font block mb-2">
                                            Status
                                        </label>
                                        <Dropdown className='border-round-md' placeholder="Select" disabled options={status} optionLabel='name' value={selectStatus}  />
                                    </div>
                                    <div className="col-12 md:col-12 mb-3">
                                        <label className="font block mb-2">
                                            Add Note
                                        </label>
                                        <InputTextarea className='border-round-md' rows={5} disabled cols={5} value={value?.add_notes} name="add_notes" />
                                    </div>
                                    <div className='col-12 md:col-12'>
                                        <h2 className="text-main mb-3 p-0 m-0 Karla font-bold" style={{fontSize:"22px"}}>Address Details</h2>
                                    </div>
                                    <div className="col-12 md:col-6 mb-3 ">
                                        <label className="font block mb-2">
                                            Address 1<code className="text-red">*</code>
                                        </label>
                                        <InputText
                                            id=""
                                            className="border-round-md "
                                            name="address1"
                                            placeholder=''
                                            value={value?.address_1}
                                            disabled
                                        />
                                    </div>

                                    <div className="col-12 md:col-6 mb-3 ">
                                        <label className="font block mb-2">
                                            Address 2
                                        </label>
                                        <InputText
                                            id=""
                                            className="border-round-md "
                                            name="addres2"
                                            placeholder=''
                                            value={value?.address_2 === "null" ? "" : value?.address_2}
                                            disabled
                                        />
                                    </div>

                                    <div className="col-12 md:col-4 mb-3 ">
                                        <label className="font block mb-2">
                                            City<code className="text-red">*</code>
                                        </label>
                                        <InputText
                                            id=""
                                            className="border-round-md "
                                            name="city"
                                            placeholder=''
                                            value={value?.city}
                                            disabled
                                        />
                                    </div>

                                    <div className="col-12 md:col-4 mb-3 ">
                                        <label className="font block mb-2">
                                            State<code className="text-red">*</code>
                                        </label>
                                        <InputText
                                            id=""
                                            className="border-round-md "
                                            name="state"
                                            placeholder=''
                                            value={value?.state}
                                            disabled
                                        />
                                    </div>

                                    <div className="col-12 md:col-4 mb-3">
                                        <label className="font block mb-2">
                                            Zip Code<code className="text-red">*</code>
                                        </label>
                                        <InputNumber
                                            id=""
                                            inputClassName='border-round-md '
                                            className="border-round-md "
                                            name="zip_code."
                                            placeholder=''
                                            maxLength={6}
                                            useGrouping={false}
                                            value={value?.zipcode}
                                            disabled
                                        />
                                    </div>


                                    <div className='col-12 md:col-12'>
                                        <h2 className="text-main mb-3 p-0 m-0 Karla font-bold " style={{fontSize:"22px"}}>Company Details</h2>
                                    </div>

                                    <div className="col-12 md:col-4 mb-3 ">
                                        <label className="font block mb-2">
                                            Company Name<code className="text-red">*</code>
                                        </label>
                                        <InputText
                                            id=""
                                            className="border-round-md "
                                            name="company_name"
                                            placeholder=''
                                            value={value?.company_name}
                                            disabled
                                        />
                                    </div>

                                    <div className="col-12 md:col-4 mb-3 ">
                                        <label className="font block mb-2">
                                            Company Email<code className="text-red">*</code>
                                        </label>
                                        <InputText
                                            id=""
                                            className="border-round-md "
                                            name="company_email"
                                            placeholder=''
                                            value={value?.company_email}
                                            disabled
                                        />
                                    </div>

                                    <div className="col-12 md:col-4 mb-3">
                                        <label className="font block mb-2">
                                            Company Contact No.<code className="text-red">*</code>
                                        </label>
                                        <InputNumber
                                            id=""
                                            inputClassName='border-round-md '
                                            className="border-round-md "
                                            name="company_contact_no."
                                            placeholder=''
                                            useGrouping={false}
                                            value={value?.company_mobile}
                                            disabled
                                        />
                                    </div>

                                    <div className="col-12 md:col-6 mb-3 ">
                                        <label className="font block mb-2">
                                            Carrier<code className="text-red">*</code>
                                        </label>
                                        <Dropdown className="border-round-md" disabled name="carrier" options={carrier} optionLabel="carrier_name" value={selectedCompanyCarrier} onChange={(e) => handleCarrier(e)} />
                                    </div>

                                    <div className="col-12 md:col-6 mb-3">
                                        <label className="font block mb-2">
                                            Phone No.<code className="text-red">*</code>
                                        </label>
                                        <InputNumber
                                            id=""
                                            inputClassName='border-round-md '
                                            className="border-round-md "
                                            name="phone_number."
                                            placeholder=''
                                            useGrouping={false}
                                            value={value?.company_alt_mobile}
                                            disabled
                                        />
                                    </div>

                                    <div className="col-12 md:col-6 mb-3 ">
                                        <label className="font block mb-2">
                                            Address 1<code className="text-red">*</code>
                                        </label>
                                        <InputText
                                            id=""
                                            className="border-round-md "
                                            name="address1"
                                            placeholder=''
                                            value={value?.company_address_1}
                                            disabled
                                        />
                                    </div>

                                    <div className="col-12 md:col-6 mb-3 ">
                                        <label className="font block mb-2">
                                            Address 2
                                        </label>
                                        <InputText
                                            id=""
                                            className="border-round-md "
                                            name="addres2"
                                            placeholder=''
                                            value={value?.company_address_2 === "null" ? "" : value?.company_address_2}
                                            disabled
                                        />
                                    </div>

                                    <div className="col-12 md:col-4 mb-3 ">
                                        <label className="font block mb-2">
                                            City<code className="text-red">*</code>
                                        </label>
                                        <InputText
                                            id=""
                                            className="border-round-md "
                                            name="city"
                                            placeholder=''
                                            value={value?.company_city}
                                            disabled
                                        />
                                    </div>

                                    <div className="col-12 md:col-4 mb-3 ">
                                        <label className="font block mb-2">
                                            State<code className="text-red">*</code>
                                        </label>
                                        <InputText
                                            id=""
                                            className="border-round-md "
                                            name="state"
                                            placeholder=''
                                            value={value?.company_state}
                                            disabled
                                        />
                                    </div>

                                    <div className="col-12 md:col-4 mb-3">
                                        <label className="font block mb-2">
                                            Zip Code<code className="text-red">*</code>
                                        </label>
                                        <InputNumber
                                            id=""
                                            inputClassName='border-round-md '
                                            className="border-round-md "
                                            name="zip_code."
                                            placeholder=''
                                            useGrouping={false}
                                            value={value?.company_zipcode}
                                            maxLength={6}
                                            disabled
                                        />
                                    </div>
                                </div>
                                <p className='text-main font-bold'style={{fontSize:"22px"}}>Follow Up Notes</p>
                                <Table
                                tableHeading={TableHeading}
                                tableData={tableData}
                                />
                            </div>
                        </div>
                    </ContentArea>
                </>
            }
        </>
    )
}
