import React from "react";
import { Button } from "primereact/button";
export default function SaveButton({
  label,
  onClick,
  className,
  btnclass,
  save_button = true,
  style,
  disabled= false
}) {
  return (
    <div className="submit_button">
      <Button
        onClick={onClick}
        label={label}
        style={style}
        className={`${
          save_button ? "save_button " : "w-full mb-3"  
        } text-center  border-0 text-lg font-medium karla p-3 border-round-lg bg-main `}
        disabled={disabled}
        
      />
    </div>
  );
}
