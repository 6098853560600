
import React, { useState, useEffect } from "react";
import { Dropdown } from 'primereact/dropdown';
import { Card } from "primereact/card";
import { InputNumber } from 'primereact/inputnumber';
import ContentArea from "../../shared/ContentArea";
import InputField from "../../../../components/input/input";
import { Calendar } from 'primereact/calendar';
import SaveButton from "../../../../components/Buttons/SaveButton";
import CancelButton from "../../../../components/Buttons/CancelButton";
import { useDispatch, useSelector } from "react-redux";
import { getEmployeesAction } from "../../../../Redux/HospitalAction/employessAction";
import { viewEmployeeCertification, editEmployeeCertification, getEmployeeCertificationList } from "../../../../Redux/HospitalAction/certificationAction";
import { useParams, useNavigate } from "react-router-dom";
import DropdownField from "../../../../components/input/dropdown";
import moment from "moment";
import Loader from "../../../../pages/Loader";
const EditIndividualCertification = () => {
    const dispatch = useDispatch()
    const { id } = useParams()
    const navigate = useNavigate()
    const [employee, setEmployee] = useState(null)
    const [status, setStatus] = useState(null);
    const [certification, setCertification] = useState(null);
    const [data, setData] = useState({
        employee_id: "",
        employee_name: "",
        certificate_id: "",
        last_date_certificate_completion: "",
        certificate_next_date: "",
        status: "",
        time: "",
        reminder: [
            {
                "reminder_type": ""
            },
            {
                "reminder_type": ""
            },
            {
                "reminder_type": ""
            },
        ]
    })
    const [error, setError] = useState("")
    useEffect(() => {
        dispatch(getEmployeesAction())
        dispatch(getEmployeeCertificationList())
        dispatch(viewEmployeeCertification(id))
    }, [])
    const getEmployeeData = useSelector((state) => state.getEmployee.getEmployeeProfile)
    const getEmployeeCertificationData = useSelector((state) => state.getEmployeeCertication.getEmployeeCertificationProfile)
    const viewEmployeeCertication = useSelector((state) => state.viewEmployeeCertificationList.viewEmployeeCerticationListProfile)
    useEffect(() => {
        if (data) {
            let newaray = []
            viewEmployeeCertication?.reminder?.map((item) => {
                let newReminder = {
                    reminder_type: item.reminder
                }
                newaray.push(newReminder)
            })

            let payload = {
                employee_id: viewEmployeeCertication?.employee_id,
                employee_name: viewEmployeeCertication?.employee_name,
                certificate_id: viewEmployeeCertication?.certificate_id,
                last_date_certificate_completion: new Date(viewEmployeeCertication?.last_date),
                certificate_next_date: new Date(viewEmployeeCertication?.next_date),
                status: viewEmployeeCertication.status,
                time: new Date(viewEmployeeCertication?.time),
                reminder: newaray
            }
            setData(payload)
        }

    }, [viewEmployeeCertication])
    useEffect(() => {
        getEmployeeData?.length > 0 && getEmployeeData?.map((item) => {
            if (item.id == data.employee_id) {
                setEmployee(item)
            }
        })
    }, [getEmployeeData, data])
    useEffect(() => {
        getEmployeeCertificationData?.length > 0 && getEmployeeCertificationData?.map((item) => {
            if (item.id == data?.certificate_id) {
                setCertification(item)
            }
        })
    }, [getEmployeeCertificationData, data])
    const Status = [
        { name: 'Active', value: "active" },
        { name: 'Inactive', value: "inactive" },
    ];
    const handleEmployee = (e) => {
        setData({
            ...data,
            employee_id: e.value?.id,
            employee_name: e.value?.name
        })
        setError({
            ...error,
            employee_id: null
        })
    }
    const handleCertification = (e) => {
        setData({
            ...data,
            certificate_id: e.value.id,
        })
        setError({
            ...error,
            certificate_id: null
        })
    }
    const time = [
        { label: "10 min", value: "10min" },
        { label: "15 min", value: "15min" },
        { label: "30 min", value: "30min" },
        { label: "01 hour", value: "01hour" },
        { label: "02 hour", value: "02hour" },
        { label: "12hour", value: "12hour" },
        { label: "01 Day", value: "01Day" },
        { label: "02Days", value: "02Days" },
        { label: "07 Days", value: "07Days" },
        { label: "15Days", value: "15Days" },
        { label: "30 Days", value: "30Days" },
    ]
    const handleReminder = (e, index) => {
        let array = [...data.reminder]
        let obj = { ...data.reminder[index], reminder_type: e.value }
        array?.splice(index, 1, obj)
        setData({
            ...data,
            reminder: array
        })
    }
    const handleSubmit = () => {
        if (data.employee_id === "" || data.certificate_id === undefined || data.last_date_certificate_completion === null || data.certificate_next_date === null || data.time === null) {
            setError({
                ...error,
                employee_id: data.employee_id === "" ? "Please Select Employee" : error.employee_id,
                certificate_id: data.certificate_id === undefined ? "Please Select Certificate" : error.certificate_id,
                last_date_certificate_completion: data.last_date_certificate_completion === null ? "Please Select Last Date" : error.last_date_certificate_completion,
                certificate_next_date: data.certificate_next_date === null ? "Please Choose Next Date" : error.certificate_next_date,
                time: data.time === null ? "Please Select Time" : error.time
            })
            return false;
        }
        const obj = data;
        let array = Object.entries(obj)
        const formData = new FormData()
        for (let i = 0; i < array.length; i++) {
            if (array[i][0] === "last_date_certificate_completion") {
                formData.append(array[i][0], moment(data.last_date_certificate_completion).format("DD-MM-YYYY"))
            } else if (array[i][0] === "certificate_next_date") {
                formData.append(array[i][0], moment(data.certificate_next_date).format("DD-MM-YYYY"))
            } else if (array[i][0] === "reminder") {

                formData.append(array[i][0], JSON.stringify(data.reminder))
            } else if (array[i][0] === "time") {
                formData.append(array[i][0], data?.time?.getHours() + ":" + data?.time?.getMinutes())
            }
            else {
                formData.append(array[i][0], array[i][1])
            }
        }
        dispatch(editEmployeeCertification(formData, id, () => navigate("/account-owner/manage-individual-certification")))
    }
    const isLoading = useSelector((state) => state.loader.isLoading)
    return (
        <>
            {isLoading === true ? <Loader /> :
                <ContentArea>
                    <div className="edit_individual_certification_container">
                        <div className="p-3">
                            <div className="grid card ml-0 mr-0 px-5 mb-5 align-items-center bg-main-light border-round-lg">
                                <h2 className="text-main">
                                    Edit Employee Certification
                                </h2>
                            </div>
                            <Card className="shadow-2 p-3 border-round-lg">
                                <div className="p-fluid grid mb-3">
                                    <div className="col-12 md:col-6 mb-3">
                                        <DropdownField label="Select Employee" inputId="dropdown" value={employee}
                                            options={getEmployeeData} onChange={(e) => handleEmployee(e)} optionLabel="emp_id" required filter />
                                        {error.employee_id && <span className="error">{error.employee_id}</span>}
                                    </div>
                                    <div className="col-12 md:col-6 mb-3">
                                        <InputField label="Employee Name" value={data?.employee_name} disabled />
                                    </div>

                                    <div className="col-12 md:col-6 mb-3">
                                        <DropdownField inputId="dropdown" label="Select Certification" value={certification} required
                                            options={getEmployeeCertificationData} onChange={(e) => handleCertification(e)} optionLabel="certification_name" />
                                        {error.certificate_id && <span className="error">{error.certificate_id}</span>}
                                    </div>

                                    <div className="col-12 md:col-6 mb-3 ">
                                        <label className="font block mb-2">
                                            Last date of Certification Completion<code className="text-red">*</code>
                                        </label>
                                        <Calendar
                                            className="border-round-md calender_picker mb-2"
                                            inputClassName="calendar p-2 border-round-md "
                                            value={data?.last_date_certificate_completion}
                                            required={true}
                                            onChange={(e) => {
                                                setData({
                                                    ...data,
                                                    last_date_certificate_completion: e.value
                                                })
                                                setError({
                                                    ...error,
                                                    last_date_certificate_completion: null
                                                })
                                            }}
                                        />
                                        {error.last_date_certificate_completion && <span className="error">{error.last_date_certificate_completion}</span>}
                                    </div>
                                    <div className="col-12 md:col-6 mb-3">
                                        <label className="font block mb-2">Select Status</label>
                                        <Dropdown inputId="dropdown" placeholder="Active" value={data.status} className="border-round-md"
                                            options={Status} onChange={(e) => setData({
                                                ...data,
                                                status: e.value
                                            })} optionLabel="name" />
                                    </div>


                                    <div className="col-12 md:col-12">
                                        <h2 className="text-main font-bold p-0 " style={{ fontSize: "22px" }} >Next date of Certification Completion</h2>
                                    </div>
                                    <div className="col-12 md:col-6 mb-3 ">
                                        <label className="font block mb-2">
                                            Select Date<code className="text-red">*</code>
                                        </label>
                                        <Calendar
                                            className="border-round-md calender_picker mb-2"
                                            inputClassName="calendar p-2 border-round-md "
                                            value={data.certificate_next_date}
                                            required
                                            onChange={(e) => {
                                                setData({
                                                    ...data,
                                                    certificate_next_date: e.value
                                                })
                                                setError({
                                                    ...error,
                                                    certificate_next_date: null
                                                })
                                            }}
                                            minDate={data?.last_date_certificate_completion}
                                        />
                                        {error.certificate_next_date && <span className="error">{error.certificate_next_date}</span>}
                                    </div>
                                    <div className="col-12 md:col-6 mb-3 ">
                                        <label className="font block mb-2">
                                            Select Time<code className="text-red">*</code>
                                        </label>
                                        <Calendar
                                            className="border-round-md clock_picker mb-2"
                                            inputClassName="calendar p-2 border-round-md "
                                            placeholder="Select"
                                            timeOnly hourFormat="24"
                                            value={data.time}
                                            onChange={(e) => {
                                                setError({
                                                    ...error,
                                                    time: null
                                                })
                                                setData({
                                                    ...data,
                                                    time: e.target.value
                                                })
                                            }}
                                        />
                                        {error.time && <span className="error">{error.time}</span>}
                                    </div>
                                    <div className="col-12 md:col-12">
                                        <h2 className="text-main font-bold p-0 " style={{ fontSize: "22px" }} >Set Appointment Reminders</h2>
                                    </div>
                                    {data?.reminder?.length > 0 && data?.reminder?.map((item, index) => {
                                        return (
                                            <div className="col-12 md:col-4 mb-5">
                                                <label className="font block mb-2">Reminder {index + 1}{index === 0 ? <code className="text-red">*</code> : ""}</label>
                                                <Dropdown inputId="dropdown" placeholder="Select" className="border-round-md" value={item.reminder_type} onChange={(e) => handleReminder(e, index)} options={time} optionLabel="label" />
                                            </div>
                                        )
                                    })}

                                </div>
                                <div className="submitButton flex">
                                    <SaveButton label="Save" onClick={handleSubmit}></SaveButton>
                                    <CancelButton label="Cancel" onClick={() => navigate("/account-owner/manage-individual-certification")}></CancelButton>
                                </div>
                            </Card>
                        </div>
                    </div>

                </ContentArea>
            }
        </>
    )
}

export default EditIndividualCertification;
