import { types } from "../HospitalType/types";

const initialState = {
    timeProfile: []
}

export const timeClockReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.TIME_CLOCK:
            return {
                ...state,
                timeProfile: action.payload,
            };
        default:
            return { ...state };
    }
}