
import React, { useState } from "react";
import InputField from "../../../../components/input/input";
import { Card } from "primereact/card";
import ContentArea from "../../../../shared/ContentArea";
import SaveButton from "../../../../components/Buttons/SaveButton";
import CancelButton from "../../../../components/Buttons/CancelButton";
import DropdownField from "../../../../components/input/dropdown";
import { addClientPaCodeAction, getClientAction } from "../../../../Redux/HospitalAction/clientAction";
import { getServiceAction } from "../../../../Redux/HospitalAction/serviceProviderAction";
import { getPaCodesAction } from "../../../../Redux/HospitalAction/paCodeAction";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
const AddClientPaCode = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [Service, Setservice] = useState(null);
    const [client, setClient] = useState(null);
    const [pacode, setPaCode] = useState(null);
    const [pacodeData, setPaCodeData] = useState(null)
    const [data, setData] = useState({
        client_id: "",
        pa_code_id: "",
        annual_allotment: "",
        no_of_unit: "",
        max_day_allowed: "",
        client_name: ""
    })
    useEffect(() => {
        dispatch(getClientAction())
        dispatch(getServiceAction())
        dispatch(getPaCodesAction())
    }, [])
    const getService = useSelector((state) => state.getService.getServiceProfile)
    const getallClient = useSelector((state) => state.getClient.getClientProfile)
    const getPaCode = useSelector((state) => state.getPaCode.getPaCodeProfile)
    const [error, setError] = useState("")
    useEffect(() => {
        let array = []
        getPaCode?.length > 0 && getPaCode.map((item, index) => {
            let obj = {
                id: item.id,
                pa_code: item.pa_code,
                service_type_id: item.service_type_id
            }
            array.push(obj)
        })
        setPaCodeData(array)
    }, [getPaCode])
    const handleChange = (e) => {
        const { name, value } = e.target
        setData({
            ...data,
            [name]: value
        })
        setError({
            ...error,
            [name]:null
        })
    }
    useEffect(() => {
        setError({
            ...error,
            client_id: null
        })
        setData({
            ...data,
            client_id: client?.id,
            client_name: client?.name,
            pa_code_id: pacode?.id
        })
    }, [client, pacode])
    useEffect(()=>{
        setError({
            ...error,
            pa_code_id: null
        })
        setData({
            ...data,
            pa_code_id: pacode?.id
        })
    },[pacode])
    useEffect(() => {
        getService?.length > 0 && getService.map((item, index) => {
            if (item.id == pacode?.service_type_id) {
                Setservice(item)
            }
        })
    }, [pacode, getService])
    const handleSubmit = () => {
        if (data.client_id === undefined ||data.client_id === ""  || data.pa_code_id === undefined || data.annual_allotment === "" || data.no_of_unit === "" || data.max_day_allowed === "") {
            setError({
                ...error,
                client_id: data.client_id === undefined  ||data.client_id === ""? "Please Select Client" : error.client_id,
                pa_code_id: data.pa_code_id === undefined ? "Please Select Pa Code" : error.pa_code_id,
                annual_allotment: data.annual_allotment === '' ? "Please Enter Annual Allotment" : error.annual_allotment,
                no_of_unit: data.no_of_unit === "" ? "Please Enter Number Of Unit" : error.no_of_unit,
                max_day_allowed: data.max_day_allowed === "" ? "Please Enter Max Days" : error.max_day_allowed
            })
            return false;
        }
        const obj = data;
        let array = Object.entries(obj)
        const formData = new FormData()
        for (let i = 0; i < array.length; i++) {
            formData.append(array[i][0], array[i][1])
        }
        dispatch(addClientPaCodeAction(formData, () => navigate("/account-owner/manage-client-pa-codes")))
    }
    return (
        <>
            <ContentArea>
                <div className="add_client_pa_code_container">
                    <div className="p-3">
                        <div className="grid card ml-0 mr-0 px-5 mb-5 align-items-center bg-main-light border-round-lg">
                            <h2 className="text-main">
                                Add Client PA Codes
                            </h2>
                        </div>
                        <Card className="shadow-2 p-3 border-round-lg">
                            <div className="p-fluid grid mb-5">
                                <div className="field col-12 md:col-6">
                                    <DropdownField label="Select Client "
                                        placeholder="Select"
                                        name="client_id"
                                        filter
                                        value={client} itemTemplate={(e) => {
                                            return (
                                              <>
                                                {e.profile_id} {e.first_name} {e.last_name}
                                              </>
                                            );
                                          }} options={getallClient}
                                        onChange={(e) => setClient(e.value)} optionLabel="profile_id" required />
                                            {error.client_id && <span className="error">{error.client_id}</span>}
                                </div>
                                <div className="field col-12 md:col-6">
                                    <InputField label="Client Name" value={data.client_name} disabled />
                                </div>

                                <div className="field col-12 md:col-6">
                                    <DropdownField label="PA Code"
                                        placeholder="Select"
                                        value={pacode} options={pacodeData}
                                        onChange={(e) => setPaCode(e.value)} optionLabel="pa_code" required />
                                        {error.pa_code_id && <span className="error">{error.pa_code_id}</span>}
                                </div>

                                <div className="field col-12 md:col-6">
                                    <InputField label="Annual Allotments" name="annual_allotment" value={data?.annual_allotment} onChange={handleChange} required />
                                    {error.annual_allotment &&<span className="error">{error.annual_allotment}</span>}
                                </div>
                                <div className="field col-12 md:col-4">
                                    <InputField label="No. of Units Per Hour " name="no_of_unit" value={data?.no_of_unit} onChange={handleChange} required />
                                    {error.no_of_unit &&  <span className="error">{error.no_of_unit}</span>}
                                </div>
                                <div className="field col-12 md:col-4">
                                    <InputField label="Max Days Allowed Per Month" name="max_day_allowed" value={data?.max_day_allowed} onChange={handleChange} required />
                                    {error.max_day_allowed && <span className="error">{error.max_day_allowed}</span>}
                                </div>
                                <div className="field col-12 md:col-4">
                                    <DropdownField label="Service Type"
                                        inputId="dropdown"
                                        placeholder="Select"
                                        value={Service}
                                        options={getService}
                                        onChange={(e) => Setservice(e.value)} optionLabel="program_name" disabled />
                                </div>

                            </div>
                            <div className="submitButton flex">
                                <SaveButton label="Save" onClick={handleSubmit}></SaveButton>
                                <CancelButton label="Cancel" onClick={() => navigate("/account-owner/manage-client-pa-codes")}></CancelButton>
                            </div>
                        </Card>
                    </div>
                </div>
            </ContentArea>

        </>
    )
}

export default AddClientPaCode;
