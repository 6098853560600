import { Component, useEffect, useState } from "react";
import { Chart } from "primereact/chart";

const RevenueChartData = () => {

   
    const [lineStylesData] = useState({
        labels: ['Jan23', 'Feb23', 'Mar23', 'Apr23', 'May23', 'Jun23', 'Jul23', "Aug23", "Sep23", "Oct23", "Nov23", "Dec23"],
        datasets: [
            {
                label: 'First Dataset',
                data: [450, 0, 350, 815, 1000, 750, 450, 725, 250, 110, 270, 175],
                fill: false,
                borderColor: '#42A5F5',
                tension: .1
            },
            
        ]
        
    });


    const getLightTheme = () => {
        let basicOptions = {
            maintainAspectRatio: false,
            aspectRatio: .6,
            plugins: {
                legend: {
                    labels: {
                        color: '#495057'
                    },
                    display: false
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef',
                        display:false
                    }
                    
                },
                y: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef'
                    }
                }
            }
        };

        return {
            basicOptions,
          };
       
    } 
    const { basicOptions } = getLightTheme();

        return {
            basicOptions,
            lineStylesData
            
        }
    
}

export default RevenueChartData;