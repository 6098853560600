import React from "react";
import "./App.scss";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import { Routes, Route, HashRouter } from "react-router-dom";
import "./assets/sass/layout/layout.scss";
import AccontOwnerLayout from "./portal/account-owner/shared/AccountOwnerLayout";
import SalesPersonDashboard from "./views/sales-person/dashboard/index";
import LandingPage from "./pages/LandingPage";

import Login from "./pages/Login";
import ContactUs from "./pages/ContactUs";
import ManageLeads from "./views/sales-person/dashboard/leads";
import ManageClients from "./views/sales-person/dashboard/clients";
import CommissionsReports from "./views/sales-person/dashboard/reports";
import AddLeads from "./views/sales-person/dashboard/leads/AddLeads";
import ViewLeads from "./views/sales-person/dashboard/leads/ViewLeads";
import EditLeads from "./views/sales-person/dashboard/leads/EditLeads";
import AddClients from "./views/sales-person/dashboard/clients/AddClients";
import EditClients from "./views/sales-person/dashboard/clients/EditClients";
import ViewClients from "./views/sales-person/dashboard/clients/ViewClients";
import Pricing from "./pages/Pricing";
import { ProtectedRoute } from "./utils/ProtectedRoute";
import MainDashboard from "./portal/account-owner/MainDashboard";
import Layout from "./shared/Layout";
import ManageProviders from "./portal/account-owner/service-Providers";
import AddProviders from "./portal/account-owner/service-Providers/AddProviders";
import EditProviders from "./portal/account-owner/service-Providers/EditProviders";
import ViewProviders from "./portal/account-owner/service-Providers/ViewProviders";
import ManagePACodeList from "./portal/account-owner/manage-pa-Codes";
import AddPaCode from "./portal/account-owner/manage-pa-Codes/AddPaCode";
import EditPaCode from "./portal/account-owner/manage-pa-Codes/EditPaCode";
import ManageEmployees from "./portal/account-owner/Employee/manage-employee";
import AddEmployees from "./portal/account-owner/Employee/manage-employee/AddEmployees";
import EditEmployee from "./portal/account-owner/Employee/manage-employee/EditEmployees";
import ViewEmployees from "./portal/account-owner/Employee/manage-employee/ViewEmployees";
import ManageHourlyRate from "./portal/account-owner/Employee/manage-hourly-rate/index";
import AddHourlyRate from "./portal/account-owner/Employee/manage-hourly-rate/AddHourlyRate";
import EditHourlyRate from "./portal/account-owner/Employee/manage-hourly-rate/EditHourlyRate";
// import EmployeeScheduling from "./portal/account-owner/Employee/employees-scheduling";
// import EmployeeSchedule from "./portal/account-owner/Employee/employees-scheduling/EmployeeSchedule";
import EmployeeScheduling from "./portal/account-owner/Employee/employees-scheduling";
import AddScheduling from "./portal/account-owner/Employee/employees-scheduling/AddScheduling";
import EditScheduling from "./portal/account-owner/Employee/employees-scheduling/EditScheduling";
import ViewScheduling from "./portal/account-owner/Employee/employees-scheduling/ViewScheduling";
import ManageClientPaCode from "./portal/account-owner/Clients/client-pa-code/index";
import AddClientPaCode from "./portal/account-owner/Clients/client-pa-code/AddClientPaCode";
import EditClientPaCode from "./portal/account-owner/Clients/client-pa-code/EditClientPaCode";
import ViewClientPaCode from "./portal/account-owner/Clients/client-pa-code/ViewClientPaCode";
import ManageClientTier from "./portal/account-owner/Clients/manage-tier";
import AddClientTier from "./portal/account-owner/Clients/manage-tier/AddClientTier";
import EditClientTier from "./portal/account-owner/Clients/manage-tier/EditClientTier";
import ManageClientList from "./portal/account-owner/Clients/manage-client-list";
import AddClient from "./portal/account-owner/Clients/manage-client-list/AddClient";
import EditClient from "./portal/account-owner/Clients/manage-client-list/EditClient";
import ViewClient from "./portal/account-owner/Clients/manage-client-list/ViewClient";
import ManageEmployeeCertification from "./portal/account-owner/certifications/manage-employee-certification";
import AddEmployeeCertification from "./portal/account-owner/certifications/manage-employee-certification/AddEmployeeCertification";
import EditEmployeeCertification from "./portal/account-owner/certifications/manage-employee-certification/EditEmployeeCertification";
import ManageClientCertification from "./portal/account-owner/certifications/manage-client-certification/inedx";
import AddClientCertification from "./portal/account-owner/certifications/manage-client-certification/AddClientCertification";
import EditClientCertification from "./portal/account-owner/certifications/manage-client-certification/EditClientCertification";
import ManageIndividualCertification from "./portal/account-owner/certifications/manage-individual-certification/inedx";
import AddIndividualCertification from "./portal/account-owner/certifications/manage-individual-certification/AddIndividualCertification";
import EditIndividualCertification from "./portal/account-owner/certifications/manage-individual-certification/EditIndividualCertification";
import ViewIndividualCertification from "./portal/account-owner/certifications/manage-individual-certification/ViewIndividualCertification";
import ManageSchedules from "./portal/account-owner/Appointment & Scheduling/schedule-view/ManageSchedules";
import AddSchdeules from "./portal/account-owner/Appointment & Scheduling/schedule-view/AddSchedules";
import ManageService from "./portal/account-owner/Appointment & Scheduling/manage-services";
import AddManageService from "./portal/account-owner/Appointment & Scheduling/manage-services/AddManageService";
import EditManageService from "./portal/account-owner/Appointment & Scheduling/manage-services/EditManageService";
import ManageAppointments from "./portal/account-owner/Appointment & Scheduling/manage-appointment/ManageAppointments";
import BookAppointment from "./portal/account-owner/Appointment & Scheduling/manage-appointment/BookAppointment";
import MedicationSchedule from "./portal/account-owner/medication-schedule";
import AddSchedule from "./portal/account-owner/medication-schedule/AddSchedule";
import EditSchedule from "./portal/account-owner/medication-schedule/EditSchedule";
import ManageExpensesCategories from "./portal/account-owner/expenses-revenues/ManageExpensesCategories";
import AddExpenseCategories from "./portal/account-owner/expenses-revenues/ManageExpensesCategories/AddExpenseCategories";
import EditExpensesCategories from "./portal/account-owner/expenses-revenues/ManageExpensesCategories/EditExpensesCategories";
import ManageExpense from "./portal/account-owner/expenses-revenues/ManageExpenses/inedx";
import AddExpenses from "./portal/account-owner/expenses-revenues/ManageExpenses/AddExpenses";
import EditExpenses from "./portal/account-owner/expenses-revenues/ManageExpenses/EditExpenses";
import ViewExpenses from "./portal/account-owner/expenses-revenues/ManageExpenses/ViewExpenses";
import ManageRevenueCategories from "./portal/account-owner/expenses-revenues/ManageRevenueCategories";
import AddRevenueCategories from "./portal/account-owner/expenses-revenues/ManageRevenueCategories/AddRevenueCategories";
import EditRevenueCategories from "./portal/account-owner/expenses-revenues/ManageRevenueCategories/EditRevenueCategories";
import ManageRevenue from "./portal/account-owner/expenses-revenues/ManageRevenue";
import AddRevenue from "./portal/account-owner/expenses-revenues/ManageRevenue/AddRevenue";
import EditRevenue from "./portal/account-owner/expenses-revenues/ManageRevenue/EditRevenue";
import ViewRevenue from "./portal/account-owner/expenses-revenues/ManageRevenue/ViewRevenue";
import ExpensesReport from "./portal/account-owner/Reports/ExpensesReport";
import RevenueReport from "./portal/account-owner/Reports/RevenueReport";
import ProfitLossReport from "./portal/account-owner/Reports/ProfitLossReport";
import ToastConatiner from "./shared/ToastContainer";
import ClientCertification from "./portal/account-owner/certifications/client-certification";
import AddCertification from "./portal/account-owner/certifications/client-certification/AddClient";
import ViewCertification from "./portal/account-owner/certifications/client-certification/viewClient";
import EditCertification from "./portal/account-owner/certifications/client-certification/editCertification";
import TimeClock from "./portal/account-owner/Time/TimeClock";
import ViewSchedulingData from "./portal/account-owner/Appointment & Scheduling/schedule-view/BookAppointmentShedule/ViewSchedulingData";
import ViewExpenseRevenue from "./portal/account-owner/Reports/ViewExpenseRevenue";
import EmployeeWorkingHours from "./portal/account-owner/Reports/EmployeeWorkingHours";
import AddEmployeeWorkingHour from "./portal/account-owner/Reports/AddEmployeeWorkingHours";
import EmployeeCertification from "./portal/account-owner/Reports/EmployeeCertification";
import ClientCertificationData from "./portal/account-owner/Reports/ClientCertificationData";
import EmployeePayReport from "./portal/account-owner/Reports/EmployeePayReport";
import Note from "./portal/account-owner/Notes/Note";
import AddNote from "./portal/account-owner/Notes/AddNote";
import MyProfile from "./portal/account-owner/MyProfile/MyProfile";
import Setting from "./portal/account-owner/Setting/Setting";
import AddSpecialSchedule from "./portal/account-owner/Employee/AddSpecialSchedule/AddSpecialSchedule";
import AddLeave from "./portal/account-owner/Employee/AddLeave/AddLeave";
import { ViewSchedulingDetails } from "./portal/account-owner/Employee/viewSchedulingDetails/ViewSchedulingDetails";
import { ViewAppointment } from "./portal/account-owner/Appointment & Scheduling/manage-appointment/ViewAppointment";
import About from "./pages/About";
export default function App() {
  return (
    <div>
      <ToastConatiner />
      <Routes>
        <Route path="*" element={<ProtectedRoute />} />
        <Route path="/" exact element={<LandingPage />} />
        <Route path="/about" exact element={<About />} />
        {/* <Route path="/work" exact element={<Home />} /> */}
        <Route path="/login" exact element={<Login />} />
        <Route path="/contact-us" exact element={<ContactUs />} />
        <Route path="/pricing" exact element={<Pricing />} />
        <Route
          path="/sales-person/dashboard"
          exact
          element={
            <Layout>
              <SalesPersonDashboard />
            </Layout>
          }
        />
        <Route
          path="/sales-person/manage-leads"
          exact
          element={
            <Layout>
              <ManageLeads />
            </Layout>
          }
        />
        <Route
          path="/sales-person/add-leads"
          exact
          element={
            <Layout>
              <AddLeads />
            </Layout>
          }
        />
        <Route
          path="/sales-person/view-leads/:id"
          exact
          element={
            <Layout>
              <ViewLeads />
            </Layout>
          }
        />
        <Route
          path="/sales-person/edit-leads/:id"
          exact
          element={
            <Layout>
              <EditLeads />
            </Layout>
          }
        />
        <Route
          path="/sales-person/manage-clients"
          exact
          element={
            <Layout>
              <ManageClients />
            </Layout>
          }
        />
        <Route
          path="/sales-person/add-client/:id"
          exact
          element={
            <Layout>
              <AddClients />
            </Layout>
          }
        />
        <Route
          path="/sales-person/add-clients"
          exact
          element={
            <Layout>
              <AddClients />
            </Layout>
          }
        />
        <Route
          path="/sales-person/edit-clients/:id"
          exact
          element={
            <Layout>
              <EditClients />
            </Layout>
          }
        />
        <Route
          path="/sales-person/view-clients/:id"
          exact
          element={
            <Layout>
              <ViewClients />
            </Layout>
          }
        />
        <Route
          path="/sales-person/commissions-reports"
          exact
          element={
            <Layout>
              <CommissionsReports />
            </Layout>
          }
        />
        <Route
          path="/account-owner/dashboard"
          exact
          element={
            <AccontOwnerLayout>
              <MainDashboard />
            </AccontOwnerLayout>
          }
        />
        <Route
          path="/account-owner/add-special_scheduling"
          exact
          element={
            <AccontOwnerLayout>
              <AddSpecialSchedule />
            </AccontOwnerLayout>
          }
        />
        <Route
          path="/account-owner/view_scheduling_details/:id"
          exact
          element={
            <AccontOwnerLayout>
              <ViewSchedulingDetails />
            </AccontOwnerLayout>
          }
        />
        <Route
          path="/account-owner/add-leave"
          exact
          element={
            <AccontOwnerLayout>
              <AddLeave />
            </AccontOwnerLayout>
          }
        />
        <Route
          path="/account-owner/my-profile"
          exact
          element={
            <AccontOwnerLayout>
              <MyProfile />
            </AccontOwnerLayout>
          }
        />
        <Route
          path="/account-owner/setting"
          exact
          element={
            <AccontOwnerLayout>
              <Setting />
            </AccontOwnerLayout>
          }
        />
        <Route
          path="/account-owner/manage-providers"
          exact
          element={
            <AccontOwnerLayout>
              <ManageProviders />
            </AccontOwnerLayout>
          }
        />
        <Route
          path="/account-owner/add-providers"
          exact
          element={
            <AccontOwnerLayout>
              <AddProviders />
            </AccontOwnerLayout>
          }
        />
        <Route
          path="/account-owner/edit-providers/:id"
          exact
          element={
            <AccontOwnerLayout>
              <EditProviders />
            </AccontOwnerLayout>
          }
        />
        <Route
          path="/account-owner/view-providers/:id"
          element={
            <AccontOwnerLayout>
              <ViewProviders />
            </AccontOwnerLayout>
          }
        />
        <Route
          path="/account-owner/manage-pa-code"
          element={
            <AccontOwnerLayout>
              <ManagePACodeList />
            </AccontOwnerLayout>
          }
        />
        <Route
          path="/account-owner/add-pa-code"
          element={
            <AccontOwnerLayout>
              <AddPaCode />
            </AccontOwnerLayout>
          }
        />
        <Route
          path="/account-owner/edit-pa-code/:id"
          element={
            <AccontOwnerLayout>
              <EditPaCode />
            </AccontOwnerLayout>
          }
        />
        <Route
          path="/account-owner/manage-employees"
          element={
            <AccontOwnerLayout>
              <ManageEmployees />
            </AccontOwnerLayout>
          }
        />
        <Route
          path="/account-owner/add-employees"
          element={
            <AccontOwnerLayout>
              <AddEmployees />
            </AccontOwnerLayout>
          }
        />
        <Route
          path="/account-owner/edit-employees/:id"
          element={
            <AccontOwnerLayout>
              <EditEmployee />
            </AccontOwnerLayout>
          }
        />
        <Route
          path="/account-owner/view-employees/:id"
          element={
            <AccontOwnerLayout>
              <ViewEmployees />
            </AccontOwnerLayout>
          }
        />
        <Route
          path="/account-owner/manage-hourly-rate"
          element={
            <AccontOwnerLayout>
              <ManageHourlyRate />
            </AccontOwnerLayout>
          }
        />
        <Route
          path="/account-owner/add-hourly-rate"
          element={
            <AccontOwnerLayout>
              <AddHourlyRate />
            </AccontOwnerLayout>
          }
        />
        <Route
          path="/account-owner/edit-hourly-rate/:id"
          element={
            <AccontOwnerLayout>
              <EditHourlyRate />
            </AccontOwnerLayout>
          }
        />
        <Route
          path="/account-owner/employees-scheduling"
          element={
            <AccontOwnerLayout>
              <EmployeeScheduling />
            </AccontOwnerLayout>
          }
        />
        <Route
          path="/account-owner/add-scheduling"
          element={
            <AccontOwnerLayout>
              <AddScheduling />
            </AccontOwnerLayout>
          }
        />
        <Route
          path="/account-owner/edit-scheduling/:id"
          element={
            <AccontOwnerLayout>
              <EditScheduling />
            </AccontOwnerLayout>
          }
        />
        <Route
          path="/account-owner/view-scheduling/:id"
          element={
            <AccontOwnerLayout>
              <ViewScheduling />
            </AccontOwnerLayout>
          }
        />
        <Route
          path="/account-owner/manage-client-pa-codes"
          element={
            <AccontOwnerLayout>
              <ManageClientPaCode />
            </AccontOwnerLayout>
          }
        />
        <Route
          path="/account-owner/add-client-pa-codes"
          element={
            <AccontOwnerLayout>
              <AddClientPaCode />
            </AccontOwnerLayout>
          }
        />
        <Route
          path="/account-owner/edit-client-pa-codes/:id"
          element={
            <AccontOwnerLayout>
              <EditClientPaCode />
            </AccontOwnerLayout>
          }
        />
        <Route
          path="/account-owner/view-client-pa-codes/:id"
          element={
            <AccontOwnerLayout>
              <ViewClientPaCode />
            </AccontOwnerLayout>
          }
        />
        <Route
          path="/account-owner/manage-tiers"
          element={
            <AccontOwnerLayout>
              <ManageClientTier />
            </AccontOwnerLayout>
          }
        />
        <Route
          path="/account-owner/manage-add-tier"
          element={
            <AccontOwnerLayout>
              <AddClientTier />
            </AccontOwnerLayout>
          }
        />
        <Route
          path="/account-owner/manage-edit-tier/:id"
          element={
            <AccontOwnerLayout>
              <EditClientTier />
            </AccontOwnerLayout>
          }
        />
        <Route
          path="/account-owner/manage-client-list"
          element={
            <AccontOwnerLayout>
              <ManageClientList />
            </AccontOwnerLayout>
          }
        />
        <Route
          path="/account-owner/add-client-details"
          element={
            <AccontOwnerLayout>
              <AddClient />
            </AccontOwnerLayout>
          }
        />
        <Route
          path="/account-owner/edit-client-details/:id"
          element={
            <AccontOwnerLayout>
              <EditClient />
            </AccontOwnerLayout>
          }
        />
        <Route
          path="/account-owner/view-client-details/:id"
          element={
            <AccontOwnerLayout>
              <ViewClient />
            </AccontOwnerLayout>
          }
        />
        <Route
          path="/account-owner/manage-employee-certification"
          element={
            <AccontOwnerLayout>
              <ManageEmployeeCertification />
            </AccontOwnerLayout>
          }
        />
        <Route
          path="/account-owner/add-employee-certification"
          element={
            <AccontOwnerLayout>
              <AddEmployeeCertification />
            </AccontOwnerLayout>
          }
        />
        <Route
          path="/account-owner/edit-employee-certification/:id"
          element={
            <AccontOwnerLayout>
              <EditEmployeeCertification />
            </AccontOwnerLayout>
          }
        />
        <Route
          path="/account-owner/manage-client-certification"
          element={
            <AccontOwnerLayout>
              <ManageClientCertification />
            </AccontOwnerLayout>
          }
        />
        <Route
          path="/account-owner/add-client-certification"
          element={
            <AccontOwnerLayout>
              <AddClientCertification />
            </AccontOwnerLayout>
          }
        />
        <Route
          path="/account-owner/edit-client-certification/:id"
          element={
            <AccontOwnerLayout>
              <EditClientCertification />
            </AccontOwnerLayout>
          }
        />
        <Route
          path="/account-owner/manage-individual-certification"
          element={
            <AccontOwnerLayout>
              <ManageIndividualCertification />
            </AccontOwnerLayout>
          }
        />
        <Route
          path="/account-owner/add-individual-certification"
          element={
            <AccontOwnerLayout>
              <AddIndividualCertification />
            </AccontOwnerLayout>
          }
        />
        <Route
          path="/account-owner/edit-individual-certification/:id"
          element={
            <AccontOwnerLayout>
              <EditIndividualCertification />
            </AccontOwnerLayout>
          }
        />
        <Route
          path="/account-owner/view-individual-certification/:id"
          element={
            <AccontOwnerLayout>
              <ViewIndividualCertification />
            </AccontOwnerLayout>
          }
        />
        <Route
          path="/account-owner/manage-schedules"
          element={
            <AccontOwnerLayout>
              <ManageSchedules />
            </AccontOwnerLayout>
          }
        />
        <Route
          path="/account-owner/client-certification"
          element={
            <AccontOwnerLayout>
              <ClientCertification />
            </AccontOwnerLayout>
          }
        />
        <Route
          path="/account-owner/add-certification"
          element={
            <AccontOwnerLayout>
              <AddCertification />
            </AccontOwnerLayout>
          }
        />
        <Route
          path="/account-owner/view-certification/:id"
          element={
            <AccontOwnerLayout>
              <ViewCertification />
            </AccontOwnerLayout>
          }
        />
        <Route
          path="/account-owner/edit-certification/:id"
          element={
            <AccontOwnerLayout>
              <EditCertification />
            </AccontOwnerLayout>
          }
        />
        <Route
          path="/account-owner/add-schedules"
          element={
            <AccontOwnerLayout>
              <AddSchdeules />
            </AccontOwnerLayout>
          }
        />
        <Route
          path="/account-owner/edit-schedules/:id"
          element={
            <AccontOwnerLayout>
              <AddSchdeules />
            </AccontOwnerLayout>
          }
        />
        <Route
          path="/account-owner/manage-services"
          element={
            <AccontOwnerLayout>
              <ManageService />
            </AccontOwnerLayout>
          }
        />
        <Route
          path="/account-owner/add-manage-services"
          element={
            <AccontOwnerLayout>
              <AddManageService />
            </AccontOwnerLayout>
          }
        />
        <Route
          path="/account-owner/add-manage-services/:id"
          element={
            <AccontOwnerLayout>
              <AddManageService />
            </AccontOwnerLayout>
          }
        />
        <Route
          path="/account-owner/edit-manage-services/:id"
          element={
            <AccontOwnerLayout>
              <EditManageService />
            </AccontOwnerLayout>
          }
        />
        <Route
          path="/account-owner/manage-appointment"
          element={
            <AccontOwnerLayout>
              <ManageAppointments />
            </AccontOwnerLayout>
          }
        />

        <Route
          path="/account-owner/view-appointment/:id"
          element={
            <AccontOwnerLayout>
              <ViewAppointment />
            </AccontOwnerLayout>
          }
        />
        <Route
          path="/account-owner/book-appointment"
          element={
            <AccontOwnerLayout>
              <BookAppointment />
            </AccontOwnerLayout>
          }
        />
        <Route
          path="/account-owner/medication-schedule"
          element={
            <AccontOwnerLayout>
              <MedicationSchedule />
            </AccontOwnerLayout>
          }
        />
        <Route
          path="/account-owner/add-schedule"
          element={
            <AccontOwnerLayout>
              <AddSchedule />
            </AccontOwnerLayout>
          }
        />
        <Route
          path="/account-owner/edit-schedule/:id"
          element={
            <AccontOwnerLayout>
              <EditSchedule />
            </AccontOwnerLayout>
          }
        />
        <Route
          path="/account-owner/view-schedule"
          element={
            <AccontOwnerLayout>
              <ViewSchedulingData />
            </AccontOwnerLayout>
          }
        />
        <Route
          path="/account-owner/manage-expenses-categories"
          element={
            <AccontOwnerLayout>
              <ManageExpensesCategories />
            </AccontOwnerLayout>
          }
        />
        <Route
          path="/account-owner/add-expenses-categories"
          element={
            <AccontOwnerLayout>
              <AddExpenseCategories />
            </AccontOwnerLayout>
          }
        />
        <Route
          path="/account-owner/edit-expenses-categories/:id"
          element={
            <AccontOwnerLayout>
              <EditExpensesCategories />
            </AccontOwnerLayout>
          }
        />
        <Route
          path="/account-owner/manage-expenses"
          element={
            <AccontOwnerLayout>
              <ManageExpense />
            </AccontOwnerLayout>
          }
        />
        <Route
          path="/account-owner/add-expenses"
          element={
            <AccontOwnerLayout>
              <AddExpenses />
            </AccontOwnerLayout>
          }
        />
        <Route
          path="/account-owner/edit-expenses/:id"
          element={
            <AccontOwnerLayout>
              <EditExpenses />
            </AccontOwnerLayout>
          }
        />
        <Route
          path="/account-owner/view-expenses/:id"
          element={
            <AccontOwnerLayout>
              <ViewExpenses />
            </AccontOwnerLayout>
          }
        />
        <Route
          path="/account-owner/manage-revenue-categories"
          element={
            <AccontOwnerLayout>
              <ManageRevenueCategories />
            </AccontOwnerLayout>
          }
        />
        <Route
          path="/account-owner/add-revenue-categories"
          element={
            <AccontOwnerLayout>
              <AddRevenueCategories />
            </AccontOwnerLayout>
          }
        />
        <Route
          path="/account-owner/edit-revenue-categories/:id"
          element={
            <AccontOwnerLayout>
              <EditRevenueCategories />
            </AccontOwnerLayout>
          }
        />
        <Route
          path="/account-owner/manage-revenue"
          element={
            <AccontOwnerLayout>
              <ManageRevenue />
            </AccontOwnerLayout>
          }
        />
        <Route
          path="/account-owner/add-revenue"
          element={
            <AccontOwnerLayout>
              <AddRevenue />
            </AccontOwnerLayout>
          }
        />
        <Route
          path="/account-owner/edit-revenue/:id"
          element={
            <AccontOwnerLayout>
              <EditRevenue />
            </AccontOwnerLayout>
          }
        />
        <Route
          path="/account-owner/view-revenue/:id"
          element={
            <AccontOwnerLayout>
              <ViewRevenue />
            </AccontOwnerLayout>
          }
        />
        <Route
          path="/account-owner/expenses-reports"
          element={
            <AccontOwnerLayout>
              <ExpensesReport />
            </AccontOwnerLayout>
          }
        />
        <Route
          path="/account-owner/revenue-reports"
          element={
            <AccontOwnerLayout>
              <RevenueReport />
            </AccontOwnerLayout>
          }
        />
        <Route
          path="/account-owner/profile-loss-reports"
          element={
            <AccontOwnerLayout>
              <ProfitLossReport />
            </AccontOwnerLayout>
          }
        />
        <Route
          path="/account-owner/time"
          element={
            <AccontOwnerLayout>
              <TimeClock />
            </AccontOwnerLayout>
          }
        />
        <Route
          path="/account-owner/expense-revenue-report"
          element={
            <AccontOwnerLayout>
              <ViewExpenseRevenue />
            </AccontOwnerLayout>
          }
        />
        <Route
          path="/account-owner/employee-working-hours"
          element={
            <AccontOwnerLayout>
              <EmployeeWorkingHours />
            </AccontOwnerLayout>
          }
        />
        <Route
          path="/account-owner/add-employee-working-hour"
          element={
            <AccontOwnerLayout>
              <AddEmployeeWorkingHour />
            </AccontOwnerLayout>
          }
        />
        <Route
          path="/account-owner/employee-certification-report"
          element={
            <AccontOwnerLayout>
              <EmployeeCertification />
            </AccontOwnerLayout>
          }
        />
        <Route
          path="/account-owner/client-certification-report"
          element={
            <AccontOwnerLayout>
              <ClientCertificationData />
            </AccontOwnerLayout>
          }
        />
        <Route
          path="/account-owner/employee-pay-report"
          element={
            <AccontOwnerLayout>
              <EmployeePayReport />
            </AccontOwnerLayout>
          }
        />
        <Route
          path="/account-owner/note"
          element={
            <AccontOwnerLayout>
              <Note />
            </AccontOwnerLayout>
          }
        />
        <Route
          path="/account-owner/add-note"
          element={
            <AccontOwnerLayout>
              <AddNote />
            </AccontOwnerLayout>
          }
        />
      </Routes>
    </div>
  );
}
