import { types } from "../HospitalType/types";

const intitalState = {
  getServiceProviderProfile: [],
  getServiceProfile: [],
  getFilterServiceProviderProfile: [],
  viewServiceProfile: [],
  editServiceProfile: {},
};

export const getServiceProviderReducer = (state = intitalState, action) => {
  switch (action.type) {
    case types.GET_SERVICE_PROVIDER:
      return {
        ...state,
        getServiceProviderProfile: action.payload,
      };
    default:
      return { ...state };
  }
};

export const getServiceReducer = (state = intitalState, action) => {
  switch (action.type) {
    case types.GET_SERVICE:
      return {
        ...state,
        getServiceProfile: action.payload,
      };
    default:
      return { ...state };
  }
};
export const getfilterServiceProviderReducer = (
  state = intitalState,
  action
) => {
  switch (action.type) {
    case types.GET_FILTER_SERVICE_PROVIDER:
      return {
        ...state,
        getFilterServiceProviderProfile: action.payload,
      };
    default:
      return { ...state };
  }
};

export const viewServiceProviderReducer = (state = intitalState, action) => {
  switch (action.type) {
    case types.VIEW_SERVICE_PROVIDER:
      return {
        ...state,
        viewServiceProfile: action.payload,
      };
    default:
      return { ...state };
  }
};

export const viewManageServiceProviderReducer = (
  state = intitalState,
  action
) => {
  switch (action.type) {
    case types.VIEW_MANAGE_SERVICE_PROVIDER:
      return {
        ...state,
        viewManageServiceProfile: action.payload,
      };
    default:
      return { ...state };
  }
};

export const editServiceProviderReducer = (state = intitalState, action) => {
  switch (action.type) {
    case types.EDIT_SERVICE_PROVIDER:
      return {
        ...state,
        editServiceProfile: action.payload,
      };
    default:
      return { ...state };
  }
};
export const editMangeServiceProviderReducer = (
  state = intitalState,
  action
) => {
  switch (action.type) {
    case types.EDIT_MANAGE_SERVICE_PROVIDER:
      return {
        ...state,
        editManageServiceProfile: action.payload,
      };
    default:
      return { ...state };
  }
};
