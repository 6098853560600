
import React, { useState, useEffect } from "react";
import InputField from "../../../../components/input/input";
import { Card } from "primereact/card";
import { Calendar } from "primereact/calendar";
import { InputText } from "primereact/inputtext";
import ContentArea from "../../../../shared/ContentArea";
import SubmitButton from "../../../../components/Buttons/SubmitButton";
import { Dropdown } from "primereact/dropdown";
import { useDispatch, useSelector } from "react-redux";
import { getRevenueCategoriesAction, viewRevenueAction } from "../../../../Redux/HospitalAction/expenseAction";
import { useParams } from "react-router-dom";
import DropdownField from "../../../../components/input/dropdown";
const ViewRevenue = () => {
    const dispatch = useDispatch()
    const [categories, setCategories] = useState(null);
    const Categories = [
        { name: 'Home' },
        { name: 'Adult' },

    ];
    const [date, setDate] = useState(null);
    const { id } = useParams()
    useEffect(() => {
        dispatch(viewRevenueAction(id))
        dispatch(getRevenueCategoriesAction())
    }, [])
    const getRevenueCategoriesData = useSelector((state) => state.getRevenueCategories.getRevenueCategoriesProfile)
    const viewData = useSelector((state)=>state.viewRevenue.viewRevenueProfile)
    useEffect(() => {
        getRevenueCategoriesData?.length > 0 && getRevenueCategoriesData?.map((item) => {
            if (item?.id === viewData?.category_id) {
                setCategories(item)
            }
        })
    }, [getRevenueCategoriesData, viewData])
    return (
        <>
            <ContentArea>
                <div className="add_expenses_container">
                    <div className="p-3">
                        <div className="grid card ml-0 mr-0 px-5 mb-5 align-items-center bg-main-light border-round-lg">
                            <h2 className="text-main">
                                View Revenue
                            </h2>
                        </div>
                        <Card className="shadow-2 p-3 border-round-lg">
                            <div className="p-fluid grid mb-3">
                                <div className="field col-12 md:col-6">
                                    <DropdownField label = "Select Category" value={categories} onChange={(e) => setCategories(e.value)} options={getRevenueCategoriesData} optionLabel="category"
                                        placeholder="Select" disabled />
                                </div>
                                <div className="field col-12 md:col-6">
                                    <InputField label="Revenue Name" value = {viewData?.name} disabled />
                                </div>
                                <div className="field col-12 md:col-6">
                                    <InputField label="Revenue Description" value = {viewData?.description} disabled/>
                                </div>
                                <div className="field col-12 md:col-6">
                                    <label className="font block mb-2">Select Revenue Date</label>
                                    <Calendar value={new Date(viewData?.date)} onChange={(e) => setDate(e.value)} className="calender_picker" inputClassName="border-round-md" disabled />
                                </div>
                                <div className="field col-12 md:col-6">
                                    <label className="font block mb-2">Revenue Amount</label>
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon bg-blue-100">$</span>
                                        <InputText placeholder="" className="border-round-right-md" value = {viewData?.amount} disabled />
                                    </div>
                                </div>
                                <div className="field col-12 md:col-6">
                                    <InputField label="Assignment" value = {viewData?.assignment}  disabled/>
                                </div>
                            </div>
                            {/* <SubmitButton /> */}
                        </Card>
                    </div>
                </div>
            </ContentArea>

        </>
    )
}

export default ViewRevenue;
