import React, { useEffect, useState } from "react";
import AddButton from "../../../../components/Buttons/AddButton";
import ContentArea from "../../../../shared/ContentArea";
import SearchIcon from "../../../../assets/icons/search.png";
import FilterIcon from "../../../../assets/icons/filter.png";
import Table from '../../../../components/TableList/Table';
import { useNavigate } from "react-router-dom";
import Index from '../../Index';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import Deleteimg from "../../../../assets/images/deleted.png";
import AddClientTier from "./AddClientTier";
import { getClientTierAction, deleteClientTiersAction } from "../../../../Redux/HospitalAction/clientAction";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../../pages/Loader";
const ManageClientTier = () => {
  const dispatch = useDispatch()
  const [deleteButton, setDeleteButton] = useState(false)
  const [tableData, setTableData] = useState("")
  const [data, setData] = useState("")
  const { isNextPage, setIsNextPage } = Index();
  const navigate = useNavigate()
  const AddClientTier = () => {
    setIsNextPage(!isNextPage)
    navigate("/account-owner/manage-add-tier")
  }
  const getData = useSelector((state) => state.getClientTier.getClientTierProfile)
  useEffect(() => {
    dispatch(getClientTierAction())
  }, [])
  useEffect(() => {
    if(getData.length === 0){
      setTableData("")
    }else{
      setTableData(getData)
    }

  }, [getData])
  const TableHeading = [
    { field: "SrNo", header: "Sr. No.", body: "srnoTemplates" },
    { field: "tiers_name", header: "Tier Name", },
    { field: "tiers_description", header: "Tier Description", body:"tierDescriptionBody" },
    { field: "icons", header: "Action", body: "tierIconsBodyTemplate" },

  ];
  const id = data
  const handleDelete = () => {
    dispatch(deleteClientTiersAction(id, () => setDeleteButton(false))).then(() => {
      dispatch(getClientTierAction())
    })
  }
  const isLoading = useSelector((state) => state.loader.isLoading)
  return (
    <>
      {isLoading === true ? <Loader /> :
        <ContentArea>
          <div className="manage_employees_container p-3 ">

            <div className="grid mb-5 mr-0 ml-0 align-items-center bg-main-light border-round-lg shadow-1 flex justify-content-around">
              <div className="md:col-10 ">
                <h1 className="text-main  " style={{ fontSize: "24px" }}>
                  Manage Tiers
                </h1>
              </div>

              <div className="md:col-1 w-max ">
                <AddButton onClick={() => AddClientTier()} />
              </div>
            </div>

            <div className="grid mt-2">
              <div className="md:col-12">

                <Table tableHeading={TableHeading}
                  tableData={tableData}
                  setDeleteButton={setDeleteButton}
                  setData={setData}
                />
              </div>

            </div>

            {
              deleteButton ?
                <Dialog
                  className="table_delete_modal flex justify-content-center "
                  visible={deleteButton}
                  onHide={() => setDeleteButton(false)}
                  style={{ width: '30vw' }}>
                  <p className="m-0">
                    <div className=" flex justify-content-center">
                      <img src={Deleteimg} alt="" width={150} height={127}></img>
                    </div>
                    <div className="text-center mb-5">
                      <h4 className=" p-0 m-0 ">Are you sure you want to delete this user?</h4>
                      <p className="text-xs text-400">All associated data will also be deleted! There is no undo!</p>
                    </div>
                    <div className=" btn flex justify-content-center">
                      <div className="text-center">
                        <Button
                          label="Cancel"
                          className="w-full surface-500 p-3 px-5    border-0 border-round-md "
                          onClick={() => setDeleteButton(false)}
                        />
                      </div>
                      <div className="text-center">
                        <Button
                          label="Delete"
                          className=" w-full px-5 border-0 p-3 ml-3 border-round-md bg-red-500"
                          onClick={handleDelete}
                        />
                      </div>
                    </div>
                  </p>
                </Dialog> : null
            }
          </div>
        </ContentArea>
      }
    </>
  )
}

export default ManageClientTier;
