import { types } from "../HospitalType/types";
import HospitalConstants from "../../Services/HospitalConstants";
import { HospitalApi } from "../../Services/HospitalApi";
import { showLoaderAction, hideLoaderAction } from "./loaderAction";
import { showToast } from "./toastAction";


export const getEmployeeCertificationList = () => async (dispatch) => {
    dispatch(showLoaderAction())
    const res = await HospitalApi("get", HospitalConstants.END_POINT.GETEMPLOYEECERTIFICATIONLIST);
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.GET_EMPLOYEE_CERTIFICATION_LIST,
                payload: res.data
            })
        }
        dispatch(hideLoaderAction())
        // dispatch(showToast({ severity: "success", summary: res.message }))
    } else {
        dispatch(hideLoaderAction())
        dispatch(showToast({ severity: "error", summary: res.message }))
    }
}

export const AddEmployeeCertificationList = (data, next) => async (dispatch) => {
    dispatch(showLoaderAction())
    const res = await HospitalApi("post", HospitalConstants.END_POINT.ADDEMPLOYEECERTIFICATIONLIST, data)
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.ADD_EMPLOYEE_CERTIFICATION_LIST,
                payload: res.data
            })
        }
        dispatch(hideLoaderAction())
        next()
        dispatch(showToast({ severity: "success", summary: res.message }))
    } else {
        dispatch(hideLoaderAction())
        dispatch(showToast({ severity: "error", summary: res.message }))
    }
}

export const editEmployeeCertificationList = (id, data, next) => async (dispatch) => {
    dispatch(showLoaderAction())
    const res = await HospitalApi("post", `${HospitalConstants.END_POINT.EDITEMPLOYEECERTIFICATION}/${id}`, data);
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.EDIT_EMPLOYEE_CERTIFICATION_LIST,
                payload: res.data
            })
        }
        dispatch(hideLoaderAction())
        next()
        dispatch(showToast({ severity: "success", summary: res.message }))
    } else {
        dispatch(hideLoaderAction())
        dispatch(showToast({ severity: "error", summary: res.message }))
    }
}


export const viewEmployeeCertificationList = (id) => async (dispatch) => {
    dispatch(showLoaderAction())
    const res = await HospitalApi("get", `${HospitalConstants.END_POINT.VIEWEMPLOYEECERTICATION}/${id}`);
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.VIEW_EMPLOYEE_CERTIFICATION_LIST,
                payload: res.data
            })
        }
        dispatch(hideLoaderAction())
    } else {
        dispatch(hideLoaderAction())
        dispatch(showToast({ severity: "error", summary: res.message }))
    }
}

export const deleteEmployeeCertificationList = (id, next) => async (dispatch) => {
    dispatch(showLoaderAction())
    const res = await HospitalApi("get", `${HospitalConstants.END_POINT.DELETEEMPLOYEECERTIFICATION}/${id}`)
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.DELETE_EMPLOYEE_CERTIFICATION_LIST,
                payload: res.data
            })
        }
        dispatch(hideLoaderAction())
        next()
        dispatch(showToast({ severity: "success", summary: res.message }))
    } else {
        dispatch(hideLoaderAction())
        dispatch(showToast({ severity: "error", summary: res.message }))
    }
}

export const getClientCertificationAction = () => async (dispatch) => {
    dispatch(showLoaderAction())
    const res = await HospitalApi("get", HospitalConstants.END_POINT.GETCLIENTCERTIFICATIONLIST);
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.GET_CLIENT_CERTIFICATION_LIST,
                payload: res.data
            })
        }
        dispatch(hideLoaderAction())
    } else {
        dispatch(hideLoaderAction())
    }
}

export const addClientCertificationAction = (data, next) => async (dispatch) => {
    dispatch(showLoaderAction())
    const res = await HospitalApi("post", HospitalConstants.END_POINT.ADDCLIENTCERTIFICATIONLIST, data)
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.ADD_CLIENT_CERTIFICATION_LIST,
                payload: res.data
            })
        }
        dispatch(hideLoaderAction())
        next()
        dispatch(showToast({ severity: "success", summary: res.message }))
    } else {
        dispatch(hideLoaderAction())
        dispatch(showToast({ severity: "error", summary: res.message }))
    }
}

export const deleteClientCertificationAction = (id, next) => async (dispatch) => {
    dispatch(showLoaderAction())
    const res = await HospitalApi("get", `${HospitalConstants.END_POINT.DELETECLIENTCERTIFICATION}/${id}`)
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.DELETE_CLIENT_CERTIFICATION_LIST,
                payload: res.data
            })
        }
        dispatch(hideLoaderAction())
        next()
        dispatch(showToast({ severity: "success", summary: res.message }))
    } else {
        dispatch(hideLoaderAction())
        dispatch(showToast({ severity: "error", summary: res.message }))
    }
}

export const viewClientCertificationAction = (id) => async (dispatch) => {
    dispatch(showLoaderAction())
    const res = await HospitalApi("get", `${HospitalConstants.END_POINT.VIEWCLIENTCERTIFICATE}/${id}`)
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.VIEW_CLIENT_CERTIFICATION_LISt,
                payload: res.data
            })
        }
        dispatch(hideLoaderAction())
    } else {
        dispatch(hideLoaderAction())
    }
}

export const editClientCertificationAction = (id, data, next) => async (dispatch) => {
    dispatch(showLoaderAction())
    const res = await HospitalApi("post", `${HospitalConstants.END_POINT.EDITCLIENTCERTICATION}/${id}`, data)
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.EDIT_CLIENT_CERTIFICATION_LIST,
                payload: res.data
            })
        }
        dispatch(hideLoaderAction())
        next()
        dispatch(showToast({ severity: "success", summary: res.message }))
    } else {
        dispatch(hideLoaderAction())
        dispatch(showToast({ severity: "error", summary: res.message }))
    }
}

export const getEmployeeCertication = (data) => async (dispatch) => {
    dispatch(showLoaderAction())
    const res = await HospitalApi("post", HospitalConstants.END_POINT.GETEMPLOYEECERTIFICATION, data)
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.GET_EMPLOYEE_CERTICATION,
                payload: res.data
            })
        }
        dispatch(hideLoaderAction())
    } else {
        dispatch(hideLoaderAction())
        dispatch(showToast({ severity: "error", summary: res.message }))
    }
}

export const AddEmployeeCertification = (data, next) => async (dispatch) => {
    dispatch(showLoaderAction())
    const res = await HospitalApi("post", HospitalConstants.END_POINT.ADDEMPLOYEECERTIFICATION, data)
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.ADD_EMPLOYEE_CERTIFICATION,
                payload: res.data
            })
        }
        dispatch(hideLoaderAction())
        next()
        dispatch(showToast({ severity: "success", summary: res.message }))
    } else {
        dispatch(hideLoaderAction())
        dispatch(showToast({ severity: "error", summary: res.message }))
    }
}

export const viewEmployeeCertification = (id) => async (dispatch) => {
    dispatch(showLoaderAction())
    const res = await HospitalApi("get", `${HospitalConstants.END_POINT.VIEWEMPLOYEECERTIFICATION}/${id}`)
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.VIEW_EMPLOYEE_CERTIFICATION,
                payload: res.data
            })
        }
        dispatch(hideLoaderAction())
    } else {
        dispatch(hideLoaderAction())
        dispatch(showToast({ severity: "error", summary: res.message }))
    }
}

export const deleteEmployeeCertification = (id, next) => async (dispatch) => {
    dispatch(showLoaderAction())
    const res = await HospitalApi("get", `${HospitalConstants.END_POINT.DELETEEMPLOYEECERTIFICATIONLIST}/${id}`)
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.DELETE_EMPLOYEE_CERTIFICATION,
                payload: res.data
            })
        }
        dispatch(hideLoaderAction())
        next()
        dispatch(showToast({ severity: "success", summary: res.message }))
    } else {
        dispatch(hideLoaderAction())
        dispatch(showToast({ severity: "error", summary: res.message }))
    }
}

export const editEmployeeCertification = (data, id, next) => async (dispatch) => {
    dispatch(showLoaderAction())
    const res = await HospitalApi("post", `${HospitalConstants.END_POINT.EDITEMPLOYEECERTIFICATIONLIST}/${id}`, data)
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.EDIT_EMPLOYEE_CERTIFICATION,
                payload: res.data
            })
        }
        dispatch(hideLoaderAction())
        next()
        dispatch(showToast({ severity: "success", summary: res.message }))
    } else {
        dispatch(hideLoaderAction())
        dispatch(showToast({ severity: "error", summary: res.message }))
    }
}

export const certificationClientGetAction = (data) => async (dispatch) => {
    dispatch(showLoaderAction())
    const res = await HospitalApi("post", HospitalConstants.END_POINT.CERTIFICATIONCLIENTGET, data)
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.CERTIFICATION_CLIENT_GET,
                payload: res.data
            })
        }
        dispatch(hideLoaderAction())
    } else {
        dispatch(hideLoaderAction())
        dispatch(showToast({ severity: "error", summary: res.message }))
    }
}

export const certicationClientDelete = (id, next) => async (dispatch) => {
    dispatch(showLoaderAction())
    const res = await HospitalApi("get", `${HospitalConstants.END_POINT.CERTIFICATIONCLIENTDELETE}/${id}`)
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.CERTIFICATION_CLIENT_DELETE,
                payload: res.data
            })
        }
        dispatch(hideLoaderAction())
        next()
        dispatch(showToast({ severity: "success", summary: res.message }))
    } else {
        dispatch(hideLoaderAction())
        dispatch(showToast({ severity: "error", summary: res.message }))
    }
}
export const certicationClientAdd = (data, next) => async (dispatch) => {
    dispatch(showLoaderAction())
    const res = await HospitalApi("post", HospitalConstants.END_POINT.CERTIFICATIONCLIENTADD, data)
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.CERTIFICATION_CLIENT_ADD,
                payload: res.data
            })
        }
        dispatch(hideLoaderAction())
        next()
        dispatch(showToast({ severity: "success", summary: res.message }))
    } else {
        dispatch(hideLoaderAction())
        dispatch(showToast({ severity: "error", summary: res.message }))
    }
}

export const certicationClientView = (id) => async (dispatch) => {
    dispatch(showLoaderAction())
    const res = await HospitalApi("get", `${HospitalConstants.END_POINT.CERTIFICATIONCLIENTVIEW}/${id}`)
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.CERTIFICATION_CLIENT_VIEW,
                payload: res.data
            })
        }
        dispatch(hideLoaderAction())
    } else {
        dispatch(hideLoaderAction())
        dispatch(showToast({ severity: "error", summary: res.message }))
    }
}

export const certicationClientEdit = (data, id, next) => async (dispatch) => {
    dispatch(showLoaderAction())
    const res = await HospitalApi("post", `${HospitalConstants.END_POINT.CERTIFICATIONCLIENTEDIT}/${id}`, data)
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.CERTIFICATION_CLIENT_EDIT,
                payload: res.data
            })
        }
        dispatch(hideLoaderAction())
        next()
        dispatch(showToast({ severity: "success", summary: res.message }))
    } else {
        dispatch(hideLoaderAction())
        dispatch(showToast({ severity: "error", summary: res.message }))
    }
}