

import React, { useState, useEffect } from "react";
import { Dropdown } from 'primereact/dropdown';
import { Card } from "primereact/card";
import { InputNumber } from 'primereact/inputnumber';
import ContentArea from "../../shared/ContentArea";
import InputField from "../../../../components/input/input";
import { Calendar } from 'primereact/calendar';
import SaveButton from "../../../../components/Buttons/SaveButton";
import CancelButton from "../../../../components/Buttons/CancelButton";
import DropdownField from "../../../../components/input/dropdown";
import InputNumberField from "../../../../components/input/inputNumber";
import { useNavigate } from "react-router-dom";
import { getSelectedEmployeeAction, addEmployeeHourlyAction, getEmployeesAction } from "../../../../Redux/HospitalAction/employessAction";
import { useDispatch, useSelector } from "react-redux";
import { getServiceAction } from "../../../../Redux/HospitalAction/serviceProviderAction";
import { getPaCodesAction } from "../../../../Redux/HospitalAction/paCodeAction";
import moment from "moment";
import reactRouter from "react-router";
import Loader from "../../../../pages/Loader";
const AddHourlyRate = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [employee, setEmployee] = useState(null);
    const [service, setService] = useState(null);
    const [client, setClient] = useState(null);
    const [time, setTime] = useState(null);
    const [error, setError] = useState({})
    const [paCode, setPaCode] = useState("")
    const [employeId, setEmployeeId] = useState()
    const [data, setData] = useState({
        employee_id: "",
        employee_name: "",
        service_id: "",
        date_of_service: "",
        start_time: "",
        client_id: "",
        employee_working_hour: "",
        pa_Code: ""
    })
    const Client = [
        { name: 'Home', id: 159 },
        { name: 'Adult', id: 163 },
        { name: 'Senior', id: 175 },
    ]
    useEffect(() => {
        dispatch(getEmployeesAction())
        dispatch(getServiceAction())
        dispatch(getPaCodesAction())
    }, [])
    const getallEmployee = useSelector((state) => state.getEmployee.getEmployeeProfile)
    const getallService = useSelector((state) => state.getService.getServiceProfile)
    const getSelectedEmployee = useSelector((state) => state.getSelectedEmployee.getSelectedProfile)
    const getPaCodeData = useSelector((state) => state.getPaCode.getPaCodeProfile)
    useEffect(() => {
        getPaCodeData?.length > 0 && getPaCodeData.map((item) => {
            if (item.id == getSelectedEmployee.pa_code_id) {
                setPaCode(item)
            }
        })
    }, [getPaCodeData, getSelectedEmployee])
    useEffect(() => {
        setEmployeeId(employee)
        setData({
            ...data,
            employee_name: employeId?.name,
            employee_id: employeId?.id,
            service_id: service?.id,
            pa_Code: employeId?.pa_Code,
            client_id: client?.id,
            start_time: time
        })
    }, [employee, getSelectedEmployee, service, paCode, client, time, employeId])
    useEffect(() => {
        const formData = new FormData()
        formData.append("employee_id", data.employee_id)
        dispatch(getSelectedEmployeeAction(formData))
    }, [employeId])
    useEffect(() => {
        if (isLoading === true) {
            setData({
                employee_id: "",
                employee_name: "",
                service_id: "",
                date_of_service: "",
                start_time: "",
                client_id: "",
                employee_working_hour: "",
                pa_Code: ""
            })
        }
    }, [])

    const handleSubmit = () => {
        if (data.employee_id === undefined || data.service_id === undefined || data.date_of_service === "" || data.start_time === "" ||
            data.client_id === undefined || data.employee_working_hour === "" || data.start_time === null) {
            setError({
                ...error,
                employee_id: data.employee_id === undefined ? "Please choose Employee" : error.employee_id,
                service_id: data.service_id === undefined ? "Please Choose Service" : error.service_id,
                date_of_service: data.date_of_service === "" ? "Please Select Date" : error.date_of_service,
                start_time: data.start_time === "" || data.start_time === null ? "Please Select Time" : error.start_time,
                client_id: data.client_id === undefined ? "Please Select Client" : error.client_id,
                employee_working_hour: data.employee_working_hour === "" ? "Please Enter Working hour" : error.employee_working_hour
            })
            return false;
        }
        const obj = data;
        let array = Object.entries(obj)
        const formData = new FormData()
        for (let i = 0; i < array.length; i++) {
            if (array[i][0] === "date_of_service") {
                formData.append(array[i][0], moment(data.date_of_service).format('DD-MM-YYYY'))
            } else if (array[i][0] === "start_time") {
                formData.append(array[i][0], time?.getHours() +":" + time?.getMinutes())
            } else {
                formData.append(array[i][0], array[i][1])
            }
        }
        dispatch(addEmployeeHourlyAction(formData, () => navigate("/account-owner/manage-hourly-rate")))
    }
    const isLoading = useSelector((state) => state.loader.isLoading)
    return (
        <>
            {isLoading === true ? <Loader /> :
                <ContentArea>
                    <div className="add_hourly-rate-container">
                        <div className="p-3">
                            <div className="grid card ml-0 mr-0 px-5 mb-5 align-items-center bg-main-light border-round-lg">
                                <h2 className="text-main">
                                    Add  Hourly Rates
                                </h2>
                            </div>
                            <Card className="shadow-2 p-3 border-round-lg">
                                <div className="p-fluid grid mb-3">
                                    <div className="col-12 md:col-6">
                                        <DropdownField label="Select Employee" inputId="dropdown" placeholder="Select" value={employee} filter
                                            options={getallEmployee} onChange={(e) => {
                                                setEmployee(e.value); setError({
                                                    ...error,
                                                    employee_id: null
                                                })
                                            }} optionLabel="emp_id" required />
                                        {error.employee_id && <span className="error">{error.employee_id}</span>}
                                    </div>
                                    <div className=" col-12 md:col-6">
                                        <InputField type="text" label="Employee Name" value={data.employee_name === "" ? "" : data.employee_name} />
                                    </div>

                                    <div className="col-12 md:col-6">
                                        <DropdownField label="Service" inputId="dropdown" placeholder="Select" value={service}
                                            options={getallService} onChange={(e) => {
                                                setService(e.value); setError({
                                                    ...error,
                                                    service_id: null
                                                })
                                            }} optionLabel="program_name" required />
                                        {error.service_id && <span className="error">{error.service_id}</span>}
                                    </div>
                                </div>

                                <div className="p-fluid grid mb-3">
                                    <div className="col-12 md:col-6">
                                        <InputField label="PA Code" value={data.pa_Code === "" ? "" : data.pa_Code} />
                                    </div>
                                </div>
                                <div className="p-fluid grid mb-5">
                                    <div className="col-12 md:col-12">
                                        <h2 className="text-main font-bold" style={{ fontSize: "22px" }} >Date of Service</h2>
                                    </div>
                                    <div className="col-12 md:col-6 mb-3 ">
                                        <label className="font block mb-2">
                                            Date Of Service<code className="text-red">*</code>
                                        </label>
                                        <Calendar
                                            className="border-round-md calender_picker "
                                            inputClassName="calendar p-2 border-round-md "
                                            placeholder="select"
                                            value={data.date_of_service === 0 ? "" : data.date_of_service}
                                            onChange={(e) => {
                                                setError({
                                                    ...error,
                                                    date_of_service: null,
                                                });
                                                setData({ ...data, date_of_service: e.value });
                                            }}
                                            dateFormat="dd/mm/yy"
                                        />
                                        {error.date_of_service && <span className="error">{error.date_of_service}</span>}
                                    </div>
                                    <div className="col-12 md:col-6 mb-3">
                                        <label className="font block mb-2">
                                            Start Time<code className="text-red">*</code>
                                        </label>
                                        <Calendar id="calendar-timeonly" value={time} onChange={(e) => {
                                            setTime(e.value); setError({
                                                ...error,
                                                start_time: null
                                            })
                                        }} timeOnly hourFormat="24" />
                                        {error.start_time && <span className="error">{error.start_time}</span>}
                                    </div>

                                    <div className="col-12 md:col-6">
                                        <DropdownField label="Client" inputId="dropdown" placeholder="Select" value={client}
                                            options={Client} onChange={(e) => {
                                                setClient(e.value); setError({
                                                    ...error,
                                                    client_id: null
                                                })
                                            }} optionLabel="name" required />
                                        {error.client_id && <span className="error">{error.client_id}</span>}
                                    </div>
                                    <div className="col-12 md:col-6">
                                        <div className="relative">
                                            <InputNumberField label="Employee Working Hours" inputClassName="border-round-md" useGrouping={false} value={data.employee_working_hour} onValueChange={(e) => {
                                                setData({
                                                    ...data,
                                                    employee_working_hour: e.value
                                                })
                                                setError({
                                                    ...error,
                                                    employee_working_hour: null
                                                })
                                            }} required allowEmpty= {true}/>
                                            <span className="hr">/hr</span>
                                            {error.employee_working_hour && <span className="error">{error.employee_working_hour}</span>}
                                        </div>

                                    </div>
                                </div>
                                <div className="submitButton flex">
                                    <SaveButton label="Save" onClick={handleSubmit}></SaveButton>
                                    <CancelButton label="Cancel" onClick={() => navigate("/account-owner/manage-hourly-rate")}></CancelButton>
                                </div>
                            </Card>
                        </div>
                    </div>

                </ContentArea>
            }
        </>
    )
}

export default AddHourlyRate;
