import React, { useEffect, useState } from "react";
import RessmedxLogo from "../assets/images/resmedx 2.png";
import LoginTopbar from "./LoginTopbar";
import { useNavigate } from "react-router-dom";
import BannerImg from "../assets/images/homebanner.png";
import SaveButton from "../components/Buttons/SaveButton";
import CancelButton from "../components/Buttons/CancelButton";
import Container from "../components/container/Container";
import AboutImg from "../assets/images/about-img.png";
import HomeContent from "./HomeContent";
import CustomButton from "../components/Buttons/CustomButton";
import DataEntryImg from "../assets/images/dataentry.png";
import ChartingImg from "../assets/images/charting.png";
import SchedulingImg from "../assets/images/scheduling.png";
import StorageImg from "../assets/images/storage.png";

import BlogCard from "../components/card/CustomCard";
import InputField from "../components/input/input";
import InputNumberField from "../components/input/inputNumber";
import InputTextareaField from "../components/input/inputTextArea";
import Footer from "../shared/Footer";

export default function LandingPage() {
  const navigate = useNavigate();
  useEffect(() => {
    if (localStorage.getItem("client")) {
      navigate("/account-owner/dashboard");
    } else {
      navigate("/");
    }
  }, []);

  const { AboutUsContent, BlogContent } = HomeContent();
  const [selesperson, setSalesPerson] = useState(false);
  return (
    <>
      <LoginTopbar showVisible={selesperson} onSaleHide={() => setSalesPerson(false)} />
      {/* banner start */}
      <div className="banner-container">
        <div className="grid m-0">
          <div className="col-12 md:col-7 p-0 bg-main">
            <div className=" h-full flex flex-column home-banner-content p-4 md:px-5 ml-auto justify-content-center">
              <h1 className="text-white text-3xl md:text-7xl m-0 md:mb-0">
                Revolutionize Healthcare Management with Our EHR Software
              </h1>
              <p className="text-main-light mb-4">
                Efficiency, Accuracy, and Innovation for Modern Healthcare. Your
                Patients. Our Priority. Better Care Together.
              </p>
              <CustomButton
                label="Learn More"
                btnclass="bg-white w-4 text-main font-medium"
              />
            </div>
          </div>
          <div className="col-12 md:col-5 p-0">
            <div className="banner-img">
              <img src={BannerImg} alt="" className="w-full" />
            </div>
          </div>
        </div>
      </div>
      {/* banner end */}

      {/* about */}
      <Container>
        <div className="grid w-full py-7">
          <div className="col-12 md:col-5">
            <img src={AboutImg} alt="" className="about-us-img w-full" />
          </div>
          <div className="col-12 md:col-6 md:col-offset-1">
            <div>
              <h1 className="text-5xl text-heading font-bold">About Us</h1>
              <p className="text-sub-heading text-lg line-height-3">
                At ResMedX, we are dedicated to revolutionizing healthcare
                through cutting-edge Electronic Health Record (EHR) software
                solutions. Our journey began with a simple yet profound vision:
                to empower healthcare professionals and institutions with the
                tools they need to provide exceptional patient care.
              </p>
              {AboutUsContent.map((item, key) => {
                return (
                  <div className="flex gap-3 mb-5">
                    <div className={`icon-box p-2 ${item.iconclass}`}>
                      <img src={item.img} alt="" />
                    </div>
                    <div>
                      <h2 className="text-xl m-0 mb-2 text-heading font-bold">
                        {item.heading}
                      </h2>
                      <p className="text-sub-heading m-0">{item.paragraph}</p>
                    </div>
                  </div>
                );
              })}
              <CustomButton
                label="Learn More"
                btnclass="bg-main text-white px-4 font-medium"
              />
            </div>
          </div>
        </div>
      </Container>
      {/* about end */}

      {/* how its work */}
      <div className="bg-main py-3 md:py-7">
        <Container>
          <div className="w-full text-center">
            <h1 className="text-5xl text-white">How It Works</h1>
            <p className="text-main-light text-lg">
              Our EHR software is designed with simplicity and efficiency in
              mind, ensuring that healthcare professionals can focus on what
              matters most: patient care. Explore the streamlined journey of how
              our EHR system works:
            </p>
          </div>
          <div className="how-its-work-container mt-4 md:w-9 m-auto">
            <div className="work-list grid justify-content-between align-items-center py-3">
              <div className="col-12 md:col-5 flex-order-3 md:flex-order-1		">
                <div>
                  <p className="text-xl font-bold text-white">
                    Patient Data Entry
                  </p>
                  <p className="text-xl font-normal text-main-light">
                    Effortlessly input and update patient information through
                    our intuitive interface. Record medical history,
                    demographics, and more with ease.
                  </p>
                </div>
              </div>
              <div className="ml-2 md:m-0 work-count text-xl text-main font-semibold flex-order-1	md:flex-order-2	">
                01
              </div>
              <div className="col-12 md:col-5 work-img flex-order-2	md:flex-order-3">
                <img src={DataEntryImg} alt="" className="w-full" />
              </div>
            </div>
            <div className="work-list grid justify-content-between align-items-center py-3">
              <div className="col-12 md:col-5 work-img flex-order-2 md:flex-order-1">
                <img src={ChartingImg} alt="" className="w-full" />
              </div>
              <div className="ml-2 md:m-0 work-count text-xl text-main font-semibold flex-order-1	md:flex-order-2">
                02
              </div>
              <div className="col-12 md:col-5 flex-order-3 md:flex-order-3">
                <div>
                  <p className="text-xl font-bold text-white">
                    Comprehensive Charting
                  </p>
                  <p className="text-xl font-normal text-main-light">
                    Experience the power of organized and detailed charting.
                    Create, access, and modify patient charts seamlessly,
                    ensuring accurate documentation.
                  </p>
                </div>
              </div>
            </div>
            <div className="work-list grid justify-content-between align-items-center py-3">
              <div className="col-12 md:col-5 flex-order-3 md:flex-order-1">
                <div>
                  <p className="text-xl font-bold text-white">
                    Integrated Scheduling
                  </p>
                  <p className="text-xl font-normal text-main-light">
                    Efficiently manage appointments and schedules, reducing wait
                    times and optimizing patient flow. Our integrated scheduling
                    system keeps your practice running smoothly.
                  </p>
                </div>
              </div>
              <div className="ml-2 md:m-0 work-count text-xl text-main font-semibold flex-order-1	md:flex-order-2">
                03
              </div>
              <div className="col-12 md:col-5 work-img flex-order-2	md:flex-order-3">
                <img src={SchedulingImg} alt="" className="w-full" />
              </div>
            </div>
            <div className="work-list grid justify-content-between align-items-center py-3">
              <div className="col-12 md:col-5 work-img flex-order-2 md:flex-order-1">
                <img src={StorageImg} alt="" className="w-full" />
              </div>
              <div className="ml-2 md:m-0 work-count text-xl text-main font-semibold flex-order-1	md:flex-order-2">
                04
              </div>
              <div className="col-12 md:col-5 flex-order-3 md:flex-order-3">
                <div>
                  <p className="text-xl font-bold text-white">
                    Secure Data Storage
                  </p>
                  <p className="text-xl font-normal text-main-light">
                    Rest easy knowing that patient data is stored securely in
                    compliance with industry standards. Our encryption and
                    authentication protocols ensure data privacy and security.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
      {/* how its work end */}

      {/* our pricing */}
      <div className="py-3 md:py-7">
        <Container>
          <div className="w-full text-center">
            <h1 className="text-5xl text-heading">Our Pricing</h1>
            <p className="text-sub-heading text-lg">
              At ResMedX, we understand that every healthcare facility is
              unique, and your EHR software needs may vary. That's why we offer
              flexible pricing options to ensure you get the most value for your
              investment. Choose from our tailored pricing plans to match your
              organization's requirements.
            </p>
          </div>
          <div className="grid w-full mt-5">
            <div className="col-12 md:col-3">
              <div className="pricing-card">
                <p className="pricing-name m-0 font-bold">
                  Home Health Program
                </p>
                <h1 className="text-6xl my-3 price">
                  $40 <span className="text-sm font-normal">/per person</span>
                </h1>
                <p className="pricing-detail">
                  Lorem Ipsum is simply dummy text of the printing & typesetting
                  industry.
                </p>
                <ul className="p-0 pricing-list list-none">
                  <li className="flex gap-3 align-items-center mb-3">
                    <div className="icon-check">
                      <i className="pi pi-check"></i>
                    </div>
                    Unlimited Storage
                  </li>
                  <li className="flex gap-3 align-items-center mb-3">
                    <div className="icon-check">
                      <i className="pi pi-check"></i>
                    </div>
                    Access to all basic features
                  </li>
                </ul>
                <CustomButton label="Start free trial" btnclass="pricing-btn" />
              </div>
            </div>
            <div className="col-12 md:col-3">
              <div className="pricing-card">
                <p className="pricing-name m-0 font-bold">Adult Day Program</p>
                <h1 className="text-6xl my-3 price">
                  $40 <span className="text-sm font-normal">/per person</span>
                </h1>
                <p className="pricing-detail">
                  Lorem Ipsum is simply dummy text of the printing & typesetting
                  industry.
                </p>
                <ul className="p-0 pricing-list list-none">
                  <li className="flex gap-3 align-items-center mb-3">
                    <div className="icon-check">
                      <i className="pi pi-check"></i>
                    </div>
                    Unlimited Storage
                  </li>
                  <li className="flex gap-3 align-items-center mb-3">
                    <div className="icon-check">
                      <i className="pi pi-check"></i>
                    </div>
                    Access to all basic features
                  </li>
                </ul>
                <CustomButton label="Start free trial" btnclass="pricing-btn" />
              </div>
            </div>
            <div className="col-12 md:col-3">
              <div className="pricing-card">
                <p className="pricing-name m-0 font-bold">
                  Senior Facility Program
                </p>
                <h1 className="text-6xl my-3 price">
                  $50 <span className="text-sm font-normal">/per person</span>
                </h1>
                <p className="pricing-detail">
                  Lorem Ipsum is simply dummy text of the printing & typesetting
                  industry.
                </p>
                <ul className="p-0 pricing-list list-none">
                  <li className="flex gap-3 align-items-center mb-3">
                    <div className="icon-check">
                      <i className="pi pi-check"></i>
                    </div>
                    Unlimited Storage
                  </li>
                  <li className="flex gap-3 align-items-center mb-3">
                    <div className="icon-check">
                      <i className="pi pi-check"></i>
                    </div>
                    Access to all basic features
                  </li>
                </ul>
                <CustomButton label="Start free trial" btnclass="pricing-btn" />
              </div>
            </div>
            <div className="col-12 md:col-3">
              <div className="pricing-card">
                <p className="pricing-name m-0 font-bold">Education Program</p>
                <h1 className="text-6xl my-3 price">
                  $60 <span className="text-sm font-normal">/per person</span>
                </h1>
                <p className="pricing-detail">
                  Lorem Ipsum is simply dummy text of the printing & typesetting
                  industry.
                </p>
                <ul className="p-0 pricing-list list-none">
                  <li className="flex gap-3 align-items-center mb-3">
                    <div className="icon-check">
                      <i className="pi pi-check"></i>
                    </div>
                    Unlimited Storage
                  </li>
                  <li className="flex gap-3 align-items-center mb-3">
                    <div className="icon-check">
                      <i className="pi pi-check"></i>
                    </div>
                    Access to all basic features
                  </li>
                </ul>
                <CustomButton label="Start free trial" btnclass="pricing-btn" />
              </div>
            </div>
          </div>
        </Container>
      </div>
      {/* our pricing end  */}

      {/* blog */}
      <div className="py-3 md:py-7 bg-main-light">
        <Container>
          <div className="grid w-full">
            <div className="md:col-9">
              <h1 className="text-5xl text-heading">Our Blogs</h1>
              <p className="text-sub-heading text-lg">
                Our EHR software is designed with simplicity and efficiency in
                mind, ensuring that healthcare professionals can focus on what
                matters most: patient care. Explore the streamlined journey of
                how our EHR system works:
              </p>
            </div>
            <div className="md:col-3 text-right">
              <CustomButton label="View All" btnclass="px-6" />
            </div>
          </div>
          <div className="grid w-full mt-3">
            {BlogContent.map((item, key) => {
              return (
                <BlogCard
                  src={item.src}
                  heading={item.heading}
                  extraClass="col-12 md:col-4"
                  date={item.date}
                  authorname={item.author}
                />
              );
            })}
          </div>
        </Container>
      </div>
      {/* blog end */}

      {/* contact */}
      <div className="py-3 md:py-7">
        <Container>
          <div className="grid w-full">
            <div className="col-12 md:col-6">
              <div>
                <h1 className="text-5xl text-heading">Contact Us</h1>
                <p className="text-sub-heading text-lg">
                  Have questions or need assistance with our EHR software? We're
                  here to help! Reach out to us through the following contact
                  methods
                </p>
                <div className="grid">
                  <div className="col-12 md:col-6">
                    <InputField label="First Name" />
                  </div>
                  <div className="col-12 md:col-6">
                    <InputField label="Last Name" />
                  </div>
                  <div className="col-12 md:col-12">
                    <InputField label="Email" />
                  </div>
                  <div className="col-12 md:col-12">
                    <InputNumberField label="Phone No." />
                  </div>
                  <div className="col-12 md:col-12">
                    <InputTextareaField label="Message" rows={5} />
                  </div>
                </div>
                <CustomButton label="Send message" btnclass="w-full" />
              </div>
            </div>
            <div className="col-12 md:col-6">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d680215.2575998373!2d-149.77137560340142!3d61.00658290438881!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x56c89b6de7b3493f%3A0x6fb354e09ba85fd9!2s19141%20Pine%20Ridge%20Cir%2C%20Anchorage%2C%20AK%2099516%2C%20USA!5e0!3m2!1sen!2sin!4v1713509154858!5m2!1sen!2sin"
                width="100%"
                height="100%"
                style={{ border: 0 }}
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </Container>
      </div>
      {/* contact end */}

      {/* footer */}
      <Footer />
    </>
  );
}
