import axios from "axios";
const config = require("../envirement/development").config


export const HospitalLoginAction = ({ payload }) => {
    return new Promise(async (resolve, reject) => {
        try {
            let response = await axios.post(`${config.backEnd}/api/accounts/login`, payload);
            return resolve(response.data);
        } catch (err) {
            return reject(err);
        }
    })
}


export const HospitalSendOTPAction = ({ payload }) => {
    return new Promise(async (resolve, reject) => {
        try {
            let response = await axios.post(`${config.backEnd}/api/accounts/email-otp`, payload);
            return resolve(response.data);
        } catch (err) {
            return reject(err);
        }
    })
}

export const HospitalOtpVerification = ({ payload }) => {
    return new Promise(async (resolve, reject) => {
        try {
            let response = await axios.post(`${config.backEnd}/api/accounts/verify-otp`, payload);
            return resolve(response.data)
        } catch (err) {
            return reject(err);
        }
    })
}

export const HospitalSetPassword = ({ payload }) => {
    return new Promise(async (resolve, reject) => {
        try {
            let response = await axios.post(`${config.backEnd}/api/accounts/reset-password`, payload);
            return resolve(response.data)
        } catch (err) {
            return reject(err);
        }
    })
}


export const HospitalSignup = ({ payload }) => {
    return new Promise(async (resolve, reject) => {
        try {
            let response = await axios.post(`${config.backEnd}/api/accounts/register`, payload);
            return resolve(response.data)
        } catch (err) {
            return reject(err);
        }
    })
}

export const HospitalGetSubscription = () => {
    return new Promise(async (resolve, reject) => {
        try {
            let response = await axios.get(`${config.backEnd}/api/accounts/subscriptionPlan`);
            return resolve(response.data)
        } catch (err) {
            return reject(err);
        }
    })
}
export const DiscountCode = ({ payload }) => {
    return new Promise(async (resolve, reject) => {
        try {
            let response = await axios.post(`${config.backEnd}/api/accounts/discount-check`, payload);
            return resolve(response.data)
        } catch (err) {
            return reject(err);
        }
    })
}

export const paymentAction = async ({ payload }) => {
    const token1 = localStorage.getItem("token")
    return new Promise(async (resolve, reject) => {
        try {
            let response = await axios.post(`${config.backEnd}/api/accounts/stripe-payment`, payload, {
                headers: {
                    Authorization: `Bearer ${token1}`
                }
            })
            return resolve(response.data)
        } catch (err) {
            return reject(err)
        }
    })
}

export const getServiceProvider = async ({ payload }) => {
    const token1 = localStorage.getItem("token")
    return new Promise(async (resolve, reject) => {
        try {
            let response = await axios.post(`${config.backEnd}/api/accounts/get-service-provider`, payload, {
                headers: {
                    Authorization: `Bearer ${token1}`
                }
            })
            return resolve(response.data)
        } catch (err) {
            return reject(err)
        }
    })
}

export const addServiceProvider = async ({ payload }) => {
    const token1 = localStorage.getItem("token")
    return new Promise(async (resolve, reject) => {
        try {
            let response = await axios.post(`${config.backEnd}/api/accounts/add-service-provider`, payload, {
                headers: {
                    Authorization: `Bearer ${token1}`
                }
            })
            return resolve(response.data)
        } catch (err) {
            return reject(err)
        }
    })
}
export const viewServiceProvider = async (id) => {
    const token1 = localStorage.getItem("token")
    return new Promise(async (resolve, reject) => {
        try {
            let response = await axios.get(`${config.backEnd}/api/accounts/view-service/${id}`, {
                headers: {
                    Authorization: `Bearer ${token1}`
                }
            })
            return resolve(response.data)
        } catch (err) {
            return reject(err)
        }
    })
}
export const getCarrier = () => {
    const token = localStorage.getItem("token")
    return new Promise(async (resolve, reject) => {
        try {
            let response = await axios.get(`${config.backEnd}/api/admin/get-carrier`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            return resolve(response.data)
        } catch (err) {
            return reject(err)
        }
    })
}

export const getService = () => {
    const token = localStorage.getItem("token")
    return new Promise(async (resolve, reject) => {
        try {
            let response = await axios.get(`${config.backEnd}/api/accounts/user-plan`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            return resolve(response.data)
        } catch (err) {
            return reject(err)
        }
    })
}

export const deleteServiceProvider = async (id) => {
    const token1 = localStorage.getItem("token")
    return new Promise(async (resolve, reject) => {
        try {
            let response = await axios.get(`${config.backEnd}/api/accounts/delete-service/${id}`, {
                headers: {
                    Authorization: `Bearer ${token1}`
                }
            })
            return resolve(response.data)
        } catch (err) {
            return reject(err)
        }
    })
}

export const editServiceProvider = async ({ id, payload }) => {
    const token1 = localStorage.getItem("token")
    return new Promise(async (resolve, reject) => {
        try {
            let response = await axios.post(`${config.backEnd}/api/accounts/edit-service/${id}`, payload, {
                headers: {
                    Authorization: `Bearer ${token1}`
                }
            })
            return resolve(response.data)
        } catch (err) {
            return reject(err)
        }
    })
}

export const managePACodes = async ({ payload }) => {
    const token = localStorage.getItem("token")
    return new Promise(async (resolve, reject) => {
        try {
            let response = await axios.post(`${config.backEnd}/api/accounts/get-PaCode`, payload, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            return resolve(response.data)
        } catch (err) {
            return reject(err)
        }
    })
}

export const deletePACodes = async (id) => {
    const token1 = localStorage.getItem("token")
    return new Promise(async (resolve, reject) => {
        try {
            let response = await axios.get(`${config.backEnd}/api/accounts/delete-PaCode/${id}`, {
                headers: {
                    Authorization: `Bearer ${token1}`
                }
            })
            return resolve(response.data)
        } catch (err) {
            return reject(err)
        }
    })
}

export const addPaCodes = async ({ payload }) => {
    const token = localStorage.getItem("token")
    return new Promise(async (resolve, reject) => {
        try {
            let response = await axios.post(`${config.backEnd}/api/accounts/add-PaCode`, payload, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            return resolve(response.data)
        } catch (err) {
            return reject(err)
        }
    })
}

export const viewPaCodes = async (id) => {
    const token = localStorage.getItem("token")
    return new Promise(async (resolve, reject) => {
        try {
            let response = await axios.get(`${config.backEnd}/api/accounts/view-PaCode/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            return resolve(response.data)
        } catch (err) {
            return reject(err)
        }
    })
}

export const editPaCodes =  async({id,payload})=>{
        const token =  localStorage.getItem("token")
        return new Promise(async (resolve,reject)=>{
            try{
                let response = await axios.get(`${config.backEnd}/api/accounts/edit-paCode/${id}`,payload,{
                    headers:{
                        Authorization : `Bearer ${token}`
                    }
                })
                return resolve(response.data)
            }catch(err){
                return reject(err)
            }
        })
}