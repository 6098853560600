import { types } from "../HospitalType/types";

const intitalState = {
    carrierProfile:[]
}

export const carrierReducer = (state = intitalState, action)=>{
    switch (action.type) {
        case types.CARRIER:
            return {
                ...state,
                carrierProfile: action.payload
            }
        default:
            return { ...state };
    }
}