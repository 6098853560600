
import React, { useEffect, useState } from "react";
import ContentArea from "../../../shared/ContentArea";
import Totalclient from "../../../assets/images/totalclient.png";
import MoneyImg from "../../../assets/images/commission money.png"
import Employeework from "../../../assets/images/employeeworking.png";
import Totalrevenge from "../../../assets/images/totalrevenge.png";
import BarChart from "../../../components/Charts/BarChart.jsx";
import LineChart from "../../../components/LineChart/LineChart";
import RevenueStatus from "../../../components/RevenueStatus/RevenueStatus";
import { getDashboard } from "../../../utils/action";
import { Calendar } from 'primereact/calendar';
// import Index from "../../../shared/index"

export default function SalesPersonDashboard() {
    const [value, setValue] = useState("")
    const [date, setDate] = useState(null);
    const handleDashboard = async () => {
        let response = await getDashboard()
        setValue(response.data)
    }
    useEffect(() => {
        handleDashboard()
    }, [])
    // const { allTabs, activeComponent, changeComponent, ShowComponent } = Index();
    return (
        <>

            <ContentArea>
                <div className="sales_person_dashboard">
                    <div className="p-3">
                        <div className="grid mb-5">
                            <div className="col-4">
                                <div className="border-round-2xl border-none shadow-none bg-seagreen p-5 h-full mr-5 ">
                                    <div className="relative">
                                        <h3 className=" karla  text-xl font-bold p-0 m-0">
                                            Total Clients
                                        </h3>
                                        <h1 className="text-4xl font-bold mb-0">{value?.total_clients}</h1>
                                        <img
                                            src={Totalclient}
                                            className=" dashboard_revenu "
                                            alt=""
                                            width={60}
                                            height={60}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="col-4">
                                <div className="border-round-2xl border-none shadow-none p-3 bg-lightpirple h-full p-5 mr-5">
                                    <div className="relative">
                                        <h3 className=" karla  text-xl font-bold p-0 m-0">
                                            Current Month Commission
                                        </h3>
                                        <h1 className="text-4xl font-bold mb-0"> {value?.monthly_commission} </h1>
                                        <img
                                            src={MoneyImg}
                                            alt=""
                                            className=" dashboard_revenu "
                                            width={43}
                                            height={60}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="border-round-2xl border-none shadow-none p-5 bg-lightorange h-full">
                                    <div className="relative">
                                        <h3 className=" karla  text-xl font-bold p-0 m-0">
                                            Total Commission
                                        </h3>
                                        <h1 className="text-4xl font-bold mb-0">{value?.total_commission}</h1>
                                        <img
                                            alt=""
                                            src={Totalrevenge}
                                            className="dashboard_revenu "
                                            width={63.29}
                                            height={60}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="grid">
                            <div className="col-12 md:col-12 mb-3">
                                <BarChart title="Client Status" />
                            </div>

                            <div className="col-12 md:col-12">
                                <RevenueStatus title="Commissions status" />
                            </div>
                        </div>

                    </div>

                </div>
            </ContentArea>
        </>

    );
};
