import React from "react";
import ContentArea from "../../../shared/ContentArea";
import SaveButton from "../../../components/Buttons/SaveButton";
import CancelButton from "../../../components/Buttons/CancelButton";
import Table from "../../../components/TableList/Table";
import DropdownField from "../../../components/input/dropdown";
import { Calendar } from "primereact/calendar";
import { Card } from "primereact/card";
import FrameMinus from "../../../assets/icons/FrameMinus.png";
import FramePlus from "../../../assets/icons/Frame.png"
const AddEmployeeWorkingHour = () => {

    const TableHeading = [
        {field:"srno",header:"Sr. No."},
        {field:"Service",header:"Service"},
        {field:"Client",header:"Client"},
        {field:"StartTime",header:"Start Time"},
        {field:"EndTime",header:"End Time"},
        { filed: "icons", header: "Action", body: "AddTimeBodyTemplate" },
    ]

    const TableData = [
        {
            srno:"1",
            Service:"Home Health",
            Client:"Ethan",
            StartTime:"10:00",
            EndTime:"05:00"
        },
        {
            srno:"2",
            Service:"Home Health",
            Client:"Ethan",
            StartTime:"10:00",
            EndTime:"05:00"
        },
        {
            srno:"3",
            Service:"Home Health",
            Client:"Ethan",
            StartTime:"10:00",
            EndTime:"05:00"
        },
        {
            srno:"4",
            Service:"Home Health",
            Client:"Ethan",
            StartTime:"10:00",
            EndTime:"05:00"
        },
        {
            srno:"5",
            Service:"Home Health",
            Client:"Ethan",
            StartTime:"10:00",
            EndTime:"05:00"
        },
        {
            srno:"6",
            Service:"Home Health",
            Client:"Ethan",
            StartTime:"10:00",
            EndTime:"05:00"
        },
        {
            srno:"7",
            Service:"Home Health",
            Client:"Ethan",
            StartTime:"10:00",
            EndTime:"05:00"
        },
        {
            srno:"8",
            Service:"Home Health",
            Client:"Ethan",
            StartTime:"10:00",
            EndTime:"05:00"
        },
        {
            srno:"9",
            Service:"Home Health",
            Client:"Ethan",
            StartTime:"10:00",
            EndTime:"05:00"
        },
        {
            srno:"10",
            Service:"Home Health",
            Client:"Ethan",
            StartTime:"10:00",
            EndTime:"05:00"
        }
    ]
    return (
        <>
               <ContentArea>
                <div className="add_employees_container">
                    <div className="p-3">
                        <div className="grid card ml-0 mr-0 px-5 mb-5 align-items-center bg-main-light border-round-lg">
                            <h2 className="text-main">
                                Time Clock
                            </h2>
                        </div>
                        <Card className="shadow-2 p-3 border-round-lg">
                            <div className="p-fluid grid mb-3">
                                <div className="col-12 md:col-6">
                                    <label className="font block mb-2">Date<code className="text-red">*</code></label>
                                    <Calendar className="border-round-md calender_picker "
                                        inputClassName="calendar p-2 border-round-md mb-2" />
                                </div>
                            </div>

                            <div className="p-fluid grid mb-3">
                                <div className="col-12 md:col-6">
                                    <label className="font block mb-2">Start Time<code className="text-red">*</code></label>
                                    <DropdownField
                                        placeholder="Start Time"
                                        name="start_time"
                                    />
                                </div>
                                <div className="col-12 md:col-6">
                                    <label className="font block mb-2">End Time<code className="text-red">*</code></label>
                                    <DropdownField
                                        placeholder="End Time"
                                        name="end_time"
    
                                    />
                                </div>
                            </div>
                            <div className="p-fluid grid mb-5">

                                <div className="col-12 md:col-12">
                                    <h3 className="text-lg font-normal p-0 m-0" style={{ color: "#1E1E1E" }}>Breaks<code className="text-red">*</code></h3>
                                </div>
                                        <>
                                            <div className="col-12 md:col-4">
                                                <DropdownField
                                                    placeholder="Start Time"
                                                    name="start_break"

                                             
                                                />
                                            </div>
                                            <div className="col-12 md:col-4">
                                                <DropdownField
                                                    placeholder="End Time"
                                                    name="end_break"
                                                />
                                            </div>
                                            <div className="col-12 md:col-4">
                                            
                                                    <div className="" >
                                                        <img src={FrameMinus} width={24} height={24} className="mt-3" />
                                                        {/* <p className="bg-main p-2 p-0 m-0 text-white border-round-2xl text-center "><i className="pi pi-minus text-white"></i></p> */}
                                                    </div>
                                              
                                        
                                                    {/* <div className="w-1 ">
                                                        <img src={FramePlus} width={24} height={24} className="mt-3" />
                                                    </div> */}
                                                

                                            </div>

                                        </>
                                 
                            </div>
                            <div className="p-fluid grid mb-3">
                                <div className="col-12 md:col-12">
                                    <h2 className="text-main font-bold" style={{ fontSize: "22px" }} >Services  and Clients<code className="text-red">*</code></h2>
                                </div>
                                <div className="col-12 md:col-4">
                                    <DropdownField label="Service"  optionLabel="program_name" placeholder = "Select" />
                                </div>
                                <div className="col-12 md:col-4">
                                    <DropdownField label="Clients"  optionLabel="profile_id" filter  placeholder = "Select" />
                                </div>
                            </div>
                            <div className="p-fluid grid mb-5">
                                <div className="col-12 md:col-4">
                                    <DropdownField
                                        placeholder="Start Time"
                                        name="start_time"
                                    />
                                </div>
                                <div className="col-12 md:col-4">
                                    <DropdownField
                                        placeholder="End Time"
                                        name="end_time"
                                      
                                    />
                                </div>
                                {/* <div className="col-12 md:col-4 p-0 m-0">
                                    {editButton === true ?
                                        <div className="flex justify-content-end">
                                            <p className="bg-main p-0 m-0 py-3 border-round-lg text-white w-3 text-center" onClick={() => handleUpdate()}>Edit</p>
                                        </div> :
                                        <div className="flex justify-content-end">
                                            <p className="bg-main p-0 m-0 py-3 border-round-lg text-white w-3 text-center" onClick={() => handleAddNotes()}>Add</p>
                                        </div>
                                    }
                                </div> */}
                            </div>
                            <div className="p-fluid grid">
                                <div className="col-12 md:col-12 mb-3">
                                    <Table tableHeading={TableHeading} tableData={TableData}/>
                                </div>
                            </div>

                            <div className="submitButton flex">
                                <SaveButton label="Save" ></SaveButton>
                                <CancelButton label="Cancel"></CancelButton>
                            </div>
                        </Card>

                    </div>
                </div>
            </ContentArea>
        </>
    )
}

export default AddEmployeeWorkingHour;