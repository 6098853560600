import { Card } from "primereact/card";
import React, { useEffect, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import CalendarIcon from "../../../../assets/icons/calendar-blue.png";
import CalendarIcon1 from "../../../../assets/icons/calendar1.png";
import EditIcon1 from "../../../../assets/icons/edit1.png";
import CrossIcon from "../../../../assets/icons/cross.png";
import LocationIcon from "../../../../assets/icons/location.png";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Avatar } from "primereact/avatar";
import CancelButton from "../../../../components/Buttons/CancelButton";
import { Dialog } from "primereact/dialog";
import SubmitButton from "../../../../components/Buttons/SubmitButton";
import SaveButton from "../../../../components/Buttons/SaveButton";
import { InputTextarea } from "primereact/inputtextarea";
import DropdownField from "../../../../components/input/dropdown";
import { useDispatch, useSelector } from "react-redux";
import { ViewSingleAppointment } from "../../../../Redux/HospitalAction/appoinmentAction";
import {
  hideLoaderAction,
  showLoaderAction,
} from "../../../../Redux/HospitalAction/loaderAction";
import { HospitalApi } from "../../../../Services/HospitalApi";
import HospitalConstants from "../../../../Services/HospitalConstants";
import { showToast } from "../../../../Redux/HospitalAction/toastAction";

export const ViewAppointment = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [visible, setVisible] = useState(false);
  const [reasonVisible, setReasonVisible] = useState(false);
  const [cancelVisible, setCancelVisible] = useState(false);
  const [client, setClient] = useState([]);
  const [deleteData, setDeleteData] = useState({
    time_slot_ids: [],
    reason: "",
  });
  const [cancelAppointmentVisible, setcancelAppointmentVisible] =
    useState(false);
  const viewAppointment = useSelector(
    (state) => state.viewappointmentList.viewAppointmentList
  );
  useEffect(() => {
    dispatch(ViewSingleAppointment(id));
  }, []);

  const BookAppointment = () => {
    Navigate("/account-owner/book-appointment");
  };

  const Delete = (item) => {
    setClient(item?.booked_appointments.map((item) => item));
  };

  const DeleteClientInfo = async (deleteData) => {
    dispatch(showLoaderAction());
    const res = await HospitalApi(
      "post",
      HospitalConstants.END_POINT.DELETEAPPOINTMENTBOOKINGDATA,
      deleteData
    );
    if (res.success) {
      dispatch(ViewSingleAppointment(id));
      setReasonVisible(false);
      setCancelVisible(false);
      setcancelAppointmentVisible(false);
      navigate("/account-owner/manage-appointment");
      dispatch(hideLoaderAction());
    } else {
      dispatch(hideLoaderAction());
      dispatch(showToast({ severity: "error", summary: res.message }));
    }
  };

  const headerElement = (
    <div className="inline-flex align-items-center justify-content-center gap-2">
      <Avatar
        image="https://primefaces.org/cdn/primevue/images/avatar/amyelsner.png"
        shape="circle"
      />
      <span className="font-bold text-sm text-main white-space-nowrap">
        Amy Elsner
      </span>
    </div>
  );
  const footerContent = (
    <div>
      <SubmitButton
        btnLabel="Confirm"
        label="Ok"
        icon="pi pi-check"
        onClick={() => {
          setVisible(false);
          DeleteClientInfo();
        }}
        autoFocus
      />
    </div>
  );

  return (
    <div className="p-3">
      {/* <div className="grid mb-5 mr-0 ml-0 align-items-center bg-main-light border-round-lg shadow-1 flex justify-content-around">
        <div className="md:col-9">
          <h1 className="text-main  " style={{ fontSize: "24px" }}>
            Manage Appointments
          </h1>
        </div>

        <div className="md:col-3 w-max ">
          <button
            className="p-0 px-5 bg-main border-round-md"
            onClick={() => BookAppointment()}
          >
            <p
              className="p-0 text-white font-medium"
              style={{ fontSize: "14px" }}
            >
              Book Appointments
            </p>
          </button>
        </div>
      </div> */}

      <div className="p-3 shadow-2 border-round-lg mb-3">
        <h2 className="text-main">
          {" "}
          {viewAppointment?.appointment_detail?.appointment_schedule}
        </h2>
        <div className="flex gap-3 mb-3">
          <div className="text-base flex gap-2 align-items-center">
            <img src={CalendarIcon} alt="" width={20} />
            {viewAppointment?.appointment_detail?.date?.slice(0, 10)}
          </div>
          <div className="text-base flex gap-2 align-items-center">
            <img src={LocationIcon} alt="" width={15} />
            {viewAppointment?.appointment_detail?.location}
          </div>
        </div>
        {viewAppointment?.time_slots.map((item, index) => {
          return (
            <Accordion className="eyecheckup-accordion" activeIndex={0}>
              <AccordionTab
                header={
                  <div className="flex justify-content-between w-full">
                    <span className="flex align-items-center gap-2 w-full">
                      <span className="font-normal text-sm text-heading">
                        {item.time}
                      </span>
                    </span>
                    <div className="flex gap-2 mr-5">
                      <img
                        src={CalendarIcon1}
                        alt=""
                        width={20}
                        onClick={() => setVisible(true)}
                      />
                      <img
                        src={CrossIcon}
                        alt=""
                        width={20}
                        onClick={() => {
                          setCancelVisible(true);
                          Delete(item);
                        }}
                      />
                    </div>
                  </div>
                }
              >
                <div className="grid justify-content-between">
                  <div className="col-5">
                    <p className="text-heading font-semibold">Clients</p>
                    <div className="flex align-items-center gap-2">
                      {/* <Avatar
                        image="/images/avatar/amyelsner.png"
                        // size="large"
                        shape="circle"
                      /> */}
                      <span className="text-sm text-main font-semibold">
                        <div>
                          {item.booked_appointments.map((item, index) => {
                            return <div>{item?.client_name} </div>;
                          })}
                        </div>
                      </span>
                    </div>
                  </div>
                  <div className="col-2">
                    <p className="text-heading font-semibold">Service</p>
                    <p className="text-heading">
                      {item.booked_appointments.map((item, index) => {
                        return <div> {item?.service_name}</div>;
                      })}
                    </p>
                  </div>
                </div>
              </AccordionTab>
            </Accordion>
          );
        })}
      </div>

      <div className="text-right">
        <CancelButton
          label="Back"
          onClick={() => navigate("/account-owner/manage-appointment")}
        />
      </div>
      <Dialog
        header={headerElement}
        footer={footerContent}
        visible={visible}
        style={{ width: "400px  " }}
        onHide={() => setVisible(false)}
      >
        <p className="m-0">
          Are you sure you want to select this Appointment ?
        </p>
      </Dialog>
      <Dialog
        header={
          <div>
            <h3 className="text-main m-0">Cancellation</h3>
          </div>
        }
        visible={cancelVisible}
        style={{ width: "400px  " }}
        onHide={() => setCancelVisible(false)}
      >
        <SaveButton
          btnclass="w-full"
          label="Cancel for Appointment"
          save_button={false}
          onClick={() => {
            setDeleteData({
              ...deleteData,
              time_slot_ids: client.map((item) => item.id),
            });
            setReasonVisible(true);
          }}
        />
        <CancelButton
          btnclass="w-full ml-0"
          label="Cancel for One Client"
          onClick={() => {
            setcancelAppointmentVisible(true);
          }}
        />
      </Dialog>
      <Dialog
        header={
          <div>
            <h3 className="text-main m-0">Cancellation</h3>
          </div>
        }
        footer={
          <>
            <div>
              <SubmitButton
                btnLabel="Submit"
                label="Ok"
                icon="pi pi-check"
                submitOnClick={() => DeleteClientInfo(deleteData)}
                onClick={() => {
                  setVisible(false);
                  setReasonVisible(false);
                }}
                autoFocus
              />
            </div>
          </>
        }
        visible={reasonVisible}
        style={{ width: "400px  " }}
        onHide={() => setReasonVisible(false)}
      >
        <label>Reason</label>
        <InputTextarea
          className="w-full mt-2"
          onChange={(e) =>
            setDeleteData({ ...deleteData, reason: e.target.value })
          }
        />
      </Dialog>
      <Dialog
        header={
          <div>
            <h3 className="text-main m-0">Cancel Appointment</h3>
          </div>
        }
        footer={
          <>
            <div>
              <SubmitButton
                btnLabel="Submit"
                label="Ok"
                icon="pi pi-check"
                submitOnClick={() => DeleteClientInfo(deleteData)}
                onClick={() => {
                  setVisible(false);
                  setcancelAppointmentVisible(false);
                }}
                autoFocus
              />
            </div>
          </>
        }
        visible={cancelAppointmentVisible}
        style={{ width: "400px  " }}
        onHide={() => setcancelAppointmentVisible(false)}
      >
        <div>
          <label>Client</label>
          <DropdownField
            value={deleteData?.time_slot_ids[0]}
            options={client.map((item) => {
              return { name: item.client_name, value: item.id };
            })}
            optionLabel={"name"}
            onChange={(e) =>
              setDeleteData({ ...deleteData, time_slot_ids: [e.value] })
            }
          />
        </div>
        <div>
          <label>Reason</label>
          <InputTextarea
            className="w-full mt-2"
            onChange={(e) =>
              setDeleteData({ ...deleteData, reason: e.target.value })
            }
          />
        </div>
      </Dialog>
    </div>
  );
};
