
import React, { useState, useEffect } from "react";
import InputField from "../../../components/input/input";
import { Dropdown } from 'primereact/dropdown';
import ContentArea from "../shared/ContentArea";
import SaveButton from "../../../components/Buttons/SaveButton";
import CancelButton from "../../../components/Buttons/CancelButton"; import { Card } from "primereact/card";
import { Calendar } from 'primereact/calendar';
import { useParams, useNavigate } from "react-router-dom";
import { getServiceAction } from "../../../Redux/HospitalAction/serviceProviderAction";
import { editPaCodeAction, viewPaCodeAction } from "../../../Redux/HospitalAction/paCodeAction";
import { useDispatch, useSelector } from "react-redux";
import DropdownField from "../../../components/input/dropdown";
import Loader from "../../../pages/Loader";
import moment from "moment";
export default function EditPaCode() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [value, setValue] = useState(null);
    const [date, setDate] = useState(null);
    const [error, setError] = useState("")
    const [endDate, setEndDate] = useState(null)
    const [startDate, setStartDate] = useState(null)
    const [serviceType, setServiceType] = useState("")
    const [service, setService] = useState("")
    const [data, setData] = useState({
        pa_code: "",
        service_type_id: "",
        start_date: "",
        end_date: ""
    })
    const { id } = useParams()
    useEffect(() => {
        dispatch(getServiceAction())
        dispatch(viewPaCodeAction(id))
    }, [])
    const serviceDatalist = useSelector((state) => state.getService?.getServiceProfile)
    const viewPaCodeData = useSelector((state) => state.viewPaCode.viewPaCodeProfile)
    useEffect(() => {
        setServiceType(serviceDatalist)
        let showstartDate = new Date(data?.start_date?.slice(0, 10))
        let showEndDate = new Date(data?.end_date?.slice(0, 10))
        setStartDate(showstartDate)
        setEndDate(showEndDate)
        setData(viewPaCodeData)
    }, [serviceDatalist, viewPaCodeData])
    useEffect(() => {
        serviceDatalist?.length > 0 && serviceDatalist?.map((item) => {
            if (data?.service_type_id == item.id) {
                setService(item)
            }
        })
    }, [serviceDatalist])
    const handleChange = (e) => {
        const { name, value } = e.target;
        setData((prev) => {
            return {
                ...prev,
                [name]: value
            }
        })
        setError({
            ...error,
            [name]: "",
        });
    }

    const handleService = (e) => {
        setService(e.value)
        setData({
            ...data,
            service_type_id: e.value.id
        })
    }
    const handleStartDate = (e) => {
        setStartDate(e.value)
        let date1 = new Date(e.value)
        let year = date1.getFullYear();
        let month = date1.getMonth() + 1;
        let dt = date1.getDate();
        if (dt < 10) {
            dt = '0' + dt;
        }
        if (month < 10) {
            month = '0' + month;
        }
        let dob = year + '-' + month + '-' + dt;
        var startDate = dob
        var endDate = data.end_date
        const isSameOrAfter = moment(startDate).isSameOrAfter(endDate);
        if (isSameOrAfter === true) {
            setError({
                ...error,
                end_date: "End date must be greater than Start date"
            })
            setStartDate(null)
        }
        else {
            setData({
                ...data,
                start_date: dob
            })
            setError({
                ...error,
                end_date: null
            })
        }
    }
    const handleEndDate = (e) => {
        setEndDate(e.value)
        let date1 = new Date(e.value)
        let year = date1.getFullYear();
        let month = date1.getMonth() + 1;
        let dt = date1.getDate();
        if (dt < 10) {
            dt = '0' + dt;
        }
        if (month < 10) {
            month = '0' + month;
        }
        let dob = year + '-' + month + '-' + dt;
        var startDate = data.start_date
        var endDate = dob
        const isSameOrAfter = moment(startDate).isSameOrAfter(endDate);
        if (isSameOrAfter === true) {
            setEndDate(null)
            setError({
                ...error,
                end_date: "End date must be greater than Start date"
            })
        }
        else {
            setData({
                ...data,
                end_date: dob
            })
            setError({
                ...error,
                end_date: null
            })
        }
    }
    const handleSubmit = () => {
        if (data.pa_code === "" || data.service_type_id === "") {
            setError({
                ...error,
                pa_code: data.pa_code === "" ? "Please Enter PA Code" : error.pa_code,
                service_type_id: data.service_type_id === "" ? "Please Choose Service" : error.serviceType
            })
            return false;
        }
        const obj = data;
        let array = Object.entries(obj)
        const formData = new FormData()
        for (let i = 0; i < array.length; i++) {
            formData.append(array[i][0], array[i][1])

        }
        dispatch(editPaCodeAction(id, formData, () => navigate("/account-owner/manage-pa-code")))
    }
    const isLoading = useSelector((state) => state.loader.isLoading)
    return (
        <>
            {isLoading === true ? <Loader /> :
                <ContentArea>
                    <div className="edit_pa_code_container p-3 ">
                        <div className="grid mb-5 mr-0 ml-0 align-items-center bg-main-light border-round-lg shadow-1 flex justify-content-evenly">
                            <div className="md:col-12 ">
                                <h1 className="text-main ml-3 " style={{ fontSize: "24px" }}>
                                    Edit PA Code
                                </h1>
                            </div>
                        </div>
                        <Card className="shadow-2 p-3 border-round-lg">
                            <div className="p-fluid grid flex mb-5 ">
                                <div className="col-12 md:col-6 ">
                                    <InputField
                                        label="PA Code"
                                        name="pa_code"
                                        value={data?.pa_code}
                                        onChange={handleChange}
                                        errorMessage={error.pa_code}
                                        required
                                    />
                                </div>
                                <div className="col-12 md:col-6">
                                    <DropdownField
                                        label="Service Type"
                                        className="border-round-md mt-2"
                                        placeholder="Select"
                                        required
                                        value={service} options={serviceType} onChange={(e) => handleService(e)} errorMessage={error?.service_type_id} optionLabel="program_name" />
                                </div>
                                <div className="col-12 md:col-6">
                                    <label className="font block mb-2">Start Date <cod className="text-red"></cod></label>
                                    <Calendar className="calender_picker border-round-md" inputClassName="border-round-md" value={startDate} onChange={(e) => handleStartDate(e)} />
                                </div>
                                <div className="col-12 md:col-6 ">
                                    <label className="font block mb-2">End Date <cod className="text-red"></cod></label>
                                    <Calendar className="calender_picker border-round-md" inputClassName="border-round-md" value={endDate} onChange={(e) => handleEndDate(e)} />
                                    {error.end_date && <span className="error">{error.end_date}</span>}
                                </div>
                                <div className="col-12 md:col-4 mt-1">
                                    <div className="submitButton flex mt-3">
                                        <SaveButton label="Save" onClick={handleSubmit}></SaveButton>
                                        <CancelButton label="Cancel" onClick={() => navigate("/account-owner/manage-pa-code")}></CancelButton>
                                    </div>
                                </div>
                            </div>

                        </Card>
                    </div>

                </ContentArea>
            }
        </>
    )
}
