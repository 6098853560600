import React, { useState } from "react";

import { Card } from "primereact/card";

import ContentArea from "../../shared/ContentArea";
import { useDispatch, useSelector } from "react-redux";
import InputField from "../../../../components/input/input";
import UploadImg from "../../../../assets/images/addimage.png";
import { viewEmployeeAction } from "../../../../Redux/HospitalAction/employessAction";
import { getServiceProviderAction } from "../../../../Redux/HospitalAction/serviceProviderAction";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import DropdownField from "../../../../components/input/dropdown";
import InputNumberField from "../../../../components/input/inputNumber";
import { carrierAction } from "../../../../Redux/HospitalAction/carrierAction";
import { getPaCodesAction } from "../../../../Redux/HospitalAction/paCodeAction";
import Loader from "../../../../pages/Loader";
import { Checkbox } from "primereact/checkbox";
import { InputSwitch } from "primereact/inputswitch";
import { getPermissionAction } from "../../../../Redux/HospitalAction/employessAction";
import Permission from "./Permission";
const ViewEmployees = () => {
  const dispatch = useDispatch();
  const [service, setService] = useState(null);
  const [serviceType, setServiceType] = useState(null);
  const [carrierType, setCarrierType] = useState(null);
  const [carrier, setCarrier] = useState(null);
  const [paCode, setPaCode] = useState(null);
  const [showPaCode, setShowPaCode] = useState(null);
  const [permissions, setPermissions] = useState([]);
  const [selectedNodes, setSelectedNodes] = useState({});
  const [data, setData] = useState();
  const [error, setError] = useState();
  const { id } = useParams();
  useEffect(() => {
    dispatch(viewEmployeeAction(id));
    dispatch(getServiceProviderAction());
    dispatch(carrierAction());
    dispatch(getPaCodesAction());
    dispatch(getPermissionAction());
  }, []);
  const getViewData = useSelector(
    (state) => state.viewEmployee.viewEmployeeProfile
  );
  const serviceDatalist = useSelector(
    (state) => state.getServiceProvider?.getServiceProviderProfile
  );
  const carrierDatalist = useSelector((state) => state.carrier.carrierProfile);
  const getPaCodeDatalist = useSelector(
    (state) => state.getPaCode.getPaCodeProfile
  );
  const getPermissionDatalist = useSelector(
    (state) => state.Permissions.getPermissionProfile
  );

  useEffect(() => {
    setServiceType(serviceDatalist);
    setCarrierType(carrierDatalist);
  }, [serviceDatalist, carrierDatalist]);

  useEffect(() => {
    serviceDatalist?.length > 0 &&
      serviceDatalist?.map((item) => {
        if (item.id == getViewData?.service_provider_id) {
          setService(item);
        }
      });
  }, [serviceDatalist, getViewData]);

  useEffect(() => {
    carrierDatalist?.length > 0 &&
      carrierDatalist?.map((item) => {
        if (item.id == getViewData.carrier) {
          setCarrier(item);
        }
      });
  }, [carrierDatalist, getViewData]);

  useEffect(() => {
    let array = [];
    let obj;
    getPaCodeDatalist?.length > 0 &&
      getPaCodeDatalist?.map((item) => {
        obj = {
          paCode: item.pa_code,
          id: item.id,
        };
        array.push(obj);
      });
    setShowPaCode(array);
  }, [getPaCodeDatalist]);

  useEffect(() => {
    showPaCode?.length > 0 &&
      showPaCode?.map((item) => {
        if (item.id == getViewData.pa_code_id) {
          setPaCode(item);
        }
      });
  }, [showPaCode, getViewData]);
  useEffect(() => {
    setData(getViewData);
    setSelectedNodes(getViewData.selected_nodes);
  }, [getViewData]);

  useEffect(() => {
    const permissionData = [];
    getPermissionDatalist?.length > 0 &&
      getPermissionDatalist?.map((permission) => {
        const permObj = {
          key: permission.id,
          label: permission.permission_name,
          data: permission.permission_name,
          icon: "",
          children: [],
        };
        permission?.account_permissions?.map((child) => {
          let childObj = {
            key: permission.id + "-" + child.id,
            label: child.name,
            data: child.name,
          };
          permObj.children.push(childObj);
        });
        permissionData.push(permObj);
      });
    setPermissions(permissionData);
  }, [getPermissionDatalist]);
  const isLoading = useSelector((state) => state.loader.isLoading);
  return (
    <>
      {isLoading === true ? (
        <Loader />
      ) : (
        <ContentArea>
          <div className="view_employees_container">
            <div className="p-3">
              <div className="grid card ml-0 mr-0 px-5 mb-5 align-items-center bg-main-light border-round-lg">
                <h2 className="text-main">View Employees Details</h2>
              </div>
              <Card className="shadow-2 p-3 border-round-lg">
                <div className="p-fluid grid mb-3">
                  <div className=" col-12 md:col-12 text-main ">
                    <h2
                      className=" font-bold flex align-items-center justify-content-between"
                      style={{ fontSize: "22px" }}
                    >
                      Service Provide
                      <span className="flex text-lg text-700">
                        Active &nbsp;&nbsp;{" "}
                        <InputSwitch
                          checked={getViewData?.is_active == 1 ? true : false}
                          name="is_active"
                        />
                      </span>
                    </h2>
                  </div>
                  <div className="col-12 md:col-6">
                    <DropdownField
                      label="Service Provider Company"
                      inputId="dropdown"
                      placeholder="select"
                      value={service}
                      options={serviceType}
                      onChange={(e) => setService(e.value)}
                      optionLabel="service_provider_name"
                      required
                      disabled
                    />
                  </div>
                </div>

                <div className="p-fluid grid">
                  <div className="col-12 md:col-12 text-main ">
                    <h2 className=" font-bold" style={{ fontSize: "22px" }}>
                      Employee Photo<code className="text-red">*</code>
                    </h2>
                  </div>
                </div>

                <div className="p-fluid grid mb-5 flex align-items-center">
                  <div className="upload-image col-12 md:col-2 ">
                    <img
                      src={
                        getViewData?.image?.length != 0
                          ? getViewData?.image
                          : UploadImg
                      }
                      alt=""
                      className="w-full h-full upload-image border-1 surface-border"
                      height={109}
                    />
                  </div>

                  <div className="col-12 md:col-10">
                    <div className="upload_image mb-3">
                      <label
                        htmlFor="profile-img"
                        className="bg-primary cursor-pointer w-3 px-5 border-0 p-2 border-round-md"
                      >
                        <i className="pi pi-plus mr-3"></i>
                        <span>Choose a File</span>
                      </label>
                      <input
                        type="file"
                        hidden
                        className="file-upload text-white border-round-lg"
                        name="profile-img"
                        accept="image/*"
                        maxFileSize={1000000}
                      />
                    </div>
                    <p className="text-500 m-0 text-xs">
                      {" "}
                      Acceptable Formats jpg, png only
                    </p>
                  </div>
                </div>

                <div className="p-fluid grid">
                  <div className="col-12 md:col-6">
                    <InputField
                      label="Employee Id"
                      inputClassName="border-round-md"
                      placeholder=""
                      disabled
                      required
                      value={getViewData?.emp_id}
                    />
                  </div>

                  <div className=" col-12 md:col-6">
                    <InputField
                      type="text"
                      placeholder=""
                      label="Employee Email"
                      value={getViewData?.email}
                      disabled
                      required
                    />
                  </div>
                </div>

                <div className="p-fluid grid">
                  <div className=" col-12 md:col-12 text-main ">
                    <h2 className="font-bold" style={{ fontSize: "22px" }}>
                      Employee Name
                    </h2>
                  </div>
                </div>

                <div className="p-fluid grid">
                  <div className=" col-12 md:col-4">
                    <InputField
                      placeholder=""
                      value={getViewData.first_name}
                      label="First Name"
                      required
                      disabled
                    />
                  </div>

                  <div className="col-12 md:col-4">
                    <InputField
                      placeholder=""
                      value={
                        getViewData.middle_name === "null"
                          ? ""
                          : getViewData.middle_name
                      }
                      label="Middle Name"
                      disabled
                    />
                  </div>

                  <div className=" col-12 md:col-4">
                    <InputField
                      placeholder=""
                      label="Last Name"
                      value={getViewData.last_name}
                      required
                      disabled
                    />
                  </div>
                </div>

                <div className="p-fluid grid">
                  <div className=" col-12 md:col-12 text-main ">
                    <h2 className=" font-bold" style={{ fontSize: "22px" }}>
                      Employee Address
                    </h2>
                  </div>
                </div>

                <div className="p-fluid grid">
                  <div className="col-12 md:col-4">
                    <InputField
                      placeholder=""
                      label="Street Address"
                      value={getViewData.address_1}
                      required
                      disabled
                    />
                  </div>

                  <div className="col-12 md:col-4">
                    <InputField
                      placeholder=""
                      label="State"
                      value={getViewData.state}
                      required
                      disabled
                    />
                  </div>

                  <div className="col-12 md:col-4">
                    <InputNumberField
                      label="Zip Code"
                      placeholder=""
                      inputClassName="border-round-md"
                      value={getViewData.zipcode}
                      required
                      disabled
                      useGrouping={false}
                    />
                  </div>
                </div>

                <div className="p-fluid grid">
                  <div className=" col-12 md:col-12 text-main ">
                    <h2 className="font-bold" style={{ fontSize: "22px" }}>
                      Employee Phone No.
                    </h2>
                  </div>
                </div>
                <div className="p-fluid grid">
                  <div className="col-12 md:col-4">
                    <DropdownField
                      required
                      disabled
                      label="Carrier"
                      inputId="dropdown"
                      placeholder="Select"
                      value={carrier}
                      options={carrierType}
                      optionLabel="carrier_name"
                    />
                  </div>

                  <div className="col-12 md:col-4">
                    <InputNumberField
                      inputClassName="border-round-md"
                      placeholder=""
                      label="Phone No."
                      value={getViewData.mobile}
                      required
                      disabled
                      useGrouping={false}
                    />
                  </div>

                  <div className="col-12 md:col-4">
                    <DropdownField
                      inputId="dropdown"
                      label="Assign PA Code"
                      placeholder="Select"
                      options={showPaCode}
                      optionLabel="paCode"
                      value={paCode}
                      disabled
                      required
                    />
                  </div>
                </div>

                <div className="p-fluid grid py-4">
                  <div className="col-12 md:col-4">
                    <InputNumberField
                      value={getViewData?.hourly_rate}
                      inputClassName=" border-round-md"
                      placeholder=""
                      label="Set Employee Hourly Rate"
                      required
                      disabled
                    />
                  </div>

                  <div className="col-12 md:col-4 ">
                    <InputField
                      placeholder=""
                      label="Employee login/ Username"
                      value={getViewData.username}
                      disabled
                      required
                    ></InputField>
                  </div>

                  <div className="col-12 md:col-4 ">
                    <InputField
                      type="password"
                      label="Employee Password"
                      required
                      disabled
                    ></InputField>
                  </div>
                </div>
                <h3 className="text-main ">Set Permissions</h3>
                <Permission
                  permissions={permissions}
                  disabled={true}
                  selectedNodes={selectedNodes}
                  value={data}
                  setSelectedNodes={setSelectedNodes}
                  setSelectedKeys={setData}
                  setError={setError}
                />
                <div className="p-fluid grid mb-5 flex">
                  <div className="col-12 md:col-12 flex align-items-center">
                    <Checkbox
                      checked={getViewData.is_email_notify == 1 ? true : false}
                    ></Checkbox>
                    &nbsp; Check to receive the Email reminder notifications.
                  </div>

                  <div className="col-12 md:col-12 flex align-items-center">
                    <Checkbox
                      checked={getViewData.is_sms_notify == 1 ? true : false}
                    ></Checkbox>
                    &nbsp; Check to receive the SMS reminder notifications.
                  </div>
                </div>
              </Card>
            </div>
          </div>
        </ContentArea>
      )}
    </>
  );
};

export default ViewEmployees;
