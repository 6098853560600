import { Card } from "primereact/card";
import React, { useEffect, useState } from "react";
import Table from "../../../../components/TableList/Table";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getScheduleListing } from "../../../../Redux/HospitalAction/employessAction";

export const ViewSchedulingDetails = () => {
  const [deleteButton, setDeleteButton] = useState(false);
  const dispatch = useDispatch();
  const { id } = useParams();

  const getSchedulingData = useSelector(
    (state) => state.getSchedulingListing.getEmployeeSchedulingListing
  );

  useEffect(() => {
    dispatch(getScheduleListing(id));
  }, []);
  const timeSchedule = (col) => {
    return (
      <>
        {" "}
        <span>{col?.start_time}</span> - <span>{col?.end_time}</span>
      </>
    );
  };

  const TableHeading = [
    { field: "day", header: "Days" },
    { field: "location", header: "Location" },
    { field: "working_hours", header: "Working hours" },
    { field: "lunch_time", header: "Lunch time" },
  ];

  const LeaveListHeading = [
    { field: "date", header: "Date" },
    { field: "leave_type_name", header: "Leave Type" },
    { field: "category_name", header: "Category" },
  ];

  const LeaveBalanceHeading = [
    { field: "category", header: "Category" },
    { field: "balance", header: "Balance" },
  ];
  const SpecialSchedulingHeading = [
    { field: "date", header: "Date" },
    { field: "working_hour", header: "Working Hour", body: timeSchedule },
    { field: "location_name", header: "Location" },
  ];

  return (
    <div className="p-3">
      <div className="grid card ml-0 mr-0 px-5 mb-5 align-items-center bg-main-light border-round-lg">
        <h2 className="text-main ">View Employee Schedule</h2>
      </div>
      <Card className="shedule shadow-2 p-3">
        <div className=" mt-2">
          <div
            className="flex align-items-center
        "
          >
            <div className=" border-round-lg  mr-4">
              <img
                src={getSchedulingData?.employee_image}
                alt=""
                style={{
                  width: "100px",
                  height: "109px",
                  borderRadius: "10px",
                  objectFit: "contain",
                }}
              />
            </div>
            <div className="flex flex-column">
              <h4 className="font-normal">
                {getSchedulingData?.employee_name}
              </h4>
              <p className="p-0 m-0 text-gray-400">
                {getSchedulingData?.employee_id}
              </p>
              <p className="text-gray-400">
                {getSchedulingData?.employee_email}
              </p>
            </div>
          </div>
          <h3 className="text-main font-22 ">Schedule Details</h3>
          <div className="md:col-12 p-0 mp-0">
            <Table
              tableHeading={TableHeading}
              tableData={getSchedulingData?.schedule_detail}
              setDeleteButton={setDeleteButton}
              deleteButton={deleteButton}
              //   filters={filters}
              //   setFilters={setFilters}
              //   setData={setData}
            />
          </div>
        </div>
        <div className="grid mt-2">
          <h3 className="text-main ml-1 font-22 ">Leave History</h3>
          <div className="md:col-12">
            <Table
              tableHeading={LeaveListHeading}
              tableData={getSchedulingData?.leave_detail}
              setDeleteButton={setDeleteButton}
              deleteButton={deleteButton}
              //   filters={filters}
              //   setFilters={setFilters}
              //   setData={setData}
            />
          </div>
        </div>
        <div className="grid mt-2">
          <h3 className="text-main ml-2 ">Leave Balance</h3>
          <div className="md:col-12">
            <Table
              tableHeading={LeaveBalanceHeading}
              tableData={getSchedulingData?.leave_balance}
              setDeleteButton={setDeleteButton}
              deleteButton={deleteButton}
              //   filters={filters}
              //   setFilters={setFilters}
              //   setData={setData}
            />
          </div>
        </div>
        <div className="grid mt-2">
          <h3 className="text-main ml-2 ">Special Schedule Details</h3>
          <div className="md:col-12">
            <Table
              tableHeading={SpecialSchedulingHeading}
              tableData={getSchedulingData?.special_schedule}
              setDeleteButton={setDeleteButton}
              deleteButton={deleteButton}
              //   filters={filters}
              //   setFilters={setFilters}
              //   setData={setData}
            />
          </div>
        </div>
        <s></s>
      </Card>
    </div>
  );
};
