import React, { useEffect, useState } from "react";
import AddLeave from "./AddLeave";
import SaveButton from "../../../../components/Buttons/SaveButton";
import Table from "../../../../components/TableList/Table";
import { useDispatch, useSelector } from "react-redux";
import {
  DeletCompanyAction,
  LeaveData,
  LocationData,
} from "../../../../Redux/HospitalAction/appoinmentAction";
import EditImg from "../../../../assets/icons/edit.png";
import DeleteImg from "../../../../assets/icons/delete.png";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { toast } from "react-toastify";

const Leave = () => {
  const [showAddLeave, setAddLeave] = useState();
  const [selectLeave, setSelectLeave] = useState();
  const [data, setData] = useState({
    id: "",
    employee_id: "",
    category: "",
    leave_type: "",
    annual_allotment: "",
    type: "leaves",
  });
  const dispatch = useDispatch();
  const [TableData, setTableData] = useState();
  const companyLeave = useSelector((state) => state.companyLeave.companyLeave);
  useEffect(() => {
    dispatch(LocationData());
  }, []);

  const DeleteAction = (data) => {
    dispatch(DeletCompanyAction(data));
    dispatch(LocationData());
  };

  useEffect(() => {
    dispatch(LeaveData());
  }, []);

  useEffect(() => {
    setTableData(companyLeave);
  }, [companyLeave]);
  const accept = () => {
    toast.current.show({
      severity: "info",
      summary: "Confirmed",
      detail: "You have accepted",
      life: 3000,
    });
  };

  const reject = () => {
    toast.current.show({
      severity: "warn",
      summary: "Rejected",
      detail: "You have rejected",
      life: 3000,
    });
  };

  const Delete = (data) => {
    confirmDialog({
      message: "Do you want to delete this record?",
      header: "Delete Confirmation",
      icon: "pi pi-info-circle",
      defaultFocus: "reject",
      acceptClassName: "p-button-danger",
      accept: () => DeleteAction(data),
    });
  };

  const leaveBodyTemplate = (col) => {
    return (
      <div className="flex">
        <img
          src={EditImg}
          onClick={() => {
            setAddLeave(true);
            setData({
              id: col.id,
              employee_id: col?.employee_id,
              category: col?.category,
              leave_type: col?.leave_type,
              annual_allotment: col?.annual_allotment,
              edit: (col.edit = true),
            });
          }}
          width="20px"
          height="20px"
          className="product-image ml-3"
          alt=""
        />

        <img
          src={DeleteImg}
          onClick={() => {
            Delete({
              id: col.id,
              type: "leaves",
            });
          }}
          width="20px"
          height="20px"
          className="product-image ml-3"
          alt=""
        />
      </div>
    );
  };

  const TableHeading = [
    { field: "SrNo", header: "Sr. No.", body: "srnoTemplates" },
    { field: "category", header: "Category" },
    { field: "icons", header: "Action", body: leaveBodyTemplate },
  ];

  return (
    <>
      <ConfirmDialog />
      {showAddLeave ? (
        <AddLeave
          setAddLeave={setAddLeave}
          data={data}
          setData={setData}
          setSelectLeave={setSelectLeave}
          selectLeave={selectLeave}
        />
      ) : (
        <div className="px-4">
          <div className="w-full flex justify-content-between align-items-center">
            <h2 className="">Leave Category Details</h2>

            <SaveButton
              label={"Add"}
              onClick={() => setAddLeave(true)}
            ></SaveButton>
          </div>
          <div className="grid mt-2">
            <div className="md:col-12">
              <Table tableHeading={TableHeading} tableData={TableData} />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Leave;
