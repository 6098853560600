import React, { useRef, useState } from "react";
import ContentArea from "../../../../shared/ContentArea";
import { Card } from "primereact/card";
import Table from "../../../../components/TableList/Table";
import { useNavigate } from "react-router-dom";
import Index from "../../Index";
import { Dropdown } from "primereact/dropdown";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import Deleteimg from "../../../../assets/images/deleted.png";
import { SelectButton } from "primereact/selectbutton";
import {
  LocationData,
  appointmentBookingAction,
} from "../../../../Redux/HospitalAction/appoinmentAction";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import Loader from "../../../../pages/Loader";
import { Calendar } from "primereact/calendar";
import SaveButton from "../../../../components/Buttons/SaveButton";
import FilterButton from "../../../../components/Buttons/FilterButton";
import { OverlayPanel } from "primereact/overlaypanel";
import DropydownMenu from "../../../../components/Dropdown/DropdownMenu";
import { ViewAppointment } from "./ViewAppointment";
import DropdownField from "../../../../components/input/dropdown";

const ManageAppointments = () => {
  const [deleteButton, setDeleteButton] = useState(false);
  const [location, setLocation] = useState("");
  const [date, setDate] = useState({ start_date: null, end_date: null });
  const [dateVal, setDateVal] = useState();
  const { isNextPage, setIsNextPage } = Index();
  const [todayTable, setTodayTable] = useState("");
  const [params, setParams] = useState({});
  const [ShowViewS, setShowViewS] = useState(false);
  const [upcomingTable, setUpcomingTable] = useState();
  const op = useRef(null);
  // const [debouncedSearchValue] = useDebounce("", 800);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const fetchAppointmentBooking = () => {
    dispatch(appointmentBookingAction(params));
  };
  useEffect(() => {
    if (params) {
      dispatch(appointmentBookingAction(params));
    } else {
      dispatch(appointmentBookingAction());
    }
  }, [params]);

  // useEffect(() => {
  //   const filterDate = new Date(date ? date : "")?.toISOString();
  //   setFormatDate(filterDate?.slice(0, 10));

  // }, [formatDate, date]);

  const formatDate = (date, location) => {
    if (date?.start_date && date?.end_date) {
      let start_date = new Date(date?.start_date)?.toISOString()?.slice(0, 10);
      let end_date = new Date(date?.end_date)?.toISOString()?.slice(0, 10);
      setParams((prev) => ({
        location_id: location,
        start_date: start_date,
        end_date: end_date,
      }));
    } else {
      let singleDate = date?.start_date
        ? new Date(date?.start_date)?.toISOString()?.slice(0, 10)
        : "";
      setParams((prev) => ({ location_id: location, date: singleDate }));
    }
  };

  useEffect(() => {
    formatDate(date, location);
  }, [date, location]);

  const appointmentBookingData = useSelector(
    (state) => state.appointmentBooking.appointmentBookingProfile
  );

  useEffect(() => {
    if (appointmentBookingData?.today_appointment !== 0) {
      setTodayTable(appointmentBookingData?.today_appointment?.reverse());
    }
    if (appointmentBookingData?.upcomming_appointment !== 0) {
      setUpcomingTable(
        appointmentBookingData?.upcomming_appointment?.reverse()
      );
    } else {
      setTodayTable("");
      setUpcomingTable("");
    }
  }, [appointmentBookingData]);

  const BookAppointment = () => {
    setIsNextPage(!isNextPage);
    navigate("/account-owner/book-appointment");
  };

  const isLoading = useSelector((state) => state.loader.isLoading);
  const companyLocation = useSelector(
    (state) => state.companyLocation.companyLocation
  );

  useEffect(() => {
    dispatch(LocationData());
  }, []);

  const DateTemplate = (col) => {
    return (
      <>
        <div>{col.date.slice(0, 10)}</div>
      </>
    );
  };

  const Actions = (col) => {
    return (
      <>
        <div
          onClick={() => navigate("/account-owner/view-appointment/" + col.id)}
        >
          <i className="pi pi-eye text-blue-300"></i>
        </div>
      </>
    );
  };

  const TableHeading = [
    { field: "created_at", header: "Date", body: DateTemplate },
    { field: "appointment_schedule", header: "Schedule" },
    {
      field: "location",
      header: "Location",
      // , body: "BookingBodyTemplate"
    },
    { field: "Action", header: "Actions", body: Actions },
  ];

  return (
    <>
      {isLoading === true ? (
        <Loader />
      ) : (
        <ContentArea>
          <div className="add_manage_service_container">
            <div className="p-3">
              <div className="grid mb-5 mr-0 ml-0 align-items-center bg-main-light border-round-lg shadow-1 flex justify-content-around">
                <div className="md:col-9">
                  <h1 className="text-main  " style={{ fontSize: "24px" }}>
                    Manage Appointments
                  </h1>
                </div>
                <div className="col-4 w-max ">
                  <Button
                    className="p-0 px-5 bg-main border-round-md "
                    onClick={() => BookAppointment()}
                  >
                    <p
                      className="p-0 text-white font-medium"
                      style={{ fontSize: "14px" }}
                    >
                      Book Appointments
                    </p>
                  </Button>
                </div>
              </div>
              {!ShowViewS ? (
                <Card className="shadow-2 p-3 border-round-lg">
                  <div className="today_appointment mb-5">
                    <div className="flex justify-content-between mb-3">
                      <Calendar
                        showIcon
                        placeholder="Date / Range"
                        value={dateVal}
                        onChange={(e) => {
                          setDateVal(e.value);
                          setDate({
                            start_date: e.value[0],
                            end_date: e.value[1],
                          });
                        }}
                        selectionMode="range"
                        readOnlyInput
                      />
                      <div className="flex gap-2">
                        <FilterButton
                          label="Filter"
                          onClick={(e) => op.current.toggle(e)}
                        />
                        <OverlayPanel ref={op} width="200px">
                          <div className=" col-12 md:col-6 mb-3">
                            <label className="font block mb-2">Location</label>
                            <Dropdown
                              placeholder={"Select Location"}
                              name="location_id"
                              className="border-round-md mb-2"
                              value={location}
                              onChange={(e) => setLocation(e.value)}
                              options={companyLocation?.map((item) => {
                                return {
                                  name: item.name,
                                  value: item.id,
                                };
                              })}
                              optionLabel={"name"}
                            />
                          </div>
                        </OverlayPanel>

                        {/* <SaveButton label="Date" disabled /> */}
                        <SaveButton
                          label="Clear"
                          onClick={() => {
                            setParams("");
                            setDateVal("");
                            setDate("");
                          }}
                        />
                      </div>
                    </div>
                    <Table
                      tableHeading={TableHeading}
                      tableData={appointmentBookingData}
                      setDeleteButton={setDeleteButton}
                    />
                  </div>
                  {/* 
                <div className="upcoming_appointment mb-5">
                  <h2
                    className="text-main font-bold"
                    style={{ fontSize: "22px" }}
                  >
                    Upcoming’s Appointment
                  </h2>
                  <Table
                    tableHeading={TableHeading}
                    tableData={upcomingTable}
                    setDeleteButton={setDeleteButton}
                  />
                </div> */}
                </Card>
              ) : (
                <ViewAppointment setShowViewS={setShowViewS} />
              )}
              {deleteButton ? (
                <Dialog
                  className="table_delete_modal flex justify-content-center "
                  visible={deleteButton}
                  onHide={() => setDeleteButton(false)}
                  style={{ width: "30vw" }}
                >
                  <p className="m-0">
                    <div className=" flex justify-content-center">
                      <img
                        src={Deleteimg}
                        alt=""
                        width={150}
                        height={127}
                      ></img>
                    </div>
                    <div className="text-center mb-5">
                      <h4 className=" p-0 m-0 ">
                        Are you sure you want to delete this user?
                      </h4>
                      <p className="text-xs text-400">
                        All associated data will also be deleted! There is no
                        undo!
                      </p>
                    </div>
                    <div className=" btn flex justify-content-center">
                      <div className="text-center">
                        <Button
                          label="Cancel"
                          className="w-full surface-500 p-3 px-5    border-0 border-round-md "
                          onClick={() => setDeleteButton(false)}
                        />
                      </div>
                      <div className="text-center">
                        <Button
                          label="Delete"
                          className=" w-full px-5 border-0 p-3 ml-3 border-round-md bg-red-500"
                        />
                      </div>
                    </div>
                  </p>
                </Dialog>
              ) : null}
            </div>
          </div>
        </ContentArea>
      )}
    </>
  );
};

export default ManageAppointments;
