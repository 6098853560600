
import React, { useEffect, useState } from "react";
import { Card } from "primereact/card";
import ContentArea from "../../../../shared/ContentArea";
import InputField from "../../../../components/input/input";
import UploadImg from "../../../../assets/images/addimage.png"
import SaveButton from "../../../../components/Buttons/SaveButton";
import CancelButton from "../../../../components/Buttons/CancelButton";
import { useDispatch, useSelector } from "react-redux";
import DropdownField from "../../../../components/input/dropdown";
import { carrierAction } from "../../../../Redux/HospitalAction/carrierAction";
import { useNavigate } from "react-router-dom";
import InputNumberField from "../../../../components/input/inputNumber";
import { getPaCodesAction } from "../../../../Redux/HospitalAction/paCodeAction";
import { addEmployeeAction, getPermissionAction } from "../../../../Redux/HospitalAction/employessAction";
import { getServiceProviderAction } from "../../../../Redux/HospitalAction/serviceProviderAction";
import Loader from "../../../../pages/Loader";
import EmployeIcon from "../../../../assets/images/Employeeicon.png"
import { validEmail } from "../../../../components/Validation";
import Permission from "./Permission";
const AddEmployees = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [checked, setChecked] = useState(false);
    const [checkbox, setCheckbox] = useState(false)
    const [service, setService] = useState(null);
    const [serviceData, setServiceData] = useState(null)
    const [selectPaCode, SetSelectPaCode] = useState(null);
    const [serviceType, setServiceType] = useState(null);
    const [error, setError] = useState("")
    const [showPaCode, setShowPaCode] = useState("")
    const [selectedNodes, setSelectedNodes] = useState({});
    const [carrierData, setCarrierData] = useState("")
    const [permissions, setPermissions] = useState([]);
    const [data, setData] = useState({
        service_provider_id: "",
        first_name: "",
        middle_name: "",
        last_name: "",
        employee_id: "",
        email: "",
        mobile: "",
        address_1: "",
        state: "",
        zip_code: "",
        carrier_id: "",
        assign_pa_code_id: "",
        hourly_rate: "",
        permissions: [],
        image: "",
        is_sms_notify: 0,
        is_email_notify: 0,
        username: "",
        password: "",
        selected_nodes: {},
    })
    useEffect(() => {
        dispatch(getServiceProviderAction())
        dispatch(carrierAction())
        dispatch(getPaCodesAction())
        dispatch(getPermissionAction())
    }, [])
    const serviceDatalist = useSelector((state) => state.getServiceProvider?.getServiceProviderProfile)
    const carrierDatalist = useSelector((state) => state.carrier.carrierProfile)
    const getPaCodeDatalist = useSelector((state) => state.getPaCode.getPaCodeProfile)
    const getPermissionDatalist = useSelector((state) => state.Permissions.getPermissionProfile)
    const isLoading = useSelector((state) => state.loader.isLoading)
    useEffect(() => {
        setServiceData(serviceDatalist)
        setCarrierData(carrierDatalist)
    }, [serviceDatalist, carrierDatalist])
    const handleChange = (e) => {
        setError({
            ...error,
            [e.target.name]: ""
        })
        setData({
            ...data,
            [e.target.name]: e.target.value
        })
    }
    const handleNumberChange = (e) => {
        setError({
            ...error,
            [e.target?.name]: ""
        })
        setData({
            ...data,
            [e.target?.name]: e?.value
        })
    }
    useEffect(() => {
        setError({
            ...error,
            service_provider_id: null,
            carrier_id: null,
            assign_pa_code_id: null
        })
        setData({
            ...data,
            service_provider_id: service?.id,
            carrier_id: serviceType?.id,
            assign_pa_code_id: selectPaCode?.id
        })
    }, [service, serviceType, selectPaCode])
    useEffect(() => {
        let array = []
        let obj
        getPaCodeDatalist?.length > 0 && getPaCodeDatalist?.map((item) => {
            obj = {
                paCode: item.pa_code,
                id: item.id
            }
            array.push(obj)
        })
        setShowPaCode(array)
    }, [getPaCodeDatalist])
    const handleSubmit = async () => {
        if (data.service_provider_id === undefined || data.image === "" || data.email === "" || data.first_name === "" || data.last_name === ""
            || data.address_1 === "" || data.state === "" || data.zip_code === "" || data.carrier_id === undefined || data.mobile === ""
            || data.assign_pa_code_id === undefined || data.hourly_rate === "" || data.username === "" || data.password === "" || data.permissions?.length === 0 || !validEmail(data.email) ) {
            setError({
                ...error,
                service_provider_id: data.service_provider_id === undefined ? "Please Select Service Provider" : error.service_provider_id,
                image: data.image === "" ? "Please Choose Image" : error.image,
                email: data.email === "" ? "Please Enter Email" : !validEmail(data.email) ? "Please Enter Valid Email": error.email,
                first_name: data.first_name === "" ? "Please Enter First Name" : error.first_name,
                last_name: data.last_name === "" ? "Please Enter Last Name" : error.last_name,
                address_1: data.address_1 === "" ? "Please Enter Address" : error.address_1,
                state: data.state === "" ? "Please Enter State" : error.state,
                zip_code: data.zip_code === "" ? "Please Enter Zip Code" : error.zip_code,
                carrier_id: data.carrier_id === undefined ? "Please Select Carrier" : error.carrier_id,
                mobile: data.mobile === "" ? "Please Enter Mobile" : error.mobile,
                assign_pa_code_id: data.assign_pa_code_id === undefined ? "Please Select PA Code" : error.assign_pa_code_id,
                hourly_rate: data.hourly_rate === "" ? "Please Enter Hour Rate" : error.hourly_rate,
                username: data.username === "" ? "Please Enter User Name" : error.username,
                password: data.password === "" ? "Please Enter Password" : error.password,
                permissions: data.permissions?.length === 0 ? "Please Enter Permission" : error.permissions
            })
            return false;
        }
        const obj = data;
        let array = Object.entries(obj)
        const formData = new FormData()
        formData.append("service_provider_id", data.service_provider_id)
        formData.append("first_name", data.first_name)
        formData.append("middle_name", data.middle_name)
        formData.append("last_name", data.last_name)
        formData.append("employee_id", data.employee_id)
        formData.append("email", data.email)
        formData.append("mobile", data.mobile)
        formData.append("address_1", data.address_1)
        formData.append("state", data.state)
        formData.append("zip_code", data.zip_code)
        formData.append("carrier_id", data.carrier_id)
        formData.append("assign_pa_code_id", data.assign_pa_code_id)
        formData.append("hourly_rate", data.hourly_rate)
        formData.append("permissions", JSON.stringify(data.permissions))
        formData.append("selected_nodes", JSON.stringify(data.selected_nodes))
        formData.append("image", data.image)
        formData.append("is_sms_notify", data.is_sms_notify)
        formData.append("is_email_notify", data.is_email_notify)
        formData.append("username", data.username)
        formData.append("password", data.password)
        dispatch(addEmployeeAction(formData, () => navigate("/account-owner/manage-employees")))
    }
    useEffect(() => {
        const permissionData = [];
        getPermissionDatalist?.length > 0 && getPermissionDatalist?.map((permission) => {
            const permObj = {
                key: permission.id,
                label: permission.permission_name,
                data: permission.permission_name,
                icon: "",
                children: [],
            };
            permission?.account_permissions?.map((child) => {
                let childObj = {
                    key: permission.id + "-" + child.id,
                    label: child.name,
                    data: child.name,
                };
                permObj.children.push(childObj);
            })
            permissionData.push(permObj);
        })
        setPermissions(permissionData);
    }, [getPermissionDatalist])
    return (
        <>
            {isLoading === true ? <Loader /> :
                <ContentArea>
                    <div className="add_employees_container">
                        <div className="p-3">
                            <div className="grid card ml-0 mr-0 px-5 mb-5 align-items-center bg-main-light border-round-lg">
                                <h2 className="text-main">
                                    Add Employees Details
                                </h2>
                            </div>
                            <Card className="shadow-2 p-3 border-round-lg">
                                <div className="p-fluid grid mb-3">
                                    <div className=" col-12 md:col-12 text-main ">
                                        <h2 className=" font-bold" style={{ fontSize: "22px" }}>Service Provider</h2>
                                    </div>
                                    <div className="col-12 md:col-6">
                                        <DropdownField label="Service Provider Company" inputId="dropdown" placeholder="Select" value={service}
                                            options={serviceData} onChange={(e) => setService(e.value)} optionLabel="service_provider_name" required errorMessage={error.service_provider_id} />
                                    </div>
                                </div>

                                <div className="p-fluid grid">
                                    <div className="col-12 md:col-12 text-main ">
                                        <h2 className="font-bold" style={{ fontSize: "22px" }}>Employee Photo<code className="text-red">*</code></h2>
                                    </div>
                                </div>

                                <div className="p-fluid grid flex align-items-center mb-4">
                                    <div className="upload-image col-12 md:col-2">
                                        <p className="mb-2 text-sm">Upload Image</p>
                                        <img 
                                            src={
                                                data.image
                                                    ? URL.createObjectURL(data?.image)
                                                    : EmployeIcon
                                            }
                                            alt=""
                                             className="w-full h-full upload-image border-1 surface-border"
                                            height={109}
                                        />
                                    </div>
                                    <div className="col-12 md:col-10">
                                        <div className="upload_image mb-3">
                                            <label
                                                htmlFor="profile-img"
                                                className="bg-primary cursor-pointer w-3 px-5 border-0 p-2 border-round-md"
                                            >
                                                <i className="pi pi-plus mr-3"></i>
                                                <span>Choose a File</span>
                                            </label>
                                            <input
                                                type="file"
                                                mode="basic"
                                                hidden
                                                className="file-upload text-white border-round-lg"
                                                id="profile-img"
                                                chooseLabel="Choose a file"
                                                name="profile-img"
                                                accept="image/*"
                                                maxFileSize={1000000}
                                                onChange={(e) => {
                                                    setError({
                                                        ...error,
                                                        image: null,
                                                    });
                                                    setData({ ...data, image: e.target.files[0] });
                                                }}
                                            />
                                        </div>
                                        <p className="text-500 m-0 text-xs"> Acceptable Formats jpg, png only </p>
                                    </div>
                                    {error.image && <span className="error">{error.image}</span>}
                                </div>

                                <div className="p-fluid grid">
                                    <div className=" col-12 md:col-6">
                                        <InputField type="text" label="Employee Email" name="email" onChange={(e) => handleChange(e)} value={data.email} required errorMessage={error.email} />
                                    </div>
                                </div>

                                <div className="p-fluid grid">
                                    <div className=" col-12 md:col-12 text-main ">
                                        <h2 className=" font-bold" style={{ fontSize: "22px" }}>Employee Name</h2>
                                    </div>
                                </div>

                                <div className="p-fluid grid">
                                    <div className=" col-12 md:col-4">
                                        <InputField type="text" label="First Name" name="first_name" value={data.first_name} onChange={(e) => handleChange(e)} required errorMessage={error.first_name} />
                                    </div>

                                    <div className="col-12 md:col-4">
                                        <InputField type="text" label="Middle Name" name="middle_name" value={data.middle_name} onChange={(e) => handleChange(e)} />
                                    </div>

                                    <div className=" col-12 md:col-4">
                                        <InputField type="text" label="Last Name" name="last_name" value={data.last_name} onChange={(e) => handleChange(e)} required errorMessage={error.last_name} />
                                    </div>
                                </div>

                                <div className="p-fluid grid">
                                    <div className=" col-12 md:col-12 text-main ">
                                        <h2 className="font-bold" style={{ fontSize: "22px" }}>Employee Address</h2>
                                    </div>
                                </div>

                                <div className="p-fluid grid">
                                    <div className="col-12 md:col-4">
                                        <InputField type="text" label="Street Address" name="address_1" value={data.address_1} onChange={(e) => handleChange(e)} required errorMessage={error.address_1} />
                                    </div>

                                    <div className="col-12 md:col-4">
                                        <InputField type="text" label="State" name="state" value={data.state} onChange={(e) => handleChange(e)} required errorMessage={error.state} />
                                    </div>

                                    <div className="col-12 md:col-4">
                                        <InputNumberField label="Zip Code"
                                            inputClassName="border-round-md" onValueChange={(e) => handleNumberChange(e)} value={data.zip_code} name="zip_code" useGrouping={false} maxLength={5} required errorMessage={error.zip_code} />
                                    </div>
                                </div>

                                <div className="p-fluid grid">
                                    <div className=" col-12 md:col-12 text-main ">
                                        <h2 className=" font-bold" style={{ fontSize: "22px" }}>Employee Phone No.</h2>
                                    </div>
                                </div>
                                <div className="p-fluid grid">
                                    <div className="col-12 md:col-4">
                                        <DropdownField label="Carrier" inputId="dropdown" placeholder="Select" value={serviceType}
                                            options={carrierData} onChange={(e) => setServiceType(e.value)} optionLabel="carrier_name" required errorMessage={error.carrier_id} />
                                    </div>

                                    <div className="col-12 md:col-4">
                                        <InputNumberField inputClassName="border-round-md" label="Phone No." value={data.mobile} name="mobile" onValueChange={(e) => handleNumberChange(e)} useGrouping={false} maxLength={10} required errorMessage={error.mobile} />
                                    </div>

                                    <div className="col-12 md:col-4">
                                        <DropdownField inputId="dropdown" label="Assign PA Code" value={selectPaCode}
                                            placeholder="Select" options={showPaCode} onChange={(e) => SetSelectPaCode(e.value)} optionLabel="paCode" required errorMessage={error.assign_pa_code_id} />
                                    </div>

                                </div>

                                <div className="p-fluid grid py-4">
                                    <div className="col-12 md:col-4">
                                        <InputNumberField placeholder='/hr' label="Set Employee Hourly Rate" onChange={(e) => {
                                            setData({
                                                ...data,
                                                hourly_rate: e.value
                                            })
                                            setError({
                                                ...error,
                                                hourly_rate: null
                                            })
                                        }} value={data.hourly_rate} name="hourly_rate" required errorMessage={error.hourly_rate} inputClassName="border-round-md" />
                                    </div>

                                    <div className="col-12 md:col-4 ">
                                        <InputField label="Employee login/ Username" value={data.username} name="username" onChange={(e) => handleChange(e)} required errorMessage={error.username} />
                                    </div>

                                    <div className="col-12 md:col-4 ">
                                        <InputField type="password" label="Employee Password" value={data.password} name="password" onChange={(e) => handleChange(e)} required errorMessage={error.password} />
                                    </div>
                                </div>
                                <h3 className="text-main ">Set Permissions</h3>
                                <Permission permissions={permissions} value={data} setSelectedKeys={setData} selectedNodes={selectedNodes} setSelectedNodes={setSelectedNodes} error={error}
                                    setError={setError} />
                                <div className="p-fluid grid mb-5 flex">
                                    <div className="col-12 md:col-12 flex align-items-center">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            onChange={(e) => {
                                                setError({
                                                    ...error,
                                                    is_sms_notify: null,
                                                });
                                                setData({
                                                    ...data,
                                                    is_sms_notify: e.target.checked === true ? 1 : 0,
                                                });
                                            }}
                                            value={data.is_sms_notify}
                                            name="is_sms_notify"
                                        />
                                        &nbsp; Check to receive the SMS reminder notifications.
                                    </div>

                                    <div className="col-12 md:col-12 flex align-items-center">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            onChange={(e) => {
                                                setError({
                                                    ...error,
                                                    is_email_notify: null,
                                                });
                                                setData({
                                                    ...data,
                                                    is_email_notify: e.target.checked === true ? 1 : 0,
                                                });
                                            }}
                                            value={data.is_email_notify}
                                            name="is_email_notify"
                                        />
                                        &nbsp; Check to receive the Email reminder notifications.
                                    </div>

                                </div>
                                <div className="submitButton flex">
                                    <SaveButton label="Save" onClick={handleSubmit}></SaveButton>
                                    <CancelButton label="Cancel" onClick={() => navigate("/account-owner/manage-employees")}></CancelButton>
                                </div>
                            </Card>

                        </div>
                    </div>
                </ContentArea>
            }
        </>
    )
}

export default AddEmployees;
