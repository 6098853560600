import React, { useEffect, useState } from "react";
import InputField from "../../../../components/input/input";
import { Dropdown } from "primereact/dropdown";
import { Card } from "primereact/card";
import { Calendar } from "primereact/calendar";
import ContentArea from "../../shared/ContentArea";
import SaveButton from "../../../../components/Buttons/SaveButton";
import CancelButton from "../../../../components/Buttons/CancelButton";
import { CommonAppointmnet } from "./CommonAppointment";
import moment from "moment";
import Loader from "../../../../pages/Loader";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { LocationData } from "../../../../Redux/HospitalAction/appoinmentAction";
const BookAppointment = () => {
  const {
    getClientData,
    form,
    setForm,
    client,
    serviceData,
    isLoading,
    setClient,
    calendarData,
    navigate,
    ScheduleData,
    // getAppointmentData,
    selectedServiceData,
    pacode,
    setPaCode,
    getServiceData,
    service,
    setService,
    setError,
    error,
    setLocation,
    location,
    handleChange,
    handleSubmit,
  } = CommonAppointmnet();
  const dispatch = useDispatch();
  const [selectDate, setSelectDate] = useState("");
  const [checkAppoitmnet, setCheckAppointment] = useState("");
  const [checkAppointmentId, setCheckAppointmentId] = useState("");
  useEffect(() => {
    let array = [];
    let constArray = [0, 1, 2, 3, 4, 5, 6];
    pacode?.appointment_week?.length > 0 &&
      pacode?.appointment_week?.map((item) => {
        if (item?.dayName === "Monday") {
          array.push(1);
        }
        if (item.dayName === "Tuesday") {
          array.push(2);
        }
        if (item?.dayName === "Wednesday") {
          array.push(3);
        }
        if (item?.dayName === "Thursday") {
          array.push(4);
        }
        if (item?.dayName === "Friday") {
          array.push(5);
        }
        if (item?.dayName === "Saturday") {
          array.push(6);
        }
        if (item?.dayName === "Sunday") {
          array.push(0);
        }
      });
    let data = constArray.filter((item) => {
      return !array.find((child) => {
        return item === child;
      });
    });
    setSelectDate(data);
  }, [pacode]);
  useEffect(() => {
    let array = [];
    let check = false;
    calendarData?.length > 0 &&
      calendarData?.map((item) => {
        if (
          moment(item?.date).format("DD-MM-YYYY") ===
          moment(form?.date).format("DD-MM-YYYY")
        ) {
          selectedServiceData?.length > 0 &&
            selectedServiceData?.map((child) => {
              if (
                item?.event?.booked_time_slot === child?.time &&
                item?.event?.appointment_schedule_id === child?.appointment_id
              ) {
                array?.push(item);
                if (child?.appointment_allowed == array?.length) {
                  check = true;
                  setCheckAppointmentId(item?.event?.booked_time_slot);
                }
              }
            });
        }
      });
    setCheckAppointment(check);
  }, [selectedServiceData]);
  useEffect(() => {
    let hoursData;
    let hours;
    selectedServiceData?.length > 0 &&
      selectedServiceData?.map((item) => {
        let text = item?.time;
        const myArray = text.split(" - ");
        let startTime = moment(myArray[0], "HH:mm");
        let endTime = moment(myArray[1], "HH:mm");
        let duration = moment.duration(endTime.diff(startTime));
        hours = parseInt(duration.asHours());
        hoursData = "0" + hours + " " + "hour";
      });
    if (service?.duration !== undefined) {
      if (
        hoursData === service?.duration ||
        parseInt(service?.duration) < hours
      ) {
      } else {
        toast.error(
          "Your selected services duration is more than selected time slot"
        );
        // alert()
        setService("");
      }
    }
  }, [selectedServiceData, service]);
  const Location = useSelector(
    (state) => state.companyLocation.companyLocation
  );
  useEffect(() => {
    dispatch(LocationData());
  }, []);
  return (
    <>
      {isLoading === true ? (
        <Loader />
      ) : (
        <ContentArea>
          <div className="add_client_pa_code_container">
            <div className="p-3">
              <div className="grid card ml-0 mr-0 px-5 mb-5 align-items-center bg-main-light border-round-lg">
                <h2 className="text-main">Book Appointment</h2>
              </div>
              <Card className="shadow-2 p-3 border-round-lg">
                <div className="p-fluid grid mb-5 p-0 m-0">
                  <div className=" col-12 md:col-6 mb-3">
                    <label className="font block mb-2"> Client </label>
                    <Dropdown
                      className="border-round-md mb-2"
                      placeholder="Select"
                      value={client}
                      options={getClientData}
                      itemTemplate={(e) => {
                        return (
                          <>
                            {e.profile_id} {e.first_name} {e.last_name}
                          </>
                        );
                      }}
                      onChange={(e) => setClient(e.value)}
                      optionLabel="profile_id"
                      filter
                    />
                    {error?.client_id && (
                      <span className="error">{error?.client_id}</span>
                    )}
                  </div>
                  <div className=" col-12 md:col-6 mb-3">
                    <label className="font block">Client Name</label>
                    <InputField value={form?.client_name} disabled />
                  </div>
                  <div className=" col-12 md:col-6 mb-3">
                    <label className="font block mb-2"> Location </label>
                    <Dropdown
                      placeholder={"Select Location"}
                      name="location_id"
                      className="border-round-md mb-2"
                      value={location}
                      onChange={(e) => setLocation(e.value)}
                      options={Location}
                      optionLabel={"name"}
                    />
                  </div>
                  <div className="field col-12 md:col-6">
                    <label className="font block ">Schedule</label>
                    <Dropdown
                      className="border-round-md mb-2"
                      placeholder="Select"
                      value={pacode}
                      options={ScheduleData}
                      onChange={(e) => setPaCode(e.value)}
                      optionLabel="appointment_name"
                    />
                    {error?.appointment_schedule_id && (
                      <span className="error">
                        {error?.appointment_schedule_id}
                      </span>
                    )}
                  </div>
                  <div className="col-12 md:col-6 mb-3 ">
                    <label className="font block mb-2">Select Date</label>
                    <Calendar
                      className="border-round-md calender_picker mb-2"
                      inputClassName="calendar p-2 border-round-md mb-2"
                      placeholder="select"
                      value={form?.date}
                      onChange={(e) => {
                        setForm({
                          ...form,
                          date: e.value,
                        });
                        setError({
                          ...error,
                          date: null,
                        });
                      }}
                      disabledDays={selectDate}
                      minDate={new Date()}
                    />
                    {error?.date && (
                      <span className="error">{error?.date}</span>
                    )}
                  </div>
                  <div className="field col-12 md:col-6">
                    <label className="font block mb-2">Service</label>
                    <Dropdown
                      className="border-round-md mb-2"
                      placeholder="Select"
                      value={service}
                      options={serviceData}
                      optionLabel="service_name"
                      onChange={(e) => setService(e.value)}
                    />
                    {error?.service_id && (
                      <span className="error">{error?.service_id}</span>
                    )}
                  </div>

                  {/* <div className="field col-12 md:col-6">
                    <label className="font block mb-0">Appointment Name</label>
                    <InputField
                      value={form?.appointment_name}
                      name="appointment_name"
                      onChange={(e) => {
                        setError({
                          ...error,
                          appointment_name: null,
                        });
                        setForm({
                          ...form,
                          appointment_name: e.target.value,
                        });
                      }}
                    />
                    {error?.appointment_name && (
                      <span className="error">{error?.appointment_name}</span>
                    )}
                  </div> */}
                </div>
                <label className="font block  ml-2">Available Timeslot</label>
                <div className="grid p-0 m-0">
                  {form?.date !== "" &&
                    selectedServiceData?.length > 0 &&
                    selectedServiceData?.map((item, index) => {
                      return (
                        <div className="field_timesloat md:col-4">
                          <InputField
                            className={
                              item?.id === form?.slot_id
                                ? "bg-blue-100 border-round-md w-full mt-2 p-2 bg-blue-200"
                                : "border-round-md w-full mt-2 p-2"
                            }
                            placeholder=""
                            onClick={() => {
                              setError({
                                ...error,
                                slot_id: null,
                              });
                              setForm({
                                ...form,
                                slot_id: item?.id,
                              });
                            }}
                            value={item?.time}
                            disabled={
                              checkAppoitmnet === true &&
                              checkAppointmentId === item?.time
                                ? true
                                : false
                            }
                          />
                        </div>
                      );
                    })}
                </div>
                {error?.slot_id && (
                  <span className="error">{error?.slot_id}</span>
                )}
                <div className="submitButton flex">
                  <SaveButton label="Save" onClick={handleSubmit}></SaveButton>
                  <CancelButton
                    label="Cancel"
                    onClick={() =>
                      navigate("/account-owner/manage-appointment")
                    }
                  ></CancelButton>
                </div>
              </Card>
            </div>
          </div>
        </ContentArea>
      )}
    </>
  );
};

export default BookAppointment;
