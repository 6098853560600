import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addAppontmentAction,
  viewAppointmentDataAction,
  editAppointmentDataAction,
} from "../../../../../Redux/HospitalAction/appoinmentAction";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
export const CommonSchedule = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [error, setError] = useState({});
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [form, setForm] = useState({
    appointment_name: "",
    location_id: "",
    time_schedule: [
      {
        day: 0,
        start_time: "",
        end_time: "",
        is_available: 0,
        time_duration: "",
        time_duration_data: [],
      },
      {
        day: 1,
        start_time: "",
        end_time: "",
        is_available: 0,
        time_duration: "",
        time_duration_data: [],
      },
      {
        day: 2,
        start_time: "",
        end_time: "",
        is_available: 0,
        time_duration: "",
        time_duration_data: [],
      },
      {
        day: 3,
        start_time: "",
        end_time: "",
        is_available: 0,
        time_duration: "",
        time_duration_data: [],
      },
      {
        day: 4,
        start_time: "",
        end_time: "",
        is_available: 0,
        time_duration: "",
        time_duration_data: [],
      },
      {
        day: 5,
        start_time: "",
        end_time: "",
        is_available: 0,
        time_duration: "",
        time_duration_data: [],
      },
      {
        day: 6,
        start_time: "",
        end_time: "",
        is_available: 0,
        time_duration: "",
        time_duration_data: [],
      },
    ],
    is_applicable: 1,
    is_active: "",
    notes: "",
    isView: false,
    reminder: [
      {
        reminder_type: "",
      },
      {
        reminder_type: "",
      },
      {
        reminder_type: "",
      },
    ],
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value,
    });
    setError({
      ...error,
      [name]: null,
    });
  };
  const hour = [
    "30 min",
    "01 hour",
    "02 hour",
    "03 hour",
    "04 hour",
    "05 hour",
    "06 hour",
    "07 hour",
    "08 hour",
    "09 hour",
    "10 hour",
  ];
  const time = [
    { label: "10 min", value: "10min" },
    { label: "15 min", value: "15min" },
    { label: "30 min", value: "30min" },
    { label: "01 hour", value: "01hour" },
    { label: "02 hour", value: "02hour" },
    { label: "12 hour", value: "12hour" },
    { label: "01 Day", value: "01Day" },
    { label: "02 Days", value: "02Days" },
    { label: "07 Days", value: "07Days" },
    { label: "15 Days", value: "15Days" },
    { label: "30 Days", value: "30Days" },
  ];
  useEffect(() => {
    if (id) {
      dispatch(viewAppointmentDataAction(id));
    }
  }, [id]);
  const handleReminder = (e, index) => {
    let array = [...form.reminder];
    let obj = { ...form.reminder[index], reminder_type: e.value };
    array?.splice(index, 1, obj);
    setForm({
      ...form,
      reminder: array,
    });
    setError({
      ...error,
      reminder: null,
    });
  };
  const checkErrors = () => {
    let array = [];
    let key = true;
    form?.time_schedule?.map((item) => {
      if (item?.is_available === 1 && item?.time_duration_data?.length === 0) {
        key = false;
        let obj = {
          error: "Please Select Slot",
          day: item?.day,
          end_time: item?.end_time,
          is_available: item?.is_available,
          start_time: item?.start_time,
          time_duration: item?.time_duration,
          time_duration_data: item?.time_duration_data,
        };
        array.push(obj);
      } else {
        array.push(item);
      }
    });
    setForm({
      ...form,
      time_schedule: array,
    });
    if (form?.appointment_name === "") {
      key = false;
      setError({
        ...error,
        appointment_name: !form.appointment_name
          ? "Please Enter Appointment Name"
          : error?.appointment_name,
      });
    } else if (form?.location_id === "") {
      key = false;
      setError({
        ...error,
        location_id: !form?.location_id
          ? "Please Select Location"
          : error?.location_id,
      });
    } else if (form?.is_applicable === "" || form?.is_applicable === 1) {
      let data = false;
      form?.reminder?.length > 0 &&
        form?.reminder?.map((item) => {
          if (item?.reminder_type?.length !== 0) {
            data = true;
          }
        });
      if (data === false) {
        key = false;
        setError({
          ...error,
          reminder: data === false ? "Please Enter Reminder" : error.reminder,
        });
      } else {
        key = true;
      }
    }
    if (key === false) {
      return false;
    } else {
      return true;
    }
  };
  const handleSubmit = () => {
    let obj;
    let array = [];
    let reminderArray = [];
    if (!checkErrors()) {
      return false;
    } else {
      form?.time_schedule?.map((item, index) => {
        if (item?.is_available === 0) {
          obj = {
            day: item?.day,
            end_time: "",
            is_available: item?.is_available,
            start_time: "",
            time_duration: "",
            time_duration_data: [],
          };
        } else {
          obj = {
            day: item?.day,
            end_time: item?.end_time,
            is_available: item?.is_available,
            start_time: item?.start_time,
            time_duration: item?.time_duration,
            time_duration_data: item?.time_duration_data,
          };
        }
        array.push(obj);
      });
      if (form?.is_applicable === 1) {
        form?.reminder?.map((item) => {
          if (item?.reminder_type !== "") {
            reminderArray?.push(item);
          }
        });
      } else {
        reminderArray = [];
      }
      let payload = {
        appointment_name: form?.appointment_name,
        location_id: form?.location_id,
        is_active: form?.is_active,
        is_applicable: form?.is_applicable,
        notes: form?.notes,
        reminder: reminderArray,
        time_schedule: array,
      };

      if (id) {
        dispatch(
          editAppointmentDataAction(id, payload, () =>
            navigate("/account-owner/manage-schedules")
          )
        );
      } else {
        dispatch(
          addAppontmentAction(payload, () =>
            navigate("/account-owner/manage-schedules")
          )
        );
      }
    }
  };

  const viewData = useSelector(
    (state) => state.viewAppointmentData.viewAppointmentDataProfile
  );
  useEffect(() => {
    if (viewData) {
      let array = [];
      let reminderArray = [];
      viewData?.appointment_week?.length > 0 &&
        viewData?.appointment_week?.map((item) => {
          let slotArray = [];
          let slotData;
          item?.slots?.length > 0 &&
            item?.slots?.map((slot) => {
              slotData = {
                time: slot?.time,
                select: slot?.appointment_allowed,
                is_block: slot?.block,
              };
              slotArray.push(slotData);
            });
          let obj = {
            day: item?.day,
            start_time: moment(item?.start_time).format("HH:mm"),
            end_time: moment(item?.end_time).format("HH:mm"),
            is_available: item?.is_available,
            time_duration: item?.time_slot_duration,
            time_duration_data: slotArray,
          };
          array.push(obj);
        });
      viewData?.appointment_reminder?.length > 0 &&
        viewData?.appointment_reminder?.map((reminder, index) => {
          let reminderData = {
            reminder_type: reminder?.reminder,
          };
          reminderArray.push(reminderData);
        });
      for (let i = 0; i <= 2 - viewData?.appointment_reminder?.length; i++) {
        let obj = {
          reminder_type: "",
        };
        reminderArray.push(obj);
      }
      setForm({
        ...form,
        appointment_name: viewData?.appointment_name,
        location_id: viewData?.location_id,
        is_applicable: viewData?.is_applicable,
        is_active: viewData?.is_active,
        notes: viewData?.notes,
        time_schedule: array,
        reminder: reminderArray,
        isView: true,
      });
    }
  }, [viewData, id]);

  return {
    form,
    setForm,
    handleChange,
    hour,
    time,
    handleReminder,
    handleSubmit,
    navigate,
    startTime,
    setStartTime,
    endTime,
    setEndTime,
    viewData,
    id,
    error,
  };
};
