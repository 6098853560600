import React, { useState } from "react";
import MobileMenu from "./MobileMenu";
import SideBar from "./SideBar";
import TopBar from "./TopBar";
import Index from "./index";
export default function AccontOwnerLayout({ children }) {
  const { allTabs, activeComponent, changeComponent } = Index();
  const [showFullSideBar, setShowFullSideBar] = useState(false);

  return (
    <>
      <SideBar
        allTabs={allTabs}
        activeComponent={activeComponent}
        showFullSideBar={showFullSideBar}
        setShowFullSideBar={setShowFullSideBar}
        changeComponent={changeComponent}
      />
      <div
        className="main-content m-0 p-0"
        style={{
          width: !showFullSideBar ? "calc(100% - 300px)" : "calc(100% - 100px)",
        }}
      >
        <MobileMenu />
        <TopBar />
        {children}
      </div>
    </>
  );
}
