import React from "react";
import LoginTopbar from "./LoginTopbar";
import PricingImg from "../assets/images/pricingbg.png";
import Container from "../components/container/Container";
import CustomButton from "../components/Buttons/CustomButton";

const Pricing = () => {
  return (
    <>
      <LoginTopbar />
      {/* banner */}
      <div
        className="about-banner h-25rem py-7"
        style={{ background: `url(${PricingImg})` }}
      >
        <h1 className=" relative text-white text-6xl">Pricing</h1>
      </div>
      {/* banner end */}
      <div className="py-7">
        <Container>
          <div className="w-full">
            <div className="grid w-full mt-5">
              <div className="col-12 md:col-3">
                <div className="pricing-card">
                  <p className="pricing-name m-0 font-bold">
                    Home Health Program
                  </p>
                  <h1 className="text-6xl my-3 price">
                    $40 <span className="text-sm font-normal">/per person</span>
                  </h1>
                  <p className="pricing-detail">
                    Lorem Ipsum is simply dummy text of the printing &
                    typesetting industry.
                  </p>
                  <ul className="p-0 pricing-list list-none">
                    <li className="flex gap-3 align-items-center mb-3">
                      <div className="icon-check">
                        <i className="pi pi-check"></i>
                      </div>
                      Unlimited Storage
                    </li>
                    <li className="flex gap-3 align-items-center mb-3">
                      <div className="icon-check">
                        <i className="pi pi-check"></i>
                      </div>
                      Access to all basic features
                    </li>
                  </ul>
                  <CustomButton
                    label="Start free trial"
                    btnclass="pricing-btn"
                  />
                </div>
              </div>
              <div className="col-12 md:col-3">
                <div className="pricing-card">
                  <p className="pricing-name m-0 font-bold">
                    Adult Day Program
                  </p>
                  <h1 className="text-6xl my-3 price">
                    $40 <span className="text-sm font-normal">/per person</span>
                  </h1>
                  <p className="pricing-detail">
                    Lorem Ipsum is simply dummy text of the printing &
                    typesetting industry.
                  </p>
                  <ul className="p-0 pricing-list list-none">
                    <li className="flex gap-3 align-items-center mb-3">
                      <div className="icon-check">
                        <i className="pi pi-check"></i>
                      </div>
                      Unlimited Storage
                    </li>
                    <li className="flex gap-3 align-items-center mb-3">
                      <div className="icon-check">
                        <i className="pi pi-check"></i>
                      </div>
                      Access to all basic features
                    </li>
                  </ul>
                  <CustomButton
                    label="Start free trial"
                    btnclass="pricing-btn"
                  />
                </div>
              </div>
              <div className="col-12 md:col-3">
                <div className="pricing-card">
                  <p className="pricing-name m-0 font-bold">
                    Senior Facility Program
                  </p>
                  <h1 className="text-6xl my-3 price">
                    $50 <span className="text-sm font-normal">/per person</span>
                  </h1>
                  <p className="pricing-detail">
                    Lorem Ipsum is simply dummy text of the printing &
                    typesetting industry.
                  </p>
                  <ul className="p-0 pricing-list list-none">
                    <li className="flex gap-3 align-items-center mb-3">
                      <div className="icon-check">
                        <i className="pi pi-check"></i>
                      </div>
                      Unlimited Storage
                    </li>
                    <li className="flex gap-3 align-items-center mb-3">
                      <div className="icon-check">
                        <i className="pi pi-check"></i>
                      </div>
                      Access to all basic features
                    </li>
                  </ul>
                  <CustomButton
                    label="Start free trial"
                    btnclass="pricing-btn"
                  />
                </div>
              </div>
              <div className="col-12 md:col-3">
                <div className="pricing-card">
                  <p className="pricing-name m-0 font-bold">
                    Education Program
                  </p>
                  <h1 className="text-6xl my-3 price">
                    $60 <span className="text-sm font-normal">/per person</span>
                  </h1>
                  <p className="pricing-detail">
                    Lorem Ipsum is simply dummy text of the printing &
                    typesetting industry.
                  </p>
                  <ul className="p-0 pricing-list list-none">
                    <li className="flex gap-3 align-items-center mb-3">
                      <div className="icon-check">
                        <i className="pi pi-check"></i>
                      </div>
                      Unlimited Storage
                    </li>
                    <li className="flex gap-3 align-items-center mb-3">
                      <div className="icon-check">
                        <i className="pi pi-check"></i>
                      </div>
                      Access to all basic features
                    </li>
                  </ul>
                  <CustomButton
                    label="Start free trial"
                    btnclass="pricing-btn"
                  />
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Pricing;
