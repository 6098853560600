import React from "react";
import ContentArea from "../../../shared/ContentArea";
import SearchIcon from "../../../assets/icons/search.png"
import FilterIcon from "../../../assets/icons/filter.png"
import Table from "../../../components/TableList/Table";
import { TabView, TabPanel } from 'primereact/tabview';
import { Button } from 'primereact/button';
const ViewExpenseRevenue = () => {
    const TableData = [
        {
            srno: "1",
            name: "Electricity",
            Expenses: "Expense 1",
            Amount: "$500",
            description: "Electricity bill for oct.",
            assignment: "Service Provider"

        },
        {
            srno: "2",
            name: "Electricity",
            Expenses: "Expense 2",
            description: "Electricity bill for oct.",
            Amount: "$500",
            assignment: "Service Provider"
        },
        {
            srno: "3",
            name: "Electricity",
            Expenses: "Expense 3",
            description: "Electricity bill for oct.",
            Amount: "$500",
            assignment: "Service Provider"
        },
        {
            srno: "4",
            name: "Electricity",
            Expenses: "Expense 4",
            description: "Electricity bill for oct.",
            Amount: "$500",
            assignment: "Service Provider"
        },
        {
            srno: "5",
            name: "Electricity",
            Expenses: "Expense 5",
            description: "Electricity bill for oct.",
            Amount: "$500",
            assignment: "Service Provider"
        },
        {
            srno: "6",
            name: "Electricity",
            Expenses: "Expense 6",
            description: "Electricity bill for oct.",
            Amount: "$500",
            assignment: "Service Provider"
        },
        {
            srno: "7",
            name: "Electricity",
            Expenses: "Expense 7",
            description: "Electricity bill for oct.",
            Amount: "$500",
            assignment: "Service Provider"
        },
        {
            srno: "8",
            name: "Electricity",
            Expenses: "Expense 8",
            description: "Electricity bill for oct.",
            Amount: "$500",
            assignment: "Service Provider"
        },
        {
            srno: "9",
            name: "Electricity",
            Expenses: "Expense 9",
            description: "Electricity bill for oct.",
            Amount: "$500",
            assignment: "Service Provider"
        },
        {
            srno: "10",
            name: "Electricity",
            Expenses: "Expense 10",
            description: "Electricity bill for oct.",
            Amount: "$500",
            assignment: "Service Provider"
        },

    ]
    const TableDatas = [
        {
            srno: "1",
            name: "Electricity",
            Expenses: "Revenue 1",
            Amount: "$500",
            description: "Electricity bill for oct.",
            assignment: "Service Provider"

        },
        {
            srno: "2",
            name: "Electricity",
            Expenses: "Revenue 2",
            description: "Electricity bill for oct.",
            Amount: "$500",
            assignment: "Service Provider"
        },
        {
            srno: "3",
            name: "Electricity",
            Expenses: "Revenue 3",
            description: "Electricity bill for oct.",
            Amount: "$500",
            assignment: "Service Provider"
        },
        {
            srno: "4",
            name: "Electricity",
            Expenses: "Revenue 4",
            description: "Electricity bill for oct.",
            Amount: "$500",
            assignment: "Service Provider"
        },
        {
            srno: "5",
            name: "Electricity",
            Expenses: "Revenue 5",
            description: "Electricity bill for oct.",
            Amount: "$500",
            assignment: "Service Provider"
        },
        {
            srno: "6",
            name: "Electricity",
            Expenses: "Revenue 6",
            description: "Electricity bill for oct.",
            Amount: "$500",
            assignment: "Service Provider"
        },
        {
            srno: "7",
            name: "Electricity",
            Expenses: "Revenue 7",
            description: "Electricity bill for oct.",
            Amount: "$500",
            assignment: "Service Provider"
        },
        {
            srno: "8",
            name: "Electricity",
            Expenses: "Revenue 8",
            description: "Electricity bill for oct.",
            Amount: "$500",
            assignment: "Service Provider"
        },
        {
            srno: "9",
            name: "Electricity",
            Expenses: "Revenue 9",
            description: "Electricity bill for oct.",
            Amount: "$500",
            assignment: "Service Provider"
        },
        {
            srno: "10",
            name: "Electricity",
            Expenses: "Revenue 10",
            description: "Electricity bill for oct.",
            Amount: "$500",
            assignment: "Service Provider"
        },

    ]
    const TableHeading = [
        { field: "srno", header: "Sr. No.", },
        { field: "name", header: "Category Name", },
        { field: "Expenses", header: "Expenses Name", },
        { field: "description", header: "Description", },
        { field: "Amount", header: "Amount" },
        { field: "assignment", header: "Assignment", },
    ];

    const TableHeadings = [
        { field: "srno", header: "Sr. No.", },
        { field: "name", header: "Category Name", },
        { field: "Expenses", header: "Revenue Name", },
        { field: "description", header: "Description", },
        { field: "Amount", header: "Amount" },
        { field: "assignment", header: "Assignment", },
    ];
    return (
        <>
            <ContentArea>
                <div className="manage_employees_container p-3 ">

                    <div className="grid mb-5 mr-0 ml-0 align-items-center bg-main-light border-round-lg shadow-1 flex justify-content-around">
                        <div className="md:col-7 ">
                            <h1 className="text-main  " style={{ fontSize: "24px" }}>
                                View Expenses and Revenue
                            </h1>
                        </div>
                        <div className="md:col-3">
                            <div className="search-box w-full">
                                <img
                                    src={SearchIcon}
                                    alt="Search Icon"
                                    width="15px"
                                    height="15px"
                                />
                                <input type="search" placeholder="Search" />
                            </div>
                        </div>
                        <div className="md:col-2 w-max ">
                            <Button label="Export" className="bg-main karla px-4 border-round-md" />
                        </div>
                    </div>
                    <p className="text-right text-end expanses-date">Date : 12-04-2023</p>
                    <div className="grid ">
                        <div className="col-12 md:col-12">
                        <TabView>
                            <TabPanel header={<><p className="p-0 m-0 " style={{paddingRight:"10rem"}} >Expenses </p></>}>
                                <div className=" mt-2 mb-5">
                                    <div className="md:col-12">

                                        <Table tableHeading={TableHeading}
                                            tableData={TableData}
                                        />
                                    </div>

                                </div>
                            </TabPanel>
                            <TabPanel header={<><p className="p-0 m-0 " style={{paddingRight:"10rem"}} >Revenue </p></>}>
                                <div className=" mt-2 mb-5">
                                    <div className="">

                                        <Table tableHeading={TableHeadings}
                                            tableData={TableDatas}
                                        />
                                    </div>

                                </div>
                            </TabPanel>
                        </TabView>

                        </div>
                    </div>



                </div>
            </ContentArea>
        </>
    )
}

export default ViewExpenseRevenue;