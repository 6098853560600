import React from "react";

export default function ContentArea({children }) {
    return (
        <div className="my-1 p-5 pt-2">
            <div className="card">
                <div className="mt-4">{children}</div>
            </div>
        </div>
    );
};
