import { types } from "../HospitalType/types";
import HospitalConstants from "../../Services/HospitalConstants";
import { HospitalApi } from "../../Services/HospitalApi";
import { showLoaderAction, hideLoaderAction } from "./loaderAction";
import { showToast } from "./toastAction";
import { authenticate, isAuthenticated } from "../../Services/authService";
export const loginAction = (data, next) => async (dispatch) => {
  dispatch(showLoaderAction());
  const res = await HospitalApi(
    "post",
    HospitalConstants.END_POINT.LOGIN,
    data
  );
  if (res.success === true) {
    if (res.data) {
      dispatch({
        type: types.LOGIN,
        payload: res.data,
      });
      authenticate(res.data.token, () => {
        if (isAuthenticated()) {
          localStorage.setItem("name", res.data.name);
          localStorage.setItem("token", JSON.stringify(res.data.token));
          localStorage.setItem("roletype", JSON.stringify(res.data.role));
        }
      });
    }
    dispatch(hideLoaderAction());
    next(true);
    dispatch(showToast({ severity: "success", summary: res.message }));
  } else {
    next(false);
    dispatch(hideLoaderAction());
    dispatch(showToast({ severity: "error", summary: res.message }));
  }
};
