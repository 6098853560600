import { types } from "../HospitalType/types";


const intitalState = {
    clientUserProfile: {},
    checkDiscountProfile: {},
    paymentProfile: {},
    subscriptionPlanProfile: {}
};
export const SignupReducer = (state = intitalState, action) => {
    switch (action.type) {
        case types.SIGNUP:
            return {
                ...state,
                clientUserProfile: action.payload,
            };
        default:
            return { ...state };
    }
};

export const checkDiscountReducer = (state = intitalState, action) => {
    switch (action.type) {
        case types.DISCOUNT_CODE:
            return {
                ...state,
                checkDiscountProfile: action.payload,
            };
        default:
            return { ...state };
    }
};

export const paymentReducer = (state = intitalState, action) => {
    switch (action.type) {
        case types.PAYMENT:
            return {
                ...state,
                paymentProfile: action.payload,
            };
        default:
            return { ...state }
    }
}

export const subscriptionPlanReducer = (state = intitalState, action) => {
    switch (action.type) {
        case types.SUBSCRIPTION_PLAN:
            return {
                ...state,
                subscriptionPlanProfile: action.payload
            };
        default:
            return { ...state }
    }
}