import { types } from "../HospitalType/types";
import HospitalConstants from "../../Services/HospitalConstants";
import { HospitalApi, HospitalGETAPi } from "../../Services/HospitalApi";
import { showLoaderAction, hideLoaderAction } from "./loaderAction";
import { showToast } from "./toastAction";

export const addAppontmentAction = (data, next) => async (dispatch) => {
  dispatch(showLoaderAction());
  const res = await HospitalGETAPi(
    "post",
    HospitalConstants.END_POINT.ADDAPPOINTMENT,
    data
  );
  if (res.success) {
    if (res.data) {
      dispatch({
        type: types.ADD_APPOINTMENT,
        payload: res.data,
      });
    }
    dispatch(hideLoaderAction());
    dispatch(showToast({ severity: "success", summary: res.message }));
    next();
  } else {
    dispatch(hideLoaderAction());
    dispatch(showToast({ severity: "error", summary: res.message }));
  }
};

export const getAppointmentAction = () => async (dispatch) => {
  dispatch(showLoaderAction());
  const res = await HospitalApi(
    "get",
    HospitalConstants.END_POINT.GETAPPOINTMENT
  );
  if (res.success) {
    if (res.data) {
      dispatch({
        type: types.GET_APPOINTMENT,
        payload: res.data,
      });
    }
    dispatch(hideLoaderAction());
  } else {
    dispatch(hideLoaderAction());
    dispatch(showToast({ severity: "error", summary: res.message }));
  }
};

export const appointmentAddServiceAction = (data, next) => async (dispatch) => {
  dispatch(showLoaderAction());
  const res = await HospitalGETAPi(
    "post",
    HospitalConstants.END_POINT.APPOINTMENTADDSERVICE,
    data
  );
  if (res.success) {
    if (res.data) {
      dispatch({
        type: types.APPOINTMENT_ADD_SERVICE,
        payload: res.data,
      });
    }
    dispatch(hideLoaderAction());
    dispatch(showToast({ severity: "success", summary: res.message }));
    next();
  } else {
    dispatch(hideLoaderAction());
    dispatch(showToast({ severity: "error", summary: res.message }));
  }
};

export const appoitmnetGetServiceAction = (id) => async (dispatch) => {
  dispatch(showLoaderAction());
  let durations = [];
  let url = HospitalConstants.END_POINT.SCHEDULEBYGETSERVICE;
  if (id) {
    url = url + `?appointment_id=${id}`;
  }
  const res = await HospitalApi("get", url);
  if (res.success) {
    if (res.data) {
      dispatch({
        type: types.APPOINTMENT_GET_SERVICE,
        payload: res.data,
      });
    }
    dispatch(hideLoaderAction());
    durations = res.data;
  } else {
    dispatch(hideLoaderAction());
    // dispatch(showToast({ severity: "error", summary: res.message }));
  }

  return durations;
};

export const schedulebylocation = (id) => async (dispatch) => {
  dispatch(showLoaderAction());
  let schedule = [];
  let url = HospitalConstants.END_POINT.GETSCHEDULEBYLOCATION;
  if (id) {
    url = url + `?location_id=${id}`;
  }
  const res = await HospitalGETAPi("get", url);
  if (res.success) {
    if (res.data) {
    }
    dispatch(hideLoaderAction());
    schedule = res.data;
  } else {
    dispatch(hideLoaderAction());
    dispatch(showToast({ severity: "error", summary: res.message }));
  }
  return schedule;
};
export const serviceBySchedule = (id) => async (dispatch) => {
  dispatch(showLoaderAction());
  let service = [];
  let url = HospitalConstants.END_POINT.GETSERVICEBYSCHEDULE;
  if (id) {
    url = url + `?scheduling_id=${id}`;
  }
  const res = await HospitalGETAPi("get", url);
  if (res.success) {
    if (res.data) {
    }
    dispatch(hideLoaderAction());
    service = res.data;
  } else {
    dispatch(hideLoaderAction());
    dispatch(showToast({ severity: "error", summary: res.message }));
  }
  return service;
};

export const appointmentDeletServiceAction = (id, next) => async (dispatch) => {
  dispatch(showLoaderAction());
  const res = await HospitalApi(
    "get",
    `${HospitalConstants.END_POINT.APPOINTMENTDELETESERVICE}/${id}`
  );
  if (res.success) {
    if (res.data) {
      dispatch({
        type: types.APPOINTMENT_DELETE_SERVICE,
        payload: res.data,
      });
    }
    dispatch(hideLoaderAction());
    dispatch(showToast({ severity: "success", summary: res.message }));
    next();
  } else {
    dispatch(hideLoaderAction());
    dispatch(showToast({ severity: "error", summary: res.message }));
  }
};

export const appointmentViewServiceAction = (id) => async (dispatch) => {
  dispatch(showLoaderAction());
  const res = await HospitalApi(
    "get",
    `${HospitalConstants.END_POINT.APPOINTMENTVIEWSERVICE}/${id}`
  );
  if (res.success) {
    if (res.data) {
      dispatch({
        type: types.APPOINTMENT_VIEW_SERVICE,
        payload: res.data,
      });
    }
    dispatch(hideLoaderAction());
  } else {
    dispatch(hideLoaderAction());
    dispatch(showToast({ severity: "error", summary: res.message }));
  }
};

export const appointmentEditServiceAction =
  (id, data, next) => async (dispatch) => {
    dispatch(showLoaderAction());
    const res = await HospitalGETAPi(
      "post",
      `${HospitalConstants.END_POINT.APPOINTMENTEDITSERVICE}/${id}`,
      data
    );
    if (res.success) {
      if (res.data) {
        dispatch({
          type: types.APPOINTMENT_EDIT_SERVICE,
          payload: res.data,
        });
      }
      dispatch(hideLoaderAction());
      dispatch(showToast({ severity: "success", summary: res.message }));
      next();
    } else {
      dispatch(hideLoaderAction());
      dispatch(showToast({ severity: "error", summary: res.message }));
    }
  };

export const addAppointmentBooking = (data, next) => async (dispatch) => {
  dispatch(showLoaderAction());
  const res = await HospitalApi(
    "post",
    HospitalConstants.END_POINT.ADDAPPOINTMENTBOOKING,
    data
  );
  if (res.success) {
    if (res.data) {
      dispatch({
        type: types.ADD_APPOINTMENT_BOOKING,
        payload: res.data,
      });
    }
    dispatch(hideLoaderAction());
    dispatch(showToast({ severity: "success", summary: res.message }));
    next();
  } else {
    dispatch(hideLoaderAction());
    dispatch(showToast({ severity: "error", summary: res.message }));
  }
};

export const selectedAppointmentAction = (data) => async (dispatch) => {
  const res = await HospitalApi(
    "post",
    HospitalConstants.END_POINT.SELECTEDAPPOINTMENT,
    data
  );
  if (res.success) {
    if (res.data) {
      dispatch({
        type: types.SELECTED_APPOINTMENT,
        payload: res.data,
      });
    }
  } else {
    dispatch(hideLoaderAction());
    dispatch(showToast({ severity: "error", summary: res.message }));
  }
};

export const appointmentBookingAction = (params) => async (dispatch) => {
  let url = HospitalConstants.END_POINT.APPOINTMENTBOOKING;
  if (params) {
    let paramsURL = "";
    for (let param in params) {
      paramsURL += paramsURL
        ? `&${param}=${params[param]}`
        : `?${param}=${params[param]}`;
    }
    url = url + paramsURL;
  }

  const res = await HospitalApi("get", url);
  if (res.success) {
    if (res.data) {
      dispatch({
        type: types.APPOINTMENT_BOOKING,
        payload: res.data,
      });
    }
    dispatch(hideLoaderAction());
  } else {
    dispatch(hideLoaderAction());
    dispatch(showToast({ severity: "error", summary: res.message }));
  }
};

export const ViewSingleAppointment = (id) => async (dispatch) => {
  dispatch(showLoaderAction());
  const res = await HospitalApi(
    "get",
    HospitalConstants.END_POINT.VIEWAPPOINTMENT + id
  );
  if (res.success) {
    if (res.data) {
      dispatch({
        type: types.VIEWAPPOINTMENT,
        payload: res.data,
      });
    }
    dispatch(hideLoaderAction());
  } else {
    dispatch(hideLoaderAction());
    dispatch(showToast({ severity: "error", summary: res.message }));
  }
};
export const getAppointmentScheduleAction = () => async (dispatch) => {
  dispatch(showLoaderAction());
  const res = await HospitalApi(
    "get",
    HospitalConstants.END_POINT.GETAPPOINTMENTSCHEDULE
  );
  if (res.success) {
    if (res.data) {
      dispatch({
        type: types.GET_APPOINTMENT_SCHEDULE,
        payload: res.data,
      });
    }
    dispatch(hideLoaderAction());
  } else {
    dispatch(hideLoaderAction());
    dispatch(showToast({ severity: "error", summary: res.message }));
  }
};

export const getAppointmentDataAction = () => async (dispatch) => {
  dispatch(showLoaderAction());
  const res = await HospitalGETAPi(
    "get",
    HospitalConstants.END_POINT.GETAPPOINTMENTDATA
  );
  if (res.success) {
    if (res.data) {
      dispatch({
        type: types.GET_APPOINTMENT_DATA,
        payload: res.data.reverse(),
      });
    }
    dispatch(hideLoaderAction());
  } else {
    dispatch(hideLoaderAction());
    dispatch(showToast({ severity: "error", summary: res.message }));
  }
};

export const viewAppointmentDataAction = (id) => async (dispatch) => {
  dispatch(showLoaderAction());
  const res = await HospitalGETAPi(
    "get",
    `${HospitalConstants.END_POINT.VIEWAPPOINTMENTDATA}/${id}`
  );
  if (res.success) {
    if (res.data) {
      dispatch({
        type: types.VIEW_APPOINTMENT_DATA,
        payload: res.data,
      });
    }
    dispatch(hideLoaderAction());
  } else {
    dispatch(hideLoaderAction());
    dispatch(showToast({ severity: "error", summary: res.message }));
  }
};

export const editAppointmentDataAction =
  (id, data, next) => async (dispatch) => {
    dispatch(showLoaderAction());
    const res = await HospitalGETAPi(
      "post",
      `${HospitalConstants.END_POINT.EDITAPPOINTMENTDATA}/${id}`,
      data
    );
    if (res.success) {
      if (res.data) {
        dispatch({
          type: types.EDIT_APPOINTMENT_DATA,
          payload: res.data,
        });
      }
      dispatch(hideLoaderAction());
      dispatch(showToast({ severity: "success", summary: res.message }));
      next();
    } else {
      dispatch(hideLoaderAction());
      dispatch(showToast({ severity: "error", summary: res.message }));
    }
  };

export const appointmentCalendarAction = (data) => async (dispatch) => {
  dispatch(showLoaderAction());
  const res = await HospitalApi(
    "post",
    HospitalConstants.END_POINT.APPOINTMENT,
    data
  );
  if (res.success) {
    if (res.data) {
      dispatch({
        type: types.APPOINTMENT_CALENDER,
        payload: res.data,
      });
    }
    dispatch(hideLoaderAction());
  } else {
    dispatch(hideLoaderAction());
    dispatch(showToast({ severity: "error", summary: res.message }));
  }
};

export const appointmentCalenderViewDate = (date, next) => async (dispatch) => {
  dispatch(showLoaderAction());
  const res = await HospitalApi(
    "get",
    `${HospitalConstants.END_POINT.GETDATEEVENT}/${date}`
  );
  if (res.success) {
    if (res.data) {
      dispatch({
        type: types.GET_DATE_EVENT,
        payload: res.data,
      });
    }
    dispatch(hideLoaderAction());
    next();
  } else {
    dispatch(hideLoaderAction());
    dispatch(showToast({ severity: "error", summary: res.message }));
  }
};

export const CalendarShowData = () => async (dispatch) => {
  dispatch(showLoaderAction());
  const res = await HospitalApi("get", HospitalConstants.END_POINT.scheduling);
  if (res.success) {
    if (res.data) {
      dispatch({
        type: types.SCHEDULING,
        payload: res.data,
      });
    }
    dispatch(hideLoaderAction());
  } else {
    dispatch(hideLoaderAction());
    dispatch(showToast({ severity: "error", summary: res.message }));
  }
};
export const ClientProfileData = () => async (dispatch) => {
  dispatch(showLoaderAction());
  const res = await HospitalApi(
    "get",
    HospitalConstants.END_POINT.ClientProfileData
  );
  if (res.success) {
    if (res.data) {
      dispatch({
        type: types.CLIENT_PROFILE_DATA,
        payload: res.data,
      });
    }
    dispatch(hideLoaderAction());
  } else {
    dispatch(hideLoaderAction());
    dispatch(showToast({ severity: "error", summary: res.message }));
  }
};
export const ClientEditProfileData =
  (data, setShowEdit) => async (dispatch) => {
    dispatch(showLoaderAction());
    const res = await HospitalApi(
      "post",
      HospitalConstants.END_POINT.ClientEditProfile,
      data
    );
    if (res.success) {
      dispatch(ClientProfileData());
      if (res.data) {
        dispatch({
          type: types.CLIENT_PROFILE_EDIT_DATA,
          payload: res.data,
        });
      }
      setShowEdit(false);
      dispatch(hideLoaderAction());
    } else {
      dispatch(hideLoaderAction());
      dispatch(showToast({ severity: "error", summary: res.message }));
    }
  };

export const CompanyShowData = () => async (dispatch) => {
  dispatch(showLoaderAction());
  const res = await HospitalApi(
    "get",
    HospitalConstants.END_POINT.companyDetails
  );
  if (res.success) {
    if (res.data) {
      dispatch({
        type: types.COMPANY_DETAIL,
        payload: res.data,
      });
    }
    dispatch(hideLoaderAction());
  } else {
    dispatch(hideLoaderAction());
    dispatch(showToast({ severity: "error", summary: res.message }));
  }
};

export const CompanyPostData = (data, setShowEdit) => async (dispatch) => {
  dispatch(showLoaderAction());
  const res = await HospitalApi(
    "post",
    HospitalConstants.END_POINT.companyPostData,
    data
  );
  if (res.success) {
    if (res.data) {
      dispatch({
        type: types.COMPANY_POST_DATA,
        payload: res.data,
      });
    }
    setShowEdit(false);
    dispatch(CompanyShowData());
    dispatch(hideLoaderAction());
    dispatch(showToast({ severity: "success", summary: res.message }));
  } else {
    dispatch(hideLoaderAction());
    dispatch(showToast({ severity: "error", summary: res.message }));
  }
};

export const LocationData = () => async (dispatch) => {
  dispatch(showLoaderAction());
  const res = await HospitalApi(
    "get",
    HospitalConstants.END_POINT.companyLocation
  );
  if (res.success) {
    if (res.data) {
      dispatch({
        type: types.COMPANY_LOCATION,
        payload: res.data,
      });
    }
    dispatch(hideLoaderAction());
  } else {
    dispatch(hideLoaderAction());
    dispatch(showToast({ severity: "error", summary: res.message }));
  }
};
export const LeaveData = () => async (dispatch) => {
  dispatch(showLoaderAction());
  const res = await HospitalApi(
    "get",
    HospitalConstants.END_POINT.companyLeave
  );
  if (res.success) {
    if (res.data) {
      dispatch({
        type: types.COMPANY_LEAVE,
        payload: res.data,
      });
    }
    dispatch(hideLoaderAction());
  } else {
    dispatch(hideLoaderAction());
    dispatch(showToast({ severity: "error", summary: res.message }));
  }
};

export const PostLocation =
  (data, setShowLAdd, setData) => async (dispatch) => {
    dispatch(showLoaderAction());
    const res = await HospitalApi(
      "post",
      HospitalConstants.END_POINT.POSTLOCATION,
      data
    );
    if (res.success) {
      LocationData();
      if (res.data) {
        dispatch({
          type: types.Location_POST_DATA,
          payload: res.data,
        });
      }
      setData({ id: "", name: "", address: "" });
      dispatch(LocationData());
      setShowLAdd(false);
      dispatch(hideLoaderAction());
    } else {
      dispatch(hideLoaderAction());
      dispatch(showToast({ severity: "error", summary: res.message }));
    }
  };
export const PostLeave = (data, setAddLeave, setData) => async (dispatch) => {
  dispatch(showLoaderAction());
  const res = await HospitalApi(
    "post",
    HospitalConstants.END_POINT.POSTLEAVE,
    data
  );
  if (res.success) {
    if (res.data) {
      dispatch({
        type: types.LEAVE_POST_DATA,
        payload: res.data,
      });
    }
    setData({
      id: "",
      employee_id: "",
      category: "",
      leave_type: "",
      annual_allotment: "",
    });
    dispatch(LeaveData());
    setAddLeave(false);
    dispatch(hideLoaderAction());
  } else {
    dispatch(hideLoaderAction());
    dispatch(showToast({ severity: "error", summary: res.message }));
  }
};
export const DeletCompanyAction = (data, setAddLeave) => async (dispatch) => {
  dispatch(showLoaderAction());
  const res = await HospitalApi(
    "post",
    HospitalConstants.END_POINT.DELETE,
    data
  );
  if (res.success) {
    if (res.data) {
      dispatch({
        type: types.DELETE_COMPANY_ACTION_DATA,
        payload: res.data,
      });
    }
    dispatch(LeaveData());
    dispatch(LocationData());
    LocationData();
    dispatch(hideLoaderAction());
  } else {
    dispatch(hideLoaderAction());
    dispatch(showToast({ severity: "error", summary: res.message }));
  }
};
export const ClientResetPassword =
  (data, setShowResetP) => async (dispatch) => {
    dispatch(showLoaderAction());
    const res = await HospitalApi(
      "post",
      HospitalConstants.END_POINT.CLIENTPASSWORDRESET,
      data
    );
    if (res.success) {
      if (res.data) {
        dispatch({
          type: types.CLIENT_PASSWORD_RESET,
          payload: res.data,
        });
      }
      setShowResetP(false);
      dispatch(hideLoaderAction());
      // dispatch(showToast({ severity: "Success", summary: res.message }));
    } else {
      dispatch(hideLoaderAction());
      dispatch(showToast({ severity: "error", summary: res.message }));
    }
  };
