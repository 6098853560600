import { types } from "../HospitalType/types";
import HospitalConstants from "../../Services/HospitalConstants";
import { HospitalApi, HospitalDeleteApi } from "../../Services/HospitalApi";
import { showToast } from "./toastAction";
import { showLoaderAction, hideLoaderAction } from "./loaderAction";

export const getEmployeesAction = (data) => async (dispatch) => {
  dispatch(showLoaderAction());
  const res = await HospitalApi(
    "post",
    HospitalConstants.END_POINT.GETEMPLOYEE,
    data
  );
  if (res.data) {
    dispatch({
      type: types.GET_EMPLOYEE,
      payload: res.data.reverse(),
    });
  }
  dispatch(hideLoaderAction());
};

export const addEmployeeAction = (data, next) => async (dispatch) => {
  dispatch(showLoaderAction());
  const res = await HospitalApi(
    "post",
    HospitalConstants.END_POINT.ADDEMPLOYEE,
    data
  );
  if (res.success) {
    if (res.data) {
      dispatch({
        type: types.ADD_EMPLOYEE,
        payload: res.data,
      });
    }
    dispatch(hideLoaderAction());
    next();
    dispatch(showToast({ severity: "success", summary: res.message }));
  } else {
    dispatch(hideLoaderAction());
    dispatch(showToast({ severity: "error", summary: res.message }));
  }
};

export const editEmployeeAction = (data, id, next) => async (dispatch) => {
  dispatch(showLoaderAction());
  const res = await HospitalApi(
    "post",
    `${HospitalConstants.END_POINT.EDITEMPLOYEE}/${id}`,
    data
  );
  if (res.statusCode === true) {
    if (res.data) {
      dispatch({
        type: types.EDIT_EMPLOYEE,
        payload: res.data,
      });
    }
    dispatch(hideLoaderAction());
    next();
    dispatch(showToast({ severity: "success", summary: res.message }));
  } else {
    dispatch(hideLoaderAction());
    dispatch(showToast({ severity: "error", summary: res.message }));
  }
};

export const deleteEmployeeAction = (id, next) => async (dispatch) => {
  dispatch(showLoaderAction());
  const res = await HospitalDeleteApi(
    "get",
    `${HospitalConstants.END_POINT.DELETEEMPLOYEE}/${id}`
  );
  if (res.statusCode === true) {
    if (res.data) {
      dispatch({
        type: types.DELETE_EMPLOYEE,
        payload: res.data,
      });
    }
    dispatch(hideLoaderAction);
    next();
    dispatch(showToast({ severity: "success", summary: res.message }));
  } else {
    dispatch(hideLoaderAction);
    dispatch(showToast({ severity: "error", summary: res.message }));
  }
};

export const getPermissionAction = () => async (dispatch) => {
  dispatch(showLoaderAction());
  const res = await HospitalApi(
    "get",
    HospitalConstants.END_POINT.GETPERMISSION
  );
  if (res.data) {
    dispatch({
      type: types.GET_PERMISSION,
      payload: res.data,
    });
    dispatch(hideLoaderAction());
  } else {
    dispatch(hideLoaderAction());
  }
};

export const viewEmployeeAction = (id) => async (dispatch) => {
  dispatch(showLoaderAction());
  const res = await HospitalApi(
    "get",
    `${HospitalConstants.END_POINT.VIEWEMPLOYEE}/${id}`
  );
  if (res.data) {
    dispatch({
      type: types.VIEW_EMPLOYEE,
      payload: res.data,
    });
  }
  dispatch(hideLoaderAction);
};

export const getEmployeeHourlyAction = (data) => async (dispatch) => {
  dispatch(showLoaderAction());
  const res = await HospitalApi(
    "post",
    HospitalConstants.END_POINT.GETHOURLYRATE,
    data
  );
  if (res.success) {
    if (res.data) {
      dispatch({
        type: types.GET_HOURLY_RATE,
        payload: res.data,
      });
    }
    dispatch(hideLoaderAction());
  } else {
    dispatch(hideLoaderAction());
  }
};

export const deleteEmployeeHourlyAction = (id, next) => async (dispatch) => {
  dispatch(showLoaderAction());
  const res = await HospitalDeleteApi(
    "get",
    `${HospitalConstants.END_POINT.DELETEHOURLYRATE}/${id}`
  );
  if (res.success) {
    if (res.data) {
      dispatch({
        type: types.DELETE_HOURLY_RATE,
        payload: res.data,
      });
    }
    dispatch(hideLoaderAction());
    next();
    dispatch(showToast({ severity: "success", summary: res.message }));
  } else {
    dispatch(hideLoaderAction());
    next();
    dispatch(showToast({ severity: "error", summary: res.message }));
  }
};

export const addEmployeeHourlyAction = (data, next) => async (dispatch) => {
  dispatch(showLoaderAction());
  const res = await HospitalApi(
    "post",
    HospitalConstants.END_POINT.ADDHOURLYRATE,
    data
  );
  if (res.success) {
    if (res.data) {
      dispatch({
        type: types.ADD_HOURLY_RATE,
        payload: res.data,
      });
    }
    dispatch(hideLoaderAction());
    next();
    dispatch(showToast({ severity: "success", summary: res.message }));
  } else {
    dispatch(hideLoaderAction());
    next();
    dispatch(showToast({ severity: "error", summary: res.message }));
  }
};

export const getSelectedEmployeeAction = (data) => async (dispatch) => {
  dispatch(hideLoaderAction());
  const res = await HospitalApi(
    "post",
    HospitalConstants.END_POINT.GETSELECTEDEMPLOYEE,
    data
  );
  if (res.success) {
    if (res.data) {
      dispatch({
        type: types.GET_SELECTED_EMPLOYEE,
        payload: res.data,
      });
    }
    dispatch(hideLoaderAction());
  } else {
    dispatch(hideLoaderAction());
  }
};

export const viewHourlyEmployeeAction = (id) => async (dispatch) => {
  dispatch(showLoaderAction());
  const res = await HospitalApi(
    "get",
    `${HospitalConstants.END_POINT.VIEWHORLYDATA}/${id}`
  );
  if (res.success) {
    if (res.data) {
      dispatch({
        type: types.VIEW_HOURLY_RATE,
        payload: res.data,
      });
    }
    dispatch(hideLoaderAction());
  } else {
    dispatch(hideLoaderAction());
  }
};

export const editEmployeeHourlyAction =
  (id, data, next) => async (dispatch) => {
    dispatch(showLoaderAction());
    const res = await HospitalApi(
      "post",
      `${HospitalConstants.END_POINT.EDITHOURLYDATA}/${id}`,
      data
    );
    if (res.success) {
      if (res.data) {
        dispatch({
          type: types.EDIT_HOURLY_RATE,
          payload: res.data,
        });
      }
      dispatch(hideLoaderAction());
      dispatch(showToast({ severity: "success", summary: res.message }));
      next();
    } else {
      dispatch(hideLoaderAction());
      dispatch(showToast({ severity: "error", summary: res.message }));
    }
  };

export const addEmployeeSchedulingAction = (data, next) => async (dispatch) => {
  dispatch(showLoaderAction());
  const res = await HospitalApi(
    "post",
    HospitalConstants.END_POINT.ADDEMPLOYEESCHEDULING,
    data
  );
  if (res.success) {
    if (res.data) {
      dispatch({
        type: types.ADD_EMPLOYEE_SCHEDULING,
        payload: res.data,
      });
    }
    dispatch(hideLoaderAction());
    dispatch(showToast({ severity: "success", summary: res.message }));
    next();
  } else {
    dispatch(hideLoaderAction());
    dispatch(showToast({ severity: "error", summary: res.message }));
  }
};
export const addSpecialEmployeeSchedulingAction =
  (data, navigate) => async (dispatch) => {
    dispatch(showLoaderAction());
    const res = await HospitalApi(
      "post",
      HospitalConstants.END_POINT.ADDSPECIALEMPLOYEESCHEDULING,
      data
    );
    if (res.success) {
      if (res.data) {
        dispatch({
          type: types.ADD_SPECIAL_EMPLOYEE_SCHEDULING,
          payload: res.data,
        });
      }
      navigate("/account-owner/employees-scheduling");
      dispatch(hideLoaderAction());
      dispatch(showToast({ severity: "success", summary: res.message }));
    } else {
      dispatch(hideLoaderAction());
      dispatch(showToast({ severity: "error", summary: res.message }));
    }
  };

export const getLeaveType = () => async (dispatch) => {
  dispatch(showLoaderAction());
  const res = await HospitalApi(
    "get",
    HospitalConstants.END_POINT.GETLEAVETYPE
  );
  if (res.data) {
    dispatch({
      type: types.GET_LEAVE_TYPE,
      payload: res.data,
    });
    dispatch(hideLoaderAction());
  } else {
    dispatch(hideLoaderAction());
  }
};
export const getLeaveCategory = (id) => async (dispatch) => {
  dispatch(showLoaderAction());
  const res = await HospitalApi(
    "get",
    `${HospitalConstants.END_POINT.GETLEAVECATEGORY}/${id}`
  );
  if (res.data) {
    dispatch({
      type: types.GET_LEAVE_CATEGORY,
      payload: res.data,
    });
    dispatch(hideLoaderAction());
  } else {
    dispatch(hideLoaderAction());
  }
};

export const getLeaveList = (id) => async (dispatch) => {
  dispatch(showLoaderAction());
  const res = await HospitalApi(
    "get",
    HospitalConstants.END_POINT.GETLEAVELIST
  );
  if (res.data) {
    dispatch({
      type: types.GET_LEAVE_LIST,
      payload: res.data,
    });
    dispatch(hideLoaderAction());
  } else {
    dispatch(hideLoaderAction());
  }
};

export const addSchedulingLeave = (data, navigate) => async (dispatch) => {
  dispatch(showLoaderAction());
  const res = await HospitalApi(
    "post",
    HospitalConstants.END_POINT.ADDLEAVE,
    data
  );
  if (res.success) {
    if (res.data) {
      dispatch({
        type: types.POST_SCHEDULING_LEAVE,
        payload: res.data,
      });
    }
    navigate("/account-owner/employees-scheduling");
    dispatch(hideLoaderAction());
    dispatch(showToast({ severity: "success", summary: res.message }));
  } else {
    dispatch(hideLoaderAction());
  }
};

export const getEmployeeSchedulingAction = (data) => async (dispatch) => {
  dispatch(showLoaderAction());
  const res = await HospitalApi(
    "post",
    HospitalConstants.END_POINT.GETEMPLOYEESCHEDULING,
    data
  );
  if (res.success) {
    if (res.data) {
      dispatch({
        type: types.GET_EMPLOYEE_SCHEDULING,
        payload: res.data,
      });
    }
    dispatch(hideLoaderAction());
  } else {
    dispatch(hideLoaderAction());
  }
};

export const deleteEmployeeScheduling = (id, next) => async (dispatch) => {
  dispatch(showLoaderAction());
  const res = await HospitalApi(
    "get",
    `${HospitalConstants.END_POINT.DELETESCHEDULING}/${id}`
  );
  if (res.success) {
    if (res.data) {
      dispatch({
        type: types.DELETE_EMPLOYEE_SCHEDULING,
        payload: res.data,
      });
    }
    dispatch(hideLoaderAction());
    next();
    dispatch(showToast({ severity: "success", summary: res.message }));
  } else {
    dispatch(hideLoaderAction());
    dispatch(showToast({ severity: "error", summary: res.message }));
  }
};
export const getScheduleListing = (id) => async (dispatch) => {
  dispatch(showLoaderAction());
  const res = await HospitalApi(
    "get",
    `${HospitalConstants.END_POINT.GETSCHEDULINGLISTING}/${id}`
  );
  if (res.success) {
    if (res.data) {
      dispatch({
        type: types.GET_EMPLOYEE_SCHEDULING_LISTING,
        payload: res.data,
      });
    }
    dispatch(hideLoaderAction());
    // dispatch(showToast({ severity: "success", summary: res.message }));
  } else {
    dispatch(hideLoaderAction());
    dispatch(showToast({ severity: "error", summary: res.message }));
  }
};

export const viewEmployeeSchedulingAction = (id) => async (dispatch) => {
  dispatch(showLoaderAction());
  const res = await HospitalApi(
    "get",
    `${HospitalConstants.END_POINT.VIEWSCHEDULING}/${id}`
  );
  if (res.success) {
    if (res.data) {
      dispatch({
        type: types.VIEW_EMPLOYEE_SCHEDULING,
        payload: res.data,
      });
    }
    dispatch(hideLoaderAction());
  } else {
    dispatch(hideLoaderAction());
    dispatch(showToast({ severity: "error", summary: res.message }));
  }
};

export const editEmployeeSchedulingAction =
  (id, data, next) => async (dispatch) => {
    dispatch(showLoaderAction());
    const res = await HospitalApi(
      "post",
      `${HospitalConstants.END_POINT.EDITSCHEDULING}/${id}`,
      data
    );
    if (res.success) {
      if (res.data) {
        dispatch({
          type: types.EDIT_EMPLOYEE_SCHEDULING,
          payload: res.data,
        });
      }
      dispatch(hideLoaderAction());
      next();
      dispatch(showToast({ severity: "success", summary: res.message }));
    } else {
      dispatch(hideLoaderAction());
      dispatch(showToast({ severity: "error", summary: res.message }));
    }
  };
