import { types } from "../HospitalType/types";

const intitalState = {
    getEmployeeCertificationProfile: {},
    addEmployeeCertificationProfile: [],
    viewEmployeeCerticationProfile: [],
    editEmployeeCerticationProfile: [],
    deleteEmployeeCertificationProfile: {},
    getClientCertificationProfile: {},
    addClientCertificationProfile: {},
    deleteClientCertificationProfile: {},
    viewClientCertificationProfile: [],
    editClientCertificationProfile: {},
    getEmployeeCerticationListProfile: {},
    addEmployeeCertificationListProfile: [],
    viewEmployeeCerticationListProfile: [],
    deleteEmployeeCertificationListProfile: [],
    editEmployeeCertificationListProfile: [],
    certificationClientGetProfile: {},
    certificationClientDeleteProfile: {},
    certificationClientViewProfile: {},
    certificationClientEditProfile: {}
}

export const getEmployeeCertificationReducer = (state = intitalState, action) => {
    switch (action.type) {
        case types.GET_EMPLOYEE_CERTIFICATION_LIST:
            return {
                ...state,
                getEmployeeCertificationProfile: action.payload
            };
        default:
            {
                return { ...state }
            }
    }
}

export const addEmployeeCertificationReducer = (state = intitalState, action) => {
    switch (action.type) {
        case types.ADD_EMPLOYEE_CERTIFICATION_LIST:
            return {
                ...state,
                addEmployeeCertificationProfile: action.payload
            }
        default:
            {
                return { ...state }
            }
    }
}

export const viewEmployeeCerticationReducer = (state = intitalState, action) => {
    switch (action.type) {
        case types.VIEW_EMPLOYEE_CERTIFICATION_LIST:
            return {
                ...state,
                viewEmployeeCerticationProfile: action.payload
            }
        default: {
            return { ...state }
        }
    }
}

export const editEmployeeCertificationReducer = (state = intitalState, action) => {
    switch (action.type) {
        case types.EDIT_EMPLOYEE_CERTIFICATION_LIST:
            return {
                ...state,
                editEmployeeCerticationProfile: action.payload
            }
        default: {
            return { ...state }
        }
    }
}

export const deleteEmployeeCertificationReducer = (state = intitalState, action) => {
    switch (action.type) {
        case types.DELETE_EMPLOYEE_CERTIFICATION_LIST:
            return {
                ...state,
                deleteEmployeeCertificationProfile: action.payload
            }
        default:
            {
                return { ...state }
            }
    }
}

export const getClientCertificationReducer = (state = intitalState, action) => {
    switch (action.type) {
        case types.GET_CLIENT_CERTIFICATION_LIST:
            return {
                ...state,
                getClientCertificationProfile: action.payload
            }
        default: {
            return { ...state }
        }
    }
}


export const addClientCertificationReducer = (state = intitalState, action) => {
    switch (action.type) {
        case types.ADD_CLIENT_CERTIFICATION_LIST:
            return {
                ...state,
                addClientCertificationProfile: action.payload
            }
        default: {
            return { ...state }
        }
    }
}

export const deleteClientCertificationReducer = (state = intitalState, action) => {
    switch (action.type) {
        case types.DELETE_CLIENT_CERTIFICATION_LIST:
            return {
                ...state,
                deleteClientCertificationProfile: action.payload
            }
        default: {
            return { ...state }
        }
    }
}

export const viewClientCerticationReducer = (state = intitalState, action) => {
    switch (action.type) {
        case types.VIEW_CLIENT_CERTIFICATION_LISt:
            return {
                ...state,
                viewClientCertificationProfile: action.payload
            }
        default: {
            return {
                ...state
            }
        }
    }
}

export const editClientCertificationReducer = (state = intitalState, action) => {
    switch (action.type) {
        case types.EDIT_CLIENT_CERTIFICATION_LIST:
            return {
                ...state,
                editClientCertificationProfile: action.payload
            }
        default: {
            return {
                ...state
            }
        }
    }
}

export const getEmployeeCerticationListReducer = (state = intitalState, action) => {
    switch (action.type) {
        case types.GET_EMPLOYEE_CERTICATION:
            return {
                ...state,
                getEmployeeCerticationListProfile: action.payload
            }
        default: {
            return {
                ...state
            }
        }
    }
}

export const addEmployeeCertificationListReducer = (state = intitalState, action) => {
    switch (action.type) {
        case types.ADD_EMPLOYEE_CERTIFICATION:
            return {
                ...state,
                addEmployeeCertificationListProfile: action.payload
            }
        default: {
            return {
                ...state
            }
        }
    }
}

export const viewEmployeeCertificationListReducer = (state = intitalState, action) => {
    switch (action.type) {
        case types.VIEW_EMPLOYEE_CERTIFICATION:
            return {
                ...state,
                viewEmployeeCerticationListProfile: action.payload
            }
        default: {
            return {
                ...state
            }
        }
    }
}

export const deleteEmployeeCertificationListReducer = (state = intitalState, action) => {
    switch (action.type) {
        case types.DELETE_EMPLOYEE_CERTIFICATION:
            return {
                ...state,
                deleteEmployeeCertificationListProfile: action.payload
            }
        default: {
            return {
                ...state
            }
        }
    }
}

export const editEmployeeCertificationListReducer = (state = intitalState, action) => {
    switch (action.type) {
        case types.EDIT_EMPLOYEE_CERTIFICATION:
            return {
                ...state,
                editEmployeeCertificationListProfile: action.payload
            }
        default: {
            return {
                ...state
            }
        }
    }
}

export const certificationClientGetReducer = (state = intitalState, action) => {
    switch (action.type) {
        case types.CERTIFICATION_CLIENT_GET:
            return {
                ...state,
                certificationClientGetProfile: action.payload
            }
        default: {
            return {
                ...state
            }
        }
    }
}
export const certificationClientDeleteReducer = (state = intitalState, action) => {
    switch (action.type) {
        case types.CERTIFICATION_CLIENT_DELETE:
            return {
                ...state,
                certificationClientDeleteProfile: action.payload
            }
        default: {
            return {
                ...state
            }
        }
    }
}

export const certificationClientViewReducer = (state = intitalState, action) => {
    switch (action.type) {
        case types.CERTIFICATION_CLIENT_VIEW:
            return {
                ...state,
                certificationClientViewProfile: action.payload
            }
        default: {
            return {
                ...state
            }
        }
    }
}
export const certificationClientEditReducer = (state = intitalState, action) => {
    switch (action.type) {
        case types.CERTIFICATION_CLIENT_Edit:
            return {
                ...state,
                certificationClientEditProfile: action.payload
            }
        default: {
            return {
                ...state
            }
        }
    }
}