import React from "react";
import AddButton from "../../../components/Buttons/AddButton";
import Table from "../../../components/TableList/Table";
import ContentArea from "../../../shared/ContentArea";
import SearchIcon from "../../../assets/icons/search.png"
import FilterIcon from "../../../assets/icons/filter.png"
const EmployeePayReport = () => {
    const TableData = [
        {
            Srno: "1",
            Date: "Dec 4, 2019 21:42",
            Employee: "5262267",
            Name: "Ann",
            Hours: "267 hr",
            Hourly: "$123",
            pay: "$56"

        },
        {
            Srno: "2",
            Date: "Dec 4, 2019 21:42",
            Employee: "5262267",
            Name: "Ann",
            Hours: "267 hr",
            Hourly: "$123",
            pay: "$56"
        },
        {
            Srno: "3",
            Date: "Dec 4, 2019 21:42",
            Employee: "5262267",
            Name: "Ann",
            Hours: "267 hr",
            Hourly: "$123",
            pay: "$56"
        },
        {
            Srno: "4",
            Date: "Dec 4, 2019 21:42",
            Employee: "5262267",
            Name: "Ann",
            Hours: "267 hr",
            Hourly: "$123",
            pay: "$56"
        },
        {
            Srno: "5",
            Date: "Dec 4, 2019 21:42",
            Employee: "5262267",
            Name: "Ann",
            Hours: "267 hr",
            Hourly: "$123",
            pay: "$56"
        },
        {
            Srno: "6",
            Date: "Dec 4, 2019 21:42",
            Employee: "5262267",
            Name: "Ann",
            Hours: "267 hr",
            Hourly: "$123",
            pay: "$56"
        },
        {
            Srno: "7",
            Date: "Dec 4, 2019 21:42",
            Employee: "5262267",
            Name: "Ann",
            Hours: "267 hr",
            Hourly: "$123",
            pay: "$56"
        },
        {
            Srno: "8",
            Date: "Dec 4, 2019 21:42",
            Employee: "5262267",
            Name: "Ann",
            Hours: "267 hr",
            Hourly: "$123",
            pay: "$56"
        },
        {
            Srno: "9",
            Date: "Dec 4, 2019 21:42",
            Employee: "5262267",
            Name: "Ann",
            Hours: "267 hr",
            Hourly: "$123",
            pay: "$56"
        },
        {
            Srno: "10",
            Date: "Dec 4, 2019 21:42",
            Employee: "5262267",
            Name: "Ann",
            Hours: "267 hr",
            Hourly: "$123",
            pay: "$56"
        },

    ]

    const TableHeading = [
        { field: "Srno", header: "Sr. No.", },
        { field: "Date", header: "Date", },
        { field: "Employee", header: "Employee Id", },
        { field: "Name", header: "Employee Name", },
        { field: "Hours", header: "Working Hours", },
        { field: "Hourly", header: "Hourly Rate" },
        { field: "pay", header: "Total pay", },

    ];
    return (
        <>
            <ContentArea>
                <div className="manage_employees_container p-3 ">

                    <div className="grid mb-5 mr-0 ml-0 align-items-center bg-main-light border-round-lg shadow-1 flex justify-content-around">
                        <div className="md:col-5 ">
                            <h1 className="text-main  " style={{ fontSize: "24px" }}>
                                Employee Pay report
                            </h1>
                        </div>
                        <div className="md:col-3">
                            <div className="search-box w-full">
                                <img
                                    src={SearchIcon}
                                    alt="Search Icon"
                                    width="15px"
                                    height="15px"
                                />
                                <input type="search" placeholder="Search" />
                            </div>
                        </div>
                        <div className="md:col-1 relative bg-main px-3 border-round-md w-max ">

                            <div className="border-round-md text-white text-center text-base relative karla">
                                Filter &nbsp;
                                <img src={FilterIcon} alt="" width={15} /></div>


                        </div>
                        <div className="md:col-2 w-max ">
                            <AddButton />
                        </div>
                    </div>

                    <div className="grid mt-2 mb-5">
                        <div className="md:col-12">

                            <Table tableHeading={TableHeading}
                                tableData={TableData}
                            />
                        </div>

                    </div>
                </div>
            </ContentArea>
        </>
    )
}

export default EmployeePayReport;