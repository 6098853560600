import React, { useState } from "react";
import { Chart } from "primereact/chart";
import BarChartData from "./barChartData";
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';


export default function BarChart(props) {

  const [selectedMonthly, setSelectedmonthly] = useState(null);
  const [date, setDate] = useState(null);
  const Monthly = [
    { name: 'Monthly' },
    { name: 'Weekly' },

  ];
  const { basicData, basicOptions } = BarChartData();


  return (
    <div>
      <div className="p-3 border-solid border-1 border-round-md surface-overlay border-500" style={{ height: "270px" }}>
        <p className="m-0 text-main mb-3 text-lg font-bold flex align-items-center justify-content-between Karla" style={{ fontSize: "22px" }}> {props.title}

          <span >
          <Calendar value={date} onChange={(e) => setDate(e.value)} view="year" dateFormat="yy" inputClassName="border-round-md" placeholder="Select year" className="dashboard-calender "/>
          </span>
        </p>
        <Chart type="bar" data={basicData} options={basicOptions}  height="172px" />

      </div>

    </div>
  );
}
