import { types } from "../HospitalType/types";
export const hideLoaderAction = () => async (dispatch) => {
    dispatch({
        type: types.HIDE_LOADER,
    })
};
export const showLoaderAction = () => async (dispatch) => {
    dispatch({
        type: types.SHOW_LOADER,
    })
};
