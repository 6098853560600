import axios from "axios";
import AdminConstants from "./AdminConstants";
import { isAuthenticated } from "./authService";

const successStatus = [200, 201, 204];
export const include = (arr, con) => arr && arr.includes(con)

export const AdminApi = (method, urlEndPoint, data = null) =>

new Promise((myResolve) => {
     let headers = {
         "Content-Type": "application/json",
     };
     if (isAuthenticated()) {
         headers = {
             ...headers,
             Authorization: `Bearer ${isAuthenticated()}`,
         };
     }
     axios({
         method,
         url: AdminConstants.BASE_URL + urlEndPoint,
         data,
         headers,
     })
         .then((response) => {
             myResolve({
                 message: response.data.message,
                 data: response.data.data,
                 success: response.data.success,
                 statusCode: include(successStatus, response.status),
             });
         })
         .catch((err) => {
             if (err?.response?.data?.message) {
                 myResolve({
                     message: err?.response?.data?.message,
                     statusCode: false,
                 });
             }
             myResolve({
                 message: err?.response?.data,
                 statusCode: false,
             });
         });
 });