import {
  HospitalApi,
  HospitalGETAPi,
  HospitalDeleteApi,
} from "../../Services/HospitalApi";
import HospitalConstants from "../../Services/HospitalConstants";
import { types } from "../HospitalType/types";
import { showLoaderAction, hideLoaderAction } from "./loaderAction";
import { showToast } from "./toastAction";
export const getServiceProviderAction = (data) => async (dispatch) => {
  dispatch(showLoaderAction());
  const res = await HospitalApi(
    "post",
    HospitalConstants.END_POINT.GETSERVICEPROVIDER,
    data
  );
  if (res.statusCode) {
    if (res.data) {
      dispatch({
        type: types.GET_SERVICE_PROVIDER,
        payload: res.data.reverse(),
      });
    }
    dispatch(hideLoaderAction());
  } else {
    dispatch(hideLoaderAction());
  }
};

export const getfilterServiceProviderAction = (data) => async (dispatch) => {
  dispatch(showLoaderAction());
  const formData = new FormData();
  formData.append("service_type", data?.service_type);
  const res = await HospitalApi(
    "post",
    HospitalConstants.END_POINT.GETSERVICEPROVIDER,
    formData
  );
  if (res.statusCode) {
    if (res.data) {
      dispatch({
        type: types.GET_FILTER_SERVICE_PROVIDER,
        payload: res.data.reverse(),
      });
    }
    dispatch(hideLoaderAction());
  } else {
    dispatch(hideLoaderAction());
  }
};

export const getServiceAction = () => async (dispatch) => {
  dispatch(showLoaderAction());
  const res = await HospitalGETAPi(
    "get",
    HospitalConstants.END_POINT.GETSERVICE
  );
  if (res.statusCode) {
    if (res.data) {
      dispatch({
        type: types.GET_SERVICE,
        payload: res.data,
      });
    }
  }
  dispatch(hideLoaderAction());
};

export const deleteServiceProviderAction = (id, next) => async (dispatch) => {
  dispatch(showLoaderAction());
  const res = await HospitalDeleteApi(
    "get",
    `${HospitalConstants.END_POINT.DELETESERVICE}/${id}`
  );
  if (res.success === true) {
    if (res.data) {
      dispatch({
        type: types.DELETE_SERVICE_PROVIDER,
        payload: res.data,
      });
    }
    next();
    dispatch(hideLoaderAction());
    dispatch(showToast({ severity: "success", summary: res.message }));
  } else {
    dispatch(hideLoaderAction());
    dispatch(showToast({ severity: "error", summary: res.message }));
  }
};
export const addServiceProviderAction = (data, next) => async (dispatch) => {
  dispatch(showLoaderAction());
  const res = await HospitalApi(
    "post",
    HospitalConstants.END_POINT.ADDSERVICEPROVIDER,
    data
  );
  if (res.success === true) {
    if (res.data) {
      dispatch({
        type: types.ADD_SERVICE_PROVIDER,
        payload: res.data,
      });
    }
    dispatch(hideLoaderAction());
    next();
    dispatch(showToast({ severity: "success", summary: res.message }));
  } else {
    dispatch(hideLoaderAction());
    dispatch(showToast({ severity: "error", summary: res.message }));
  }
};

export const viewManageService = (id) => async (dispatch) => {
  dispatch(showLoaderAction());
  const res = await HospitalApi(
    "get",
    `${HospitalConstants.END_POINT.VIEWMANAGESERVICEPROVIDER}/${id}`
  );
  if (res.success === true) {
    if (res.data) {
      dispatch({
        type: types.VIEW_MANAGE_SERVICE_PROVIDER,
        payload: res.data,
      });
    }
    dispatch(hideLoaderAction());
  } else {
    dispatch(hideLoaderAction());
  }
};

export const viewServiceProviderAction = (id) => async (dispatch) => {
  dispatch(showLoaderAction());
  const res = await HospitalApi(
    "get",
    `${HospitalConstants.END_POINT.VIEWSERVICEPROVIDER}/${id}`
  );
  if (res.success === true) {
    if (res.data) {
      dispatch({
        type: types.VIEW_SERVICE_PROVIDER,
        payload: res.data,
      });
    }
    dispatch(hideLoaderAction());
  } else {
    dispatch(hideLoaderAction());
  }
};

export const editServiceProviderAction =
  (id, data, next) => async (dispatch) => {
    dispatch(showLoaderAction());
    const res = await HospitalApi(
      "post",
      `${HospitalConstants.END_POINT.EDITSERVICEPROVIDER}/${id}`,
      data
    );
    if (res.success === true) {
      if (res.data) {
        dispatch({
          type: types.EDIT_SERVICE_PROVIDER,
          payload: res.data,
        });
      }
      dispatch(hideLoaderAction());
      next();
      dispatch(showToast({ severity: "success", summary: res.message }));
    } else {
      dispatch(hideLoaderAction());
    }
  };
export const editManageServiceProviderAction =
  (id, data, next) => async (dispatch) => {
    dispatch(showLoaderAction());
    const res = await HospitalApi(
      "post",
      `${HospitalConstants.END_POINT.EDITMANAGESERVICEPROVIDER}/${id}`,
      data
    );
    if (res.success === true) {
      if (res.data) {
        dispatch({
          type: types.EDIT_MANAGE_SERVICE_PROVIDER,
          payload: res.data,
        });
      }
      dispatch(hideLoaderAction());
      next();
      dispatch(showToast({ severity: "success", summary: res.message }));
    } else {
      dispatch(hideLoaderAction());
    }
  };
