import axios from "axios";
import HospitalConstants from "./HospitalConstants";
import { isAuthenticated,authenticate } from "./authService";
const successStatus = [200, 201, 204];
export const include = (arr, con) => arr && arr.includes(con)

export const HospitalApi = (method, urlEndPoint, data = null) =>
new Promise((myResolve) => {
    let headers = {
        "Content-Type": "multipart/form-data",
    };
    if (isAuthenticated()) {
        headers = {
            ...headers,
            Authorization: `Bearer ${isAuthenticated()}`,
        };
    }
    axios({
        method,
        url: HospitalConstants.BASE_URL + urlEndPoint,
        data,
        headers,
    }) 
        .then((response) => {
            myResolve({
                message: response.data.message,
                data: response.data.data,
                success: response.data.success,
                statusCode: include(successStatus, response.status),
            });
        })
        .catch((err) => {
            if (err?.response?.data?.message) {
                myResolve({
                    message: err?.response?.data?.message,
                    statusCode: false,
                });
            }
            myResolve({
                message: err?.response?.data,
                statusCode: false,
            });
        });
});

export const HospitalGETAPi = (method, urlEndPoint, data = null) =>

new Promise((myResolve) => {
     let headers = {
         "Content-Type": "application/json",
     };
     if (isAuthenticated()) {
         headers = {
             ...headers,
             Authorization: `Bearer ${isAuthenticated()}`,
         };
     }
     axios({
         method,
         url: HospitalConstants.BASE_URL + urlEndPoint,
         data,
         headers,
     })
         .then((response) => {
             myResolve({
                 message: response.data.message,
                 data: response.data.data,
                 success: response.data.success,
                 statusCode: include(successStatus, response.status),
             });
         })
         .catch((err) => {
             if (err?.response?.data?.message) {
                 myResolve({
                     message: err?.response?.data?.message,
                     statusCode: false,
                 });
             }
             myResolve({
                 message: err?.response?.data,
                 statusCode: false,
             });
         });
 });

 export const HospitalDeleteApi = (method, urlEndPoint, data = null) =>

 new Promise((myResolve) => {
      let headers = {
          "Content-Type": "application/json",
      };
      if (isAuthenticated()) {
          headers = {
              ...headers,
              Authorization: `Bearer ${isAuthenticated()}`,
          };
      }
      axios({
          method,
          url: HospitalConstants.BASE_URL + urlEndPoint,
          headers,
      })
          .then((response) => {
              myResolve({
                  message: response.data.message,
                  data: response.data.data,
                  success: response.data.success,
                  statusCode: include(successStatus, response.status),
              });
          })
          .catch((err) => {
              if (err?.response?.data?.message) {
                  myResolve({
                      message: err?.response?.data?.message,
                      statusCode: false,
                  });
              }
              myResolve({
                  message: err?.response?.data,
                  statusCode: false,
              });
          });
  });