import React, { useEffect } from "react";
import RessmedxLogo from "../assets/images/resmedx 2.png";
import LoginTopbar from "./LoginTopbar";
import { useLocation, useNavigate } from "react-router-dom";
import AboutBg from "../assets/images/aboutbg.png";
import Container from "../components/container/Container";
import HomeContent from "./HomeContent";
import Footer from "../shared/Footer";
export default function About() {
  const {AboutUsContent} = HomeContent()
  return (
    <>
      <LoginTopbar />
      {/* banner */}
      <div
        className="about-banner h-25rem py-7"
        style={{ background: `url(${AboutBg})` }}
      >
        <h1 className=" relative text-white text-6xl">About Us</h1>
      </div>
      {/* banner end */}
      <div className="py-7">
        <Container>
          <p className="text-sub-heading">
            At ResMedX, we are dedicated to revolutionizing healthcare through
            cutting-edge Electronic Health Record (EHR) software solutions. Our
            journey began with a simple yet profound vision: to empower
            healthcare professionals and institutions with the tools they need
            to provide exceptional patient care.
          </p>
          <p className="text-sub-heading">
            Our mission is to transform healthcare delivery by streamlining and
            enhancing the way patient information is managed and accessed. We
            strive to make the practice of medicine more efficient, accurate,
            and patient-centric, ultimately improving outcomes and saving lives.
          </p>
          <p className="text-sub-heading">
            We are a passionate team of healthcare and technology experts,
            united by a shared commitment to innovation and the betterment of
            healthcare systems worldwide. Our diverse backgrounds in medicine,
            software development, data science, and user experience design
            converge to create a dynamic force for positive change in the
            industry.
          </p>
          <div className="w-full mt-3">
            {AboutUsContent.map((item, key) => {
              return (
                <div className="flex gap-3 mb-5">
                  <div className={`icon-box p-2 ${item.iconclass}`}>
                    <img src={item.img} alt="" />
                  </div>
                  <div>
                    <h2 className="text-xl m-0 mb-2 text-heading font-bold">
                      {item.heading}
                    </h2>
                    <p className="text-sub-heading m-0">{item.paragraph}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </Container>
      </div>
      <Footer />
    </>
  );
}
