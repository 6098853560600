import { types } from "../HospitalType/types";
import HospitalConstants from "../../Services/HospitalConstants";
import { HospitalApi, HospitalDeleteApi } from "../../Services/HospitalApi";
import { showLoaderAction, hideLoaderAction } from "./loaderAction";
import { showToast } from "./toastAction";



export const getExpenseCategoriesAction = () => async (dispatch) => {
    dispatch(showLoaderAction())
    const res = await HospitalApi("get", HospitalConstants.END_POINT.GETEXPENSECATEGORIES)
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.GET_EXPENSE_CATEGORIES,
                payload: res.data
            })
        }
        dispatch(hideLoaderAction())
    } else {
        dispatch(hideLoaderAction())
        dispatch(showToast({ severity: "error", summary: res.message }))
    }
}

export const addExpenseCategoriesAction = (data, next) => async (dispatch) => {
    dispatch(showLoaderAction())
    const res = await HospitalApi("post", HospitalConstants.END_POINT.ADDEXPENSECATEGORIES, data)
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.ADD_EXPENSE_CATEGORIES,
                payload: res.data
            })
        }
        dispatch(hideLoaderAction())
        next()
        dispatch(showToast({ severity: "success", summary: res.message }))
    } else {
        dispatch(hideLoaderAction())
        dispatch(showToast({ severity: "error", summary: res.message }))
    }
}


export const deleteExpenseCateogiesAction = (id, next) => async (dispatch) => {
    dispatch(showLoaderAction())
    const res = await HospitalDeleteApi("get", `${HospitalConstants.END_POINT.DELETEEXPENSECATEGORIES}/${id}`);
    if (res.statusCode === true) {
        if (res.data) {
            dispatch({
                type: types.DELETE_EXPENSE_CATEGORIES,
                payload: res.data
            })
        }
        dispatch(hideLoaderAction)
        next()
        dispatch(showToast({ severity: "success", summary: res.message }));
    } else {
        dispatch(hideLoaderAction)
        dispatch(showToast({ severity: "error", summary: res.message }));
    }
}

export const editExpenseCategoriesAction = (data, id, next) => async (dispatch) => {
    dispatch(showLoaderAction())
    const res = await HospitalApi("post", `${HospitalConstants.END_POINT.EDITEXPENSECATEGORIES}/${id}`, data)
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.EDIT_EXPENSE_CATEGORIES,
                payload: res.data
            })
        }
        dispatch(hideLoaderAction)
        next()
        dispatch(showToast({ severity: "success", summary: res.message }));
    } else {
        dispatch(hideLoaderAction)
        dispatch(showToast({ severity: "error", summary: res.message }));
    }
}

export const viewExpenseCategoriesAction = (id) => async (dispatch) => {
    dispatch(showLoaderAction())
    const res = await HospitalApi("get", `${HospitalConstants.END_POINT.VIEWEXPENSECATEGORIES}/${id}`)
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.VIEW_EXPENSE_CATEGORIES,
                payload: res.data
            })
        }
        dispatch(hideLoaderAction())
    } else {
        dispatch(hideLoaderAction)
        dispatch(showToast({ severity: "error", summary: res.message }));
    }
}

export const getExpenseAction = (data) => async (dispatch) => {
    dispatch(showLoaderAction())
    const res = await HospitalApi("post", HospitalConstants.END_POINT.GETEXPENSE, data)
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.GET_EXPENSE,
                payload: res.data.reverse()
            })
        }
        dispatch(hideLoaderAction())
    } else {
        dispatch(hideLoaderAction())
    }
}

export const addExpenseAction = (data, next) => async (dispatch) => {
    dispatch(showLoaderAction())
    const res = await HospitalApi("post", HospitalConstants.END_POINT.ADDEXPENSE, data)
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.ADD_EXPENSE,
                payload: res.data
            })
        }
        dispatch(hideLoaderAction)
        next()
        dispatch(showToast({ severity: "success", summary: res.message }));
    } else {
        dispatch(hideLoaderAction())
        dispatch(showToast({ severity: "error", summary: res.message }));
    }
}

export const deleteExpenseAction = (id, next) => async (dispatch) => {
    dispatch(showLoaderAction())
    const res = await HospitalDeleteApi("get", `${HospitalConstants.END_POINT.DELETEEXPENSE}/${id}`)
    if (res.statusCode) {
        if (res.data) {
            dispatch({
                type: types.DELETE_EXPENSE,
                payload: res.data
            })
        }
        dispatch(hideLoaderAction())
        next()
        dispatch(showToast({ severity: "success", summary: res.message }));
    } else {
        dispatch(hideLoaderAction())
        dispatch(showToast({ severity: "error", summary: res.message }));
    }
}

export const viewExpenseAction = (id, next) => async (dispatch) => {
    dispatch(showLoaderAction())
    const res = await HospitalApi("get", `${HospitalConstants.END_POINT.VIEWEXPENSE}/${id}`)
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.VIEW_EXPENSE,
                payload: res.data
            })
        }
        dispatch(hideLoaderAction())
    } else {
        dispatch(hideLoaderAction())
    }
}

export const editExpenseAction = (id, data, next) => async (dispatch) => {
    dispatch(showLoaderAction())
    const res = await HospitalApi("post", `${HospitalConstants.END_POINT.EDITEXPENSE}/${id}`, data)
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.EDIT_EXPENSE,
                payload: res.data
            })
        }
        dispatch(hideLoaderAction())
        next()
        dispatch(showToast({ severity: "success", summary: res.message }));
    } else {
        dispatch(hideLoaderAction())
        dispatch(showToast({ severity: "error", summary: res.message }));
    }
}

export const getRevenueCategoriesAction = () => async (dispatch) => {
    dispatch(showLoaderAction())
    const res = await HospitalApi("get", HospitalConstants.END_POINT.GETREVENUECATEGORIES)
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.GET_REVENUE_CATEGORIES,
                payload: res.data
            })
        }
        dispatch(hideLoaderAction())
        // dispatch(showToast({ severity: "success", summary: res.message }))
    } else {
        dispatch(hideLoaderAction())
        dispatch(showToast({ severity: "error", summary: res.message }))
    }
}

export const addRevenueCategoriesAction = (data, next) => async (dispatch) => {
    dispatch(showLoaderAction())
    const res = await HospitalApi("post", HospitalConstants.END_POINT.ADDREVENUECATEGORIES, data)
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.ADD_REVENUE_CATEGORIES,
                payload: res.data
            })
        }
        dispatch(hideLoaderAction())
        next()
        dispatch(showToast({ severity: "success", summary: res.message }))
    } else {
        dispatch(hideLoaderAction())
        dispatch(showToast({ severity: "error", summary: res.message }))
    }
}

export const deleteRevenueCategoriesAction = (id, next) => async (dispatch) => {
    dispatch(showLoaderAction())
    const res = await HospitalDeleteApi("get", `${HospitalConstants.END_POINT.DELETEREVENUECATEGORIES}/${id}`)
    if (res.statusCode === true) {
        if (res.data) {
            dispatch({
                type: types.DELETE_REVENUE_CATEGORIES,
                payload: res.data
            })
        }
        dispatch(hideLoaderAction())
        next()
        dispatch(showToast({ severity: "success", summary: res.message }))
    } else {
        dispatch(hideLoaderAction())
        dispatch(showToast({ severity: "error", summary: res.message }))
    }
}

export const viewRevenuCategoriesAction = (id) => async (dispatch) => {
    dispatch(showLoaderAction())
    const res = await HospitalApi("get", `${HospitalConstants.END_POINT.VIEWREVENUECATEGORIES}/${id}`)
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.VIEW_REVENUE_CATEGORIES,
                payload: res.data
            })
        }
        dispatch(hideLoaderAction())
        // dispatch(showToast({severity:"success",summary:res.message}))
    } else {
        dispatch(hideLoaderAction())
        dispatch(showToast({ severity: "error", summary: res.message }))
    }
}

export const editRevenueCategoriesAction = (data, id, next) => async (dispatch) => {
    dispatch(showLoaderAction())
    const res = await HospitalApi("post", `${HospitalConstants.END_POINT.EDITREVENUECATEGORIES}/${id}`, data)
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.EDIT_REVENUE_CATEGORIES,
                payload: res.data
            })
        }
        dispatch(hideLoaderAction())
        next()
        dispatch(showToast({ severity: "success", summary: res.message }))
    } else {
        dispatch(hideLoaderAction())
        dispatch(showToast({ severity: "error", summary: res.message }))
    }
}

export const getRevenueAction = (data) => async (dispatch) => {
    dispatch(showLoaderAction())
    const res = await HospitalApi("post", HospitalConstants.END_POINT.GETREVENUE, data)
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.GET_REVENUE,
                payload: res.data
            })
        }
        dispatch(hideLoaderAction())
    } else {
        dispatch(hideLoaderAction())
    }
}

export const addRevenueAction = (data, next) => async (dispatch) => {
    dispatch(showLoaderAction())
    const res = await HospitalApi("post", HospitalConstants.END_POINT.ADDREVENUE, data)
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.ADD_REVENUE,
                payload: res.data
            })
        }
        dispatch(hideLoaderAction())
        next()
        dispatch(showToast({ severity: "success", summary: res.message }))
    } else {
        dispatch(hideLoaderAction())
        dispatch(showToast({ severity: "error", summary: res.message }))
    }
}

export const deleteRevenueAction = (id, next) => async (dispatch) => {
    dispatch(showLoaderAction())
    const res = await HospitalDeleteApi("get", `${HospitalConstants.END_POINT.DELETEREVENUE}/${id}`)
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.DELETE_REVENUE,
                payload: res.data
            })
        }
        dispatch(hideLoaderAction())
        next()
        dispatch(showToast({ severity: "success", summary: res.message }))
    } else {
        dispatch(hideLoaderAction())
        dispatch(showToast({ severity: "error", summary: res.message }))
    }
}

export const viewRevenueAction = (id) => async (dispatch) => {
    dispatch(showLoaderAction())
    const res = await HospitalApi("get", `${HospitalConstants.END_POINT.VIEWEREVENUE}/${id}`)
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.VIEW_REVENUE,
                payload: res.data
            })
        }
        dispatch(hideLoaderAction())
    } else {
        dispatch(hideLoaderAction())
        dispatch(showToast({ severity: "error", summary: res.message }))
    }
}

export const editRevenueAction = (data,id,next)=>async(dispatch)=>{
    dispatch(showLoaderAction())
    const res =  await HospitalApi("post",`${HospitalConstants.END_POINT.EDITREVENUE}/${id}`,data)
    if(res.success){
        if(res.data){
            dispatch({
                type:types.EDIT_REVENUE,
                payload:res.data
            })
        }
        dispatch(hideLoaderAction())
        next()
        dispatch(showToast({severity:"success",summary:res.message}))
    }else{
        dispatch(hideLoaderAction())
        dispatch(showToast({severity:"error",summary:res.message}))
    }
}