import React, { useState, useEffect } from "react";
import AddButton from "../../../../components/Buttons/AddButton";
import ContentArea from "../../../../shared/ContentArea";
import Table from "../../../../components/TableList/Table";
import { useNavigate } from "react-router-dom";
import Index from "../../Index";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import Deleteimg from "../../../../assets/images/deleted.png";
import SearchIcon from "../../../../assets/icons/search.png";
import {
  getEmployeeSchedulingAction,
  deleteEmployeeScheduling,
} from "../../../../Redux/HospitalAction/employessAction";
import { useDispatch, useSelector } from "react-redux";
import { FilterMatchMode } from "primereact/api";
import Loader from "../../../../pages/Loader";
import MenuDrop from "../../../../components/Menu/Menu";
import AddSpecialSchedule from "../AddSpecialSchedule/AddSpecialSchedule";
const EmployeeScheduling = () => {
  const dispatch = useDispatch();
  const [deleteButton, setDeleteButton] = useState(false);
  const [TableData, setTableData] = useState("");
  const { isNextPage, setIsNextPage } = Index();
  const [isShowFilter, setIsShowFilter] = useState(false);
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const [data, setData] = useState("");
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const navigate = useNavigate();
  const id = data;
  const handleDelete = () => {
    dispatch(deleteEmployeeScheduling(id, () => setDeleteButton(false))).then(
      () => {
        dispatch(getEmployeeSchedulingAction());
      }
    );
  };

  const AddScheduling = () => {
    setIsNextPage(!isNextPage);
    navigate("/account-owner/add-scheduling");
  };

  useEffect(() => {
    dispatch(getEmployeeSchedulingAction());
  }, []);

  const getData = useSelector(
    (state) => state.getEmployeeScheduling.getEmployeeSchedulingProfile
  );

  useEffect(() => {
    if (getData.length === 0) {
      setTableData("");
    } else {
      setTableData(getData);
    }
  }, [getData]);

  const TableHeading = [
    { field: "SrNo", header: "Sr. No.", body: "srnoTemplates" },
    { field: "employee_Id", header: "Employee Id" },
    { field: "employee_name", header: "Employee Name" },
    { field: "icons", header: "Action", body: "SchedulingIconsBodyTemplate" },
  ];

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const items = [
    {
      items: [
        {
          label: "Add Schedule",
          command: () => AddScheduling(),
        },
        {
          label: "Add Special Schedule",
          command: () => navigate("/account-owner/add-special_scheduling"),
        },
        {
          label: "Add Leave",
          command: () => navigate("/account-owner/add-leave"),
        },
      ],
    },
  ];

  const isLoading = useSelector((state) => state.loader.isLoading);
  return (
    <>
      {isLoading === true ? (
        <Loader />
      ) : (
        <ContentArea>
          <div className="manage_employees_container p-3 ">
            <div className="grid mb-5 mr-0 ml-0 align-items-center bg-main-light border-round-lg shadow-1 flex justify-content-around">
              <div className="md:col-5">
                <h1 className="text-main  " style={{ fontSize: "24px" }}>
                  Manage Scheduling
                </h1>
              </div>
              <div className="md:col-3">
                <div className="search-box w-full">
                  <img
                    src={SearchIcon}
                    alt="Search Icon"
                    width="15px"
                    height="15px"
                  />
                  <input
                    type="search"
                    placeholder="Search"
                    onChange={onGlobalFilterChange}
                    onClick={() => setIsShowFilter(false)}
                  />
                </div>
              </div>
              <div className="md:col-1 w-max ">
                <MenuDrop
                  items={items}
                  icon={"pi pi-chevron-down text-sm "}
                  label={"Add"}
                ></MenuDrop>
              </div>
            </div>

            <div className="grid mt-2">
              <div className="md:col-12">
                <Table
                  tableHeading={TableHeading}
                  tableData={TableData}
                  setDeleteButton={setDeleteButton}
                  filters={filters}
                  setFilters={setFilters}
                  setData={setData}
                />
              </div>
            </div>

            {deleteButton ? (
              <Dialog
                className="table_delete_modal flex justify-content-center "
                visible={deleteButton}
                onHide={() => setDeleteButton(false)}
                style={{ width: "30vw" }}
              >
                <p className="m-0">
                  <div className=" flex justify-content-center">
                    <img src={Deleteimg} alt="" width={150} height={127}></img>
                  </div>
                  <div className="text-center mb-5">
                    <h4 className=" p-0 m-0 ">
                      Are you sure you want to delete this user?
                    </h4>
                    <p className="text-xs text-400">
                      All associated data will also be deleted! There is no
                      undo!
                    </p>
                  </div>
                  <div className=" btn flex justify-content-center">
                    <div className="text-center">
                      <Button
                        label="Cancel"
                        className="w-full surface-500 p-3 px-5    border-0 border-round-md "
                        onClick={() => setDeleteButton(false)}
                      />
                    </div>
                    <div className="text-center">
                      <Button
                        onClick={handleDelete}
                        label="Delete"
                        className=" w-full px-5 border-0 p-3 ml-3 border-round-md bg-red-500"
                      />
                    </div>
                  </div>
                </p>
              </Dialog>
            ) : null}
          </div>
        </ContentArea>
      )}
    </>
  );
};

export default EmployeeScheduling;
