
import React, { useState, useEffect } from "react";
import InputField from "../../../../components/input/input";
import { Card } from "primereact/card";
import { Calendar } from "primereact/calendar";
import { InputText } from "primereact/inputtext";
import ContentArea from "../../../../shared/ContentArea";
import SaveButton from "../../../../components/Buttons/SaveButton";
import CancelButton from "../../../../components/Buttons/CancelButton";
import { Dropdown } from "primereact/dropdown";
import { viewExpenseAction, getExpenseCategoriesAction, editExpenseAction } from "../../../../Redux/HospitalAction/expenseAction";
import { useDispatch, useSelector } from "react-redux";
import DropdownField from "../../../../components/input/dropdown";
import { useParams, useNavigate } from "react-router-dom";
import Loader from "../../../../pages/Loader";
import moment from "moment";
const EditExpenses = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [categories, setCategories] = useState(null);
    const [date, setDate] = useState(null);
    const [error, setError] = useState("")
    const [data, setData] = useState({
        select_category_id: "",
        expense_name: "",
        expense_date: "",
        expense_amount: "",
        expense_description: "",
        assignment: ""
    })
    const { id } = useParams()
    useEffect(() => {
        dispatch(getExpenseCategoriesAction())
        dispatch(viewExpenseAction(id))
    }, [])
    const viewData = useSelector((state) => state.viewExpense.viewExpenseProfile)
    useEffect(() => {
        let obj
        if (viewData) {
            obj = {
                expense_name: viewData?.name,
                expense_date: new Date(viewData?.date?.split(" ")[0]),
                expense_amount: viewData?.amount,
                expense_description: viewData?.description,
                assignment: viewData?.assignment,
                select_category_id: viewData?.category_id
            }
        }
        setData(obj)
    }, [viewData])
    const viewExpenseData = useSelector((state) => state.getExpenseCategories.getExpenseProfile)
    useEffect(() => {
        viewExpenseData?.length > 0 && viewExpenseData?.map((item) => {
            if (item?.id === viewData?.category_id) {
                setCategories(item)
            }
        })
    }, [viewExpenseData, viewData])
    const handleChange = (e) => {
        const { name, value } = e.target
        setData({
            ...data,
            [name]: value
        })
        setError({
            ...error,
            [name]: null
        })
    }
    useEffect(() => {
        setData({
            ...data,
            select_category_id: categories?.id
        })
        setError({
            ...error,
            select_category_id: null
        })
    }, [categories])
    const handleSubmit = () => {
        if (data.select_category_id === "" || data.expense_name === "" || data.expense_amount === "" || data.assignment === "" || data.expense_date ===
            "Invalid date") {
            setError({
                ...error,
                select_category_id: data.select_category_id === "" ? "Please Enter Select Category" : error.select_category_id,
                expense_name: data.expense_name === "" ? "Please Enter Expense Name" : error.expense_name,
                expense_amount: data.expense_amount === "" ? "Please Enter Expense Amount" : error.expense_amount,
                assignment: data.assignment === "" ? "Please Enter Assignment" : error.assignment,
                expense_date: data.expense_date === "Invalid date" ? "Please Select Date" : error.expense_date
            })
            return false;
        }
        const obj = data;
        let array = Object.entries(obj)
        const formData = new FormData()
        for (let i = 0; i < array.length; i++) {
            if (array[i][0] === "expense_date") {
                formData.append(array[i][0], moment(data.expense_date).format('DD-MM-YYYY'))
            } else {
                formData.append(array[i][0], array[i][1])
            }

        }
        dispatch(editExpenseAction(id, formData, () => navigate("/account-owner/manage-expenses")))
    }
    const isLoading = useSelector((state) => state.loader.isLoading)
    return (
        <>
            {isLoading === true ? <Loader /> :
                <ContentArea>
                    <div className="edit_expenses_container">
                        <div className="p-3">
                            <div className="grid card ml-0 mr-0 px-5 mb-5 align-items-center bg-main-light border-round-lg">
                                <h2 className="text-main">
                                    Edit Expenses
                                </h2>
                            </div>
                            <Card className="shadow-2 p-3 border-round-lg">
                                <div className="p-fluid grid mb-3">
                                    <div className="field col-12 md:col-6">
                                        <DropdownField label="Select Category" value={categories} onChange={(e) => setCategories(e.value)} options={viewExpenseData} optionLabel="category"
                                            placeholder="Select" required />
                                               {error.select_category_id && <span className="error">{error.select_category_id}</span>}
                                    </div>
                                    <div className="field col-12 md:col-6">
                                        <InputField label="Expenses Name" value={data?.expense_name} name="expense_name" onChange={handleChange} required />
                                        {error.expense_name && <span className="error">{error.expense_name}</span>}
                                    </div>
                                    <div className="field col-12 md:col-6">
                                        <InputField label="Expenses Description" value={data?.expense_description} name="expense_description" onChange={handleChange} />
                                    </div>
                                    <div className="field col-12 md:col-6">
                                        <label className="font block mb-2">Select Expense Date<code className="text-red">*</code></label>
                                        <Calendar value={data?.expense_date} onChange={(e) => {
                                            setData({
                                                ...data,
                                                expense_date: e.value
                                            })
                                            setError({
                                                ...error,
                                                expense_date: null
                                            })
                                        }
                                        } className="calender_picker" inputClassName="border-round-md" />
                                         {error.expense_date && <span className="error">{error.expense_date}</span>}
                                    </div>
                                    <div className="field col-12 md:col-6">
                                        <label className="font block mb-2">Expense Amount<code className="text-red">*</code></label>
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon bg-blue-100">$</span>
                                            <InputText placeholder="" className="border-round-right-md" value={data?.expense_amount} name="expense_amount" onChange={handleChange} />
                                        </div>
                                        {error.expense_amount && <span className="error">{error.expense_amount}</span>}
                                    </div>
                                    <div className="field col-12 md:col-6">
                                        <InputField label="Assignment" value={data?.assignment} onChange={handleChange} name="assignment" required />
                                        {error.assignment && <span className="error">{error.assignment}</span>}
                                    </div>
                                </div>
                                <div className="submitButton flex">
                                    <SaveButton label="Save" onClick={handleSubmit}></SaveButton>
                                    <CancelButton label="Cancel" onClick={() => navigate("/account-owner/manage-expenses")}></CancelButton>
                                </div>
                            </Card>
                        </div>
                    </div>
                </ContentArea>
            }
        </>
    )
}

export default EditExpenses;
