import React from "react";
import SearchIcon from "../../../assets/icons/search.png";
import BellIcon from "../../../assets/icons/bell.png";
import BellRedDotIcon from "../../../assets/icons/bell-with-red-dot.png";
import CalendarIcon from "../../../assets/icons/calendar.png";
import SettingIcon from "../../../assets/images/setting.png";
import ProfileImage from "../../../assets/images/profile-image.png";
import clockdashboardIcon from "../../../assets/icons/clockdashboard.png";
import { useLocation, useNavigate } from "react-router-dom";
export default function TopBar() {
  const location = useLocation();
  const navigate = useNavigate();
  const name = localStorage.getItem("name");
  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/");
  };

  return (
    <div className="topbar_container">
      <div className="flex topbar-inner justify-content-end">
        {/* <div className="search-box">
                <img src={SearchIcon} alt="Search Icon" width="18px" height="18px" />
                <input type="search" placeholder="Search" />
            </div> */}
        <div className="right-side flex">
          <div className="icon-btn flex align-items-center align-items-center">
            <div className="" style={{ borderRight: "1px solid #D1D1D1" }}>
              {/* <img
                src={SettingIcon}
                width="25px"
                height="25px"
                alt="Calendar icon"
                className="mx-3 cursor-pointer"
                style={{ objectFit: "contain" }}
                onClick={() => navigate("/account-owner/setting")}
              /> */}
              <i
                className={`pi pi-cog text-xl cursor-pointer ${
                  location.pathname === "/account-owner/setting"
                    ? "text-blue-600"
                    : "text-gray-400"
                }  `}
                onClick={() => navigate("/account-owner/setting")}
              ></i>
              <i className="pi pi-clock text-xl text-gray-400 cursor-pointer"></i>
            </div>
            <div className="px-3  flex align-items-center align-items-center cursor-pointer">
              {/* <img
                src={BellRedDotIcon}
                width="40px"
                height="40px"
                alt="Bell Icon"
              /> */}
              <i className="pi pi-bell text-xl text-gray-400"></i>
            </div>
          </div>
          <div className="profile-box flex align-items-center ml-4">
            <div className="user-name pr-2 ">
              <p className="text-lg font-medium p-0 m-0">{name}</p>
              <p className="text-sm p-0 m-0 text-right">Account Owner</p>
            </div>
            <img
              src={ProfileImage}
              className="mr-2 border-circle"
              width="40px"
              height="40px"
              alt="Profile Image"
            />
            <label className="dropdown">
              <div className="dd-button"></div>
              <input type="checkbox" className="dd-input" id="test" />
              <ul className="dd-menu py-3 z-5">
                <li className="flex">
                  <img
                    src={ProfileImage}
                    className="mr-2 border-circle"
                    width="40px"
                    height="40px"
                    alt="Profile Image"
                  />{" "}
                  <h4
                    className="my-2 text-heading "
                    onClick={() => navigate("/account-owner/my-profile")}
                  >
                    My Profile
                  </h4>
                </li>
                <li className="flex" onClick={() => handleLogout()}>
                  <i className="pi pi-sign-out ml-3"></i> &nbsp;&nbsp;
                  <h4 className="m-0 ml-2 text-heading">Logout</h4>
                </li>
              </ul>
            </label>
          </div>
        </div>
      </div>
      <hr
        className="m-0 border-none"
        style={{ height: "1px", backgroundColor: "rgba(30, 30, 30, .1)" }}
      />
    </div>
  );
}
