import React, { useEffect, useState } from "react";
import AddButton from "../../../../components/Buttons/AddButton";
import ContentArea from "../../../../shared/ContentArea";
import SearchIcon from "../../../../assets/icons/search.png";
import FilterIcon from "../../../../assets/icons/filter.png";
import Table from "../../../../components/TableList/Table";
import { useNavigate } from "react-router-dom";
import Index from "../../Index";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import Deleteimg from "../../../../assets/images/deleted.png";
import {
  getEmployeesAction,
  deleteEmployeeAction,
} from "../../../../Redux/HospitalAction/employessAction";
import { useDispatch, useSelector } from "react-redux";
import { carrierAction } from "../../../../Redux/HospitalAction/carrierAction";
import { FilterMatchMode } from "primereact/api";
import Loader from "../../../../pages/Loader";
const ManageEmployees = () => {
  const dispatch = useDispatch();
  const [deleteButton, setDeleteButton] = useState(false);
  const { isNextPage, setIsNextPage } = Index();
  const [filterData, setFilterData] = useState({
    status: "",
  });
  const [isFiltered, setIsFiltered] = useState(false);
  const [serviceData, setServiceData] = useState("");
  const [carrierData, setCarrierData] = useState("");
  const [tableData, setTableData] = useState("");
  const [isShowFilter, setIsShowFilter] = useState(false);
  const [filterName, setFilterName] = useState("");
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [data, setData] = useState();
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const navigate = useNavigate();
  const AddEmployees = () => {
    setIsNextPage(!isNextPage);
    navigate("/account-owner/add-employees");
  };
  useEffect(() => {
    dispatch(getEmployeesAction());
    dispatch(carrierAction());
  }, []);
  const getEmployeeTableData = useSelector(
    (state) => state.getEmployee.getEmployeeProfile
  );
  const getCarrierData = useSelector((state) => state.carrier.carrierProfile);
  useEffect(() => {
    setTableData(getEmployeeTableData);
    setCarrierData(getCarrierData);
  }, [getEmployeeTableData, getCarrierData]);
  useEffect(() => {
    if (tableData?.length !== 0) {
      let test = "";
      let data =
        tableData?.length > 0 &&
        tableData?.map((item) => {
          let data1 =
            carrierData?.length > 0 &&
            carrierData?.map((items) => {
              if (item?.carrier == items.id) {
                test = items?.carrier_name;
                return true;
              } else {
                return false;
              }
            });
          if (data1) {
            return {
              ...item,
              carrier_name: test,
            };
          }
        });
      setServiceData(data);
    } else {
      setServiceData("");
    }
  }, [tableData, carrierData]);

  const TableHeading = [
    { field: "SrNo", header: "Sr. No.", body: "srnoTemplates" },
    { field: "emp_id", header: "Employee Id" },
    { field: "name", header: "Employee Name" },
    { field: "service_provider_company", header: "Service Provider Name" },
    { field: "email", header: "Email" },
    { field: "carrier_name", header: "Carrier" },
    { field: "mobile", header: "Contact no." },
    { field: "pa_Code", header: "PA Code" },
    { field: "Status", header: "Status", body: "EmployeeactiveTemplate" },
    {
      field: "icons",
      header: "Action",
      body: "manageEmployeesIconsBodyTemplate",
    },
  ];
  //body: "EmployeeactiveTemplate"
  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };
  const clearFilter = (value) => {
    setIsFiltered(false);
    setIsShowFilter(false);
    dispatch(getEmployeesAction());
    setFilterName("");
  };
  const id = data;
  const handleSubmit = () => {
    dispatch(deleteEmployeeAction(id, () => setDeleteButton(false))).then(
      () => {
        dispatch(getEmployeesAction());
      }
    );
  };
  const applyfilter = () => {
    setIsShowFilter(!isShowFilter);
    dispatch(getEmployeesAction(filterData));
    setIsFiltered(true);
  };
  const isLoading = useSelector((state) => state.loader.isLoading);
  const handleChange = (e) => {
    setFilterData({
      ...filterData,
      status: e.target.value,
    });
  };
  return (
    <>
      {isLoading === true ? (
        <Loader />
      ) : (
        <ContentArea>
          <div className="manage_employees_container p-3 ">
            <div className="grid mb-5 mr-0 ml-0 align-items-center bg-main-light border-round-lg shadow-1 flex justify-content-around">
              <div className="md:col-5 ">
                <h1 className="text-main" style={{ fontSize: "24px" }}>
                  Manage Employees
                </h1>
              </div>
              <div className="md:col-3">
                <div className="search-box w-full">
                  <img
                    src={SearchIcon}
                    alt="Search Icon"
                    width="15px"
                    height="15px"
                  />
                  <input
                    type="search"
                    placeholder="Search"
                    onChange={onGlobalFilterChange}
                    onClick={() => setIsShowFilter(false)}
                  />
                </div>
              </div>
              <div className="md:col-1 relative bg-main px-3 border-round-md w-max  ">
                {isFiltered ? (
                  <div
                    onClick={() => clearFilter()}
                    className="bg-main border-round-md text-white text-center text-base relative karla"
                  >
                    Clear &nbsp;
                    <i className="pi pi-filter-slash"></i>
                  </div>
                ) : (
                  <div
                    onClick={() => setIsShowFilter((prev) => !prev)}
                    className="border-round-md text-white text-center text-base relative karla"
                  >
                    Filter &nbsp;
                    <img src={FilterIcon} alt="" width={15} />
                  </div>
                )}
                {isShowFilter ? (
                  <div
                    className="flex flex-column gap-2 filter-popup"
                    style={{ display: isShowFilter ? "block" : "none" }}
                  >
                    <label htmlFor="verified-filter" className="font-bold">
                      Status
                    </label>
                    <select
                      name=""
                      id=""
                      className="p-2"
                      onChange={(e) => handleChange(e)}
                    >
                      <option value="">
                        {globalFilterValue === "inActive"
                          ? "InActive"
                          : globalFilterValue === "active"
                          ? "active"
                          : "Select"}
                      </option>
                      <option value="inActive">Inactive</option>
                      <option value="active">Active</option>
                    </select>
                    <div>
                      <Button
                        className="w-full justify-content-center"
                        onClick={(e) => applyfilter(e)}
                      >
                        Apply
                      </Button>
                    </div>
                  </div>
                ) : null}
              </div>
              <div className="md:col-1 w-max ">
                <AddButton onClick={() => AddEmployees()} />
              </div>
            </div>

            <div className="grid mt-2">
              <div className="md:col-12">
                <Table
                  tableHeading={TableHeading}
                  tableData={serviceData}
                  setDeleteButton={setDeleteButton}
                  deleteButton={deleteButton}
                  filters={filters}
                  setFilters={setFilters}
                  setData={setData}
                />
              </div>
            </div>

            {deleteButton ? (
              <Dialog
                className="table_delete_modal flex justify-content-center "
                visible={deleteButton}
                onHide={() => setDeleteButton(false)}
                style={{ width: "30vw" }}
              >
                <p className="m-0">
                  <div className=" flex justify-content-center">
                    <img src={Deleteimg} alt="" width={150} height={127}></img>
                  </div>
                  <div className="text-center mb-5">
                    <h4 className=" p-0 m-0 ">
                      Are you sure you want to delete this user?
                    </h4>
                    <p className="text-xs text-400">
                      All associated data will also be deleted! There is no
                      undo!
                    </p>
                  </div>
                  <div className=" btn flex justify-content-center">
                    <div className="text-center">
                      <Button
                        label="Cancel"
                        className="w-full surface-500 p-3 px-5  border-0 border-round-md "
                        onClick={() => setDeleteButton(false)}
                      />
                    </div>
                    <div className="text-center">
                      <Button
                        onClick={handleSubmit}
                        label="Delete"
                        className=" w-full px-5 border-0 p-3 ml-3 border-round-md bg-red-500"
                      />
                    </div>
                  </div>
                </p>
              </Dialog>
            ) : null}
          </div>
        </ContentArea>
      )}
    </>
  );
};

export default ManageEmployees;
