
import React, { useEffect, useState } from 'react'
import ContentArea from "../../../../shared/ContentArea";
import SearchIcon from "../../../../assets/icons/search.png";
import Table from '../../../../components/TableList/Table';
import FilterIcon from "../../../../assets/icons/filter.png";
import { getcommission } from '../../../../utils/action';
import { FilterMatchMode } from 'primereact/api';
import { Calendar } from "primereact/calendar";
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
export default function CommissionsReports() {
    const [tableData, setTableData] = useState("")
    const [value, setValue] = useState()
    const [isShowFilter, setIsShowFilter] = useState(false);
    const [isFiltered, setIsFiltered] = useState(false);
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [filterData, setFilterData] = useState({
        start_date: "",
        end_date: ""
    })
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });
    const [products, setProducts] = useState([]);
    const [isLoading, setIsLoading] = useState(false)
    const [numberClient, setNumberClient] = useState(0)
    const [totalRevenue, setTotalRevenue] = useState(0)
    const [totalCommission, setTotalCommission] = useState(0)
    const handleGetCommission = async () => {
        setIsLoading(true)
        let response = await getcommission({ payload: value })
        setTableData(response.data)
        setIsLoading(false)
    }
    useEffect(() => {
        handleGetCommission()
    }, [])
    const TableHeading = [
        // { selectionMode: "multiple" },
        { field: "SrNo", header: "Sr. No.", body: "srnoTemplates" },
        { field: "name", header: "Client’s Name" },
        { field: "number_of_client", header: "No. of Clients", },
        { field: "total_revenue", header: "Total Revenue" },
        { field: "commission_percentage", header: "Commission Percentage", },
        { field: "total_commission", header: "Total Commission", },
        // { field: "icons", header: "Action", body: "reportsEyeBodyTemplate" },
    ];
    const exportExcel = () => {
        import('xlsx').then((xlsx) => {
            const worksheet = xlsx.utils.json_to_sheet(products);
            const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, {
                bookType: 'xlsx',
                type: 'array'
            });

            saveAsExcelFile(excelBuffer, 'products');
        });
    };
    useEffect(() => {

        let object
        let array = []
        tableData?.client?.length > 0 && tableData.client.map((item, index) => {
            object = {
                SrNo: index + 1,
                ClientName: item.name,
                NoofClients: item?.number_of_client,
                TotalRevenue: item?.total_revenue,
                CommissionPercentage: item?.commission_percentage,
                TotalCommission: item.total_commission
            }
            array.push(object)
        })
        setProducts(array)
    }, [tableData]);
    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then((module) => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
            }
        });
    };
    const applyfilter = async () => {
        setIsLoading(true)
        setIsShowFilter(!isShowFilter)
        let response = await getcommission({ payload: filterData })
        setIsFiltered(true)
        setTableData(response.data)
        setIsLoading(false)
    }
    const Clearfilter = async () => {
        setIsLoading(true)
        setIsFiltered(false)
        setIsShowFilter(false)
        let res = await getcommission({ payload: value })
        setIsLoading(false)
        setTableData(res.data)
    }
    const handleChange = (e) => {
        let date = new Date(e.target.value)
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let dt = date.getDate();
        if (dt < 10) {
            dt = '0' + dt;
        }
        if (month < 10) {
            month = '0' + month;
        }
        let dob = year + '-' + month + '-' + dt;
        setFilterData({
            ...filterData,
            [e?.target?.name]: dob,
        });
    };

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        _filters['global'].value = value;
        setFilters(_filters);
        setGlobalFilterValue(value);
    };
    useEffect(() => {
        let number_of_client = 0
        let splitRevenue
        let splitCommision
        let commission = 0
        let revenue = 0
        tableData?.client?.length > 0 && tableData?.client?.map((item, index) => {
            number_of_client += parseInt(item?.number_of_client)
            splitRevenue = item?.total_revenue?.substring(1)
            splitCommision = item?.total_commission?.substring(1)
            revenue += parseInt(splitRevenue)
            commission += parseInt(splitCommision)
        })
        setTotalRevenue(revenue)
        setNumberClient(number_of_client)
        setTotalCommission(commission)
    }, [tableData])
    return (
        <>
            {isLoading === true ? <ProgressSpinner style={{ width: '50px', height: '50px' }} className='text-center h-screen flex justify-content-center' strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" /> :
                <ContentArea>

                    <div className="p-3">
                        <div className="grid mb-5 mr-0 ml-0 align-items-center bg-main-light border-round-lg shadow-1 flex justify-content-evenly">
                            <div className="md:col-5 ">
                                <h1 className="text-main  " style={{ fontSize: "24px" }}>
                                    Commission Reports
                                </h1>
                            </div>
                            <div className="md:col-3">
                                <div className="search-box w-full">
                                    <img
                                        src={SearchIcon}
                                        alt="Search Icon"
                                        width="15px"
                                        height="15px"
                                    />
                                    <input type="search" placeholder="Search " onChange={onGlobalFilterChange} />
                                </div>
                            </div>
                            <div className="md:col-2 relative bg-main px-3 border-round-md w-max ">
                                {
                                    isFiltered ?
                                        <div onClick={() => Clearfilter()} className="bg-main border-round-md text-white text-center text-base relative karla">
                                            Clear &nbsp;
                                            <i className="pi pi-filter-slash"></i></div>
                                        :
                                        <div onClick={() => setIsShowFilter(prev => !prev)} className="border-round-md text-white text-center text-base relative karla">
                                            Filter &nbsp;
                                            <img src={FilterIcon} alt="" width={15} /></div>
                                }
                                {
                                    isShowFilter ?
                                        <div className="filter-popup">
                                            <label className="font block mb-2">
                                                Start Date<code className="text-red"></code>
                                            </label>
                                            <Calendar
                                                className="border-round-md calender_picker "
                                                inputClassName="calendar p-2 border-round-md "
                                                placeholder="Select Date"
                                                name="start_date"
                                                onChange={(e) => handleChange(e)}
                                            />
                                            <label className="font block mb-2 mt-2">
                                                End Date<code className="text-red"></code>
                                            </label>
                                            <Calendar
                                                className="border-round-md calender_picker "
                                                inputClassName="calendar p-2 border-round-md "
                                                placeholder="Select Date"
                                                name="end_date"
                                                onChange={(e) => handleChange(e)}
                                            />
                                            <div className="mt-3 flex justify-content-between">
                                                <Button onClick={Clearfilter}>Clear</Button>
                                                <Button onClick={(e) => applyfilter(e)}>Apply</Button></div>
                                        </div>
                                        : null
                                }
                            </div>
                            <div className="md:col-2 w-max">
                                <Button type="button" label="Export" className="bg-main karla px-4 border-round-md " severity="success" rounded onClick={exportExcel} data-pr-tooltip="XLS" />
                            </div>

                        </div>
                        <div className="grid mt-2">
                            <div className="md:col-12">

                                <Table tableHeading={TableHeading} tableData={tableData.client} filters={filters} setFilters={setFilters} />
                            </div>

                        </div>
                        {globalFilterValue ? "" :
                        <div className='grid m-0 p-0'>
                            <div className='col-12 md:col-3 '>
                                <p>Grand Total</p>
                            </div>
                            <div className='col-12 md:col-2'>
                                <p className='text-center'>{numberClient}</p>
                            </div>
                            <div className='col-12 md:col-5'>
                                <p  className='ml-7' >${totalRevenue}</p>
                            </div>
                            <div className='col-12 md:col-2'>
                                <p className=''>${totalCommission}</p>
                            </div>
                        </div>}
                    </div>
                </ContentArea>

            }
        </>
    );
}
