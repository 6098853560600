import { types } from "../HospitalType/types";
import { AdminApi } from "../../Services/AdminApi";
import AdminConstants from "../../Services/AdminConstants";
import { showLoaderAction, hideLoaderAction } from "./loaderAction";

export const carrierAction = () => async (dispatch) => {
    dispatch(showLoaderAction())
    const res = await AdminApi("get", AdminConstants.END_POINT.CARRIER);
    if (res.statusCode) {
        if (res.data) {
            dispatch({
                type: types.CARRIER,
                payload: res.data
            })
        }
        dispatch(hideLoaderAction())
    }else{
        dispatch(hideLoaderAction())
    }
   
}