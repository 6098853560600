
import React, { useState } from "react";
import EmployeeTiming from './EmployeeTiming';
import ContentArea from "../../shared/ContentArea";
import InputField from "../../../../components/input/input";
import { Card } from "primereact/card";
import DropdownField from "../../../../components/input/dropdown";
import { getEmployeesAction } from "../../../../Redux/HospitalAction/employessAction";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { viewEmployeeSchedulingAction } from "../../../../Redux/HospitalAction/employessAction";
import { useParams } from "react-router-dom";
import Loader from "../../../../pages/Loader";
import { Checkbox } from 'primereact/checkbox';
const ViewScheduling = () => {
  const [Employe, SetEmploye] = useState(null);
  const [checked, setChecked] = useState(false);
  const [employeeData, setEmployeeData] = useState(null)
  const [data, setData] = useState("")
  const dispatch = useDispatch()
  const { id } = useParams()
  useEffect(() => {
    dispatch(getEmployeesAction())
    dispatch(viewEmployeeSchedulingAction(id))
  }, [])
  const getallEmployee = useSelector((state) => state.getEmployee.getEmployeeProfile)
  const viewData = useSelector((state) => state.viewEmployeeScheduling.viewEmployeeSchedulingProfile)
  useEffect(() => {
    let newViewData = viewData?.time_schedule?.map((item, index) => {
      if (item.is_working === 1) {
        return {
          ...item,
          start_time: new Date(item.start_time).getHours().toLocaleString('en-US', {
            minimumIntegerDigits: 2,
            useGrouping: false
          }) + ":" + new Date(item.start_time).getMinutes().toLocaleString('en-US', {
            minimumIntegerDigits: 2,
            useGrouping: false
          }),
          end_time: new Date(item.end_time).getHours().toLocaleString('en-US', {
            minimumIntegerDigits: 2,
            useGrouping: false
          }) + ":" + new Date(item.end_time).getMinutes().toLocaleString('en-US', {
            minimumIntegerDigits: 2,
            useGrouping: false
          }) ,
          start_lunch_time : new Date(item.start_lunch_time).getHours().toLocaleString('en-US', {
            minimumIntegerDigits: 2,
            useGrouping: false
          }) + ":" +   new Date(item.start_lunch_time).getMinutes().toLocaleString('en-US', {
            minimumIntegerDigits: 2,
            useGrouping: false
          }),
          end_lunch_time: new Date(item.end_lunch_time).getHours().toLocaleString('en-US', {
            minimumIntegerDigits: 2,
            useGrouping: false
          }) + ":" + new Date(item.end_lunch_time).getMinutes().toLocaleString('en-US', {
            minimumIntegerDigits: 2,
            useGrouping: false
          }),
          is_working: item.is_working === 1 ? true : false
        }
      } else {
        return item
      }

    })
    let obj = {
      ...viewData,
      time_schedule: newViewData
    }

    setData(obj)
  }, [viewData])
  useEffect(() => {
    setEmployeeData(getallEmployee)
  }, [getallEmployee])
  useEffect(() => {
    employeeData?.map((item) => {
      if (item.emp_id == data.employee_id) {
        SetEmploye(item)
      }
    })
  }, [employeeData, data])
  const isLoading = useSelector((state) => state.loader.isLoading)
  useEffect(() => {
    if (viewData.is_repeat === 1) {
      setChecked(true)
    } else {
      setChecked(false)
    }
  }, [viewData])

  return (
    <>
      {isLoading === true ? <Loader /> :
        <ContentArea>
          <div className="p-3" style={{ pointerEvents: "none" }}>
            <div className="grid card ml-0 mr-0 px-5 mb-5 align-items-center bg-main-light border-round-lg">
              <h2 className="text-main ">
                View Scheduling
              </h2>
            </div>
            <Card className="shedule shadow-2 p-3">
              <div className='p-fluid grid'>
                <div className="col-12 md:col-6 ">
                  <DropdownField label="Select Employee" inputId="dropdown" disabled required value={Employe} options={employeeData} onChange={(e) => SetEmploye(e.value)} optionLabel="emp_id" />
                </div>

                <div className="col-12 md:col-6">
                  <InputField id="inputtext" label="Full Name" required value={data?.employee_name} disabled />
                </div>

              </div>

              <div className="p-fluid grid" >
                <div className=" col-12 md:col-12 ">
                  <h2 className="text-main font-bold" style={{ fontSize: "22px" }}>Add Timings for selected days</h2>
                </div>
              </div>

              <div className="p-fluid grid" >
                <div className="col-12 md:col-12 border-round-sm ">
                  {data?.time_schedule?.length > 0 && data?.time_schedule?.map((item, index) => {
                    return (
                      <EmployeeTiming title={item.day} index={index} items={item} setData={setData} data={data} />
                    )
                  })}
                </div>

              </div>
              <div className="p-fluid grid mb-5" >
                <div className="col-12 md:col-12 ">
                  <Checkbox onChange={e => setChecked(e.checked)} checked={checked}></Checkbox> &nbsp; Repeat for every week.
                </div>
              </div>

            </Card>

          </div>

        </ContentArea>
      }
    </>
  );
};

export default ViewScheduling;