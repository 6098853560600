import React, { useEffect, useState } from "react";
import { Calendar } from "primereact/calendar";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import SearchIcon from "../../../../assets/icons/search.png";
import { Dialog } from "primereact/dialog";
import ContentArea from "../../shared/ContentArea";
import { Accordion, AccordionTab } from "primereact/accordion";
import { useNavigate } from "react-router-dom";
import Index from "../../Index";
import { Button } from "primereact/button";
import TimeIcon from "../../../../assets/icons/time.png";
import { CommonData } from "./CommonData";
import ClientIcon from "../../../../assets/icons/Ellipse.png";
import moment from "moment";
import Table from "../../../../components/TableList/Table";
import { useDispatch, useSelector } from "react-redux";
import { getAppointmentDataAction } from "../../../../Redux/HospitalAction/appoinmentAction";
import { FilterMatchMode } from "primereact/api";
const ManageSchedules = () => {
  const {
    schedulingData,
    eventData,
    handleClick,
    show,
    setShow,
    viewData,
    name,
  } = CommonData();
  const [isShowFilter, setIsShowFilter] = useState(false);

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };
  const { isNextPage, setIsNextPage } = Index();
  const [header, setHeader] = useState("");
  const [time, setTime] = useState("");
  const navigate = useNavigate();
  const AddSchedules = () => {
    setIsNextPage(!isNextPage);
    navigate("/account-owner/add-schedules");
  };
  const dispatch = useDispatch();
  const [tableData, setTableData] = useState("");
  useEffect(() => {
    dispatch(getAppointmentDataAction());
  }, []);
  const getData = useSelector(
    (state) => state.getAppointmentData.getAppointmentDataProfile
  );
  useEffect(() => {
    if (getData?.length !== 0) {
      setTableData(getData);
    } else {
      setTableData("");
    }
  }, [getData]);
  useEffect(() => {
    if (show === true) {
      viewData?.map((item) => {
        if (item?.appointment_name == name) {
          setHeader(item?.appointment_name);
          setTime(item?.booked_time_slot);
        }
      });
    }
  }, [show, name, viewData]);
  const TableHeading = [
    { field: "SrNo", header: "Sr. No.", body: "srnoTemplates" },
    { field: "appointment_name", header: "Schedule Name" },
    {
      field: "duration",
      header: "Schedule Day",
      body: "showAppointmentDayTemplate",
    },
    { field: "notes", header: "Notes" },
    { field: "icons", header: "", body: "showAppointmentTemplate" },
  ];
  return (
    <>
      <ContentArea>
        <div className="manage_service_container p-3 ">
          <div className="grid mb-5 mr-0 ml-0 align-items-center bg-main-light border-round-lg shadow-1 flex justify-content-around">
            <div className="md:col-4">
              <h1 className="text-main  " style={{ fontSize: "24px" }}>
                Manage Schedules
              </h1>
            </div>
            <div className="md:col-7 flex align-items-center justify-content-end    ">
              <div className="md:col-5">
                <div className="search-box w-full">
                  <img
                    src={SearchIcon}
                    alt="Search Icon"
                    width="15px"
                    height="15px"
                  />
                  <input
                    type="search"
                    placeholder="Search"
                    onChange={onGlobalFilterChange}
                    onClick={() => setIsShowFilter(false)}
                  />
                </div>
              </div>
              <div className="w-max ml-3">
                <Button
                  className="border-round-lg"
                  label="Create Schedule"
                  onClick={() => AddSchedules()}
                />
              </div>
            </div>
          </div>

          <div className="grid mt-2">
            <div className="md:col-12">
              <Table
                tableHeading={TableHeading}
                tableData={tableData}
                filters={filters}
                setFilters={setFilters}
              />
            </div>
          </div>
        </div>
      </ContentArea>
      <Dialog
        header={
          <>
            <h1 className="text-main  font-bold">{header}</h1>
          </>
        }
        visible={show}
        className="lg:w-5"
        style={{ width: "100vw" }}
        onHide={() => setShow(false)}
      >
        <p className="m-0">
          <div className="flex align-items-center">
            <img src={TimeIcon} width={24} height={24} />
            <span
              className="ml-3 text-xl font-medium"
              style={{ fontFamily: "Manrope" }}
            >
              {time}
            </span>
          </div>
          <p
            className="text-lg font-semibold"
            style={{ color: "#050505", fontFamily: "Manrope" }}
          >
            Days
          </p>
          <div className="flex align-items-center mb-3">
            {viewData?.length > 0 &&
              viewData?.map((item) => {
                return item?.appointment_name == name
                  ? item?.days_name?.length > 0 &&
                      item?.days_name?.map((days) => {
                        return (
                          <span className="bg-main text-white p-2 border-round-xl px-4 ml-3">
                            {days}
                          </span>
                        );
                      })
                  : "";
              })}
          </div>
          <p
            className="text-lg font-semibold"
            style={{ color: "#050505", fontFamily: "Manrope" }}
          >
            Clients
          </p>
          <div
            className="w-full"
            style={{ height: "300px", overflowY: "auto" }}
          >
            {viewData?.length > 0 &&
              viewData?.map((item) => {
                return item?.appointment_name == name
                  ? item?.client_name?.length > 0 &&
                      item?.client_name?.map((items) => {
                        return (
                          <div className="grid w-full flex align-items-center">
                            <div className="col-12 md:col-6">
                              <div className="flex align-items-center">
                                <img src={ClientIcon} />
                                <span
                                  className="text-lg font-semibold ml-3 text-main"
                                  style={{ fontFamily: "Manrope" }}
                                >
                                  {items}
                                </span>
                              </div>
                            </div>
                            <div className="col-12 md:col-6">
                              <p
                                className="text-lg font-normal ml-3"
                                style={{
                                  color: "#050505",
                                  fontFamily: "Manrope",
                                }}
                              >
                                {item?.service_name}
                              </p>
                            </div>
                          </div>
                        );
                      })
                  : "";
              })}
          </div>
        </p>
      </Dialog>
    </>
  );
};

export default ManageSchedules;

function renderEventContent(eventInfo) {
  return (
    <div className="p-3">
      <p className="m-0">{eventInfo.event.extendedProps?.appointment_name}</p>
      <p className="m-0">{eventInfo.event.extendedProps?.booked_time_slot}</p>
    </div>
  );
}
