import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAppointmentAction,
  appointmentAddServiceAction,
  appointmentDeletServiceAction,
  appointmentViewServiceAction,
  appointmentEditServiceAction,
} from "../../../../Redux/HospitalAction/appoinmentAction";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  hideLoaderAction,
  showLoaderAction,
} from "../../../../Redux/HospitalAction/loaderAction";
import { HospitalApi } from "../../../../Services/HospitalApi";
import HospitalConstants from "../../../../Services/HospitalConstants";
export const CommonManageService = () => {
  const [tableData, setTableData] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const [deleteButton, setDeleteButton] = useState(false);
  const [schedule, setSchedule] = useState("");
  const [duration, setDuration] = useState("");
  const [data, setData] = useState("");
  const [error, setError] = useState("");
  const [inputs, setInputs] = useState([
    { appointment_scheduling_id: "", duration: "", options: [] },
  ]);
  const isLoading = useSelector((state) => state.loader.isLoading);
  const getAppointmentData = useSelector(
    (state) => state.getappointment.getAppointmentProfile
  );

  const getServiceData = useSelector(
    (state) => state.appointmentGetService.appointmentGetServiceAction
  );

  const viewServiceData = useSelector(
    (state) => state.appointmentViewService.appointementViewServiceProfile
  );

  const [form, setForm] = useState({
    appointment_scheduling_id: "",
    id: "",
    duration: "",
  });

  useEffect(() => {
    dispatch(getAppointmentAction());
  }, []);

  useEffect(() => {
    if (id) {
      dispatch(appointmentViewServiceAction(id));
    } else {
      setInputs([{ appointment_scheduling_id: "", duration: "", options: [] }]);
      setForm({
        appointment_scheduling_id: "",
        id: "",
        duration: "",
      });
    }
  }, [id]);

  const hour = [
    { name: "30 min" },
    { name: "01 hour" },
    { name: "02 hour" },
    { name: "03 hour" },
    { name: "04 hour" },
    { name: "05 hour" },
    { name: "06 hour" },
    { name: "07 hour" },
    { name: "08 hour" },
    { name: "09 hour" },
    { name: "10 hour" },
  ];
  const handleSubmit = () => {
    if (!form?.service_name) {
      setError({
        ...error,
        service_name:
          form?.service_name === "" || form?.service_name === undefined
            ? "Please Enter Service Name"
            : error?.service_name,
      });
      inputs.map((item) => {
        if (!item.appointment_scheduling_id) {
          item.errors = {
            ...item.errors,
            appointment_scheduling_id: "Please Choose Appointment Scheduling",
          };
        }
        if (!item.duration) {
          item.errors = {
            ...item.errors,
            duration: "Please select duration",
          };
        }
      });
      return false;
    }

    // let payload = {
    //   // appointment_scheduling_id: form?.appointment_scheduling_id?.id,
    //   service_name: form?.service_name,
    //   id: viewServiceData.id,
    //   schedule_duration: inputs.map((item) => ({
    //     appointment_scheduling_id: item.appointment_scheduling_id,
    //     duration: item.duration,
    //   })),
    // };
    if (location.pathname === "/account-owner/add-manage-services/" + id) {
      let payload = {
        service_name: form?.service_name,
        schedule_duration: inputs.map((item) => ({
          // id: item.options.find(
          //   (opt) => opt.time_slot_duration === item.duration
          // ).id,
          id: item.id,
          appointment_scheduling_id: item.appointment_scheduling_id,
          duration: item.duration,
          // duration: item.duration.map((duration) => ({
          //   id: duration.id,
          //   appointment_scheduling_id: item.appointment_scheduling_id,
          //   duration: duration.time_slot_duration,
          // })),
        })),
      };

      dispatch(
        appointmentEditServiceAction(
          id,
          payload,
          navigate("/account-owner/manage-services")
        )
      );
    } else {
      let payload = {
        service_name: form?.service_name,
        schedule_duration: inputs.map((item) => ({
          appointment_scheduling_id: item.appointment_scheduling_id,
          duration: item.duration,
        })),
      };
      dispatch(
        appointmentAddServiceAction(
          payload,
          navigate("/account-owner/manage-services")
        )
      );
    }
  };

  const ids = data;
  const handleDelete = () => {
    dispatch(
      appointmentDeletServiceAction(ids, () => setDeleteButton(false))
    ).then(() => {
      dispatch(getServiceList());
    });
  };

  useEffect(() => {
    if (viewServiceData.id) {
      setForm({
        ...form,
        appointment_scheduling_id: viewServiceData?.appointment_scheduling_id,
        service_name: viewServiceData?.service_name,
        duration: viewServiceData?.duration,
      });
      const inputsData = [];
      viewServiceData?.durations?.map((item) => {
        inputsData.push({
          appointment_scheduling_id: item?.appointment_scheduling_id,
          id: item.id,
          duration: item?.duration,
          options: item?.all_durations,
        });
      });
      setInputs(inputsData);
    } else {
      setInputs([{ appointment_scheduling_id: "", duration: "", options: [] }]);
      setForm({
        appointment_scheduling_id: "",
        id: "",
        duration: "",
      });
    }
  }, [viewServiceData]);

  useEffect(() => {
    getAppointmentData?.length > 0 &&
      getAppointmentData?.map((item) => {
        if (item?.id == form?.appointment_scheduling_id) {
          setSchedule(item);
        }
      });
  }, [form, getAppointmentData]);

  useEffect(() => {
    hour?.length > 0 &&
      hour?.map((item) => {
        if (item.name == form?.duration) {
          setDuration(item);
        }
      });
  }, [form]);

  const handleSchedule = (e) => {
    setForm({
      ...form,
      appointment_scheduling_id: e.value,
    });
    setSchedule(e.value);
    setError({
      ...error,
      appointment_scheduling_id: null,
    });
  };

  const handleDuration = (e) => {
    setForm({
      ...form,
      duration: e.value,
    });
    setDuration(e.value);
    setError({
      ...error,
      duration: null,
    });
  };

  // const handleEditSubmit = () => {
  //   // if (
  //   //   form?.appointment_scheduling_id === "" ||
  //   //   form?.service_name === "" ||
  //   //   form?.duration === "" ||
  //   //   form?.appointment_scheduling_id === undefined ||
  //   //   form?.service_name === undefined ||
  //   //   form?.duration === undefined
  //   // ) {
  //   //   setError({
  //   //     ...error,
  //   //     appointment_scheduling_id:
  //   //       form?.appointment_scheduling_id === undefined
  //   //         ? "Please Choose Appointment Scheduling"
  //   //         : error?.appointment_scheduling_id,
  //   //     service_name:
  //   //       form?.service_name === "" || form?.service_name === undefined
  //   //         ? "Please Enter Service Name"
  //   //         : error?.service_name,
  //   //     duration:
  //   //       form?.duration === "" || form?.duration === undefined
  //   //         ? "Please Choose Duration"
  //   //         : error?.duration,
  //   //   });
  //   //   return false;
  //   // }
  //   let payload = {
  //     appointment_scheduling_id:
  //       typeof form?.appointment_scheduling_id === "object"
  //         ? form?.appointment_scheduling_id?.id
  //         : form?.appointment_scheduling_id,
  //     service_name: form?.service_name,
  //     duration:
  //       typeof form?.duration === "object"
  //         ? form?.duration?.name
  //         : form?.duration,
  //   };
  //   const obj = payload;
  //   let array = Object.entries(obj);
  //   const formData = new FormData();
  //   for (let i = 0; i < array.length; i++) {
  //     formData.append(array[i][0], array[i][1]);
  //   }
  //   dispatch(
  //     appointmentEditServiceAction(
  //       id,
  //       formData,
  //       navigate("/account-owner/manage-services")
  //     )
  //   );
  // };

  const getServiceList = async () => {
    dispatch(showLoaderAction());
    const res = await HospitalApi(
      "get",
      HospitalConstants.END_POINT.APPINTMENTGETSERVICE
    );
    if (res.success) {
      setTableData(res.data);
      dispatch(hideLoaderAction());
    } else {
      dispatch(hideLoaderAction());
    }
  };

  return {
    getAppointmentData,
    form,
    setForm,
    hour,
    handleSubmit,
    navigate,
    isLoading,
    error,
    setError,
    getServiceData,
    setData,
    deleteButton,
    setDeleteButton,
    handleDelete,
    viewServiceData,
    schedule,
    inputs,
    setInputs,
    duration,
    handleSchedule,
    handleDuration,
    getServiceList,
    tableData,
  };
};
