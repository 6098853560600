
import React, { useEffect, useState } from "react";
import InputField from "../../../../components/input/input";
import { Card } from "primereact/card";
import { Calendar } from "primereact/calendar";
import { InputText } from "primereact/inputtext";
import ContentArea from "../../../../shared/ContentArea";
import { viewExpenseAction, getExpenseCategoriesAction } from "../../../../Redux/HospitalAction/expenseAction";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import DropdownField from "../../../../components/input/dropdown";
import Loader from "../../../../pages/Loader";
const ViewExpenses = () => {
    const dispatch = useDispatch()
    const [categories, setCategories] = useState(null);
    const { id } = useParams()
    useEffect(() => {
        dispatch(getExpenseCategoriesAction())
        dispatch(viewExpenseAction(id))
    }, [])
    const viewData = useSelector((state) => state.viewExpense.viewExpenseProfile)
    const viewExpenseData = useSelector((state) => state.getExpenseCategories.getExpenseProfile)
    useEffect(() => {
        viewExpenseData?.length > 0 && viewExpenseData?.map((item) => {
            if (item?.id === viewData?.category_id) {
                setCategories(item)
            }
        })
    }, [viewExpenseData, viewData])
    const isLoading = useSelector((state) => state.loader.isLoading)
    return (
        <>
            {isLoading === true ? <Loader /> :
                <ContentArea>
                    <div className="view_expenses_container">
                        <div className="p-3">
                            <div className="grid card ml-0 mr-0 px-5 mb-5 align-items-center bg-main-light border-round-lg">
                                <h2 className="text-main">
                                    View Expenses
                                </h2>
                            </div>
                            <Card className="shadow-2 p-3 border-round-lg">
                                <div className="p-fluid grid mb-3">
                                    <div className="field col-12 md:col-6">
                                        <DropdownField label="Select Category" value={categories} onChange={(e) => setCategories(e.value)} options={viewExpenseData} optionLabel="category"
                                            placeholder="Select" required disabled />
                                    </div>
                                    <div className="field col-12 md:col-6">
                                        <InputField label="Expenses Name" required disabled value={viewData?.name} />
                                    </div>
                                    <div className="field col-12 md:col-6">
                                        <InputField label="Expenses Description" disabled value={viewData?.description} />
                                    </div>
                                    <div className="field col-12 md:col-6">
                                        <label className="font block mb-2">Select Expense Date<code className="text-red">*</code></label>
                                        <Calendar value={new Date(viewData?.date)}  className="calender_picker" inputClassName="border-round-md" disabled />
                                    </div>
                                    <div className="field col-12 md:col-6">
                                        <label className="font block mb-2">Expense Amount <code className="text-red">*</code></label>
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon bg-blue-100">$</span>
                                            <InputText placeholder="" className="border-round-right-md" disabled value={viewData?.amount} />
                                        </div>
                                    </div>
                                    <div className="field col-12 md:col-6">
                                        <InputField label="Assignment" required disabled value={viewData?.assignment} />
                                    </div>
                                </div>

                            </Card>
                        </div>
                    </div>
                </ContentArea>
            }
        </>
    )
}

export default ViewExpenses;
