
import React, { useEffect, useState } from "react";
import AddButton from "../../../../components/Buttons/AddButton";
import ContentArea from "../../../../shared/ContentArea";
import SearchIcon from "../../../../assets/icons/search.png";
import FilterIcon from "../../../../assets/icons/filter.png";
import Table from '../../../../components/TableList/Table';
import { useNavigate } from "react-router-dom";
import Index from '../../Index';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import Deleteimg from "../../../../assets/images/deleted.png";
import { getClientPaCodeAction, deleteClientPaCodeAction } from "../../../../Redux/HospitalAction/clientAction";
import { getServiceAction } from "../../../../Redux/HospitalAction/serviceProviderAction";
import { FilterMatchMode } from 'primereact/api';
import { useDispatch, useSelector } from "react-redux";
import DropdownField from "../../../../components/input/dropdown";
import Loader from "../../../../pages/Loader";
const ManageClientPaCode = () => {
    const dispatch = useDispatch()
    const [deleteButton, setDeleteButton] = useState(false)
    const { isNextPage, setIsNextPage } = Index();
    const [tableData, setTableData] = useState("")
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [isShowFilter, setIsShowFilter] = useState(false);
    const [filterData, setFilterData] = useState({
        service_type_id: "",
        service_type: ""
    })
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });
    const [data, setData] = useState("")
    const [isFiltered, setIsFiltered] = useState(false);
    const navigate = useNavigate()
    const AddClientPaCode = () => {
        setIsNextPage(!isNextPage)
        navigate("/account-owner/add-client-pa-codes")
    }
    useEffect(() => {
        dispatch(getClientPaCodeAction())
        dispatch(getServiceAction())
    }, [])
    const getData = useSelector((state) => state.getClientPaCode.getClientPaCodeProfile)
    const getService = useSelector((state) => state.getService.getServiceProfile)
    useEffect(() => {
        setTableData(getData)
    }, [getData])
    useEffect(() => {
        if (tableData.length === 0) {
            setTableData("")
        }
    }, [tableData])
    const TableHeading = [
        { field: "SrNo", header: "Sr. No.", body: "srnoTemplates" },
        { field: "client_id", header: "Client Id" },
        { field: "client_name", header: "Client Name", },
        { field: "pa_code", header: "PA Code" },
        { field: "annual_allotment", header: "Annual Allotments", },
        { field: "no_of_unit", header: "No. of Units/hr", },
        { field: "max_day_allowed", header: "Max Days Allowed/Month", },
        { field: "service_plan", header: "Service Type", },
        { field: "icons", header: "Action", body: "clientPaCodeIconsBodyTemplate" },

    ];
    const handleChange = (e) => {
        const { name, value } = e.target
        setFilterData({
            ...filterData,
            [name]: value,
            service_type_id: value.id
        })
    }
    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        _filters['global'].value = value;
        setFilters(_filters);
        setGlobalFilterValue(value);
    };
    const applyFilter = () => {
        setIsShowFilter(!isShowFilter)
        const formData = new FormData()
        formData.append("service_type_id", filterData.service_type_id);
        dispatch(getClientPaCodeAction(formData))
        setIsFiltered(true)
    }
    const clearFilter = () => {
        setIsFiltered(false)
        setIsShowFilter(false)
        dispatch(getClientPaCodeAction())
        setFilterData({
            service_type: "",
            service_type_id: ""
        })

    }
    const id = data
    const handleDelete = () => {
        dispatch(deleteClientPaCodeAction(id, () => setDeleteButton(false))).then(() => {
            dispatch(getClientPaCodeAction())
        })
    }
    const isLoading = useSelector((state) => state.loader.isLoading)
    return (
        <>
            {isLoading === true ? <Loader /> :
                <ContentArea>
                    <div className="manage_employees_container p-3 ">

                        <div className="grid mb-5 mr-0 ml-0 align-items-center bg-main-light border-round-lg shadow-1 flex justify-content-around">
                            <div className="md:col-5 ">
                                <h1 className="text-main  " style={{ fontSize: "24px" }}>
                                    Manage Client PA Codes
                                </h1>
                            </div>
                            <div className="md:col-3">
                                <div className="search-box w-full">
                                    <img
                                        src={SearchIcon}
                                        alt="Search Icon"
                                        width="15px"
                                        height="15px"
                                    />
                                    <input type="search" placeholder="Search" onChange={onGlobalFilterChange} onClick={() => setIsShowFilter(false)} />
                                </div>
                            </div>
                            <div className="md:col-1 relative bg-main px-3 border-round-md w-max  ">
                                {
                                    isFiltered ?
                                        <div onClick={clearFilter} className="bg-main border-round-md text-white text-center text-base relative karla">
                                            Clear &nbsp;
                                            <i className="pi pi-filter-slash"></i></div>
                                        :
                                        <div onClick={() => setIsShowFilter(prev => !prev)} className="border-round-md text-white text-center text-base relative karla">
                                            Filter &nbsp;
                                            <img src={FilterIcon} alt="" width={15} /></div>
                                }
                                {
                                    isShowFilter ?
                                        < div className="flex flex-column gap-2 filter-popup" style={{ display: isShowFilter ? "block width:220%" : "none" }} >
                                            <label htmlFor="verified-filter" className="font-bold">
                                                Service
                                            </label>
                                            <DropdownField placeholder="Select" optionLabel="program_name" options={getService} value={filterData.service_type} onChange={handleChange} name="service_type" />
                                            <div>

                                                <Button className="w-full justify-content-center" onClick={applyFilter}  >Apply</Button>
                                            </div>
                                        </div >
                                        : null
                                }
                            </div>
                            <div className="md:col-1 w-max ">
                                <AddButton onClick={() => AddClientPaCode()} />
                            </div>
                        </div>

                        <div className="grid mt-2">
                            <div className="md:col-12">

                                <Table tableHeading={TableHeading}
                                    tableData={tableData}
                                    setDeleteButton={setDeleteButton}
                                    filters={filters}
                                    setData={setData}
                                />
                            </div>

                        </div>

                        {
                            deleteButton ?
                                <Dialog
                                    className="table_delete_modal flex justify-content-center "
                                    visible={deleteButton}
                                    onHide={() => setDeleteButton(false)}
                                    style={{ width: '30vw' }}>
                                    <p className="m-0">
                                        <div className=" flex justify-content-center">
                                            <img src={Deleteimg} alt="" width={150} height={127}></img>
                                        </div>
                                        <div className="text-center mb-5">
                                            <h4 className=" p-0 m-0 ">Are you sure you want to delete this user?</h4>
                                            <p className="text-xs text-400">All associated data will also be deleted! There is no undo!</p>
                                        </div>
                                        <div className=" btn flex justify-content-center">
                                            <div className="text-center">
                                                <Button
                                                    label="Cancel"
                                                    className="w-full surface-500 p-3 px-5    border-0 border-round-md "
                                                    onClick={() => setDeleteButton(false)}
                                                />
                                            </div>
                                            <div className="text-center">
                                                <Button
                                                    label="Delete"
                                                    className=" w-full px-5 border-0 p-3 ml-3 border-round-md bg-red-500"
                                                    onClick={handleDelete}
                                                />
                                            </div>
                                        </div>
                                    </p>
                                </Dialog> : null
                        }
                    </div>
                </ContentArea>
            }
        </>
    )
}

export default ManageClientPaCode;
