import React, { useEffect, useState } from "react";
import InputField from "../../../components/input/input";
import ContentArea from "../shared/ContentArea";
import { Card } from "primereact/card";
import CancelButton from "../../../components/Buttons/CancelButton";
import SaveButton from "../../../components/Buttons/SaveButton";
import { Calendar } from 'primereact/calendar';
import { ProgressSpinner } from 'primereact/progressspinner';
import DropdownField from "../../../components/input/dropdown";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getServiceAction } from "../../../Redux/HospitalAction/serviceProviderAction";
import { addPaCodeAction } from "../../../Redux/HospitalAction/paCodeAction";
import moment from "moment";
export default function AddPaCode() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [data, setData] = useState({
        pa_code: "",
        service_type_id: "",
        start_date: "",
        end_date: ""
    })
    const [error, setError] = useState("")
    const [service, setService] = useState(null)
    const [value, setValue] = useState("")
    const [startDates, setStartDate] = useState(null)
    const [endDates, setEndDate] = useState(null)
    useEffect(() => {
        dispatch(getServiceAction())
    }, [])
    const serviceDatalist = useSelector((state) => state.getService?.getServiceProfile)
    const isLoading = useSelector((state) => state.loader.isLoading)
    useEffect(() => {
        setService(serviceDatalist)
    }, [serviceDatalist])
    useEffect(() => {
        setError({
            ...error,
            service_type_id: null
        })
        setData({
            ...data,
            service_type_id: value?.id
        })
    }, [value])
    useEffect(() => {
        if (startDates !== null) {
            let date1 = new Date(startDates)
            let year = date1.getFullYear();
            let month = date1.getMonth() + 1;
            let dt = date1.getDate();
            if (dt < 10) {
                dt = '0' + dt;
            }
            if (month < 10) {
                month = '0' + month;
            }

            let dob = year + '-' + month + '-' + dt;
            var startDate = dob
            var endDate = data.end_date
            const isSameOrAfter = moment(startDate).isSameOrAfter(endDate);
            if (isSameOrAfter === true) {
                setError({
                    ...error,
                    end_date:"End date must be greater than Start date"
                })
                setStartDate(null)
            }
            else {
                setData({
                    ...data,
                    start_date: dob
                })
                setError({
                    ...error,
                    end_date:null
                })
            }

        }
    }, [startDates])
    useEffect(() => {
        if (endDates !== null) {
            let date1 = new Date(endDates)
            let year = date1.getFullYear();
            let month = date1.getMonth() + 1;
            let dt = date1.getDate();
            if (dt < 10) {
                dt = '0' + dt;
            }
            if (month < 10) {
                month = '0' + month;
            }
            let dob = year + '-' + month + '-' + dt;;
            var startDate = data.start_date
            var endDate = dob
            const isSameOrAfter = moment(startDate).isSameOrAfter(endDate);
            if (isSameOrAfter === true) {
                setEndDate(null)
                setError({
                    ...error,
                    end_date:"End date must be greater than Start date"
                })
            }
            else {
                setData({
                    ...data,
                    end_date: dob
                })
                setError({
                    ...error,
                    end_date:null
                })
            }

        }


    }, [endDates])
    const handleSubmit = () => {
        if (data.pa_code === "" || data.service_type_id === "") {
            setError({
                ...error,
                pa_code: data.pa_code === "" ? "Please Enter PA Code" : error.pa_code,
                service_type_id: data.service_type_id === "" ? "Please Choose Service" : error.serviceType
            })
            return false;
        }
        const obj = data;
        let array = Object.entries(obj)
        const formData = new FormData()
        for (let i = 0; i < array.length; i++) {
            if (array[i][0] === "service") {
                formData.append(array[i][0], JSON.stringify(array[i][1]))
            } else {
                formData.append(array[i][0], array[i][1])
            }
        }
        dispatch(addPaCodeAction(formData, () => navigate("/account-owner/manage-pa-code")))
    }
    return (
        <>
            {isLoading === true ? <ProgressSpinner style={{ width: '50px', height: '50px' }} className='text-center h-screen flex justify-content-center' strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" /> :
                <ContentArea>
                    <div className="add_pa_code_container p-3 ">
                        <div className="grid mb-5 mr-0 ml-0 align-items-center bg-main-light border-round-lg shadow-1 flex justify-content-evenly">
                            <div className="md:col-12 ">
                                <h1 className="text-main ml-3 " style={{ fontSize: "24px" }}>
                                    Add PA Code
                                </h1>
                            </div>
                        </div>
                        <Card className="shadow-2 p-3 border-round-lg">
                            <div className="p-fluid grid flex mb-5 ">
                                <div className="col-12 md:col-6 ">
                                    <InputField label="PA Code" required
                                        onChange={(e) => {
                                            setError({
                                                ...error,
                                                pa_code: null
                                            })
                                            setData({
                                                ...data,
                                                pa_code: e.target.value
                                            })
                                        }}
                                        value={data?.pa_code}
                                        errorMessage={error.pa_code}
                                        className="border-round-md mt-2"
                                    />
                                </div>
                                <div className="col-12 md:col-6">
                                    <DropdownField label="Service Type" className="border-round-md mt-2" inputId="dropdown" errorMessage={error.service_type_id} options={service} optionLabel="program_name" placeholder="Select" onChange={(e) => setValue(e.value)} value={value} required />
                                </div>
                                <div className="col-12 md:col-6">
                                    <label className="font block mb-2">Start Date <cod className="text-red">*</cod></label>
                                    <Calendar className="calender_picker border-round-md" inputClassName="border-round-md" value={startDates} onChange={(e) => setStartDate(e.value)} />
                                </div>
                                <div className="col-12 md:col-6 ">
                                    <label className="font block mb-2">End Date <cod className="text-red"></cod></label>
                                    <Calendar className="calender_picker border-round-md" inputClassName="border-round-md" value={endDates} onChange={(e) => setEndDate(e.value)} />
                                    {error.end_date && <span className="error">{error.end_date}</span>}
                                </div>
                                <div className="col-12 md:col-4 mt-1">
                                    <div className="submitButton flex mt-3">
                                        <SaveButton label="Save" onClick={handleSubmit}></SaveButton>
                                        <CancelButton label="Cancel" onClick={() => navigate("/account-owner/manage-pa-code")}></CancelButton>
                                    </div>
                                </div>
                            </div>
                        </Card>
                    </div>
                </ContentArea>
            }
        </>
    )
}
