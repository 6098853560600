

import React, { useEffect, useState } from "react";
import InputField from "../../../../components/input/input";
import { Card } from "primereact/card";
import ContentArea from "../../../../shared/ContentArea";
import SaveButton from "../../../../components/Buttons/SaveButton";
import CancelButton from "../../../../components/Buttons/CancelButton";
import DropdownField from "../../../../components/input/dropdown";
import { viewClientPaCodeAction, editClientPaCodeAction, getClientAction } from "../../../../Redux/HospitalAction/clientAction";
import { getPaCodesAction } from "../../../../Redux/HospitalAction/paCodeAction";
import { getServiceAction } from "../../../../Redux/HospitalAction/serviceProviderAction";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import Loader from "../../../../pages/Loader";
const EditClientPaCode = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [Service, Setservice] = useState(null);
  const [client, setClient] = useState(null);
  const [pacode, setPaCode] = useState(null);
  const [pacodeData, setPaCodeData] = useState(null)
  const [error, setError] = useState("")
  const [data, setData] = useState({
    client_id: "",
    pa_code_id: "",
    annual_allotment: "",
    no_of_unit: "",
    max_day_allowed: "",
    client_name: ""
  })
  const { id } = useParams()
  useEffect(() => {
    dispatch(viewClientPaCodeAction(id))
    dispatch(getClientAction())
    dispatch(getPaCodesAction())
    dispatch(getServiceAction())
  }, [])
  const getViewData = useSelector((state) => state.viewClientPaCode.viewClientPaCodeProfile)
  const getallClient = useSelector((state) => state.getClient.getClientProfile)
  const getPaCode = useSelector((state) => state.getPaCode.getPaCodeProfile)
  const getService = useSelector((state) => state.getService.getServiceProfile)
  useEffect(() => {
    setData(getViewData)
  }, [getViewData])
  useEffect(() => {
    let array = []
    getPaCode?.length > 0 && getPaCode.map((item, index) => {
      let obj = {
        id: item.id,
        pa_code: item.pa_code,
        service_type_id: item.service_type_id
      }
      array.push(obj)
    })
    setPaCodeData(array)
  }, [getPaCode])
  useEffect(() => {
    getallClient?.length > 0 && getallClient.map((item, index) => {
      if (item.id == getViewData.client_id) {
        setClient(item)
      }
    })
    pacodeData?.length > 0 && pacodeData.map((item, index) => {
      if (item.id == getViewData.pa_code_id) {
        setPaCode(item)
      }
    })
    getService?.length > 0 && getService.map((item) => {
      if (item.id == getViewData.service_plan_id) {
        Setservice(item)
      }
    })
  }, [getallClient, getViewData, pacodeData, getService])
  useEffect(() => {
    setData({
      ...data,
      client_id: client?.id,
      client_name: client?.name
    })
  }, [client])
  useEffect(() => {
    setData({
      ...data,
      pa_code_id: pacode?.id
    })
  }, [pacode])
  useEffect(() => {
    getService?.length > 0 && getService.map((item, index) => {
      if (item.id == pacode?.service_type_id) {
        Setservice(item)
      }
    })
  }, [pacode, getService])
  const handleChange = (e) => {
    const { name, value } = e.target
    setError({
      ...error,
      [name]: null
    })
    setData({
      ...data,
      [name]: value
    })
  }
  const handleSubmit = () => {
    if (data.annual_allotment === "" || data.no_of_unit === "" || data.max_day_allowed === "") {
      setError({
        ...error,
        annual_allotment: data.annual_allotment === "" ? "Please Enter Annual Allotment" : error.annual_allotment,
        no_of_unit: data.no_of_unit === "" ? "Please Enter Number Of Unit" : error.no_of_unit,
        max_day_allowed: data.max_day_allowed === "" ? "Please Enter Max Days" : error.max_day_allowed
      })
      return false;
    }
    const obj = data;
    let array = Object.entries(obj)
    const formData = new FormData()
    for (let i = 0; i < array.length; i++) {
      formData.append(array[i][0], array[i][1])
    }
    dispatch(editClientPaCodeAction(id, formData, () => navigate("/account-owner/manage-client-pa-codes")))
  }
  const isLoading = useSelector((state) => state.loader.isLoading)
  return (
    <>
      {isLoading === true ? <Loader /> :
        <ContentArea>
          <div className="edit_client_pa_code_container">
            <div className="p-3">
              <div className="grid card ml-0 mr-0 px-5 mb-5 align-items-center bg-main-light border-round-lg">
                <h2 className="text-main">
                  Edit Client PA Codes
                </h2>
              </div>
              <Card className="shadow-2 p-3 border-round-lg">
                <div className="p-fluid grid mb-5">
                  <div className="field col-12 md:col-6">
                    <DropdownField label="Select Client"
                      placeholder="Select"
                      value={client} options={getallClient}
                      onChange={(e) => setClient(e.value)} optionLabel="profile_id" required />

                  </div>
                  <div className="field col-12 md:col-6">
                    <InputField placeholder="" label="Client Name" value={data.client_name} disabled />
                  </div>

                  <div className="field col-12 md:col-6">
                    <DropdownField label="PA Code"
                      placeholder="Select"
                      value={pacode} options={pacodeData}
                      onChange={(e) => setPaCode(e.value)} optionLabel="pa_code" required />
                  </div>

                  <div className="field col-12 md:col-6">
                    <InputField placeholder="" label="Annual Allotments" value={data.annual_allotment} required onChange={handleChange} name="annual_allotment" />
                  </div>
                  <div className="field col-12 md:col-4">
                    <InputField placeholder="" label="No. of Units Per Hour" value={data.no_of_unit} required onChange={handleChange} name="no_of_unit" />
                  </div>
                  <div className="field col-12 md:col-4">
                    <InputField placeholder="" label="Max Days Allowed Per Month" value={data.max_day_allowed} required onChange={handleChange} name="max_day_allowed" />
                  </div>
                  <div className="field col-12 md:col-4">
                    <DropdownField label="Select Service Type"
                      inputId="dropdown"
                      placeholder="Select"
                      value={Service}
                      options={getService}
                      onChange={(e) => Setservice(e.value)} optionLabel="program_name" disabled required />
                  </div>

                </div>
                <div className="submitButton flex">
                  <SaveButton label="Save" onClick={handleSubmit}></SaveButton>
                  <CancelButton label="Cancel" onClick={() => navigate("/account-owner/manage-client-pa-codes")}></CancelButton>
                </div>
              </Card>
            </div>
          </div>
        </ContentArea>
      }
    </>
  )
}

export default EditClientPaCode;
