import axios from "axios";
const config = require("../envirement/development").config;
export const LoginAction = async ({ payload }) => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.post(
        `${config.backEnd}/api/sales/login`,
        payload
      );
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};

export const getLeadsAction = async ({ payload }) => {
  const token1 = localStorage.getItem("token");
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.post(
        `${config.backEnd}/api/sales/get-lead`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token1}`,
          },
        }
      );
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};

export const addLeadsAction = ({ payload }) => {
  const token = localStorage.getItem("token");
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.post(
        `${config.backEnd}/api/sales/add-lead`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};

export const viewLeads = (id) => {
  const token = localStorage.getItem("token");
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.get(
        `${config.backEnd}/api/sales/view-lead/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};

export const deleteLeads = (id) => {
  const token = localStorage.getItem("token");
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.get(
        `${config.backEnd}/api/sales/delete-lead/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};

export const editLeads = ({ id, payload }) => {
  const token = localStorage.getItem("token");
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.post(
        `${config.backEnd}/api/sales/edit-lead/${id}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};

export const getDiscount = () => {
  const token = localStorage.getItem("token");
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.get(`${config.backEnd}/api/sales/discount`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};

export const getcommission = ({ payload }) => {
  const token = localStorage.getItem("token");
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.post(
        `${config.backEnd}/api/sales/commission`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};

export const addClient = ({ payload }) => {
  const token = localStorage.getItem("token");
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.post(
        `${config.backEnd}/api/sales/add-client`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};

export const getSubscription = () => {
  const token = localStorage.getItem("token");
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.get(
        `${config.backEnd}/api/admin/get-subscription`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};

export const getClientAction = async ({ payload }) => {
  const token1 = localStorage.getItem("token");
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.post(
        `${config.backEnd}/api/sales/get-client`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token1}`,
          },
        }
      );
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};

export const viewClientAction = async (id) => {
  const token1 = localStorage.getItem("token");
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.get(
        `${config.backEnd}/api/sales/view-client/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token1}`,
          },
        }
      );
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};

export const deleteAction = async (id) => {
  const token1 = localStorage.getItem("token");
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.get(
        `${config.backEnd}/api/sales/delete-client/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token1}`,
          },
        }
      );
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};

export const editClient = ({ id, payload }) => {
  const token = localStorage.getItem("token");
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.post(
        `${config.backEnd}/api/sales/edit-client/${id}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};

export const forgetPasswordEmail = ({ payload }) => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.post(
        `${config.backEnd}/api/sales/email-otp`,
        payload
      );
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};
export const verifyOtps = ({ payload }) => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.post(
        `${config.backEnd}/api/sales/verify-otp`,
        payload
      );
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};

export const NewPasswordChange = ({ payload }) => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.post(
        `${config.backEnd}/api/sales/reset-password`,
        payload
      );
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};

export const getDashboard = () => {
  const token = localStorage.getItem("token");
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.get(`${config.backEnd}/api/sales/dashboard`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};

export const getCarrier = () => {
  const token = localStorage.getItem("token");
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.get(
        `${config.backEnd}/api/admin/get-carrier`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};
export const CompanyDetails = () => {
  const token = localStorage.getItem("token");
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.get(
        `${config.backEnd}/api/accounts/get-setting/company`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};
export const LocationDetails = () => {
  const token = localStorage.getItem("token");
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.get(
        `${config.backEnd}/api/accounts/get-setting/location`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};
export const LeaveDeatils = () => {
  const token = localStorage.getItem("token");
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.get(
        `${config.backEnd}/api/accounts/get-setting/leave`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return resolve(response.data);
    } catch (err) {
      return reject(err);
    }
  });
};
