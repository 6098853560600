import { types } from "../HospitalType/types";
import HospitalConstants from "../../Services/HospitalConstants";
import { HospitalApi } from "../../Services/HospitalApi";
import { hideLoaderAction, showLoaderAction } from "./loaderAction";
import { showToast } from "./toastAction";

export const timeClockAction = (data,next) => async (dispatch) => {
    dispatch(showLoaderAction())
    const res = await HospitalApi("post", HospitalConstants.END_POINT.TIME_CLOCK, data);
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.TIME_CLOCK,
                payload: res.data
            })
        }
        dispatch(hideLoaderAction())
        next()
        dispatch(showToast({ severity: "success", summary: res.message }))
    } else {
        dispatch(hideLoaderAction())
        dispatch(showToast({ severity: "error", summary: res.message }))
    }
}