import { types } from "../HospitalType/types";

const intitalState = {
    getClientTierProfile: {},
    deleteClientTierProfile: {},
    addClientTierProfile: [],
    editClientTierProfile: {},
    getClientProfile: {},
    viewClientTierProfile: {},
    addClientProfile: {},
    deleteClientProfile: {},
    viewClientProfile: [],
    editClientProfile: [],
    getClientPaCodeProfile: {},
    addClientPaCodeProfile: [],
    deleteClientPaCodeProfile: {},
    viewClientPaCodeProfile: [],
    editClientPaCodeProfile: []
}


export const getClientTierReducer = (state = intitalState, action) => {
    switch (action.type) {
        case types.GET_TIERS:
            return {
                ...state,
                getClientTierProfile: action.payload
            };
        default:
            return { ...state }
    }
}

export const deleteClientTierReducer = (state = intitalState, action) => {
    switch (action.type) {
        case types.DELETE_TIERS:
            return {
                ...state,
                deleteClientTierReducer: action.payload
            };
        default:
            return { ...state }
    }
}

export const addClientTierReducer = (state = intitalState, action) => {
    switch (action.type) {
        case types.ADD_TIERS:
            return {
                ...state,
                addClientTierProfile: action.payload
            };
        default:
            return { ...state }
    }
}

export const editClientTierReducer = (state = intitalState, action) => {
    switch (action.type) {
        case types.EDIT_TIERS:
            return {
                ...state,
                editClientTierProfile: action.payload
            };
        default:
            return { ...state }
    }
}

export const getClientReducer = (state = intitalState, action) => {
    switch (action.type) {
        case types.GET_CLIENT:
            return {
                ...state,
                getClientProfile: action.payload
            };
        default:
            return {
                ...state
            }
    }
}

export const viewClientTierReducer = (state = intitalState, action) => {
    switch (action.type) {
        case types.VIEW_TIERS:
            return {
                ...state,
                viewClientTierProfile: action.payload
            };
        default:
            return { ...state }
    }
}

export const addClientReducer = (state = intitalState, action) => {
    switch (action.type) {
        case types.ADD_CLIENT:
            return {
                ...state,
                addClientProfile: action.payload
            }
        default:
            return { ...state }
    }
}

export const deleteClientReducer = (state = intitalState, action) => {
    switch (action.type) {
        case types.DELETE_CLIENT:
            return {
                ...state,
                deleteClientProfile: action.payload
            }
        default:
            return { ...state }
    }
}
export const viewClientReducer = (state = intitalState, action) => {
    switch (action.type) {
        case types.VIEW_CLIENT:
            return {
                ...state,
                viewClientProfile: action.payload
            }
        default:
            return { ...state }
    }
}
export const editClientReducer = (state = intitalState, action) => {
    switch (action.type) {
        case types.EDIT_CLIENT:
            return {
                ...state,
                editClientProfile: action.payload
            }
        default:
            return { ...state }
    }
}

export const getClientPaCodeReducer = (state = intitalState, action) => {
    switch (action.type) {
        case types.GET_CLIENT_PA_CODE:
            return {
                ...state,
                getClientPaCodeProfile: action.payload
            }
        default:
            return { ...state }
    }
}

export const addClientPaCodeReducer = (state = intitalState, action) => {
    switch (action.type) {
        case types.ADD_CLIENT_PA_CODE:
            return {
                ...state,
                addClientPaCodeProfile: action.payload
            }
        default:
            return { ...state }
    }
}

export const deleteClientPaCodeReducer = (state = intitalState, action) => {
    switch (action.type) {
        case types.DELETE_CLIENT_PA_CODE:
            return {
                ...state,
                deleteClientPaCodeProfile: action.payload
            }
        default:
            return { ...state }
    }
}


export const viewClientPaCodeReducer = (state = intitalState, action) => {
    switch (action.type) {
        case types.VIEW_CLIENT_PA_CODE:
            return {
                ...state,
                viewClientPaCodeProfile: action.payload
            }
        default:
            return { ...state }
    }
}

export const editClientPaCodeReducer = (state = intitalState, action) => {
    switch (action.type) {
        case types.EDIT_CLIENT_PA_CODE:
            return {
                ...state,
                editClientPaCodeProfile: action.payload
            }
        default:
            return { ...state }
    }
}