import React, { useEffect, useState } from "react";
import { Card } from "primereact/card";
import ContentArea from "../../../../shared/ContentArea";
import SaveButton from "../../../../components/Buttons/SaveButton";
import CancelButton from "../../../../components/Buttons/CancelButton";
import { Dropdown } from "primereact/dropdown";
import InputField from "../../../../components/input/input";
import { CommonManageService } from "./CommonManageService";
import Loader from "../../../../pages/Loader";
import { appoitmnetGetServiceAction } from "../../../../Redux/HospitalAction/appoinmentAction";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

const AddManageService = () => {
  const dispatch = useDispatch();
  const {
    getAppointmentData,
    form,
    setForm,
    handleSubmit,
    navigate,
    isLoading,
    error,
    inputs,
    setInputs,
    setError,
  } = CommonManageService();
  const { id } = useParams();
  const handleAddInput = () => {
    setInputs([
      ...inputs,
      { appointment_scheduling_id: "", duration: "", options: [], errors: {} },
    ]);
  };
  useEffect(() => {
    if (!id) {
      setForm([
        {
          appointment_scheduling_id: "",
          duration: "",
          options: [],
          errors: {},
        },
      ]);
      setInputs([
        {
          appointment_scheduling_id: "",
          duration: "",
          options: [],
          errors: {},
        },
      ]);
    }
  }, [id]);

  const handleChange = async (event, index, name) => {
    if (name === "appointment_scheduling_id") {
      let durationOptions = await dispatch(
        appoitmnetGetServiceAction(event.value)
      );
      let onChangeValue = inputs.map((item, i) =>
        i === index
          ? {
              ...item,
              [name]: event.value,
              options: durationOptions,
              errors: { ...item.errors, [name]: "" },
            }
          : item
      );
      setInputs(onChangeValue);
    } else {
      let onChangeValue = inputs.map((item, i) =>
        i === index
          ? {
              ...item,
              [name]: event.value,
              errors: { ...item.errors, [name]: "" },
            }
          : item
      );
      setInputs(onChangeValue);
    }
  };

  const handleDeleteInput = (index) => {
    const newArray = [...inputs];
    newArray.splice(index, 1);
    setInputs(newArray);
  };

  return (
    <>
      {isLoading === true ? (
        <Loader />
      ) : (
        <ContentArea>
          <div className="add_manage_service_container">
            <div className="p-3">
              <div className="grid card ml-0 mr-0 px-5 mb-5 align-items-center bg-main-light border-round-lg">
                <h2 className="text-main">Service List</h2>
              </div>
              <Card className="shadow-2 p-3 border-round-lg">
                <div className="col-12 md:col-5 ">
                  <label className="font block ">
                    Service Name<code className="text-red">*</code>{" "}
                  </label>
                  <InputField
                    name="service_name"
                    value={form?.service_name}
                    onChange={(e) => {
                      setForm({
                        ...form,
                        service_name: e.target.value,
                      });
                      setError({
                        ...error,
                        service_name: null,
                      });
                    }}
                  />
                  {error?.service_name && (
                    <span className="error">{error?.service_name}</span>
                  )}
                </div>
                <div className="grid w-full px-2">
                  {inputs?.map((item, index) => (
                    <div
                      className="input_container flex w-full align-items-center"
                      key={index}
                    >
                      <div className="col-5">
                        <label className="font block mb-2">
                          Schedule<code className="text-red">*</code>
                        </label>
                        <Dropdown
                          value={item?.appointment_scheduling_id}
                          onChange={(e) => {
                            handleChange(e, index, "appointment_scheduling_id");
                            // setError({
                            //   ...error,
                            //   appointment_scheduling_id: null,
                            // });
                          }}
                          options={getAppointmentData.map((item) =>
                            inputs.filter(
                              (i) => i.appointment_scheduling_id === item.id
                            ).length
                              ? {
                                  value: item.id,
                                  name: item.appointment_name,
                                  disabled: true,
                                }
                              : {
                                  value: item.id,
                                  name: item.appointment_name,
                                }
                          )}
                          optionLabel="name"
                          placeholder="Select"
                          className="w-full border-round-md mb-2"
                        />

                        {item.errors?.appointment_scheduling_id && (
                          <span className="error">
                            {item.errors?.appointment_scheduling_id}
                          </span>
                        )}
                      </div>
                      <div className="col-5">
                        <label className="font block mb-2">
                          Service Duration<code className="text-red">*</code>
                        </label>
                        <Dropdown
                          // disabled={!form.appointment_scheduling_id}
                          value={item?.duration}
                          onChange={(e) => {
                            handleChange(e, index, "duration");
                            setError({
                              ...error,
                              duration: null,
                            });
                          }}
                          options={item?.options?.map((item) => {
                            return {
                              name: item?.time_slot_duration,
                              value: item?.time_slot_duration,
                            };
                          })}
                          optionLabel="name"
                          placeholder="Select"
                          className="w-full border-round-md mb-2"
                        />
                        {item.errors?.duration && (
                          <span className="error">{item.errors?.duration}</span>
                        )}
                      </div>
                      <div>
                        {inputs.length > 1 && (
                          <i
                            className="pi pi-minus-circle text-red-600 font-bold mx-2 mt-4"
                            onClick={() => handleDeleteInput(index)}
                          ></i>
                        )}
                        {index === inputs.length - 1 && (
                          <i
                            className="pi pi-plus-circle text-green-400 font-bold mt-4"
                            onClick={() => handleAddInput()}
                          ></i>
                        )}
                      </div>
                    </div>
                  ))}
                </div>

                {/* <div className="col-12 md:col-6">
                    <label className="font block mb-2">
                      Schedule<code className="text-red">*</code>
                    </label>
                    <Dropdown
                      value={form?.appointment_scheduling_id}
                      onChange={(e) => {
                        setForm({
                          ...form,
                          appointment_scheduling_id: e.value,
                        });
                        setError({
                          ...error,
                          appointment_scheduling_id: null,
                        });
                      }}
                      options={getAppointmentData}
                      optionLabel="appointment_name"
                      placeholder="Select"
                      className="w-full border-round-md mb-2"
                    />
                  
                    {error?.appointment_scheduling_id && (
                      <span className="error">
                        {error?.appointment_scheduling_id}
                      </span>
                    )}
                  </div> */}
                {/* <div className="p-fluid grid mb-5 p-0 m-0">
                  <div className="col-12 md:col-6 h-20rem">
                    <label className="font block mb-2">
                      Service Duration<code className="text-red">*</code>
                    </label>
                    <Dropdown
                      disabled={!form.appointment_scheduling_id}
                      value={form?.duration}
                      onChange={(e) => {
                        setForm({
                          ...form,
                          duration: e.value,
                        });
                        setError({
                          ...error,
                          duration: null,
                        });
                      }}
                      options={getServiceData.map((item) => {
                        return {
                          duration: item.duration,
                          value: item.duration,
                        };
                      })}
                      optionLabel="duration"
                      placeholder="Select"
                      className="w-full border-round-md mb-2"
                    />
                    {error?.duration && (
                      <span className="error">{error?.duration}</span>
                    )}
                 
                  </div>
                </div> */}
                <div className="submitButton flex p-1 gap-3">
                  <SaveButton label="Save" onClick={handleSubmit}></SaveButton>
                  <CancelButton
                    label="Cancel"
                    onClick={() => {
                      navigate("/account-owner/manage-services");
                      setInputs([
                        {
                          appointment_scheduling_id: "",
                          duration: "",
                          options: [],
                        },
                      ]);
                    }}
                  ></CancelButton>
                </div>
              </Card>
            </div>
          </div>
        </ContentArea>
      )}
    </>
  );
};

export default AddManageService;
