
import React, { useEffect, useState } from "react";
import InputField from "../../../../components/input/input";
import { useDispatch, useSelector } from "react-redux";
import ContentArea from "../../../../shared/ContentArea";
import { Card } from "primereact/card";
import { viewClientPaCodeAction, getClientAction } from "../../../../Redux/HospitalAction/clientAction";
import { getPaCodesAction } from "../../../../Redux/HospitalAction/paCodeAction";
import DropdownField from "../../../../components/input/dropdown";
import { useParams } from "react-router-dom";
import { getServiceAction } from "../../../../Redux/HospitalAction/serviceProviderAction";
import Loader from "../../../../pages/Loader";
const ViewClientPaCode = () => {
  const dispatch = useDispatch()
  const [Service, Setservice] = useState(null);
  const [client, setClient] = useState(null);
  const [pacode, setPaCode] = useState(null);
  const [pacodeData, setPaCodeData] = useState(null)
  const { id } = useParams()
  useEffect(() => {
    dispatch(viewClientPaCodeAction(id))
    dispatch(getClientAction())
    dispatch(getPaCodesAction())
  }, [])
  const getViewData = useSelector((state) => state.viewClientPaCode.viewClientPaCodeProfile)
  const getallClient = useSelector((state) => state.getClient.getClientProfile)
  const getPaCode = useSelector((state) => state.getPaCode.getPaCodeProfile)
  const getService = useSelector((state) => state.getService.getServiceProfile)

  useEffect(() => {
    getallClient?.length > 0 && getallClient.map((item, index) => {
      if (item.id == getViewData.client_id) {
        setClient(item)
      }
    })
    pacodeData?.length > 0 && pacodeData.map((item, index) => {
      if (item.id == getViewData.pa_code_id) {
        setPaCode(item)
      }
    })
    getService?.length > 0 && getService.map((item) => {
      if (item.id == getViewData.service_plan_id) {
        Setservice(item)
      }
    })
  }, [getallClient, getViewData, pacodeData, getService])
  useEffect(() => {
    let array = []
    getPaCode?.length > 0 && getPaCode.map((item, index) => {
      let obj = {
        id: item.id,
        pa_code: item.pa_code,
        service_type_id: item.service_type_id
      }
      array.push(obj)
    })
    setPaCodeData(array)
  }, [getPaCode])
  const isLoading = useSelector((state) => state.loader.isLoading)
  return (
    <>
      {isLoading === true ? <Loader /> :
        <ContentArea>
          <div className="view_client_pa_code_container">
            <div className="p-3">
              <div className="grid card ml-0 mr-0 px-5 mb-5 align-items-center bg-main-light border-round-lg">
                <h2 className="text-main">
                  View Client PA Codes
                </h2>
              </div>
              <Card className="shadow-2 p-3 border-round-lg">
                <div className="p-fluid grid mb-5">

                  <div className="field col-12 md:col-6">
                    <DropdownField label="Select Client"
                      placeholder="Select"
                      value={client} options={getallClient}
                      onChange={(e) => setClient(e.value)} optionLabel="profile_id" disabled required />

                  </div>
                  <div className="field col-12 md:col-6">
                    <InputField placeholder="" label="Client Name" value={getViewData.client_name} disabled />
                  </div>

                  <div className="field col-12 md:col-6">
                    <DropdownField label="PA Code"
                      placeholder="Select"
                      value={pacode} options={pacodeData}
                      onChange={(e) => setPaCode(e.value)} optionLabel="pa_code" required disabled />
                  </div>

                  <div className="field col-12 md:col-6">
                    <InputField placeholder="" label="Annual Allotments" value={getViewData.annual_allotment} required disabled />
                  </div>
                  <div className="field col-12 md:col-4">
                    <InputField placeholder="" label="No. of Units Per Hour" value={getViewData.no_of_unit} required disabled />
                  </div>
                  <div className="field col-12 md:col-4">
                    <InputField placeholder="" label="Max Days Allowed Per Month" value={getViewData.max_day_allowed} required disabled />
                  </div>
                  <div className="field col-12 md:col-4">
                    <DropdownField label="Select Service Type"
                      inputId="dropdown"
                      placeholder="Select"
                      value={Service}
                      options={getService}
                      onChange={(e) => Setservice(e.value)} optionLabel="program_name" disabled required />
                  </div>

                </div>
              </Card>
            </div>
          </div>
        </ContentArea>
      }
    </>
  )
}

export default ViewClientPaCode;
