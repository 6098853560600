import React, { useEffect, useState } from "react";
import { InputSwitch } from "primereact/inputswitch";
import DropdownField from "../../../../components/input/dropdown";
import { LocationData } from "../../../../Redux/HospitalAction/appoinmentAction";
import { useSelector } from "react-redux";

const EmployeeTiming = ({
  title,
  index,
  items,
  setData,
  data,
  Location,
  error,
  setError,
}) => {
  const [date, setDate] = useState();
  const [endTimeSolts, setEndTimeSolts] = useState([]);
  const [startLunchTimeSlots, setStartLunchTimeSlots] = useState([]);
  const [endLunchTimeSlots, setEndLunchTimeSLots] = useState([]);

  const array = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  //build
  const handleChange = (e) => {
    let { name, value } = e.target;

    // let errorMsg = "";
    // if (name === "is_working") {
    //   if (value) errorMsg = "Start time and End time is required";
    //   else errorMsg = "";
    // }
    const dateClone = [...date];
    const endTimeClone = [...endTimeSolts];
    let newData =
      data.time_schedule?.length > 0 &&
      data?.time_schedule?.map((item, i) => {
        if (i === index) {
          if (name === "start_time") {
            let endTime = dateClone.slice(date.indexOf(value));
            endTime = [...endTime];
            setEndTimeSolts(endTime);
          }
          if (name === "end_time") {
            let startLunchTime = dateClone.slice(
              date.indexOf(item.start_time),
              date.indexOf(value)
            );
            startLunchTime = [...startLunchTime];
            setStartLunchTimeSlots(startLunchTime);
          }

          // if (item.start_time && item.end_time) {
          //   item.error.working_time = "";
          // }
          // if (name === "location_id") {
          //   setData({ ...data, [name]: e.value });
          // }
          if (name === "start_lunch_time") {
            let endLunchTime = date.slice(
              date.indexOf(value),
              date.indexOf(item.end_time)
            );
            endLunchTime = [...endLunchTime];
            setEndLunchTimeSLots(endLunchTime);
          }

          // if (item.start_lunch_time && item.end_lunch_time) {
          //   item.error.lunch_time = "";
          // }

          return {
            ...item,
            [name]: value,
          };
        } else {
          return {
            ...item,
          };
        }
      });

    setData((prev) => {
      return {
        ...prev,
        time_schedule: newData,
      };
    });
  };
  const generateTimeSlots = async (hh, mm, ss) => {
    const startTime = new Date();
    startTime.setHours(0, 0, 0);

    const endTime = new Date();
    endTime.setHours(23, 30, 0);

    const genTimeSlots = [];
    while (startTime <= endTime) {
      const hours = startTime.getHours().toString().padStart(2, "0");
      const minutes = startTime.getMinutes().toString().padStart(2, "0");
      let timeSlot = `${hours}:${minutes}`;
      genTimeSlots.push(timeSlot);
      startTime.setMinutes(startTime.getMinutes() + 60);
    }
    setEndTimeSolts(() => {
      return [...genTimeSlots];
    });
    setStartLunchTimeSlots(() => {
      return [...genTimeSlots];
    });
    setEndLunchTimeSLots(() => {
      return [...genTimeSlots];
    });
    setDate(genTimeSlots);
  };
  useEffect(() => {
    generateTimeSlots();
  }, []);

  return (
    <>
      <div className="p-fluid grid align-items-center flex justify-content-between ">
        <div className="col-8 text-center border-round-sm flex align-items-center gap-3 ">
          <p className="col-2  text-xl  p-2  bg-blue-100 border-round-md working_weak">
            {array[title]}
          </p>
          <div className="col-4">
            <DropdownField
              placeholder={"Select Location"}
              name="location_id"
              value={data?.time_schedule[index]?.location_id}
              onChange={handleChange}
              options={Location?.map((item) => ({
                name: item?.name,
                value: item?.id,
              }))}
              optionLabel={"name"}
            />
          </div>
        </div>

        <div className="col-12 md:col-4 ">
          <p className=" flex flex-column align-items-center justify-content-end working_weak">
            Working
            <span className=" ml-2">
              <InputSwitch
                checked={items?.is_working}
                onChange={handleChange}
                name="is_working"
              />
            </span>
          </p>
        </div>
      </div>
      <div className="p-fluid grid align-items-center flex mb-3">
        <div className="col  ">
          <p className="text-lg working_time"> Working Time:</p>
        </div>

        <div className="flex__child col-12 md:col-2 py-3 ml-0">
          <label className="font block mb-2 text-500">Start Time</label>
          <DropdownField
            placeholder="Start Time"
            name="start_time"
            value={items?.start_time}
            onChange={handleChange}
            options={date}
          />
        </div>

        <div className="flex__child col-12 md:col-2  py-3 m-0 ">
          <label className="font block mb-2 text-500">End Time</label>
          <DropdownField
            placeholder="End Time"
            name="end_time"
            value={items?.end_time}
            onChange={handleChange}
            options={endTimeSolts}
          />
        </div>
        <span className="text-red-500"> {items?.error?.working_time}</span>

        <div className="flex__child col-12 md:col-2 p-0 m-0  text-lg text-center ">
          <p className="text-lg working_time">Lunch Time:</p>
        </div>

        <div className="flex__child col-12 md:col-2 py-3 m-0 ">
          <label className="font block mb-2 text-500">Start Time</label>
          <DropdownField
            placeholder="Start Time"
            name="start_lunch_time"
            value={items?.start_lunch_time}
            onChange={handleChange}
            options={startLunchTimeSlots}
          />
        </div>

        <div className="flex__child col-12 md:col-2 py-3  ">
          <label className="font block mb-2 text-500">End Time</label>
          <DropdownField
            placeholder="End Time"
            name="end_lunch_time"
            value={items?.end_lunch_time}
            onChange={handleChange}
            options={endLunchTimeSlots}
          />
        </div>
        <span className="text-red-500"> {items?.error?.lunch_time}</span>
      </div>
    </>
  );
};

export default EmployeeTiming;
