import { types } from "../HospitalType/types";

const intitalState = {
  getEmployeeProfile: [],
  addEmployeeProfile: {},
  editEmployeeProfile: [],
  deleteEmployeeProfile: {},
  viewEmployeeProfile: [],
  getPermissionProfile: [],
  getHourlyRateProfile: [],
  deleteHourlyRateProfile: [],
  addHourlyRateProfile: [],
  getSelectedProfile: [],
  viewHourlyProfile: [],
  editHourlyProfile: {},
  addEmployeeSchedulingProfile: [],
  addSpecialEmployeeScheduling: [],
  getEmployeeSchedulingProfile: [],
  deleteEmployeeSchedulingProfile: [],
  viewEmployeeSchedulingProfile: [],
  editEmployeeSchedulingProfile: [],
};

export const getEmployeeReducer = (state = intitalState, action) => {
  switch (action.type) {
    case types.GET_EMPLOYEE:
      return {
        ...state,
        getEmployeeProfile: action.payload,
      };
    default:
      return { ...state };
  }
};

export const addEmployeeReducer = (state = intitalState, action) => {
  switch (action.type) {
    case types.ADD_EMPLOYEE:
      return {
        ...state,
        addEmployeeProfile: action.payload,
      };
    default:
      return { ...state };
  }
};

export const editEmployeeReducer = (state = intitalState, action) => {
  switch (action.type) {
    case types.EDIT_EMPLOYEE:
      return {
        ...state,
        editEmployeeProfile: action.payload,
      };
    default:
      return { ...state };
  }
};

export const deleteEmployeeReducer = (state = intitalState, action) => {
  switch (action.type) {
    case types.DELETE_EMPLOYEE:
      return {
        ...state,
        deleteEmployeeProfile: action.payload,
      };
    default:
      return { ...state };
  }
};

export const viewEmployeeReducer = (state = intitalState, action) => {
  switch (action.type) {
    case types.VIEW_EMPLOYEE:
      return {
        ...state,
        viewEmployeeProfile: action.payload,
      };
    default:
      return { ...state };
  }
};

export const getPermissionReducer = (state = intitalState, action) => {
  switch (action.type) {
    case types.GET_PERMISSION:
      return {
        ...state,
        getPermissionProfile: action.payload,
      };
    default:
      return { ...state };
  }
};

export const getHourlyRateReducer = (state = intitalState, action) => {
  switch (action.type) {
    case types.GET_HOURLY_RATE:
      return {
        ...state,
        getHourlyRateProfile: action.payload,
      };
    default:
      return { ...state };
  }
};

export const deleteHourlyRateReducer = (state = intitalState, action) => {
  switch (action.type) {
    case types.DELETE_HOURLY_RATE:
      return {
        ...state,
        deleteHourlyRateProfile: action.payload,
      };
    default:
      return { ...state };
  }
};
export const addHourlyRateRducer = (state = intitalState, action) => {
  switch (action.type) {
    case types.ADD_HOURLY_RATE:
      return {
        ...state,
        addHourlyRateProfile: action.payload,
      };
    default:
      return { ...state };
  }
};

export const getSelectedEmployeeReducer = (state = intitalState, action) => {
  switch (action.type) {
    case types.GET_SELECTED_EMPLOYEE:
      return {
        ...state,
        getSelectedProfile: action.payload,
      };
    default:
      return { ...state };
  }
};

export const viewHourlyEmployeeReducer = (state = intitalState, action) => {
  switch (action.type) {
    case types.VIEW_HOURLY_RATE:
      return {
        ...state,
        viewHourlyProfile: action.payload,
      };
    default:
      return { ...state };
  }
};

export const editHourlyEmployeeReducer = (state = intitalState, action) => {
  switch (action.type) {
    case types.EDIT_HOURLY_RATE:
      return {
        ...state,
        editHourlyProfile: action.payload,
      };
    default:
      return { ...state };
  }
};

export const addEmployeeSchedulingReducer = (state = intitalState, action) => {
  switch (action.type) {
    case types.ADD_EMPLOYEE_SCHEDULING:
      return {
        ...state,
        addEmployeeSchedulingProfile: action.payload,
      };
    default:
      return { ...state };
  }
};
export const addSpecialEmployeeScheduling = (state = intitalState, action) => {
  switch (action.type) {
    case types.ADD_SPECIAL_EMPLOYEE_SCHEDULING:
      return {
        ...state,
        addSpecialEmployeeScheduling: action.payload,
      };
    default:
      return { ...state };
  }
};
export const getLeaveTypeReducer = (state = intitalState, action) => {
  switch (action.type) {
    case types.GET_LEAVE_TYPE:
      return {
        ...state,
        getLeaveType: action.payload,
      };
    default:
      return { ...state };
  }
};
export const getLeaveCategoryReducer = (state = intitalState, action) => {
  switch (action.type) {
    case types.GET_LEAVE_CATEGORY:
      return {
        ...state,
        getLeaveCategory: action.payload,
      };
    default:
      return { ...state };
  }
};
export const postSchedulingLeaveTypeReducer = (
  state = intitalState,
  action
) => {
  switch (action.type) {
    case types.POST_SCHEDULING_LEAVE:
      return {
        ...state,
        postSchedulingLeave: action.payload,
      };
    default:
      return { ...state };
  }
};
export const getSchedulingListingTypeReducer = (
  state = intitalState,
  action
) => {
  switch (action.type) {
    case types.GET_EMPLOYEE_SCHEDULING_LISTING:
      return {
        ...state,
        getEmployeeSchedulingListing: action.payload,
      };
    default:
      return { ...state };
  }
};
export const getEmployeeSchedulingReducer = (state = intitalState, action) => {
  switch (action.type) {
    case types.GET_EMPLOYEE_SCHEDULING:
      return {
        ...state,
        getEmployeeSchedulingProfile: action.payload,
      };
    default:
      return { ...state };
  }
};

export const deleteEmployeeSchedulingReducer = (
  state = intitalState,
  action
) => {
  switch (action.type) {
    case types.DELETE_EMPLOYEE_SCHEDULING:
      return {
        ...state,
        deleteEmployeeSchedulingProfile: action.payload,
      };
    default:
      return { ...state };
  }
};

export const viewEmployeeSchedulingReducer = (state = intitalState, action) => {
  switch (action.type) {
    case types.VIEW_EMPLOYEE_SCHEDULING:
      return {
        ...state,
        viewEmployeeSchedulingProfile: action.payload,
      };
    default:
      return { ...state };
  }
};

export const editEmployeeSchedulingReducer = (state = intitalState, action) => {
  switch (action.type) {
    case types.EDIT_EMPLOYEE_SCHEDULING:
      return {
        ...state,
        editEmployeeSchedulingProfile: action.payload,
      };
    default:
      return { ...state };
  }
};
