import { useState } from "react";
import SalesPersonDashboard from "../views/sales-person/dashboard";
import ManageLeads from "../views/sales-person/dashboard/leads";
import ManageClients from "../views/sales-person/dashboard/clients";
import DashBoardIcon from "../assets/icons/dashboard.png"
import LeadsIcon from "../assets/icons/leads icon.png"
import ClientsIcon from "../assets/icons/clients.png"
import ReportsIconIcon from "../assets/icons/reports.png"
import CommissionsReports from "../views/sales-person/dashboard/reports";
const Index = () => {
    const [activeComponent, setActiveComponent] = useState("")
    const changeComponet = (comp) => {
        setActiveComponent(comp)
    }
    function ShowComponent(e) {
        let allComponents = {
            SalesPersonDashboard,
            ManageLeads,
            ManageClients,
            CommissionsReports,

        }
        const Component = activeComponent ? allComponents[e] : null
        return <Component />
    }
    const allTabs = [
        {
            name: "Dashboard",
            link: "/sales-person/dashboard",
            icon: DashBoardIcon,
            iconHeight: "24px",
            iconWidth: "24px",
            subMenu: false,
        },
        {
            name: "Manage Leads",
            link: "/sales-person/manage-leads",
            icon: LeadsIcon,
            iconHeight: "24px",
            iconWidth: "24px",
            // subMenu: [

            //     { name: "Manage Leads", link: "/sales-person/manage-leads" },

            // ],
        },

        {
            name: "Manage Clients",
            link: "/sales-person/manage-clients",
            icon: ClientsIcon,
            iconHeight: "24px",
            iconWidth: "24px",
            // subMenu: [

            //     { name: "Manage Clients", link: "/sales-person/manage-clients" },

            // ],
        },

        {
            name: "Commission Reports",
            link: "/sales-person/commissions-reports",
            icon: ReportsIconIcon,
            iconHeight: "24px",
            iconWidth: "24px",
            // subMenu: [

            //     { name: "Commissions Reports", link: "/sales-person/commissions-reports" },
            // ],
            
        },
        

    ];
    return { allTabs, activeComponent, setActiveComponent, changeComponet, ShowComponent }
}
export default Index;