import React, { useEffect, useState } from "react";
import EditImg from "../../../../assets/icons/edit.png";
import DeleteImg from "../../../../assets/icons/delete.png";
import AddLocation from "./AddLocation";
import SaveButton from "../../../../components/Buttons/SaveButton";
import Table from "../../../../components/TableList/Table";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";

import { useDispatch, useSelector } from "react-redux";
import {
  DeletCompanyAction,
  LocationData,
} from "../../../../Redux/HospitalAction/appoinmentAction";

const Location = () => {
  const [data, setData] = useState({
    id: "",
    type: "location",
  });
  const dispatch = useDispatch();

  const [showAddLocation, setShowLAdd] = useState();
  const [TableData, setTableData] = useState();

  const companyLocation = useSelector(
    (state) => state.companyLocation.companyLocation
  );

  useEffect(() => {
    dispatch(LocationData());
  }, []);

  const DeleteAction = (data) => {
    dispatch(DeletCompanyAction(data));
  };

  useEffect(() => {
    setTableData(companyLocation);
  }, [companyLocation]);

  const locationBodyTemplate = (col) => {
    return (
      <div className="flex">
        <img
          src={EditImg}
          onClick={() => {
            setShowLAdd(true);
            setData({ id: col.id, name: col.name, address: col.address });
          }}
          width="20px"
          height="20px"
          className="product-image ml-3"
          alt=""
        />

        <img
          src={DeleteImg}
          onClick={() => {
            Delete({
              id: col.id,
              type: "location",
            });
          }}
          width="20px"
          height="20px"
          className="product-image ml-3"
          alt=""
        />
      </div>
    );
  };

  const TableHeading = [
    { field: "SrNo", header: "Sr. No.", body: "srnoTemplates" },
    { field: "name", header: "Location Name" },
    { field: "address", header: "Location Address" },
    { field: "icons", header: "Action", body: locationBodyTemplate },
  ];
  const Delete = (data) => {
    confirmDialog({
      message: "Do you want to delete this record?",
      header: "Delete Confirmation",
      icon: "pi pi-info-circle",
      defaultFocus: "reject",
      acceptClassName: "p-button-danger",
      accept: () => DeleteAction(data),
    });
  };

  return (
    <>
      <ConfirmDialog />
      {showAddLocation ? (
        <AddLocation
          setShowLAdd={setShowLAdd}
          setData={setData}
          data={data}
          companyLocation={companyLocation}
          LocationData={LocationData}
        />
      ) : (
        <div className="px-4">
          <div className="w-full flex justify-content-between align-items-center">
            <h2 className="">Location Details</h2>
            <SaveButton
              label={"Add Location"}
              save_button={false}
              onClick={() => setShowLAdd(true)}
              style={{
                height: "50px",
              }}
            ></SaveButton>
          </div>
          <div className="grid mt-2">
            <div className="md:col-12">
              <Table tableHeading={TableHeading} tableData={TableData} />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Location;
