import { types } from "../HospitalType/types";
import HospitalConstants from "../../Services/HospitalConstants";
import { HospitalApi } from "../../Services/HospitalApi";
import { showLoaderAction, hideLoaderAction } from "./loaderAction";
import { showToast } from "./toastAction";


export const sendOtpAction = (data, next) => async (dispatch) => {
    dispatch(showLoaderAction())
    const res = await HospitalApi("post", HospitalConstants.END_POINT.FORGETPASSWORD, data);
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.FORGET_PASSWORD,
                payload: res.data,
            });
        }
        dispatch(showToast({ severity: "success", summary: res.message }));
        next(true);
        dispatch(hideLoaderAction())
    } else {
        next(false)
        dispatch(showToast({ severity: "error", summary: res.message }));
        dispatch(hideLoaderAction())
    }
}


export const verifyOtpAction = (data, next) => async (dispatch) => {
    dispatch(showLoaderAction())
    const res = await HospitalApi("post", HospitalConstants.END_POINT.VERIFYOTP, data);
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.VERIFY_OTP,
                payload: res.data
            })
        }
        dispatch(showToast({ severity: "success", summary: res.message }));
        next(true)
        dispatch(hideLoaderAction())
    } else {
        next(false)
        dispatch(hideLoaderAction())
        dispatch(showToast({ severity: "error", summary: res.message }));
    }
}


export const resetPasswordAction = (data, next) => async (dispatch) => {
    dispatch(showLoaderAction())
    const res = await HospitalApi("post", HospitalConstants.END_POINT.RESETPASSWORD, data);
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.RESET_PASSWORD,
                payload: res.data
            })
        }
        dispatch(showToast({ severity: "success", summary: res.message }));
        next(true)
        dispatch(hideLoaderAction())
    } else {
        next(false)
        dispatch(hideLoaderAction())
        dispatch(showToast({ severity: "error", summary: res.message }))
    }
}