import React, { useEffect, useState } from "react";
import { Checkbox } from "primereact/checkbox";
import EmployeeTiming from "./EmployeeTiming";
import ContentArea from "../../shared/ContentArea";
import InputField from "../../../../components/input/input";
import { Card } from "primereact/card";
import SaveButton from "../../../../components/Buttons/SaveButton";
import CancelButton from "../../../../components/Buttons/CancelButton";
import DropdownField from "../../../../components/input/dropdown";
import {
  getEmployeeSchedulingAction,
  getEmployeesAction,
  getSelectedEmployeeAction,
} from "../../../../Redux/HospitalAction/employessAction";
import { useDispatch, useSelector } from "react-redux";
import { RouterProvider, useNavigate } from "react-router-dom";
import { addEmployeeSchedulingAction } from "../../../../Redux/HospitalAction/employessAction";
import Loader from "../../../../pages/Loader";
import { LocationData } from "../../../../Redux/HospitalAction/appoinmentAction";
const AddScheduling = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [CheckEmptyData, setCheckEmptyData] = useState();
  const [empID, setEmpId] = useState();
  const [Employe, SetEmploye] = useState(null);
  const [checked, setChecked] = useState(false);
  const [error, setError] = useState({});
  const [employeeData, setEmployeeData] = useState(null);
  const [location, setLocation] = useState();
  const [data, setData] = useState({
    employee_name: "",
    employee_id: "",
    is_repeat: "",
    time_schedule: [
      {
        day: 0,
        start_time: null,
        end_time: null,
        start_lunch_time: "",
        end_lunch_time: "",
        is_working: "",
        location_id: "",
        error: {},
      },
      {
        day: 1,
        start_time: null,
        end_time: null,
        start_lunch_time: "",
        end_lunch_time: "",
        is_working: "",
        location_id: "",
        error: {},
      },
      {
        day: 2,
        start_time: null,
        end_time: null,
        start_lunch_time: "",
        end_lunch_time: "",
        is_working: "",
        location_id: "",
        error: {},
      },
      {
        day: 3,
        start_time: null,
        end_time: null,
        start_lunch_time: "",
        end_lunch_time: "",
        is_working: "",
        location_id: "",
        error: {},
      },
      {
        day: 4,
        start_time: null,
        end_time: null,
        start_lunch_time: "",
        end_lunch_time: "",
        is_working: "",
        location_id: "",
        error: {},
      },
      {
        day: 5,
        start_time: null,
        end_time: null,
        start_lunch_time: "",
        end_lunch_time: "",
        is_working: "",
        location_id: "",
        error: {},
      },
      {
        day: 6,
        start_time: null,
        end_time: null,
        start_lunch_time: "",
        end_lunch_time: "",
        is_working: "",
        location_id: "",
        error: {},
      },
    ],
  });

  useEffect(() => {
    dispatch(getEmployeesAction());
  }, []);

  useEffect(() => {
    dispatch(getEmployeeSchedulingAction());
  }, []);

  const getData = useSelector(
    (state) => state.getEmployeeScheduling.getEmployeeSchedulingProfile
  );
  useEffect(() => {
    setEmpId(getData?.map((item) => item.employee_Id));
  }, [getData]);
  const getallEmployee = useSelector(
    (state) => state.getEmployee.getEmployeeProfile
  );

  const getSelectedEmployee = useSelector(
    (state) => state.getSelectedEmployee.getSelectedProfile
  );
  useEffect(() => {
    setEmployeeData(getallEmployee);
  }, [getallEmployee]);
  useEffect(() => {
    const formData = new FormData();
    formData.append("employee_id", Employe?.id);
    dispatch(getSelectedEmployeeAction(formData));
  }, [Employe]);
  useEffect(() => {
    setData({
      ...data,
      location_id: data?.location_id,
      employee_name: Employe?.name,
      employee_id: Employe?.id,
    });
    setError({
      ...error,
      employee_id: null,
    });
  }, [getSelectedEmployee, Employe]);
  const isLoading = useSelector((state) => state.loader.isLoading);
  useEffect(() => {
    if (isLoading === true) {
      setData({
        employee_name: "",
      });
    }
  }, []);

  // location data show in dropdown
  const Location = useSelector(
    (state) => state?.companyLocation?.companyLocation
  );
  useEffect(() => {
    dispatch(LocationData());
  }, []);

  useEffect(() => {
    if (checked === true) {
      setData((prev) => {
        return {
          ...prev,
          is_repeat: 1,
        };
      });
    } else {
      setData((prev) => {
        return {
          ...prev,
          is_repeat: 0,
        };
      });
    }
  }, [checked]);
  const handleSubmit = () => {
    if (!data.employee_id) {
      setError({
        ...error,
        employee_id: !data.employee_id
          ? "Please Select Employee"
          : error.employee_id,
      });
      return;
    }
    let hasErrors = false;
    data.time_schedule?.map((item, index) => {
      if (item.is_working && (!item.start_time || !item.end_time)) {
        item.error.working_start_time = "Start time and end time is required";
        return (hasErrors = true);
      } else if (
        item.is_working &&
        (!item.start_lunch_time || !item.end_lunch_time)
      ) {
        item.error.lunch_time = "Start time and end time is required";
        return (hasErrors = true);
      } else if (item.is_working && item.start_time && item.end_time) {
        item.error = {
          ...error,
          working_time: "",
        };
        return (hasErrors = false);
      } else if (
        item.is_working &&
        item.start_lunch_time &&
        item.end_lunch_time
      ) {
        item.error = {
          ...error,
          lunch_time: "",
        };
        return (hasErrors = false);
      }
    });

    setData({ ...data });
    if (hasErrors) return;

    const formData = new FormData();
    formData.append("emp_id", data.employee_id);
    formData.append("is_repeat", data.is_repeat);
    data.time_schedule?.map((item, index) => {
      if (item?.is_working === true) {
        formData.append(`time_schedule[${index}][day]`, item.day);
        formData.append(
          `time_schedule[${index}][location_id]`,
          item.location_id
        );
        formData.append(`time_schedule[${index}][is_working]`, 1);
        formData.append(
          `time_schedule[${index}][start_time]`,
          item?.start_time
        );
        formData.append(`time_schedule[${index}][end_time]`, item?.end_time);
        formData.append(
          `time_schedule[${index}][start_lunch_time]`,
          item.start_lunch_time
        );
        formData.append(
          `time_schedule[${index}][end_lunch_time]`,
          item.end_lunch_time
        );
      }
    });
    dispatch(
      addEmployeeSchedulingAction(formData, () =>
        navigate("/account-owner/employees-scheduling")
      )
    );
  };
  return (
    <>
      {isLoading === true ? (
        <Loader />
      ) : (
        <ContentArea>
          <div className="p-3">
            <div className="grid card ml-0 mr-0 px-5 mb-5 align-items-center bg-main-light border-round-lg">
              <h1 className="text-main">Add Employee Scheduling</h1>
            </div>
            <Card className="shedule shadow-2 p-3">
              <div className="p-fluid grid mb-3">
                <div className="col-12 md:col-6 ">
                  <DropdownField
                    label="Select Employee"
                    inputId="dropdown"
                    value={Employe}
                    options={employeeData?.filter(
                      (item) => !empID.includes(item.emp_id)
                    )}
                    onChange={(e) => SetEmploye(e.value)}
                    itemTemplate={(e) => {
                      return (
                        <>
                          {e.emp_id} {e.first_name} {e.last_name}
                        </>
                      );
                    }}
                    optionLabel="emp_id"
                    placeholder="Select"
                    required
                  />
                  {error.employee_id && (
                    <span className="error">{error.employee_id}</span>
                  )}
                </div>

                <div className="col-12 md:col-6">
                  <InputField
                    id="inputtext"
                    label="Full Name"
                    value={data?.employee_name}
                    required
                  />
                </div>
              </div>
              <div className="p-fluid grid">
                <div className=" col-12 md:col-12 ">
                  <h2
                    className="text-main font-bold"
                    style={{ fontSize: "22px" }}
                  >
                    Add Timings for selected days
                  </h2>
                </div>
              </div>
              <div className="p-fluid grid">
                <div className="col-12 md:col-12 border-round-sm ">
                  {data?.time_schedule?.length > 0 &&
                    data?.time_schedule?.map((item, index) => {
                      return (
                        <EmployeeTiming
                          title={item?.day}
                          index={index}
                          items={item}
                          setData={setData}
                          data={data}
                          Location={Location}
                          setError={setError}
                          error={error}
                        />
                      );
                    })}
                </div>
              </div>
              <div className="p-fluid grid mb-5">
                <div className="col-12 md:col-12 ">
                  <Checkbox
                    onChange={(e) => setChecked(e.checked)}
                    checked={checked}
                  ></Checkbox>{" "}
                  &nbsp; Repeat for every week.
                </div>
              </div>
              <div className="submitButton flex">
                <SaveButton label="Save" onClick={handleSubmit}></SaveButton>
                <CancelButton
                  label="Cancel"
                  onClick={() =>
                    navigate("/account-owner/employees-scheduling")
                  }
                ></CancelButton>
              </div>
            </Card>
          </div>
        </ContentArea>
      )}
    </>
  );
};

export default AddScheduling;
