import React, { useState } from "react";
import { Card } from "primereact/card";
import ContentArea from "../../../../shared/ContentArea";
import SaveButton from "../../../../components/Buttons/SaveButton";
import CancelButton from "../../../../components/Buttons/CancelButton";
import { Dropdown } from "primereact/dropdown";
import InputField from "../../../../components/input/input";
import { CommonManageService } from "./CommonManageService";
import Loader from "../../../../pages/Loader";

const EditManageService = () => {
  const {
    hour,
    getAppointmentData,
    form,
    schedule,
    setForm,
    setError,
    error,
    duration,
    handleSchedule,
    handleDuration,
    handleEditSubmit,
    navigate,
    isLoading,
  } = CommonManageService();
  return (
    <>
      {isLoading === true ? (
        <Loader />
      ) : (
        <ContentArea>
          <div className="edit_manage_service_container">
            <div className="p-3">
              <div className="grid card ml-0 mr-0 px-5 mb-5 align-items-center bg-main-light border-round-lg">
                <h2 className="text-main">Service List</h2>
              </div>
              <Card className="shadow-2 p-3 border-round-lg">
                <div className="p-fluid grid  p-0 m-0">
                  <div className="col-12 md:col-6">
                    <label className="font block">
                      Service Name<code className="text-red">*</code>{" "}
                    </label>
                    <InputField
                      name="service_name"
                      value={form?.service_name}
                      onChange={(e) => {
                        setForm({
                          ...form,
                          service_name: e.target.value,
                        });
                        setError({
                          ...error,
                          service_name: null,
                        });
                      }}
                    />
                    {error?.service_name && (
                      <span className="error">{error?.service_name}</span>
                    )}
                  </div>
                  <div className="col-12 md:col-6">
                    <label className="font block mb-2">
                      Schedule<code className="text-red">*</code>
                    </label>
                    <Dropdown
                      value={schedule}
                      onChange={(e) => handleSchedule(e)}
                      options={getAppointmentData}
                      optionLabel="appointment_name"
                      placeholder="Select"
                      className="w-full border-round-md"
                    />
                    {error?.appointment_scheduling_id && (
                      <span className="error">
                        {error?.appointment_scheduling_id}
                      </span>
                    )}
                  </div>
                </div>
                <div className="p-fluid grid mb-5 p-0 m-0">
                  <div className="col-12 md:col-6 h-20rem">
                    <label className="font block mb-2">
                      Service Duration<code className="text-red">*</code>{" "}
                    </label>
                    <Dropdown
                      value={duration}
                      onChange={(e) => handleDuration(e)}
                      options={hour}
                      optionLabel="name"
                      placeholder="Select"
                      className="w-full border-round-md"
                    />
                    {error.duration && (
                      <span className="error">{error?.duration}</span>
                    )}
                  </div>
                </div>
                <div className="submitButton flex p-1">
                  {/* <SaveButton label="Save" onClick={handleEditSubmit}></SaveButton> */}
                  <CancelButton
                    label="Cancel"
                    onClick={() => navigate("/account-owner/manage-services")}
                  ></CancelButton>
                </div>
              </Card>
            </div>
          </div>
        </ContentArea>
      )}
    </>
  );
};

export default EditManageService;
