import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { appointmentCalendarAction, appointmentCalenderViewDate, CalendarShowData, getAppointmentDataAction } from "../../../../Redux/HospitalAction/appoinmentAction";
import moment from "moment";

export const CommonData = () => {
    const dispatch = useDispatch()
    const [eventData, setEventData] = useState("")
    const [show, setShow] = useState(false)
    const [name, setName] = useState("")
    useEffect(() => {
        dispatch(appointmentCalendarAction())
        dispatch(CalendarShowData())
        dispatch(getAppointmentDataAction())
    }, [])
    const handleClick = (date) => {
        // event?.start
        setName(date?.event?.extendedProps?.appointment_name)
        let payload = moment(date?.event?.start).format("DD-MM-YYYY")
        dispatch(appointmentCalenderViewDate(payload, () => setShow(true)))
    }
    const viewData = useSelector((state) => state.getEvent.getDateEventProfile)
    const calendarData = useSelector((state) => state.calendar.appointmentCalendarProfile)
    const schedulingData = useSelector((state) => state.calendarScheduling.calendarSchedulingProfile)
    const getAppointmentData = useSelector((state) => state.getAppointmentData.getAppointmentDataProfile)
    useEffect(() => {
        let events = []
        getAppointmentData?.length > 0 && getAppointmentData?.map((child) => {
            calendarData?.length > 0 && calendarData?.map((item) => {
                if (child?.id === item?.event?.appointment_schedule_id) {
                    let obj = {
                        date: item?.date,
                        // allDay: true,
                        appointment_name: child?.appointment_name,
                        booked_time_slot: item?.event?.booked_time_slot,
                        // color:"#006ED0"
                    }
                    events.push(obj)
                }

            })
        })
        let jsonObject = events.map(JSON.stringify);
       let  uniqueSet = new Set(jsonObject);
      let  uniqueArray = Array.from(uniqueSet).map(JSON.parse);
        setEventData(uniqueArray)
    }, [calendarData, getAppointmentData])
    return { calendarData, eventData, handleClick, show, setShow, viewData, schedulingData, name }
}