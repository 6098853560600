export function updateNestedObject(obj, path, value) {
    let keys = path.split('.');  // Split the path into an array of keys
    let current = obj;           // Reference to the current level of the object

    // Traverse the object except for the last key
    for (let i = 0; i < keys.length - 1; i++) {
        let key = keys[i];

        // If the key does not exist, create an empty object at that key
        if (!current[key]) {
            current[key] = {};
        }

        // Move deeper into the object
        current = current[key];
    }

    // Update the value of the last key
    current[keys[keys.length - 1]] = value;

    return obj;  // Return the updated object (optional)
}
