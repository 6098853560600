
import React, { useState, useEffect } from "react";
import { Dropdown } from 'primereact/dropdown';
import { Card } from "primereact/card";
import { InputNumber } from 'primereact/inputnumber';
import ContentArea from "../../shared/ContentArea";
import InputField from "../../../../components/input/input";
import { Calendar } from 'primereact/calendar';
import SaveButton from "../../../../components/Buttons/SaveButton";
import CancelButton from "../../../../components/Buttons/CancelButton";
import DropdownField from "../../../../components/input/dropdown";
import InputNumberField from "../../../../components/input/inputNumber";
import { useNavigate, useParams } from "react-router-dom";
import { viewHourlyEmployeeAction, getEmployeesAction, editEmployeeHourlyAction } from "../../../../Redux/HospitalAction/employessAction";
import { useDispatch, useSelector } from "react-redux";
import { getServiceAction } from "../../../../Redux/HospitalAction/serviceProviderAction";
import moment from "moment";
import Loader from "../../../../pages/Loader";
const EditHourlyRate = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [time, setTime] = useState(null);
  const [employee, setEmployee] = useState(null);
  const [service, setService] = useState(null);
  const [client, setClient] = useState(null);
  const { id } = useParams()
  const [error, setError] = useState({})
  const [data, setData] = useState({
    employee_id: "",
    employee_name: "",
    service_id: "",
    date_of_service: "",
    start_time: "",
    client_id: "",
    employee_working_hour: "",
    pa_Code: "",
    employee_Id: ""
  })
  const Client = [
    { name: 'Home', id: 159 },
    { name: 'Adult', id: 163 },
    { name: 'Senior', id: 175 },
  ]
  useEffect(() => {
    dispatch(viewHourlyEmployeeAction(id))
    dispatch(getEmployeesAction())
    dispatch(getServiceAction())
  }, [])
  const getViewData = useSelector((state) => state.viewHourlyEmployee.viewHourlyProfile)
  const getEmployee = useSelector((state) => state.getEmployee.getEmployeeProfile)
  const getallService = useSelector((state) => state.getService.getServiceProfile)
  useEffect(() => {
    let obj

    if (getViewData) {
      let time1 = getViewData?.start_time?.split(":")[0]
      let time2 = getViewData?.start_time?.split(":")[1]
      obj = {
        client: getViewData.client,
        client_name: getViewData.client_name,
        date_of_service: new Date(getViewData?.date_of_service?.split(" ")[0]),
        employee_Id: getViewData.employee_Id,
        employee_id: getViewData.employee_id,
        employee_name: getViewData.employee_name,
        employee_working_hour: getViewData.employee_working_hour,
        id: getViewData.id,
        pa_Code: getViewData.pa_Code,
        service: getViewData.service,
        start_time: new Date(null, null, null, time1, time2),
        subscription_plan_name: getViewData.subscription_plan_name,
        user_id: getViewData.user_id
      }
      setData(obj)
    }

  }, [getViewData])
  useEffect(() => {
    getEmployee?.length > 0 && getEmployee?.map((item) => {
      if (item.id == getViewData.employee_id) {
        setEmployee(item)
      }
    })
  }, [getViewData, getEmployee])
  useEffect(() => {
    getallService?.length > 0 && getallService?.map((item) => {
      if (item.id == getViewData.service) {
        setService(item)
      }
    })
  }, [getallService, getViewData])
  useEffect(() => {
    Client?.map((item) => {
      if (item.id == getViewData.client) {
        setClient(item)
      }
    })
  }, [getViewData])
  useEffect(() => {
    setData({
      ...data,
      employee_id: employee?.id,
      employee_name: employee?.name,
      pa_Code: employee?.pa_Code,
      service_id: service?.id,
      client_id: client?.id
    })
  }, [employee, service, client])
  const handleSubmit = () => {
    if (data.employee_id === undefined || data.service_id === undefined || data.date_of_service === "" || data.start_time === "" ||
      data.client_id === undefined || data.employee_working_hour === null || data.start_time === null) {
      setError({
        ...error,
        employee_id: data.employee_id === undefined ? "Please choose Employee" : error.employee_id,
        service_id: data.service_id === undefined ? "Please Choose Service" : error.service_id,
        date_of_service: data.date_of_service === "" ? "Please Select Date" : error.date_of_service,
        start_time: data.start_time === "" || data.start_time === null ? "Please Select Time" : error.start_time,
        client_id: data.client_id === undefined ? "Please Select Client" : error.client_id,
        employee_working_hour: data.employee_working_hour === null ? "Please Enter Working hour" : error.employee_working_hour
      })
      return false;
    }
    const obj = data;
    let array = Object.entries(obj)
    const formData = new FormData()
    for (let i = 0; i < array.length; i++) {
      if (array[i][0] === "date_of_service") {
        formData.append(array[i][0], moment(data.date_of_service).format('DD-MM-YYYY'))
      } else if (array[i][0] === "start_time") {
        formData.append(array[i][0], data.start_time?.getHours() +":" + data.start_time?.getMinutes())
      } else {
        formData.append(array[i][0], array[i][1])
      }
    }
    dispatch(editEmployeeHourlyAction(id, formData, () => navigate("/account-owner/manage-hourly-rate")))
  }
  const isLoading = useSelector((state) => state.loader.isLoading)
  return (
    <>
      {isLoading === true ? <Loader /> :
        <ContentArea>
          <div className="edit_hourly_rate_container">
            <div className="p-3">
              <div className="grid card ml-0 mr-0 px-5 mb-5 align-items-center bg-main-light border-round-lg">
                <h2 className="text-main">
                  Edit Manage Hourly Rates
                </h2>
              </div>
              <Card className="shadow-2 p-3 border-round-lg">
                <div className="p-fluid grid mb-3">
                  <div className="col-12 md:col-6">
                    <DropdownField label="Select Employee" placeholder="Select" inputId="dropdown" value={employee}
                      options={getEmployee} onChange={(e) => setEmployee(e.value)} optionLabel="emp_id" filter  required />
                  </div>
                  <div className=" col-12 md:col-6">
                    <InputField type="text" label="Employee Name" value={data.employee_name} />
                  </div>

                  <div className="col-12 md:col-6">
                    <DropdownField label="Service" inputId="dropdown" placeholder="Select" value={service}
                      options={getallService} onChange={(e) => setService(e.value)} optionLabel="program_name" required />
                  </div>
                </div>

                <div className="p-fluid grid mb-3">
                  <div className="col-12 md:col-6">
                    <InputField label="PA Code" inputClassName="border-round-md" value={data.pa_Code} />
                  </div>
                </div>
                <div className="p-fluid grid mb-5">
                  <div className="col-12 md:col-12">
                    <h2 className="text-main ">Date of Service</h2>
                  </div>
                  <div className="col-12 md:col-6 mb-3 ">
                    <label className="font block mb-2">
                      Date Of Service<code className="text-red">*</code>
                    </label>
                    <Calendar
                      className="border-round-md calender_picker "
                      inputClassName="calendar p-2 border-round-md "
                      placeholder="select"
                      value={data.date_of_service}
                      onChange={(e) => {
                        setData({
                          ...data,
                          date_of_service: e.value
                        })
                      }}
                    />
                  </div>
                  <div className="col-12 md:col-6 mb-3">
                    <label className="font block mb-2">
                      Start Time<code className="text-red">*</code>
                    </label>
                    <Calendar id="calendar-timeonly" value={data.start_time} onChange={(e) => setData({
                      ...data,
                      start_time: e.value
                    })} timeOnly hourFormat="24" />
                  </div>

                  <div className="col-12 md:col-6">
                    <DropdownField label="Client" inputId="dropdown" placeholder="Select" value={client}
                      options={Client} onChange={(e) => setClient(e.value)} optionLabel="name" required/>
                  </div>
                  <div className="col-12 md:col-6">
                    <div className="relative">
                    <InputNumberField label="Employee Working Hours" required inputClassName="border-round-md"  value={data.employee_working_hour} useGrouping={false}
                      onValueChange={(e) => {
                        setError({
                          ...error,
                          employee_working_hour: null
                        })
                        setData({
                          ...data,
                          employee_working_hour: e.value
                        })
                      }
                      } />
                        <span className="hr">/hr</span>
                    {error.employee_working_hour && <span className="error">{error.employee_working_hour}</span>}
                  </div>
                  </div>
                </div>
                <div className="submitButton flex">
                  <SaveButton label="Save" onClick={handleSubmit}></SaveButton>
                  <CancelButton label="Cancel" onClick={() => navigate("/account-owner/manage-hourly-rate")}></CancelButton>
                </div>
              </Card>

            </div>
          </div>

        </ContentArea>
      }
    </>
  )
}

export default EditHourlyRate;
