import { types } from "../HospitalType/types";

const intitalState = {
    loginProfile: []
}

export const loginReducer = (state = intitalState, action) => {
    switch (action.type) {
        case types.LOGIN:
            return {
                ...state,
                loginProfile: action.payload
            }
        default:
            return { ...state };
    }
}