import { types } from "../HospitalType/types";
import HospitalConstants from "../../Services/HospitalConstants";
import { HospitalApi } from "../../Services/HospitalApi";
import { showLoaderAction, hideLoaderAction } from "./loaderAction";
import { showToast } from "./toastAction";

export const getClientTierAction = () => async (dispatch) => {
  dispatch(showLoaderAction());
  const res = await HospitalApi("get", HospitalConstants.END_POINT.GETTIERS);
  if (res.success) {
    if (res.data) {
      dispatch({
        type: types.GET_TIERS,
        payload: res.data,
      });
    }
    dispatch(hideLoaderAction());
  } else {
    dispatch(hideLoaderAction());
  }
};

export const deleteClientTiersAction = (id, next) => async (dispatch) => {
  dispatch(showLoaderAction());
  const res = await HospitalApi(
    "get",
    `${HospitalConstants.END_POINT.DELETETIERS}/${id}`
  );
  if (res.success) {
    if (res.data) {
      dispatch({
        type: types.DELETE_TIERS,
        payload: res.data,
      });
    }
    dispatch(hideLoaderAction());
    next();
    dispatch(showToast({ severity: "success", summary: res.message }));
  } else {
    dispatch(hideLoaderAction());
    dispatch(showToast({ severity: "error", summary: res.message }));
  }
};

export const addClientTiersAtion = (data, next) => async (dispatch) => {
  dispatch(showLoaderAction());
  const res = await HospitalApi(
    "post",
    HospitalConstants.END_POINT.ADDTIERS,
    data
  );
  if (res.success) {
    if (res.data) {
      dispatch({
        type: types.ADD_TIERS,
        payload: res.data,
      });
    }
    dispatch(hideLoaderAction());
    next();
    dispatch(showToast({ severity: "success", summary: res.message }));
  } else {
    dispatch(hideLoaderAction());
    dispatch(showToast({ severity: "error", summary: res.message }));
  }
};

export const editClientTiersAction = (id, data, next) => async (dispatch) => {
  dispatch(showLoaderAction());
  const res = await HospitalApi(
    "post",
    `${HospitalConstants.END_POINT.EDITTIERS}/${id}`,
    data
  );
  if (res.success) {
    if (res.data) {
      dispatch({
        type: types.EDIT_TIERS,
        payload: res.data,
      });
    }
    dispatch(hideLoaderAction());
    next();
    dispatch(showToast({ severity: "success", summary: res.message }));
  } else {
    dispatch(hideLoaderAction());
    dispatch(showToast({ severity: "error", summary: res.message }));
  }
};

export const viewClientTiersAction = (id) => async (dispatch) => {
  dispatch(showLoaderAction());
  const res = await HospitalApi(
    "get",
    `${HospitalConstants.END_POINT.VIEWTIERS}/${id}`
  );
  if (res.success) {
    if (res.data) {
      dispatch({
        type: types.VIEW_TIERS,
        payload: res.data,
      });
      dispatch(hideLoaderAction());
    }
  } else {
    dispatch(hideLoaderAction());
  }
};

export const getClientAction = (data) => async (dispatch) => {
  dispatch(showLoaderAction());
  const res = await HospitalApi(
    "post",
    HospitalConstants.END_POINT.GETCLIENT,
    data
  );
  if (res.success) {
    if (res.data) {
      dispatch({
        type: types.GET_CLIENT,
        payload: res.data,
      });
    }
    dispatch(hideLoaderAction());
  } else {
    dispatch(hideLoaderAction());
  }
};

export const addClientAction = (data, next) => async (dispatch) => {
  // dispatch(showLoaderAction())
  const res = await HospitalApi(
    "post",
    HospitalConstants.END_POINT.ADDCLIENT,
    data
  );
  if (res.data) {
    dispatch({
      type: types.ADD_CLIENT,
      payload: res.data,
    });
    // dispatch(hideLoaderAction())
    next();
    dispatch(showToast({ severity: "success", summary: res.message }));
  } else {
    // dispatch(hideLoaderAction())
    dispatch(showToast({ severity: "error", summary: res.message }));
  }
};

export const deleteClientAction = (id, next) => async (dispatch) => {
  dispatch(showLoaderAction());
  const res = await HospitalApi(
    "get",
    `${HospitalConstants.END_POINT.DELETECLIENT}/${id}`
  );
  if (res.success) {
    if (res.data) {
      dispatch({
        type: types.DELETE_CLIENT,
        payload: res.data,
      });
    }
    dispatch(hideLoaderAction());
    next();
    dispatch(showToast({ severity: "success", summary: res.message }));
  } else {
    dispatch(hideLoaderAction());
    dispatch(showToast({ severity: "error", summary: res.message }));
  }
};

export const viewClientAction = (id) => async (dispatch) => {
  dispatch(showLoaderAction());
  const res = await HospitalApi(
    "get",
    `${HospitalConstants.END_POINT.VIEWCLIENT}/${id}`
  );
  if (res.success) {
    if (res.data) {
      dispatch({
        type: types.VIEW_CLIENT,
        payload: res.data,
      });
    }
    dispatch(hideLoaderAction());
  } else {
    dispatch(hideLoaderAction());
    dispatch(showToast({ severity: "error", summary: res.message }));
  }
};

export const editClientAction = (id, data, next) => async (dispatch) => {
  dispatch(showLoaderAction());
  const res = await HospitalApi(
    "post",
    `${HospitalConstants.END_POINT.EDITCLIENT}/${id}`,
    data
  );
  if (res.data) {
    dispatch({
      type: types.EDIT_CLIENT,
      payload: res.data,
    });
    dispatch(hideLoaderAction());
    next();
    dispatch(showToast({ severity: "success", summary: res.message }));
  } else {
    dispatch(hideLoaderAction());
    dispatch(showToast({ severity: "error", summary: res.message }));
  }
};
export const getClientPaCodeAction = (data) => async (dispatch) => {
  dispatch(showLoaderAction());
  const res = await HospitalApi(
    "post",
    HospitalConstants.END_POINT.GETCLIENTPACODE,
    data
  );
  if (res.success) {
    if (res.data) {
      dispatch({
        type: types.GET_CLIENT_PA_CODE,
        payload: res.data,
      });
    }
    dispatch(hideLoaderAction());
  } else {
    dispatch(hideLoaderAction());
    dispatch(showToast({ severity: "error", summary: res.message }));
  }
};

export const addClientPaCodeAction = (data, next) => async (dispatch) => {
  dispatch(showLoaderAction());
  const res = await HospitalApi(
    "post",
    HospitalConstants.END_POINT.ADDCLIENTPACODE,
    data
  );
  if (res.success) {
    if (res.data) {
      dispatch({
        type: types.ADD_CLIENT_PA_CODE,
        payload: res.data,
      });
    }
    dispatch(hideLoaderAction());
    next();
    dispatch(showToast({ severity: "success", summary: res.message }));
  } else {
    dispatch(hideLoaderAction());
    dispatch(showToast({ severity: "error", summary: res.message }));
  }
};

export const deleteClientPaCodeAction = (id, next) => async (dispatch) => {
  dispatch(showLoaderAction());
  const res = await HospitalApi(
    "get",
    `${HospitalConstants.END_POINT.DELETECLIENTPACODE}/${id}`
  );
  if (res.success) {
    if (res.data) {
      dispatch({
        type: types.DELETE_CLIENT_PA_CODE,
        payload: res.data,
      });
    }
    dispatch(hideLoaderAction());
    next();
    dispatch(showToast({ severity: "success", summary: res.message }));
  } else {
    dispatch(hideLoaderAction());
    dispatch(showToast({ severity: "error", summary: res.message }));
  }
};

export const viewClientPaCodeAction = (id) => async (dispatch) => {
  dispatch(showLoaderAction());
  const res = await HospitalApi(
    "get",
    `${HospitalConstants.END_POINT.VIEWCLIENTPACODE}/${id}`
  );
  if (res.success) {
    if (res.data) {
      dispatch({
        type: types.VIEW_CLIENT_PA_CODE,
        payload: res.data,
      });
    }
    dispatch(hideLoaderAction());
    // dispatch(showToast({severity:"success",summary:res.message}))
  } else {
    dispatch(hideLoaderAction());
    dispatch(showToast({ severity: "error", summary: res.message }));
  }
};

export const editClientPaCodeAction = (id, data, next) => async (dispatch) => {
  dispatch(showLoaderAction());
  const res = await HospitalApi(
    "post",
    `${HospitalConstants.END_POINT.EDITCLIENTPACODE}/${id}`,
    data
  );
  if (res.success) {
    if (res.data) {
      dispatch({
        type: types.EDIT_CLIENT_PA_CODE,
        payload: res.data,
      });
    }
    dispatch(hideLoaderAction);
    next();
    dispatch(showToast({ severity: "success", summary: res.message }));
  } else {
    dispatch(hideLoaderAction());
    dispatch(showToast({ severity: "error", summary: res.message }));
  }
};
