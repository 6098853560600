import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";
import { Link, json } from "react-router-dom";
import { Button } from "primereact/button";
import { InputNumber } from "primereact/inputnumber";
import { Dropdown } from "primereact/dropdown";
import { validEmail } from "../components/Validation";
import { Accordion, AccordionTab } from "primereact/accordion";
import DeleteIcon from "../assets/icons/trash.png";
import "react-toastify/dist/ReactToastify.css";
import SignUpImg from "../assets/images/sign-up img.png";
import { Stepper, Step } from "react-form-stepper";
import {
  signupAction,
  checkDiscountCodeAction,
  paymenDataAction,
  subscriptionPlanAction,
} from "../Redux/HospitalAction/signupAction";
import { useDispatch, useSelector } from "react-redux";
import { config } from "../envirement/development";
import { useNavigate } from "react-router-dom";
import InputField from "../components/input/input";
import PasswordField from "../components/input/Password";
import InputNumberField from "../components/input/inputNumber";
import InputTextareaField from "../components/input/inputTextArea";
import DropdownField from "../components/input/dropdown";
import { USA_STATES } from "../constants/global";
const Signup = ({ setNextPage, NextPage }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [activeIndex, setActiveIndex] = useState(0);
  const [checked, setChecked] = useState(false);
  const [signupData, setSignUpData] = useState({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
    phone_number: "",
    address: "",
    state: "",
    zip_code: "",
    company_tin_ein: "",
    company_name: "",
    company_address: "",
    company_state: "",
    company_city: "",
    company_zip_code: "",
    service_type: [],
    discount_code: "",
  });
  const [signupError, setSignupError] = useState("");
  const [tableData, setTableData] = useState("");
  const [service, setService] = useState(null);
  const [getIndex, setGetIndex] = useState("");
  const [totalPrice, setTotalPrice] = useState(0);
  const [editButton, setEditButton] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [addServices, setAddServices] = useState({
    subscription_plans: "",
    number_of_client: "",
    price: 0,
  });
  const [value, setValue] = useState({
    success_url: "",
    cancel_url: "",
  });
  const handleAddNotes = () => {
    let array = [];
    array.push(addServices);
    setTableData([...tableData, ...array]);
    setEditButton(false);
    setAddServices({
      subscription_plans: "",
      number_of_client: "",
      price: 0,
    });
  };
  useEffect(() => {
    setAddServices({
      ...addServices,
      price: totalPrice,
    });
  }, [addServices.number_of_client, totalPrice]);
  const handleCancel = () => {
    setEditButton(false);
    setAddServices({
      subscription_plans: "",
      number_of_client: "",
      price: 0,
    });
  };
  const handleEdit = (index) => {
    setGetIndex(index);
    setEditButton(true);
    tableData?.map((item, i) => {
      if (i === index) {
        setAddServices({
          subscription_plans: item?.subscription_plans,
          number_of_client: item?.number_of_client,
          price: item?.price,
        });
      }
    });
  };
  const handleDelete = (index) => {
    let data = tableData.filter((item, row) => {
      return index !== row;
    });
    setTableData(data);
  };
  const handleChange = (e) => {
    setSignupError({
      ...signupError,
      service_type: null,
    });
    setAddServices((prev) => {
      return {
        ...prev,
        subscription_plans: e.value,
      };
    });
  };
  const handleFirstPage = async () => {
    try {
      const validate = await checkValidation();
      if (validate) return setActiveIndex(1);
      else return;
      // setNextPage1(true)
    } catch (err) {
      console.log(err);
    }
  };

  const checkValidation = async () => {
    if (
      signupData.email === "" ||
      !validEmail(signupData.email) ||
      signupData.password === "" ||
      signupData?.confirmPassword === "" ||
      signupData.password !== signupData.confirmPassword ||
      checked === false
    ) {
      setSignupError({
        ...signupError,
        email:
          signupData?.email === ""
            ? "Please Enter Email"
            : !validEmail(signupData?.email)
            ? "Please Enter Valid Email"
            : signupError?.email,
        password:
          signupData.password === ""
            ? "Please Enter Password"
            : signupError?.password,
        confirmPassword:
          signupData.confirmPassword === ""
            ? "Please Enter Confirm Password"
            : signupData.password !== signupData?.confirmPassword
            ? "Password doesn't Match"
            : signupError?.confirmPassword,
        checked:
          checked === false
            ? "Please accept term & condition"
            : signupError.checked,
      });
      return false;
    } else {
      return true;
    }
  };
  const handleSecondPage = () => {
    try {
      if (
        signupData.name === "" ||
        signupData?.phone_number === null ||
        signupData?.address === "" ||
        signupData?.state === "" ||
        signupData?.zip_code === null ||
        signupData?.phone_number === ""
      ) {
        setSignupError({
          ...signupError,
          name:
            signupData.name === ""
              ? "Please Enter Full Name"
              : signupError?.name,
          phone_number:
            signupData.phone_number === null || signupData?.phone_number === ""
              ? "Please Enter Phone Number"
              : signupError?.phone_number,
          address:
            signupData.address === ""
              ? "Please Enter Address"
              : signupError?.address,
          state:
            signupData.state === "" ? "Please Enter State" : signupError?.state,
          zip_code:
            signupData?.zip_code === null
              ? "Please Enter Zip Code"
              : signupError?.zip_code,
        });
        return false;
      }
      setActiveIndex(2);
    } catch (err) {
      console.log(err);
    }
  };
  const handleUpdate = () => {
    let newArray = [];
    newArray = tableData.map((item, i) => {
      if (getIndex === i) {
        return { ...addServices };
      } else {
        return item;
      }
    });
    setTableData(newArray);
    setEditButton(false);
    setAddServices({
      subscription_plans: "",
      number_of_client: "",
      price: "",
    });
  };
  const handleThirdPage = () => {
    try {
      if (
        signupData?.company_tin_ein === "" ||
        signupData?.company_name === "" ||
        signupData?.company_address === "" ||
        signupData?.company_state === "" ||
        signupData?.company_city === "" ||
        signupData?.company_zip_code === null
      ) {
        setSignupError({
          ...signupError,
          company_tin_ein:
            signupData?.company_tin_ein === ""
              ? "Please Enter Company Tin/Ein"
              : signupError?.company_tin_ein,
          company_name:
            signupData?.company_name === ""
              ? "Please Enter Company Name"
              : signupError?.company_name,
          company_address:
            signupData?.company_address === ""
              ? "Please Enter Company Address"
              : signupError?.company_address,
          company_state:
            signupData?.company_state === ""
              ? "Please Enter Company State"
              : signupError?.company_state,
          company_city:
            signupData?.company_city === ""
              ? "Please Enter Company City"
              : signupError?.company_city,
          company_zip_code:
            signupData?.company_zip_code === null
              ? "Please Enter Company Zip Code"
              : signupError?.company_zip_code,
        });
        return false;
      }
      setActiveIndex(3);
      // setNextPage3(true)
    } catch (err) {
      console.log(err);
    }
  };
  const handleSignupSubmit = async () => {
    if (
      signupData.email === "" ||
      signupData.password === "" ||
      signupData?.confirmPassword === "" ||
      !validEmail(signupData?.email) ||
      signupData.password !== signupData?.confirmPassword ||
      signupData?.name === "" ||
      signupData?.phone_number === null ||
      signupData?.address === "" ||
      signupData?.state === "" ||
      signupData?.zip_code === null ||
      signupData?.company_tin_ein === "" ||
      signupData?.company_name === "" ||
      signupData?.company_address === "" ||
      signupData?.company_state === "" ||
      signupData?.company_city === "" ||
      signupData?.company_zip_code === null ||
      signupData?.service_type?.length === 0
    ) {
      setSignupError({
        ...signupError,
        email:
          signupData?.email === ""
            ? "Please Enter Email"
            : !validEmail(signupData?.email)
            ? "Please Enter Valid Email"
            : signupError?.email,
        password:
          signupData.password === ""
            ? "Please Enter Password"
            : signupError?.password,
        confirmPassword:
          signupData.confirmPassword === ""
            ? "Please Enter Confirm Password"
            : signupData.password !== signupData?.confirmPassword
            ? "Password doesn't Match"
            : signupError?.confirmPassword,
        name:
          signupData.name === "" ? "Please Enter Full Name" : signupError?.name,
        phone_number:
          signupData.phone_number === null
            ? "Please Enter Phone Number"
            : signupError?.phone_number,
        address:
          signupData.address === ""
            ? "Please Enter Address"
            : signupError?.address,
        state:
          signupData.state === "" ? "Please Enter State" : signupError?.state,
        zip_code:
          signupData?.zip_code === null
            ? "Please Enter Zip Code"
            : signupError?.zip_code,
        company_tin_ein:
          signupData?.company_tin_ein === ""
            ? "Please Enter Company Tin/Ein"
            : signupError?.company_tin_ein,
        company_name:
          signupData?.company_name === ""
            ? "Please Enter Company Name"
            : signupError?.company_name,
        company_address:
          signupData?.company_address === ""
            ? "Please Enter Company Address"
            : signupError?.company_address,
        company_state:
          signupData?.company_state === ""
            ? "Please Enter Company State"
            : signupError?.company_state,
        company_city:
          signupData?.company_city === ""
            ? "Please Enter Company City"
            : signupError?.company_city,    
        company_zip_code:
          signupData?.company_zip_code === null
            ? "Please Enter Company Zip Code"
            : signupError?.company_zip_code,
        service_type:
          signupData?.service_type?.length === 0
            ? "Please Enter Service Type"
            : signupError?.service_type,
      });
      return false;
    }
    const obj = signupData;
    let array = Object.entries(obj);
    const formData = new FormData();
    for (let i = 0; i < array.length; i++) {
      if (array[i][0] === "service_type") {
        formData.append(array[i][0], JSON.stringify(array[i][1]));
      } else {
        formData.append(array[i][0], array[i][1]);
      }
    }
    let success_url = config.host + "account-owner/dashboard";
    let cancel_url = config.host;
    setValue({
      ...value,
      success_url,
      cancel_url,
    });
    if (signupData.discount_code !== "") {
      dispatch(
        checkDiscountCodeAction(formData, (status) => {
          if (status === true) {
            dispatch(
              signupAction(formData, (response) => {
                if (response === true) {
                  dispatch(
                    paymenDataAction(value, (data) => {
                      window.location.replace(data);
                    })
                  );
                }
              })
            );
          }
        })
      );
    } else {
      dispatch(
        signupAction(formData, (response) => {
          if (response === true) {
            dispatch(
              paymenDataAction(value, (data) => {
                window.location.replace(data);
              })
            );
          }
        })
      );
    }
  };
  useEffect(() => {
    dispatch(subscriptionPlanAction());
  }, []);
  const subscriptionData = useSelector(
    (state) => state.subscriptionPlan.subscriptionPlanProfile
  );
  useEffect(() => {
    setService(subscriptionData);
  }, [subscriptionData]);
  useEffect(() => {
    let totalAmount = "";
    totalAmount =
      addServices?.subscription_plans?.price_per_person *
      addServices?.number_of_client;
    setTotalPrice(totalAmount);
  }, [addServices]);

  useEffect(() => {
    setAddServices({
      ...addServices,
      price: totalPrice,
    });
  }, [addServices.number_of_client, totalPrice]);
  useEffect(() => {
    let payload = [];
    tableData?.length > 0 &&
      tableData.map((item) => {
        let obj = {
          subscription_plans_id: item?.subscription_plans?.id,
          number_of_client: item?.number_of_client,
        };
        payload.push(obj);
      });
    setSignUpData((prev) => {
      return {
        ...prev,
        service_type: payload,
      };
    });
  }, [tableData]);
  useEffect(() => {
    if (NextPage === false) {
      setActiveIndex(0);
      setSignUpData({
        name: "",
        email: "",
        password: "",
        confirmPassword: "",
        phone_number: "",
        address: "",
        state: "",
        zip_code: "",
        company_tin_ein: "",
        company_name: "",
        company_address: "",
        company_state: "",
        company_city: "",
        company_zip_code: "",
        service_type: [],
        discount_code: "",
      });
    }
  }, [NextPage]);
  useEffect(() => {
    let success_url = config.host + "account-owner/dashboard";
    let cancel_url = config.host;
    setValue({
      ...value,
      success_url,
      cancel_url,
    });
  }, []);
  useEffect(() => {
    if (checked === true) {
      setSignupError({
        ...signupError,
        checked: null,
      });
    }
  }, [checked]);
  return (
    <>
      {NextPage === true && (
        <Dialog
          className="modal_container mt-5  border-round-lg overflow-hidden"
          visible={NextPage}
          // dismissableMask={true}
          onHide={() => setNextPage(false)}
          style={{ width: "60vw", Height: "850px" }}
          breakpoints={{ "960px": "75vw", "641px": "100vw" }}
        >
          <div className="login_details_modal_content">
            <div className="p-fluid grid p-0 m-0">
              <div className="col-12 md:col-7 p-5">
                <Stepper activeStep={activeIndex}>
                  <Step onClick={() => setActiveIndex(0)} label="Step 1" />
                  <Step onClick={() => setActiveIndex(1)} label="Step 2" />
                  <Step onClick={() => setActiveIndex(2)} label="Step 3" />
                  <Step onClick={() => setActiveIndex(3)} label="Step 4" />
                </Stepper>
                {activeIndex === 0 && (
                  <div className="p-5">
                    <div className="p-fluid grid flex justify-content-center">
                      <div className="col-12 md:col-11 text-center">
                        <h1 className="font-bold" style={{ fontSize: "30px" }}>
                          Login Details{" "}
                        </h1>
                      </div>
                      <div className="col-12 md:col-11 ">
                        <InputField
                          label="Email"
                          required
                          inputClassName="border-round-md"
                          value={signupData?.email}
                          onChange={(e) => {
                            setSignupError({
                              ...signupError,
                              email: null,
                            });
                            setSignUpData({
                              ...signupData,
                              email: e.target.value,
                            });
                          }}
                          errorMessage={signupError?.email}
                        />
                      </div>

                      <div className="col-12 md:col-11 ">
                        <PasswordField
                          label="Password"
                          inputClassName="border-round-md"
                          toggleMask
                          value={signupData?.password}
                          onChange={(e) => {
                            setSignupError({
                              ...signupError,
                              password: null,
                            });
                            setSignUpData({
                              ...signupData,
                              password: e.target.value,
                            });
                          }}
                          required
                          errorMessage={signupError?.password}
                        />
                      </div>

                      <div className="col-12 md:col-11 mb-3 ">
                        <PasswordField
                          label="Confirm Password"
                          required
                          inputClassName="border-round-md"
                          toggleMask
                          value={signupData?.confirmPassword}
                          onChange={(e) => {
                            setSignupError({
                              ...signupError,
                              confirmPassword: null,
                            });
                            setSignUpData({
                              ...signupData,
                              confirmPassword: e.target.value,
                            });
                          }}
                          errorMessage={signupError?.confirmPassword}
                        />
                      </div>

                      <div className="col-12 md:col-11 text-sm mb-3">
                        <Checkbox
                          onChange={(e) => setChecked(e.checked)}
                          checked={checked}
                        ></Checkbox>{" "}
                        &nbsp; Check here to accept our{" "}
                        <span className="underline">terms & conditions </span>{" "}
                        and <span className="underline">privacy policy.</span>
                        <br />
                        {signupError.checked && (
                          <span className="error">{signupError.checked}</span>
                        )}
                      </div>

                      <div className="col-12 md:col-11 ">
                        <Link to="/#" className="link no-underline">
                          <Button
                            label="Next"
                            onClick={() => handleFirstPage()}
                            className="p p-3  border-round-lg"
                            style={{ background: "#006ED0" }}
                          />
                        </Link>
                      </div>
                    </div>
                  </div>
                )}
                {activeIndex === 1 && (
                  <div className="p-5">
                    <div className="p-fluid grid justify-content-center ">
                      <div className="col-12 md:col-11 text-center">
                        <h2 className="font-bold" style={{ fontSize: "30px" }}>
                          Personal Details
                        </h2>
                      </div>
                      <div className="col-12 md:col-11 ">
                        <InputField
                          label="Full Name"
                          required
                          errorMessage={signupError?.name}
                          inputClassName="border-round-md"
                          value={signupData?.name}
                          onChange={(e) => {
                            setSignupError({
                              ...signupError,
                              name: null,
                            });
                            setSignUpData({
                              ...signupData,
                              name: e.target.value,
                            });
                          }}
                        />
                      </div>
                      <div className="col-12 md:col-11 ">
                        <InputNumberField
                          label="Phone Number"
                          required
                          errorMessage={signupError?.phone_number}
                          inputClassName="border-round-md"
                          useGrouping={false}
                          maxLength={10}
                          value={signupData?.phone_number}
                          onValueChange={(e) => {
                            setSignupError({
                              ...signupError,
                              phone_number: null,
                            });
                            setSignUpData({
                              ...signupData,
                              phone_number: e.value,
                            });
                          }}
                        />
                      </div>

                      <div className="col-12 md:col-11 ">
                        <InputTextareaField
                          label="Street Address"
                          rows={5}
                          cols={5}
                          inputClassName="border-round-md"
                          value={signupData?.address}
                          onChange={(e) => {
                            setSignupError({
                              ...signupError,
                              address: null,
                            });
                            setSignUpData({
                              ...signupData,
                              address: e.target.value,
                            });
                          }}
                          required
                          errorMessage={signupError?.address}
                        />
                      </div>
                      <div className="col-12 md:col-11">
                        <InputField
                          label="State"
                          required
                          errorMessage={signupError?.state}
                          inputClassName="border-round-md"
                          value={signupData?.state}
                          onChange={(e) => {
                            setSignupError({
                              ...signupError,
                              state: null,
                            });
                            setSignUpData({
                              ...signupData,
                              state: e.target.value,
                            });
                          }}
                        />
                      </div>
                      <div className="col-12 md:col-11 mb-3 ">
                        <InputNumberField
                          label="Zip Code"
                          required
                          errorMessage={signupError?.zip_code}
                          inputClassName="border-round-md"
                          useGrouping={false}
                          maxLength={5}
                          value={signupData?.zip_code}
                          onValueChange={(e) => {
                            setSignupError({
                              ...signupError,
                              zip_code: null,
                            });
                            setSignUpData({
                              ...signupData,
                              zip_code: e.value,
                            });
                          }}
                        />
                      </div>
                      <div className="col-12 md:col-11">
                        {/* <Link to="/#" className="link no-underline"> */}
                        <Button
                          label="Next"
                          onClick={() => handleSecondPage()}
                          className="p p-3  border-round-lg"
                          style={{ background: "#006ED0" }}
                        />
                        {/* </Link> */}
                      </div>
                    </div>
                  </div>
                )}
                {activeIndex === 2 && (
                  <div className="p-5">
                    <div className="p-fluid grid flex justify-content-center">
                      <div className="col-12 md:col-11 text-center">
                        <h2 className="font-bold" style={{ fontSize: "30px" }}>
                          Company Details
                        </h2>
                      </div>
                      <div className="col-12 md:col-11">
                        <InputField
                          inputClassName="border-round-md"
                          value={signupData?.company_tin_ein}
                          onChange={(e) => {
                            setSignupError({
                              ...signupError,
                              company_tin_ein: null,
                            });
                            setSignUpData({
                              ...signupData,
                              company_tin_ein: e.target.value,
                            });
                          }}
                          required
                          errorMessage={signupError?.company_tin_ein}
                          label="Company TIN/EIN"
                        />
                      </div>
                      <div className="col-12 md:col-11">
                        <InputField
                          inputClassName="border-round-md"
                          value={signupData?.company_name}
                          onChange={(e) => {
                            setSignupError({
                              ...signupError,
                              company_name: null,
                            });
                            setSignUpData({
                              ...signupData,
                              company_name: e.target.value,
                            });
                          }}
                          required
                          errorMessage={signupError?.company_name}
                          label="Company Name"
                        />
                      </div>

                      <div className="col-12 md:col-11">
                        <InputTextareaField
                          label="Street Address"
                          rows={5}
                          cols={5}
                          inputClassName="border-round-md"
                          value={signupData?.company_address}
                          onChange={(e) => {
                            setSignupError({
                              ...signupError,
                              company_address: null,
                            });
                            setSignUpData({
                              ...signupData,
                              company_address: e.target.value,
                            });
                          }}
                          required
                          errorMessage={signupError?.company_address}
                        />
                      </div>
                      <div className="col-12 md:col-11">
                        <DropdownField
                          filter
                          label="State"
                          inputId="dropdown"
                          placeholder="Select"
                          value={signupData?.company_state}
                          required
                          errorMessage={signupError?.company_state}
                          options={USA_STATES}
                          onChange={(e) => {
                            setSignupError({
                              ...signupError,
                              company_state: null,
                            });
                            setSignUpData({
                              ...signupData,
                              company_state: e.value,
                            });
                          }}
                        />
                      </div>
                      <div className="col-12 md:col-11 mb-3">
                        <InputField
                          label="City"
                          required
                          errorMessage={signupError?.company_city}
                          inputClassName="border-round-md"
                          value={signupData?.company_city}
                          onChange={(e) => {
                            setSignupError({
                              ...signupError,
                              company_city: null,
                            });
                            setSignUpData({
                              ...signupData,
                              company_city: e.target.value,
                            });
                          }}
                        />
                      </div>
                      <div className="col-12 md:col-11 mb-3">
                        <InputNumberField
                          required
                          label="Zip Code"
                          errorMessage={signupError?.company_zip_code}
                          inputClassName="border-round-md"
                          useGrouping={false}
                          maxLength={5}
                          value={signupData?.company_zip_code}
                          onValueChange={(e) => {
                            setSignupError({
                              ...signupError,
                              company_zip_code: null,
                            });
                            setSignUpData({
                              ...signupData,
                              company_zip_code: e.value,
                            });
                          }}
                        />
                      </div>
                      <div className="col-12 md:col-11">
                        <Link to="/#" className="link no-underline">
                          <Button
                            label="Next"
                            onClick={() => handleThirdPage()}
                            className="p p-3  border-round-lg"
                            style={{ background: "#006ED0" }}
                          />
                        </Link>
                      </div>
                    </div>
                  </div>
                )}
                {activeIndex === 3 && (
                  <div className="p-5">
                    <div className="p-fluid grid flex justify-content-center">
                      <div className="col-12 md:col-11 text-center">
                        <h2 className="font-bold" style={{ fontSize: "30px" }}>
                          Plan Details
                        </h2>
                      </div>
                      <div className="col-12 md:col-11 mb-3">
                        <label className="font block mb-2">
                          Select Service <code className="text-red">*</code>
                        </label>
                        <Dropdown
                          value={addServices?.subscription_plans}
                          onChange={(e) => handleChange(e)}
                          options={service}
                          optionLabel="program_name"
                          placeholder="Select Service"
                          className="w-full border-round-md "
                        />
                      </div>
                      <div className="col-12 md:col-7 mb-3  ">
                        <label className="font block mb-2">
                          Enter no of Client<code className="text-red">*</code>
                        </label>
                        <div className=" add_service_modal_increment_button  border-round-md ">
                          <InputNumber
                            inputClassName=" border-round-md w-full border-0 "
                            className="w-full"
                            inputId="minmax-buttons"
                            value={addServices?.number_of_client}
                            onValueChange={(e) =>
                              setAddServices({
                                ...addServices,
                                number_of_client: e.value,
                              })
                            }
                            mode="decimal"
                            min={0}
                            showButtons
                          />
                        </div>
                      </div>
                      <div className="col-12 md:col-4 mb-3 mt-1">
                        <div className="add_service_currency">
                          <InputNumber
                            inputClassName="border-round-md "
                            placeholder=""
                            className=" border-round-md mt-4 p-0"
                            style={{ padding: "15px" }}
                            value={totalPrice || ""}
                          ></InputNumber>
                        </div>
                      </div>
                      {signupError?.service_type && (
                        <span className="error">
                          {signupError?.service_type}
                        </span>
                      )}
                      <div className="col-12 md:col-11 mb-3 flex justify-content-between">
                        {editButton === true ? (
                          <Button
                            className="save_button text-center  border-0 text-lg font-medium karla p-3 border-round-lg bg-main"
                            label="Update"
                            onClick={() => handleUpdate()}
                          />
                        ) : (
                          <Button
                            className="save_button text-center  border-0 text-lg font-medium karla p-3 border-round-lg bg-main"
                            label="Add"
                            onClick={() => handleAddNotes()}
                          />
                        )}

                        <Button
                          className="save_button text-center  border-0 text-lg font-medium karla p-3 border-round-lg bg-main"
                          label="Cancel"
                          onClick={() => handleCancel()}
                        />
                      </div>
                      <div className="col-12 md:col-11 mb-3">
                        {tableData?.length > 0 ? (
                          <label className="font block mb-2">
                            Service plan<code className="text-red">*</code>
                          </label>
                        ) : null}
                        {tableData?.length > 0 &&
                          tableData.map((item, index) => {
                            return (
                              <div className="  plan_details_accordion  border-round-md overflow-hidden mb-3">
                                <Accordion
                                  activeIndex={1}
                                  className="text-color border-0"
                                >
                                  <AccordionTab
                                    header={
                                      item?.subscription_plans?.program_name
                                    }
                                    className="border-0 "
                                  >
                                    <div className="grid flex align-items-center">
                                      <di className="col-12 md:col-8">
                                        <p>
                                          No of Clients:{" "}
                                          {item?.number_of_client}
                                        </p>
                                      </di>
                                      <di className="col-12 md:col-4 text-right ">
                                        <p className="text-main text-xl font-bold">
                                          ${item?.price}
                                        </p>
                                      </di>
                                      <div className="col-12 md:col-6 justify-content-center flex w-max">
                                        <Button
                                          className="p-2 px-3 border-round-md bg-main text-white w-full text-center border-1 border-0 flex align-items-center w-max"
                                          onClick={() => handleEdit(index)}
                                        >
                                          <i
                                            className="pi pi-user-edit"
                                            style={{ fontSize: "1.5rem" }}
                                          ></i>
                                          <span className="ml-2">
                                            Edit Service
                                          </span>
                                        </Button>
                                      </div>
                                      <div className="col-12 md:col-6 justify-content-center flex w-max">
                                        <Button
                                          className="p-2 px-3 border-round-md bg-red-400 text-white w-full text-center border-1 border-0 flex align-items-center w-max"
                                          onClick={() => handleDelete(index)}
                                        >
                                          <img
                                            src={DeleteIcon}
                                            height={24}
                                            width={20}
                                            alt=""
                                          ></img>
                                          <span className="ml-2">
                                            Delete Service
                                          </span>
                                        </Button>
                                      </div>
                                    </div>
                                  </AccordionTab>
                                </Accordion>
                              </div>
                            );
                          })}
                      </div>
                      <div className="col-12 md:col-11 mb-3">
                        <label className="font block mb-2">
                          Discount Code<code className="text-red"></code>
                        </label>
                        <InputText
                          className="border-round-md"
                          value={signupData?.discount_code}
                          onChange={(e) => {
                            setSignupError({
                              ...signupError,
                              discount_code: null,
                            });
                            setSignUpData({
                              ...signupData,
                              discount_code: e.target.value,
                            });
                          }}
                        ></InputText>
                        {signupError?.discount_code && (
                          <span className="error">
                            {signupError?.discount_code}
                          </span>
                        )}
                      </div>
                      <div className="col-12 md:col-11">
                        <Link to="/#" className="link no-underline">
                          <Button
                            onClick={handleSignupSubmit}
                            label="Proceed to pay"
                            className="p p-3  border-round-lg"
                            style={{ background: "#006ED0" }}
                          />
                        </Link>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className="col-12 md:col-5 hospital_modal_image p-0 overflow-hidden relative">
                <img
                  src={SignUpImg}
                  alt=""
                  className="w-full h-full"
                  style={{ objectFit: "cover" }}
                />
                <div
                  className="absolute cursor-pointer"
                  style={{ top: "12px", right: "0" }}
                  onClick={() => setNextPage(false)}
                >
                  <i className="pi pi-times border-circle p-1 border-1 "></i>
                </div>
              </div>
            </div>
          </div>
        </Dialog>
      )}
    </>
  );
};
export default Signup;
