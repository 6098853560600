import { types } from "../HospitalType/types";
const intitalState = {
    forgetPasswordProfile: {},
    verifyOtpProfile: {},
    resetPasswordProfile: {}
};

export const forgetPasswordReducer = (state = intitalState, action) => {
    switch (action.type) {
        case types.FORGET_PASSWORD:
            return {
                ...state,
                forgetPasswordProfile: action.payload,
            };
        default:
            return { ...state };
    }
};


export const verifyOtpReducer = (state = intitalState, action) => {
    switch (action.type) {
        case types.VERIFY_OTP:
            return {
                ...state,
                verifyOtpProfile: action.payload
            };
        default:
            return { ...state }
    }
}

export const resetPasswordReducer = (state = intitalState, action) => {
    switch (action.type) {
        case types.RESET_PASSWORD:
            return {
                ...state,
                resetPasswordProfile: action.payload
            };
        default:
            return { ...state }
    }
}