
import React, { useState, useEffect } from "react";
import InputField from "../../../../components/input/input";
import { Card } from "primereact/card";
import { Calendar } from "primereact/calendar";
import { InputText } from "primereact/inputtext";
import ContentArea from "../../../../shared/ContentArea";
import SaveButton from "../../../../components/Buttons/SaveButton";
import CancelButton from "../../../../components/Buttons/CancelButton";
import DropdownField from "../../../../components/input/dropdown";
import { getRevenueCategoriesAction, viewRevenueAction, editRevenueAction } from "../../../../Redux/HospitalAction/expenseAction";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { useParams, useNavigate } from "react-router-dom";
import Loader from "../../../../pages/Loader";
const EditRevenue = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [categories, setCategories] = useState(null);
    const [error, setError] = useState("")
    const [data, setData] = useState({
        select_category_id: "",
        revenue_name: "",
        revenue_date: "",
        revenue_description: "",
        assignment: "",
        revenue_amount: ""
    })
    const { id } = useParams()
    useEffect(() => {
        dispatch(getRevenueCategoriesAction())
        dispatch(viewRevenueAction(id))
    }, [])
    const getRevenueCategoriesData = useSelector((state) => state.getRevenueCategories.getRevenueCategoriesProfile)
    const viewData = useSelector((state) => state.viewRevenue.viewRevenueProfile)
    useEffect(() => {
        getRevenueCategoriesData?.length > 0 && getRevenueCategoriesData?.map((item) => {
            if (item?.id === viewData?.category_id) {
                setCategories(item)
            }
        })
    }, [getRevenueCategoriesData, viewData])
    useEffect(() => {
        let obj
        if (viewData) {
            obj = {
                revenue_name: viewData?.name,
                revenue_date: new Date(viewData?.date?.split(" ")[0]),
                revenue_amount: viewData?.amount,
                revenue_description: viewData?.description,
                assignment: viewData?.assignment,
                select_category_id: viewData?.category_id
            }
        }
        setData(obj)
    }, [viewData])
    const handleChange = (e) => {
        const { name, value } = e.target
        setError({
            ...error,
            [name]: null
        })
        setData({
            ...data,
            [name]: value
        })
    }
    useEffect(() => {
        setData({
            ...data,
            select_category_id: categories?.id
        })
        setError({
            ...error,
            select_category_id: null
        })
    }, [categories])
 
    const handleSubmit = () => {
        if (data.select_category_id === "" || data.revenue_name === "" || data.revenue_amount === "" || data.assignment === "" || data.revenue_date ===
            "Invalid date") {
            setError({
                ...error,
                select_category_id: data.select_category_id === "" ? "Please Enter Select Category" : error.select_category_id,
                revenue_name: data.revenue_name === "" ? "Please Enter Revenue Name" : error.revenue_name,
                revenue_amount: data.revenue_amount === "" ? "Please Enter Revenue Amount" : error.revenue_amount,
                assignment: data.assignment === "" ? "Please Enter Assignment" : error.assignment,
                revenue_date: data.revenue_date === "Invalid date" ? "Please Select Date" : error.revenue_date
            })
            return false;
        }
        const obj = data;
        let array = Object.entries(obj)
        const formData = new FormData()
        for (let i = 0; i < array.length; i++) {
            if (array[i][0] === "revenue_date") {
                formData.append(array[i][0], moment(data.revenue_date).format('DD-MM-YYYY'))
            } else {
                formData.append(array[i][0], array[i][1])
            }

        }
        dispatch(editRevenueAction(formData,id, () => navigate("/account-owner/manage-revenue")))
    }
    const isLoading = useSelector((state) => state.loader.isLoading)
    return (
        <>
            {isLoading === true ? <Loader /> :
                <ContentArea>
                    <div className="add_expenses_container">
                        <div className="p-3">
                            <div className="grid card ml-0 mr-0 px-5 mb-5 align-items-center bg-main-light border-round-lg">
                                <h2 className="text-main">
                                    Edit Revenue
                                </h2>
                            </div>
                            <Card className="shadow-2 p-3 border-round-lg">
                                <div className="p-fluid grid mb-3">
                                    <div className="field col-12 md:col-6">
                                        <DropdownField label="Select Category" value={categories} onChange={(e) => setCategories(e.value)} options={getRevenueCategoriesData} optionLabel="category"
                                            placeholder="Select" required />
                                            {error.select_category_id && <span className="error">{error.select_category_id}</span>}
                                    </div>
                                    <div className="field col-12 md:col-6">
                                        <InputField label="Revenue Name" required value={data?.revenue_name} name="revenue_name" onChange={handleChange} />
                                        {error.revenue_name && <span className="error">{error.revenue_name}</span>}
                                    </div>
                                    <div className="field col-12 md:col-6">
                                        <InputField label="Revenue Description" value={data?.revenue_description} name="revenue_description" onChange={handleChange} />
                                    </div>
                                    <div className="field col-12 md:col-6">
                                        <label className="font block mb-2">Select Revenue Date<code className="text-red">*</code></label>
                                        <Calendar value={data?.revenue_date} onChange={(e) => {
                                            setData({
                                                ...data,
                                                revenue_date: e.value
                                            })
                                            setError({
                                                ...error,
                                                revenuee_date: null
                                            })
                                        }
                                        } className="calender_picker" inputClassName="border-round-md" />
                                        {error.expense_date && <span className="error">{error.expense_date}</span>}
                                    </div>
                                    <div className="field col-12 md:col-6">
                                        <label className="font block mb-2">Revenue Amount<code className="text-red">*</code></label>
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon bg-blue-100">$</span>
                                            <InputText placeholder="" className="border-round-right-md" value={data?.revenue_amount} name="revenue_amount" onChange={handleChange} />
                                        </div>
                                        {error.revenue_amount && <span className="error">{error.revenue_amount}</span>}
                                    </div>
                                    <div className="field col-12 md:col-6">
                                        <InputField label="Assignment" required value={data?.assignment} name="assignment" onChange={handleChange} />
                                        {error.assignment && <sapn className ="error ">{error.assignment}</sapn>}
                                    </div>
                                </div>
                                <div className="submitButton flex">
                                    <SaveButton label="Save" onClick={handleSubmit}></SaveButton>
                                    <CancelButton label="Cancel" onClick={() => navigate("/account-owner/manage-revenue")}></CancelButton>
                                </div>
                            </Card>
                        </div>
                    </div>
                </ContentArea>
            }
        </>
    )
}

export default EditRevenue;
