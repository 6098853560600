
import React,{useState} from 'react'
import { Chart } from "primereact/chart";
import LineChartData from './lineChartData'
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
export default function LineChart (props) {
  const [selectedMonthly, setSelectedmonthly] = useState(null);
  const [date, setDate] = useState(null);
  const Monthly = [
    { name: 'Monthly' },
    { name: 'Weekly' },
  ];
  const { basicOptions, lineStylesData } = LineChartData();
  return (
    <div>
      <div className="p-3 border-solid border-1 border-round-md surface-overlay border-500" style={{ height: "270px" }}>
        <p className="m-0 mb-3  text-main text-lg flex align-items-center justify-content-between font-bold Karla "> {props.title}
          <span >
          <Calendar value={date} onChange={(e) => setDate(e.value)} view="year" dateFormat="yy" inputClassName='border-round-md' placeholder='Select year' className="dashboard-calender text-color " />
          </span>
        </p>
        <Chart type="line" data={lineStylesData} options={basicOptions} height="172px" />
      </div>
    </div>

  )
}