import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getClientAction } from "../../../../Redux/HospitalAction/clientAction";
import {
  getAppointmentAction,
  appoitmnetGetServiceAction,
  addAppointmentBooking,
  selectedAppointmentAction,
  appointmentCalendarAction,
  schedulebylocation,
  serviceBySchedule,
} from "../../../../Redux/HospitalAction/appoinmentAction";
import moment from "moment";
import { useNavigate } from "react-router-dom";
export const CommonAppointmnet = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [client, setClient] = useState(null);
  const [service, setService] = useState(null);
  const [serviceData, setServiceData] = useState(null);
  const [ScheduleData, setScheduleData] = useState();
  const [pacode, setPaCode] = useState(null);
  const [location, setLocation] = useState(null);
  const [error, setError] = useState("");
  const [form, setForm] = useState({
    client_id: "",
    client_name: "",
    appointment_schedule_id: "",
    service_id: "",
    // appointment_name: "",
    date: "",
    slot_id: "",
  });
  useEffect(() => {
    dispatch(getClientAction());
    dispatch(appoitmnetGetServiceAction());
    dispatch(appointmentCalendarAction());
  }, []);

  useEffect(() => {
    if (form?.appointment_schedule_id !== "" && form?.date !== "") {
      let payload = {
        appointment_schedule_id: form?.appointment_schedule_id,
        date: moment(form?.date).format("DD-MM-YYYY"),
      };
      dispatch(selectedAppointmentAction(payload));
    }
  }, [form]);

  const selectedServiceData = useSelector(
    (state) => state.selectedAppointment.selectedAppointmentProfile
  );

  const getClientData = useSelector(
    (state) => state.getClient.getClientProfile
  );

  // const getAppointmentData = useSelector(
  //   (state) => state.getappointment.getAppointmentProfile
  // );

  const getServiceData = useSelector(
    (state) => state.appointmentGetService.appointmentGetServiceAction
  );

  const calendarData = useSelector(
    (state) => state.calendar.appointmentCalendarProfile
  );

  useEffect(() => {
    setForm({
      ...form,
      client_id: client?.id,
      client_name: client?.name,
    });
    setError({
      ...error,
      client_id: null,
    });
  }, [client]);

  useEffect(() => {
    setForm({
      ...form,
      location_id: location?.id,
    });

    if (location?.id) {
      let ScheduleList = dispatch(schedulebylocation(location?.id)).then(
        (data) => {
          setScheduleData(data);
        }
      );
    }
    setError({
      ...error,
      location_id: null,
    });
  }, [location]);

  useEffect(() => {
    setForm({
      ...form,
      appointment_schedule_id: pacode?.id,
    });
    if (location?.id) {
      let serviceList = dispatch(serviceBySchedule(pacode?.id)).then((data) => {
        setServiceData(data);
      });
    }
    setError({
      ...error,
      appointment_schedule_id: null,
    });
  }, [pacode]);

  useEffect(() => {
    setForm({
      ...form,
      service_id: service?.id,
    });
    setError({
      ...error,
      service_id: null,
    });
  }, [service]);

  const handleSubmit = () => {
    if (
      form?.client_id === "" ||
      form?.appointment_schedule_id === "" ||
      form?.date === "" ||
      form?.service_id === undefined ||
      form?.slot_id === ""
    ) {
      setError({
        ...error,
        client_id:
          form?.client_id === "" ? "Please choose client" : error.client_id,
        appointment_schedule_id:
          form?.appointment_schedule_id === ""
            ? "Please choose Schedule"
            : error.appointment_schedule_id,
        date: form?.date === "" ? "Please Choose Date" : error?.date,
        service_id:
          form?.service_id === undefined
            ? "Please Choose Service"
            : error?.service_id,
        slot_id: form?.slot_id === "" ? "Please choose Slot" : error?.slot_id,
        // appointment_name:
        //   form?.appointment_name === ""
        //     ? "Please Enter Appointment Name"
        //     : error?.appointment_name,
      });
      return false;
    }

    let payload = {
      client_id: form?.client_id,
      location_id: form?.location_id,
      appointment_schedule_id: form?.appointment_schedule_id,
      //   appointment_name: form?.appointment_name,
      date: moment(form?.date).format("DD-MM-YYYY"),
      service_id: form?.service_id,
      slot_id: form?.slot_id,
    };
    dispatch(
      addAppointmentBooking(payload, () =>
        navigate("/account-owner/manage-appointment")
      )
    );
  };

  const isLoading = useSelector((state) => state.loader.isLoading);
  return {
    getClientData,
    form,
    setForm,
    isLoading,
    client,
    setClient,
    // getAppointmentData,
    pacode,
    serviceData,
    setPaCode,
    getServiceData,
    service,
    setService,
    ScheduleData,
    handleSubmit,
    navigate,
    selectedServiceData,
    error,
    setError,
    calendarData,
    setLocation,
    location,
  };
};
