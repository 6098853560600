import React, { useState, useEffect } from "react";
import { Tree } from "primereact/tree";
const Permission = ({ permissions, selectedNodes, setSelectedNodes, value, setSelectedKeys, disabled, setError,error }) => {
    const [expandedKeys, setExpandedKeys] = useState({});
    const getSelectedKeysArray = () => {
        const selectedKeysArrays = []
        for (let keys in selectedNodes) {
            if (keys.length > 1) {
                let data = keys.split('-')[1]
                selectedKeysArrays.push(Number(data))
            }
        }
        setSelectedKeys({
            ...value,
            permissions: selectedKeysArrays,
            selected_nodes: selectedNodes
        });
        setError((prev) => {
            return {
                ...prev,
                permissions: ""
            }
        })
    }

    useEffect(() => {
        getSelectedKeysArray()
    }, [selectedNodes])
    useEffect(() => {
        let expanded = {}
        permissions?.map(element => {
            expanded[element.key] = true
        })
        setExpandedKeys(expanded)

    }, [permissions])
 
    return (
        <div className="grid">
            <div className="col-12 md:col-12">
            <div className="flex justify-content-center permissions-container ">
                <Tree value={permissions} selectionMode="checkbox" disabled={disabled ? true : false} expandedKeys={`${expandedKeys}`} selectionKeys={selectedNodes} onSelectionChange={(e) => setSelectedNodes(e.value)} className="w-full"  expanded = {true} />
            </div>
            {error?.permissions && <span className="error">{error?.permissions}</span>}
            </div>
        </div>
    )
}
export default Permission;