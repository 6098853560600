
import React, { useState } from "react";
import { Card } from "primereact/card";
import ContentArea from "../../../../shared/ContentArea";
import SaveButton from "../../../../components/Buttons/SaveButton";
import CancelButton from "../../../../components/Buttons/CancelButton";
import { AddEmployeeCertificationList } from "../../../../Redux/HospitalAction/certificationAction";
import { useDispatch, useSelector } from "react-redux";
import InputField from "../../../../components/input/input";
import { useNavigate } from "react-router-dom";
import Loader from "../../../../pages/Loader";
const AddEmployeeCertification = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [certification, setCertification] = useState({
        certification_name: ""
    });
    const [error, setError] = useState("")
    const handleChange = (e) => {
        const { name, value } = e.target
        setError({
            ...error,
            [name]: null
        })
        setCertification({
            ...certification,
            [name]: value
        })
    }
    const handleSubmit = () => {
        if (certification.certification_name === "") {
            setError({
                ...error,
                certification_name: certification.certification_name === "" ? "Please Enter Certification Name" : error.certification_name
            })
            return false;
        }
        const obj = certification;
        let array = Object.entries(obj)
        const formData = new FormData()
        for (let i = 0; i < array.length; i++) {
            formData.append(array[i][0], array[i][1])
        }
        dispatch(AddEmployeeCertificationList(formData, () => navigate("/account-owner/manage-employee-certification")))
    }
    const isLoading = useSelector((state) => state.loader.isLoading)
    return (
        <>
            {isLoading === true ? <Loader /> :
                <ContentArea>
                    <div className="add_employee_certification_container">
                        <div className="p-3">
                            <div className="grid card ml-0 mr-0 px-5 mb-5 align-items-center bg-main-light border-round-lg">
                                <h2 className="text-main">
                                    Add Employee Certification
                                </h2>
                            </div>
                            <Card className="shadow-2 p-3 border-round-lg">
                                <div className="p-fluid grid mb-5">
                                    <div className="col-12 md:col-6">
                                        <label className="font block mb-2">Add Certificate Name </label>
                                        <InputField name="certification_name" value={certification.certification_name} onChange={handleChange} />
                                        {error.certification_name && <span className="error">{error.certification_name}</span>}
                                    </div>
                                </div>
                                <div className="submitButton flex">
                                    <SaveButton label="Save" onClick={handleSubmit}></SaveButton>
                                    <CancelButton label="Cancel" onClick={() => navigate("/account-owner/manage-employee-certification")}></CancelButton>
                                </div>
                            </Card>
                        </div>
                    </div>
                </ContentArea>
            }
        </>
    )
}

export default AddEmployeeCertification;
