import React from "react";
import { Calendar } from "primereact/calendar";

export default function CalendarField({
  label,
  name,
  errorMessage,
  extraClassName,
  required,
  inputClass,
  disabled,
  onChange,
  icon,

  ...props
}) {
  return (
    <div className="grid">
      <div className="col-12 md:col-6 w-full">
        <label htmlFor={name}>
          {label} {required && <span className="error">*</span>}
        </label>
        <Calendar
          id={name}
          disabled={disabled}
          name={name}
          className={`w-full mt-2 border-round-md ${inputClass} ${
            errorMessage ? "p-invalid" : null
          }`}
          {...props}
          onChange={onChange}
        />
        {errorMessage ? <small className="error">{errorMessage}</small> : null}
      </div>
    </div>
  );
}
