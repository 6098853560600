import { Button } from "primereact/button";
import React from "react";

export default function CustomButton({ onClick, label, btnclass }) {
  return (
    <Button
      onClick={onClick}
      label={label}
      className={`  text-center border-0 p-3  karla border-round-lg ${btnclass}`}
    />
  );
}
