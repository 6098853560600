
import React, { useState } from "react";
import InputField from "../../../../components/input/input";
import { Card } from "primereact/card";
import { InputTextarea } from 'primereact/inputtextarea';
import ContentArea from "../../../../shared/ContentArea";
import SaveButton from "../../../../components/Buttons/SaveButton";
import CancelButton from "../../../../components/Buttons/CancelButton";
import InputTextareaField from "../../../../components/input/inputTextArea";
import { addClientTiersAtion } from "../../../../Redux/HospitalAction/clientAction";
import { useDispatch,useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loader from "../../../../pages/Loader";
const AddClientTier = () => {
    const dispatch =  useDispatch()
    const navigate =  useNavigate()
    const[data,setData] =  useState({
        tiers_name:"",
        tiers_description:""
    })
    const[error,setError] =  useState("")
    const handleChange = (e)=>{
        const {name,value} =  e.target
        setError({
            ...error,
                [name]:null
        })
        setData({
            ...data,
            [name]:value
        })
    }
    const handleSubmit = ()=>{
        if(data.tiers_description === "" || data.tiers_name === ""){
            setError({
                ...error,
                tiers_description:data.tiers_description === "" ? "Please Enter Tier Description":error.tiers_description,
                tiers_name:data.tiers_name === ""?"Please Enter Tier Name":error.tiers_name
            })
            return false;
        }
        const obj = data;
        let array = Object.entries(obj)
        const formData = new FormData()
        for(let i = 0;i<array.length;i++){
            formData.append(array[i][0], array[i][1])
        }
        dispatch(addClientTiersAtion(formData,()=>navigate("/account-owner/manage-tiers")))
    }
    const isLoading = useSelector((state) => state.loader.isLoading)
    return (
        <>
        {isLoading === true ? <Loader/>:
            <ContentArea>
                <div className="add_client_tier_container">
                    <div className="p-3">
                        <div className="grid card ml-0 mr-0 px-5 mb-5 align-items-center bg-main-light border-round-lg">
                            <h2 className="text-main">
                                Add Tier
                            </h2>
                        </div>
                        <Card className="shadow-2 p-3 border-round-lg">
                            <div className="p-fluid grid mb-5">
                                <div className="field col-12 md:col-12">
                                    <InputField label = "Tier Name" required value = {data.tiers_name} name = "tiers_name" onChange={handleChange}/>
                                    {error.tiers_name && <sapn className = "error">{error.tiers_name}</sapn>}
                                </div>
                                <div className="field col-12 md:col-12">
                                    <InputTextareaField label = "Tier Description" required value={data.tiers_description} onChange={handleChange} name = "tiers_description" rows={5} cols={30} />
                                    {error.tiers_description && <span className="error">{error.tiers_description}</span>}
                                </div>
                            </div>
                            <div className="submitButton flex">
                                <SaveButton label="Save" onClick={handleSubmit}></SaveButton>
                                <CancelButton label="Cancel" onClick={()=>navigate("/account-owner/manage-tiers")}></CancelButton>
                            </div>
                        </Card>
                    </div>
                </div>
            </ContentArea>
}

        </>
    )
}

export default AddClientTier;
