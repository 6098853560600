import { types } from "../HospitalType/types";
const intitalState = {
    getPaCodeProfile: [],
    deletePaCodeProfile: [],
    filterPaCode: [],
    addPaCodeProfile: [],
    viewPaCodeProfile: {},
    editPaCodeProfile: []
}

export const getPaCodeReducer = (state = intitalState, action) => {
    switch (action.type) {
        case types.GET_PA_CODES:
            return {
                ...state,
                getPaCodeProfile: action.payload
            }
        default:
            return { ...state };
    }
}
export const getfilterPaCodeReducer = (state = intitalState, action) => {
    switch (action.type) {
        case types.GET_PA_CODES:
            return {
                ...state,
                filterPaCode: action.payload
            }
        default:
            return { ...state };
    }
}
export const deletePaCodesReducer = (state = intitalState, action) => {
    switch (action.type) {
        case types.DELETE_PA_CODES:
            return {
                ...state,
                deletePaCodeProfile: action.payload
            }
        default:
            return { ...state }
    }
}

export const addPaCodeReducer = (state = intitalState, action) => {
    switch (action.type) {
        case types.ADD_PA_CODE:
            return {
                ...state,
                addPaCodeProfile: action.payload
            }
        default:
            return { ...state }
    }
}

export const viewPaCodeReducer = (state = intitalState, action) => {
    switch (action.type) {
        case types.VIEW_PA_CODE:
            return {
                ...state,
                viewPaCodeProfile: action.payload
            }
        default:
            return { ...state }
    }
}

export const editPaCodeReducer = (state = intitalState, action) => {
    switch (action.type) {
        case types.EDIT_PA_CODE:
            return {
                ...state,
                editPaCodeProfile: action.payload
            }
        default:
            return { ...state }
    }
}