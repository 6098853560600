import { InputTextarea } from "primereact/inputtextarea";
import React from 'react'

export default function InputTextareaField({ label, name, errorMessage, extraClassName, required, inputClass, onChange, ...props }) {
    return (
        <div className='grid'>
            <div className='col-12 md:col-6 w-full'>
                <label htmlFor={name}>
                    {label} {required && <span className="error">*</span>}
                </label>
                    <InputTextarea  id={name} name={name} className={`w-full mt-2 border-round-md ${inputClass} ${errorMessage ? "p-invalid" : null}`} {...props} onChange = {onChange}/>
                    {errorMessage ? <small className="error">{errorMessage}</small> : null}
            </div>
        </div>
    )
}