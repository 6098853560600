import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import EyeIcon from "../../assets/icons/eye.png";
import EditIcon from "../../assets/icons/edit.png";
import DeleteIcon from "../../assets/icons/delete.png";
import NoteInnerIcon from "../../assets/icons/NoteInner.png";
import CheckboxIcon from "../../assets/icons/chcekblue.png";
import { Paginator } from "primereact/paginator";
import { useNavigate } from "react-router-dom";
import "../../assets/sass/components/table.scss";
import { FilterMatchMode } from "primereact/api";
import checkboxIcon from "../../assets/icons/chekcbox.png";
import moment from "moment";
import TableAddButton from "../../views/sales-person/dashboard/clients/TableCountButton";
export default function Table({
  tableHeading,
  setTableData,
  showGridlines,
  setAddNotes,
  setAddService,
  setGetIndex,
  setEditButton,
  tableData,
  emptyMessage = "Data not  available",
  editButton,
  setDeleteButton,
  deleteButton,
  data,
  setData,
  filters,
  title,
  handleGrandTotal,
  programData,
  setProgramData,
  selectedProducts,
  setSelectedProducts,
}) {
  const [count, setCount] = useState(0);
  const [newTableData, setNewTableData] = useState([]);
  const [rows, setRows] = useState(10);
  const navigate = useNavigate();

  const tableAddButton = (col) => {
    return (
      <div style={{ textAlign: "left" }}>
        <TableAddButton
          count={count}
          setCount={setCount}
          col={col}
          programData={programData}
          setProgramData={setProgramData}
          selectedProducts={selectedProducts}
          setSelectedProducts={setSelectedProducts}
        />
      </div>
    );
  };

  const onPageChange = (event) => {
    let startPage = event.page + 1;
    let itemsPerPage = event.rows;
    let value = [];
    for (var i = 0; i < tableData.length; i++) {
      if (i >= (startPage - 1) * itemsPerPage && i < startPage * itemsPerPage) {
        value.push(tableData[i]);
      }
    }
    setNewTableData(value);
    setCount(event.first);
    setRows(event.rows);
  };

  useEffect(() => {
    let startPage = 1;
    let itemsPerPage = rows;
    let value = [];
    for (var i = 0; i < tableData?.length; i++) {
      if (i >= (startPage - 1) * itemsPerPage && i < startPage * itemsPerPage) {
        value.push(tableData[i]);
      }
    }
    setNewTableData(value);
    // setCount(0)
  }, [tableData]);
  const runEdit = () => {
    editButton((prev) => !prev);
  };

  // const [deleteButton, setDeleteButton] = useState(false)
  const setVisible = (col) => {
    setDeleteButton(true);
    setData(col.id ? col.id : col.Id);
    // setFilters( {global: { value: null, matchMode: FilterMatchMode.CONTAINS }})
  };

  const srnoTemplates = (col, i) => {
    return <span>{count + i.rowIndex + 1}</span>;
  };

  const AddCheckboxTemple = (col) => {
    return (
      <div className="flex">
        <img
          alt=""
          src={checkboxIcon}
          alt=""
          width="20px"
          height="18px"
          className="product-image "
        />
      </div>
    );
  };

  const imageBodyTemplate = (col) => {
    return (
      <div className="flex">
        <img
          alt=""
          src={EyeIcon}
          onClick={() => navigate(`/sales-person/view-clients/${col.id}`)}
          alt=""
          width="20px"
          height="18px"
          className="product-image "
        />
        <img
          alt=""
          src={EditIcon}
          onClick={() => navigate(`/sales-person/edit-clients/${col.id}`)}
          width="20px"
          height="20px"
          className="product-image ml-3"
          alt=""
        />
        <img
          alt=""
          src={DeleteIcon}
          onClick={() => setVisible(col)}
          width="20px"
          height="20px"
          className="product-image ml-3"
          alt=""
        />
      </div>
    );
  };

  // sales person leads template
  const leadsIconBodyTemplate = (col) => {
    return (
      <div className="flex">
        {col.lead_status === "sold" ? (
          <img
            alt=""
            src={CheckboxIcon}
            alt=""
            width="20px"
            height="20px"
            className="product-image "
            style={{ opacity: "0.3" }}
            // onClick={()=>navigate(`/sales-person/add-client/${col.id}`)}
          />
        ) : (
          <img
            alt=""
            src={CheckboxIcon}
            alt=""
            width="20px"
            height="20px"
            className="product-image "
            onClick={() => navigate(`/sales-person/add-client/${col.id}`)}
          />
        )}
        <img
          alt=""
          src={EyeIcon}
          onClick={() => navigate(`/sales-person/view-leads/${col.id}`)}
          alt=""
          width="20px"
          height="18px"
          className="product-image ml-3"
        />
        <img
          alt=""
          src={EditIcon}
          onClick={() => navigate(`/sales-person/edit-leads/${col.id}`)}
          width="20px"
          height="20px"
          className="product-image ml-3"
        />
        <img
          alt=""
          src={DeleteIcon}
          onClick={() => setVisible(col)}
          width="20px"
          height="20px"
          className="product-image ml-3"
        />
      </div>
    );
  };

  // sales person clients template
  const clientsIconBodyTemplate = (col) => {
    return (
      <div className="flex">
        <img
          alt=""
          src={EyeIcon}
          onClick={() => navigate("/sales-person/view-clients")}
          alt=""
          width="20px"
          height="15px"
          className="product-image "
        />
        <img
          alt=""
          src={EditIcon}
          onClick={() => navigate("/sales-person/edit-clients")}
          width="20px"
          height="20px"
          className="product-image ml-3"
        />
        <img
          alt=""
          src={DeleteIcon}
          onClick={() => setVisible(true)}
          width="20px"
          height="20px"
          className="product-image ml-3"
        />
      </div>
    );
  };

  // sales person reports template
  const reportsEyeBodyTemplate = (col) => {
    return (
      <div className="flex ">
        <img
          alt=""
          src={EyeIcon}
          alt=""
          width="20px"
          height="15px"
          className="product-image "
        />
      </div>
    );
  };

  //  Account Owner manage provider Template
  const manageIconsBodyTemplate = (col) => {
    return (
      <div className="flex">
        <img
          alt=""
          src={EyeIcon}
          onClick={() => navigate(`/account-owner/view-providers/${col.id}`)}
          alt=""
          width="20px"
          height="15px"
          className="product-image "
        />
        <img
          alt=""
          src={EditIcon}
          onClick={() => navigate(`/account-owner/edit-providers/${col.id}`)}
          width="20px"
          height="20px"
          className="product-image ml-3"
        />
        <img
          alt=""
          src={DeleteIcon}
          onClick={() => setVisible(col)}
          width="20px"
          height="20px"
          className="product-image ml-3"
        />
      </div>
    );
  };

  // Account Owner Manage PA code Template
  const paCodeIconsBodyTemplate = (col) => {
    return (
      <div className="flex">
        <img
          alt=""
          src={EditIcon}
          width="20px"
          height="20px"
          className="product-image "
          onClick={() => navigate(`/account-owner/edit-pa-code/${col.id}`)}
        />
        <img
          alt=""
          src={DeleteIcon}
          onClick={() => setVisible(col)}
          width="20px"
          height="20px"
          className="product-image ml-3"
        />
      </div>
    );
  };

  // Account Owner Manage employee Template
  const manageEmployeesIconsBodyTemplate = (col) => {
    return (
      <div className="flex">
        <img
          alt=""
          src={EyeIcon}
          onClick={() => navigate(`/account-owner/view-employees/${col.id}`)}
          width="20px"
          height="15px"
          className="product-image "
        />
        <img
          alt=""
          src={EditIcon}
          onClick={() => navigate(`/account-owner/edit-employees/${col.id}`)}
          width="20px"
          height="20px"
          className="product-image ml-3"
        />
        <img
          alt=""
          src={DeleteIcon}
          onClick={() => setVisible(col)}
          width="20px"
          height="20px"
          className="product-image ml-3"
        />
      </div>
    );
  };

  // Account Owner Manage employee Template
  const SchedulingIconsBodyTemplate = (col) => {
    return (
      <div className="flex">
        <img
          alt=""
          src={EyeIcon}
          onClick={() =>
            navigate(`/account-owner/view_scheduling_details/${col.Id}`)
          }
          width="20px"
          height="15px"
          className="product-image "
        />
        <img
          alt=""
          src={EditIcon}
          onClick={() => navigate(`/account-owner/edit-scheduling/${col.Id}`)}
          width="20px"
          height="20px"
          className="product-image ml-3"
        />
        <img
          alt=""
          src={DeleteIcon}
          onClick={() => setVisible(col)}
          width="20px"
          height="20px"
          className="product-image ml-3"
        />
      </div>
    );
  };

  // Account Owner Manage Rorly Rate  Template
  const hourlyIconsBodyTemplate = (col) => {
    return (
      <div className="flex">
        <img
          alt=""
          src={EditIcon}
          onClick={() => navigate(`/account-owner/edit-hourly-rate/${col.id}`)}
          width="20px"
          height="20px"
          className="product-image "
        />
        <img
          alt=""
          src={DeleteIcon}
          onClick={() => setVisible(col)}
          width="20px"
          height="20px"
          className="product-image ml-3"
        />
      </div>
    );
  };

  // Account Owner manage client PA Code Template
  const clientPaCodeIconsBodyTemplate = (col) => {
    return (
      <div className="flex">
        <img
          alt=""
          src={EyeIcon}
          onClick={() =>
            navigate(`/account-owner/view-client-pa-codes/${col.id}`)
          }
          alt=""
          width="20px"
          height="15px"
          className="product-image "
        />
        <img
          alt=""
          src={EditIcon}
          onClick={() =>
            navigate(`/account-owner/edit-client-pa-codes/${col.id}`)
          }
          width="20px"
          height="20px"
          className="product-image ml-3"
        />
        <img
          alt=""
          src={DeleteIcon}
          onClick={() => setVisible(col)}
          width="20px"
          height="20px"
          className="product-image ml-3"
        />
      </div>
    );
  };

  // Account Owner Manage Tire Template
  const tierIconsBodyTemplate = (col) => {
    return (
      <div className="flex">
        <img
          alt=""
          src={EditIcon}
          onClick={() => navigate(`/account-owner/manage-edit-tier/${col.id}`)}
          width="20px"
          height="20px"
          className="product-image"
        />
        <img
          alt=""
          src={DeleteIcon}
          onClick={() => setVisible(col)}
          width="20px"
          height="20px"
          className="product-image ml-3"
        />
      </div>
    );
  };

  const appointmentServiceName = (col) => {
    var text = col.service_name;
    if (text.length > 40) {
      text = text.substring(0, 40) + "...";
    }
    return <div>{text}</div>;
  };
  const tierDescriptionBody = (col) => {
    var text = col.tiers_description;

    if (text.length > 40) {
      text = text.substring(0, 40) + "...";
    }
    return <div>{text}</div>;
  };
  const categoryNameBodyTemplate = (col) => {
    var text = col.category;

    if (text.length > 40) {
      text = text.substring(0, 40) + "...";
    }
    return <div>{text}</div>;
  };
  const clientCertification = (col) => {
    var text = col.certification_name;
    if (text.length > 40) {
      text = text.substring(0, 40) + "...";
    }
    return <div>{text}</div>;
  };
  // Account Owner Manage Client List Template
  const clientListIconBodyTemplate = (col) => {
    return (
      <div className="flex">
        <img
          alt=""
          src={EyeIcon}
          onClick={() =>
            navigate(`/account-owner/view-client-details/${col.id}`)
          }
          alt=""
          width="20px"
          height="15px"
          className="product-image "
        />
        <img
          alt=""
          src={EditIcon}
          onClick={() =>
            navigate(`/account-owner/edit-client-details/${col.id}`)
          }
          width="20px"
          height="20px"
          className="product-image ml-3"
        />
        <img
          alt=""
          src={DeleteIcon}
          onClick={() => setVisible(col)}
          width="20px"
          height="20px"
          className="product-image ml-3"
        />
      </div>
    );
  };

  // Account Owner Manage employee certification  Template
  const employeeCertificaticonBodyTemplate = (col) => {
    return (
      <div className="flex">
        <img
          alt=""
          src={EditIcon}
          onClick={() =>
            navigate(`/account-owner/edit-employee-certification/${col.id}`)
          }
          width="20px"
          height="20px"
          className="product-image"
        />
        <img
          alt=""
          src={DeleteIcon}
          onClick={() => setVisible(col)}
          width="20px"
          height="20px"
          className="product-image ml-3"
        />
      </div>
    );
  };

  // Account Owner Manage Client certification  Template
  const clientCertificaticonBodyTemplate = (col) => {
    return (
      <div className="flex">
        <img
          alt=""
          src={EditIcon}
          onClick={() =>
            navigate(`/account-owner/edit-client-certification/${col.id}`)
          }
          width="20px"
          height="20px"
          className="product-image"
        />
        <img
          alt=""
          src={DeleteIcon}
          onClick={() => setVisible(col)}
          width="20px"
          height="20px"
          className="product-image ml-3"
        />
      </div>
    );
  };
  // Account Owner Manage individual certification  Template
  const individualCertificaticonBodyTemplate = (col) => {
    return (
      <div className="flex">
        <img
          alt=""
          src={EyeIcon}
          onClick={() =>
            navigate(`/account-owner/view-individual-certification/${col.id}`)
          }
          alt=""
          width="20px"
          height="15px"
          className="product-image "
        />
        <img
          alt=""
          src={EditIcon}
          onClick={() =>
            navigate(`/account-owner/edit-individual-certification/${col.id}`)
          }
          width="20px"
          height="20px"
          className="product-image ml-3"
        />
        <img
          alt=""
          src={DeleteIcon}
          onClick={() => setVisible(col)}
          width="20px"
          height="20px"
          className="product-image ml-3"
        />
      </div>
    );
  };
  const clientCertificaticonTemplate = (col) => {
    return (
      <div className="flex">
        <img
          alt=""
          src={EyeIcon}
          onClick={() =>
            navigate(`/account-owner/view-certification/${col.id}`)
          }
          alt=""
          width="20px"
          height="15px"
          className="product-image "
        />
        <img
          alt=""
          src={EditIcon}
          onClick={() =>
            navigate(`/account-owner/edit-certification/${col.id}`)
          }
          width="20px"
          height="20px"
          className="product-image ml-3"
        />
        <img
          alt=""
          src={DeleteIcon}
          onClick={() => setVisible(col)}
          width="20px"
          height="20px"
          className="product-image ml-3"
        />
      </div>
    );
  };
  // Account Owner appointment and shedule Manage service  Template
  const manageserviceiconBodyTemplate = (col) => {
    return (
      <div className="flex">
        <img
          alt=""
          src={EditIcon}
          onClick={() =>
            navigate(`/account-owner/add-manage-services/${col.id}`)
          }
          width="20px"
          height="20px"
          className="product-image"
        />
        <img
          alt=""
          src={DeleteIcon}
          onClick={() => setVisible(col)}
          width="20px"
          height="20px"
          className="product-image ml-3"
        />
      </div>
    );
  };
  // Account Owner appointment and shedule Manage service  Template
  const manageappointmenticonBodyTemplate = (col) => {
    return (
      <div className="flex">
        <img
          alt=""
          src={EditIcon}
          width="20px"
          height="20px"
          className="product-image"
        />
        <img
          alt=""
          src={DeleteIcon}
          onClick={() => setVisible(true)}
          width="20px"
          height="20px"
          className="product-image ml-3"
        />
      </div>
    );
  };

  // Account Owner Medication Schedule  Template
  const medicationiconBodyTemplate = (col) => {
    return (
      <div className="flex">
        <img
          alt=""
          src={EditIcon}
          onClick={() => navigate(`/account-owner/edit-schedule/${col?.id}`)}
          width="20px"
          height="20px"
          className="product-image"
        />
        <img
          alt=""
          src={DeleteIcon}
          onClick={() => setVisible(col)}
          width="20px"
          height="20px"
          className="product-image ml-3"
        />
      </div>
    );
  };

  // Account Owner Medication Add Schedule  Template
  const addscheduleiconBodyTemplate = (col) => {
    //here
    return (
      <div className="flex">
        <img
          alt=""
          src={EditIcon}
          // onClick={() => navigate("/account-owner/edit-schedule")}
          onClick={() => setData(col.id ? col.id : col.Id)}
          width="20px"
          height="20px"
          className="product-image"
        />
        <img
          alt=""
          src={DeleteIcon}
          onClick={() => setVisible(col)}
          width="20px"
          height="20px"
          className="product-image ml-3"
        />
      </div>
    );
  };

  // Account Owner manage Expenses Categories Template
  const revenueCategoriesiconBodyTemplate = (col) => {
    return (
      <div className="flex">
        <img
          alt=""
          src={EditIcon}
          onClick={() =>
            navigate(`/account-owner/edit-revenue-categories/${col.id}`)
          }
          width="20px"
          height="20px"
          className="product-image"
        />
        <img
          alt=""
          src={DeleteIcon}
          onClick={() => setVisible(col)}
          width="20px"
          height="20px"
          className="product-image ml-3"
        />
      </div>
    );
  };
  const certicationLastDateTemplate = (col) => {
    return <div>{moment(col.last_date).format("DD-MM-YYYY")}</div>;
  };
  const statusColorTemplate = (col) => {
    return (
      <span
        style={
          col.status === "inactive"
            ? { color: "rgba(255, 0, 0, 1)" }
            : col.status === "active"
            ? { color: "rgba(0, 110, 208, 1)" }
            : { color: "rgba(241, 218, 7, 1)" }
        }
      >
        {col?.status}
      </span>
    );
  };
  // Account Owner manage Expenses Categories Template
  const expensesCategoriesiconBodyTemplate = (col) => {
    return (
      <div className="flex">
        <img
          alt=""
          src={EditIcon}
          onClick={() =>
            navigate(`/account-owner/edit-expenses-categories/${col.id}`)
          }
          width="20px"
          height="20px"
          className="product-image"
        />
        <img
          alt=""
          src={DeleteIcon}
          onClick={() => setVisible(col)}
          width="20px"
          height="20px"
          className="product-image ml-3"
        />
      </div>
    );
  };
  const showAmountSign = (col) => {
    return <div>${col.amount}</div>;
  };
  const showDescription = (col) => {
    var text = col?.description;
    if (text?.length > 40) {
      text = text.substring(0, 40) + "...";
    }
    return <div>{text === null ? "-" : text}</div>;
  };
  const certicationNameTemplate = (col) => {
    var text = col?.certificate_name;
    if (text?.length > 20) {
      text = text.substring(0, 20) + "...";
    }
    return <div>{text === null ? "-" : text}</div>;
  };
  const revenueCategoryTemplate = (col) => {
    var text = col?.category;

    if (text?.length > 40) {
      text = text.substring(0, 40) + "...";
    }
    return <div>{text}</div>;
  };
  const descriptionRevenueTemplate = (col) => {
    var text = col?.description;
    if (text?.length > 40) {
      text = text.substring(0, 40) + "...";
    }
    return <div>{text === null ? "-" : text}</div>;
  };
  // Account Owner manage Expenses Categories Template
  const expensesiconBodyTemplate = (col) => {
    return (
      <div className="flex">
        <img
          alt=""
          src={EyeIcon}
          onClick={() => navigate(`/account-owner/view-expenses/${col.id}`)}
          alt=""
          width="20px"
          height="15px"
          className="product-image "
        />
        <img
          alt=""
          src={EditIcon}
          onClick={() => navigate(`/account-owner/edit-expenses/${col.id}`)}
          width="20px"
          height="20px"
          className="product-image ml-3"
        />
        <img
          alt=""
          src={DeleteIcon}
          onClick={() => setVisible(col)}
          width="20px"
          height="20px"
          className="product-image ml-3"
        />
      </div>
    );
  };
  const EmployeeactiveTemplate = (col) => {
    return (
      <span className={col?.is_active === 1 ? "text-green" : "text-red"}>
        {col?.is_active === 0 ? "Inactive" : "Active"}
      </span>
    );
  };
  // Account Owner manage Revenue Template
  const revenueiconBodyTemplate = (col) => {
    return (
      <div className="flex">
        <img
          alt=""
          src={EyeIcon}
          onClick={() => navigate(`/account-owner/view-revenue/${col.id}`)}
          alt=""
          width="20px"
          height="15px"
          className="product-image "
        />
        <img
          alt=""
          src={EditIcon}
          onClick={() => navigate(`/account-owner/edit-revenue/${col.id}`)}
          width="20px"
          height="20px"
          className="product-image ml-3"
        />
        <img
          alt=""
          src={DeleteIcon}
          onClick={() => setVisible(col)}
          width="20px"
          height="20px"
          className="product-image ml-3"
        />
      </div>
    );
  };
  const AddNoteBodyTemplate = () => {
    return (
      <div className="flex">
        <img
          alt=""
          src={EditIcon}
          width="20px"
          height="20px"
          className="product-image ml-3"
        />
        <img
          alt=""
          src={DeleteIcon}
          width="20px"
          height="20px"
          className="product-image ml-3"
        />
      </div>
    );
  };
  // Account Owner manage Revenue Template
  const expensesreportsiconBodyTemplate = (col) => {
    return (
      <div className="flex">
        <img
          alt=""
          src={EyeIcon}
          onClick={() => navigate("/account-owner/expense-revenue-report")}
          alt=""
          width="20px"
          height="15px"
          className="product-image "
        />
      </div>
    );
  };
  const employeeWorkingHoursAction = (col) => {
    return (
      <div className="flex">
        <img
          alt=""
          src={EyeIcon}
          onClick={() => navigate("/account-owner/add-employee-working-hour")}
          alt=""
          width="20px"
          height="15px"
          className="product-image "
        />
      </div>
    );
  };
  const noteBodyTemplate = (col) => {
    return (
      <div className="flex">
        <img
          alt=""
          src={EyeIcon}
          alt=""
          width="20px"
          height="15px"
          className="product-image "
        />
        <img
          alt=""
          src={NoteInnerIcon}
          alt=""
          width="20px"
          height="20px"
          className="product-image ml-3"
        />
      </div>
    );
  };

  const handleDelete = (row) => {
    let data = tableData.filter((item, index) => {
      return row !== index;
    });
    setTableData(data);
  };
  const handleEdit = (row, col) => {
    setGetIndex(row.rowIndex);
    setEditButton(true);
    setAddNotes({
      date: new Date(col.date),
      note: col.note,
    });
  };
  const AddLeadsBodyTemplate = (col, row) => {
    return (
      <div className="flex">
        <img
          alt=""
          src={EditIcon}
          onClick={() => handleEdit(row, col)}
          width="20px"
          height="20px"
          className="product-image"
        />
        <img
          alt=""
          src={DeleteIcon}
          width="20px"
          height="20px"
          className="product-image ml-3"
          onClick={() => handleDelete(row.rowIndex)}
        />
      </div>
    );
  };
  const handleTimeEdit = (row, col) => {
    setGetIndex(row.rowIndex);
    setEditButton(true);
    setAddService({
      client_id: col.client_id,
      service_plan_id: col.service_plan_id,
      start_time: col.start_time,
      end_time: col.end_time,
    });
  };
  const AddTimeBodyTemplate = (col, row) => {
    return (
      <div className="flex">
        <img
          alt=""
          src={EditIcon}
          onClick={() => handleTimeEdit(row, col)}
          width="20px"
          height="20px"
          className="product-image"
        />
        <img
          alt=""
          src={DeleteIcon}
          width="20px"
          height="20px"
          className="product-image ml-3"
          onClick={() => handleDelete(row.rowIndex)}
        />
      </div>
    );
  };
  const addLeadsDateTemplate = (col) => {
    let date = new Date(col.date);
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let dt = date.getDate();
    if (dt < 10) {
      dt = "0" + dt;
    }
    if (month < 10) {
      month = "0" + month;
    }
    col.date = year + "-" + month + "-" + dt;
    return <div>{col.date}</div>;
  };
  // empName template
  const empNameTemplate = (col) => {
    return col.empName ? (
      <span className="text-main">{col.empName}</span>
    ) : null;
  };

  // email Template
  const emailTemplate = (col) => {
    return col.email ? <span className="text-main">{col.email}</span> : null;
  };

  // active/Deactive
  const activeTemplate = (col) => {
    return col.status ? (
      <span className={col.status == "Active" ? "text-green" : "text-red"}>
        {col.status}
      </span>
    ) : null;
  };
  const ProgramDetails = (col) => {
    return (
      <>
        {col?.subscription_details?.length > 0 &&
          col?.subscription_details?.map((item) => {
            return <div>{item?.subscription_plan?.program_name}</div>;
          })}
      </>
    );
  };
  const workingHoursTemplate = (col) => {
    return (
      <span style={{ color: "#0C8D00" }}>{col?.employee_working_hour} hr</span>
    );
  };
  const chnageEmailProvider = (col) => {
    return <span style={{ color: "#006ED0" }}>{col?.email}</span>;
  };
  const chnageServiceProvider = (col) => {
    return (
      <span
        style={
          col.status === "inactive"
            ? { color: "#FF0000" }
            : { color: "#0C8D00" }
        }
      >
        {col?.status}
      </span>
    );
  };
  const chageLeadColor = (col) => {
    return (
      <span
        style={
          col.lead_status === "follow_up"
            ? { color: "#F9AE2B" }
            : col.lead_status === "intial_contact"
            ? { color: "#006ED0" }
            : col.lead_status === "not_interested"
            ? { color: "#FF0000" }
            : col.lead_status === "sold"
            ? { color: "#0C8D00" }
            : { color: "black" }
        }
      >
        {col.lead_status}
      </span>
    );
  };
  const chageClientColor = (col) => {
    return (
      <span
        style={
          col.lead_status === "processing"
            ? { color: "red" }
            : { color: "green" }
        }
      >
        {col.lead_status}
      </span>
    );
  };
  const selectedData = (e) => {
    setSelectedProducts(e.value);
  };
  const serviceNameTemplate = (col) => {
    return <div>{col?.service_plan_id?.program_name}</div>;
  };
  const clientNameTemplate = (col) => {
    return <div>{col?.client_id?.name}</div>;
  };
  const startTimeTemplate = (col) => {
    return <div>{col?.start_time}</div>;
  };
  const editTimeTemplate = (col) => {
    return <div>{col?.end_time}</div>;
  };
  const BookingBodyTemplate = (col) => {
    return <span>{moment(col.date).format("DD-MM-YYYY")}</span>;
  };
  const showAppointmentDayTemplate = (col) => {
    return (
      <div>
        {col?.appointment_week?.length > 0 &&
          col?.appointment_week?.map((item) => {
            return <div>{item?.dayName}</div>;
          })}
      </div>
    );
  };
  const showAppointmentTemplate = (col) => {
    return (
      <div className="flex">
        <img
          alt=""
          src={EditIcon}
          onClick={() => navigate(`/account-owner/edit-schedules/${col.id}`)}
          width="20px"
          height="20px"
          className="product-image ml-3"
        />
      </div>
    );
  };
  const ActionTemplate = (col) => {
    return (
      <div className="flex">
        <img
          alt=""
          src={EditIcon}
          onClick={() => navigate(`/sales-person/edit-clients/${col.id}`)}
          width="20px"
          height="20px"
          className="product-image ml-3"
        />
        <img
          alt=""
          src={DeleteIcon}
          onClick={() => setVisible(col)}
          width="20px"
          height="20px"
          className="product-image ml-3"
        />
      </div>
    );
  };

  // dynamicColumns
  const dynamicColumns = tableHeading
    ? tableHeading.map((col, i) => {
        return col.selectionMode ? (
          <Column
            selectionMode="multiple"
            headerStyle={{ width: "3rem" }}
          ></Column>
        ) : (
          <Column
            key={col.field}
            field={col.field}
            header={col.header}
            sortable={col.sortable ? true : false}
            body={eval(col.body)}
            style={{ fontSize: "14px" }}
          />
        );
      })
    : null;
  return (
    <div>
      {tableData ? (
        <>
          <DataTable
            value={newTableData ? newTableData : null}
            responsiveLayout="scroll"
            filters={filters}
            globalFilterFields={[
              "name",
              "duration",
              "company_name",
              "appointment_name",
              "service_provider_name",
              "service_name",
              "email",
              "pa_code",
              "subscription_plan.program_name",
              "emp_id",
              "carrier_name",
              "service_provider_company",
              "status",
              "is_active",
              "employee_Id",
              "employee_name",
              "service_name",
              "client_subscription_plan",
              "client_tier",
              "profile_id",
              "client_id",
              "client_name",
              "pa_code",
              "categoryName",
              "assignment",
              "certificate_name",
              "service_name",
            ]}
            className="text-center shadow-1 dynamicdata"
            style={{ borderRadius: "10px" }}
            showGridlines={showGridlines}
            // paginator
            sortMode="multiple"
            selectionMode={"checkbox"}
            selection={selectedProducts?.length ? selectedProducts : null}
            onSelectionChange={
              setSelectedProducts ? (e) => selectedData(e) : null
            }
            paginatorTemplate=" PrevPageLink CurrentPageReport NextPageLink "
            rows={10}
            currentPageReportTemplate={`Showing {first} to {last} of {totalRecords}`}
            emptyMessage={emptyMessage}
          >
            {dynamicColumns}
          </DataTable>
          {title === "addClient" ||
          title === "viewClient" ||
          title === "editClient" ||
          title === "expensesReport"
            ? handleGrandTotal
            : ""}
          <Paginator
            first={count}
            rows={rows}
            totalRecords={tableData.length}
            onPageChange={onPageChange}
          />
        </>
      ) : (
        <div className="d-4 p-3 bg-primary">Data not available</div>
      )}
    </div>
  );
}
