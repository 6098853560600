
import React, { useState } from "react";
import InputField from "../../../../components/input/input";
import { Card } from "primereact/card";
import ContentArea from "../../../../shared/ContentArea";
import SaveButton from "../../../../components/Buttons/SaveButton";
import CancelButton from "../../../../components/Buttons/CancelButton";
import { addExpenseCategoriesAction } from "../../../../Redux/HospitalAction/expenseAction";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loader from "../../../../pages/Loader";
const AddExpenseCategories = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [data, setData] = useState({
        expense_category_name: ""
    })
    const [error, setError] = useState("")
    const handleChange = (e) => {
        const { name, value } = e.target
        setData({
            ...data,
            [name]: value
        })
        setError({
            ...error,
            [name]: null
        })
    }
    const handleSubmit = () => {
        if (data.expense_category_name === "") {
            setError({
                ...error,
                expense_category_name: data.expense_category_name === "" ? "Please Enter Category Name" : error.expense_category_name
            })
            return false;
        }
        const formData = new FormData()
        formData.append("expense_category_name", data.expense_category_name)
        dispatch(addExpenseCategoriesAction(formData, () => navigate("/account-owner/manage-expenses-categories")))
    }
    const isLoading = useSelector((state) => state.loader.isLoading)
    return (
        <>
            {isLoading === true ? <Loader /> :
                <ContentArea>
                    <div className="add_client_tier_container">
                        <div className="p-3">
                            <div className="grid card ml-0 mr-0 px-5 mb-5 align-items-center bg-main-light border-round-lg">
                                <h2 className="text-main">
                                    Add Expenses Category
                                </h2>
                            </div>
                            <Card className="shadow-2 p-3 border-round-lg">
                                <div className="p-fluid grid mb-3">
                                    <div className="field col-12 md:col-3">
                                        <InputField name="expense_category_name" label="Category Name" required value={data.expense_category_name} onChange={handleChange} />
                                        {error.expense_category_name && <span className="error">{error.expense_category_name}</span>}
                                    </div>

                                </div>
                                <div className="submitButton flex">
                                    <SaveButton label="Save" onClick={handleSubmit}></SaveButton>
                                    <CancelButton label="Cancel" onClick={() => navigate("/account-owner/manage-expenses-categories")}></CancelButton>
                                </div>
                            </Card>
                        </div>
                    </div>
                </ContentArea>
            }
        </>
    )
}

export default AddExpenseCategories;
