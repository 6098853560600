export default class HospitalConstants {
  static BASE_URL = "https://resmedxapi.appdeft.biz/public/api/accounts";

  static END_POINT = {
    GETSERVICEPROVIDER: "/get-service-provider",
    GETSERVICE: "/user-plan",
    DELETESERVICE: "/delete-service",
    ADDSERVICEPROVIDER: "/add-service-provider",
    VIEWSERVICEPROVIDER: "/view-service",
    VIEWMANAGESERVICEPROVIDER: "/view-service-provider",
    EDITMANAGESERVICEPROVIDER: "/edit-service-provider",
    EDITSERVICEPROVIDER: "/edit-service",
    GETPACODES: "/get-PaCode",
    DELETEPACODES: "/delete-PaCode",
    ADDPACODES: "/add-PaCode",
    ViewPACODES: "/view-pa-code",
    EDITPACODE: "/edit-paCode",
    SIGNUP: "/register",
    DISCOUNTCODE: "/discount-check",
    Payment: "/stripe-payment",
    SUBSCRIPTIONPLAN: "/subscriptionPlan",
    LOGIN: "/login",
    FORGETPASSWORD: "/email-otp",
    VERIFYOTP: "/verify-otp",
    RESETPASSWORD: "/reset-password",
    GETEMPLOYEE: "/get-employee",
    ADDEMPLOYEE: "/add-employee",
    EDITEMPLOYEE: "/edit-employee",
    DELETEEMPLOYEE: "/delete-employee",
    GETPERMISSION: "/account-permission-type",
    VIEWEMPLOYEE: "/view-employee",
    GETHOURLYRATE: "/get-hourly-rate",
    DELETEHOURLYRATE: "/delete-hourly-rate",
    ADDHOURLYRATE: "/add-hourly-rate",
    GETSELECTEDEMPLOYEE: "/employee-data",
    VIEWHORLYDATA: "/view-hourly-rate",
    EDITHOURLYDATA: "/edit-hourly-rate",
    ADDEMPLOYEESCHEDULING: "/time-scheduling",
    ADDSPECIALEMPLOYEESCHEDULING: "/special-schedule",
    GETEMPLOYEESCHEDULING: "/get-scheduling",
    DELETESCHEDULING: "/delete-scheduling",
    VIEWSCHEDULING: "/view-scheduling",
    EDITSCHEDULING: "/edit-scheduling",
    GETTIERS: "/get-tiers",
    DELETETIERS: "/delete-tiers",
    ADDTIERS: "/add-tiers",
    EDITTIERS: "/edit-tiers",
    GETCLIENT: "/clients",
    VIEWTIERS: "/view-tiers",
    ADDCLIENT: "/add-client",
    DELETECLIENT: "/delete-client",
    VIEWCLIENT: "/view-client",
    EDITCLIENT: "/edit-client",
    GETCLIENTPACODE: "/client-pa-codes",
    ADDCLIENTPACODE: "/add-client-pa-code",
    DELETECLIENTPACODE: "/delete-client-pa-code",
    VIEWCLIENTPACODE: "/view-client-pa-code",
    EDITCLIENTPACODE: "/edit-client-pa-code",
    GETEMPLOYEECERTIFICATIONLIST: "/get-certification",
    ADDEMPLOYEECERTIFICATIONLIST: "/add-certification",
    EDITEMPLOYEECERTIFICATION: "/edit-certification",
    VIEWEMPLOYEECERTICATION: "/view-certificate",
    DELETEEMPLOYEECERTIFICATION: "/delete-certification",
    GETCLIENTCERTIFICATIONLIST: "/get-client-certification",
    ADDCLIENTCERTIFICATIONLIST: "/add-client-certification",
    DELETECLIENTCERTIFICATION: "/delete-client-certification",
    VIEWCLIENTCERTIFICATE: "/view-client-certificate",
    EDITCLIENTCERTICATION: "/edit-client-certification",
    GETEXPENSECATEGORIES: "/get-expense-category",
    ADDEXPENSECATEGORIES: "/add-expense-category",
    DELETEEXPENSECATEGORIES: "/delete-expense-category",
    EDITEXPENSECATEGORIES: "/edit-expense-category",
    VIEWEXPENSECATEGORIES: "/view-expense-category",
    GETEXPENSE: "/get-expense",
    ADDEXPENSE: "/add-expense",
    DELETEEXPENSE: "/delete-expense",
    EDITEXPENSE: "/edit-expense",
    VIEWEXPENSE: "/view-expense",
    GETREVENUECATEGORIES: "/get-revenue-category",
    ADDREVENUECATEGORIES: "/add-revenue-category",
    DELETEREVENUECATEGORIES: "/delete-revenue-category",
    VIEWREVENUECATEGORIES: "/view-revenue-category",
    EDITREVENUECATEGORIES: "/edit-revenue-category",
    GETREVENUE: "/get-revenue",
    ADDREVENUE: "/add-revenue",
    DELETEREVENUE: "/delete-revenue",
    VIEWEREVENUE: "/view-revenue",
    EDITREVENUE: "/edit-revenue",
    GETEMPLOYEECERTIFICATION: "/get-employee-certificate",
    ADDEMPLOYEECERTIFICATION: "/add-employee-certification",
    VIEWEMPLOYEECERTIFICATION: "/view-employee-certification",
    EDITEMPLOYEECERTIFICATIONLIST: "/edit-employee-certification",
    DELETEEMPLOYEECERTIFICATIONLIST: "/delete-employee-certification",
    CERTIFICATIONCLIENTGET: "/client-certification-get",
    CERTIFICATIONCLIENTDELETE: "/client-certification-delete",
    CERTIFICATIONCLIENTADD: "/client-certification-add",
    CERTIFICATIONCLIENTVIEW: "/client-certification-view",
    CERTIFICATIONCLIENTEDIT: "/client-certification-edit",
    TIME_CLOCK: "/add-time-clock",
    ADDAPPOINTMENT: "/add-appointment",
    GETAPPOINTMENT: "/get-appointment",
    GETSCHEDULEBYLOCATION: "/get-schedule-by-location",
    GETSERVICEBYSCHEDULE: "/get-service-by-schedule",
    APPOINTMENTADDSERVICE: "/add-service",
    APPINTMENTGETSERVICE: "/service",
    SCHEDULEBYGETSERVICE: "/get-schedule-duration",
    APPOINTMENTDELETESERVICE: "/service-delete",
    APPOINTMENTVIEWSERVICE: "/view-service",
    APPOINTMENTEDITSERVICE: "/edit-service",
    ADDAPPOINTMENTBOOKING: "/add-appointment-booking",
    SELECTEDAPPOINTMENT: "/selected-appointment",
    VIEWAPPOINTMENT: "/appointment-booking-detail/",
    APPOINTMENTBOOKING: "/appointment-booking",
    GETAPPOINTMENTSCHEDULE: "/get-appointment",
    GETAPPOINTMENTDATA: "/get-appointment",
    VIEWAPPOINTMENTDATA: "/view-appointment",
    EDITAPPOINTMENTDATA: "/edit-appointment",
    APPOINTMENT: "/appointment",
    DELETEAPPOINTMENTBOOKINGDATA: "/cancel-booking-appointment",
    GETDATEEVENT: "/get-date-event",
    scheduling: "/scheduling",
    companyDetails: "/get-setting/company",
    companyLocation: "/get-setting/location",
    companyLeave: "/get-setting/leaves",
    companyPostData: "/company",
    POSTLOCATION: "/location",
    POSTLEAVE: "/add-leave-category",
    PostLeave: "/location",
    DELETE: "/delete-setting",
    ClientProfileData: "/client-profile",
    ClientEditProfile: "/client-profile",
    CLIENTPASSWORDRESET: "/client-update-password",
    GETLEAVETYPE: "/get-leave-type",
    ADDLEAVE: "/add-leave",
    GETLEAVECATEGORY: "/get-leave-category",
    GETSCHEDULINGLISTING: "/view-employee-schedule",
    GETLEAVELIST: "/get-leave-type",
    MEDICATION_SCHEDULES: "/medication-schedules",
    ADD_MEDICATION_SCHEDULE: "/medication-schedule",
    DELETE_MEDICATION_SCHEDULE: "/medication-schedules",
  };
}
