import React from "react";

import MedicationScheduleForms from "./MedicationScheduleForms";

const AddSchedule = () => {
  return (
    <>
      <MedicationScheduleForms title={"Add"} />
    </>
  );
};

export default AddSchedule;
