export const types = {
  GET_SERVICE_PROVIDER: "GET_SERVICE_PROVIDER",
  GET_SERVICE: "GET_SERVICE",
  GET_FILTER_SERVICE_PROVIDER: "GET_SERVICE_PROVIDER",
  SHOW_LOADER: "SHOW_LOADER",
  HIDE_LOADER: "HIDE_LOADER",
  DELETE_SERVICE_PROVIDER: "DELETE_SERVICE_PROVIDER",
  ADD_SERVICE_PROVIDER: "ADD_SERVICE_PROVIDER",
  CARRIER: "CARRIER",
  SHOW_TOAST: "SHOW_TOAST",
  VIEW_SERVICE_PROVIDER: "VIEW_SERVICE_PROVIDER",
  VIEW_MANAGE_SERVICE_PROVIDER: "VIEW_MANAGE_SERVICE_PROVIDER",
  EDIT_SERVICE_PROVIDER: "EDIT_SERVICE_PROVIDER",
  EDIT_MANAGE_SERVICE_PROVIDER: "EDIT_MANAGE_SERVICE_PROVIDER",
  GET_PA_CODES: "GET_PA_CODES",
  DELETE_PA_CODES: "DELETE_PA_CODES",
  ADD_PA_CODE: "ADD_PA_CODE",
  VIEW_PA_CODE: "VIEW_PA_CODE",
  EDIT_PA_CODE: "EDIT_PA_CODE",
  SIGNUP: "SIGNUP",
  DISCOUNT_CODE: "DISCOUNT_CODE",
  PAYMENT: "PAYMENT",
  SUBSCRIPTION_PLAN: "SUBSCRIPTIN_PLAN",
  LOGIN: "LOGIN",
  FORGET_PASSWORD: "FORGET_PASSWORD",
  VERIFY_OTP: "VERIFY_OTP",
  RESET_PASSWORD: "RESET_PASSWORD",
  GET_EMPLOYEE: "GET_EMPLOYEE",
  ADD_EMPLOYEE: "ADD_EMPLOYEE",
  EDIT_EMPLOYEE: "EDIT_EMPLOYEE",
  DELETE_EMPLOYEE: "DELETE_EMPLOYEE",
  VIEW_EMPLOYEE: "VIEW_EMPLOYEE",
  GET_PERMISSION: "GET_PERMISSION",
  GET_HOURLY_RATE: "GET_HOURLY_RATE",
  DELETE_HOURLY_RATE: "DELETE_HOURLY_RATE",
  ADD_HOURLY_RATE: "ADD_HOURLY_RATE",
  GET_SELECTED_EMPLOYEE: "GET_SELECTED_EMPLOYEE",
  VIEW_HOURLY_RATE: "VIEW_HOURLY_RATE",
  EDIT_HOURLY_RATE: "EDIT_HOURLY_RATE",
  ADD_EMPLOYEE_SCHEDULING: "ADD_EMPLOYEE_SCHEDULING",
  GET_EMPLOYEE_SCHEDULING: "GET_EMPLOYEE_SCHEDULING",
  DELETE_EMPLOYEE_SCHEDULING: "DELETE_EMPLOYEE_SCHEDULING",
  VIEW_EMPLOYEE_SCHEDULING: "VIEW_EMPLOYEE_SCHEDULING",
  EDIT_EMPLOYEE_SCHEDULING: "EDIT_EMPLOYEE_SCHEDULING",
  GET_TIERS: "GET_TIERS",
  DELETE_TIERS: "DELETE_TIERS",
  ADD_TIERS: "ADD_TIERS",
  EDIT_TIERS: "EDIT_TIERS",
  VIEW_TIERS: "VIEW_TIERS",
  GET_CLIENT: "GET_CLIENT",
  ADD_CLIENT: "ADD_CLIENT",
  DELETE_CLIENT: "DELETE_CLIENT",
  VIEW_CLIENT: "VIEW_CLIENT",
  EDIT_CLIENT: "EDIT_CLIENT",
  GET_CLIENT_PA_CODE: "GET_CLIENT_PA_CODE",
  ADD_CLIENT_PA_CODE: "ADD_CLIENT_PA_CODE",
  DELETE_CLIENT_PA_CODE: "DELETE_CLIENT_PA_CODE",
  VIEW_CLIENT_PA_CODE: "VIEW_CLIENT_PA_CODE",
  EDIT_CLIENT_PA_CODE: "EDIT_CLIENT_PA_CODE",
  GET_EMPLOYEE_CERTIFICATION_LIST: "GET_EMPLOYEE_CERTIFICATION_LIST",
  ADD_EMPLOYEE_CERTIFICATION_LIST: "ADD_EMPLOYEE_CERTIFICATION_LIST",
  EDIT_EMPLOYEE_CERTIFICATION_LIST: "EDIT_EMPLOYEE_CERTIFICATION_LIST",
  VIEW_EMPLOYEE_CERTIFICATION_LIST: "VIEW_EMPLOYEE_CERTIFICATION_LIST",
  DELETE_EMPLOYEE_CERTIFICATION_LIST: "DELETE_EMPLOYEE_CERTIFICATION_LIST",
  GET_CLIENT_CERTIFICATION_LIST: "GET_CLIENT_CERTIFICATION_LIST",
  ADD_CLIENT_CERTIFICATION_LIST: "ADD_CLIENT_CERTIFICATION_LIST",
  DELETE_CLIENT_CERTIFICATION_LIST: "DELETE_CLIENT_CERTIFICATION_LIST",
  VIEW_CLIENT_CERTIFICATION_LISt: "VIEW_CLIENT_CERTIFICATION_LISt",
  EDIT_CLIENT_CERTIFICATION_LIST: "EDIT_CLIENT_CERTIFICATION_LIST",
  GET_EXPENSE_CATEGORIES: "GET_EXPENSE_CATEGORIES",
  ADD_EXPENSE_CATEGORIES: "ADD_EXPENSE_CATEGORIES",
  DELETE_EXPENSE_CATEGORIES: "DELETE_EXPENSE_CATEGORIES",
  EDIT_EXPENSE_CATEGORIES: "EDIT_EXPENSE_CATEGORIES",
  VIEW_EXPENSE_CATEGORIES: "VIEW_EXPENSE_CATEGORIES",
  GET_EXPENSE: "GET_EXPENSE",
  ADD_EXPENSE: "ADD_EXPENSE",
  DELETE_EXPENSE: "DELETE_EXPENSE",
  VIEW_EXPENSE: "VIEW_EXPENSE",
  EDIT_EXPENSE: "EDIT_EXPENSE",
  GET_REVENUE_CATEGORIES: "GET_REVENUE_CATEGORIES",
  ADD_REVENUE_CATEGORIES: "ADD_REVENUE_CATEGORIES",
  DELETE_REVENUE_CATEGORIES: "DELETE_REVENUE_CATEGORIES",
  VIEW_REVENUE_CATEGORIES: "VIEW_REVENUE_CATEGORIES",
  EDIT_REVENUE_CATEGORIES: "EDIT_REVENUE_CATEGORIES",
  GET_REVENUE: "GET_REVENUE",
  ADD_REVENUE: "ADD_REVENUE",
  DELETE_REVENUE: "DELETE_REVENUE",
  VIEW_REVENUE: " VIEW_REVENUE",
  EDIT_REVENUE: "EDIT_REVENUE",
  GET_EMPLOYEE_CERTICATION: "GET_EMPLOYEE_CERTICATION",
  ADD_EMPLOYEE_CERTIFICATION: "ADD_EMPLOYEE_CERTIFICATION",
  VIEW_EMPLOYEE_CERTIFICATION: "VIEW_EMPLOYEE_CERTIFICATION",
  EDIT_EMPLOYEE_CERTIFICATION: "EDIT_EMPLOYEE_CERTIFICATION",
  DELETE_EMPLOYEE_CERTIFICATION: "DELETE_EMPLOYEE_CERTIFICATION",
  CERTIFICATION_CLIENT_GET: "CERTIFICATION_CLIENT_GET",
  CERTIFICATION_CLIENT_DELETE: "CERTIFICATION_CLIENT_DELETE",
  CERTIFICATION_CLIENT_ADD: "CERTIFICATION_CLIENT_ADD",
  CERTIFICATION_CLIENT_VIEW: "CERTIFICATION_CLIENT_VIEW",
  CERTIFICATION_CLIENT_EDIT: "CERTIFICATION_CLIENT_EDIT",
  TIME_CLOCK: "TIME_CLOCK",
  ADD_APPOINTMENT: "ADD_APPOINTMENT",
  GET_APPOINTMENT: "GET_APPOINTMENT",
  APPOINTMENT_ADD_SERVICE: "APPOINTMENT_ADD_SERVICE",
  APPOINTMENT_GET_SERVICE: "APPOINTMENT_GET_SERVICE",
  APPOINTMENT_DELETE_SERVICE: "APPOINTMENT_DELETE_SERVICE",
  APPOINTMENT_VIEW_SERVICE: "APPOINTMENT_VIEW_SERVICE",
  APPOINTMENT_EDIT_SERVICE: "APPOINTMENT_EDIT_SERVICE",
  ADD_APPOINTMENT_BOOKING: "ADD_APPOINTMENT_BOOKING",
  SELECTED_APPOINTMENT: "SELECTED_APPOINTMENT",
  APPOINTMENT_BOOKING: "APPOINTMENT_BOOKING",
  VIEWAPPOINTMENT: "VIEWAPPOINTMENT",
  GET_APPOINTMENT_SCHEDULE: "GET_APPOINTMENT_SCHEDULE",
  GET_APPOINTMENT_DATA: "GET_APPOINTMENT_DATA",
  VIEW_APPOINTMENT_DATA: "VIEW_APPOINTMENT_DATA",
  EDIT_APPOINTMENT_DATA: "EDIT_APPOINTMENT_DATA",
  APPOINTMENT_CALENDER: "APPOINTMENT_CALENDER",
  GET_DATE_EVENT: "GET_DATE_EVENT",
  SCHEDULING: "SCHEDULING",
  COMPANY_DETAIL: "COMPANY_DETAIL",
  COMPANY_LOCATION: "COMPANY_Location",
  COMPANY_LEAVE: "COMPANY_LEAVE",
  COMPANY_POST_DATA: "COMPANY_POST_DATA",
  Location_POST_DATA: "Location_POST_DATA",
  LEAVE_POST_DATA: "LEAVE_POST_DATA",
  DELETE_COMPANY_ACTION_DATA: "DELETE_COMPANY_ACTION_DATA",
  CLIENT_PROFILE_DATA: "CLIENT_PROFILE_DATA",
  CLIENT_PROFILE_EDIT_DATA: "CLIENT_PROFILE_EDIT_DATA",
  CLIENT_PASSWORD_RESET: "CLIENT_PASSWORD_RESET",
  ADD_SPECIAL_EMPLOYEE_SCHEDULING: "ADD_SPECIAL_EMPLOYEE_SCHEDULING",
  GET_LEAVE_TYPE: "GET_LEAVE_TYPE",
  GET_LEAVE_LIST: "GET_LEAVE_LIST",
  GET_LEAVE_CATEGORY: "GET_LEAVE_CATEGORY",
  POST_SCHEDULING_LEAVE: "POST_SCHEDULING_LEAVE",
  GET_EMPLOYEE_SCHEDULING_LISTING: "GET_EMPLOYEE_SCHEDULING_LISTING",
  ADD_MEDICATION_SCHEDULE: "ADD_MEDICATION_SCHEDULE",
};
