

import React, { useEffect, useState } from "react";
import AddButton from "../../../../components/Buttons/AddButton";
import ContentArea from "../../../../shared/ContentArea";
import SearchIcon from "../../../../assets/icons/search.png";
import FilterIcon from "../../../../assets/icons/filter.png";
import Table from '../../../../components/TableList/Table';
import { useNavigate } from "react-router-dom";
import Index from '../../Index';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import Deleteimg from "../../../../assets/images/deleted.png";
import { FilterMatchMode } from 'primereact/api';
import { getClientAction, deleteClientAction } from "../../../../Redux/HospitalAction/clientAction";
import { getServiceAction } from "../../../../Redux/HospitalAction/serviceProviderAction";
import { useDispatch, useSelector } from "react-redux";
import { getClientTierAction } from "../../../../Redux/HospitalAction/clientAction";
import Loader from "../../../../pages/Loader";
import DropdownField from "../../../../components/input/dropdown";
import moment from "moment"
const ManageClientList = () => {
  const dispatch = useDispatch()
  const [deleteButton, setDeleteButton] = useState(false)
  const { isNextPage, setIsNextPage } = Index();
  const [tableData, setTableData] = useState("")
  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const [isShowFilter, setIsShowFilter] = useState(false);
  const [isFiltered, setIsFiltered] = useState(false);
  const [tiers, setTiers] = useState({
    tierId: "",
    serviceId: "",
    tier_id: "",
    subscription_plan_id: ""
  })
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const [data, setData] = useState()
  const navigate = useNavigate()
  const AddClient = () => {
    setIsNextPage(!isNextPage)
    navigate("/account-owner/add-client-details")
  }

  useEffect(() => {
    dispatch(getClientAction())
    dispatch(getServiceAction())
    dispatch(getClientTierAction())
  }, [])
  const getData = useSelector((state) => state.getClient.getClientProfile)
  const getServiceData = useSelector((state) => state.getService.getServiceProfile)
  const getTierData = useSelector((state) => state.getClientTier.getClientTierProfile)
  useEffect(() => {
    let data = []
 
      getServiceData?.length > 0 && getServiceData?.map((item, index) => {
        getData?.length > 0 && getData.map((items, index) => {
          if (item.id == items.client_subscription_plan) {
            let obj = {
              profile_id: items.profile_id,
              name: items.name,
              client_tier: items.client_tier,
              client_subscription_plan: item.program_name,
              email: items.email,
              mobile: items.mobile,
              client_MHN_no: items.client_MHN_no,
              dob: moment(items.dob).format('DD-MM-YYYY'),
              lead_status: items.lead_status,
              id:items.id
            }
            data.push(obj)
          }
        })
      })
      setTableData(data)
    

  }, [getData, getServiceData])
  useEffect(()=>{
    if(tableData.length === 0){
      setTableData("")
    }
  },[tableData])
  const TableHeading = [
    { field: "SrNo", header: "Sr. No.", body: "srnoTemplates" },
    { field: "profile_id", header: "Id" },
    { field: "name", header: "Name", },
    { field: "client_tier", header: "Tier" },
    { field: "client_subscription_plan", header: "Classification", },
    { field: "email", header: "Email", },
    { field: "mobile", header: "Contact no.", },
    { field: "client_MHN_no", header: "MHN No.", },
    { field: "dob", header: "DOB", },
    { field: "icons", header: "Action", body: "clientListIconBodyTemplate" },

  ];
  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters['global'].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };
  const isLoading = useSelector((state) => state.loader.isLoading)
  const id = data
  const handleSubmit = () => {
    dispatch(deleteClientAction(id, () => setDeleteButton(false))).then(() => {
      dispatch(getClientAction())
    })
  }
  const handleChange = (e) => {
    const { name, value, id } = e.target
    setTiers({
      ...tiers,
      [name]: value,
      [id]: value.id
    })
  }
  const handleApplyFilter = () => {
    setIsShowFilter(!isShowFilter)
    const formData = new FormData()
    formData.append("tier_id", tiers.tier_id)
    formData.append("subscription_plan_id", tiers.subscription_plan_id)
    dispatch(getClientAction(formData))
    setIsFiltered(true)
  }
  const clearFilter = (value) => {
    setIsFiltered(false)
    setIsShowFilter(false)
    dispatch(getClientAction())
    setTiers({
      tierId: "",
      serviceId: "",
      tier_id: "",
      subscription_plan_id: ""
    })
  }
  return (
    <>
      {isLoading === true ? <Loader /> :
        <ContentArea>
          <div className="manage_employees_container p-3 ">

            <div className="grid mb-5 mr-0 ml-0 align-items-center bg-main-light border-round-lg shadow-1 flex justify-content-around">
              <div className="md:col-5 ">
                <h1 className="text-main  " style={{ fontSize: "24px" }}>
                  Manage Client
                </h1>
              </div>
              <div className="md:col-3">
                <div className="search-box w-full">
                  <img
                    src={SearchIcon}
                    alt="Search Icon"
                    width="15px"
                    height="15px"
                  />
                  <input type="search" placeholder="Search" onChange={onGlobalFilterChange} onClick={() => setIsShowFilter(false)} />
                </div>
              </div>
              <div className="md:col-1 relative bg-main px-3 border-round-md w-max  ">
                {
                  isFiltered ?
                    <div onClick={clearFilter} className="bg-main border-round-md text-white text-center text-base relative karla">
                      Clear &nbsp;
                      <i className="pi pi-filter-slash"></i></div>
                    :
                    <div onClick={() => setIsShowFilter(prev => !prev)} className="border-round-md text-white text-center text-base relative karla">
                      Filter &nbsp;
                      <img src={FilterIcon} alt="" width={15} /></div>
                }
                {
                  isShowFilter ?
                    < div className="flex flex-column gap-2 filter-popup" style={{ display: isShowFilter ? "block width:220%" : "none" }} >
                      <label htmlFor="verified-filter" className="font-bold">
                        Tiers
                      </label>
                      <DropdownField options={getTierData} optionLabel="tiers_name" onChange={handleChange} value={tiers.tierId} name="tierId" id="tier_id" />
                      <label htmlFor="verified-filter" className="font-bold">Classification</label>
                      <DropdownField options={getServiceData} optionLabel="program_name" onChange={handleChange} value={tiers.serviceId} name="serviceId" id="subscription_plan_id" />
                      <div>

                        <Button className="w-full justify-content-center" onClick={handleApplyFilter} >Apply</Button>
                      </div>
                    </div >
                    : null
                }
              </div>
              <div className="md:col-1 w-max ">
                <AddButton onClick={() => AddClient()} />
              </div>
            </div>

            <div className="grid mt-2">
              <div className="md:col-12">

                <Table tableHeading={TableHeading}
                  tableData={tableData}
                  setDeleteButton={setDeleteButton}
                  setFilters={setFilters}
                  filters={filters}
                  setData={setData}
                />
              </div>

            </div>

            {
              deleteButton ?
                <Dialog
                  className="table_delete_modal flex justify-content-center "
                  visible={deleteButton}
                  onHide={() => setDeleteButton(false)}
                  style={{ width: '30vw' }}>
                  <p className="m-0">
                    <div className=" flex justify-content-center">
                      <img src={Deleteimg} alt="" width={150} height={127}></img>
                    </div>
                    <div className="text-center mb-5">
                      <h4 className=" p-0 m-0 ">Are you sure you want to delete this user?</h4>
                      <p className="text-xs text-400">All associated data will also be deleted! There is no undo!</p>
                    </div>
                    <div className=" btn flex justify-content-center">
                      <div className="text-center">
                        <Button
                          label="Cancel"
                          className="w-full surface-500 p-3 px-5    border-0 border-round-md "
                          onClick={() => setDeleteButton(false)}
                        />
                      </div>
                      <div className="text-center">
                        <Button
                          label="Delete"
                          className=" w-full px-5 border-0 p-3 ml-3 border-round-md bg-red-500"
                          onClick={handleSubmit}
                        />
                      </div>
                    </div>
                  </p>
                </Dialog> : null
            }
          </div>
        </ContentArea>
      }
    </>
  )
}

export default ManageClientList;
