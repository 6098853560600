import ExpertiseImg from "../assets/icons/expertise.png";
import SecurityImg from "../assets/icons/security.png";
import InnovationImg from "../assets/icons/innovation.png";
import BlogImg1 from "../assets/images/blog1.png";
import BlogImg2 from "../assets/images/blog2.png";
import BlogImg3 from "../assets/images/blog3.png";

const HomeContent = () => {
  const AboutUsContent = [
    {
      img: ExpertiseImg,
      heading: "Expertise",
      iconclass: "bg-light-red",
      paragraph:
        "Our team boasts extensive experience in both healthcare and software development.",
    },
    {
      img: SecurityImg,
      heading: "Security and Compliance",
      iconclass: "bg-violet",
      paragraph:
        "We are committed to maintaining the highest levels of data security and compliance with industry regulations.",
    },
    {
      img: InnovationImg,
      heading: "Innovation",
      iconclass: "bg-light-pink",
      paragraph:
        "We stay at the forefront of technological advancements in healthcare to continuously improve our EHR software.",
    },
  ];

  const BlogContent = [
    {
      src: BlogImg1,
      heading: "The Importance of EHR Systems in Modern Healthcare",
      date: "Jun 20, 2023",
      author: "Akari Mizunashi",
    },
    {
      src: BlogImg2,
      heading: "Key Features to Look for in an EHR Software",
      date: "Aug 24, 2023",
      author: "Akari Mizunashi",
    },
    {
      src: BlogImg3,
      heading: "EHR Software Customization: Tailoring to Your Needs",
      date: "Sep 25, 2023",
      author: "Akari Mizunashi",
    },
  ];

  return { AboutUsContent, BlogContent };
};
export default HomeContent;
