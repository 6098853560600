

import React, { useState, useEffect } from "react";
import { Card } from "primereact/card";
import ContentArea from "../../../shared/ContentArea";
import { Calendar } from 'primereact/calendar';
import DropdownField from "../../../components/input/dropdown";
import SaveButton from "../../../components/Buttons/SaveButton";
import Table from "../../../components/TableList/Table";
import CancelButton from "../../../components/Buttons/CancelButton";
import { getServiceAction } from "../../../Redux/HospitalAction/serviceProviderAction";
import { useDispatch, useSelector } from "react-redux";
import { getClientAction } from "../../../Redux/HospitalAction/clientAction";
import moment from "moment";
import { timeClockAction } from "../../../Redux/HospitalAction/timeAction";
import { useNavigate } from "react-router-dom";
import FramePlus from "../../../assets/icons/Frame.png"
import FrameMinus from "../../../assets/icons/FrameMinus.png"
const TimeClock = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [error, setError] = useState("")
    const [tableData, setTableData] = useState("")
    const [date, setDate] = useState();
    const [endTimeSolts, setEndTimeSolts] = useState([]);
    const [startTimeService, setStartTimeService] = useState("")
    const [endTimeService, setEndService] = useState([])
    const [startLunchTimeSlots, setStartLunchTimeSlots] = useState([])
    const [endLunchTimeSlots, setEndLunchTimeSLots] = useState([])
    const [data, setData] = useState({
        date: "",
        start_time: "",
        end_time: "",
        breaks: [{
            start_break: "",
            end_break: "",
            start_option: "",
            end_option: ""
        }],
        services: []
    })
    const [editButton, setEditButton] = useState(false)
    useEffect(() => {
        dispatch(getServiceAction())
        dispatch(getClientAction())
    }, [])
    const serviceDatalist = useSelector((state) => state.getService?.getServiceProfile)
    const clientDatalist = useSelector((state) => state.getClient.getClientProfile)
    const [addService, setAddService] = useState({
        clientid: "",
        service_plan_id: "",
        start_time: "",
        end_time: ""
    })
    const [getIndex, setGetIndex] = useState("")
    const tableHeading = [
        { filed: "srno", header: "Sr. No.", body: "srnoTemplates" },
        { filed: "program_name", header: "Service", body: "serviceNameTemplate" },
        { filed: "class", header: "Client", body: "clientNameTemplate" },
        { filed: "gbf", header: "Start Time", body: "startTimeTemplate" },
        { filed: "name", header: "End Time", body: "editTimeTemplate" },
        { filed: "icons", header: "Action", body: "AddTimeBodyTemplate" },
    ]
    const handleAddClick = () => {
        setData({
            ...data,
            breaks: [...data.breaks, {
                start_break: "",
                end_break: "",
                start_option: "",
                end_option: ""
            }]
        })
    };
    const handleRemoveClick = (index) => {
        const list = [...data.breaks];
        const remove = list.filter((_, indexFilter) => !(indexFilter === index));
        setData({
            ...data,
            breaks: remove
        })
    };
    const handleAddNotes = () => {
        let check = true
        Object.keys(addService).map((item) => {
            if (addService[item].length !== 0) {
                check = false;
            }
        })
        if (check === false) {
            let array = []
            array.push(addService)
            setTableData([...tableData, ...array])
            setAddService({
                client_id: "",
                service_plan_id: "",
                start_time: "",
                end_time: ""
            })
        }

    }
    const handleUpdate = () => {
        let newArray = []
        newArray = tableData.map((item, i) => {
            if (getIndex === i) {
                return { ...addService };
            } else {
                return item;
            }
        });
        setTableData(newArray)
        setEditButton(false)
        setAddService({
            client_id: "",
            service_plan_id: "",
            start_time: "",
            end_time: ""
        })
    }
    useEffect(() => {
        setData({
            ...data,
            services: tableData
        })
    }, [addService])
    const handleSubmit = () => {
        let databreak = true
        data?.breaks?.map((item) => {
            if (item?.start_break?.length === 0 && item.end_break?.length === 0) {
                databreak = false
                return;
            }
        })
        if (data.date === "" || data.start_time === "" || data.end_time === "" || databreak === false || data.services === "") {
            setError({
                ...error,
                date: data.date === "" ? "Please Choose Date" : error.date,
                start_time: data.start_time === "" ? "Please Enter start Time" : error.start_time,
                end_time: data.end_time === "" ? "Please Enter End Time" : error.end_time,
                services: data.services === "" ? "Please Enter Services" : error.services,
                breaks: databreak === false ? "Please Enter  Break Time" : error.start_break,

            })
            return false;
        }
        let arrayBreak = []
        let arrayService = []
        data.breaks?.length > 0 && data.breaks?.map((item, index) => {
            let obj = {
                start_time: item.start_break,
                end_time: item.end_break
            }
            arrayBreak.push(obj)
        })
        data?.services?.length > 0 && data?.services?.map((item, index) => {
            let obj = {
                client_id: item?.client_id?.id,
                service_plan_id: item?.service_plan_id?.id,
                start_time: item?.start_time,
                end_time: item?.end_time
            }
            arrayService.push(obj)
        })
        const formData = new FormData()
        formData.append("date", moment(data.date).format("DD-MM-YYYY"))
        formData.append("start_time", data.start_time)
        formData.append("end_time", data.end_time)
        formData.append("breaks", JSON.stringify(arrayBreak))
        formData.append("services", JSON.stringify(arrayService))
        dispatch(timeClockAction(formData, () => navigate("/account-owner/dashboard")))
    }
    const generateTimeSlots = async (hh, mm, ss) => {
        const startTime = new Date();
        startTime.setHours(0, 0, 0);

        const endTime = new Date();
        endTime.setHours(23, 30, 0);

        const genTimeSlots = [];
        while (startTime <= endTime) {
            const hours = startTime.getHours().toString().padStart(2, '0');
            const minutes = startTime.getMinutes().toString().padStart(2, '0');
            let timeSlot = `${hours}:${minutes}`;
            genTimeSlots.push(timeSlot);
            startTime.setMinutes(startTime.getMinutes() + 60);
        }
        setEndTimeSolts(() => {
            return [
                ...genTimeSlots
            ]
        })
        setStartLunchTimeSlots(() => {
            return [
                ...genTimeSlots
            ]
        })
        setEndLunchTimeSLots(() => {
            return [
                ...genTimeSlots
            ]
        })
        setDate(genTimeSlots);
        setStartTimeService(genTimeSlots)
        setEndService(() => {
            return [
                ...genTimeSlots
            ]
        })
    };
    useEffect(() => {
        generateTimeSlots();
    }, []);
    const handleChange = (e, index) => {
        const { name, value } = e.target
        const dateClone = [...date];
        const endTimeClone = [...endTimeSolts]
        if (name === "start_time" || name === "end_time") {
            if (name === "start_time") {
                let endTime = dateClone.slice(date.indexOf(value));
                endTime = [...endTime]
                setEndTimeSolts(endTime);
            }
            if (name === "end_time") {
                let startLunchTime = dateClone.slice(date.indexOf(data.start_time), date.indexOf(value));
                startLunchTime = [...startLunchTime]
                setStartLunchTimeSlots(startLunchTime)
            }
            setData({
                ...data,
                [name]: value
            })
            setError({
                ...error,
                [name]: null
            })
        } else if (name === "start_break" || name === "end_break") {
            if (name === "start_break") {
                let endLunchTime = date.slice(date.indexOf(value), date.indexOf(data.end_time));
                endLunchTime = [...endLunchTime]
                // setEndLunchTimeSLots(endLunchTime)
                let wholeBreak = [...data.breaks]
                let obj = { ...data.breaks[index], [name]: value, end_option: endLunchTime }
                wholeBreak.splice(index, 1, obj)
                setData((prev) => {
                    return {
                        ...prev,
                        breaks: wholeBreak,
                    }
                })
                setError({
                    ...error,
                    breaks: null
                })
            }

            else {
                let startLunchTime = date.slice(date.indexOf(value), date.indexOf(data.start_time));
                startLunchTime = [...startLunchTime]
                let wholeBreak = [...data.breaks]
                let obj = { ...data.breaks[index], [name]: value, start_option: startLunchTime }
                wholeBreak.splice(index, 1, obj)
                setData((prev) => {
                    return {
                        ...prev,
                        breaks: wholeBreak,

                    }
                })
                setError({
                    ...error,
                    breaks: null
                })
            }
        }

    }
    const handleServiceChange = (e) => {
        const { name, value } = e.target
        const dateClone = [...date];
        if (name === "start_time") {
            let endTime = dateClone.slice(date.indexOf(value));
            endTime = [...endTime]
            setEndService(endTime);
        }
        setAddService({
            ...addService,
            [name]: value
        })
        setError({
            ...error,
            services:null
                
        })
    }
    return (
        <>
            <ContentArea>
                <div className="add_employees_container">
                    <div className="p-3">
                        <div className="grid card ml-0 mr-0 px-5 mb-5 align-items-center bg-main-light border-round-lg">
                            <h2 className="text-main">
                                Time Clock
                            </h2>
                        </div>
                        <Card className="shadow-2 p-3 border-round-lg">
                            <div className="p-fluid grid mb-3">
                                <div className="col-12 md:col-6">
                                    <label className="font block mb-2">Date<code className="text-red">*</code></label>
                                    <Calendar className="border-round-md calender_picker "
                                        inputClassName="calendar p-2 border-round-md mb-2" value={data?.date} onChange={(e) => {
                                            setData({
                                                ...data,
                                                date: e.target.value
                                            })
                                            setError({
                                                ...error,
                                                date: null
                                            })
                                        }}  maxDate={new Date()} />
                                    {error.date && <span className="error">{error.date}</span>}
                                </div>
                            </div>

                            <div className="p-fluid grid mb-3">
                                <div className="col-12 md:col-6">
                                    <label className="font block mb-2">Start Time<code className="text-red">*</code></label>
                                    <DropdownField
                                        placeholder="Start Time"
                                        name="start_time"
                                        value={data?.start_time}
                                        onChange={handleChange}
                                        options={date}
                                    />
                                    {error.start_time && <span className="error">{error.start_time}</span>}
                                </div>
                                <div className="col-12 md:col-6">
                                    <label className="font block mb-2">End Time<code className="text-red">*</code></label>
                                    <DropdownField
                                        placeholder="End Time"
                                        name="end_time"
                                        value={data?.end_time}
                                        onChange={handleChange}
                                        options={endTimeSolts}
                                    />
                                    {error.end_time && <span className="error">{error.end_time}</span>}
                                </div>
                            </div>
                            <div className="p-fluid grid mb-5">

                                <div className="col-12 md:col-12">
                                    <h3 className="text-lg font-normal p-0 m-0" style={{ color: "#1E1E1E" }}>Breaks<code className="text-red">*</code></h3>
                                </div>
                                {data?.breaks?.map((item, index) => {
                                    return (
                                        <>
                                            <div className="col-12 md:col-4">
                                                <DropdownField
                                                    placeholder="Start Time"
                                                    name="start_break"
                                                    value={item?.start_break}
                                                    onChange={(e) => handleChange(e, index)}
                                                    options={startLunchTimeSlots}
                                                />
                                            </div>
                                            <div className="col-12 md:col-4">
                                                <DropdownField
                                                    placeholder="Start Time"
                                                    name="end_break"
                                                    value={item?.end_break}
                                                    onChange={(e) => handleChange(e, index)}
                                                    options={item.end_option}
                                                />
                                            </div>
                                            <div className="col-12 md:col-4">
                                                {index === 0 ? "" : data.breaks?.length !== 1 && (
                                                    <div className="" onClick={() => handleRemoveClick(index)}>
                                                        <img src={FrameMinus} width={24} height={24} className="mt-3" />
                                                        {/* <p className="bg-main p-2 p-0 m-0 text-white border-round-2xl text-center "><i className="pi pi-minus text-white"></i></p> */}
                                                    </div>
                                                )}
                                                {index === 0 &&
                                                    <div className="w-1 " onClick={handleAddClick}>
                                                        <img src={FramePlus} width={24} height={24} className="mt-3" />
                                                        {/* <p className="bg-main p-2 p-0 m-0 text-white border-round-2xl text-center "><i className="pi pi-plus text-white"></i></p> */}
                                                    </div>
                                                }

                                            </div>

                                        </>
                                    )
                                })}
                                {error.breaks && <span className="error">{error.breaks}</span>}
                            </div>
                            <div className="p-fluid grid mb-3">
                                <div className="col-12 md:col-12">
                                    <h2 className="text-main font-bold" style={{ fontSize: "22px" }} >Services  and Clients<code className="text-red">*</code></h2>
                                </div>
                                <div className="col-12 md:col-4">
                                    <DropdownField label="Service" options={serviceDatalist} optionLabel="program_name" value={addService?.service_plan_id} onChange={(e) => setAddService({
                                        ...addService,
                                        service_plan_id: e.value
                                    })} />
                                </div>
                                <div className="col-12 md:col-4">
                                    <DropdownField label="Clients" options={clientDatalist} optionLabel="profile_id" filter value={addService.client_id} onChange={(e) => setAddService({
                                        ...addService,
                                        client_id: e.value
                                    })} />
                                </div>
                            </div>
                            <div className="p-fluid grid mb-5">
                                <div className="col-12 md:col-4">
                                    <DropdownField
                                        placeholder="Start Time"
                                        name="start_time"
                                        value={addService?.start_time}
                                        onChange={handleServiceChange}
                                        options={startTimeService}
                                    />
                                </div>
                                <div className="col-12 md:col-4">
                                    <DropdownField
                                        placeholder="End Time"
                                        name="end_time"
                                        value={addService?.end_time}
                                        onChange={handleServiceChange}
                                        options={endTimeService}
                                    />
                                </div>
                                <div className="col-12 md:col-4 p-0 m-0">
                                    {editButton === true ?
                                        <div className="flex justify-content-end">
                                            <p className="bg-main p-0 m-0 py-3 border-round-lg text-white w-3 text-center" onClick={() => handleUpdate()}>Edit</p>
                                        </div> :
                                        <div className="flex justify-content-end">
                                            <p className="bg-main p-0 m-0 py-3 border-round-lg text-white w-3 text-center" onClick={() => handleAddNotes()}>Add</p>
                                        </div>
                                    }
                                </div>
                                {error?.services && <span className="error">{error.services}</span>}
                            </div>
                            <div className="p-fluid grid">
                                <div className="col-12 md:col-12 mb-3">
                                    <Table tableData={tableData} setTableData={setTableData} tableHeading={tableHeading} setGetIndex={setGetIndex} setEditButton={setEditButton} setAddService={setAddService} />
                                </div>
                            </div>

                            <div className="submitButton flex">
                                <SaveButton label="Save" onClick={handleSubmit}></SaveButton>
                                <CancelButton label="Cancel" onClick={() => navigate("/account-owner/dashboard")}></CancelButton>
                            </div>
                        </Card>

                    </div>
                </div>
            </ContentArea>
        </>
    )
}

export default TimeClock;
