
import React, { useEffect, useState } from "react";
import { Card } from "primereact/card";
import ContentArea from "../../../../shared/ContentArea";
import InputField from "../../../../components/input/input";
import EmployeeImg from "../../../../assets/images/Employeeicon.png"
import { Calendar } from 'primereact/calendar';
import { Checkbox } from 'primereact/checkbox';
import { viewClientAction, getClientTierAction } from "../../../../Redux/HospitalAction/clientAction";
import { useSelector, useDispatch } from "react-redux";
import DropdownField from "../../../../components/input/dropdown";
import InputNumberField from "../../../../components/input/inputNumber";
import InputTextareaField from "../../../../components/input/inputTextArea";
import { useParams } from "react-router-dom";
import { carrierAction } from "../../../../Redux/HospitalAction/carrierAction";
import { getServiceAction } from "../../../../Redux/HospitalAction/serviceProviderAction";
import Loader from "../../../../pages/Loader";
const ViewClient = () => {
    const dispatch = useDispatch()
    const [date, setDate] = useState(null);
    const[genderValue,setGenderValue] =  useState(null)
    const [gender, setGender] = useState(null);
    const [checked, setChecked] = useState(false);
    const [carrier, setcarrier] = useState(null);
    const [clientTire, setClientTire] = useState(null);
    const [clientClassification, setClientClassification] = useState(null);
    const [carrier_id, setCarrier_Id] = useState(null)
    const [clientTier, setClientTierData] = useState(null)
    const [service, setService] = useState(null)
    const [data, setData] = useState("")
    const Gender = [
        { name: 'Male' },
        { name: 'Female', },

    ]

    const { id } = useParams()
    useEffect(() => {
        dispatch(viewClientAction(id))
        dispatch(carrierAction())
        dispatch(getClientTierAction())
        dispatch(getServiceAction())
    }, [])
    const getData = useSelector((state) => state.viewClient.viewClientProfile)
    useEffect(() => {
        let showEndDate = new Date(getData?.dob?.slice(0, 10))
        setDate(showEndDate)
        setData(getData)
    }, [getData])
    const clientTierData = useSelector((state) => state.getClientTier.getClientTierProfile)
    const carrierData = useSelector((state) => state.carrier.carrierProfile)
    const getallService = useSelector((state) => state.getService.getServiceProfile)
    useEffect(() => {
        carrierData?.length > 0 && carrierData.map((item) => {
            if (item.id == data.carrier) {
                setCarrier_Id(item)
            }
        })
    }, [carrierData, data])
    useEffect(() => {
        clientTierData.length > 0 && clientTierData.map((item) => {
            if (item.id == data.client_tier_id) {
                setClientTierData(item)
            }
        })
    }, [clientTierData, data])
    useEffect(() => {
        getallService?.length > 0 && getallService.map((item) => {
            if (item.id == data.client_subscription_plan) {
                setService(item)
            }
        })
    }, [getallService, data])
    useEffect(()=>{
        Gender?.length > 0 && Gender.map((item)=>{
            if(item.name == data.gender){
                setGenderValue(item)
            }
        })
    },[data])
    const isLoading = useSelector((state) => state.loader.isLoading)
    return (
        <>
            {isLoading === true ? <Loader /> :
                <ContentArea>
                    <div className="add_employees_container">
                        <div className="p-3">
                            <div className="grid card ml-0 mr-0 px-5 mb-5 align-items-center bg-main-light border-round-lg">
                                <h2 className="text-main">
                                    View Client Details
                                </h2>
                            </div>
                            <Card className="shadow-2 p-3 border-round-lg">
                                <div className="p-fluid grid">
                                    <div className="col-12 md:col-12 text-main ">
                                        <h2 className="font-bold" style={{ fontSize: "22px" }}>Client Photo</h2>
                                    </div>
                                </div>

                                <div className="p-fluid grid mb-5 flex align-items-center">
                                    <div className="upload-image col-12 md:col-2 ">
                                        <img src={data?.image != "" ? data.image : EmployeeImg} alt="" height={109} className="w-full h-full upload-image border-1 surface-border"></img>
                                    </div>

                                    <div className="col-12 md:col-10">
                                        <div className="upload_image mb-3">
                                            <label
                                                htmlFor="profile-img"
                                                className="bg-primary cursor-pointer w-3 px-5 border-0 p-2 border-round-md"
                                            >
                                                <i className="pi pi-plus mr-3"></i>
                                                <span>Choose a File</span>
                                            </label>
                                            <input
                                                type="file"
                                                hidden
                                                className="file-upload text-white border-round-lg"
                                                name="profile-img"
                                                accept="image/*"
                                                maxFileSize={1000000}

                                            />

                                        </div>
                                        <p className="text-500 m-0 text-xs"> Acceptable Formats jpg, png only up to 10mb</p>
                                    </div>
                                </div>

                                <div className="p-fluid grid mb-3">
                                    <div className="col-12 md:col-6">
                                        <DropdownField label="Select Client Tier" inputId="dropdown" placeholder="" value={clientTier} reuired disabled
                                            options={clientTierData} onChange={(e) => setClientTire(e.value)} optionLabel="tiers_name" />
                                    </div>

                                    <div className="col-12 md:col-6">
                                        <DropdownField label="Select Client Classification" inputId="dropdown" placeholder="" value={service} required disabled
                                            options={getallService} onChange={(e) => setClientClassification(e.value)} optionLabel="program_name" />
                                    </div>
                                </div>

                                <div className="p-fluid grid ">
                                    <div className=" col-12 md:col-12 text-main ">
                                        <h2 className=" font-bold" style={{ fontSize: "22px" }}>Client Name</h2>
                                    </div>
                                    <div className=" col-12 md:col-4">
                                        <InputField placeholder="" label="First Name" required value={data.first_name} disabled></InputField>
                                    </div>

                                    <div className="col-12 md:col-4">
                                        <InputField placeholder="" label="Middle Name" value={data.middle_name} disabled ></InputField>
                                    </div>

                                    <div className=" col-12 md:col-4">
                                        <InputField placeholder="" label="Last Name" required value={data.last_name} disabled></InputField>
                                    </div>
                                </div>


                                <div className="p-fluid grid">
                                    <div className=" col-12 md:col-12 text-main ">
                                        <h2 className="font-bold" style={{ fontSize: "22px" }}>Client Address</h2>
                                    </div>
                                    <div className="col-12 md:col-6">
                                        <InputField placeholder="" label="House/ Apt. no." required value={data.house_no} disabled></InputField>
                                    </div>

                                    <div className="col-12 md:col-6">
                                        <InputField placeholder="" label="Street Address" required value={data.address_1} disabled></InputField>
                                    </div>
                                    <div className="col-12 md:col-6">
                                        <InputField placeholder="" label="State" required value={data.state} disabled></InputField>
                                    </div>
                                    <div className="col-12 md:col-6">
                                        <InputNumberField
                                            placeholder=""
                                            label="Zip Code"
                                            useGrouping={false}
                                            inputClassName="border-round-md" value={data.zipcode} disabled required />
                                    </div>
                                </div>


                                <div className="p-fluid grid">
                                    <div className=" col-12 md:col-12 text-main ">
                                        <h2 className=" font-bold" style={{ fontSize: "22px" }}>Client Phone No.</h2>
                                    </div>
                                    <div className="col-12 md:col-6 mb-3">
                                        <DropdownField label="Select Carrier" inputId="dropdown" required
                                            placeholder="" value={carrier_id} disabled options={carrierData} onChange={(e) => setcarrier(e.value)} optionLabel="carrier_name" />
                                    </div>

                                    <div className="col-12 md:col-6 mb-3">
                                        <InputNumberField
                                            placeholder="" label="Phone Number"
                                            inputClassName="border-round-md" required value={data.mobile} disabled useGrouping={false} />
                                    </div>
                                    <div className="col-12 md:col-6 mb-3">
                                        <InputField placeholder="" label="Client Email" value={data.email} disabled required></InputField>
                                    </div>
                                    <div className="col-12 md:col-6">
                                        <InputNumberField label="Client MHN No."
                                            placeholder=""
                                            inputClassName="border-round-md" value={data.client_MHN_no} disabled required />
                                    </div>
                                    <div className="col-12 md:col-6 mb-3">
                                        <label className="font block mb-2">Date of Birth  <cod className="text-red">*</cod></label>
                                        <Calendar placeholder="" value={date}  className="calender_picker disable-input" inputClassName="border-round-md" />
                                    </div>

                                    <div className="col-12 md:col-6 mb-3">
                                        <DropdownField label="Gender" inputId="dropdown" required disabled
                                            placeholder="" value={genderValue} options={Gender} onChange={(e) => setGender(e.value)} optionLabel="name" />
                                    </div>
                                    <div className="col-12 md:col-12 mb-3">
                                        <InputTextareaField label="General Note" value={data.general_note === null ? "":data.general_note} disabled rows={5} cols={5}
                                        />
                                    </div>
                                    <div className="col-12 md:col-12 mb-5">
                                        <Checkbox checked={data.is_sms_reminder === 1 ? true : false}></Checkbox> &nbsp;  Check to receive the SMS reminder notifications.
                                    </div>

                                </div>

                            </Card>

                        </div>
                    </div>
                </ContentArea>
            }

        </>
    )
}

export default ViewClient;
