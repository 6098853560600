
import React, { useEffect, useState } from "react";
import ContentArea from "../../../../portal/account-owner/shared/ContentArea";
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Calendar } from "primereact/calendar";
import Table from '../../../../components/TableList/Table';
import { Card } from 'primereact/card';
import { Dropdown } from 'primereact/dropdown';
import TableAddButton from "./TableCountButton";
import { InputSwitch } from "primereact/inputswitch";
import { viewClientAction, editClient,getSubscription,getDiscount } from "../../../../utils/action";
import { useParams } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { validEmail } from "../../../../components/Validation";
import { useNavigate } from "react-router-dom";
import { ProgressSpinner } from 'primereact/progressspinner';
toast.configure();
export default function EditClients() {
    const navigate = useNavigate()
    const [checked, setChecked] = useState(false);
    const [TableData, setTableData] = useState()
    const [selectedProducts, setSelectedProducts] = useState(null);
    const [discount, setDiscount] = useState([])
    const [programData, setProgramData] = useState()
    const [value, setValue] = useState({
        first_name: "",
        middle_name: "",
        last_name: "",
        email: "",
        mobile: "",
        dob: "",
        social_security_no: "",
        client_carrier: "",
        address_1: "",
        address_2: "",
        city: "",
        state: "",
        zipcode: "",
        company_name: "",
        company_email: "",
        company_mobile: "",
        company_alt_mobile: "",
        company_address_1: "",
        company_address_2: "",
        company_city: "",
        company_state: "",
        company_zipcode: "",
        manage_discounts_id: "",
        program_details: [],
        manage_discounts_id: "",
        company_tin_eim: "",
        is_active:""
    })
    const [error, setError] = useState()
    const [selectedDiscount, setSelectdDiscount] = useState(null);
    const [isLoading, setIsLoading] = useState(false)
    const [totalClient, setTotalClient] = useState(0)
    const [totalPrice, setTotalPrice] = useState(0)
    const [finalPrice, setFinalPrice] = useState(0)
    const [countPrice, setCountPrice] = useState(0)
    const TableHeading = [
        { selectionMode: "multiple" },
        { field: "program_name", header: "Program Name", },
        { field: "price_per_person", header: "Price Per Client" },
        { field: "NoOfClient", header: "No. Of Client", body: "tableAddButton" },
        { field: "total", header: "Total" },
    ];
    const { id } = useParams()
    const handleView = async () => {
        try {
            setIsLoading(true)
            let response = await viewClientAction(id)
            setValue(response.data)
            if (response.data.is_active) {
                setChecked(true);
            }
            response.data.dob = new Date(response.data.dob.slice(0, 10));
            let preSelectedPrograms = response.data.subscription_details.map(data => {
                let selected = {
                    subscription_plans_id: data.subscription_plans_id,
                    program_name: data.subscription_plan?.program_name,
                    price_per_person: data.subscription_plan?.price_per_person,
                    number_of_client: data?.number_of_client,
                    total: data.total
                };
               setIsLoading(false)
                return selected;
            })
            setSelectedProducts(preSelectedPrograms)
         
            setIsLoading(false)
        } catch (err) {
            setIsLoading(false)
            let message =
                err && err.message ? err.message : "Something went wrong during login";
            toast.error(err?.response?.data?.message);
            return false;
        }
    }
    useEffect(() => {
        handleView()
    }, [])
    let date = new Date(value?.dob)
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let dt = date.getDate();
    if (dt < 10) {
        dt = '0' + dt;
    }
    if (month < 10) {
        month = '0' + month;
    }
    let dob = year + '-' + month + '-' + dt;

    useEffect(() => {
        setValue((prev) => {
          return {
            ...prev,
            program_details: selectedProducts
          }
        })
        setError((prev) => {
          return {
            ...prev,
            program_details: ""
          }
        })
      }, [selectedProducts])
    const handleSubmit = async () => {
        setValue((prev) => {
            return {
              ...prev,
              manage_discounts_id: discount[0]?.id
            }
          })
        setIsLoading(true)
        try {
            if (value.first_name === "" || value.last_name === "" || value.email === "" || value.mobile === null || value.dob === null ||
                value.address_1 === "" || value.city === "" || value.state === "" || value.zipcode === null || value.company_name === "" ||
                value.company_email === "" || value.company_mobile === null || value.company_alt_mobile === null ||
                value.company_address_1 === "" || value.company_city === "" || value.company_state === "" || value.company_zipcode === null || value.program_details?.length === 0 || !validEmail(value.email) || !validEmail(value.company_email) || value.company_tin_eim === "" || value?.company_tin_eim === null ) {
                setError({
                    ...error,
                    first_name: value.first_name === "" ? "Please Enter First Name" : error.first_name,
                    last_name: value.last_name === "" ? "Please Enter Last Name" : error.last_name,
                    email: value.email === "" ? "Please Enter Email" : !validEmail(value.email) ? "Please Enter Valid Email" : error.email,
                    mobile: value.mobile === null ? "Please Enter Phone Number" : error.mobile,
                    dob: value.dob === null ? "Please Enter dob" : error.dob,
                    address_1: value.address_1 === "" ? "Please Enter Address" : error.address_1,
                    city: value.city === "" ? "Please Enter City" : error.city,
                    state: value.state === "" ? "Please Enter State" : error.state,
                    zipcode: value.zipcode === null ? "Please Enter ZipCode" : error.zipcode,
                    company_name: value.company_name === "" ? "Please Enter Company Name" : error.company_name,
                    company_email: value.company_email === "" ? "Please Enter Company Email" : !validEmail(value.company_email) ? "Please Enter Valid Company EMail" : error.company_email,
                    company_mobile: value.company_mobile === null ? "Please Enter Company Mobile" : error.company_mobile,
                    company_alt_mobile: value.company_alt_mobile === null ? "Please Enter Alt Mobile Number" : error.company_alt_mobile,
                    company_address_1: value.company_address_1 === "" ? "Please Enter Company Address" : error.company_address_1,
                    company_city: value.company_city === "" ? "Please Enter Company City" : error.company_city,
                    comapny_state: value.company_state === "" ? "Please Enter Company State" : error.company_state,
                    company_zipcode: value.company_zipcode === null ? "PLease Enter Company ZipCode" : error.company_zipcode,
                    program_details: value.program_details?.length === 0  ? "Please Select Program Details" : error.program_details,
                    company_tin_eim: value.company_tin_eim === "" || value.company_tin_eim=== null ? "Please Enter Company_tin_eim" : error.company_tin_eim
                })
                setIsLoading(false)
                return false;
            }
            const formData = new FormData()
            formData.append("first_name", value.first_name);
            formData.append("middle_name", value.middle_name);
            formData.append("last_name", value.last_name);
            formData.append("email", value.email);
            formData.append("mobile", value.mobile);
            formData.append("dob", dob);
            formData.append("program_details", JSON.stringify(value.program_details));
            formData.append("social_security_no", value.social_security_no);
            formData.append("address_1", value.address_1);
            formData.append("address_2", value.address_2);
            formData.append("client_carrier", "pppp")
            formData.append("city", value.city);
            formData.append("state", value.state);
            formData.append("zipcode", value.zipcode);
            formData.append("company_name", value.company_name);
            formData.append("company_email", value.company_email);
            formData.append("company_tin_eim", value.company_tin_eim);
            formData.append("company_mobile", value.company_mobile);
            formData.append("company_alt_mobile", value.company_alt_mobile);
            formData.append("company_address_1", value.company_address_1);
            formData.append("company_address_2", value.company_address_2);
            formData.append("company_city", value.company_city);
            formData.append("manage_discounts_id", discount[0]?.id)
            formData.append("company_state", value.company_state);
            formData.append("company_zipcode", value.company_zipcode);
            formData.append("is_active", value.is_active);
            let response = await editClient({ payload: formData, id: id });
            if (response.status === false) {
                setIsLoading(false);
                if (response?.message === "The email has already been taken.") {
                    setError({
                        ...error,
                        email: response?.message
                    })
                    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                    return false;
                }
                else if (response?.message === "The company email has already been taken.") {
                    setError({
                        ...error,
                        company_email: response?.message
                    })
                    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                    return false;
                } else {
                    toast.error(response.message || "Something Wrong")
                }
            }
            if (response.status === true) {
                setIsLoading(false);
                toast.success(response?.message || "Client update Successfully")
                navigate("/sales-person/manage-clients");
                return true;
            }
        } catch (err) {
            setIsLoading(false)
            toast.error(err?.response?.data?.message);
            return false;
        }
    }
    const handleValueChange = (e) => {
        setValue({
            ...value,
            [e?.target?.name]: e?.target?.value,
        });
        setError({
            ...error,
            [e?.target?.name]: "",
        });
    };
    const handleToogle = (e) => {
        setChecked(e.value);
        setValue({
            ...value,
            is_active: e.value ? 1 : 0,
        });
    };
    const getTable = async (selected) => {
        async function fetchMyAPI() {
            let response = await getSubscription();
            let program = response.data.map((item, index) => {
                const payload = {
                    subscription_plans_id: item.id,
                    program_name: item.program_name,
                    price_per_person: item.price_per_person,
                    number_of_client: 0,
                    total: 0
                }
                selected?.forEach((data => {
                    if (data.subscription_plans_id === item.id) {
                        payload.number_of_client = data.number_of_client;
                        payload.total = data.total;
                    }
                }))
                return payload
            })
            setProgramData(program)
        }
        fetchMyAPI()
    }
    useEffect(() => {
        async function fetchMyAPI() {
            getTable(selectedProducts);
        }
        fetchMyAPI();
    }, [selectedProducts])
    useEffect(() => {
        let array = []
        async function fetchMyAPI() {
            let response = await getDiscount();
            array.push(response.data)
            setDiscount(array)
            setSelectdDiscount(response.data)
        }
        fetchMyAPI();
    }, [])
    useEffect(()=>{
        setValue({
            ...value,
            manage_discounts_id:discount[0]?.id
        })
    },[discount])
    useEffect(() => {
        let client = 0;
        let total = 0;
        programData?.map((item) => {
            client += item.number_of_client
            total += item.total
        })
        setTotalClient(client)
        setTotalPrice(total)
        let final = totalPrice * selectedDiscount?.discount_rate_percent / 100
        let totalCount = totalPrice - final
        setCountPrice(totalCount)
        setFinalPrice(final)
    }, [programData, selectedDiscount, totalClient])
function handleGrandTotal() {
    return (
        <>
            <div className="grid justify-content-between mt-2">
                <div className="md:col-2 pl-8">
                    <small className="karla">Grand Total</small>
                </div>
                <div className="md:col-3 pl-8"></div>
                <div className="md:col-2 pl-8">
                    <small className="karla">{totalClient}</small>
                </div>
                <div className="md:col-2 pl-7">
                    <small>${totalPrice}</small>
                </div>
            </div>
            <div className="grid justify-content-between mt-2 bg-main-light">
                <div className="md:col-2 pl-8">
                    <small className="karla">Discount</small>
                </div>
                <div className="md:col-3 pl-8"></div>
                <div className="md:col-2 pl-8">
                    <small className="karla"></small>
                </div>
                <div className="md:col-2 pl-7">
                    <small>${finalPrice || 0}</small>
                </div>
            </div>
            <div className="grid justify-content-between mt-2">
                <div className="md:col-2 pl-8">
                    <small className="karla">Final Total</small>
                </div>
                <div className="md:col-3 pl-8"></div>
                <div className="md:col-2 pl-8">
                    <small className="karla"></small>
                </div>
                <div className="md:col-2 pl-7">
                    <small>${countPrice || 0}</small>
                </div>
            </div>
        </>
    )
}
    return (
        <>
        {isLoading === true ? <ProgressSpinner style={{ width: '50px', height: '50px' }} className='text-center h-screen flex justify-content-center' strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" /> :
            <ContentArea>
                <div className="p-3">

                    <div className="grid card ml-0 mr-0 px-5 mb-5 align-items-center bg-main-light border-round-lg">
                        <h2 className="text-main">
                            Edit Client
                        </h2>
                    </div>
                    <div className="shadow-2 p-5 border-round-lg">
                        <div className="client_details flex align-items-center justify-content-between ">
                            <h2 className="text-main mb-5 p-0 m-0 Karla font-bold " style={{fontSize:"22px"}}>Client Details   </h2>
                            <p className="flex align-items-center text-lg font-">
                                Active
                                <span className="ml-2">
                                <InputSwitch checked={checked}
                                        onChange={(e) => handleToogle(e)}
                                        name="is_active" />
                                </span>
                            </p>
                        </div>
                        <div className="p-fluid grid mb-3">

                            <div className=" col-12 md:col-4 mb-3">
                                <label className="font block mb-2">
                                    First Name<code className="text-red">*</code>
                                </label>
                                <InputText
                                    id=""
                                    className="border-round-md "
                                    name="first_name"
                                    placeholder=""
                                    value={value?.first_name}
                                    onChange={(e) => handleValueChange(e)}
                                />
                                {error?.first_name && <span className="error">{error?.first_name}</span>}
                            </div>
                            <div className="col-12 md:col-4 mb-3 ">
                                <label className="font block mb-2">Middle Name</label>
                                <InputText
                                    id=""
                                    className="border-round-md "
                                    name="middle_name"
                                    placeholder=""
                                    value={value?.middle_name === null ? "" : value?.middle_name}
                                    onChange={(e) => handleValueChange(e)}
                                />
                            </div>
                            <div className="col-12 md:col-4 mb-3">
                                <label className="font block mb-2">
                                    Last Name<code className="text-red">*</code>
                                </label>
                                <InputText
                                    id=""
                                    className="border-round-md "
                                    name="last_name"
                                    placeholder=""
                                    value={value?.last_name}
                                    onChange={(e) => handleValueChange(e)}
                                />
                                {error?.last_name && <span className="error">{error?.last_name}</span>}
                            </div>

                            <div className="col-12 md:col-4 mb-3">
                                <label className="font block mb-2">
                                    Email<code className="text-red">*</code>
                                </label>
                                <InputText
                                    id=""
                                    className="border-round-md "
                                    name="email"
                                    placeholder=""
                                    value={value?.email}
                                    onChange={(e) => handleValueChange(e)}
                                />
                                {error?.email && <span className="error">{error?.email}</span>}
                            </div>
                            <div className="col-12 md:col-4 mb-3 ">
                                <label className="font block mb-2">
                                    Phone No.<code className="text-red">*</code>
                                </label>
                                <InputNumber
                                    id=""
                                    className="border-round-md "
                                    name="mobile"
                                    placeholder=""
                                    useGrouping={false}
                                    maxLength={10}
                                    value={value?.mobile}
                                    onValueChange={(e) => {
                                        setError({
                                            ...error,
                                            mobile: null,
                                        });
                                        setValue({
                                            ...value,
                                            mobile: e.value,
                                        });
                                    }}
                                />
                                {error?.mobile && <span className="error">{error?.mobile}</span>}
                            </div>
                            <div className="col-12 md:col-4 mb-3 ">
                                <label className="font block mb-2">
                                    DOB<code className="text-red">*</code>
                                </label>
                                <Calendar
                                    className="border-round-md calender_picker "
                                    inputClassName="calendar p-2 border-round-md "
                                    name="dob"
                                    placeholder=""
                                    value={value?.dob}
                                    onChange={(e) => {
                                        setError({
                                            ...error,
                                            dob: null,
                                        })
                                        setValue({
                                            ...value,
                                            dob: e.value
                                        })
                                    }}
                                />
                                {error?.dob && <span className="error">{error?.dob}</span>}
                            </div>

                            <div className="col-12 md:col-4 mb-3">
                                <label className="font block mb-2">
                                    Social Security No.
                                </label>
                                <InputText
                                    id=""
                                    inputClassName='border-round-md '
                                    className="border-round-md "
                                    name="social_security_no"
                                    placeholder=""
                                    value={value?.social_security_no === "null" ? "" : value?.social_security_no}
                                    onChange={(e) => handleValueChange(e)}
                                />
                            </div>
                            <div className="col-12 md:col-4 mb-3 ">
                                <label className="font block mb-2">
                                    Address 1<code className="text-red">*</code>
                                </label>
                                <InputText
                                    id=""
                                    className="border-round-md "
                                    name="address_1"
                                    placeholder=""
                                    value={value?.address_1}
                                    onChange={(e) => handleValueChange(e)}
                                />
                                {error?.address_1 && <span className="error">{error?.address_1}</span>}
                            </div>
                            <div className="col-12 md:col-4 mb-3 ">
                                <label className="font block mb-2">
                                    Address 2
                                </label>
                                <InputText
                                    id=""
                                    className="border-round-md "
                                    name="address_2"
                                    placeholder=""
                                    value={value?.address_2 === "null" ? "" : value?.address_2}
                                    onChange={(e) => handleValueChange(e)}
                                />
                            </div>

                            <div className="col-12 md:col-4 mb-3 ">
                                <label className="font block mb-2">
                                    City<code className="text-red">*</code>
                                </label>
                                <InputText
                                    id=""
                                    className="border-round-md "
                                    name="city"
                                    placeholder=""
                                    value={value?.city}
                                    onChange={(e) => handleValueChange(e)}
                                />
                                {error?.city && <span className="error">{error?.city}</span>}
                            </div>
                            <div className="col-12 md:col-4 mb-3 ">
                                <label className="font block mb-2">
                                    State<code className="text-red">*</code>
                                </label>
                                <InputText
                                    id=""
                                    className="border-round-md "
                                    name="state"
                                    placeholder=""
                                    value={value?.state}
                                    onChange={(e) => handleValueChange(e)}
                                />
                                {error?.state && <span className="error">{error?.state}</span>}
                            </div>
                            <div className="col-12 md:col-4 mb-3">
                                <label className="font block mb-2">
                                    Zip Code<code className="text-red">*</code>
                                </label>
                                <InputNumber
                                    id=""
                                    inputClassName='border-round-md '
                                    className="border-round-md "
                                    name=""
                                    placeholder=""
                                    useGrouping={false}
                                    maxLength={6}
                                    value={value?.zipcode}
                                    onValueChange={(e) => {
                                        setError({
                                            ...error,
                                            zipcode: null
                                        })
                                        setValue({
                                            ...value,
                                            zipcode: e.value
                                        })
                                    }}
                                />
                                {error?.zipcode && <span className="error">{error?.zipcode}</span>}
                            </div>


                            <div className='col-12 md:col-12'>
                                <h2 className="text-main mb-3 p-0 m-0 Karla font-bold"style={{fontSize:"22px"}}>Company Details</h2>
                            </div>
                            <div className="col-12 md:col-6 mb-3 ">
                                <label className="font block mb-2">
                                    Company Name<code className="text-red">*</code>
                                </label>
                                <InputText
                                    id=""
                                    className="border-round-md "
                                    name="company_name"
                                    placeholder=""
                                    value={value?.company_name}
                                    onChange={(e) => handleValueChange(e)}
                                />
                                {error?.company_name && <span className="error">{error?.company_name}</span>}
                            </div>
                            <div className="col-12 md:col-6 mb-3 ">
                                <label className="font block mb-2">
                                    Company TIN/EIN<code className="text-red">*</code>
                                </label>
                                <InputText
                                    id=""
                                    className="border-round-md "
                                    name="company_tin_eim"
                                    placeholder=""
                                    value={value?.company_tin_eim}
                                    onChange={(e) => handleValueChange(e)}
                                />
                                {error?.company_tin_eim && <span className="error">{error?.company_tin_eim}</span>}
                            </div>


                            <div className="col-12 md:col-4 mb-3 ">
                                <label className="font block mb-2">
                                    Company Email<code className="text-red">*</code>
                                </label>
                                <InputText
                                    id=""
                                    className="border-round-md "
                                    name="company_email"
                                    placeholder=""
                                    value={value?.company_email}
                                    onChange={(e) => handleValueChange(e)}
                                />
                                {error?.company_email && <span className="error">{error?.company_email}</span>}
                            </div>
                            <div className="col-12 md:col-4 mb-3">
                                <label className="font block mb-2">
                                    Company Contact No.<code className="text-red">*</code>
                                </label>
                                <InputNumber
                                    id=""
                                    inputClassName='border-round-md '
                                    className="border-round-md "
                                    name="company_mobile"
                                    placeholder=""
                                    useGrouping={false}
                                    maxLength={10}
                                    value={value?.company_mobile}
                                    onValueChange={(e) => {
                                        setValue({
                                            ...value,
                                            company_mobile: e.value
                                        })
                                        setError({
                                            ...error,
                                            company_mobile: null
                                        })
                                    }}
                                />
                                {error?.company_mobile && <span className="error">{error?.company_mobile}</span>}
                            </div>
                            <div className="col-12 md:col-4 mb-3">
                                <label className="font block mb-2">
                                    Phone No.<code className="text-red">*</code>
                                </label>
                                <InputNumber
                                    id=""
                                    inputClassName='border-round-md '
                                    className="border-round-md "
                                    name="company_alt_mobile."
                                    placeholder=""
                                    useGrouping={false}
                                    maxLength={10}
                                    value={value?.company_alt_mobile}
                                    onValueChange={(e) => {
                                        setError({
                                            ...error,
                                            company_alt_mobile: null
                                        })
                                        setValue({
                                            ...value,
                                            company_alt_mobile: e.value
                                        })
                                    }}
                                />
                                {error?.company_alt_mobile && <span className="error">{error?.company_alt_mobile}</span>}
                            </div>


                            <div className="col-12 md:col-4 mb-3 ">
                                <label className="font block mb-2">
                                    Address 1<code className="text-red">*</code>
                                </label>
                                <InputText
                                    id=""
                                    className="border-round-md "
                                    name="company_address_1"
                                    placeholder=""
                                    value={value?.company_address_1}
                                    onChange={(e) => handleValueChange(e)}
                                />
                                {error?.company_address_1 &&<span className="error">{error?.company_address_1}</span>}
                            </div>
                            <div className="col-12 md:col-4 mb-3 ">
                                <label className="font block mb-2">
                                    Address 2
                                </label>
                                <InputText
                                    id=""
                                    className="border-round-md "
                                    name="company_address_2"
                                    placeholder=""
                                    value={value?.company_address_2 === "null" ? "" : value?.company_address_2}
                                    onChange={(e) => handleValueChange(e)}
                                />
                            </div>
                            <div className="col-12 md:col-4 mb-3 ">
                                <label className="font block mb-2">
                                    City<code className="text-red">*</code>
                                </label>
                                <InputText
                                    id=""
                                    className="border-round-md "
                                    name="company_city"
                                    placeholder=""
                                    value={value?.company_city}
                                    onChange={(e) => handleValueChange(e)}
                                />
                                {error?.company_city && <span className="error">{error?.company_city}</span>}
                            </div>


                            <div className="col-12 md:col-6 mb-3 ">
                                <label className="font block mb-2">
                                    State<code className="text-red">*</code>
                                </label>
                                <InputText
                                    id=""
                                    className="border-round-md "
                                    name="company_state"
                                    placeholder=""
                                    value={value?.company_state}
                                    onChange={(e) => handleValueChange(e)}
                                />
                          {error?.company_state && <span className="error">{error?.company_state}</span>}
                            </div>

                            <div className="col-12 md:col-6 mb-3">
                                <label className="font block mb-2">
                                    Zip Code<code className="text-red">*</code>
                                </label>
                                <InputNumber
                                    id=""
                                    inputClassName='border-round-md '
                                    className="border-round-md "
                                    name="company_zipcode"
                                    placeholder=""
                                    useGrouping={false}
                                    maxLength={6}
                                    value={value?.company_zipcode}
                                    onValueChange={(e) => {
                                        setError({
                                            ...error,
                                            company_zipcode: null
                                        })
                                        setValue({
                                            ...value,
                                            company_zipcode: e.value
                                        })
                                    }}
                                />
                                {error?.company_zipcode && <span className="error">{error?.company_zipcode}</span>}
                            </div>


                        </div>
                        <Card className="shadow-2 text-main border-round-lg mb-5">
                            <div className="grid justify-content-between align-items-center mb-3">
                                <h2 className="text-main mb-3 p-0 m-0 Karla  ml-2"style={{fontSize:"22px"}}>
                                    Program Details
                                </h2>
                                <Dropdown
                                    className="border-round-md md:col-3 disable-input"
                                    placeholder="Apply Discount"
                                    options={discount}
                                    optionLabel="discount_code"
                                    value={selectedDiscount} />
                            </div>

                            <div className="grid program_table">
                                <div className="md:col-12">
                                    <Table 
                                    tableHeading={TableHeading}
                                    tableData={programData}
                                    setSelectedProducts={setSelectedProducts}
                                    selectedProducts={selectedProducts}
                                    setProgramData={setProgramData}
                                    programData={programData}
                                    title="editClient"
                                    handleGrandTotal={handleGrandTotal()}
                                     />
                                     {error?.program_details && <span className="error">{error?.program_details}</span>}
                                </div>
                            </div>

                        </Card>
                        <div className="grid program_table">
                            <div className='col-12 md:col-12'>
                                <Button
                                    label="Save"
                                    className="save_button text-center  border-0 text-lg font-medium karla p-3 border-round-lg bg-main"
                                    onClick={handleSubmit}
                                />
                                <Button
                                    label="Cancel"
                                    className="cancel_button  text-center border-0 p-3 ml-3 karla border-round-lg"
                                    onClick={() => navigate("/sales-person/manage-clients")}
                                />
                            </div>
                        </div>

                    </div>

                </div>
            </ContentArea>
}
        </>
    );
};