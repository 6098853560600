import React from "react";
import InputField from "../../../../components/input/input";
import SaveButton from "../../../../components/Buttons/SaveButton";
import CancelButton from "../../../../components/Buttons/CancelButton";
import { useDispatch } from "react-redux";
import { PostLocation } from "../../../../Redux/HospitalAction/appoinmentAction";

const AddLocation = ({
  setShowLAdd,
  data,
  setData,
  companyLocation,
  LocationData,
}) => {
  const dispatch = useDispatch();

  const SaveData = (data) => {
    dispatch(PostLocation(data, setShowLAdd, setData));
    LocationData();
  };

  const handleChange = (name) => (e) => {
    setData({ ...data, [name]: e.target.value });
  };

  return (
    <>
      <div className="px-4">
        <h2 className="">Add Location</h2>
        <div className="flex p-0 ">
          <div className="col pl-0">
            <InputField
              label={"Location Name"}
              value={data?.name}
              onChange={handleChange("name")}
            ></InputField>
          </div>
          <div className="col">
            <InputField
              label={"Address"}
              value={data?.address}
              onChange={handleChange("address")}
            ></InputField>
          </div>
        </div>

        <div className="flex py-5">
          <SaveButton
            label={"Save"}
            onClick={() => SaveData(data)}
          ></SaveButton>
          <CancelButton
            label={"Cancel"}
            onClick={() => {
              setShowLAdd(false);
              setData({ id: "", name: "", address: "" });
            }}
          ></CancelButton>
        </div>
      </div>
    </>
  );
};

export default AddLocation;
