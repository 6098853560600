import { types } from "../HospitalType/types";
import HospitalConstants from "../../Services/HospitalConstants";
import { HospitalApi, HospitalDeleteApi } from "../../Services/HospitalApi";
import { showLoaderAction, hideLoaderAction } from "./loaderAction";
import { showToast } from "./toastAction";

export const getPaCodesAction = (data) => async (dispatch) => {
    dispatch(showLoaderAction())
    const res = await HospitalApi("post", HospitalConstants.END_POINT.GETPACODES, data);
    if (res.success === true) {
        if (res.data) {
            dispatch({
                type: types.GET_PA_CODES,
                payload: res.data.reverse()
            })
        }
        dispatch(hideLoaderAction())
    } else {
        dispatch(hideLoaderAction())
    }
}

export const getfilterPaCodeAction = (data) => async (dispatch) => {
    dispatch(showLoaderAction())
    const formData = new FormData()
    formData.append("service_type", data?.service_type)
    const res = await HospitalApi("post", HospitalConstants.END_POINT.GETPACODES, formData);
    if (res.success === true) {
        if (res.data) {
            dispatch({
                type: types.GET_PA_CODES,
                payload: res.data.reverse()
            })
        }
        dispatch(hideLoaderAction())
    } else {
        dispatch(hideLoaderAction())
    }
}
export const deletePaCodeAction = (id, next) => async (dispatch) => {
    dispatch(showLoaderAction())
    const res = await HospitalDeleteApi("get", `${HospitalConstants.END_POINT.DELETEPACODES}/${id}`);
    if (res.success === true) {
        if (res.data) {
            dispatch({
                type: types.DELETE_PA_CODES,
                payload: res.data
            })
        }
        dispatch(hideLoaderAction)
        next()
        dispatch(showToast({ severity: "success", summary: res.message }));
    } else {
        dispatch(hideLoaderAction)
        dispatch(showToast({ severity: "error", summary: res.message }));
    }
}
export const addPaCodeAction = (data, next) => async (dispatch) => {
    dispatch(showLoaderAction())
    const res = await HospitalApi("post", HospitalConstants.END_POINT.ADDPACODES, data);
    if (res.success === true) {
        if (res.data) {
            dispatch({
                type: types.ADD_PA_CODE,
                payload: res.data
            })
        }
        dispatch(hideLoaderAction())
        next()
        dispatch(showToast({ severity: "success", summary: res.message }));
    } else {
        dispatch(hideLoaderAction)
        dispatch(showToast({ severity: "error", summary: res.message }));
    }
}

export const viewPaCodeAction = (id) => async (dispatch) => {
    dispatch(showLoaderAction())
    const res = await HospitalApi("get", `${HospitalConstants.END_POINT.ViewPACODES}/${id}`);
    if (res.success === true) {
        if (res.data) {
            dispatch({
                type: types.VIEW_PA_CODE,
                payload: res.data
            })
        }
        dispatch(hideLoaderAction())
    } else {
        dispatch(hideLoaderAction())
    }
}

export const editPaCodeAction = (id, data, next) => async (dispatch) => {
    dispatch(showLoaderAction())
    const res = await HospitalApi("post", `${HospitalConstants.END_POINT.EDITPACODE}/${id}`, data);
    if (res.success === true) {
        if (res.data) {
            dispatch({
                type: types.EDIT_PA_CODE,
                payload: res.data
            })
        }
        dispatch(hideLoaderAction())
        next()
        dispatch(showToast({ severity: "success", summary: res.message }));
    } else {
        dispatch(hideLoaderAction())
        dispatch(showToast({ severity: "error", summary: res.message }));
    }
}