import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getClientAction } from "../../../Redux/HospitalAction/clientAction";
import { useNavigate, useParams } from "react-router-dom";
import {
    addMedicationScheduleAction,
    deleteMedicationScheduleAction,
    getMedicationScheduleAction,
} from "../../../Redux/HospitalAction/medicationScheduleAction";
import { showToast } from "../../../Redux/HospitalAction/toastAction";
import { updateNestedObject } from "../../../utils/helpers";

const MedicationScheduleContainer = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();
    const [deleteButton, setDeleteButton] = useState(false);
    const dosageOptions = [
        { name: "Hours", value: "hours" },
        { name: "Day", value: "day" },
    ];
    const durationOptions = [
        { name: "Day", value: "day" },
        { name: "Months", value: "months" },
    ];
    const [TableData, setTableData] = useState([]);
    const [clientsList, setClientsList] = useState([]);
    const [selectedId, setSelectedId] = useState({ id: null, index: null });
    const getClientData = useSelector(
        (state) => state.getClient.getClientProfile
    );
    const [clientId, setClientId] = useState("")
    const emptyObject = {
        id: "",
        index: "",
        name: "",
        dosage_amount: "",
        number_of_times: 0,
        send_reminders: false,
        start_date: "",
        end_date: "",
        time_between: {
            number: "",
            time: "",
        },
        duration: {
            number: "",
            time: "",
        },
    };

    const [data, setData] = useState(emptyObject);
    const [error, setError] = useState("");

    useEffect(() => {
        if (getClientData.length) {
            let list = getClientData?.map((client) => {
                return {
                    name: `${client?.profile_id} ${client?.name}`,
                    value: client?.id,
                };
            });
            setClientsList(list);
            params?.id && setData({ ...data, client_id: +params?.id });
        } else {
            dispatch(getClientAction());
        }
    }, [getClientData]);

    useEffect(() => {
        if (params?.id) {
            getMedicationInfo(params?.id);
        }
    }, [params]);

    const getMedicationInfo = (id) => {
        dispatch(
            getMedicationScheduleAction(id, (res) => {
                if (res?.statusCode) {
                    setClientId(Number(res?.data?.client_id))
                    let list = res?.data?.schedules?.map((item, index) => {
                        return {
                            ...item,
                            index: index,
                            SrNo: index + 1,
                            DoseNo: index + 1,
                        };
                    });

                    setTableData(list);
                }
            })
        );
    };

    const TableHeading = [
        { field: "SrNo", header: "Sr. No." },
        { field: "name", header: "Medication Name " },
        { field: "dosage_amount", header: "Amount" },
        { field: "number_of_times", header: "No. of Time" },
        { field: "duration.number", header: "Period" },

        { field: "DoseNo", header: "Dose No" },
        { field: "start_date", header: "Start Date" },
        { field: "icon", header: "Action", body: "addscheduleiconBodyTemplate" },
    ];

    const handleChange = ({ name, value }) => {
        setData({ ...data, [name]: value });
        setError({ ...error, [name]: "" });
    };

    const handleDropDownChange = (e) => {
        const {
            value,
            target: { name },
        } = e;

        const res = updateNestedObject(data, name, value);

        setData({ ...res });
        setError({
            ...error,
            [name]: "",
        });
    };

    const handleNumberChange = (e) => {
        const {
            value,
            target: { name },
        } = e;

        const res = updateNestedObject(data, name, value);

        setData({ ...res });
        setError({
            ...error,
            [name]: "",
        });
    };

    const showErrors = () => {
        if (
            clientId === "" ||
            data.name === "" ||
            data.dosage_amount === 0 ||
            data.dosage_amount === null ||
            data.number_of_times === 0 ||
            !data.start_date ||
            !data.end_date ||
            !data.time_between.number ||
            !data.time_between.time ||
            !data.duration.number ||
            !data.duration.time
        ) {
            setError({
                ...error,
                client_id:
                    clientId === "" ? "Please Select a client." : error?.client_id,
                name: data.name === "" ? "Please Enter Medication Name." : error.name,

                dosage_amount:
                    (data.dosage_amount === 0 || data?.dosage_amount === null)
                        ? "Please Enter Dosage dosage amount."
                        : error.dosage_amount,

                number_of_times:
                    data.number_of_times === 0
                        ? "Please Enter Number of Time."
                        : error.number_of_times,

                number_of_times:
                    data.number_of_times === 0
                        ? "Please Enter Number of Time."
                        : error.number_of_times,

                start_date: data?.start_date ? "" : "Please enter Start Date.",
                end_date: data?.end_date ? "" : "Please enter End Date.",

                "time_between.number": data.time_between.number ? "" : "Please Enter Time Between Dosage.",
                "time_between.time": data.time_between.time ? "" : "Required.",

                "duration.number": data.duration.time ? "" : "Please enter Duration.",
                "duration.time": data.duration.time ? "" : "Required.",
            });
            return false;
        } else {
            return true;
        }
    };

    // const handleAddMedicineDetails = () => {
    //     // let isFormError = showErrors();
    //     // if (isFormError) {
    //     onsubmit();
    //     // }
    // };

    // const handleUpdateMedicalDetails = () => {
    //     // let isFormError = showErrors();
    //     // if (isFormError) {
    //     onsubmit();
    //     // }
    // };

    const handleDateChange = (e) => {
        let date = new Date(e.target.value);
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let dt = date.getDate();
        if (dt < 10) {
            dt = "0" + dt;
        }
        if (month < 10) {
            month = "0" + month;
        }
        let formattedDate = year + "/" + month + "/" + dt;
        setData({
            ...data,
            [e.target?.name]: formattedDate,
        });
        setError({
            ...error,
            [e.target?.name]: "",
        });
    };

    // useEffect(() => {
    //     if (selectedId && !deleteButton) {
    //         onEdit();
    //     }
    // }, [deleteButton, selectedId]);

    const onEdit = (col) => {
        setSelectedId({ id: col?.id, index: col.index })
        const result = {
            index: col.index,
            client_id: col.client_id,
            id: col.id,
            name: col.name,
            dosage_amount: col.dosage_amount,
            number_of_times: col.number_of_times,
            send_reminders: col.send_reminders,
            start_date: col.start_date,
            end_date: col.end_date,
            time_between: col.time_between,
            duration: col.duration,
            SrNo: col?.SrNo,
            DoseNo: col?.DoseNo
        };

        setData(result);
    };

    const handleDelete = () => {
        if (selectedId?.id) {
            dispatch(
                deleteMedicationScheduleAction({ id: [selectedId?.id] }, (res) => {
                    if (res?.statusCode) {
                        dispatch(showToast({ severity: "success", summary: res.message }));
                        let list = TableData.filter((col) => col.id !== selectedId?.id);
                        setTableData(list);
                        setDeleteButton(false);
                        setSelectedId({ id: null, index: null });
                        !list?.length && navigate("/account-owner/medication-schedule")
                    } else {
                        dispatch(showToast({ severity: "error", summary: res.message }));
                    }
                })
            );
        } else if (!selectedId?.id && selectedId?.index != null) {
            let list = TableData.filter((col, index) => index !== selectedId?.index);
            dispatch(showToast({ severity: "success", summary: "Medication schedule has been successfully deleted." }));
            setTableData(list);
            setDeleteButton(false);
            setSelectedId({ id: null, index: null });
            !list?.length && navigate("/account-owner/medication-schedule")
        }
    };

    const onsubmit = () => {
        let isFormError = showErrors();
        if (isFormError) {
            const list = [...TableData]
            if (selectedId?.index > 0 || selectedId?.index === 0) {
                list[selectedId?.index] = data
            } else {
                list.push({
                    ...data,
                    index: TableData.length,
                    SrNo: TableData.length + 1,
                    DoseNo: TableData.length + 1,
                })
            }

            setTableData(list)
            setData(emptyObject);
            setSelectedId({ id: null, index: null });
        }
    };

    const onSave = () => {
        const newList = TableData.map(({ id, index, SrNo, DoseNo, ...rest }) => {
            if (!id) {
                const { id, ...withoutId } = rest;
                return withoutId
            }

            return { id, ...rest }
        });
        let payload = {
            client_id: clientId,
            schedules: newList,
        }

        dispatch(
            addMedicationScheduleAction(payload, (res) => {
                setClientId("")
                navigate("/account-owner/medication-schedule")
            })
        );
    }

    // const handleCancel = () => {
    //     if (TableData?.length && !params?.id) {
    //         setDeleteButton(true);
    //     } else {
    //         navigate("/account-owner/medication-schedule");
    //     }
    // };

    const handleTimeframeDateChange = (name, value) => {
        let date = new Date(value);
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let dt = date.getDate();
        if (dt < 10) {
            dt = "0" + dt;
        }
        if (month < 10) {
            month = "0" + month;
        }
        let formattedDate = year + "/" + month + "/" + dt;

        setData({
            ...data,
            [name]: formattedDate,
        });
        setError({
            ...error,
            [name]: "",
        });
    };

    const onDelete = (col, index) => {
        setSelectedId({ id: col?.id, index: index })
        setDeleteButton(true)
    }

    // useEffect(() => {
    //     console.log("data", data?.duration)
    //     console.log("data", data?.start_date)
    //     if (data?.duration?.number && data?.start_date) {
    //         const calculatedEndDate = new Date(data?.start_date);
    //         calculatedEndDate.setDate(calculatedEndDate.getDate() + data?.duration?.number);
    //         setData((prev) => ({ ...prev, ["end_date"]: calculatedEndDate }))
    //         console.log("calculatedEndDate", calculatedEndDate)
    //     }
    // }, [])

    return {
        data,
        setData,
        handleDropDownChange,
        clientsList,
        error,
        dosageOptions,
        durationOptions,
        handleChange,
        handleNumberChange,
        handleDateChange,
        // handleUpdateMedicalDetails,
        // handleAddMedicineDetails,
        TableHeading,
        TableData,
        setDeleteButton,
        deleteButton,
        selectedId,
        setSelectedId,
        handleDelete,
        // handleCancel,
        navigate,
        handleTimeframeDateChange,
        onEdit,
        onsubmit,
        onSave,
        clientId,
        setClientId,
        setError,
        onDelete,
        emptyObject
    };
};

export default MedicationScheduleContainer;
