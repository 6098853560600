
import React, { useState, useEffect } from "react";
import { Card } from "primereact/card";
import ContentArea from "../../shared/ContentArea";
import InputField from "../../../../components/input/input";
import { Calendar } from 'primereact/calendar';
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getEmployeesAction } from "../../../../Redux/HospitalAction/employessAction";
import { getEmployeeCertificationList, viewEmployeeCertification } from "../../../../Redux/HospitalAction/certificationAction";
import DropdownField from "../../../../components/input/dropdown";
import Loader from "../../../../pages/Loader";
import moment from "moment";
const ViewIndividualCertification = () => {
    const { id } = useParams()
    const dispatch = useDispatch()
    const [category, setCategory] = useState(null);
    const [certification, setCertification] = useState(null);
    useEffect(() => {
        dispatch(getEmployeesAction())
        dispatch(getEmployeeCertificationList())
        dispatch(viewEmployeeCertification(id))
    }, [])
    const getEmployeeData = useSelector((state) => state.getEmployee.getEmployeeProfile)
    const getEmployeeCertificationData = useSelector((state) => state.getEmployeeCertication.getEmployeeCertificationProfile)
    const viewEmployeeCertication = useSelector((state) => state.viewEmployeeCertificationList.viewEmployeeCerticationListProfile)
    const Status = [
        { name: 'active',value :"active" },
        { name: 'inactive',value:"inactive" },
        { name: 'processing', value: "processing" },
    ];
   
    useEffect(() => {
        getEmployeeData?.length > 0 && getEmployeeData?.map((item, index) => {
            if (item.id == viewEmployeeCertication.employee_id) {
                setCategory(item)
            }
        })
    }, [getEmployeeData, viewEmployeeCertication])
    useEffect(() => {
        getEmployeeCertificationData?.length > 0 && getEmployeeCertificationData?.map((item) => {
            if (item.id == viewEmployeeCertication?.certificate_id) {
                setCertification(item)
            }
        })
    }, [getEmployeeCertificationData, viewEmployeeCertication])
    const time = [
        { label: "10min", value: "10min" },
        { label: "15min", value: "15min" },
        { label: "30min", value: "30min" },
        { label: "01hour", value: "01hour" },
        { label: "02hour", value: "02hour" },
        { label: "12hour", value: "12hour" },
        { label: "01Day", value: "01Day" },
        { label: "02Days", value: "02Days" },
        { label: "07Days", value: "07Days" },
        { label: "15Days", value: "15Days" },
        { label: "30Days", value: "30Days" },
    ]
    const isLoading = useSelector((state) => state.loader.isLoading)
    return (
        <>
        {isLoading === true ? <Loader/>:
            <ContentArea>
                <div className="view_individual_cetification_container">
                    <div className="p-3">
                        <div className="grid card ml-0 mr-0 px-5 mb-5 align-items-center bg-main-light border-round-lg">
                            <h2 className="text-main">
                                View Employee Certification
                            </h2>
                        </div>
                        <Card className="shadow-2 p-3 border-round-lg">
                            <div className="p-fluid grid mb-3">
                                <div className="col-12 md:col-6 mb-3">
                                    <DropdownField label="Select Employee" inputId="dropdown" value={category}
                                        options={getEmployeeData} disabled optionLabel="emp_id" />
                                </div>
                                <div className="col-12 md:col-6 mb-3">
                                    <InputField label="Employee Name" value={viewEmployeeCertication?.employee_name} disabled />
                                </div>

                                <div className="col-12 md:col-6 mb-3">
                                    <label className="font block mb-2">Select Certification</label>
                                    <DropdownField inputId="dropdown" value={certification} disabled
                                        options={getEmployeeCertificationData} optionLabel="certification_name" />
                                </div>

                                <div className="col-12 md:col-6 mb-3 ">
                                    <label className="font block mb-3">
                                        Last date of Certification Completion
                                    </label>
                                    <Calendar
                                        className="border-round-md calender_picker "
                                        inputClassName="calendar p-2 border-round-md "
                                        value={new Date(viewEmployeeCertication?.last_date)}
                                        disabled
                                    />
                                </div>
                                <div className="col-12 md:col-6 mb-3">
                                    <DropdownField label="Select Status" inputId="dropdown" value={viewEmployeeCertication?.status} disabled
                                        options={Status} optionLabel="name" />
                                </div>


                                <div className="col-12 md:col-12">
                                    <h2 className="text-main font-bold p-0 " style={{ fontSize: "22px" }} >Next date of Certification Completion</h2>
                                </div>
                                <div className="col-12 md:col-6 mb-3 ">
                                    <label className="font block mb-2">
                                        Select Date
                                    </label>
                                    <Calendar
                                        className="border-round-md calender_picker "
                                        inputClassName="calendar p-2 border-round-md "
                                        value={new Date(viewEmployeeCertication?.next_date)}
                                        disabled
                                    />
                                </div>
                                <div className="col-12 md:col-6 mb-3 ">
                                        <label className="font block mb-2">
                                            Select Time<code className="text-red">*</code>
                                        </label>
                                        <Calendar
                                            className="border-round-md clock_picker mb-2"
                                            inputClassName="calendar p-2 border-round-md "
                                            placeholder="Select"
                                            timeOnly hourFormat="24"
                                            value={new Date(viewEmployeeCertication.time)}
                                                disabled
                                        />
                                    </div>

                                <div className="col-12 md:col-12">
                                    <h2 className="text-main font-bold p-0 " style={{ fontSize: "22px" }} >Set Appointment Reminders</h2>
                                </div>
                                {viewEmployeeCertication?.reminder?.length > 0 && viewEmployeeCertication?.reminder?.map((item,index) => {
                                    return (
                                        <div className="col-12 md:col-4 mb-5">
                                            <label className="font block mb-2">Reminder {index+1}</label>
                                            <DropdownField inputId="dropdown" placeholder="Select" disabled value = {item.reminder} className="border-round-md" options = {time} optionLabel="label"/>
                                        </div>
                                    )
                                })}
                            </div>

                        </Card>
                    </div>
                </div>

            </ContentArea>
}
        </>
    )
}

export default ViewIndividualCertification;
