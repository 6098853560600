import React, { useEffect, useState } from "react";
import { Card } from "primereact/card";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import ContentArea from "../shared/ContentArea";
import InputField from "../../../components/input/input";
import { Checkbox } from "primereact/checkbox";
import { ProgressSpinner } from "primereact/progressspinner";
import { useDispatch, useSelector } from "react-redux";
import {
  viewServiceProviderAction,
  getServiceAction,
  viewManageService,
} from "../../../Redux/HospitalAction/serviceProviderAction";
import { carrierAction } from "../../../Redux/HospitalAction/carrierAction";
import { useParams } from "react-router-dom";
import Loader from "../../../pages/Loader";
import DropdownField from "../../../components/input/dropdown";
import InputNumberField from "../../../components/input/inputNumber";
const ViewProviders = () => {
  const dispatch = useDispatch();
  const [checked, setChecked] = useState(false);
  const [Service, Setservice] = useState(null);
  const [carrier, SetCarrier] = useState(null);
  const [data, setData] = useState({});
  const [selectedCarrier, setSelectedCarrier] = useState(null);
  const [selectedService, setSelectedService] = useState(null);
  const { id } = useParams();
  useEffect(() => {
    dispatch(viewManageService(id));
    dispatch(getServiceAction());
    dispatch(carrierAction());
  }, []);
  const viewServiceData = useSelector(
    (state) => state.viewManageService.viewManageServiceProfile
  );
  const serviceDatalist = useSelector(
    (state) => state.getService?.getServiceProfile
  );
  const carrierData = useSelector((state) => state.carrier.carrierProfile);
  useEffect(() => {
    setData(viewServiceData);
    if (viewServiceData?.is_sms_notification === "1") {
      setChecked(true);
    }
    Setservice(serviceDatalist);
    SetCarrier(carrierData);
  }, [viewServiceData, serviceDatalist, carrierData]);
  useEffect(() => {
    carrier?.map((item) => {
      if (data?.carrier_id === item.id) {
        setSelectedCarrier(item);
      }
    });
  }, [carrier, data, carrierData]);
  useEffect(() => {
    Service?.map((item) => {
      if (data?.service_type_id === item?.id) {
        setSelectedService(item);
      }
    });
  }, [Service, data, serviceDatalist]);
  const isLoading = useSelector((state) => state.loader.isLoading);
  return (
    <>
      {isLoading === true ? (
        <Loader />
      ) : (
        <ContentArea>
          <div className="view_provider_container">
            <div className="p-3">
              <div className="grid card ml-0 mr-0 px-5 mb-5 align-items-center bg-main-light border-round-lg">
                <h2 className="text-main">View Service Providers Details</h2>
              </div>
              <Card className="shadow-2 p-3 border-round-lg">
                <div className="p-fluid grid">
                  <div className="field col-12 md:col-6">
                    <InputField
                      label="Service Providers Name"
                      placeholder=""
                      value={data?.service_provider_name}
                      disabled
                      required
                    />
                  </div>

                  <div className="field col-12 md:col-6">
                    <DropdownField
                      label="Service Type"
                      inputId="dropdown"
                      placeholder="Select"
                      value={selectedService}
                      options={Service}
                      disabled
                      optionLabel="program_name"
                      required
                    />
                  </div>
                  {data?.service?.length > 0 &&
                    data?.service?.map((item, index) => {
                      return (
                        <div className="field col-12 md:col-4">
                          <label className="font block">
                            Service {index + 1}
                          </label>
                          <InputField
                            placeholder=""
                            value={item?.service_name}
                            disabled
                          />
                        </div>
                      );
                    })}
                  <div className="field col-12 md:col-12 text-main ">
                    <h2 className="p-0 m-0">Service Provider Address*</h2>
                  </div>

                  <div className="field col-12 md:col-4">
                    <InputField
                      label="Street Address"
                      placeholder=""
                      value={data?.address === "null" ? "" : data?.address}
                      disabled
                    />
                  </div>

                  <div className="field col-12 md:col-4">
                    <InputField
                      label="State"
                      placeholder=""
                      value={data?.state === "null" ? "" : data?.state}
                      disabled
                    />
                  </div>

                  <div className="field col-12 md:col-4">
                    <InputNumberField
                      label="Zip Code"
                      placeholder=""
                      useGrouping={false}
                      inputClassName="border-round-md"
                      value={data?.zip_code === "null" ? "" : data?.zip_code}
                      disabled
                    />
                  </div>

                  <div className="field col-12 md:col-12 ">
                    <h2 className="text-main p-0 m-0">Contact No*</h2>
                  </div>

                  <div className="field col-12 md:col-4">
                    <InputField
                      label="Email Address"
                      placeholder=""
                      value={data?.email}
                      disabled
                      required
                    />
                  </div>

                  <div className="field col-12 md:col-4">
                    <DropdownField
                      label="Select Carrier"
                      placeholder="Select"
                      disabled
                      value={selectedCarrier}
                      options={carrier}
                      onChange={(e) => SetCarrier(e.value)}
                      optionLabel="carrier_name"
                    />
                  </div>

                  <div className="field col-12 md:col-4">
                    <InputNumberField
                      label="Phone Number"
                      placeholder=""
                      inputClassName="border-round-md"
                      value={data?.phone_number}
                      disabled
                      required
                      useGrouping={false}
                    />
                  </div>
                  <div className="col-12 md:col-12 mb-3">
                    <Checkbox
                      onChange={(e) => setChecked(e.checked)}
                      checked={checked}
                      disabled
                    ></Checkbox>{" "}
                    &nbsp; Check to receive the SMS reminder notifications.
                  </div>
                </div>
              </Card>
            </div>
          </div>
        </ContentArea>
      )}
    </>
  );
};

export default ViewProviders;
