import { useState } from "react";
import DashBoardIcon from "../../../assets/icons/dashboard.png";
import ServiceIcon from "../../../assets/icons/service-providers.png";
import PACodeIcon from "../../../assets/icons/pa-codes.png";
import EmployeesIcon from "../../../assets/icons/employees.png";
import ClientIcon from "../../../assets/icons/clients.png";
import CertificationIcon from "../../../assets/icons/certifications.png";
import AppointmentSchedulesIcon from "../../../assets/icons/appointment-and-scheduling.png";
import MedicationScheduleIcon from "../../../assets/icons/medication schedule.png";
import ExpensesRevenueIcon from "../../../assets/icons/Expenses revenue.png";
import ReportsIcon from "../../../assets/icons/reports.png";
import NoteIcon from "../../../assets/icons/NoteIcon.png";
import MainDashboard from "../MainDashboard";
import ManageProvider from "../service-Providers";
import ManagePACodeList from "../manage-pa-Codes";
import ManageEmployees from "../Employee/manage-employee";
import ManageHourlyRate from "../Employee/manage-hourly-rate";
import EmployeeScheduling from "../Employee/employees-scheduling";
import ManageClientPaCode from "../Clients/client-pa-code";
import ManageClientTier from "../Clients/manage-tier";
import ManageClientList from "../Clients/manage-client-list";
import ManageEmployeeCertification from "../certifications/manage-employee-certification";
import ManageIndividualCertification from "../certifications/manage-individual-certification/inedx";
import ManageClientCertification from "../certifications/manage-client-certification/inedx";
import ManageSchedules from "../Appointment & Scheduling/schedule-view/ManageSchedules";
import ManageService from "../Appointment & Scheduling/manage-services";
import ManageAppointments from "../Appointment & Scheduling/manage-appointment/ManageAppointments";
import MedicationSchedule from "../medication-schedule";
import ManageExpensesCategories from "../expenses-revenues/ManageExpensesCategories";
import ManageExpense from "../expenses-revenues/ManageExpenses/inedx";
import ManageRevenueCategories from "../expenses-revenues/ManageRevenueCategories/index";
import ManageRevenue from "../expenses-revenues/ManageRevenue/index";
import ExpensesReport from "../Reports/ExpensesReport";

const Index = () => {
  const [activeComponent, setActiveComponent] = useState("");
  const changeComponet = (comp) => {
    setActiveComponent(comp);
  };
  function ShowComponent(e) {
    let allComponents = {
      MainDashboard,
      ManageProvider,
      ManagePACodeList,
      ManageEmployees,
      ManageHourlyRate,
      EmployeeScheduling,
      ManageClientPaCode,
      ManageClientTier,
      ManageClientList,
      ManageEmployeeCertification,
      ManageClientCertification,
      ManageIndividualCertification,
      ManageSchedules,
      ManageService,
      ManageAppointments,
      MedicationSchedule,
      ManageExpensesCategories,
      ManageExpense,
      ManageRevenueCategories,
      ManageRevenue,
      ExpensesReport,
    };
    const Component = activeComponent ? allComponents[e] : null;
    return <Component />;
  }
  const allTabs = [
    {
      name: "Dashboard",
      link: "/account-owner/dashboard",
      icon: DashBoardIcon,
      iconHeight: "24px",
      iconWidth: "24px",
      subMenu: false,
    },

    {
      name: "Manage Service Providers",
      link: "/account-owner/manage-providers",
      icon: ServiceIcon,
      iconHeight: "24px",
      iconWidth: "24px",
      subMenu: false,
    },
    {
      name: "Manage PA Code",
      link: "/account-owner/manage-pa-code",
      icon: PACodeIcon,
      iconHeight: "24px",
      iconWidth: "20x",
      subMenu: false,
    },

    {
      name: "Employees",
      link: "",
      icon: EmployeesIcon,
      iconHeight: "24px",
      iconWidth: "16.18px",
      subMenu: [
        { name: "Manage Employees", link: "/account-owner/manage-employees" },
        // { name: "Manage Hourly Rate", link: "/account-owner/manage-hourly-rate" },
        {
          name: "Employee Scheduling",
          link: "/account-owner/employees-scheduling",
        },
      ],
    },
    {
      name: "Clients",
      link: "",
      icon: ClientIcon,
      iconHeight: "22px",
      iconWidth: "25.18px",
      subMenu: [
        {
          name: "Manage Client PA Codes",
          link: "/account-owner/manage-client-pa-codes",
        },
        { name: "Manage Tiers", link: "/account-owner/manage-tiers" },
        { name: "Manage Client ", link: "/account-owner/manage-client-list" },
      ],
    },
    {
      name: "Certifications",
      link: "",
      icon: CertificationIcon,
      iconHeight: "24px",
      iconWidth: "17.22px",
      subMenu: [
        {
          name: "Employee Certification List",
          link: "/account-owner/manage-employee-certification",
        },
        {
          name: "Client Certification List",
          link: "/account-owner/manage-client-certification",
        },
        {
          name: "Manage Employee Certification",
          link: "/account-owner/manage-individual-certification",
        },
        {
          name: "Manage Client Certification",
          link: "/account-owner/client-certification",
        },
      ],
    },

    {
      name: "Appointment & Scheduling",
      link: "",
      icon: AppointmentSchedulesIcon,
      iconHeight: "18px",
      iconWidth: "18px",
      subMenu: [
        {
          name: "Manage Appointments",
          link: "/account-owner/manage-appointment",
        },

        {
          name: "Manage Schedule View",
          link: "/account-owner/manage-schedules",
        },
        { name: "Manage Service List", link: "/account-owner/manage-services" },
      ],
    },

    {
      name: "Medication Schedule",
      link: "/account-owner/medication-schedule",
      icon: MedicationScheduleIcon,
      iconHeight: "20px",
      iconWidth: "20x",
      subMenu: false,
    },

    {
      name: "Expenses & Revenues ",
      link: "",
      icon: ExpensesRevenueIcon,
      iconHeight: "23.96px",
      iconWidth: "24px",
      subMenu: [
        {
          name: "Manage Expenses Categories",
          link: "/account-owner/manage-expenses-categories",
        },
        { name: "Manage Expenses", link: "/account-owner/manage-expenses" },
        {
          name: "Manage Revenue Categories",
          link: "/account-owner/manage-revenue-categories",
        },
        { name: "Manage Revenue", link: "/account-owner/manage-revenue" },
      ],
    },

    {
      name: "Reports",
      link: "/account-owner/expenses-reports",
      icon: ReportsIcon,
      iconHeight: "24px",
      iconWidth: "18.29px",
      subMenu: [
        { name: "Expenses Reports", link: "/account-owner/expenses-reports" },
        { name: "Revenue Reports", link: "/account-owner/revenue-reports" },
        {
          name: "Profit & Loss Report",
          link: "/account-owner/profile-loss-reports",
        },
        {
          name: "Employee Working Hours",
          link: "/account-owner/employee-working-hours",
        },
        {
          name: "Employee Certification Report",
          link: "/account-owner/employee-certification-report",
        },
        {
          name: "Client Certification Report",
          link: "/account-owner/client-certification-report",
        },
        {
          name: "Employee Pay Report",
          link: "/account-owner/employee-pay-report",
        },
      ],
    },
    {
      name: "Notes",
      link: "/account-owner/note",
      icon: NoteIcon,
      iconHeight: "24px",
      iconWidth: "18.29px",
    },
  ];
  return {
    allTabs,
    activeComponent,
    setActiveComponent,
    changeComponet,
    ShowComponent,
  };
};
export default Index;
