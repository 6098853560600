import { types } from "../HospitalType/types";
import HospitalConstants from "../../Services/HospitalConstants";
import { HospitalApi } from "../../Services/HospitalApi";
import { showLoaderAction, hideLoaderAction } from "./loaderAction";
import { showToast } from "./toastAction";
import { authenticate } from "../../Services/authService";

export const signupAction = (data, next) => async (dispatch) => {
    dispatch(showLoaderAction());
    const res = await HospitalApi("post", HospitalConstants.END_POINT.SIGNUP, data);
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.SIGNUP,
                payload: res.data,
            });

            authenticate(res.data.token, () => {
                if (res.success) {
                    dispatch(showToast({ severity: "success", summary: res.message }));
                    localStorage.setItem("name", res?.data?.name)
                }
            });
            dispatch(hideLoaderAction())
            next(true)
            dispatch(showToast({ severity: "success", summary: res.message }));
        }
    } else {
        next(false)
        dispatch(showToast({ severity: "error", summary: res.message }));
        dispatch(hideLoaderAction())
    }
}

export const checkDiscountCodeAction = (data, next) => async (dispatch) => {
    dispatch(showLoaderAction())
    const res = await HospitalApi("post", HospitalConstants.END_POINT.DISCOUNTCODE, data);
    if (res.success === true) {
        if (res.data) {
            dispatch({
                type: types.DISCOUNT_CODE,
                payload: res.data
            })
        }
        localStorage.setItem("name", res.data.name)
        next(true)
        dispatch(hideLoaderAction())
        dispatch(showToast({ severity: "success", summary: res.message }));
    } else {
        next(false)
        dispatch(hideLoaderAction())
        dispatch(showToast({ severity: "error", summary: res.message }));
    }
}

export const paymenDataAction = (data, next) => async (dispatch) => {
    dispatch(showLoaderAction())
    const res = await HospitalApi("post", HospitalConstants.END_POINT.Payment, data);
    if (res.success === true) {
        if (res.data) {
            dispatch({
                type: types.PAYMENT,
                payload: res.data
            })
        }
        dispatch(hideLoaderAction())
        next(res.data)
    } else {
        next(res.data)
        dispatch(hideLoaderAction())
        dispatch(showToast({ severity: "error", summary: res.message }));
    }
}

export const subscriptionPlanAction = () => async (dispatch) => {
    dispatch(showLoaderAction())
    const res = await HospitalApi("get", HospitalConstants.END_POINT.SUBSCRIPTIONPLAN);
    if (res.success === true) {
        if (res.data) {
            dispatch({
                type: types.SUBSCRIPTION_PLAN,
                payload: res.data
            })
        }
        dispatch(hideLoaderAction())
    } else {
        dispatch(hideLoaderAction())
    }
}