import React, { useEffect, useState } from "react";
import ContentArea from "../../../shared/ContentArea";
import SearchIcon from "../../../../../assets/icons/search.png";
import Table from "../../../../../components/TableList/Table";
import { getAppointmentDataAction } from "../../../../../Redux/HospitalAction/appoinmentAction";
import { useDispatch, useSelector } from "react-redux";
const ViewSchedulingData = () => {
  const dispatch = useDispatch();
  const [tableData, setTableData] = useState("");

  useEffect(() => {
    dispatch(getAppointmentDataAction());
  }, []);

  const getData = useSelector(
    (state) => state.getAppointmentData.getAppointmentDataProfile
  );

  useEffect(() => {
    if (getData?.length !== 0) {
      setTableData(getData);
    } else {
      setTableData("");
    }
  }, [getData]);

  const TableHeading = [
    { field: "SrNo", header: "Sr. No.", body: "srnoTemplates" },
    { field: "appointment_name", header: "Appointment Name" },
    {
      field: "duration",
      header: "Appointment Day",
      body: "showAppointmentDayTemplate",
    },
    { field: "notes", header: "Notes" },
    { field: "icons", header: "Action", body: "showAppointmentTemplate" },
  ];

  return (
    <>
      <ContentArea>
        <div className="manage_service_container p-3 ">
          <div className="grid mb-5 mr-0 ml-0 align-items-center bg-main-light border-round-lg shadow-1 flex justify-content-around">
            <div className="md:col-7">
              <h1 className="text-main" style={{ fontSize: "24px" }}>
                Schedule List
              </h1>
            </div>
            <div className="md:col-3">
              <div className="search-box w-full">
                <img
                  src={SearchIcon}
                  alt="Search Icon"
                  width="15px"
                  height="15px"
                />
                <input type="search" placeholder="Search" />
              </div>
            </div>
          </div>

          <div className="grid mt-2">
            <div className="md:col-12">
              <Table tableHeading={TableHeading} tableData={tableData} />
            </div>
          </div>
        </div>
      </ContentArea>
    </>
  );
};

export default ViewSchedulingData;
