
import React from "react";
import { Button } from "primereact/button";
export default function CancelButton({ label, onClick, btnclass }) {
    return (
        <div>

            <Button
                onClick={onClick}
                label={label}
                className={` cancel_button  text-center border-0 p-3  karla border-round-lg ${btnclass}`}
            />
        </div>
    );
}
