
import React, { useState } from "react";
import { Card } from "primereact/card";
import ContentArea from "../../../../shared/ContentArea";
import { Checkbox } from 'primereact/checkbox';
import InputField from "../../../../components/input/input";
import EmployeIcon from "../../../../assets/images/Employeeicon.png"
import SaveButton from "../../../../components/Buttons/SaveButton";
import CancelButton from "../../../../components/Buttons/CancelButton";
import { Calendar } from 'primereact/calendar';
import { addClientAction, getClientTierAction } from "../../../../Redux/HospitalAction/clientAction";
import DropdownField from "../../../../components/input/dropdown";
import { getServiceAction } from "../../../../Redux/HospitalAction/serviceProviderAction";
import InputNumberField from "../../../../components/input/inputNumber";
import InputTextareaField from "../../../../components/input/inputTextArea";
import { carrierAction } from "../../../../Redux/HospitalAction/carrierAction";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "../../../../pages/Loader";
const AddClient = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [date, setDate] = useState(null);
    const [checked, setChecked] = useState(false);
    const [gender, setGender] = useState(null);
    const [carrier, setcarrier] = useState(null);
    const [clientTire, setClientTire] = useState(null);
    const [ClientTire, setClientTier] = useState(null)
    const [clientClassification, setClientClassification] = useState(null);
    const [service, setService] = useState(null)
    const [showCarrier, setShowCarrier] = useState(null)
    const [data, setData] = useState({
        first_name: "",
        middle_name: "",
        last_name: "",
        email: "",
        mobile: "",
        dob: "",
        house_no: "",
        address_1: "",
        state: "",
        zipcode: "",
        client_MHN_no: "",
        gender: "",
        image: "",
        carrier_id: "",
        client_tier_id: "",
        client_subscription_plan_id: "",
        is_sms_reminder: "",
        general_note: ""
    })
    const [error, setError] = useState("")
    const handleChange = (e) => {
        const { name, value } = e.target
        setError({
            ...error,
            [name]: null
        })
        setData({
            ...data,
            [name]: value
        })
    }
    useEffect(() => {
        dispatch(getClientTierAction())
        dispatch(getServiceAction())
        dispatch(carrierAction())
    }, [])
    const clientTierData = useSelector((state) => state.getClientTier.getClientTierProfile)
    const getallService = useSelector((state) => state.getService.getServiceProfile)
    const carrierData = useSelector((state) => state.carrier.carrierProfile)
    useEffect(() => {
        setClientTier(clientTierData)
        setService(getallService)
        setShowCarrier(carrierData)
    }, [clientTierData, getallService, carrierData])
    const Gender = [
        { name: 'Male' },
        { name: 'Female', },

    ]
    const handleNumberChange = (e) => {
        setError({
            ...error,
            [e.target?.name]: ""
        })
        setData({
            ...data,
            [e.target?.name]: e?.value
        })
    }
    useEffect(() => {
        setData({
            ...data,
            client_tier_id: clientTire?.id
        })
        setError({
            ...error,
            client_tier_id: null
        })
    }, [clientTire])
    useEffect(() => {
        setData({
            ...data,
            client_subscription_plan_id: clientClassification?.id
        })
        setError({
            ...error,
            client_subscription_plan_id: null
        })
    }, [clientClassification])
    useEffect(() => {
        setData({
            ...data,
            carrier_id: carrier?.id
        })
        setError({
            ...error,
            carrier_id: null
        })
    }, [carrier])
    useEffect(() => {
        setData({
            ...data,
            gender: gender?.name
        })
        setError({
            ...error,
            gender: null
        })
    }, [gender])
    useEffect(() => {
        if (checked === true) {
            setData({
                ...data,
                is_sms_reminder: 1
            })
        }
        else {
            setData({
                ...data,
                is_sms_reminder: 0
            })
        }
    }, [checked])
    useEffect(() => {
        let date1 = new Date(data.dob)
        let year = date1.getFullYear();
        let month = date1.getMonth() + 1;
        let dt = date1.getDate();
        if (dt < 10) {
            dt = '0' + dt;
        }
        if (month < 10) {
            month = '0' + month;
        }
        let dob = year + '-' + month + '-' + dt;;
        setDate(dob)
    }, [data.dob])
    const handleSubmit = () => {
        if (data.client_tier_id === "" || data.client_subscription_plan_id === "" || data.first_name === "" || data.last_name === "" || data.house_no === "" ||
            data.address_1 === "" || data.state === "" || data.zipcode === "" || data.carrier_id === "" || data.mobile === "" || data.email === "" ||
            data.client_MHN_no === "" || data.dob === "" || data.gender === "" || data.note === "") {
            setError({
                ...error,
                client_tier_id: data.client_tier_id === "" ? "Please Select Client Tier" : error.client_tier_id,
                client_subscription_plan_id: data.client_subscription_plan_id === "" ? "Please Select Subscription Plan" : error.client_subscription_plan_id,
                first_name: data.first_name === "" ? "Please Enter First Name" : error.first_name,
                last_name: data.last_name === "" ? "Please Enter Last Name" : error.last_name,
                house_no: data.house_no === "" ? "Please Enter Room/ Apt./ Seat Address" : error.house_no,
                address_1: data.address_1 === "" ? "Please Enter Address" : error.address_1,
                state: data.state === "" ? "Please Enter State" : error.state,
                zipcode: data.zipcode === "" ? "Please Enter Zip Code" : error.zipcode,
                carrier_id: data.carrier_id === "" ? "Please Select Carrier" : error.carrier_id,
                mobile: data.mobile === "" ? "Add Mobile Number" : error.mobile,
                email: data.email === "" ? "Please Enter Email" : error.email,
                client_MHN_no: data.client_MHN_no === "" ? "Please Enter MHN Number" : error.client_MHN_no,
                dob: data.dob === "" ? "Please Choose DOB" : error.dob,
                gender: data.gender === "" ? "Please choose Gender" : error.gender
            })
            return false;
        }
        const obj = data;
        let array = Object.entries(obj)
        const formData = new FormData()
        for (let i = 0; i < array.length; i++) {
            if (array[i][0] === "dob") {
                formData.append(array[i][0], date)
            } else {
                formData.append(array[i][0], array[i][1])
            }

        }
        dispatch(addClientAction(formData, () => navigate("/account-owner/manage-client-list")))

    }
    const isLoading = useSelector((state) => state.loader.isLoading)
    return (
        <>
        {isLoading === true ? <Loader/>:
            <ContentArea>
                <div className="add_employees_container">
                    <div className="p-3">
                        <div className="grid card ml-0 mr-0 px-5 mb-5 align-items-center bg-main-light border-round-lg">
                            <h2 className="text-main">
                                Add Client Details
                            </h2>
                        </div>
                        <Card className="shadow-2 p-3 border-round-lg">
                            <div className="p-fluid grid">
                                <div className="col-12 md:col-12 text-main ">
                                    <h2 className="font-bold" style={{ fontSize: "22px" }}>Client Photo</h2>
                                </div>
                            </div>

                            <div className="p-fluid grid flex align-items-center mb-4">
                                <div className="upload-image col-12 md:col-2">
                                    <p className="mb-2 text-sm">Upload Image</p>
                                    <img
                                        src={
                                            data.image
                                                ? URL.createObjectURL(data?.image)
                                                : EmployeIcon
                                        }
                                        alt=""
                                        className="w-full h-full upload-image border-1 surface-border"
                                        height={109}
                                    />
                                </div>
                                <div className="col-12 md:col-10">
                                    <div className="upload_image mb-3">
                                        <label
                                            htmlFor="profile-img"
                                            className="bg-primary cursor-pointer w-3 px-5 border-0 p-2 border-round-md"
                                        >
                                            <i className="pi pi-plus mr-3"></i>
                                            <span>Choose a File</span>
                                        </label>
                                        <input
                                            type="file"
                                            mode="basic"
                                            hidden
                                            className="file-upload text-white border-round-lg"
                                            id="profile-img"
                                            chooseLabel="Choose a file"
                                            name="profile-img"
                                            accept="image/*"
                                            maxFileSize={1000000}
                                            onChange={(e) => {
                                                setError({
                                                    ...error,
                                                    image: null,
                                                });
                                                setData({ ...data, image: e.target.files[0] });
                                            }}
                                        />
                                    </div>
                                    <p className="text-500 m-0 text-xs"> Acceptable Formats jpg, png only </p>
                                </div>
                            </div>


                            <div className="p-fluid grid mb-3">
                                <div className="col-12 md:col-6">
                                    <DropdownField label="Select Client Tier" required inputId="dropdown" placeholder="Select" value={clientTire}
                                        options={ClientTire} onChange={(e) => setClientTire(e.value)} optionLabel="tiers_name" />
                                    {error.client_tier_id && <span className="error">{error.client_tier_id}</span>}
                                </div>

                                <div className="col-12 md:col-6">
                                    <DropdownField label="Select Client Classification" required inputId="dropdown" placeholder="Select" value={clientClassification}
                                        options={service} onChange={(e) => setClientClassification(e.value)} optionLabel="program_name" />
                                    {error.client_subscription_plan_id && <span className="error">{error.client_subscription_plan_id}</span>}
                                </div>
                            </div>

                            <div className="p-fluid grid ">
                                <div className=" col-12 md:col-12 text-main ">
                                    <h2 className=" font-bold" style={{ fontSize: "22px" }}>Client Name</h2>
                                </div>
                                <div className=" col-12 md:col-4">
                                    <InputField label="First Name" required name="first_name" onChange={handleChange} />
                                    {error.first_name && <span className="error">{error.first_name}</span>}
                                </div>

                                <div className="col-12 md:col-4">
                                    <InputField label="Middle Name" name="middle_name" onChange={handleChange} />
                                </div>

                                <div className=" col-12 md:col-4">
                                    <InputField label="Last Name" required name="last_name" onChange={handleChange} />
                                    {error.last_name && <span className="error">{error.last_name}</span>}
                                </div>
                            </div>


                            <div className="p-fluid grid">
                                <div className=" col-12 md:col-12 text-main ">
                                    <h2 className="font-bold" style={{ fontSize: "22px" }}>Client Address</h2>
                                </div>
                                <div className="col-12 md:col-6">
                                    <InputField label="House/ Apt. no." required name="house_no" onChange={handleChange} />
                                    {error.house_no && <span className="error">{error.house_no}</span>}
                                </div>

                                <div className="col-12 md:col-6">
                                    <InputField label="Street Address" required name="address_1" onChange={handleChange} />
                                    {error.address_1 && <span className="error">{error.address_1}</span>}
                                </div>
                                <div className="col-12 md:col-6">
                                    <InputField label="State" required name="state" onChange={handleChange} />
                                    {error.state && <span className="error">{error.state}</span>}
                                </div>
                                <div className="col-12 md:col-6">
                                    <InputNumberField label="Zip Code" useGrouping={false} maxLength={5} required
                                        inputClassName="border-round-md" name="zipcode" onValueChange={handleNumberChange} />
                                    {error.zipcode && <span className="error">{error.zipcode}</span>}
                                </div>
                            </div>


                            <div className="p-fluid grid">
                                <div className=" col-12 md:col-12 text-main ">
                                    <h2 className=" font-bold" style={{ fontSize: "22px" }}>Client Phone No.</h2>
                                </div>
                                <div className="col-12 md:col-6 mb-3">
                                    <DropdownField label="Select Carrier" inputId="dropdown" required
                                        placeholder="Select" value={carrier} options={showCarrier} onChange={(e) => setcarrier(e.value)} optionLabel="carrier_name" />
                                    {error.carrier_id && <span className="error">{error.carrier_id}</span>}
                                </div>

                                <div className="col-12 md:col-6 mb-3">
                                    <InputNumberField label="Phone Number" required maxLength={10} useGrouping={false}
                                        inputClassName="border-round-md" name="mobile" onValueChange={handleNumberChange} />
                                    {error.mobile && <span className="error">{error.mobile}</span>}
                                </div>
                                <div className="col-12 md:col-6 mb-3">
                                    <InputField label="Client Email" required name="email" onChange={handleChange} />
                                    {error.email && <span className="error">{error.email}</span>}
                                </div>
                                <div className="col-12 md:col-6">
                                    <InputNumberField label="Client MHN No." required
                                        inputClassName="border-round-md" name="client_MHN_no" onValueChange={handleNumberChange} useGrouping={false} allowEmpty= {true} />
                                    {error.client_MHN_no && <span className="error">{error.client_MHN_no}</span>}
                                </div>
                                <div className="col-12 md:col-6 mb-3">
                                    <label className="font block mb-2">Date of Birth <cod className="text-red">*</cod></label>
                                    <Calendar value={data.dob} onChange={handleNumberChange} name="dob" className="calender_picker" inputClassName="border-round-md" />
                                    {error.dob && <span className="error">{error.dob}</span>}
                                </div>

                                <div className="col-12 md:col-6 mb-3">
                                    <DropdownField label="Gender" inputId="dropdown" required
                                        placeholder="Select" value={gender} options={Gender} onChange={(e) => setGender(e.value)} optionLabel="name" />
                                    {error.gender && <span className="error">{error.gender}</span>}
                                </div>
                                <div className="col-12 md:col-12 mb-3">
                                    <InputTextareaField label="General Note" value={data.general_note} onChange={handleChange} name="general_note" rows={5} cols={5}
                                    />
                                </div>
                                <div className="col-12 md:col-12 mb-5">
                                    <Checkbox onChange={e => setChecked(e.checked)} checked={checked}></Checkbox> &nbsp;  Check to receive the SMS reminder notifications.
                                </div>

                            </div>
                            <div className="submitButton flex">
                                <SaveButton label="Save" onClick={handleSubmit}></SaveButton>
                                <CancelButton label="Cancel" onClick={() => navigate("/account-owner/manage-client-list")}></CancelButton>
                            </div>
                        </Card>

                    </div>
                </div>
            </ContentArea>
}
        </>
    )
}

export default AddClient;
