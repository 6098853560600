
import React, { useState } from "react";
import ContentArea from "../../../shared/ContentArea";
import Table from '../../../components/TableList/Table';
import { useNavigate } from "react-router-dom";
import Index from '../Index';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import Deleteimg from "../../../assets/images/deleted.png";
import SearchIcon from "../../../assets/icons/search.png"
import FilterIcon from "../../../assets/icons/filter.png"

const RevenueReport = () => {

  const [deleteButton, setDeleteButton] = useState(false)

  const { isNextPage, setIsNextPage } = Index();
  const navigate = useNavigate()

  const AddRevenue = () => {
    setIsNextPage(!isNextPage)
    // navigate("/account-owner/add-revenue")
  }


  const TableData = [
    {
      Date: "31/10/2022",
      ExpenseName: "Electricity",
      CategoryName: "Expense 1",
      Description: "Electricity bill for oct.",
      ExpenseAmount$: "$200",

    },
    {
      Date: "31/10/2022",
      ExpenseName: "Electricity",
      CategoryName: "Expense 1",
      Description: "Electricity bill for oct.",
      ExpenseAmount$: "$200",
    },
    {
      Date: "31/10/2022",
      ExpenseName: "Electricity",
      CategoryName: "Expense 1",
      Description: "Electricity bill for oct.",
      ExpenseAmount$: "$200",
    },
    {
      Date: "31/10/2022",
      ExpenseName: "Electricity",
      CategoryName: "Expense 1",
      Description: "Electricity bill for oct.",
      ExpenseAmount$: "$200",
    },
    {
      Date: "31/10/2022",
      ExpenseName: "Electricity",
      CategoryName: "Expense 1",
      Description: "Electricity bill for oct.",
      ExpenseAmount$: "$200",
    },
    {
      Date: "31/10/2022",
      ExpenseName: "Electricity",
      CategoryName: "Expense 1",
      Description: "Electricity bill for oct.",
      ExpenseAmount$: "$200",
    },
    {
      Date: "31/10/2022",
      ExpenseName: "Electricity",
      CategoryName: "Expense 1",
      Description: "Electricity bill for oct.",
      ExpenseAmount$: "$200",
    },
    {
      Date: "31/10/2022",
      ExpenseName: "Electricity",
      CategoryName: "Expense 1",
      Description: "Electricity bill for oct.",
      ExpenseAmount$: "$200",
    },
    {
      Date: "31/10/2022",
      ExpenseName: "Electricity",
      CategoryName: "Expense 1",
      Description: "Electricity bill for oct.",
      ExpenseAmount$: "$200",
    },
    {
      Date: "31/10/2022",
      ExpenseName: "Electricity",
      CategoryName: "Expense 1",
      Description: "Electricity bill for oct.",
      ExpenseAmount$: "$200",
    },

  ]

  const TableHeading = [
    { selectionMode: "multiple" },
    { field: "Date", header: "Date", },
    { field: "ExpenseName", header: "Expense Name", },
    { field: "CategoryName", header: "Category Name", },
    { field: "Description", header: "Description", },
    { field: "ExpenseAmount$", header: "Expense Amount $", },
    { field: "icons", header: "Action", body: "expensesreportsiconBodyTemplate" },

  ];
  const handleGrandTotal = () => {
    return (
      <div className="grid bg-blue-100 border-round-md p-0 m-0 mt-4">
        <div className="col-12 md:col-8">
          <p className="mx-2"> Total Amount </p>
        </div>
        <div className="col-12 md:col-4">
          <p className="ml-7"> $ 2000 </p>
        </div>
      </div>
    )
  }
  return (
    <>

      <ContentArea>
        <div className="manage_employees_container p-3 ">

          <div className="grid mb-5 mr-0 ml-0 align-items-center bg-main-light border-round-lg shadow-1 flex justify-content-around">
            <div className="md:col-5 ">
              <h1 className="text-main  " style={{ fontSize: "24px" }}>
                Revenue Report
              </h1>
            </div>
            <div className="md:col-3">
              <div className="search-box w-full">
                <img
                  src={SearchIcon}
                  alt="Search Icon"
                  width="15px"
                  height="15px"
                />
                <input type="search" placeholder="Search" />
              </div>
            </div>
            <div className="md:col-1 relative bg-main px-3 border-round-md w-max ">

              <div className="border-round-md text-white text-center text-base relative karla">
                Filter &nbsp;
                <img src={FilterIcon} alt="" width={15} /></div>


            </div>
            <div className="md:col-2 w-max ">
              <Button label="Export" className="bg-main karla px-4 border-round-md" />
            </div>
          </div>

          <div className="grid mt-2 mb-5">
            <div className="md:col-12">

              <Table tableHeading={TableHeading}
                tableData={TableData}
                setDeleteButton={setDeleteButton}
                handleGrandTotal={handleGrandTotal()}
                title = "expensesReport"
                />
            </div>

          </div>

          {
            deleteButton ?
              <Dialog
                className="table_delete_modal flex justify-content-center "
                visible={deleteButton}
                onHide={() => setDeleteButton(false)}
                style={{ width: '30vw' }}>
                <p className="m-0">
                  <div className=" flex justify-content-center">
                    <img src={Deleteimg} alt="" width={150} height={127}></img>
                  </div>
                  <div className="text-center mb-5">
                    <h4 className=" p-0 m-0 ">Are you sure you want to delete this user?</h4>
                    <p className="text-xs text-400">All associated data will also be deleted! There is no undo!</p>
                  </div>
                  <div className=" btn flex justify-content-center">
                    <div className="text-center">
                      <Button
                        label="Cancel"
                        className="w-full surface-500 p-3 px-5    border-0 border-round-md "
                        onClick={() => setDeleteButton(false)}
                      />
                    </div>
                    <div className="text-center">
                      <Button
                        label="Delete"
                        className=" w-full px-5 border-0 p-3 ml-3 border-round-md bg-red-500"

                      />
                    </div>
                  </div>
                </p>
              </Dialog> : null
          }
        </div>
      </ContentArea>
    </>
  )
}

export default RevenueReport;
