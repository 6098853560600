import React from "react";
import RessmedxLogo from "../assets/images/resmedx 2.png";
import LoginTopbar from "./LoginTopbar";
export default function Login() {
  return (
    <>
      <LoginTopbar />
      <div
        className="flex justify-content-center  align-items-center"
        style={{ height: "80vh" }}
      >
        <div className="">
          <h2 className="text-main text-7xl">Welcome To ResMedX</h2>
          <div className="flex justify-content-center">
            <img src={RessmedxLogo} width="406px" height="318px"></img>
          </div>
        </div>
      </div>
    </>
  );
}
