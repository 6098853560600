import React from "react";
import MedicationScheduleForms from "./MedicationScheduleForms";

const EditSchedule = () => {
  return (
    <>
      <MedicationScheduleForms title={"Edit"} />
    </>
  );
};

export default EditSchedule;
