import React from "react";
import Logo from "../../../assets/icons/resmedx-logo-white.png";
import Accordion from "../../../components/Accordion";
import Index from "../Index";
import LogoWhiteIcon from "../../../assets/icons/resmedx-icon-logo-white.png";
import TogleIcon from "../../../assets/icons/toggle-white-icon.png";

export default function SideBar({
  allTabs,
  activeComponent,
  changeComponent,
  showFullSideBar,
  setShowFullSideBar,
}) {
  const { setIsNextPage } = Index();
  return (
    <>
      <div
        className="sidebar hidden md:block "
        style={{ width: !showFullSideBar ? "300px" : "100px" }}
      >
        <img
          className={!showFullSideBar ? "logo" : "logo-icon-only"}
          src={showFullSideBar ? LogoWhiteIcon : Logo}
          alt="ResMedX"
        />
        <hr className="m-0 border-none bg-white" style={{ height: "1px" }} />
        <div className="menu-items">
          <ul className="accordion">
            {/* <li>Dashboard</li> */}
            {allTabs.map(
              ({ name, link, icon, iconWidth, iconHeight, subMenu }, key) => (
                <Accordion
                  activeComponent={activeComponent}
                  heading={showFullSideBar ? "" : name}
                  key={key}
                  link={link}
                  icon={icon}
                  iconWidth={iconWidth}
                  iconHeight={iconHeight}
                  changeComponent={changeComponent}
                  showNextPage={setIsNextPage}
                  content={subMenu && !showFullSideBar ? subMenu : null}
                />
              )
            )}
          </ul>
        </div>

        <span className="toggle-icon">
          <img
            src={TogleIcon}
            alt=""
            onClick={() => setShowFullSideBar((prev) => !prev)}
            className="cursor-pointer"
            style={{ transform: showFullSideBar ? "rotate(180deg)" : "" }}
          />
        </span>
      </div>
    </>
  );
}
