import { InputText } from "primereact/inputtext";
import React from "react";

export default function InputField({
  label,
  name,
  errorMessage,
  extraClassName,
  required,
  inputClass,
  disabled,
  onChange,
  ...props
}) {
  return (
    <>
      <label htmlFor={name}>
        {label} {required && <span className="error">*</span>}
      </label>
      <InputText
        id={name}
        disabled={disabled}
        name={name}
        className={`w-full mt-2 border-round-md ${inputClass} ${
          errorMessage ? "p-invalid" : null
        }`}
        {...props}
        onChange={onChange}
      />
      {errorMessage ? <small className="error">{errorMessage}</small> : null}
    </>
  );
}
