import React, { useState, useEffect } from "react";
import { Divider } from "primereact/divider";
import { InputText } from "primereact/inputtext";
import LoginModalImg from "../assets/images/login_modal_img.png";
import { Link, json } from "react-router-dom";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Password } from "primereact/password";
import { Checkbox } from "primereact/checkbox";
import Logo from "../assets/icons/resmedx-logo-blue.png";
import { validEmail } from "../components/Validation";
import {
  LoginAction,
  forgetPasswordEmail,
  verifyOtps,
  NewPasswordChange,
} from "../utils/action";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, useLocation } from "react-router-dom";
import OTPInput, { ResendOTP } from "otp-input-react";
import SalesPersonLoginImg from "../assets/images/sales person login img.png";
import {
  HospitalOtpVerification,
  HospitalSetPassword,
} from "../utils/HospitalAction";
import { NavLink } from "react-router-dom";
import Signup from "./Signup";
import { useDispatch, useSelector } from "react-redux";
import { loginAction } from "../Redux/HospitalAction/loginAction";
import InputField from "../components/input/input";
import PasswordField from "../components/input/Password";
import {
  sendOtpAction,
  verifyOtpAction,
  resetPasswordAction,
} from "../Redux/HospitalAction/forgetPasswordAction";
import { validPassword } from "../utils/regex/regex";
import Container from "../components/container/Container";

toast.configure();
export default function LoginTopbar() {
  const [option, setOption] = useState(false);

  const handleclick = () => {
    setOption(!option);
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selesperson, setSalesPerson] = useState(false);
  const [forgotpassword, setForgotPassword] = useState(false);
  const [otpverification, setOtpVerification] = useState(false);
  const [password, setNewPassword] = useState(false);
  const [hospital, setHospital] = useState(false);
  const [hospitalforgotpassword, setHospitalForgotPassword] = useState(false);
  const [hospitalotpverification, setHospitalOtpVerification] = useState(false);
  const [hospitalpassword, setHospitalNewPassword] = useState(false);
  const [hospitalCounter, setHospitalCounter] = useState(60);
  const [visible, setVisible] = useState(false);
  const [NextPage, setNextPage] = useState(false);
  const [NextPage4, setNextPage4] = useState(false);
  const [value, setValue] = useState({
    email: "",
    password: "",
  });
  const [hospitalValue, setHospitalValue] = useState({
    email: "",
    password: "",
  });
  const [hospitalPassword, setHospitalPassword] = useState({
    email: "",
    otp: "",
    new_password: "",
    setNewPassword: "",
  });
  const [hospitalError, setHospitalError] = useState({
    email: "",
    otp: "",
    new_password: "",
    setNewPassword: "",
  });
  const [error, setError] = useState("");
  const [checked, setChecked] = useState(false);
  const [data, setData] = useState({
    email: "",
  });
  const [counter, setCounter] = useState(60);
  const [OTP, setOTP] = useState();
  const [pass, setPass] = useState({
    email: "",
    new_password: "",
    confirmPassword: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  const handleSubmit = async () => {
    try {
      if (
        value.email === "" ||
        value.password === "" ||
        !validPassword(value.password) ||
        !validEmail(value.email)
      ) {
        setError({
          ...error,
          email:
            value.email === ""
              ? "Please Enter Email"
              : !validEmail(value.email)
              ? "Please Enter Valid Email"
              : error.email,
          password:
            value.password === ""
              ? "Please Enter Password"
              : !validEmail(value.password)
              ? "Password must has at least 8 characters that include at least 1 lowercase character , 1 uppercase characters , 1 number , and 1 special character in (!@#$%^&*)"
              : error.password,
        });
        return false;
      }
      let response = await LoginAction({ payload: value });
      if (response.success === true) {
        localStorage.setItem("token", response?.data?.token);
        localStorage.setItem("name", response?.data?.name);
        toast.success(response?.data?.message || "login successfully.");
        navigate("/sales-person/dashboard");
      } else if (response.success === false) {
        toast.error(response?.message || "Something Wrong");
      }
    } catch (err) {
      let message =
        err && err.message ? err.message : "Something went wrong during login";
      toast.error(err?.response?.message);
      return false;
    }
  };
  const sendOtp = async (e) => {
    try {
      e?.preventDefault();
      setIsLoading(true);
      if (data.email === "" || !validEmail(data.email)) {
        setError({
          ...error,
          email:
            data.email === ""
              ? "Please Enter Email"
              : !validEmail(data.email)
              ? "Please Enter Valid Email"
              : error?.email,
        });
        setIsLoading(false);
        return false;
      } else {
        let response = await forgetPasswordEmail({ payload: data });
        if (response.success === false) {
          toast.error(response.message || "Something went wrong during login.");
          setIsLoading(false);
        }
        if (response.success === true) {
          setIsLoading(false);
          localStorage.setItem("email", data.email);
          setOtpVerification(true);
          setForgotPassword(false);
        }
      }
    } catch (err) {
      let message =
        err && err.message ? err.message : "Something went wrong during login";
      toast.error(err?.response?.data?.message);
      setIsLoading(false);
      return false;
    }
  };
  useEffect(() => {
    if (counter > 0 && otpverification === true) {
      setTimeout(() => setCounter(counter - 1), 1000);
    }
  }, [counter, otpverification]);
  const resendOtp = () => {
    setCounter(60);
    sendOtp();
  };
  useEffect(() => {
    if (hospitalCounter > 0 && hospitalotpverification === true) {
      setTimeout(() => setHospitalCounter(hospitalCounter - 1), 1000);
    }
  }, [hospitalCounter, hospitalotpverification]);
  const verifyOtp = async () => {
    try {
      let email = localStorage.getItem("email");
      if (OTP === undefined) {
        setError({
          ...error,
          OTP: OTP === undefined ? "Please Enter OTP" : error.OTP,
        });
        return false;
      }
      let payload = {
        email: email,
        otp: OTP,
      };
      let response = await verifyOtps({ payload: payload });
      if (response.success === false) {
        toast.error(response.message || "Something went wrong during login.");
        setIsLoading(false);
      }
      if (response.success === true) {
        setNewPassword(true);
        setOtpVerification(false);
        setOTP(undefined);
      }
      setIsLoading(true);
    } catch (err) {
      toast.error(err?.response?.data?.message);
      setIsLoading(false);
      return false;
    }
  };
  const handleNewPassword = async () => {
    if (
      pass.new_password === "" ||
      pass.confirmPassword === "" ||
      pass.new_password !== pass.confirmPassword
    ) {
      setError({
        ...error,
        new_password:
          pass.new_password === ""
            ? "Please Enter Password"
            : error.new_password,
        confirmPassword:
          pass.confirmPassword === ""
            ? "Please Enter ConfirmPassword"
            : pass.new_password !== pass.confirmPassword
            ? "Password doesn't match"
            : error.confirmPassword,
      });
      return false;
    }
    const formData = new FormData();
    formData.append("email", localStorage.getItem("email"));
    formData.append("new_password", pass.new_password);
    let response = await NewPasswordChange({ payload: formData });
    if (response.success === false) {
      toast.error(response.message || "Something went wrong");
    }
    if (response.success === true) {
      toast.success(response.message || "Password Change Successfully");
      setSalesPerson(true);
    }
  };
  useEffect(() => {
    if (
      (selesperson === false || forgotpassword === false,
      otpverification === false || password === false)
    ) {
      setError("");
    }
  }, [selesperson, forgotpassword, otpverification, password]);
  const handleHospitalLoginSubmit = async () => {
    setIsLoading(true);
    if (
      hospitalValue.email === "" ||
      hospitalValue.password === "" ||
      !validEmail(hospitalValue.email)
    ) {
      setError({
        ...error,
        email:
          hospitalValue.email === ""
            ? "Please Enter Email"
            : !validEmail(value.email)
            ? "Please Enter Valid Email"
            : error.email,
        password:
          hospitalValue.password === ""
            ? "Please Enter Password"
            : error.password,
      });
      return false;
    }
    dispatch(
      loginAction(hospitalValue, (state) => {
        if (state === true) {
          navigate("/account-owner/dashboard");
        } else {
          navigate("/");
        }
      })
    );
  };
  const handleSendOtp = () => {
    if (hospitalPassword.email === "" || !validEmail(hospitalPassword.email)) {
      setHospitalError({
        ...hospitalError,
        email:
          hospitalPassword.email === ""
            ? "Please Enter Email"
            : !validEmail(hospitalPassword.email)
            ? "Please Enter Valid Email"
            : hospitalError.email,
      });
      return false;
    }
    dispatch(
      sendOtpAction(hospitalPassword, (state) => {
        if (state === true) {
          setHospitalOtpVerification(true);
          setHospital(false);
        }
      })
    );
  };
  const handleReset = () => {
    setHospitalCounter(60);
    handleSendOtp();
  };
  const handleVerifyOtp = async () => {
    if (hospitalPassword.otp === "") {
      setHospitalError({
        ...hospitalError,
        otp:
          hospitalPassword.otp === "" ? "Please Enter otp" : hospitalError.otp,
      });
      return false;
    }
    dispatch(
      verifyOtpAction(hospitalPassword, (state) => {
        if (state === true) {
          setHospitalNewPassword(true);
          setOtpVerification(false);
        }
      })
    );
  };
  const handleSetPassword = async () => {
    setIsLoading(true);
    if (
      hospitalPassword.new_password === "" ||
      hospitalPassword.setNewPassword === "" ||
      hospitalPassword.new_password !== hospitalPassword?.setNewPassword
    ) {
      setHospitalError({
        ...hospitalError,
        new_password:
          hospitalPassword.new_password === ""
            ? "Please Enter Password"
            : hospitalError.new_password,
        setNewPassword:
          hospitalPassword.setNewPassword === ""
            ? "Please Enter Confirm Password"
            : hospitalPassword.new_password !== hospitalPassword?.setNewPassword
            ? "Password doesn't match "
            : hospitalError.confirmPassword,
      });
      setIsLoading(false);
      return false;
    }
    dispatch(
      resetPasswordAction(hospitalPassword, (state) => {
        if (state === true) {
          setHospital(true);
          setHospitalNewPassword(false);
        }
      })
    );
  };
  useEffect(() => {
    if (hospital === false) {
      setHospitalValue({
        email: "",
        password: "",
      });
    } else if (hospitalforgotpassword === false) {
      setHospitalPassword({
        email: "",
        otp: "",
        new_password: "",
        setNewPassword: "",
      });
    }
  }, [hospital, hospitalforgotpassword]);
  return (
    <>
      {/* header section  */}
      <div className="login_topbar_container py-2 shadow-2">
        <Container>
          <div className="w-full flex align-items-center justify-content-between">
            <div className="logo">
              <img src={Logo} width="92px" height="72px" alt="ResMedX" />
            </div>
            <Button
              icon="pi pi-align-left"
              className="text-black get-started-btn block lg:hidden"
              onClick={toggleMenu}
            />
            <nav
              className={`nav-menu flex justify-content-start md:justify-content-around lg:align-items-center ${
                isOpen ? "open" : ""
              }`}
            >
              <ul className="flex flex-column md:flex-row h-full align-items-center gap-7">
                <li>
                  <NavLink to="/" className=" no-underline  text-nav link">
                    Home
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/about" className=" no-underline text-nav  link">
                    About Us
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/Pricing"
                    className=" text-nav  link  no-underline"
                  >
                    Pricing
                  </NavLink>
                </li>
                
                <li>
                  <NavLink
                    to="/contact-us"
                    className="no-underline text-nav link"
                    onClick={handleclick}
                  >
                    Contact Us
                  </NavLink>
                </li>
                <li className="relative">
                  <div className=" align-items-center" onClick={handleclick}>
                    <NavLink
                      onClick={() => setHospital(true)}
                      className={
                        selesperson === true ||
                        visible === true ||
                        hospital === true
                          ? "no-underline text-blue-500 text-nav link font-normal"
                          : "no-underline text-nav link text-color font-normal"
                      }
                    >
                      <div className="flex align-items-center text-nav">
                        Login
                        {/* <span>
                          <i
                            className="pi pi-angle-down ml-2 cursor-pointer text-nav mt-1 "
                            style={{ fontSize: "1.3rem" }}
                          />
                        </span> */}
                      </div>
                    </NavLink>
                  </div>
                  {/* <div
                    className=" p-2  bg-white  absolute   right-0 shadow-2 "
                    style={{
                      display: option ? "block" : "none",
                      top: "35px",
                      width: "140px",
                    }}
                  >
                    <div className=" text-center ">
                      <p
                        className="text-sm font-normal cursor-pointer w-full"
                        onClick={() => setSalesPerson(true)}
                        style={{ color: "#343434" }}
                      >
                        Sales Person
                      </p>
                      <Divider />
                      <p
                        className="text-sm font-normal cursor-pointer w-full "
                        onClick={() => setHospital(true)}
                        style={{ color: "#343434" }}
                      >
                        Hospital
                      </p>
                    </div>
                  </div> */}
                </li>
                <li>
                  <NavLink
                    to=""
                    className="get-started-btn border-round-md  no-underline text-nav active link"
                  >
                    <Button
                      onClick={() => setNextPage(true)}
                      label=" Get Started"
                      className="bg-main send-button border-round-md block w-max px-5"
                    ></Button>
                  </NavLink>
                </li>
              </ul>
            </nav>
          </div>
        </Container>
      </div>

      {/* Sales person login dailog */}
      <Dialog
        className="modal_container custom-modal mt-5  "
        maskClassName="testing"
        visible={selesperson}
        closable={false}
        style={{ width: "60vw" }}
        onHide={() => setSalesPerson(false)}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
      >
        <div className="sales_person_login_content">
          <div className="p-fluid grid flex p-0 m-0 ">
            <div className="col-12 md:col-6 align-items-center flex p-0 border-round-lg ">
              <div className=" p-5">
                <div className="p-fluid grid flex justify-content-center align-items-center ">
                  <div className="col-12 md:col-11 text-center">
                    <h1 className="" style={{ fontSize: "36px" }}>
                      <cod className="text-main">Welcome</cod> Back
                    </h1>
                    <p className="text-lg text-500">
                      Sign In to your account to continue...
                    </p>
                  </div>
                  <div className="col-12 md:col-11 mb-3">
                    <label className="font block mb-2">Email</label>
                    <InputText
                      className="border-round-md"
                      value={value.email}
                      onChange={(e) => {
                        setError({
                          ...error,
                          email: null,
                        });
                        setValue({
                          ...value,
                          email: e.target.value,
                        });
                      }}
                    ></InputText>
                    {error.email && (
                      <span className="error">{error.email}</span>
                    )}
                  </div>

                  <div className="col-12 md:col-11 mb-3">
                    <label className="font block mb-2">Password</label>
                    <Password
                      value={value.password}
                      onChange={(e) => {
                        setError({
                          ...error,
                          password: null,
                        });
                        setValue({
                          ...value,
                          password: e.target.value,
                        });
                      }}
                      toggleMask
                      inputClassName="border-round-md"
                    />
                    {error.password && (
                      <span className="error">{error.password}</span>
                    )}
                  </div>

                  <div className="col-12 md:col-11 mb-3">
                    <p className="flex justify-content-between align-items-center">
                      <div>
                        <Checkbox
                          onChange={(e) => setChecked(e.checked)}
                          checked={checked}
                        ></Checkbox>
                        &nbsp;Remember me
                      </div>
                      <p
                        className="text-main m-0 p-0"
                        onClick={() => {
                          setForgotPassword(true);
                          setSalesPerson(false);
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        Forgot Password?
                      </p>
                    </p>
                  </div>

                  <div className="col-12 md:col-11">
                    <Link to="#" className="link no-underline">
                      <Button
                        label="Sign in"
                        className="p p-3  border-round-lg"
                        style={{ background: "#006ED0" }}
                        onClick={handleSubmit}
                      />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 md:col-6 p-0 sales_person_modal_image overflow-hidden relative">
              <img
                src={SalesPersonLoginImg}
                height={692}
                alt=""
                className="w-full"
              />
              <div
                className="absolute cursor-pointer"
                style={{ top: "12px", right: "0" }}
                onClick={() => setSalesPerson(false)}
              >
                <i className="pi pi-times border-circle p-1 border-1 "></i>
              </div>
            </div>
          </div>
        </div>
      </Dialog>

      {/* sales person forgot password pop-up  */}
      <Dialog
        className="mt-5 modal_container "
        visible={forgotpassword}
        closeIcon={false}
        style={{ width: "60vw" }}
        onHide={() => setForgotPassword(false)}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
      >
        <div className="forgot_password_content">
          <div className="p-fluid grid flex p-0 m-0">
            <div className="col-12 md:col-6 flex align-items-center p-0 ">
              <div className=" p-5">
                <div className="p-fluid grid flex justify-content-center align-items-center ">
                  <div className="col-12 md:col-11 text-center">
                    <h1 className="" style={{ fontSize: "36px" }}>
                      <cod className="text-main">Forgot</cod> Password
                    </h1>
                    <p className="text-500 text-lg">
                      Forgot your password? Enter your email address and we’ll
                      send you a link to reset it.
                    </p>
                  </div>

                  <div className="col-12 md:col-11 mb-3 ">
                    <label className="font block mb-2">Email</label>
                    <InputText
                      className="w-full mt-1 border-round-md"
                      placeholder=""
                      name="email"
                      id=""
                      onChange={(e) => {
                        setError({
                          ...error,
                          email: null,
                        });
                        setData({ ...data, email: e.target.value });
                      }}
                    />
                    {error.email && (
                      <span className="error">{error.email}</span>
                    )}
                  </div>

                  <div className="col-12 md:col-11 ">
                    <Link to="#" className="link no-underline">
                      <Button
                        label="Send OTP"
                        className="p p-3  border-round-lg"
                        style={{ background: "#006ED0" }}
                        onClick={() => {
                          sendOtp();
                        }}
                      />
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 md:col-6 p-0 sales_person_modal_image overflow-hidden relative ">
              <img
                src={SalesPersonLoginImg}
                height={692}
                alt=""
                className="w-full"
              />
              <div
                className="absolute cursor-pointer"
                style={{ top: "12px", right: "0" }}
                onClick={() => setForgotPassword(false)}
              >
                <i className="pi pi-times border-circle p-1 border-1 "></i>
              </div>
            </div>
          </div>
        </div>
      </Dialog>

      {/* sales person otp verification modal pop-up modal  */}
      <Dialog
        className="mt-5 modal_container"
        visible={otpverification}
        closeIcon={false}
        style={{ width: "60vw" }}
        onHide={() => setOtpVerification(false)}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
      >
        <div className="otp_verification_content">
          <div className="p-fluid grid flex p-0 m-0">
            <div className="col-12 md:col-6 flex align-items-center p-0 ">
              <div className=" p-5 ">
                <div className="grid flex justify-content-center">
                  <div className="col-12 md:col-11 text-center mb-3">
                    <h1 className="" style={{ fontSize: "36px" }}>
                      <cod className="text-main">OTP </cod> Verification
                    </h1>
                    <p className="text-500 text-lg">
                      Code has been send to registered email:{" "}
                      {localStorage.getItem("email")}
                    </p>
                  </div>
                  <OTPInput
                    className="otpinput flex
                                                     flex-wrap
                                            justify-content-center;
                                             align-items-center;
                                              }"
                    value={OTP}
                    onChange={setOTP}
                    autoFocus
                    OTPLength={4}
                    otpType="number"
                    disabled={false}
                  />
                  <div className="md:col-12 md:col-11 mb-3 text-center mt-3 flex border-0 justify-content-center">
                    <p className="text-main text-center fw-semibold">
                      {counter === 0 ? (
                        <span
                          onClick={resendOtp}
                          className="text-color-main font-bold"
                        >
                          Resend Code{" "}
                        </span>
                      ) : (
                        <>
                          <span className="text-color-main font-bold">
                            Resend Code{" "}
                          </span>
                          <span className="text-color font-bold">in</span>{" "}
                          {counter}
                          <span className="text-color font-bold ml-1">s</span>
                        </>
                      )}
                    </p>
                  </div>
                  {error.OTP && <span className="error">{error.OTP}</span>}
                  <div className="col-12 md:col-11 ">
                    <Link to="" className="link no-underline">
                      <Button
                        label="Continue"
                        className="p p-3  border-round-lg"
                        style={{ background: "#006ED0" }}
                        onClick={() => verifyOtp()}
                      />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 md:col-6 p-0 sales_person_modal_image overflow-hidden relative">
              <img
                src={SalesPersonLoginImg}
                height={692}
                alt=""
                className="w-full"
              />
              <div
                className="absolute cursor-pointer"
                style={{ top: "12px", right: "0" }}
                onClick={() => setOtpVerification(false)}
              >
                <i className="pi pi-times border-circle p-1 border-1 "></i>
              </div>
            </div>
          </div>
        </div>
      </Dialog>

      {/* sales person set new password pop-up modal */}
      <Dialog
        Dialog
        className="mt-5 modal_container "
        visible={password}
        closeIcon={false}
        style={{ width: "60vw" }}
        onHide={() => setNewPassword(false)}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
      >
        <div className="password_modal_content ">
          <div className="p-fluid grid flex p-0 m-0">
            <div className="col-12 md:col-6 align-items-center flex p-0 ">
              <div className="p-5">
                <div className="p-fluid grid flex justify-content-center">
                  <div className="col-12 md:col-11 text-center">
                    <h1 className="" style={{ fontSize: "36px" }}>
                      <cod className="text-main">Reset</cod> Password
                    </h1>
                  </div>
                  <div className="col-12 md:col-11 mb-3">
                    <label className="font block mb-2">New Password</label>
                    <Password
                      className="w-full "
                      inputClassName="w-full border-round-md"
                      toggleMask
                      name="Password"
                      onChange={(e) => {
                        setError({
                          ...error,
                          new_password: null,
                        });
                        setPass({ ...pass, new_password: e.target.value });
                      }}
                    />
                    {error.new_password && (
                      <span className="error">{error.new_password}</span>
                    )}
                  </div>

                  <div className="col-12 md:col-11 mb-3">
                    <label className="font block mb-2"> Confirm Password</label>
                    <Password
                      className="w-full mt-1"
                      inputClassName="w-full border-round-md"
                      toggleMask
                      name="confirmPassword"
                      onChange={(e) => {
                        setError({
                          ...error,
                          confirmPassword: null,
                        });
                        setPass({ ...pass, confirmPassword: e.target.value });
                      }}
                    />
                    {error.confirmPassword && (
                      <span className="error">{error.confirmPassword}</span>
                    )}
                  </div>

                  <div className="col-12 md:col-11">
                    <Link to="#" className="link no-underline">
                      <Button
                        label="Reset Password"
                        className="p p-3  border-round-lg"
                        style={{ background: "#006ED0" }}
                        onClick={handleNewPassword}
                      />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 md:col-6 sales_person_modal_image p-0 overflow-hidden relative">
              <img
                src={SalesPersonLoginImg}
                height={692}
                alt=""
                className="w-full"
              />
              <div
                className="absolute cursor-pointer"
                style={{ top: "12px", right: "0" }}
                onClick={() => setNewPassword(false)}
              >
                <i className="pi pi-times border-circle p-1 border-1 "></i>
              </div>
            </div>
          </div>
        </div>
      </Dialog>

      {/* hospital login modal  */}
      <Dialog
        className="mt-5 modal_container "
        visible={hospital}
        style={{ width: "60vw" }}
        onHide={() => setHospital(false)}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
      >
        <div className="modal_hospital_login_content">
          <div className="p-fluid grid m-0 p-0">
            <div className="col-12 md:col-6 p-0 align-items-center flex">
              <div className=" p-5">
                <div className="p-fluid grid flex justify-content-center ">
                  <div className="col-12 md:col-11 text-center">
                    <h1 className="" style={{ fontSize: "36px" }}>
                      <cod className="text-main">Welcome</cod> Back
                    </h1>
                    <p className="text-lg text-500">
                      Sign In to your account to continue...
                    </p>
                  </div>
                  <div className="col-12 md:col-11 mb-3 ">
                    <InputField
                      value={hospitalValue?.email}
                      onChange={(e) => {
                        setError({
                          ...error,
                          email: null,
                        });
                        setHospitalValue({
                          ...hospitalValue,
                          email: e.target.value,
                        });
                      }}
                      label="Email"
                      errorMessage={error.email}
                      required
                    />
                  </div>

                  <div className="col-12 md:col-11 mb-3">
                    <PasswordField
                      label="Password"
                      required
                      inputClassName="border-round-md"
                      toggleMask
                      value={hospitalValue?.password}
                      onChange={(e) => {
                        setError({
                          ...error,
                          password: null,
                        });
                        setHospitalValue({
                          ...hospitalValue,
                          password: e.target.value,
                        });
                      }}
                      errorMessage={error.password}
                    />
                  </div>

                  <div className="col-12 md:col-11 mb-3">
                    <p className="flex justify-content-between align-items-center">
                      <div>
                        <Checkbox
                          onChange={(e) => setChecked(e.checked)}
                          checked={checked}
                        ></Checkbox>
                        &nbsp;Remember me
                      </div>
                      <p
                        className="text-main m-0 p-0 "
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setHospitalForgotPassword(true);
                          setHospital(false);
                        }}
                      >
                        Forgot Password?
                      </p>
                    </p>
                  </div>

                  <div className="col-12 md:col-11 mb-3 ">
                    {/* <Link to="" className="link no-underline"> */}
                    <Button
                      label="Sign in"
                      className="p p-3  border-round-lg"
                      style={{ background: "#006ED0" }}
                      onClick={() => handleHospitalLoginSubmit()}
                    />
                    {/* </Link> */}
                  </div>

                  <div className="col-12 md:col-12 text-center">
                    Don’t have an account?
                    <cod
                      className="text-main font-semibold cursor-pointer "
                      onClick={() => {
                        setNextPage(true);
                        setHospital(false);
                      }}
                    >
                      {" "}
                      Create Account
                    </cod>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 md:col-6 p-0 relative">
              {" "}
              <img
                src={LoginModalImg}
                height={692}
                alt=""
                className="w-full "
              />
              <div
                className="absolute cursor-pointer"
                style={{ top: "12px", right: "0" }}
                onClick={() => setHospital(false)}
              >
                <i className="pi pi-times border-circle p-1 border-1 "></i>
              </div>
            </div>
          </div>
        </div>
      </Dialog>

      {/* hospital forgot password pop-up start */}
      <Dialog
        className="mt-5 modal_container"
        visible={hospitalforgotpassword}
        closeIcon={false}
        style={{ width: "60vw" }}
        onHide={() => setHospitalForgotPassword(false)}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
      >
        <div className="forgot_modal_content">
          <div className="p-fluid grid p-0 m-0">
            <div className="col-12 md:col-6 flex align-items-center p-0 ">
              <div className="p-5">
                <div className="p-fluid grid flex justify-content-center">
                  <div className="col-12 md:col-11 text-center">
                    <h1 className="" style={{ fontSize: "36px" }}>
                      <cod className="text-main">Forgot</cod> Password
                    </h1>
                    <p className="text-500 text-lg">
                      Forgot your password? Enter your email address and we’ll
                      send you a link to reset it.
                    </p>
                  </div>
                  <div className="col-12 md:col-11 mb-3 ">
                    <InputField
                      label="Email"
                      required
                      className="w-full mt-1 border-round-md"
                      value={hospitalPassword.email}
                      onChange={(e) => {
                        setHospitalError({
                          ...hospitalError,
                          email: "",
                        });
                        setHospitalPassword({
                          ...hospitalPassword,
                          email: e.target?.value,
                        });
                      }}
                      errorMessage={hospitalError.email}
                    />
                  </div>
                  <div className="col-12 md:col-11 ">
                    {/* <Link to="#" className="link no-underline"> */}
                    <Button
                      label="Send OTP"
                      className="p p-3  border-round-lg"
                      style={{ background: "#006ED0" }}
                      onClick={() => handleSendOtp()}
                    />
                    {/* </Link> */}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 md:col-6 p-0 hospital_modal_image overflow-hidden relative">
              <img src={LoginModalImg} height={692} alt="" className="w-full" />
              <div
                className="absolute cursor-pointer"
                style={{ top: "12px", right: "0" }}
                onClick={() => setHospitalForgotPassword(false)}
              >
                <i className="pi pi-times border-circle p-1 border-1 "></i>
              </div>
            </div>
          </div>
        </div>
      </Dialog>

      {/* hospital otp verification modal pop-up modal start */}
      <Dialog
        className="mt-5 modal_container"
        visible={hospitalotpverification}
        closeIcon={false}
        // dismissableMask={true}
        style={{ width: "60vw" }}
        onHide={() => setHospitalOtpVerification(false)}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
      >
        <div className="otp_modal_content">
          <div className="p-fluid grid flex p-0 m-0">
            <div className="col-12 md:col-6 flex align-items-center ">
              <div className="p-5">
                <div className="p-fluid grid flex justify-content-center">
                  <div className="col-12 md:col-11 text-center">
                    <h1 className="" style={{ fontSize: "36px" }}>
                      <cod className="text-main">OTP </cod> Verification
                    </h1>
                    <p className="text-500 text-lg">
                      Code has been send to registered email:
                      {hospitalPassword?.email}
                    </p>
                  </div>
                  <div className="col-12 md:col-11 text-center flex justify-content-around mb-3">
                    <OTPInput
                      className="otpinput flex
                                                     flex-wrap
                                            justify-content-center;
                                             align-items-center;
                                              }"
                      value={hospitalPassword.otp}
                      onChange={(e) => {
                        setHospitalError({
                          ...hospitalError,
                          otp: null,
                        });
                        setHospitalPassword({
                          ...hospitalPassword,
                          otp: e,
                        });
                      }}
                      autoFocus
                      OTPLength={4}
                      otpType="number"
                      disabled={false}
                    />
                  </div>
                  <div className="md:col-12 md:col-11 mb-3 text-center mt-3 flex border-0 justify-content-center">
                    <p className="text-main text-center fw-semibold">
                      {hospitalCounter === 0 ? (
                        <span
                          className="text-color-main font-bold"
                          style={{ cursor: "pointer" }}
                          onClick={handleReset}
                        >
                          Resend Code{" "}
                        </span>
                      ) : (
                        <>
                          <span
                            className="text-color-main font-bold"
                            style={{ cursor: "pointer" }}
                          >
                            Resend Code{" "}
                          </span>
                          <span className="text-color font-bold">in</span>{" "}
                          {hospitalCounter}
                          <span className="text-color font-bold ml-1">s</span>
                        </>
                      )}
                    </p>
                  </div>
                  {hospitalError.otp && (
                    <span className="error">{hospitalError?.otp}</span>
                  )}
                  <div className="col-12 md:col-11 ">
                    {/* <Link to="" className="link no-underline"> */}
                    <Button
                      label="Continue"
                      className="p p-3  border-round-lg"
                      style={{ background: "#006ED0" }}
                      onClick={handleVerifyOtp}
                    />
                    {/* </Link> */}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 md:col-6 p-0 hospital_modal_image overflow-hidden relative">
              <img src={LoginModalImg} height={692} alt="" className="w-full" />
              <div
                className="absolute cursor-pointer"
                style={{ top: "12px", right: "0" }}
                onClick={() => setHospitalOtpVerification(false)}
              >
                <i className="pi pi-times border-circle p-1 border-1 "></i>
              </div>
            </div>
          </div>
        </div>
      </Dialog>

      {/*hospital set new password pop-up modal */}
      <Dialog
        className="mt-5 modal_container"
        visible={hospitalpassword}
        closeIcon={false}
        style={{ width: "60vw" }}
        // dismissableMask={true}
        onHide={() => setHospitalNewPassword(false)}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
      >
        <div className="password_modal_content">
          <div className="p-fluid grid flex m-0 p-0 ">
            <div className="col-12 md:col-6 align-items-center flex p-0">
              <div className="p-5">
                <div className="p-fluid grid flex justify-content-center">
                  <div className="col-12 md:col-11 text-center">
                    <h1 className="" style={{ fontSize: "36px" }}>
                      <cod className="text-main">Reset</cod> Password
                    </h1>
                  </div>
                  <div className="col-12 md:col-11 mb-3">
                    <PasswordField
                      label="New Password"
                      inputClassName="w-full border-round-md"
                      className="w-full mt-1"
                      toggleMask
                      value={hospitalPassword?.new_password}
                      required
                      onChange={(e) => {
                        setHospitalError({
                          ...hospitalError,
                          new_password: null,
                        });
                        setHospitalPassword({
                          ...hospitalPassword,
                          new_password: e.target.value,
                        });
                      }}
                      errorMessage={hospitalError.new_password}
                    />
                  </div>

                  <div className="col-12 md:col-11 mb-3">
                    <PasswordField
                      label="Confirm Password"
                      className="w-full mt-1"
                      inputClassName="w-full border-round-md"
                      toggleMask
                      value={hospitalPassword?.setNewPassword}
                      required
                      onChange={(e) => {
                        setHospitalError({
                          ...hospitalError,
                          setNewPassword: null,
                        });
                        setHospitalPassword({
                          ...hospitalPassword,
                          setNewPassword: e.target.value,
                        });
                      }}
                      errorMessage={hospitalError?.setNewPassword}
                    />
                  </div>

                  <div className="col-12 md:col-11">
                    <Link to="#" className="link no-underline">
                      <Button
                        label="Reset Password"
                        className="p p-3  border-round-lg"
                        style={{ background: "#006ED0" }}
                        onClick={() => handleSetPassword()}
                      />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 md:col-6 hospital_modal_image p-0 overflow-hidden relative">
              <img src={LoginModalImg} height={692} alt="" className="w-full" />
              <div
                className="absolute cursor-pointer"
                style={{ top: "12px", right: "0" }}
                onClick={() => setHospitalNewPassword(false)}
              >
                <i className="pi pi-times border-circle p-1 border-1 "></i>
              </div>
            </div>
          </div>
        </div>
      </Dialog>

      {/* hospital create account  steps 1 login details */}
      <Signup setNextPage={setNextPage} NextPage={NextPage} />

      {/* hospital create account  steps 6 card details  */}
      <Dialog
        className="modal_container_height  mt-5  border-round-lg overflow-hidden"
        visible={NextPage4}
        dismissableMask={true}
        onHide={() => setNextPage4(false)}
        style={{ width: "60vw" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
      ></Dialog>
    </>
  );
}
