import { InputText } from "primereact/inputtext";
import React from "react";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import LoginTopbar from "./LoginTopbar";
import ContactImg from "../assets/images/contact-img.png";
import Footer from "../shared/Footer";
export default function ContactUs() {

  return (
    <>
      <LoginTopbar />

      <div className="Contact_Us_Container">
        <div className=" grid m-0 p-0">
          <div className="col-12 md:col-6 m-0 p-0 ">
            <img src={ContactImg} alt="" className="w-full" ></img>
          </div>

          <div className="col-12 md:col-6  ">
            <div className="grid p-3 md:p-5 md:mx-5">
              <div className="col-12 md:col-12">
                <h1>
                  Contact Us
                </h1>
                <p>Our friendly team would love to hear from you!</p>
              </div>
              <div className="col-12">
                <label className="font block font-bold mb-2">Full Name</label>
                <InputText className="border-round-md w-full" placeholder="Enter Your Name"/>
              </div>

              <div className="col-12 md:col-12">
                <label className="font block font-bold mb-2">Email</label>
                <InputText className="border-round-md w-full" placeholder="Enter Email" />
              </div>

              <div className="col-12 md:col-12">
                <label className="font block font-bold mb-2">Phone No.</label>
                <InputText className="border-round-md w-full" placeholder="Enter Phone No." />
              </div>

              <div className="col-12 md:col-12">
                <label className="font block font-bold mb-2">Message</label>
                <InputTextarea
                  autoResize
                  rows={5}
                  cols={30}
                  className="w-full"
                  placeholder="Enter Message"
                />
              </div>

              <div className="col-12 md:col-12 mt-2">
                <Button
                  label="Submit"
                  className="p py-3  border-round-xl w-full"
                  style={{ background: "#006ED0" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
