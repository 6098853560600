import React from "react";

export default function MobileMenu() {
    return (
        <div className="mobileMenu block md:hidden">
            <h1>Mobile Menu</h1>
            {/* <img className="logo" src={Logo} alt="ResMedX" /> */}
        </div>
    );
};
