import React, { useState, useEffect } from "react";
import { Dropdown } from "primereact/dropdown";
import { Card } from "primereact/card";
import ContentArea from "../../shared/ContentArea";
import InputField from "../../../../components/input/input";
import { Calendar } from "primereact/calendar";
import SaveButton from "../../../../components/Buttons/SaveButton";
import CancelButton from "../../../../components/Buttons/CancelButton";
import DropdownField from "../../../../components/input/dropdown";
import { getEmployeesAction } from "../../../../Redux/HospitalAction/employessAction";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment/moment";
import {
  getEmployeeCertificationList,
  AddEmployeeCertification,
} from "../../../../Redux/HospitalAction/certificationAction";
import Loader from "../../../../pages/Loader";
const AddIndividualCertification = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [employee, setEmployee] = useState(null);
  const [certification, setCertification] = useState(null);
  //comment
  const [data, setData] = useState({
    employee_id: "",
    employee_name: "",
    certificate_id: "",
    last_date_certificate_completion: "",
    certificate_next_date: "",
    time: "",
    reminder: [
      {
        reminder_type: "",
      },
      {
        reminder_type: "",
      },
      {
        reminder_type: "",
      },
    ],
  });
  const [error, setError] = useState("");
  useEffect(() => {
    dispatch(getEmployeesAction());
    dispatch(getEmployeeCertificationList());
  }, []);
  const getEmployeeData = useSelector(
    (state) => state.getEmployee.getEmployeeProfile
  );
  const getEmployeeCertificationData = useSelector(
    (state) => state.getEmployeeCertication.getEmployeeCertificationProfile
  );
  useEffect(() => {
    setError({
      ...error,
      employee_id: null,
    });
    setData({
      ...data,
      employee_id: employee?.id,
      employee_name: employee?.name,
    });
  }, [employee]);
  useEffect(() => {
    setError({
      ...error,
      certificate_id: null,
    });
    setData({
      ...data,
      certificate_id: certification?.id,
    });
  }, [certification]);
  const time = [
    { label: "10 min", value: "10min" },
    { label: "15 min", value: "15min" },
    { label: "30 min", value: "30min" },
    { label: "01 hour", value: "01hour" },
    { label: "02 hour", value: "02hour" },
    { label: "12 hour", value: "12hour" },
    { label: "01 Day", value: "01Day" },
    { label: "02 Days", value: "02Days" },
    { label: "07 Days", value: "07Days" },
    { label: "15 Days", value: "15Days" },
    { label: "30 Days", value: "30Days" },
  ];
  const handleReminder = (e, index) => {
    let array = [...data.reminder];
    let obj = { ...data.reminder[index], reminder_type: e.value };
    array?.splice(index, 1, obj);
    setData({
      ...data,
      reminder: array,
    });
    setError({
      ...error,
      reminder: null,
    });
  };
  const handleSubmit = () => {
    let check = false;
    data.reminder?.map((item) => {
      if (item.reminder_type?.length !== 0) {
        check = true;
        return;
      }
    });
    if (
      data.employee_id === "" ||
      data.certificate_id === undefined ||
      data.last_date_certificate_completion === "" ||
      data.certificate_next_date === "" ||
      check === false ||
      data.time === ""
    ) {
      setError({
        ...error,
        employee_id:
          data.employee_id === ""
            ? "Please Select Employee"
            : error.employee_id,
        certificate_id:
          data.certificate_id === undefined
            ? "Please Select Certificate"
            : error.certificate_id,
        last_date_certificate_completion:
          data.last_date_certificate_completion === ""
            ? "Please Select Last Date"
            : error.last_date_certificate_completion,
        certificate_next_date:
          data.certificate_next_date === ""
            ? "Please Choose Next Date"
            : error.certificate_next_date,
        reminder:
          check === false ? "Atleast one Reminder enter" : error.reminder,
        time: data.time === "" ? "Please Select Time" : error.time,
      });
      return false;
    }
    const obj = data;
    let array = Object.entries(obj);
    const formData = new FormData();
    for (let i = 0; i < array.length; i++) {
      if (array[i][0] === "last_date_certificate_completion") {
        formData.append(
          array[i][0],
          moment(data.last_date_certificate_completion).format("DD-MM-YYYY")
        );
      } else if (array[i][0] === "certificate_next_date") {
        formData.append(
          array[i][0],
          moment(data.certificate_next_date).format("DD-MM-YYYY")
        );
      } else if (array[i][0] === "reminder") {
        formData.append(array[i][0], JSON.stringify(data.reminder));
      } else if (array[i][0] === "time") {
        formData.append(
          array[i][0],
          data?.time?.getHours() + ":" + data?.time?.getMinutes()
        );
      } else {
        formData.append(array[i][0], array[i][1]);
      }
    }
    dispatch(
      AddEmployeeCertification(formData, () =>
        navigate("/account-owner/manage-individual-certification")
      )
    );
  };
  const isLoading = useSelector((state) => state.loader.isLoading);
  return (
    <>
      {isLoading === true ? (
        <Loader />
      ) : (
        <ContentArea>
          <div className="add_individual_certification_container">
            <div className="p-3">
              <div className="grid card ml-0 mr-0 px-5 mb-5 align-items-center bg-main-light border-round-lg">
                <h2 className="text-main">Add Employee Certification</h2>
              </div>
              <Card className="shadow-2 p-3 border-round-lg">
                <div className="p-fluid grid mb-3">
                  <div className="col-12 md:col-6 mb-3">
                    <DropdownField
                      label="Select Employee"
                      inputId="dropdown"
                      placeholder="Select"
                      value={employee}
                      options={getEmployeeData}
                      itemTemplate={(e) => {
                        return (
                          <>
                            {e.emp_id} {e.first_name} {e.last_name}
                          </>
                        );
                      }}
                      onChange={(e) => setEmployee(e.value)}
                      optionLabel="emp_id"
                      required
                      filter
                    />
                    {error.employee_id && (
                      <span className="error">{error.employee_id}</span>
                    )}
                  </div>
                  <div className="col-12 md:col-6 mb-3">
                    <InputField
                      label="Employee Name"
                      value={data?.employee_name}
                      disabled
                    />
                  </div>

                  <div className="col-12 md:col-6 mb-3">
                    <DropdownField
                      label="Select Certification"
                      required
                      inputId="dropdown"
                      placeholder="Select"
                      value={certification}
                      options={getEmployeeCertificationData}
                      onChange={(e) => setCertification(e.value)}
                      optionLabel="certification_name"
                    />
                    {error.certificate_id && (
                      <span className="error">{error.certificate_id}</span>
                    )}
                  </div>

                  <div className="col-12 md:col-6 mb-3 ">
                    <label className="font block mb-2">
                      Last date of Certification Completion
                      <code className="text-red">*</code>
                    </label>
                    <Calendar
                      className="border-round-md calender_picker mb-2"
                      inputClassName="calendar p-2 border-round-md "
                      placeholder="Select"
                      value={data.last_date_certificate_completion}
                      onChange={(e) => {
                        setError({
                          ...error,
                          last_date_certificate_completion: null,
                        });
                        setData({
                          ...data,
                          last_date_certificate_completion: e.target.value,
                        });
                      }}
                    />
                    {error.last_date_certificate_completion && (
                      <span className="error">
                        {error.last_date_certificate_completion}
                      </span>
                    )}
                  </div>
                  <div className="col-12 md:col-12">
                    <h2
                      className="text-main font-bold p-0 "
                      style={{ fontSize: "22px" }}
                    >
                      Next date of Certification Completion
                    </h2>
                  </div>
                  <div className="col-12 md:col-6 mb-3 ">
                    <label className="font block mb-2">
                      Select Date<code className="text-red">*</code>
                    </label>
                    <Calendar
                      className="border-round-md calender_picker mb-2"
                      inputClassName="calendar p-2 border-round-md "
                      placeholder="Select"
                      value={data.certificate_next_date}
                      onChange={(e) => {
                        setError({
                          ...error,
                          certificate_next_date: null,
                        });
                        setData({
                          ...data,
                          certificate_next_date: e.target.value,
                        });
                      }}
                      minDate={data?.last_date_certificate_completion}
                    />
                    {error.certificate_next_date && (
                      <span className="error">
                        {error.certificate_next_date}
                      </span>
                    )}
                  </div>
                  <div className="col-12 md:col-6 mb-3 ">
                    <label className="font block mb-2">
                      Select Time<code className="text-red">*</code>
                    </label>
                    <Calendar
                      className="border-round-md clock_picker mb-2"
                      inputClassName="calendar p-2 border-round-md "
                      placeholder="Select"
                      timeOnly
                      hourFormat="24"
                      value={data.time}
                      onChange={(e) => {
                        setError({
                          ...error,
                          time: null,
                        });
                        setData({
                          ...data,
                          time: e.target.value,
                        });
                      }}
                    />
                    {error.time && <span className="error">{error.time}</span>}
                  </div>

                  <div className="col-12 md:col-12">
                    <h2
                      className="text-main font-bold p-0 "
                      style={{ fontSize: "22px" }}
                    >
                      Set Appointment Reminders
                    </h2>
                  </div>
                  {data?.reminder?.map((item, index) => {
                    return (
                      <div className="col-12 md:col-4 mb-2">
                        <label className="font block mb-2">
                          Reminder {index + 1}
                          {index === 0 ? (
                            <code className="text-red">*</code>
                          ) : (
                            ""
                          )}
                        </label>
                        <Dropdown
                          inputId="dropdown"
                          placeholder="Select"
                          className="border-round-md"
                          options={time}
                          optionLabel="label"
                          value={item.reminder_type}
                          onChange={(e) => handleReminder(e, index)}
                        />
                      </div>
                    );
                  })}
                  {error?.reminder && (
                    <span className="error">{error.reminder}</span>
                  )}
                </div>
                <div className="submitButton flex">
                  <SaveButton label="Save" onClick={handleSubmit}></SaveButton>
                  <CancelButton
                    label="Cancel"
                    onClick={() =>
                      navigate("/account-owner/manage-individual-certification")
                    }
                  ></CancelButton>
                </div>
              </Card>
            </div>
          </div>
        </ContentArea>
      )}
    </>
  );
};

export default AddIndividualCertification;
