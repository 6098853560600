
import React, { useState, useEffect } from "react";
import { Card } from "primereact/card";
import ContentArea from "../../../../shared/ContentArea";
import { Checkbox } from 'primereact/checkbox';
import InputField from "../../../../components/input/input";
import UploadImg from "../../../../assets/images/Employeeicon.png"
import SaveButton from "../../../../components/Buttons/SaveButton";
import CancelButton from "../../../../components/Buttons/CancelButton";
import { Calendar } from 'primereact/calendar';
import { InputTextarea } from 'primereact/inputtextarea';
import DropdownField from "../../../../components/input/dropdown";
import InputNumberField from "../../../../components/input/inputNumber";
import InputTextareaField from "../../../../components/input/inputTextArea";
import { viewClientAction, getClientTierAction, editClientAction } from "../../../../Redux/HospitalAction/clientAction";
import { useParams, useNavigate } from "react-router-dom";
import { carrierAction } from "../../../../Redux/HospitalAction/carrierAction";
import { getServiceAction } from "../../../../Redux/HospitalAction/serviceProviderAction";
import Loader from "../../../../pages/Loader";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
const EditClient = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [date, setDate] = useState(null);
    const [checked, setChecked] = useState(false);
    const [gender, setGender] = useState(null);
    const [carrier, setcarrier] = useState(null);
    const [clientTire, setClientTire] = useState(null);
    const [clientClassification, setClientClassification] = useState(null);
    const [error, setError] = useState("")
    const [img, setImg] = useState({ image: null, updated: false })
    const [data, setData] = useState({
        first_name: "",
        middle_name: "",
        last_name: "",
        email: "",
        mobile: "",
        dob: "",
        house_no: "",
        address_1: "",
        state: "",
        zipcode: "",
        client_MHN_no: "",
        gender: "",
        image: "",
        carrier_id: "",
        client_tier_id: "",
        client_subscription_plan_id: "",
        is_sms_reminder: "",
        general_note: ""
    })
    const Gender = [
        { name: 'Male' },
        { name: 'Female', },

    ]
    const { id } = useParams()
    useEffect(() => {
        dispatch(viewClientAction(id))
        dispatch(carrierAction())
        dispatch(getClientTierAction())
        dispatch(getServiceAction())
    }, [])
    const getData = useSelector((state) => state.viewClient.viewClientProfile)
    useEffect(() => {
        let showEndDate = new Date(getData?.dob?.slice(0, 10))
        if (getData.is_sms_reminder === 1) {
            setChecked(true)
        } else {
            setChecked(false)
        }
        setDate(showEndDate)
        setData(getData)
    }, [getData])
    const clientTierData = useSelector((state) => state.getClientTier.getClientTierProfile)
    const carrierData = useSelector((state) => state.carrier.carrierProfile)
    const getallService = useSelector((state) => state.getService.getServiceProfile)
    useEffect(() => {
        carrierData?.length > 0 && carrierData.map((item) => {
            if (item.id == getData.carrier) {
                setcarrier(item)
            }
        })
    }, [carrierData, getData])
    useEffect(() => {
        clientTierData.length > 0 && clientTierData.map((item) => {
            if (item.id == getData.client_tier_id) {
                setClientTire(item)
            }
        })
    }, [clientTierData, getData])
    useEffect(() => {
        getallService?.length > 0 && getallService.map((item) => {
            if (item.id == getData.client_subscription_plan) {
                setClientClassification(item)
            }
        })
    }, [getallService, getData])
    useEffect(() => {
        Gender?.length > 0 && Gender.map((item) => {
            if (item.name == getData.gender) {
                setGender(item)
            }
        })
    }, [getData])
    const isLoading = useSelector((state) => state.loader.isLoading)
    const handleChange = (e) => {
        const { name, value } = e.target
        setError({
            ...error,
            [name]: null
        })
        setData({
            ...data,
            [name]: value
        })
    }
    const handleNumberChange = (e) => {
        setError({
            ...error,
            [e.target?.name]: ""
        })
        setData({
            ...data,
            [e.target?.name]: e?.value
        })
    }
    useEffect(() => {
        setData({
            ...data,
            client_tier_id: clientTire?.id
        })
        setError({
            ...error,
            client_tier_id: null
        })
    }, [clientTire])
    useEffect(() => {
        setData({
            ...data,
            client_subscription_plan_id: clientClassification?.id
        })
        setError({
            ...error,
            client_subscription_plan_id: null
        })
    }, [clientClassification])
    useEffect(() => {
        setData({
            ...data,
            carrier_id: carrier?.id
        })
        setError({
            ...error,
            carrier_id: null
        })
    }, [carrier])
    useEffect(() => {
        setData({
            ...data,
            gender: gender?.name
        })
        setError({
            ...error,
            gender: null
        })
    }, [gender])
    const handleSubmit = () => {
        if (data.first_name === "" || data.last_name === "" || data.house_no === "" || data.address_1 === "" || data.state === "" || data.zipcode === null ||
            data.mobile === null || data.email === "" || data.client_MHN_no === null) {
            setError({
                ...error,
                first_name: data.first_name === "" ? "Please Enter First Name" : error.first_name,
                last_name: data.last_name === "" ? "Please Enter Last Name" : error.last_name,
                house_no: data.house_no === "" ? "Please Enter Room/ Apt./ Seat Address" : error.house_no,
                address_1: data.address_1 === "" ? "Please Enter Address" : error.address_1,
                state: data.state === "" ? "Please Enter State" : error.state,
                zipcode: data.zipcode === null ? "Please Enter Zip Code" : error.zipcode,
                mobile: data.mobile === null ? "Add Mobile Number" : error.mobile,
                email: data.email === "" ? "Please Enter Email" : error.email,
                client_MHN_no: data.client_MHN_no === null ? "Please Enter MHN Number" : error.client_MHN_no,
            })
            return false;
        }
        const obj = data;
        let array = Object.entries(obj)
        const formData = new FormData()
        for (let i = 0; i < array.length; i++) {
            if (array[i][0] === "image" && img.updated) {
                formData.append(array[i][0], img?.image)
            } if (array[i][0] === "dob") {
                formData.append(array[i][0], moment(date).format('DD-MM-YYYY'))
            }
            else {
                formData.append(array[i][0], array[i][1])
            }

        }

        dispatch(editClientAction(id, formData, () => navigate("/account-owner/manage-client-list")))
    }
    useEffect(() => {
        if (checked === true) {
            setData({
                ...data,
                is_sms_reminder: 1
            })
        } else {
            setData({
                ...data,
                is_sms_reminder: 0
            })
        }
    }, [checked])

    return (
        <>
            {isLoading === true ? <Loader /> :
                <ContentArea>
                    <div className="add_employees_container">
                        <div className="p-3">
                            <div className="grid card ml-0 mr-0 px-5 mb-5 align-items-center bg-main-light border-round-lg">
                                <h2 className="text-main">
                                    Edit Client Details
                                </h2>
                            </div>
                            <Card className="shadow-2 p-3 border-round-lg">
                                <div className="p-fluid grid">
                                    <div className="col-12 md:col-12 text-main ">
                                        <h2 className="font-bold" style={{ fontSize: "22px" }}>Client Photo</h2>
                                    </div>
                                </div>

                                <div className="p-fluid grid flex align-items-center mb-4">
                                    <div className="upload-image col-12 md:col-2">
                                        <p className="mb-2 text-sm">Upload Image</p>
                                        <img
                                            src={img?.image ? URL.createObjectURL(img?.image) : data?.image != "" ?data.image : UploadImg}
                                            alt=""
                                            className="w-full h-full upload-image border-1 surface-border"
                                            height={109}
                                        />
                                    </div>
                                    <div className="col-12 md:col-10">
                                        <div className="upload_image mb-3">
                                            <label
                                                htmlFor="profile-img"
                                                className="bg-primary cursor-pointer w-3 px-5 border-0 p-2 border-round-md"
                                            >
                                                <i className="pi pi-plus mr-3"></i>
                                                <span>Choose a File</span>
                                            </label>
                                            <input
                                                type="file"
                                                mode="basic"
                                                hidden
                                                className="file-upload text-white border-round-lg"
                                                id="profile-img"
                                                chooseLabel="Choose a file"
                                                name="profile-img"
                                                accept="image/*"
                                                maxFileSize={1000000}
                                                onChange={(e) => {
                                                    setError({
                                                        ...error,
                                                        image: null,
                                                    });
                                                    if (e.target.files.length) {
                                                        setImg({ image: e.target.files[0], updated: true });
                                                    }
                                                }}
                                            />
                                        </div>
                                        <p className="text-500 m-0 text-xs"> Acceptable Formats jpg, png only </p>
                                    </div>
                                </div>

                                <div className="p-fluid grid mb-3">
                                    <div className="col-12 md:col-6">
                                        <DropdownField label="Select Client Tier " required inputId="dropdown" placeholder="" value={clientTire}
                                            options={clientTierData} onChange={(e) => setClientTire(e.value)} optionLabel="tiers_name" />
                                    </div>

                                    <div className="col-12 md:col-6">
                                        <DropdownField label="Select Client Classification" required inputId="dropdown" placeholder="" value={clientClassification}
                                            options={getallService} onChange={(e) => setClientClassification(e.value)} optionLabel="program_name" />
                                    </div>
                                </div>

                                <div className="p-fluid grid ">
                                    <div className=" col-12 md:col-12 text-main ">
                                        <h2 className=" font-bold" style={{ fontSize: "22px" }}>Client Name</h2>
                                    </div>
                                    <div className=" col-12 md:col-4">
                                        <InputField placeholder="" label="First Name" required value={data.first_name} onChange={handleChange} name="first_name"></InputField>
                                                {error.first_name && <span className="error">{error.first_name}</span>}
                                    </div>

                                    <div className="col-12 md:col-4">
                                        <InputField placeholder="" label="Middle Name" value={data.middle_name} onChange={handleChange} name="middle_name" ></InputField>
                                    </div>

                                    <div className=" col-12 md:col-4">
                                        <InputField placeholder="" label="Last Name" required value={data.last_name} onChange={handleChange} name="last_name"></InputField>
                                                {error.last_name && <span className="error">{error.last_name}</span>}
                                    </div>
                                </div>


                                <div className="p-fluid grid">
                                    <div className=" col-12 md:col-12 text-main ">
                                        <h2 className="font-bold" style={{ fontSize: "22px" }}>Client Address</h2>
                                    </div>
                                    <div className="col-12 md:col-6">
                                        <InputField placeholder="" label="House/ Apt. no. " value={data.house_no} required name="house_no" onChange={handleChange}></InputField>
                                    {error.house_no && <span className="error">{error.house_no}</span>}
                                    </div>

                                    <div className="col-12 md:col-6">
                                        <InputField placeholder="" label="Street Address" required value={data.address_1} name="address_1" onChange={handleChange}></InputField>
                                                {error.address_1 && <span className="error">{error.address_1}</span>}
                                    </div>
                                    <div className="col-12 md:col-6">
                                        <InputField placeholder="" label="State" required value={data.state} name="state" onChange={handleChange} />
                                                {error.state && <span className="error">{error.state}</span>}
                                    </div>
                                    <div className="col-12 md:col-6">
                                        <InputNumberField label="Zip Code" name="zipcode" onValueChange={handleNumberChange}
                                            placeholder="" useGrouping={false} maxLength={5} required
                                            inputClassName="border-round-md" value={data.zipcode} />
                                        {error.zipcode && <span className="error">{error.zipcode}</span>}
                                    </div>
                                </div>


                                <div className="p-fluid grid">
                                    <div className=" col-12 md:col-12 text-main ">
                                        <h2 className=" font-bold" style={{ fontSize: "22px" }}>Client Phone No.</h2>
                                    </div>
                                    <div className="col-12 md:col-6 mb-3">
                                        <DropdownField label="Select Carrier" inputId="dropdown" required
                                            placeholder="" value={carrier} options={carrierData} onChange={(e) => setcarrier(e.value)} optionLabel="carrier_name" />
                                    </div>

                                    <div className="col-12 md:col-6 mb-3">
                                        <InputNumberField label="Phone Number" name="mobile" onValueChange={handleNumberChange}
                                            placeholder="" required useGrouping={false} maxLength={10}
                                            inputClassName="border-round-md" value={data.mobile} />
                                            {error.mobile && <span className="error">{error.mobile}</span>}
                                    </div>
                                    <div className="col-12 md:col-6 mb-3">
                                        <InputField placeholder="" label="Client Email" value={data.email} name="email" onChange={handleChange} required></InputField>
                                                {error.email && <span className="error">{error.email}</span>}
                                    </div>
                                    <div className="col-12 md:col-6">
                                        <InputNumberField label="Client MHN No."
                                            placeholder="" useGrouping={false} required name="client_MHN_no" onValueChange={handleNumberChange}
                                            inputClassName="border-round-md" value={data.client_MHN_no} />
                                            {error.client_MHN_no && <span className="error">{error.client_MHN_no}</span>}
                                    </div>
                                    <div className="col-12 md:col-6 mb-3">
                                        <label className="font block mb-2">Date of Birth  <cod className="text-red">*</cod></label>
                                        <Calendar placeholder="14-06-1981" value={date} onChange={(e) => setDate(e.value)} className="calender_picker" inputClassName="border-round-md" />
                                    </div>

                                    <div className="col-12 md:col-6 mb-3">
                                        <DropdownField label="Gender" inputId="dropdown" required
                                            placeholder="Male" value={gender} options={Gender} onChange={(e) => setGender(e.value)} optionLabel="name" />
                                    </div>
                                    <div className="col-12 md:col-12 mb-3">
                                        <InputTextareaField label="General Note" value={data.general_note} name="general_note" onChange={handleChange} rows={5} cols={5}
                                        />
                                    </div>
                                    <div className="col-12 md:col-12 mb-5">
                                        <Checkbox onChange={e => setChecked(e.checked)} checked={checked}></Checkbox> &nbsp;  Check to receive the SMS reminder notifications.
                                    </div>

                                </div>
                                <div className="submitButton flex">
                                    <SaveButton label="Save" onClick={handleSubmit}></SaveButton>
                                    <CancelButton label="Cancel" onClick={() => navigate("/account-owner/manage-client-list")}></CancelButton>
                                </div>
                            </Card>

                        </div>
                    </div>
                </ContentArea>
            }
        </>
    )
}

export default EditClient;
