
import React, { useState, useEffect } from "react";
import ContentArea from "../../shared/ContentArea";
import { Checkbox } from 'primereact/checkbox';
import InputField from "../../../../components/input/input";
import UploadImg from "../../../../assets/images/addimage.png"
import SaveButton from "../../../../components/Buttons/SaveButton";
import CancelButton from "../../../../components/Buttons/CancelButton";
import { Card } from "primereact/card";
import DropdownField from "../../../../components/input/dropdown";
import InputNumberField from "../../../../components/input/inputNumber";
import { getServiceProviderAction } from "../../../../Redux/HospitalAction/serviceProviderAction";
import { carrierAction } from "../../../../Redux/HospitalAction/carrierAction";
import { useDispatch, useSelector } from "react-redux";
import { InputSwitch } from 'primereact/inputswitch';
import { getPaCodesAction } from "../../../../Redux/HospitalAction/paCodeAction";
import { viewEmployeeAction, editEmployeeAction, getPermissionAction } from "../../../../Redux/HospitalAction/employessAction";
import { useParams, useNavigate } from "react-router-dom";
import Loader from "../../../../pages/Loader";
import Permission from "./Permission";
const EditEmployees = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { id } = useParams()
    const [checked, setChecked] = useState(false);
    const [permissions, setPermissions] = useState([])
    const [selectedNodes, setSelectedNodes] = useState({});
    const [service, setService] = useState(null);
    const [error, setError] = useState({})
    const [serviceProvider, setServiceProvider] = useState(null)
    const [carrier, setCarrier] = useState(null)
    const [carrierData, setCarrierData] = useState(null)
    const [paCode, setPaCode] = useState(null)
    const [paCodeType, setPaCodeType] = useState(null)
    const [showPaCode, setShowPaCode] = useState(null)
    const [img, setImg] = useState({ image: null, updated: false })
    const [data, setData] = useState({
        service_provider_id: "",
        first_name: "",
        middle_name: "",
        last_name: "",
        employee_id: "",
        email: "",
        mobile: "",
        address_1: "",
        state: "",
        zip_code: "",
        carrier_id: "",
        assign_pa_code_id: "",
        hourly_rate: "",
        permissions: [],
        image: "",
        is_sms_notify: 0,
        is_email_notify: 0,
        username: "",
        password: "",
        is_active: "",
        selected_nodes: {}
    })
    useEffect(() => {
        dispatch(getServiceProviderAction())
        dispatch(carrierAction())
        dispatch(getPaCodesAction())
        dispatch(viewEmployeeAction(id))
        dispatch(getPermissionAction())
    }, [])
    const serviceDatalist = useSelector((state) => state.getServiceProvider?.getServiceProviderProfile)
    const carrierDatalist = useSelector((state) => state.carrier.carrierProfile)
    const getPaCodeDatalist = useSelector((state) => state.getPaCode.getPaCodeProfile)
    const getViewData = useSelector((state) => state.viewEmployee.viewEmployeeProfile)
    const getPermissionDatalist = useSelector((state) => state.Permissions.getPermissionProfile)
    useEffect(() => {
        getViewData.middle_name = getViewData.middle_name === null ? "" : getViewData.middle_name
        setServiceProvider(serviceDatalist)
        setCarrier(carrierDatalist)
        setPaCode(getPaCodeDatalist)
        setData(getViewData)

    }, [serviceDatalist, carrierDatalist, getPaCodeDatalist, getViewData])
    useEffect(() => {
        let array = []
        let obj
        getPaCodeDatalist?.length > 0 && getPaCodeDatalist?.map((item) => {
            obj = {
                paCode: item.pa_code,
                id: item.id
            }
            array.push(obj)
        })
        setShowPaCode(array)
    }, [getPaCodeDatalist])
    const handleToggle = (e) => {
        setChecked((prev) => !prev)
        if (!checked) {
            setData({
                ...data,
                is_active: 1
            })
        } else {
            setData({
                ...data,
                is_active: 0
            })
        }
    };
    useEffect(() => {
        serviceDatalist?.length > 0 && serviceDatalist?.map((item) => {
            if (item.id == getViewData?.service_provider_id)
                setService(item)
        })
    }, [getViewData, serviceDatalist])
    useEffect(() => {
        carrierDatalist?.length > 0 && carrierDatalist?.map((item) => {
            if (item.id == getViewData.carrier) {
                setCarrierData(item)
            }
        })
    }, [carrierDatalist, getViewData])
    useEffect(() => {
        showPaCode?.length > 0 && showPaCode?.map((item) => {
            if (item.id == getViewData.pa_code_id) {
                setPaCodeType(item)
            }
        })
    }, [showPaCode, getViewData])
    const handleChange = (e) => {
        setError({
            ...error,
            [e.target.name]: ""
        })
        setData({
            ...data,
            [e.target.name]: e.target.value
        })
    }
    useEffect(() => {
        setError({
            ...error,
            service_provider_id: null,
            carrier_id: null,
            assign_pa_code_id: null
        })
        setData({
            ...data,
            service_provider_id: service?.id,
            carrier_id: carrierData?.id,
            assign_pa_code_id: paCodeType?.id
        })
    }, [service, carrierData, paCodeType])
    useEffect(() => {
        setData({
            ...data,
            image: img
        })
        setError({
            ...error,
            image: null
        })
    }, [img])
    useEffect(() => {
        setSelectedNodes(getViewData.selected_nodes)
    }, [getViewData])
    const handleNumberChange = (e) => {
        setError({
            ...error,
            [e.target?.name]: ""
        })
        setData({
            ...data,
            [e.target?.name]: e?.value
        })
    }
    const handleSubmit = () => {
        if (data.service_provider_id === undefined || data.image === "" || data.email === "" || data.first_name === "" || data.last_name === ""
            || data.address_1 === "" || data.state === "" || data.zip_code === null || data.mobile === null
            || data.hourly_rate === null || data.username === "" || data.permissions?.length === 0) {
            setError({
                ...error,
                service_provider_id: data.service_provider_id === undefined ? "Please Select Service Provider" : error.service_provider_id,
                image: data.image === "" ? "Please Choose Image" : error.image,
                email: data.email === "" ? "Please Enter Email" : error.email,
                first_name: data.first_name === "" ? "Please Enter First Name" : error.first_name,
                last_name: data.last_name === "" ? "Please Enter Last Name" : error.last_name,
                address_1: data.address_1 === "" ? "Please Enter Address" : error.address_1,
                state: data.state === "" ? "Please Enter State" : error.state,
                zip_code: data.zip_code === null ? "Please Enter Zip Code" : error.zip_code,
                mobile: data.mobile === null ? "Please Enter Mobile" : error.mobile,
                hourly_rate: data.hourly_rate === null ? "Please Enter Hour Rate" : error.hourly_rate,
                username: data.username === "" ? "Please Enter User Name" : error.username,
                permissions: data.permissions?.length === 0 ? "Please Enter Permission" : error.permissions,
            })
            return false;
        }
        const formData = new FormData()
        if (data.image.updated) {
            formData.append("image", data.image?.image);
        }
        formData.append("service_provider_id", data.service_provider_id)
        formData.append("first_name", data.first_name)
        formData.append("middle_name", data.middle_name)
        formData.append("last_name", data.last_name)
        formData.append('email', data.email)
        formData.append("employee_id", data.emp_id)
        formData.append("mobile", data.mobile)
        formData.append("address_1", data.address_1)
        formData.append("state", data.state)
        formData.append("zip_code", data.zip_code || data.zipcode)
        formData.append("carrier_id", data.carrier_id)
        formData.append("assign_pa_code_id", data.assign_pa_code_id)
        formData.append("hourly_rate", data.hourly_rate)
        formData.append("is_sms_notify", data.is_sms_notify)
        formData.append("is_email_notify", data.is_email_notify)
        formData.append("username", data.username)
        formData.append("permission", JSON.stringify(data.permissions))
        formData.append("password", data.password)
        formData.append("is_active", data.is_active)
        formData.append("selected_nodes", JSON.stringify(data.selected_nodes))
        dispatch(editEmployeeAction(formData, id, () => navigate("/account-owner/manage-employees")))
    }
    useEffect(() => {
        const permissionData = [];
        getPermissionDatalist?.length > 0 && getPermissionDatalist?.map((permission) => {
            const permObj = {
                key: permission.id,
                label: permission.permission_name,
                data: permission.permission_name,
                icon: "",
                children: [],
            };
            permission?.account_permissions?.map((child) => {
                let childObj = {
                    key: permission.id + "-" + child.id,
                    label: child.name,
                    data: child.name,
                };
                permObj.children.push(childObj);
            })
            permissionData.push(permObj);
        })
        setPermissions(permissionData);
    }, [getPermissionDatalist])
    const isLoading = useSelector((state) => state.loader.isLoading)
    return (
        <>
            {isLoading === true ? <Loader /> :
                <ContentArea>
                    <div className="edit_employees_container">
                        <div className="p-3">
                            <div className="grid card ml-0 mr-0 px-5 mb-5 align-items-center bg-main-light border-round-lg">
                                <h2 className="text-main">
                                    Edit Employees Details
                                </h2>
                            </div>
                            <Card className="shadow-2 p-3 border-round-lg">
                                <div className="p-fluid grid mb-3">
                                    <div className=" col-12 md:col-12 text-main ">

                                        <h2 className=" font-bold flex align-items-center justify-content-between" style={{ fontSize: "22px" }}>Service Provide
                                            <span className="flex text-lg text-700">
                                                Active &nbsp;&nbsp;  <InputSwitch checked={data.is_active === 1 ? true : false} onChange={(e) => handleToggle(e)} name="is_active" />
                                            </span>
                                        </h2>
                                    </div>
                                    <div className="col-12 md:col-6">
                                        <DropdownField label="Service Provider Company" inputId="dropdown" placeholder="Select" value={service}
                                            options={serviceProvider} onChange={(e) => setService(e.value)} optionLabel="service_provider_name" required errorMessage={error.service_provider_id} />
                                    </div>
                                </div>

                                <div className="p-fluid grid">
                                    <div className="col-12 md:col-12 text-main ">
                                        <h2 className="font-bold" style={{ fontSize: "22px" }}>Employee Photo<code className="text-red">*</code></h2>
                                    </div>
                                </div>

                                <div className="p-fluid grid flex align-items-center mb-4">
                                    <div className="upload-image col-12 md:col-2">
                                        <p className="mb-2 text-sm">Upload Image</p>
                                        <img src={img?.image ? URL.createObjectURL(img?.image) : data?.image?.length != 0 ? data?.image : UploadImg} alt=""   className="w-full h-full upload-image border-1 surface-border"
                                            height={109} />
                                    </div>
                                    <div className="col-12 md:col-10">
                                        <div className="upload_image mb-3">
                                            <label
                                                htmlFor="profile-img"
                                                className="bg-primary cursor-pointer w-3 px-5 border-0 p-2 border-round-md"
                                            >
                                                <i className="pi pi-plus mr-3"></i>
                                                <span>Choose a File</span>
                                            </label>
                                            <input
                                                type="file"
                                                mode="basic"
                                                hidden
                                                className="file-upload text-white border-round-lg"
                                                id="profile-img"
                                                chooseLabel="Choose a file"
                                                name="profile-img"
                                                accept="image/*"
                                                maxFileSize={1000000}
                                                onChange={(e) => {
                                                    setError({
                                                        ...error,
                                                        image: null,
                                                    });
                                                    if (e.target.files.length) {
                                                        setImg({ image: e.target.files[0], updated: true });
                                                    }
                                                }}
                                            />
                                        </div>
                                        <p className="text-500 m-0 text-xs"> Acceptable Formats jpg, png only </p>
                                    </div>
                                    {error.image && <span className="error">{error.image}</span>}
                                </div>


                                <div className="p-fluid grid">
                                    <div className=" col-12 md:col-6">
                                        <InputField type="text" placeholder="" label="Employee Email" required value={data.email} name="email" onChange={(e) => handleChange(e)} errorMessage={error.email} ></InputField>
                                    </div>
                                </div>

                                <div className="p-fluid grid">
                                    <div className=" col-12 md:col-12 text-main ">
                                        <h2 className=" font-bold" style={{ fontSize: "22px" }}>Employee Name</h2>
                                    </div>
                                </div>

                                <div className="p-fluid grid">
                                    <div className=" col-12 md:col-4">
                                        <InputField placeholder="" label="First Name" required value={data.first_name} name="first_name" onChange={(e) => handleChange(e)} errorMessage={error.first_name}></InputField>
                                    </div>

                                    <div className="col-12 md:col-4">
                                        <InputField placeholder="" label="Middle Name" value={data.middle_name === "null" ? "" : data.middle_name} name="middle_name" onChange={(e) => handleChange(e)}></InputField>
                                    </div>

                                    <div className=" col-12 md:col-4">
                                        <InputField placeholder="" label="Last Name" required value={data.last_name} name="last_name" onChange={(e) => handleChange(e)} errorMessage={error.last_name}></InputField>
                                    </div>
                                </div>

                                <div className="p-fluid grid">
                                    <div className=" col-12 md:col-12 text-main ">
                                        <h2 className=" font-bold" style={{ fontSize: "22px" }}>Employee Address</h2>
                                    </div>
                                </div>

                                <div className="p-fluid grid">
                                    <div className="col-12 md:col-4">
                                        <InputField placeholder="" label="Street Address" required value={data.address_1} name="address_1" onChange={(e) => handleChange(e)} errorMessage={error.address_1}></InputField>
                                    </div>

                                    <div className="col-12 md:col-4">
                                        <InputField placeholder="" label="State" required value={data.state} name="state" onChange={(e) => handleChange(e)} errorMessage={error.state}></InputField>
                                    </div>

                                    <div className="col-12 md:col-4">
                                        <InputNumberField
                                            label="Zip Code"
                                            placeholder=""
                                            value={data.zipcode}
                                            name="zip_code"
                                            inputClassName="border-round-md" required useGrouping={false} maxLength={5} onValueChange={(e) => {
                                                setData({
                                                    ...data,
                                                    zip_code: e.value
                                                })
                                                setError({
                                                    ...error,
                                                    zip_code: null
                                                })
                                            }} errorMessage={error.zip_code} />
                                    </div>
                                </div>

                                <div className="p-fluid grid">
                                    <div className=" col-12 md:col-12 text-main ">
                                        <h2 className=" font-bold" style={{ fontSize: "22px" }}>Employee Phone No.</h2>
                                    </div>
                                </div>
                                <div className="p-fluid grid">
                                    <div className="col-12 md:col-4">
                                        <DropdownField label="Carrier" inputId="dropdown" placeholder="Select" value={carrierData} required
                                            options={carrier} onChange={(e) => setCarrierData(e.value)} optionLabel="carrier_name" errorMessage={error.carrier_id} />
                                    </div>

                                    <div className="col-12 md:col-4">
                                        <InputNumberField inputClassName="border-round-md" placeholder="" label="Phone No." required value={data.mobile} useGrouping={false} maxLength={10} name="mobile" onValueChange={(e) => handleNumberChange(e)} errorMessage={error.mobile} />
                                    </div>

                                    <div className="col-12 md:col-4">
                                        <DropdownField label="Assign PA Code" inputId="dropdown" required errorMessage={error?.assign_pa_code_id}
                                            placeholder="Select" value={paCodeType} options={showPaCode} onChange={(e) => setPaCodeType(e.value)} optionLabel="paCode" />
                                    </div>

                                </div>

                                <div className="p-fluid grid py-4">
                                    <div className="col-12 md:col-4">
                                        <InputNumberField label="Set Employee Hourly Rate" required inputClassName=" border-round-md" placeholder='' value={data.hourly_rate} name="hourly_rate" onValueChange={(e) => handleNumberChange(e)} errorMessage={error.hourly_rate} />

                                    </div>

                                    <div className="col-12 md:col-4 ">
                                        <InputField placeholder="" label="Employee login/ Username" required value={data?.username} name="username" onChange={(e) => handleChange(e)} errorMessage={error.username}></InputField>
                                    </div>

                                    <div className="col-12 md:col-4 ">
                                        <InputField type="password" label="Employee Password" required name="password" onChange={(e) => handleChange(e)}></InputField>
                                    </div>
                                </div>
                                <h3 className="text-main ">Set Permissions</h3>
                                <Permission permissions={permissions} selectedNodes={selectedNodes} value={data} setSelectedNodes={setSelectedNodes} setSelectedKeys={setData} setError={setError} error={error} />
                                <div className="p-fluid grid mb-5 flex">
                                    <div className="col-12 md:col-12 flex align-items-center">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            checked={data.is_sms_notify}
                                            onChange={(e) => {
                                                setError({
                                                    ...error,
                                                    is_sms_notify: null,
                                                });
                                                setData({ ...data, is_sms_notify: (e.target.checked === true ? 1 : 0) });
                                            }}
                                            name="is_sms_notify"
                                        />
                                        &nbsp; Check to receive the SMS reminder notifications.
                                    </div>

                                    <div className="col-12 md:col-12 flex align-items-center">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            checked={data.is_email_notify}
                                            onChange={(e) => {
                                                setError({
                                                    ...error,
                                                    is_email_notify: null,
                                                });
                                                setData({ ...data, is_email_notify: (e.target.checked === true ? 1 : 0) });
                                            }}
                                            name="is_email_notify"
                                        />
                                        &nbsp; Check to receive the Email reminder notifications.
                                    </div>

                                </div>
                                <div className="submitButton flex">
                                    <SaveButton label="Save" onClick={handleSubmit}></SaveButton>
                                    <CancelButton label="Cancel" onClick={() => navigate("/account-owner/manage-employees")}></CancelButton>
                                </div>
                            </Card>

                        </div>
                    </div>
                </ContentArea>
            }
        </>
    )
}

export default EditEmployees;
