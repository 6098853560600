import React from "react";
import { Dropdown } from "primereact/dropdown";

export default function DropdownField({
  label,
  name,
  value,
  onChange,
  options,
  optionLabel,
  errorMessage,
  extraClassName,
  required,
  inputClass,
  appendTo,
  ...props
}) {
  return (
    <div className="grid">
      <div className="col-12 md:col-6 w-full">
        <label htmlFor={name}>
          {label} {required && <span className="error">*</span>}
        </label>
        <Dropdown
          value={value}
          name={name}
          onChange={onChange}
          options={options}
          optionLabel={optionLabel}
          className={`w-full mt-2 border-round-md ${inputClass} ${
            errorMessage ? "p-invalid" : null
          }`}
          appendTo={"self"}
          {...props}
        />
        {errorMessage ? <small className="error">{errorMessage}</small> : null}
      </div>
    </div>
  );
}
