import React, { useEffect, useState } from "react";
import ProfileImage from "./../../../assets/images/Profilepic.png";
import InputField from "../../../components/input/input";
import DropdownField from "../../../components/input/dropdown";
import SaveButton from "../../../components/Buttons/SaveButton";
import CancelButton from "../../../components/Buttons/CancelButton";
import { useDispatch, useSelector } from "react-redux";
import { ClientEditProfileData } from "../../../Redux/HospitalAction/appoinmentAction";
import { carrierAction } from "../../../Redux/HospitalAction/carrierAction";
import { ConvertIntoFromData } from "../../../components/FromData/FromData";
import CalendarField from "../../../components/input/calendar";

const EditProfile = ({ setShowEdit, data, setData }) => {
  const dispatch = useDispatch();
  const [isError, setIsError] = useState({});
  const [UploadPath, setUploadPath] = useState({});
  const [saveImage, setImage] = useState({});
  const [showImage, setShowImage] = useState();

  const SaveEditData = async (data) => {
    // let newData = await ConvertIntoFromData(data);
    // dispatch(ClientEditProfileData(newData, setShowEdit));
  };
  const carrierlist = useSelector((state) => state?.carrier?.carrierProfile);
  useEffect(() => {
    dispatch(carrierAction());
  }, []);

  const handleChange = (name) => (e) => {
    if (name === "carrier") {
      setData({ ...data, [name]: e.value.name });
    }
    setData({ ...data, [name]: e.target.value });
    setIsError({ ...isError, [name]: "" });
  };

  const validate = () => {
    let errors = {};
    if (!data.last_name) {
      errors.last_name = "Enter last name";
    }
    if (!data.first_name) {
      errors.first_name = "Enter first name";
    }
    if (!data.dob) {
      errors.dob = "Enter Date of Birth";
    }
    if (!data.mobile) {
      errors.mobile = "Enter Phone Number";
    }
    if (!data.state) {
      errors.state = "Enter State";
    }
    if (!data.zipcode) {
      errors.zipcode = "Enter Zip Code";
    }
    if (!data.carrier) {
      errors.carrier = "Enter carrier";
    }
    if (!data.social_security_no) {
      errors.social_security_no = "Enter Social Security No";
    }
    if (!data.address_1) {
      errors.address_1 = "Enter Address 1";
    }
    if (!data.city) {
      errors.city = "Enter city";
    }

    setIsError(errors);
    if (Object.keys(errors).length === 0) {
      return true;
    } else {
      return false;
    }
  };

  const DataSubmit = async (data) => {
    let errors = await validate();
    if (errors) SaveEditData(data);
  };

  const customBase64Uploader = async (event) => {
    setShowImage(URL.createObjectURL(event.target.files[0]));
    // let data = new FormData();
    // data.append("files", event?.target?.files[0]);
    setData({ ...data, image: event?.target?.files[0] });
  };
  return (
    <div className=" p-5">
      <div className="heading">Edit Profile</div>
      <div className="col-12  profile-content p-5 mt-5 ">
        <div className="flex">
          {showImage ? (
            <img
              src={showImage}
              alt=""
              style={{ width: "200px", height: "200px", borderRadius: "10px" }}
            />
          ) : (
            <img
              src={data.image ? data?.image : ProfileImage}
              alt=""
              style={{ width: "200px", height: "200px", borderRadius: "10px" }}
            />
          )}
          <div className="flex justify-content-end align-items-end mx-5 mb-2">
            <div className=" flex gap-3 align-items-center">
              <div className="upload_image">
                <label
                  htmlFor="profile-img"
                  className="bg-primary cursor-pointer w-3 px-5 border-0 p-2 border-round-md"
                >
                  <i className="pi pi-upload"></i>
                </label>
                <input
                  type="file"
                  mode="basic"
                  hidden
                  className="file-upload text-white border-round-lg"
                  id="profile-img"
                  chooseLabel="Choose a file"
                  name="profile-img"
                  accept="image/*"
                  maxFileSize={1000000}
                  onChange={customBase64Uploader}
                />
              </div>
              <div className="bg-red-500  cursor-pointer text-center px-5 py-2  w-8   border-0  border-round-md">
                <i className="pi pi-trash text-white "></i>
              </div>
            </div>
          </div>
        </div>
        <div className="col-8 p-0  ">
          <h2 className="  m-0 font-mediumn text-main my-3 pt-4">
            Personal Details
          </h2>
          <div className="grid p-0 m-0 ">
            <div className="col-4 ">
              <InputField
                label={"Email"}
                value={data?.email}
                onChange={handleChange("email")}
                disabled
              ></InputField>
            </div>
            <div className="col-4 ">
              <InputField
                label={"First Name"}
                value={data?.first_name}
                onChange={handleChange("first_name")}
                required
              ></InputField>
              {isError?.first_name && (
                <p className="text-red-600 text-xs mt-1">
                  {isError.first_name}
                </p>
              )}
            </div>
            <div className="col-4">
              <InputField
                label={"Middle Name"}
                value={data?.middle_name}
                onChange={handleChange("middle_name")}
                required
              ></InputField>
              {isError?.middle_name && (
                <p className="text-red-600 text-xs mt-1">
                  {isError.middle_name}
                </p>
              )}
            </div>
            <div className="col-4">
              <InputField
                label={"Last Name"}
                value={data?.last_name}
                onChange={handleChange("last_name")}
                required
              ></InputField>
              {isError?.last_name && (
                <p className="text-red-600 text-xs mt-1">{isError.last_name}</p>
              )}
            </div>
            <div className="col-4">
              <CalendarField
                value={new Date(data?.dob)}
                label={"Date of Birth"}
                onChange={handleChange("dob")}
                required
              />
              {isError?.dob && (
                <p className="text-red-600 text-xs mt-1">{isError.dob}</p>
              )}
            </div>
            <div className="col-4 ">
              <InputField
                label={"Phone Number"}
                value={data?.mobile}
                onChange={handleChange("mobile")}
                required
              ></InputField>
              {isError?.mobile && (
                <p className="text-red-600 text-xs mt-1">{isError.mobile}</p>
              )}
            </div>{" "}
            <div className="col-4 ">
              <InputField
                label={"Social Security No"}
                value={data?.social_security_no}
                onChange={handleChange("social_security_no")}
                required
              ></InputField>
              {isError?.social_security_no && (
                <p className="text-red-600 text-xs mt-1">
                  {isError.social_security_no}
                </p>
              )}
            </div>{" "}
            <div className="col-4">
              <DropdownField
                label={"Carrier"}
                value={data?.carrier}
                optionLabel={"name"}
                options={carrierlist.map((item) => {
                  return { name: item.carrier_name, value: item.carrier_name };
                })}
                onChange={handleChange("carrier")}
              ></DropdownField>
              {isError?.carrier && (
                <p className="text-red-600 text-xs mt-1">{isError.carrier}</p>
              )}
            </div>
          </div>{" "}
          <h2 className=" m-0 font-mediumn text-main my-3">Address Info</h2>
          <div className="grid   ">
            <div className="col-4 ">
              <InputField
                label={"Address 1"}
                value={data?.address_1}
                onChange={handleChange("address_1")}
                required
              ></InputField>
              {isError?.address_1 && (
                <p className="text-red-600 text-xs mt-1">{isError.address_1}</p>
              )}
            </div>{" "}
            <div className="col-4 ">
              <InputField
                label={"Address 2"}
                value={data?.address_2}
                onChange={handleChange("address_2")}
              ></InputField>
              {isError?.address_2 && (
                <p className="text-red-600 text-xs mt-1">{isError.address_2}</p>
              )}
            </div>{" "}
            <div className="col-4 ">
              <InputField
                label={"City"}
                value={data?.city}
                onChange={handleChange("city")}
                required
              ></InputField>
              {isError?.city && (
                <p className="text-red-600 text-xs mt-1">{isError.city}</p>
              )}
            </div>{" "}
            <div className="col-4">
              <InputField
                label={"State"}
                value={data?.state}
                onChange={handleChange("state")}
                required
              ></InputField>
              {isError?.state && (
                <p className="text-red-600 text-xs mt-1">{isError.state}</p>
              )}
            </div>
            <div className="col-4">
              <InputField
                label={"Zip Code"}
                value={data?.zipcode}
                onChange={handleChange("zipcode")}
                required
              ></InputField>
              {isError?.zipcode && (
                <p className="text-red-600 text-xs mt-1">{isError.zipcode}</p>
              )}
            </div>
          </div>
          <div className="flex py-5">
            <SaveButton
              label={"Save"}
              onClick={() => DataSubmit(data)}
            ></SaveButton>
            <CancelButton
              label={"Cancel"}
              onClick={() => setShowEdit(false)}
            ></CancelButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditProfile;
