

import React, { useEffect, useState } from "react";
import AddButton from "../../../../components/Buttons/AddButton";
import ContentArea from "../../../../shared/ContentArea";
import SearchIcon from "../../../../assets/icons/search.png";
import FilterIcon from "../../../../assets/icons/filter.png";
import Table from '../../../../components/TableList/Table';
import { useNavigate } from "react-router-dom";
import Index from "../../Index";
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import Deleteimg from "../../../../assets/images/deleted.png";
import { getServiceAction } from "../../../../Redux/HospitalAction/serviceProviderAction";
import { getEmployeeHourlyAction, deleteEmployeeHourlyAction } from "../../../../Redux/HospitalAction/employessAction";
import { FilterMatchMode } from 'primereact/api';
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../../pages/Loader";
import { Calendar } from "primereact/calendar";
import moment from "moment";
const ManageHourlyRate = () => {
  const dispatch = useDispatch()
  const [isFiltered, setIsFiltered] = useState(false);
  const [deleteButton, setDeleteButton] = useState(false)
  const { isNextPage, setIsNextPage } = Index();
  const [tableData, setTableData] = useState("")
  const [data, setData] = useState()
  const [isShowFilter, setIsShowFilter] = useState(false);
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const [service, SetService] = useState(null);
  const [serviceData, setServiceData] = useState("")
  const [filterData, setFilterData] = useState({
    start_date: "",
    end_date: ""
  })
  const navigate = useNavigate()
  const AddHourlyRate = () => {
    setIsNextPage(!isNextPage)
    navigate("/account-owner/add-hourly-rate")
  }
  useEffect(() => {
    dispatch(getEmployeeHourlyAction())
    dispatch(getServiceAction())
  }, [])
  const getEmployeeData = useSelector((state) => state.getHourlyRate.getHourlyRateProfile)
  const serviceDatalist = useSelector((state) => state.getService?.getServiceProfile)
  const isLoading = useSelector((state) => state.loader.isLoading)
  useEffect(() => {
    let array = []
    let obj
    getEmployeeData.length > 0 && getEmployeeData.map((item) => {
      let date = new Date(item.date_of_service)
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let dt = date.getDate();
      if (dt < 10) {
        dt = '0' + dt;
      }
      if (month < 10) {
        month = '0' + month;
      }
      let dob = dt + '-' + month + '-' + year;
      obj = {
        client: item.client,
        date_of_service: dob,
        employee_Id: item.employee_Id,
        employee_id: item.employee_id,
        employee_name: item.employee_name,
        employee_working_hour: item.employee_working_hour,
        id: item.id,
        pa_Code: item.pa_Code,
        service: item.service,
        service_name: item.service_name,
        start_time: item.start_time,
        user_id: item.user_id
      }
      array.push(obj)
    })

    setTableData(array)
    SetService(serviceDatalist)
  }, [getEmployeeData, serviceDatalist])
  useEffect(() => {
    if (tableData.length !== 0) {
      let test = ""
      let data = tableData?.length > 0 && tableData.map((item) => {
        let data1 = serviceDatalist?.length > 0 && serviceDatalist?.map((items) => {
          if (item?.service == items?.id) {
            test = items.program_name
            return true
          } else {
            return false
          }
        })
        if (data1) {
          return {
            ...item,
            service_name: test
          }
        }
      })
      setServiceData(data)
    }
    else {
      setServiceData("")
    }
  }, [service, tableData, getEmployeeData])
  const TableHeading = [
    { field: "SrNo", header: "Sr. No.", body: "srnoTemplates" },
    { field: "employee_Id", header: "Employee Id" },
    { field: "employee_name", header: "Employee Name", },
    { field: "service_name", header: "Service" },
    { field: "pa_Code", header: "PA Code", },
    { field: "date_of_service", header: "Date of Service", },
    { field: "start_time", header: "Start Time", },
    { field: "client", header: "Client", },
    { field: "employee_working_hour", header: "Working Hours", body: "workingHoursTemplate" },
    { field: "icons", header: "Action", body: "hourlyIconsBodyTemplate" },

  ];
  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters['global'].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };
  const handleChange = (e) => {
    let date = new Date(e.target.value)
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let dt = date.getDate();
    if (dt < 10) {
      dt = '0' + dt;
    }
    if (month < 10) {
      month = '0' + month;
    }
    let dob = dt + '-' + month + '-' + year;
    setFilterData({
      ...filterData,
      [e?.target?.name]: dob,
    });
  };
  const applyFilter = () => {
    setIsFiltered(true)
    setIsShowFilter(!isShowFilter)
    dispatch(getEmployeeHourlyAction(filterData))
  }
  const id = data
  const clearFilter = () => {
    setIsFiltered(false)
    setIsShowFilter(false)
    dispatch(getEmployeeHourlyAction())
  }
  const handleSubmitDelete = () => {
    dispatch(deleteEmployeeHourlyAction(id, () => setDeleteButton(false))).then(() => {
      dispatch(getEmployeeHourlyAction())
    })
  }
  return (
    <>
      {isLoading === true ? <Loader /> :
        <ContentArea>
          <div className="manage_hourly_rate_container p-3 ">

            <div className="grid mb-5 mr-0 ml-0 align-items-center bg-main-light border-round-lg shadow-1 flex justify-content-around">
              <div className="md:col-5 ">
                <h1 className="text-main  " style={{ fontSize: "24px" }}>
                  Manage Hourly Rate
                </h1>
              </div>
              <div className="md:col-3">
                <div className="search-box w-full">
                  <img
                    src={SearchIcon}
                    alt="Search Icon"
                    width="15px"
                    height="15px"
                  />
                  <input type="search" placeholder="Search" onChange={onGlobalFilterChange} onClick={() => setIsShowFilter(false)} />
                </div>
              </div>
              <div className="md:col-2 relative bg-main px-3 border-round-md w-max ">
                {
                  isFiltered ?
                    <div onClick={() => clearFilter()} className="bg-main border-round-md text-white text-center text-base relative karla">
                      Clear &nbsp;
                      <i className="pi pi-filter-slash"></i></div>
                    :
                    <div onClick={() => setIsShowFilter(prev => !prev)} className="border-round-md text-white text-center text-base relative karla">
                      Filter &nbsp;
                      <img src={FilterIcon} alt="" width={15} /></div>
                }
                {
                  isShowFilter ?
                    <div className="filter-popup">
                      <label className="font block mb-2">
                        Start Date<code className="text-red"></code>
                      </label>
                      <Calendar
                        className="border-round-md calender_picker "
                        inputClassName="calendar p-2 border-round-md "
                        placeholder="Select Date"
                        name="start_date"
                        onChange={(e) => handleChange(e)}
                      />
                      <label className="font block mb-2 mt-2">
                        End Date<code className="text-red"></code>
                      </label>
                      <Calendar
                        className="border-round-md calender_picker "
                        inputClassName="calendar p-2 border-round-md "
                        placeholder="Select Date"
                        name="end_date"
                        onChange={(e) => handleChange(e)}
                      />
                      <div className="mt-3 flex justify-content-between">
                        <Button onClick={clearFilter}>Clear</Button>
                        <Button onClick={(e) => applyFilter(e)}>Apply</Button></div>
                    </div>
                    : null
                }
              </div>
              <div className="md:col-1 w-max ">
                <AddButton onClick={() => AddHourlyRate()} />
              </div>
            </div>

            <div className="grid mt-2">
              <div className="md:col-12">

                <Table tableHeading={TableHeading} tableData={serviceData} setDeleteButton={setDeleteButton} deleteButton={deleteButton} filters={filters} setFilters={setFilters} setData={setData} />
              </div>

            </div>

            {
              deleteButton ?
                <Dialog
                  className="table_delete_modal flex justify-content-center "
                  visible={deleteButton}
                  onHide={() => setDeleteButton(false)}
                  style={{ width: '30vw' }}>
                  <p className="m-0">
                    <div className=" flex justify-content-center">
                      <img src={Deleteimg} alt="" width={150} height={127}></img>
                    </div>
                    <div className="text-center mb-5">
                      <h4 className=" p-0 m-0 ">Are you sure you want to delete this user?</h4>
                      <p className="text-xs text-400">All associated data will also be deleted! There is no undo!</p>
                    </div>
                    <div className=" btn flex justify-content-center">
                      <div className="text-center">
                        <Button
                          label="Cancel"
                          className="w-full surface-500 p-3 px-5    border-0 border-round-md "
                          onClick={() => setDeleteButton(false)}
                        />
                      </div>
                      <div className="text-center">
                        <Button
                          label="Delete"
                          className=" w-full px-5 border-0 p-3 ml-3 border-round-md bg-red-500"
                          onClick={handleSubmitDelete}
                        />
                      </div>
                    </div>
                  </p>
                </Dialog> : null
            }
          </div>
        </ContentArea>
      }
    </>
  )
}

export default ManageHourlyRate;
