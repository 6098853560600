import React, { useState } from "react";
import SaveButton from "../../../components/Buttons/SaveButton";
import CancelButton from "../../../components/Buttons/CancelButton";
import { useDispatch } from "react-redux";
import PasswordField from "../../../components/input/Password";
import { ClientResetPassword } from "../../../Redux/HospitalAction/appoinmentAction";
import { validPassword } from "../../../utils/regex/regex";

const ResetPassword = ({ setShowResetP }) => {
  const [data, setData] = useState({
    old_password: "",
    new_password: "",
    confirm_password: "",
  });

  const dispatch = useDispatch();
  const [isError, setIsError] = useState({});
  const SavePasswordData = (data) => {
    dispatch(ClientResetPassword(data, setShowResetP));
  };

  const handleChange = (name) => (e) => {
    setData({ ...data, [name]: e.target.value });
    setIsError({ ...isError, [name]: "" });
  };

  const Validate = () => {
    let errors = {};
    if (data.new_password) {
      if (!validPassword.test(data.new_password)) {
        errors.new_password =
          "Password must has at least 8 characters that include at least 1 lowercase character , 1 uppercase characters , 1 number , and 1 special character in (!@#$%^&*)";
      }
    }
    if (!data.new_password) {
      errors.new_password = "Enter New Password ";
    }
    if (!data.old_password) {
      errors.old_password = "Enter Old Password ";
    }
    if (!data.confirm_password) {
      errors.confirm_password = "Enter Confirm Password";
    } else if (data.new_password !== data.confirm_password) {
      errors.confirm_password = "Password Mismatch";
    }
    setIsError(errors);
    if (Object.keys(errors).length === 0) {
      return true;
    } else {
      return false;
    }
  };

  const DataSubmit = async (data) => {
    let errors = await Validate();
    if (errors) SavePasswordData(data);
  };

  return (
    <div className=" p-5">
      <div className="heading">Change Password</div>
      <div className="col-12 profile-content p-5 mt-5 ">
        <div className="flex">
          <div className="col pl-0">
            <PasswordField
              required
              label={"Old Password"}
              value={data.old_password}
              onChange={handleChange("old_password")}
            ></PasswordField>
            {isError?.old_password && (
              <p className="text-red-600 text-xs mt-1">
                {isError.old_password}
              </p>
            )}
          </div>
          <div className="col">
            <PasswordField
              required
              label={"New Password"}
              value={data.new_password}
              onChange={handleChange("new_password")}
            ></PasswordField>
            {isError?.new_password && (
              <p className="text-red-600 text-xs mt-1">
                {isError.new_password}
              </p>
            )}
          </div>
          <div className="col">
            <PasswordField
              value={data.confirm_password}
              required
              label={"Confirm Password"}
              onChange={handleChange("confirm_password")}
            ></PasswordField>
            {isError?.confirm_password && (
              <p className="text-red-600 text-xs mt-1">
                {isError.confirm_password}
              </p>
            )}
          </div>
        </div>
        <div className="flex py-5">
          <SaveButton
            label={"Change Password"}
            save_button={false}
            style={{ height: "50px" }}
            onClick={() => DataSubmit(data)}
          ></SaveButton>
          <CancelButton
            label={"Cancel"}
            onClick={() => setShowResetP(false)}
          ></CancelButton>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
