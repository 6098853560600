import React, { useEffect, useState } from "react";
import EditImg from "../../../../assets/images/edit.png";
import EditCompany from "./EditCompany";
import { useDispatch, useSelector } from "react-redux";
import { CompanyShowData } from "../../../../Redux/HospitalAction/appoinmentAction";

const Company = () => {
  const dispatch = useDispatch();
  const [showEdit, setShowEdit] = useState();

  const companyDatalist = useSelector(
    (state) => state?.companyDetails?.CompanyDetails
  );

  useEffect(() => {
    dispatch(CompanyShowData());
  }, []);

  return (
    <>
      {showEdit ? (
        <EditCompany
          setShowEdit={setShowEdit}
          companyDatalist={companyDatalist}
        />
      ) : (
        <div className="px-4">
          <div className="w-full flex justify-content-between align-items-center">
            <h2 className="">Company Details</h2>
            <div className="cursor-pointer" onClick={() => setShowEdit(true)}>
              <img src={EditImg} height={20} alt="" />
            </div>
          </div>
          <div className="flex align-items-center ">
            <h2 className="text-lg my-2 font-mediumn col ">Company TIN/EIN</h2>
            <small className="text-lg font-mediumn  text-lightgreen col ">
              {companyDatalist?.tin_ein}
            </small>
          </div>
          <div className="flex align-items-center ">
            <h2 className="text-lg my-2 font-mediumn col ">Company Name</h2>
            <small className="text-lg font-mediumn  text-lightgreen col ">
              {companyDatalist?.name}
            </small>
          </div>
          <div className="flex align-items-center ">
            <h2 className="text-lg my-2 font-mediumn col ">Street Address</h2>
            <small className="text-lg font-mediumn  text-lightgreen col ">
              {companyDatalist?.address}
            </small>
          </div>
          <div className="flex align-items-center ">
            <h2 className="text-lg my-2 font-mediumn col ">State</h2>{" "}
            <small className="text-lg font-mediumn  text-lightgreen col ">
              {companyDatalist?.state}
            </small>
          </div>
          <div className="flex align-items-center ">
            <h2 className="text-lg my-2 font-mediumn col ">Pin Code</h2>{" "}
            <small className="text-lg font-mediumn  text-lightgreen col ">
              {companyDatalist?.pin_code}
            </small>
          </div>
        </div>
      )}
    </>
  );
};

export default Company;
