
import React, { useEffect, useState } from "react";
import AddButton from "../../../components/Buttons/AddButton";
import ContentArea from "../shared/ContentArea";
import SearchIcon from "../../../assets/icons/search.png";
import FilterIcon from "../../../assets/icons/filter.png";
import Table from '../../../components/TableList/Table';
import { deletePaCodeAction, getPaCodesAction,getfilterPaCodeAction } from "../../../Redux/HospitalAction/paCodeAction";
import { getServiceAction } from "../../../Redux/HospitalAction/serviceProviderAction";
import { useDispatch, useSelector } from "react-redux";
import { FilterMatchMode } from 'primereact/api';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import Deleteimg from "../../../assets/images/deleted.png";
import Loader from "../../../pages/Loader";
import { useNavigate } from "react-router-dom";
import { Dropdown } from "primereact/dropdown";
const ManagePaCodeList = () => {
  const dispatch = useDispatch()
  const navigate =  useNavigate()
  const [isFiltered, setIsFiltered] = useState(false);
  const [tableData, setTableData] = useState("")
  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const [isShowFilter, setIsShowFilter] = useState(false);
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const [service, SetService] = useState(null);
  const [filterData, setFilterData] = useState({
    service_type: ""
  })
  const [ filterName,setFilterName] =  useState("")
  const [deleteButton, setDeleteButton] = useState(false)
  const [data, setData] = useState()
  useEffect(() => {
    dispatch(getPaCodesAction())
    dispatch(getServiceAction())
  }, [])
  const paCodeTableData = useSelector((state) => state.getPaCode.getPaCodeProfile)
  const serviceDatalist = useSelector((state) => state.getService?.getServiceProfile)
  useEffect(() => {
    if(paCodeTableData?.length ===  0){
      setTableData("")
    }else{
      setTableData(paCodeTableData)
    }
    SetService(serviceDatalist)
  }, [paCodeTableData])
  const TableHeading = [
    { field: "SrNo", header: "Sr. No.", body: "srnoTemplates" },
    { field: "pa_code", header: "PA Code" ,key:"pa_code" },
    { field: "subscription_plan.program_name", header: "Service Type", },
    { field: "icons", header: "Action", body: "paCodeIconsBodyTemplate" },
  ];
  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters['global'].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };
  const id = data;
  const handlDelete = () => {
    dispatch(deletePaCodeAction(id, () => setDeleteButton(false))).then(() => {
      dispatch(getPaCodesAction())
    })
  }
  const handleChange = (e) => {
    setFilterName(e.value)
    setFilterData({
      ...filterData,
      [e?.target?.name]: e.value.id,
    });
  };
  const isLoading = useSelector((state) => state.loader.isLoading)
  const applyfilter = () => {
    setIsShowFilter(!isShowFilter)
    dispatch(getfilterPaCodeAction(filterData))
    setIsFiltered(true)
  }
  const clearfilter = ()=>{
    setIsFiltered(false)
    setIsShowFilter(false)
    dispatch(getPaCodesAction())
    setFilterName("")
  }
  return (
    <>
      {isLoading === true ? <Loader /> :
        <ContentArea>
          <div className="manage_pa_code_container p-3 ">
            <div className="grid mb-5 mr-0 ml-0 align-items-center bg-main-light border-round-lg shadow-1 flex justify-content-around">
              <div className="md:col-5 ">
                <h1 className="text-main  " style={{ fontSize: "24px" }}>
                  Manage PA Code
                </h1>
              </div>
              <div className="md:col-3">
                <div className="search-box w-full">
                  <img
                    src={SearchIcon}
                    alt="Search Icon"
                    width="15px"
                    height="15px"
                  />
                  <input type="search" placeholder="Search" onChange={onGlobalFilterChange} onClick={() => setIsShowFilter(false)} />
                </div>
              </div>
              <div className="md:col-2 relative bg-main px-3 border-round-md w-max ">
                {
                  isFiltered ?
                    <div onClick={() => clearfilter()} className="bg-main border-round-md text-white text-center text-base relative karla">
                      Clear &nbsp;
                      <i className="pi pi-filter-slash"></i></div>
                    :
                    <div onClick={() => setIsShowFilter(prev => !prev)} className="border-round-md text-white text-center text-base relative karla">
                      Filter &nbsp;
                      <img src={FilterIcon} alt="" width={15} /></div>
                }
                {
                  isShowFilter ?
                    <div className="filter-popup">
                      <label className="font block mb-2">
                        Service Type<code className="text-red"></code>
                      </label>
                      <Dropdown
                        className="border-round-md"
                        inputId="dropdown"
                        name="service_type"
                        placeholder="Select"
                        options={service}
                        value ={filterName}
                        optionLabel="program_name" onChange={(e) => handleChange(e)} />
                      <div className="mt-3 flex justify-content-between">
                        <Button className="w-full justify-content-center" onClick={(e) => applyfilter(e)}>Apply</Button>
                      </div>
                    </div>
                    : null
                }
              </div>
              <div className="md:col-2 w-max ">
                <AddButton onClick={()=>navigate("/account-owner/add-pa-code")}/>
              </div>
            </div>
            <div className="grid mt-2">
              <div className="md:col-12">
                <Table tableHeading={TableHeading} tableData={tableData} filters={filters} setDeleteButton={setDeleteButton} setData={setData} />
              </div>
            </div>
            {
              deleteButton ?
                <Dialog
                  className="table_delete_modal flex justify-content-center "
                  visible={deleteButton}
                  onHide={() => setDeleteButton(false)}
                  style={{ width: '30vw' }}>
                  <p className="m-0">
                    <div className=" flex justify-content-center">
                      <img src={Deleteimg} alt="" width={150} height={127}></img>
                    </div>
                    <div className="text-center mb-5">
                      <h4 className=" p-0 m-0 ">Are you sure you want to delete this user?</h4>
                      <p className="text-xs text-400">All associated data will also be deleted! There is no undo!</p>
                    </div>
                    <div className=" btn flex justify-content-center">
                      <div className="text-center">
                        <Button
                          label="Cancel"
                          className="w-full surface-500 p-3 px-5    border-0 border-round-md "
                          onClick={() => setDeleteButton(false)}
                        />
                      </div>
                      <div className="text-center">
                        <Button
                          label="Delete"
                          className=" w-full px-5 border-0 p-3 ml-3 border-round-md bg-red-500"
                          onClick={handlDelete}
                        />
                      </div>
                    </div>
                  </p>
                </Dialog> : null
            }
          </div>
        </ContentArea>
      }
    </>
  )
}

export default ManagePaCodeList;
