import React, { useEffect, useState } from "react";
import AddButton from "../../../../components/Buttons/AddButton";
import ContentArea from "../../../../shared/ContentArea";
import SearchIcon from "../../../../assets/icons/search.png";
import Table from "../../../../components/TableList/Table";
import { useNavigate } from "react-router-dom";
import Index from "../../Index";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import Deleteimg from "../../../../assets/images/deleted.png";
import { CommonManageService } from "./CommonManageService";
import { FilterMatchMode } from "primereact/api";

const ManageService = () => {
  const {
    setData,
    tableData,
    deleteButton,
    setDeleteButton,
    handleDelete,
    getServiceList,
  } = CommonManageService();
  const { isNextPage, setIsNextPage } = Index();
  const [isShowFilter, setIsShowFilter] = useState(false);
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const navigate = useNavigate();
  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };
  useEffect(() => {
    getServiceList();
  }, []);

  const AddManageService = () => {
    setIsNextPage(!isNextPage);
    navigate("/account-owner/add-manage-services");
  };
  const duration = (col) => {
    return (
      <>
        {col?.durations.map((item, index) => {
          return (
            <span className="flex flex-column">
              {item?.duration}
              {/* {col?.durations.length - 1 !== index ? "," : ""} */}
            </span>
          );
        })}
      </>
    );
  };
  const SchedulingName = (col) => {
    return (
      <>
        {col?.durations.map((item, index) => {
          return (
            <span className="flex flex-column">
              {item?.scheduling_name}
              {/* {col?.durations.length - 1 !== index ? "," : ""} */}
            </span>
          );
        })}
      </>
    );
  };
  const TableHeading = [
    { field: "SrNo", header: "Sr. No.", body: "srnoTemplates" },
    {
      field: "service_name",
      header: "Service Name",
    },
    {
      field: "scheduling_name",
      header: "Scheduling Name",
      body: SchedulingName,
    },
    { field: "duration", header: "Duration", body: duration },
    { field: "icons", header: "Action", body: "manageserviceiconBodyTemplate" },
  ];

  return (
    <>
      <ContentArea>
        <div className="manage_service_container p-3 ">
          <div className="grid mb-5 mr-0 ml-0 align-items-center bg-main-light border-round-lg shadow-1 flex justify-content-around">
            <div className="md:col-7">
              <h1 className="text-main  " style={{ fontSize: "24px" }}>
                Manage Services
              </h1>
            </div>
            <div className="md:col-3">
              <div className="search-box w-full">
                <img
                  src={SearchIcon}
                  alt="Search Icon"
                  width="15px"
                  height="15px"
                />
                <input
                  type="search"
                  placeholder="Search"
                  onChange={onGlobalFilterChange}
                  onClick={() => setIsShowFilter(false)}
                />
              </div>
            </div>

            <div className="md:col-1 w-max ">
              <AddButton onClick={() => AddManageService()} />
            </div>
          </div>

          <div className="grid mt-2">
            <div className="md:col-12">
              <Table
                tableHeading={TableHeading}
                tableData={tableData}
                setDeleteButton={setDeleteButton}
                setData={setData}
                filters={filters}
                setFilters={setFilters}
              />
            </div>
          </div>

          {deleteButton ? (
            <Dialog
              className="table_delete_modal flex justify-content-center "
              visible={deleteButton}
              onHide={() => setDeleteButton(false)}
              style={{ width: "30vw" }}
            >
              <p className="m-0">
                <div className=" flex justify-content-center">
                  <img src={Deleteimg} alt="" width={150} height={127}></img>
                </div>
                <div className="text-center mb-5">
                  <h4 className=" p-0 m-0 ">
                    Are you sure you want to delete this user?
                  </h4>
                  <p className="text-xs text-400">
                    All associated data will also be deleted! There is no undo!
                  </p>
                </div>
                <div className=" btn flex justify-content-center">
                  <div className="text-center">
                    <Button
                      label="Cancel"
                      className="w-full surface-500 p-3 px-5    border-0 border-round-md "
                      onClick={() => setDeleteButton(false)}
                    />
                  </div>
                  <div className="text-center">
                    <Button
                      label="Delete"
                      className=" w-full px-5 border-0 p-3 ml-3 border-round-md bg-red-500"
                      onClick={handleDelete}
                    />
                  </div>
                </div>
              </p>
            </Dialog>
          ) : null}
        </div>
      </ContentArea>
    </>
  );
};

export default ManageService;
