import React, { useState } from 'react';
import { Dropdown } from 'primereact/dropdown';
import InputField from '../../../../../components/input/input';
import { useEffect } from 'react';


const SelectTimesloat = ({ hour, item, form, setForm, index, setCheck }) => {
    const [showValue, setShowValue] = useState(null)
    const handleToogle = (e) => {
        const { name, value } = e.target
        let array = [...form?.time_schedule]
        let obj = { ...array[index], [name]: value,error:"" }
        array.splice(index, 1, obj)
        setShowValue(value)
        setForm((prev) => {
            return {
                ...prev,
                time_schedule: array,
                isView:false
            }
        })
    }
   useEffect(()=>{
    setShowValue(item?.time_duration)
   },[item])
    return (
        <>
            <div className='timesloat py-3'>

                <div className="p-fluid grid align-items-center p-0 m-0">
                    <div className=" col-12 md:col-4 mb-3">
                        <label className="font block mb-2 ">
                            Manage Timeslot Availability
                        </label>
                        <InputField inputClass = 'bg-blue-100 border-round-md mt-2 p-2 bg-blue-200' value={item?.day === 0 && item.is_available === 1 ? "Monday" : item?.day === 1 && item.is_available === 1 ? "Tuesday" : item?.day === 2 && item.is_available === 1 ? "Wednesday" : item?.day === 3 && item.is_available === 1 ? "Thursday" : item?.day === 4 && item.is_available === 1 ? "Friday" : item?.day === 5 && item.is_available === 1 ? "Saturday" : item?.day === 6 && item.is_available === 1 ? "Sunday" : ""} disabled />
                    </div>
                    {item?.error && <span className='error'>{item?.error}</span>}
                </div>

                <div className="p-fluid grid align-items-center p-0 m-0 ">
                    <div className=" col-12 md:col-3">
                        <p className='text-color_rgb'> Select Timeslot Duration</p>
                    </div>
                    <div className=" col-12 md:col-4">
                        <Dropdown inputId="dropdown" className='border-round-md' placeholder="Select hour" value={showValue}
                            options={hour} onChange={(e) => handleToogle(e)}  name="time_duration" />
                    </div>

                </div>
            </div>
        </>
    )
}

export default SelectTimesloat
