import React from "react";
import { Button } from "primereact/button";
export default function SubmitButton({ onClick, submitOnClick, btnLabel }) {
  return (
    <div>
      <div className="submit_button">
        <div className="grid">
          <div className="col-12 md:col-12">
            <Button
              label={btnLabel ? btnLabel : "Save"}
              className=" save_button text-center  border-0 text-lg font-medium karla p-3 border-round-lg bg-main "
              onClick={submitOnClick}
            />
            <Button
              label="Cancel"
              className=" cancel_button  text-center border-0 p-3 ml-3 karla border-round-lg "
              onClick={onClick}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
