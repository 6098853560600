import React, { useEffect, useState } from "react";
import SheduleTime from "./BookAppointmentShedule/SheduleTime";
import Timesloat from "./BookAppointmentShedule/Timesloat";
import { Dropdown } from "primereact/dropdown";
import SelectTimesloat from "./BookAppointmentShedule/SelectTimesloat";
import { InputTextarea } from "primereact/inputtextarea";
import SaveButton from "../../../../components/Buttons/SaveButton";
import CancelButton from "../../../../components/Buttons/CancelButton";
import ContentArea from "../../shared/ContentArea";
import InputField from "../../../../components/input/input";
import { Card } from "primereact/card";
import { Checkbox } from "primereact/checkbox";
import { CommonSchedule } from "./BookAppointmentShedule/CommonSchedules";
import { InputSwitch } from "primereact/inputswitch";
import DropdownField from "../../../../components/input/dropdown";
import { useDispatch, useSelector } from "react-redux";
import { LocationData } from "../../../../Redux/HospitalAction/appoinmentAction";
const AddSchdeules = () => {
  const {
    form,
    setForm,
    handleChange,
    hour,
    time,
    handleReminder,
    error,
    handleSubmit,
    navigate,
    startTime,
    id,
    setStartTime,
    endTime,
    setEndTime,
    viewData,
  } = CommonSchedule();
  const [checked, setChecked] = useState(false);
  const dispatch = useDispatch();
  const [check, setCheck] = useState(true);
  useEffect(() => {
    if (form?.is_applicable === 0 && id !== undefined) {
      setChecked(true);
    } else {
      setChecked(false);
    }
  }, [id, viewData]);
  useEffect(() => {
    if (checked === false) {
      setForm({
        ...form,
        is_applicable: 1,
      });
    } else {
      setForm({
        ...form,
        is_applicable: 0,
      });
    }
  }, [checked]);
  useEffect(() => {
    if (check === false) {
      setForm({
        ...form,
        is_active: 0,
      });
    } else {
      setForm({
        ...form,
        is_active: 1,
      });
    }
  }, [check]);
  const Location = useSelector(
    (state) => state.companyLocation.companyLocation
  );
  useEffect(() => {
    dispatch(LocationData());
  }, []);
  return (
    <>
      <ContentArea>
        <div className="p-3">
          <div className="grid card ml-0 mr-0  px-5 mb-5 align-items-center bg-main-light border-round-lg">
            <h2 className="text-main">Create Schedule</h2>
          </div>
          <Card className="shadow-2 p-3 border-round-lg">
            <div className="col-12 md:col-12 text-right flex align-items-center justify-content-end">
              {id !== undefined ? (
                <span className="mx-3">
                  {form?.is_active === 1 ? "Active" : "InActive"}
                </span>
              ) : (
                ""
              )}
              {id !== undefined ? (
                <InputSwitch
                  checked={form?.is_active === 1 ? true : false}
                  onChange={(e) => setCheck(e.value)}
                />
              ) : (
                ""
              )}
            </div>
            <div className="grid flex align-items-center">
              <div className="col-12 md:col-6">
                <InputField
                  placeholder="Scheduling Name"
                  name="appointment_name"
                  value={form?.appointment_name}
                  onChange={handleChange}
                ></InputField>
                {error?.appointment_name && (
                  <span className="error">{error?.appointment_name}</span>
                )}
              </div>
              <div className="col-6">
                <DropdownField
                  placeholder={"Select Location"}
                  name="location_id"
                  value={form?.location_id}
                  onChange={handleChange}
                  options={Location?.map((item) => ({
                    name: item?.name,
                    value: item?.id,
                  }))}
                  optionLabel={"name"}
                />
                {error?.location_id && (
                  <span className="error">{error?.location_id}</span>
                )}
              </div>
            </div>

            <div className="p-fluid grid p-0 m-0 ">
              <div className="col-12 md:col-6 text-main">
                <h3 className="font-bold" style={{ fontSize: "22px" }}>
                  {" "}
                  Select Days for the Appointment{" "}
                </h3>
              </div>
            </div>

            <div className="clendar ">
              {form?.time_schedule?.length > 0 &&
                form?.time_schedule?.map((item, index) => {
                  return (
                    <SheduleTime
                      index={index}
                      item={item}
                      form={form}
                      setForm={setForm}
                      startTime={startTime}
                      setStartTime={setStartTime}
                      endTime={endTime}
                      setEndTime={setEndTime}
                    />
                  );
                })}
            </div>
            <div className="timesloat">
              {form?.time_schedule?.map((item, index) => {
                return item?.is_available === 1 ? (
                  <>
                    <SelectTimesloat
                      day="Tuesday, Oct 18"
                      hour={hour}
                      item={item}
                      index={index}
                      form={form}
                      setForm={setForm}
                    />
                    <Timesloat
                      time="09:00AM - 10:00AM"
                      num="01"
                      name="Block"
                      item={item}
                      index={index}
                      form={form}
                      setForm={setForm}
                      viewData={viewData}
                    />
                  </>
                ) : (
                  ""
                );
              })}
            </div>

            <div className="p-fluid grid p-0 m-0 mb-5">
              <div className="col-12 md:col-12">
                <h3
                  className="font-bold text-main"
                  style={{ fontSize: "22px" }}
                >
                  Set Appointment Reminders
                </h3>
              </div>
              {form?.reminder?.map((item, index) => {
                return (
                  <div className="col-12 md:col-4 mb-2">
                    <label className="font block mb-2">
                      Reminder {index + 1}
                      {index === 0 ? <code className="text-red">*</code> : ""}
                    </label>
                    <Dropdown
                      inputId="dropdown"
                      placeholder="Select"
                      className="border-round-md"
                      options={time}
                      optionLabel="label"
                      value={item.reminder_type}
                      onChange={(e) => handleReminder(e, index)}
                    />
                  </div>
                );
              })}
              {error?.reminder && (
                <span className="error">{error?.reminder}</span>
              )}
              <div className="col-12 md:col-12 mb-5">
                <Checkbox
                  onChange={(e) => setChecked(e.checked)}
                  checked={checked}
                ></Checkbox>{" "}
                &nbsp; Not applicable
              </div>
              <div className="col-12 md:col-12 mb-5">
                <label className="font block mb-2"> General Note</label>
                <InputTextarea
                  className="border-round-md"
                  value={form?.notes}
                  onChange={handleChange}
                  rows={5}
                  cols={5}
                  name="notes"
                />
              </div>
              <div className="submitButton flex p-2">
                <SaveButton
                  label="Save"
                  onClick={() => handleSubmit()}
                ></SaveButton>
                <CancelButton
                  label="Cancel"
                  onClick={() => navigate("/account-owner/manage-schedules")}
                ></CancelButton>
              </div>
            </div>
          </Card>
        </div>
      </ContentArea>
    </>
  );
};

export default AddSchdeules;
