import { types } from "../HospitalType/types";

const intitalState = {
    getExpenseProfile: {},
    addExpenseCategoriesProfile: {},
    deleteExpenseCategoriesProfile: {},
    editExpenseCategoriesProfile: {},
    viewExpenseCategoriesProfile: {},
    getExpense: {},
    addExpenseProfile: {},
    deleteExpenseProfile: {},
    viewExpenseProfile: {},
    editExpenseProfile: {},
    getRevenueCategoriesProfile: {},
    addRevenueCategoriesProfile: {},
    deleteRevenueCategoriesProfile: {},
    viewRevenueCategoriesProfile: {},
    editRevenueCategoriesProfile: {},
    getRevenueProfile: {},
    addRevenueProfile: {},
    deleteRevenueProfile: {},
    viewRevenueProfile: {},
    editRevenueProfile: {}
}

export const getExpenseCategoriesReducer = (state = intitalState, action) => {
    switch (action.type) {
        case types.GET_EXPENSE_CATEGORIES:
            return {
                ...state,
                getExpenseProfile: action.payload
            }
        default:
            return { ...state }
    }
}

export const addExpenseCategoriesReducer = (state = intitalState, action) => {
    switch (action.type) {
        case types.ADD_EXPENSE_CATEGORIES:
            return {
                ...state,
                addExpenseCategoriesProfile: action.payload
            }
        default:
            return { ...state }
    }
}

export const deleteExpenseCategoriesReducer = (state = intitalState, action) => {
    switch (action.type) {
        case types.DELETE_EXPENSE_CATEGORIES:
            return {
                ...state,
                deleteExpenseCategoriesProfile: action.payload
            }
        default:
            return { ...state }
    }
}

export const editExpenseCategoriesReducer = (state = intitalState, action) => {
    switch (action.type) {
        case types.EDIT_EXPENSE_CATEGORIES:
            return {
                ...state,
                editExpenseCategoriesProfile: action.payload
            }
        default:
            return { ...state }
    }
}

export const viewExpenseCategoriesReducer = (state = intitalState, action) => {
    switch (action.type) {
        case types.VIEW_EXPENSE_CATEGORIES:
            return {
                ...state,
                viewExpenseCategoriesProfile: action.payload
            }
        default:
            return { ...state }
    }
}

export const getExpenseReducer = (state = intitalState, action) => {
    switch (action.type) {
        case types.GET_EXPENSE:
            return {
                ...state,
                getExpense: action.payload
            }
        default:
            return { ...state }
    }
}

export const addExpenseReducer = (state = intitalState, action) => {
    switch (action.type) {
        case types.ADD_EXPENSE:
            return {
                ...state,
                addExpenseProfile: action.payload
            }
        default:
            return { ...state }
    }
}

export const deleteExpenseReducer = (state = intitalState, action) => {
    switch (action.type) {
        case types.DELETE_EXPENSE:
            return {
                ...state,
                deleteExpenseProfile: action.payload
            }
        default:
            return {
                ...state
            }
    }
}

export const viewExpenseReducer = (state = intitalState, action) => {
    switch (action.type) {
        case types.VIEW_EXPENSE:
            return {
                ...state,
                viewExpenseProfile: action.payload
            }
        default:
            return {
                ...state
            }
    }
}

export const editExpenseReducer = (state = intitalState, action) => {
    switch (action.type) {
        case types.EDIT_EXPENSE:
            return {
                ...state,
                editExpenseProfile: action.payload
            }
        default:
            return {
                ...state
            }
    }
}

export const getRevenueCategoriesReducer = (state = intitalState, action) => {
    switch (action.type) {
        case types.GET_REVENUE_CATEGORIES:
            return {
                ...state,
                getRevenueCategoriesProfile: action.payload
            }
        default:
            return {
                ...state
            }
    }
}

export const addRevenueCategoriesReducer = (state = intitalState, action) => {
    switch (action.type) {
        case types.ADD_REVENUE_CATEGORIES:
            return {
                ...state,
                addRevenueCategoriesProfile: action.payload
            }
        default:
            return {
                ...state
            }
    }
}

export const deleteRevenueCategoriesReducer = (state = intitalState, action) => {
    switch (action.type) {
        case types.DELETE_REVENUE_CATEGORIES:
            return {
                ...state,
                deleteRevenueCategoriesProfile: action.payload
            }
        default:
            return {
                ...state
            }
    }
}

export const viewRevenueCategoriesReducer = (state = intitalState, action) => {
    switch (action.type) {
        case types.VIEW_REVENUE_CATEGORIES:
            return {
                ...state,
                viewRevenueCategoriesProfile: action.payload
            }
        default:
            return {
                ...state
            }
    }
}

export const editRevenueCategoriesReducer = (state = intitalState, action) => {
    switch (action.type) {
        case types.EDIT_REVENUE_CATEGORIES:
            return {
                ...state,
                editRevenueCategoriesProfile: action.payload
            }
        default:
            return {
                ...state
            }
    }
}

export const getRevenueReducer = (state = intitalState, action) => {
    switch (action.type) {
        case types.GET_REVENUE:
            return {
                ...state,
                getRevenueProfile: action.payload
            }
        default:
            return {
                ...state
            }
    }
}

export const addRevenueReducer = (state = intitalState, action) => {
    switch (action.type) {
        case types.ADD_REVENUE:
            return {
                ...state,
                addRevenueProfile: action.payload
            }
        default:
            return {
                ...state
            }
    }
}

export const deleteRevenueReducer = (state = intitalState, action) => {
    switch (action.type) {
        case types.DELETE_REVENUE:
            return {
                ...state,
                deleteRevenueProfile: action.payload
            }
        default:
            return {
                ...state
            }
    }
}

export const viewRevenueReducer = (state = intitalState, action) => {
    switch (action.type) {
        case types.VIEW_REVENUE:
            return {
                ...state,
                viewRevenueProfile: action.payload
            }
        default:
            return {
                ...state
            }
    }
}

export const editRevenueReducer = (state = intitalState, action) => {
    switch (action.type) {
        case types.EDIT_REVENUE:
            return {
                ...state,
                editRevenueProfile: action.payload
            }
        default:
            return {
                ...state
            }
    }
}