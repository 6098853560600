import React, { useEffect } from "react";
import InputField from "../../../../components/input/input";
import SaveButton from "../../../../components/Buttons/SaveButton";
import CancelButton from "../../../../components/Buttons/CancelButton";
import DropdownField from "../../../../components/input/dropdown";
import { PostLeave } from "../../../../Redux/HospitalAction/appoinmentAction";
import { useDispatch, useSelector } from "react-redux";
import { getLeaveList } from "../../../../Redux/HospitalAction/employessAction";

const AddLeave = ({ setAddLeave, data, setData }) => {
  const dispatch = useDispatch();

  const handleChange = (name) => (e) => {
    // if (name === "leave_type") {
    //   setSelectLeave({ name: e.value.name });
    // }
    if (name === "leave_type") {
      setData(e.value?.name);
    }
    setData({ ...data, [name]: e.target.value });
  };
  const SaveData = (data) => {
    dispatch(PostLeave(data, setAddLeave, setData));
  };
  const fetchLeaveList = useSelector(
    (state) => state.getLeaveList.getLeaveList
  );

  useEffect(() => {
    dispatch(getLeaveList());
  }, []);

  return (
    <>
      <div className="px-4">
        <h2 className="">
          {data?.edit === true ? "Edit Leave" : " Leave"} Category Details
        </h2>
        <div className="flex p-0 ">
          <div className="col pl-0">
            <InputField
              label={"Category"}
              value={data?.category}
              onChange={handleChange("category")}
            ></InputField>
          </div>
          <div className="col">
            <DropdownField
              label={"Leave Type"}
              optionLabel={"name"}
              options={fetchLeaveList?.map((item) => {
                return { name: item?.name, value: item?.id };
              })}
              value={Number(data?.leave_type)}
              onChange={handleChange("leave_type")}
            ></DropdownField>
          </div>
        </div>
        <div className="flex p-0 ">
          <div className="col-6 pl-0">
            <InputField
              label={"Annual_Allotment"}
              value={data?.annual_allotment}
              onChange={handleChange("annual_allotment")}
            ></InputField>
          </div>
        </div>

        <div className="flex py-5">
          <SaveButton
            label={"Save"}
            onClick={() => SaveData(data)}
          ></SaveButton>
          <CancelButton
            label={"Cancel"}
            onClick={() => {
              setAddLeave(false);
              setData({
                id: "",
                employee_id: "",
                category: "",
                leave_type: "",
                annual_allotment: "",
              });
            }}
          ></CancelButton>
        </div>
      </div>
    </>
  );
};

export default AddLeave;
