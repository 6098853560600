
import React, { useState, useEffect } from 'react';
import { InputSwitch } from 'primereact/inputswitch';
import { Calendar } from 'primereact/calendar';
import CalendarIcon from "../../../../../assets/icons/calendar.png"
import InputField from '../../../../../components/input/input';
import DropdownField from '../../../../../components/input/dropdown';
const SheduleTime = ({ item, index, form, setForm,startTime,setStartTime,endTime,setEndTime }) => {
  const generateTimeSlots = async (hh, mm, ss) => {
    const startTime = new Date();
    startTime.setHours(0, 0, 0);

    const endTime = new Date();
    endTime.setHours(23, 30, 0);

    const genTimeSlots = [];
    while (startTime <= endTime) {
      const hours = startTime.getHours().toString().padStart(2, '0');
      const minutes = startTime.getMinutes().toString().padStart(2, '0');
      let timeSlot = `${hours}:${minutes}`;
      genTimeSlots.push(timeSlot);
      startTime.setMinutes(startTime.getMinutes() + 60);
    }
    setEndTime(() => {
      return [
        ...genTimeSlots
      ]
    })

    setStartTime(genTimeSlots)
  };
  useEffect(() => {
    generateTimeSlots();
  }, []);
  const handleChange = (e) => {
    const { name, value } = e.target;
    const dateClone = [...startTime];
    let newData = form?.time_schedule?.map((items, i) => {
      if (i === index) {
        if (name === "start_time") {
          let endTime = dateClone.slice(startTime.indexOf(value));
          endTime = [...endTime]
          setEndTime(endTime)
        }
        return {
          ...items,
          [name]: value,
        }
      } else {
        return {
          ...items
        }
      }
    })
    setForm((prev) => {
      return {
        ...prev,
        time_schedule: newData
      }
    })
  }
  const handleToogle = (e) => {
    const { name, value } = e.target
    let array = [...form?.time_schedule]
    let obj = { ...array[index], [name]: value === true ? 1 : 0 }
    array.splice(index, 1, obj)
    setForm((prev) => {
      return {
        ...prev,
        time_schedule: array
      }
    })
  }
  return (
    <>


      <div className="p-fluid grid flex align-items-center justify-content-between mb-4 p-0 m-0">
        <div className=" col-12 md:col-4">
          <label>
          </label>
          <InputField disabled inputClass={item?.is_available === 0?'border-round-md mt-2 p-2':'bg-blue-100 border-round-md mt-2 p-2 bg-blue-200'} value={item?.day === 0 ? "Monday" : item?.day === 1 ? "Tuesday" : item?.day === 2 ? "Wednesday" : item?.day === 3 ? "Thursday" : item?.day === 4 ? "Friday" : item?.day === 5 ? "Saturday" : item?.day === 6 ? "Sunday" : ""} />
        </div>
        <div className="col-12 md:col-2">
          <label className='text-color_rgb'>
            Start time
          </label>
          <DropdownField placeholder="Start Time"
            name="start_time" options={startTime} value={item?.start_time} onChange={handleChange} />
          {/* <input type="time" className='time p-3 border-400 px-2 border-round-lg border-1 w-full mt-2' required /> */}
        </div>
        <div className="col-12 md:col-2 ">
          <label className='text-color_rgb'>
            End time
          </label>
          <DropdownField placeholder="End Time"
            name="end_time" options={endTime} onChange={handleChange} value={item?.end_time} />
          {/* <input type="time" className='time border-400 p-3 px-2 border-round-lg border-1 w-full mt-2' required /> */}
        </div>
        <div className="col-12 md:col-2">
          <label className='flex align-items-center mt-3 justify-content-end '>
            {item?.is_available === 0 ? "Unavailable":"Available"}
            <span className='ml-2'>
              <InputSwitch checked={item?.is_available === 0 ? false : true} onChange={(e) => handleToogle(e)} name="is_available" />
            </span>
          </label>
        </div>

      </div>

    </>
  )
}

export default SheduleTime
